<div class="page-content">
  <div class="container">
    <div class="page-content-container" style="padding-bottom: 15px">
      <div class="row">
        <div class="col-xs-6">
          <h4 style="font-size: 26px; padding-bottom: 5px; overflow: auto">
            Broadband - Upgrade Service - {{ name }}
          </h4>
        </div>
        <div class="col-xs-6">
          <button
            class="login-button"
            mat-raised-button
            color="primary"
            style="
              margin-top: 20px;
              float: right;
              margin-right: 25px;
              background-color: #333333;
            "
            routerLink="/secure/products-and-services/broadband">
            Back
          </button>
        </div>
      </div>
      <div class="card-border-break"></div>
      @if (requestRaised) {
        <div class="row">
          @if (reference) {
            <div class="col-xs-12">
              <p style="text-align: center; padding-top: 25px">
                Your request has been raised with reference number
              </p>
              <h2 style="text-align: center">{{ reference }}</h2>
            </div>
          }
          @if (!reference) {
            <div class="col-xs-12">
              <p
                style="text-align: center; padding-top: 25px; padding-bottom: 25px">
                Your request has been raised and you will be notified of your
                reference number shortly.
              </p>
            </div>
          }
        </div>
      }
      <!-- Loading -->
      @if (!requestRaised) {
        <div class="row">
          <div class="col-xs-12">
            <div class="loading-container" style="text-align: center">
              <img
              style="
                text-align: center;
                margin-top: 20px;
                width: 53px;
                height: 60px;
              "
                src="../../assets/gifs/loading/servicehub-loading-ani.gif" />
              <h4 style="text-align: center; padding: 0 !important">Loading</h4>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</div>
