@if (submittingData) {
  <loading-spinner />
}

@if (!submittingData) {
  <div class="flex flex-column h-full">
    <h1 class="text-xl px-3">{{ categoryName }} - Add Authorisation Type</h1>
    <hr class="w-full" />
    <div class="mx-3 text-lg">
      <p>
        Please select the user(s) that you wish to add to the
        <span class="text-primary font-bold">{{ categoryName }}</span>
        {{ contactType }} group.
      </p>
      <p-table
        #dataTable
        [value]="users$ | async"
        [columns]="tableColumn"
        styleClass="p-datatable-striped"
        [rowHover]="true"
        sortMode="single"
        [rows]="25"
        [lazy]="true"
        (onLazyLoad)="loadUsers(dataTable, $event)"
        [(selection)]="selectedUsers"
        [scrollable]="true"
        scrollHeight="600px"
        [loading]="tableLoading">
        <ng-template pTemplate="caption">
          <div class="flex justify-content-end align-content-center">
            <div class="flex-grow-1">
              @if (tableColumn && dataTable && dataTable.loading === false) {
                <prime-filter-dropdown
                  [selectedColumns]="tableColumn"
                [table]="dataTable"></prime-filter-dropdown>
              }
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 4rem" class="bg-gray-800">
              <p-tableHeaderCheckbox />
            </th>
            @for (col of columns; track col) {
              <th
                class="text-white bg-gray-800 p-datatable-header-first-row"
                [pSortableColumn]="col.field">
                {{ col.header }}
                @if (col.showSortIcon) {
                  <p-sortIcon
                    class="text-white"
                  [field]="col.field"></p-sortIcon>
                }
              </th>
            }
          </tr>
        </ng-template>
        <ng-template pTemplate="loadingbody">
          <tr>
            <td [attr.colspan]="tableColumn?.length + 1">
              <loading-spinner></loading-spinner>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="loadingicon"></ng-template>
        <ng-template pTemplate="body" let-user let-columns="columns">
          <tr class="cursor-pointer">
            <td (click)="$event.stopPropagation()">
              <p-tableCheckbox [value]="user" />
            </td>
            @for (col of columns; track col) {
              <td>
                @if (col.display?.type === 'text') {
                  {{ user[col.field] }}
                }
              </td>
            }
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [attr.colspan]="tableColumn?.length + 1">
              No results were found.
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr>
            <td [attr.colspan]="tableColumn?.length + 1">
              <div class="flex align-items-center justify-content-center">
                <p-paginator
                  #tablePaginator
                  (onPageChange)="loadUsers(dataTable, $event)"
                  [first]="dataTable.first"
                  [rows]="dataTable.rows"
                  [totalRecords]="totalRecords"
                  [showCurrentPageReport]="true"
                  currentPageReportTemplate="{first} - {last} of {totalRecords}"
                  [rowsPerPageOptions]="[25, 50, 100]"
                dropdownAppendTo="body"></p-paginator>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="flex align-items-center justify-content-center pb-3 pt-2">
      <p-button
        (onClick)="close()"
        styleClass="p-button-secondary"
      label="Cancel"></p-button>
      <p-button
        styleClass="p-button"
        label="Submit"
        [disabled]="!submitButtonEnabled()"
      (onClick)="submitClicked()"></p-button>
    </div>
  </div>
}
