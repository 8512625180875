@if (selectedDate) {
  <span>
    {{ selectedDateView }}
  </span>
}
<div class="p-input-icon-right w-full">
  <p-calendar
    #calendar
    class="card flex justify-content-center"
    dateFormat="dd/mm/yy"
    appendTo="body"
    [(ngModel)]="selectedDate"
    [selectionMode]="selectionMode"
    [numberOfMonths]="2"
    [maxDate]="maxAllowedDate"
    styleClass="w-full"
    [showIcon]="true"
    [showButtonBar]="false"
    [readonlyInput]="true"
    (ngModelChange)="dateChange($event, selectedDate)"
    [inline]="true">
    <ng-template pTemplate="header">
      <div>
        <div>
          <p-dropdown [options]="rangeOptions" [(ngModel)]="selectionMode" />
          @for (dateSelection of dateSelections; track dateSelection) {
            <button
              class="p-button p-button-text range-buttons"
              (click)="setDate(dateSelection.value, dateSelection.label)">
              {{ dateSelection.label }}
            </button>
          }
        </div>
      </div>
    </ng-template>
  </p-calendar>
</div>
