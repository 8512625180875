import { DateTime } from 'luxon';
import { ChangeRequestTableColumnName } from 'src/app/models/change/change.models';
import { TableColumn } from 'src/app/models/table';

export enum exportFields {
  number = 'number',
  state = 'state',
  requestedFor = 'requestedFor',
  shortDescription = 'subject',
  startDate = 'plannedStartDate',
  endDate = 'plannedEndDate',
  closedStatus = 'changeOutcome',
}

export const ChangeRequestOpenColumns: TableColumn[] = [
  {
    field: 'number',
    header: 'Number',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
    disabled: true,
    serviceNowField: ChangeRequestTableColumnName.number,
    exportField: exportFields.number,
  },
  {
    field: 'shortDescription',
    header: 'Subject',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
    serviceNowField: ChangeRequestTableColumnName.shortDescription,
    exportField: exportFields.shortDescription,
  },
  {
    field: 'requestedFor',
    header: 'Request For',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
    serviceNowField: ChangeRequestTableColumnName.requestedFor,
    exportField: exportFields.requestedFor,
  },
  {
    field: 'startDate',
    header: 'Planned Start Date',
    display: { type: 'date' },
    filter: {
      type: 'dateRange',
    },
    showSortIcon: true,
    default: true,
    serviceNowField: ChangeRequestTableColumnName.startDate,
    exportField: exportFields.startDate,
  },
  {
    field: 'endDate',
    header: 'Planned End Date',
    display: { type: 'date' },
    filter: {
      type: 'dateRange',
    },
    showSortIcon: true,
    default: true,
    serviceNowField: ChangeRequestTableColumnName.endDate,
    exportField: exportFields.endDate,
  },
  {
    field: 'state',
    header: 'State',
    display: {
      type: 'chip',
      colors: {
        implementation: 'implementation',
        approval: 'approval',
        'awaiting info': 'awitinginfo',
        closed: 'closed',
        'on hold ': 'onhold',
        pir: 'pir',
      },
    },
    filter: {
      type: 'multiSelect',
      values: [
        { label: 'Approval', value: 'Approval' },
        { label: 'Implementation', value: 'Implementation' },
        { label: 'On Hold', value: 'On Hold' },
        { label: 'PIR', value: 'PIR' },
      ],
    },
    showSortIcon: true,
    default: true,
    serviceNowField: ChangeRequestTableColumnName.state,
    exportField: exportFields.state,
  },
];

const ChangeRequestDefaultClosedDays: number = 90;

export const ChangeRequestClosedColumns: TableColumn[] = [
  {
    field: 'number',
    header: 'Number',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
    disabled: true,
    serviceNowField: ChangeRequestTableColumnName.number,
    exportField: exportFields.number,
  },
  {
    field: 'shortDescription',
    header: 'Subject',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
    serviceNowField: ChangeRequestTableColumnName.shortDescription,
    exportField: exportFields.shortDescription,
  },
  {
    field: 'requestedFor',
    header: 'Request For',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
    serviceNowField: ChangeRequestTableColumnName.requestedFor,
    exportField: exportFields.requestedFor,
  },
  {
    field: 'startDate',
    header: 'Planned Start Date',
    display: { type: 'date' },
    filter: {
      type: 'dateRange',
    },
    showSortIcon: true,
    default: true,
    serviceNowField: ChangeRequestTableColumnName.startDate,
    exportField: exportFields.startDate,
  },
  {
    field: 'endDate',
    header: 'Planned End Date',
    display: { type: 'date' },
    filter: {
      type: 'dateRange',
      preset: {
        matchMode: 'between',
        value: [
          DateTime.now()
            .minus({ days: ChangeRequestDefaultClosedDays })
            .toJSDate(),
          DateTime.now().toJSDate(),
        ],
      },
      message: `By default the last ${ChangeRequestDefaultClosedDays} days of closed change requests are shown`,
    },
    showSortIcon: true,
    default: true,
    serviceNowField: ChangeRequestTableColumnName.endDate,
    exportField: exportFields.endDate,
  },
  {
    field: 'state',
    header: 'State',
    display: {
      type: 'chip',
      colors: {
        implementation: 'implementation',
        approval: 'approval',
        'awaiting info': 'awitinginfo',
        closed: 'closed',
        'on hold ': 'onhold',
        pir: 'pir',
      },
    },
    filter: null,
    showSortIcon: true,
    default: true,
    serviceNowField: ChangeRequestTableColumnName.state,
    exportField: exportFields.state,
  },
  {
    field: 'closedStatus',
    header: 'Change Outcome',
    display: { type: 'text-highlight' },
    filter: {
      type: 'multiSelect',
      values: [
        { label: 'Successfully Completed', value: 'Successfully Completed' },
        { label: 'Completed Successful', value: 'Completed Successful' },
        { label: 'Completed Unsuccessful', value: 'Completed Unsuccessful' },
        { label: 'Cancelled', value: 'Cancelled' },
        { label: 'Backed Out', value: 'Backed Out' },
      ],
    },
    showSortIcon: true,
    default: true,
    serviceNowField: ChangeRequestTableColumnName.closedStatus,
    exportField: exportFields.closedStatus,
  },
];
