import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import {
  BillingItem,
  BillingItemTypes,
  DownloadBillingItemResponse,
  DownloadStatus,
  ExportPdfRequest,
  ExportStatusStrings,
} from '../../../../models/export/export-pdf.model';
import { DownloadInvoiceCreditNoteService } from 'src/app/services/download-invoice.service';
import { InvoiceService } from 'src/app/services/api/invoice/invoice.service';
import { firstValueFrom, Observable } from 'rxjs';
import { IDownloadFile } from 'src/app/models/export-models';
import { Invoice } from 'src/app/models/billing/invoice-models';
import { CreditNote } from 'src/app/models/billing/credit-note.models';
import { TitleCasePipe } from '@angular/common';
import { isPromiseFulfilledResult } from 'src/app/helpers/export/export.helper';

const IndividualDownloadLimit: number = 1;

@Component({
  selector: 'app-export-pdf',
  templateUrl: './export-pdf.component.html',
  styleUrls: ['./export-pdf.component.scss'],
  providers: [TitleCasePipe],
})
export class ExportPdfComponent implements OnInit, OnDestroy {
  data: BillingItem[];
  itemType: BillingItemTypes;
  showExportStatus: boolean = false;
  exportComplete: boolean = false;

  ExportStatusStrings = ExportStatusStrings;
  downloadStatus: DownloadStatus = {};

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig<ExportPdfRequest>,
    private downloadInvoiceAndCreditNoteService: DownloadInvoiceCreditNoteService,
    private invoiceService: InvoiceService,
    private titleCasePipe: TitleCasePipe
  ) {}

  ngOnInit(): void {
    this.data = this.config.data?.billingItems;
    this.itemType = this.config.data?.itemType;
  }

  async export(downloadStatus: DownloadStatus) {
    this.showExportStatus = true;

    // Create File download Requests as Promises
    const tasks = this.data.map(async billingItem =>
      this.downloadBillingItem(downloadStatus, billingItem)
    );

    const downloadBillingItemsResponse: DownloadBillingItemResponse[] = (
      await Promise.allSettled(tasks)
    )
      .filter(downloadResult => isPromiseFulfilledResult(downloadResult))
      .map(
        (
          downloadResult: PromiseFulfilledResult<DownloadBillingItemResponse>
        ): DownloadBillingItemResponse => downloadResult.value
      );

    // //Filter to only successful responses
    const fileDownloadPrefix = `Digital Space ${this.titleCasePipe.transform(
      this.itemType
    )}`;
    if (downloadBillingItemsResponse.length === IndividualDownloadLimit) {
      this.individualExport(downloadBillingItemsResponse, fileDownloadPrefix);
    }
    if (downloadBillingItemsResponse.length > IndividualDownloadLimit) {
      this.downloadInvoiceAndCreditNoteService.downloadAsZip(
        downloadBillingItemsResponse,
        fileDownloadPrefix
      );
    }
    this.exportComplete = true;
  }

  async downloadBillingItem(
    downloadStatus: DownloadStatus,
    billingItem: BillingItem
  ): Promise<DownloadBillingItemResponse> {
    downloadStatus[billingItem.invoiceNo] = ExportStatusStrings.Loading;
    try {
      const downloadedFile = await firstValueFrom(
        this.handleBillingItemRequest(billingItem)
      );
      downloadStatus[billingItem.invoiceNo] = ExportStatusStrings.Success;
      return { billingItem: billingItem, file: downloadedFile };
    } catch (error) {
      downloadStatus[billingItem.invoiceNo] = ExportStatusStrings.Failed;
      throw error;
    }
  }

  async individualExport(
    downloadInvoicesResponseArray: DownloadBillingItemResponse[],
    fileNamePrefix: string
  ) {
    downloadInvoicesResponseArray.forEach(
      (response: DownloadBillingItemResponse) => {
        this.downloadInvoiceAndCreditNoteService.downloadToPdf(
          response.billingItem.invoiceNo,
          response.file,
          fileNamePrefix
        );
      }
    );
  }

  handleBillingItemRequest(
    billingItem: BillingItem
  ): Observable<IDownloadFile> {
    switch (this.itemType) {
      case BillingItemTypes.CreditNote:
        return this.invoiceService.getCreditNotePdf(billingItem.invoiceNo);

      // Invoices without an Invoice Id do not yet exist in the billing table as a PDF, need to be converted using credit note system first.
      case BillingItemTypes.Invoice:
        if (!billingItem.invoiceId) {
          return this.invoiceService.getManualInvoicePdf(billingItem.invoiceNo);
        } else {
          return this.invoiceService.getInvoicesPdf(
            billingItem.companyId,
            billingItem.invoiceId,
            billingItem.invoiceNo
          );
        }
    }
  }

  async downloadAsCreditNote(invoiceNo: string) {
    const downloadedInvoiceManual = await firstValueFrom(
      this.invoiceService.getManualInvoicePdf(invoiceNo)
    );
    return downloadedInvoiceManual;
  }

  async downloadAsInvoice(invoice: Invoice) {
    const downloadedInvoice = await firstValueFrom(
      this.invoiceService.getInvoicesPdf(
        invoice.companyId,
        invoice.invoiceId,
        invoice.invoiceNo
      )
    );
    return downloadedInvoice;
  }

  getStatusClass(invoiceNo: string): string {
    const status = this.downloadStatus[invoiceNo];
    switch (status) {
      case this.ExportStatusStrings.Loading:
        return 'pi-spinner pi-spin text-pink-500';
      case this.ExportStatusStrings.Success:
        return 'pi-check-circle text-teal-500';
      case this.ExportStatusStrings.Failed:
        return 'pi-times-circle text-red-500';
      default:
        return '';
    }
  }

  close() {
    this.ref.close();
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }
}
