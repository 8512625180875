<div class="flex flex-column h-full">
  <div
    class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200">
    <h1 class="px-3 text-xl">Knowledge Articles</h1>
  </div>

  <div
    class="flex-grow-1 overflow-auto mx-3 overflow-hidden bg-white border-solid border-1 border-gray-200 border-top-none border-bottom-none px-3">
    <p-table
      class="p-datatable-equal-width"
      #dataTable
      [columns]="selectedColumns"
      [value]="knowledgeArticles$ | async"
      [lazy]="true"
      (onLazyLoad)="loadKnowledgeArticles(dataTable, $event)"
      [rows]="25"
      styleClass="p-datatable-striped"
      [rowsPerPageOptions]="[25, 50, 100]"
      sortMode="single"
      scrollHeight="flex"
      [scrollable]="true"
      [tableStyle]="{ 'min-width': '50rem' }"
      [rowHover]="true"
      [selectionPageOnly]="true"
      selectionMode="single">
      <ng-template pTemplate="caption">
        <div class="flex justify-content-start align-content-center">
          <div class="flex-grow-1">
            @if (selectedColumns && dataTable && dataTable.loading === false) {
              <prime-filter-dropdown
                [selectedColumns]="cols"
                [table]="dataTable"></prime-filter-dropdown>
            }
            <p-button
              label="Reset"
              styleClass="p-button p-button-text p-button-secondary"
              icon="pi pi-eraser"
              (onClick)="reset(dataTable, false)"></p-button>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <tr>
          <td [attr.colspan]="selectedColumns?.length + 1">
            <loading-spinner></loading-spinner>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingicon"></ng-template>
      <ng-template pTemplate="header" let-columns let-knowledge>
        <tr>
          @for (col of columns; track col) {
            <th
              class="text-white bg-gray-800 p-datatable-header-first-row"
              [pSortableColumn]="col.field">
              @if (!col.field?.visible) {
                {{ col.header }}
              }
              @if (col.showSortIcon) {
                <p-sortIcon class="text-white" [field]="col.field"></p-sortIcon>
              }
            </th>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-knowledge let-columns="columns">
        <tr class="cursor-pointer" (click)="handleKnowledgeRowClick(knowledge)">
          @for (col of columns; track col) {
            <td>
              @if (col.display?.type === 'text') {
                {{ knowledge[col.field] }}
              }
            </td>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="selectedColumns?.length + 1">
            No results were found.
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td class="bg-white" [attr.colspan]="selectedColumns.length + 1">
            <div class="flex align-items-center justify-content-center">
              <p-paginator
                #tablePaginator
                (onPageChange)="loadKnowledgeArticles(dataTable, $event)"
                [first]="dataTable.first"
                [rows]="dataTable.rows"
                [totalRecords]="totalRecords"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} - {last} of {totalRecords}"
                [rowsPerPageOptions]="[25, 50, 100]"></p-paginator>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="flex justify-content-center flex-wrap">
    <h2 class="text-base">
      Still need help? Please
      <span>
        <a class="text-primary font-bold" routerLink="/support/contacts"
          >contact us.</a
        >
      </span>
    </h2>
  </div>
</div>
