import { Inject, Injectable } from '@angular/core';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';
import { ITableToFile } from 'src/app/models/export-models';
import { ExportType } from '../../export.service';

@Injectable({
  providedIn: 'root',
})
export class ExportApiService {
  constructor(private apiRequest: ApiRequest) {}

  exportTableToFile(endPoint: string, fileBody: ITableToFile) {
    const requestProps: IRequestOptions = {
      endpoint: endPoint,
      body: fileBody,
      requiresVpc: true,
    };
    return this.apiRequest.request(requestProps);
  }

  exportTableToFileVPN(endPoint: string, fileBody: ITableToFile) {
    const requestProps: IRequestOptions = {
      endpoint: endPoint,
      body: fileBody,
      requiresVpc: true,
    };
    return this.apiRequest.request(requestProps);
  }

  fileExportSingle(endPoint: string, id: string, type: ExportType) {
    const requestProps: IRequestOptions = {
      endpoint: endPoint,
      body: {
        Value: id,
        ExportType: type,
      },
    };
    return this.apiRequest.request(requestProps);
  }

  fileExportList(endPoint: string, body: any) {
    const requestProps: IRequestOptions = {
      endpoint: endPoint,
      body: body,
    };
    return this.apiRequest.request(requestProps);
  }
}
