<div class="flex flex-column h-full">
  <h1 class="text-xl px-3">Location</h1>
  <hr class="w-full" />
  <div class="flex-grow-1 mx-3">
    <p-table
      #dataTable
      [value]="locations$ | async"
      [lazy]="true"
      (onLazyLoad)="getLocations(dataTable, $event)"
      [rows]="25"
      styleClass="p-datatable-striped"
      [columns]="columns"
      [rowsPerPageOptions]="[25, 50, 100]"
      sortMode="single"
      [resizableColumns]="true"
      columnResizeMode="expand"
      [rowHover]="true"
      [selectionPageOnly]="true"
      selectionMode="single"
      [(selection)]="selectedLocation"
      dataKey="sysId"
      [scrollable]="true"
      scrollHeight="600px">
      <ng-template pTemplate="caption">
        <div class="flex justify-content-between align-items-center">
          <div class="flex-grow-1">
            @if (columns && dataTable && dataTable.loading === false) {
              <prime-filter-dropdown
                [selectedColumns]="columns"
              [table]="dataTable"></prime-filter-dropdown>
            }
          </div>
          <p-button
            label="Reset"
            styleClass="p-button p-button-text p-button-secondary"
            icon="pi pi-filter-slash"
          (click)="reset(dataTable, false)"></p-button>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          @for (col of columns; track col) {
            <th
              class="text-white bg-gray-800 p-datatable-header-first-row"
              [pSortableColumn]="col.field">
              {{ col.header }}
              @if (col.showSortIcon) {
                <p-sortIcon
                  class="text-white"
                [field]="col.field"></p-sortIcon>
              }
            </th>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <tr>
          <td [attr.colspan]="columns?.length + 1">
            <loading-spinner></loading-spinner>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingicon"></ng-template>
      <ng-template pTemplate="body" let-location let-columns="columns">
        <tr [pSelectableRow]="location" class="cursor-pointer">
          @for (col of columns; track col) {
            <td>
              @if (col.display?.type === 'text') {
                {{ location[col.field] }}
              }
            </td>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="selectedColumns?.length + 1">
            No results were found.
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td [attr.colspan]="columns?.length + 1">
            <div class="flex align-items-center justify-content-center">
              <p-paginator
                #tablePaginator
                (onPageChange)="getLocations(dataTable, $event)"
                [first]="dataTable.first"
                [rows]="dataTable.rows"
                [totalRecords]="totalRecords"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} - {last} of {totalRecords}"
                [rowsPerPageOptions]="[25, 50, 100]"
              dropdownAppendTo="body"></p-paginator>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
    @if (!loading) {
      <div
        class="flex align-items-center justify-content-center pt-4 mb-3">
        <p-button
          styleClass="p-button-secondary"
          (onClick)="cancelButton()"
          label="Cancel">
        </p-button>
        <p-button
          (onClick)="closeDialog()"
          [disabled]="!selectedLocation"
          label="Confirm">
        </p-button>
      </div>
    }
  </div>
</div>
