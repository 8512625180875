import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Message } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { SurveyService } from 'src/app/services/api/survey/survey.service';
import { GetSurveyResponse } from '../models/survey-models';

const unknownError = `An unknown error occured, please try again. If the issue persists please contact us at customer.services@digitalspace.co.uk`;

/**
 * Component which gets a survey by ID and allows that survey to be filled in and submitted
 */
@Component({
  selector: 'submit-survey',
  templateUrl: './submit-survey.component.html',
})
export class SubmitSurveyComponent implements OnInit {
  // Parameters from params and queryParams
  surveyId: string;
  score: number;

  loading: boolean = false;

  messages: Message[] | undefined = [];

  survey: GetSurveyResponse;
  additionalComments: string;

  scoreOptions: any[] = [
    { value: 1 },
    { value: 2 },
    { value: 3 },
    { value: 4 },
    { value: 5 },
    { value: 6 },
    { value: 7 },
    { value: 8 },
    { value: 9 },
    { value: 10 },
  ];

  previousUrl = '';

  canTakeSurvey = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private surveyService: SurveyService
  ) {
    this.surveyId = this.activatedRoute.snapshot.params.id;
    this.score = this.activatedRoute.snapshot.queryParams.score
      ? Number(this.activatedRoute.snapshot.queryParams.score)
      : null;
    let previousUrl = localStorage.getItem('currentNavigation');
    if (previousUrl) {
      this.previousUrl = previousUrl;
    }
  }

  async ngOnInit() {
    await this.getSurvey(this.surveyId);
  }

  goBack() {
    localStorage.removeItem('currentNavigation');
    this.router.navigate([this.previousUrl]);
  }

  async getSurvey(surveyId: string) {
    try {
      if (!surveyId) {
        throw new Error('Survey not provided');
      }
      let canTakeSurvey = true;
      this.loading = true;
      this.survey = await firstValueFrom(
        this.surveyService.getSurvey(surveyId)
      );

      if (this.survey?.score) {
        this.score = Number(this.survey?.score);
        this.messages = [
          {
            severity: 'success',
            detail: `
              <h4>
                Thank you for your help, your survey and additional comments have been successfully submitted.
              </h4>
              <p>Your comments and feedback are important to us and we use these as part of our commitment to continuously improve the service we offer.</p>
            `,
          },
        ];
        canTakeSurvey = false;
      }
      this.canTakeSurvey = canTakeSurvey;
    } catch (error) {
      this.messages = this.handleError(error);
      this.canTakeSurvey = false;
    }
    this.loading = false;
  }

  async submitSurvey() {
    this.loading = true;
    try {
      if (!this.score) {
        throw new Error('Score not selected, please select a score.');
      }
      if (
        !this.scoreOptions
          .map(scoreOption => scoreOption.value)
          .includes(this.score)
      ) {
        throw new Error(`Invaild score, please select a valid score.`);
      }
      await firstValueFrom(
        this.surveyService.takeSurvey(this.surveyId, {
          score: this.score,
          ...(this.additionalComments && {
            additionalComments: this.additionalComments,
          }),
        })
      );
      await this.getSurvey(this.surveyId);
    } catch (error) {
      this.messages = this.handleError(error);
    }
    this.loading = false;
  }

  handleError(error: Error | HttpErrorResponse): Message[] {
    console.error(error);
    if (error instanceof Error) {
      return [
        {
          severity: 'error',
          detail: error.toString(),
        },
      ];
    }
    if (!(error instanceof HttpErrorResponse)) {
      return [
        {
          severity: 'error',
          detail: unknownError,
        },
      ];
    }
    if (error.status === 404) {
      return [
        {
          severity: 'error',
          detail: `Unfortunately we cannot find your Survey! <br />Please attempt to follow
        the link in your email again.`,
        },
      ];
    }
    return [];
  }
}
