@if (showLoader) {
  <loading-spinner />
}

@if (catalogItemId === OtherServiceRequestCategoryItem.VALUE) {
  @if (!showLoader) {
    <div class="flex align-content-center justify-content-center">
      <div>
        <p class="text-xl">
          Your request has been raised and you will be notified of your
          <br />
          reference number shortly.
        </p>
        <br />
        <div class="flex align-content-center justify-content-center">
          <p-button
            [disabled]="showLoader"
            label="Raise Another Request"
            (onClick)="raiseAnotherRequest()">
          </p-button>
        </div>
        @if (fileUploadErrors?.length > 0) {
          <div class="pb-1 p-error text-base">
            The files below were not upload sucessfully. You can go to the
            incident to upload the files anytime.
            <br />
            @for (error of fileUploadErrors; track error) {
              <ul>
                <li>
                  <p class="p-error text-base">{{ error.fileName }}</p>
                </li>
              </ul>
            }
          </div>
        }
      </div>
    </div>
  }
}

@if (catalogItemId !== OtherServiceRequestCategoryItem.VALUE) {
  @if (!showLoader) {
    <div class="flex align-items-center justify-content-center flex-column">
      @if (isRecentVisit) {
        <p-messages severity="warn" icon="pi pi-exclamation-triangle">
          <ng-template pTemplate>
            <div class="pr-3">
              <i class="pi pi-exclamation-triangle text-xl"></i>
            </div>
            <p>
              <span class="font-bold">Urgent Access: </span>Please note that as
              access has been requested in the next 24h, please <br />
              contact us on
              <span class="font-bold">0333 220 0222</span>
              to confirm the details.
            </p>
          </ng-template>
        </p-messages>
      }
      @if (isDcAccessRequest) {
        <p>
          @if (!pollMaxAttemptsReached) {
            Your request for
            {{ serviceNowCatalogForm.get('request').value.label }} is being
            reviewed by our team. You will be notified by email once it's been
            approved.
          }
          @if (pollMaxAttemptsReached) {
            Your request for
            {{ serviceNowCatalogForm.get('request').value.label }} is being
            processed. Once reviewed by our team, you will be notified by email
            once it's been approved.
          }
        </p>
      }
      @if (!isDcAccessRequest) {
        @if (!pollMaxAttemptsReached) {
          Your
          {{ serviceNowCatalogForm.get('request').value.label }} request has
          been successfully submitted.
        }
        @if (pollMaxAttemptsReached) {
          Your
          {{ serviceNowCatalogForm.get('request').value.label }} is been
          processed.
        }
      }

      @if (!pollMaxAttemptsReached) {
        <h2 class="text-3xl m-0">
          {{ requestNumber }}
          <i
            (keyup.enter)="copyRequestNumber()"
            role="button"
            tabindex="0"
            title="Copy Request Number"
            (click)="copyRequestNumber()"
            class="pi pi-copy hover:text-primary text-3xl"></i>
        </h2>
      }
      <p class="text-3xl text-primary font-bold m-0">{{ textAlert }}</p>

      <div class="flex align-content-center justify-content-center pb-4 pt-2">
        @if (!pollMaxAttemptsReached) {
          <p-button
            styleClass="p-button-secondary"
            label="View Ticket"
            (onClick)="viewTicket()"></p-button>
        }
        @if (pollMaxAttemptsReached) {
          <p-button
            styleClass="p-button-secondary"
            label="View Service Requests"
            (onClick)="viewServiceRequests()"></p-button>
        }
        <p-button
          styleClass="p-button"
          label="Raise Another Request"
          (onClick)="raiseAnotherRequest()">
        </p-button>
      </div>
    </div>
  }
}
