import { TableColumnDisplay } from '../table';

export const ServiceRequestSizeLimit = 1000;

export enum ServiceRequestTabs {
  open = 'Open',
  closed = 'Closed',
}

export const ServiceRequestOpenStateValues = [
  'Pending',
  'Open',
  'Work In Progress',
  'Awaiting Info',
  'Fulfilled',
];

export const ServiceRequestClosedStateValues = ['Closed'];

export interface GetServiceRequestsResponse {
  sysId: string;
  shortDescription: string;
  state: string;
  openedAt?: Date;
  number: string;
  requestedFor: string;
}

export interface GetSingleServiceRequestResponse {
  active: boolean;
  description: string;
  number: string;
  openedAt: string;
  serviceRequestNumber: string;
  shortDescription: string;
  state: string;
  sysId: string;
  uExternalReferenceNumber: string;
  uRequestedFor: string;
  uRequestedForSysId: string;
}

//Column names match export DTOs in Customer portal
export enum ServiceRequestTableColumnName {
  number = 'Number',
  shortDescription = 'ShortDescription',
  description = 'Description',
  requestedFor = 'RequestedFor',
  requestedForSysId = 'RequestedFor.SysId',
  openedAt = 'OpenedAt',
  sysOpened = 'SysCreatedOn',
  state = 'State',
}

export enum CaseTableColumnName {
  number = ServiceRequestTableColumnName.number,
  shortDescription = ServiceRequestTableColumnName.shortDescription,
  description = ServiceRequestTableColumnName.description,
  requestedFor = ServiceRequestTableColumnName.requestedFor,
  openedAt = ServiceRequestTableColumnName.openedAt,
  state = ServiceRequestTableColumnName.state,
}

export interface ServiceRequestColumn {
  field: string;
  header: string;
  display: TableColumnDisplay;
  showSortIcon: boolean;
  default: boolean;
  disabled?: boolean;
  serviceNowField: string;
}

export const serviceRequestColors = {
  New: 'new',
  'In Progress': 'workinprogress',
  Pending: 'pending',
  'Awaiting Info': 'awaitinginfo',
  Resolved: 'resolved',
  Closed: 'closed',
  Fulfilled: 'fulfilled',
  Open: 'open',
};

export interface PacOptions {
  name: string;
  pacType?: string;
}

export interface RequestDropDownOptions {
  name: string;
  key: string;
}

export interface RequestSubOptions {
  name: string;
  value: string;
}

export interface RequestOptions {
  category: string;
  request: string;
}

export enum RequestCategory {
  Mobile = 'mobile',
  General = 'general',
  Access = 'access',
}
