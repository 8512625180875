export enum NotificationTableColumnNames {
  number = 'uTicket.number',
  description = 'uDescription',
  category = 'uCategory',
  lastUpdated = 'uLastUpdated',
  ticketSysId = 'uTicket.sysId',
  notificationRead = 'uNotificationRead',
}

export type Notification = {
  sysId: string;
  ticketNumber: string;
  ticketSysId: string;
  category: string;
  lastUpdated?: string;
  description: string;
  notificationRead: boolean;
};
