<div class="px-4 pt-3">
  <p-table
    #dt1
    [value]="data"
    styleClass="p-datatable-striped"
    [scrollable]="true">
    <ng-template pTemplate="header">
      <tr>
        <th class="text-white bg-gray-800 p-datatable-header-first-row">
          Number
        </th>
        <th class="text-white bg-gray-800 p-datatable-header-first-row">
          Date
        </th>
        <th
          class="text-white bg-gray-800 p-datatable-header-first-row text-center">
          <span [class.invisible]="!showExportStatus">Status</span>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data>
      <tr>
        <td>{{ data.invoiceNo }}</td>
        <td>{{ data.date | date: 'dd/MM/yyyy' }}</td>
        <td class="text-center">
          <i class="pi" [ngClass]="getStatusClass(data.invoiceNo)"></i>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div class="flex align-items-center justify-content-center pt-4 pb-2">
    @if (!showExportStatus) {
      <p-button
        (onClick)="close()"
        styleClass="p-button-secondary"
      label="Cancel"></p-button>
      <p-button
        styleClass="p-button"
        label="Download"
      (onClick)="export(downloadStatus)"></p-button>
    }
    @if (showExportStatus) {
      <p-button
        (onClick)="close()"
        styleClass="p-button"
        label="Close"
        icon="pi pi-times"
      [disabled]="!exportComplete"></p-button>
    }
  </div>
</div>
