import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api/menuitem';
//import { ContactPersonType } from "src/app/helpers/EnumHelper";
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';

@Component({
  selector: 'support',
  templateUrl: 'support.component.html',
  styleUrls: ['support.component.scss'],
})
export class SupportComponent implements OnInit {
  constructor(
    private router: Router,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    const breadcrumbs: MenuItem[] = [{ label: 'Support' }];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
  }

  redirectToKnowledgeArticles() {
    this.router.navigateByUrl('/support/knowledge-articles');
  }

  redirectToContacts() {
    this.router.navigateByUrl('/support/contacts');
  }

  redirectToArticles() {
    this.router.navigateByUrl('/support/articles');
  }

  redirectToEscalationMatrix() {
    this.router.navigateByUrl('/support/escalation-matrix');
  }
}
