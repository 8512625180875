/**
 * Core Angular Imports
 */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { Router, RouterModule } from '@angular/router';
import { routing } from './routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';

/**
 *  Misc Modules
 */
import { NgCircleProgressModule } from 'ng-circle-progress';

import { NgxEchartsModule } from 'ngx-echarts';

/**
 * Services
 */
import { ExportService } from './services/export.service';
import { FilterService } from './services/filter.service';
import { DictionaryService } from './services/dictionary.service';
import { UtilService } from './services/util.service';
import { BlockerService } from './services/blocker.service';
import { NotificationHelper } from './helpers/notifications/notification.helper';
import { NotificationService } from './services/notification.service';
import { StorageService } from './services/storage.service';

/**
 * Directives
 */
import { PermissionDirective } from './directives/permission.directive';

/**
 * Pipes
 */
import { EscapeHtmlPipe } from './pipes/escape-html.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

/**
 * ServiceHub Components
 */
import { MenuInfoComponent } from './components/global/menu-info/menu-info.component';
import { CommonModule, DOCUMENT, DatePipe } from '@angular/common';
import { ServiceHubComponent } from './components/service-hub/service-hub.component';
import { DashboardComponent } from './components/service-hub/dashboard/dashboard.component';
import { TicketsComponent } from './components/service-hub/tickets/tickets.component';
import { IncidentsComponent } from './components/service-hub/tickets/incidents/incidents.component';
import { ServiceRequestsComponent } from './components/service-hub/tickets/service-requests/service-requests.component';
import { OrdersComponent } from './components/service-hub/tickets/orders/orders.component';
import { BillingOverviewComponent } from './components/service-hub/billing/billing.component';
import { InvoicesComponent } from './components/service-hub/billing/invoices/invoices.component';
import { CreditNotesComponent } from './components/service-hub/billing/credit-notes/credit-notes.component';
import { BillingUsageComponent } from './components/service-hub/billing/usage/usage.component';
import { BroadbandComponent } from './components/service-hub/products-and-services/broadband/broadband.component';
import { DomainsComponent } from './components/service-hub/products-and-services/domains/domains.component';
import { ServiceStatusComponent } from './components/service-hub/products-and-services/services/status/status.component';
import { UsersComponent } from './components/service-hub/company/users/users.component';
import { ContactsComponent } from './components/service-hub/company/company-contacts/company-contacts.component';
import { AddContactComponent } from './components/misc/pop-up/add-contact/add-contact.component';
import { CredentialsComponent } from './components/service-hub/developer/credentials/credentials.component';
import { ShowApiCredentialsComponent } from './components/misc/pop-up/show-api-credentials/show-api-credentials.component';
import { GenerateApiCredentialsComponent } from './components/misc/pop-up/generate-api-credentials/generate-api-credentials.component';
import { WebHooksComponent } from './components/service-hub/developer/web-hooks/web-hooks.component';
import { ConfigureWebHookComponent } from './components/misc/pop-up/configure-web-hook/configure-web-hook.component';
import { AccountComponent } from './components/service-hub/account/account.component';
import { AdminsComponent } from './components/service-hub/account/admins/admins.component';
import { NotificationsComponent } from './components/service-hub/account/notifications/notifications.component';
import { RaiseTicketComponent } from './components/service-hub/tickets/raise/raise-ticket.component';
import { SingleIncidentComponent } from './components/service-hub/tickets/incidents/single/incident.component';
import { AddCommentComponent } from './components/misc/pop-up/add-comment/add-comment.component';
import { FileManagerComponent } from './components/misc/pop-up/file-manager/file-manager.component';
import { SingleServiceRequestComponent } from './components/service-hub/tickets/service-requests/single/service-request.component';
import { ServiceHubGraphComponent } from './components/misc/graph/graph.component';
import { DomainComponent } from './components/service-hub/products-and-services/domains/domain/domain.component';
import { ServiceInformationComponent } from './components/service-hub/products-and-services/services/information/information.component';
import { InvoiceOnlineViewComponent } from './components/service-hub/billing/online-view/online-view.component';

import { LogoutComponent } from './components/user-access/logout/logout.component';
import { ChangePasswordComponent } from './components/service-hub/account/change-password/change-password.component';
//import { ResetPasswordComponent } from "./components/user-access/reset-password/reset-password.component";
import { MiscNotificationComponent } from './components/service-hub/notifications/misc/misc-notification.component';
import { SearchComponent } from './components/service-hub/misc/search/search.component';
import { FilterComponent } from './components/misc/filters/filter/filter.component';
import { DatePickerDirective } from './directives/date-picker.directive';
import { EqualValidator } from './directives/password-match.directive';
import { NotFoundComponent } from './components/global/errors/not-found/not-found.component';
import { NotAuthorisedComponent } from './components/global/errors/not-authorised/not-authorised.component';
import { SubmitSurveyComponent } from './components/service-hub/survey/submit-survey/submit-survey.component';
import { DownloadExportComponent } from './components/misc/download-export/download-export.component';
import { TelephonyComponent } from './components/service-hub/products-and-services/telephony/telephony.component';
import { TelephonyDataComponent } from './components/service-hub/products-and-services/telephony/data/telephony-data.component';
import { ProgressCircle } from './components/misc/progress-circle/progress-circle.component';
import { SupportComponent } from './components/service-hub/misc/support/support.component';
import { KnowledgeArticleComponent } from './components/service-hub/misc/knowledge-article/knowledge-article.component';
import { ScienceLogicComponent } from './components/service-hub/products-and-services/science-logic/science-logic/science-logic.component';
import { ScienceLogicGraphReportingComponent } from './components/service-hub/products-and-services/science-logic/graph-reporting/science-logic-graph-reporting.component';
import { LoginHistoryComponent } from './components/service-hub/account/login-history/login-history.component';
import { DeviceHistoryComponent } from './components/service-hub/account/device-history/device-history.component';
import { ReleaseNotesComponent } from './components/misc/release-notes/release-notes.component';
import { ReleaseNotesNotificationComponent } from './components/misc/pop-up/release-notes-notification/release-notes-notification.component';
import { CmdbDevicesComponent } from './components/service-hub/products-and-services/science-logic/cmdb-devices/cmdb-devices.component';
import { ChangeRequestsComponent } from './components/service-hub/tickets/change-requests/change-requests.component';
import { SingleChangeRequestComponent } from './components/service-hub/tickets/change-requests/single/change-request.component';
import { RestrictionService } from './services/restriction.service';
import { RestrictedComponent } from './components/global/errors/restricted/restricted.component';
import { DeveloperPageLandingComponent } from './components/service-hub/account/developers/developer-landing/developer-landing.component';
import { UpgradeBroadbandComponent } from './components/service-hub/products-and-services/adsl/upgrade/upgrade-broadband.component';
import { InformationDetailsComponent } from './components/service-hub/products-and-services/services/details/information-details.component';
import { KnowledgeArticleDetailsComponent } from './components/service-hub/misc/knowledge-article/knowledge-article-details/knowledge-article-details.component';
import { ScienceLogicGraphServersReportingComponent } from './components/service-hub/products-and-services/science-logic/graph-reporting-servers/science-logic-graph-servers-reporting.component';
import { UserActivityComponent } from './components/service-hub/misc/logs/user-crud-activity.component';

import { UsageInformationComponent } from './components/service-hub/products-and-services/telephony/data/usage-information/usage-information.component';
import { EditBarsComponent } from './components/service-hub/products-and-services/telephony/data/edit-bars/edit-bars.component';
import { CookiesPolicyComponent } from './components/service-hub/misc/cookies-policy/cookies-policy.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CreateServiceRequestComponent } from './components/service-hub/tickets/raise/service-request/create-service-request.component';
import { CreateIncidentComponent } from './components/service-hub/tickets/raise/incidents/create-incident.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthorisationDialogComponent } from './components/service-hub/company/users/shared/authorisation-dialog/authorisation-dialog.component';
import { FileUploadComponent } from './components/misc/file-upload/file-upload.component';
import { FileDragDirective } from './directives/file-drag.directive';
import { DeviceServerTableComponent } from './components/service-hub/products-and-services/science-logic/cmdb-devices/device-category-table/device-category-table.component';
import { AddEditUserComponent } from './components/service-hub/company/users/add-edit-user/add-edit-user.component';
import { AddEditUserDetailsComponent } from './components/service-hub/company/users/add-edit-user/add-edit-user-details/add-edit-user-details.component';
import { AddEditUserReviewComponent } from './components/service-hub/company/users/add-edit-user/add-edit-user-review/add-edit-user-review.component';
import { AddEditUserRoleComponent } from './components/service-hub/company/users/add-edit-user/add-edit-user-role/add-edit-user-role.component';
import { AddEditUserPermissionsComponent } from './components/service-hub/company/users/add-edit-user/add-edit-user-permissions/add-edit-user-permissions.component';
import { AddEditUserAdditionalComponent } from './components/service-hub/company/users/add-edit-user/add-edit-user-additional/add-edit-user-additional.component';
import { RoleManagementComponent } from './components/service-hub/company/roles/role-management/role-management.component';
import { RoleInformationComponent } from './components/service-hub/company/roles/role-information/role-information.component';
import { ReplaceUnderscorePipe } from './pipes/replace-underscore.pipe';
import { ReplacePdfPipe } from './pipes/replace-pdf.pipe';
import { BroadbandUsageComponent } from './components/service-hub/products-and-services/broadband/broadband-usage/broadband-usage.component';
import { PermissionService } from './services/permissions.service';
import { AddEditRoleComponent } from './components/service-hub/company/roles/add-edit-role/add-edit-role.component';
import { ServicePoliciesComponent } from './components/service-hub/company/service-policies/service-policies.component';
import { TelephonyService } from './components/service-hub/products-and-services/telephony/shared/telephony-service.service';
import { DeleteDnsComponent } from './components/misc/pop-up/delete-dns/delete-dns.component';
import { ServiceNotificationsDialogComponent } from './components/misc/pop-up/service-notifications-dialog/service-notifications-dialog.component';
import { ServiceNotificationsService } from './components/service-hub/products-and-services/services/status/shared/service-notifications-service.service';
import { AccountService } from './components/service-hub/account/shared/services/account.service';
import { UserDetailsDialogComponent } from './components/misc/pop-up/user-details-dialog/user-details-dialog.component';
import { CloudHostingComponent } from './components/service-hub/billing/cloud-hosting/cloud-hosting.component';
import { ConnectivityComponent } from './components/service-hub/billing/connectivity/connectivity.component';
import { UCCComponent } from './components/service-hub/billing/ucc/ucc.component';
import { SecurityComponent } from './components/service-hub/billing/security/security.component';
import { ServicesComponent } from './components/service-hub/billing/services/services.component';
import { ProductService } from './components/service-hub/billing/shared/product.service';
import { ViewFilesComponent } from './components/misc/pop-up/view-files/view-files.component';
import { ImpersonateUserComponent } from './components/misc/pop-up/impersonate-user/impersonate-user.component';
import { SupportContactsComponent } from './components/service-hub/misc/support-contacts/support-contacts.component';
import { SupportArticlesComponent } from './components/service-hub/misc/support-articles/support-articles.component';
import { SupportCategoryComponent } from './components/service-hub/misc/support-articles/support-category/support-category.component';
import { RemoveContactComponent } from './components/misc/pop-up/remove-contact/remove-contact.component';
import { NotificationTableComponent } from './components/service-hub/notifications/notification-table/notification-table.component';
import { LoadingSpinnerComponent } from './components/misc/loading-spinner/loading-spinner.component';
import { EChartPieComponent } from './components/misc/graph/e-chart-pie/e-chart-pie.component';
import { CostcentresUsersComponent } from './components/service-hub/billing/costcentres-users/costcentres-users.component';
import { DeveloperPageRestrictionsComponent } from './components/service-hub/account/developers/page-restrictions/page-restrictions.component';
import { AmendCostcentresUsersComponent } from './components/service-hub/billing/costcentres-users/amend-costcentres-users/amend-costcentres-users/amend-costcentres-users.component';
import { EditMultiBarComponent } from './components/service-hub/products-and-services/telephony/data/edit-multi-bar/edit-multi-bar.component';
import { ViewNumbersComponent } from './components/misc/pop-up/view-numbers/view-numbers.component';
import { TicketsReportingComponent } from './components/service-hub/tickets/tickets-reporting/tickets-reporting.component';
import { TicketConfirmationComponent } from './components/misc/pop-up/ticket-confirmation/ticket-confirmation.component';
import { LocationDialogComponent } from './components/service-hub/tickets/raise/location-dialog/location-dialog.component';
import { CredentialConfirmComponent } from './components/service-hub/developer/credential-confirm/credential-confirm.component';
import { AuthModule } from '@auth0/auth0-angular';
import { EditCostCentreComponent } from './components/misc/pop-up/edit-cost-centre/edit-cost-centre.component';
import { EditCostUserComponent } from './components/misc/pop-up/edit-cost-user/edit-cost-user.component';
import { AmendCostcentresGroupComponent } from './components/service-hub/billing/costcentres-users/amend-costcentres-group/amend-costcentres-group.component';
import { ApiRequest } from './helpers/api.request.helper';
import { AbzordService } from './services/api/abzord/abzord.service';
import { AuthenticationService } from './services/api/authentication/authentication.service';
import { BillingEntityService } from './services/api/billing-entity/billing-entity.service';
import { CallService } from './services/api/call/call.service';
import { ChangeRequestService } from './services/api/change-request/change-request.service';
import { CommentAPIService } from './services/api/comment/comment.service';
import { CompanyAPIService } from './services/api/company/company.service';
import { CookieService } from './services/api/cookie/cookie.service';
import { DeveloperService } from './services/api/developer/developer.service';
import { DomainService } from './services/api/domain/domain.service';
import { ExportApiService } from './services/api/export-api/export-api.service';
import { FileService } from './services/api/file/file.service';
import { HelpService } from './services/api/help/help.service';
import { HelpArticleService } from './services/api/help-article/help-article.service';
import { IncidentService } from './services/api/incident/incident.service';
import { InvoiceService } from './services/api/invoice/invoice.service';
import { KnowledgeService } from './services/api/knowledge/knowledge.service';
import { LocationService } from './services/api/location/location.service';
import { LogsService } from './services/api/logs/logs.service';
import { MiscService } from './services/api/misc/misc.service';
import { NospService } from './services/api/nosp/nosp.service';
import { NotificationsService } from './services/api/notifications/notifications.service';
import { OrderService } from './services/api/order/order.service';
import { OutageService } from './services/api/outage/outage.service';
import { PermissionsService } from './services/api/permissions/permissions.service';
import { ProductAPIService } from './services/api/product/product.service';
import { RadiusService } from './services/api/radius/radius.service';
import { ReleaseNotesService } from './services/api/release-notes/release-notes.service';
import { RequestedItemsService } from './services/api/requested-items/requested-items.service';
import { ScienceLogicService } from './services/api/science-logic/science-logic.service';
import { SearchService } from './services/api/search/search.service';
import { SurveyService } from './services/api/survey/survey.service';
import { TelephonyApiService } from './services/api/telephony-api/telephony-api.service';
import { TicketsService } from './services/api/tickets/tickets.service';
import { UnityService } from './services/api/unity/unity.service';
import { UserService } from './services/api/user/user.service';
import { ValidationService } from './services/api/validation/validation.service';
import { AuthService } from './services/auth/auth.service';
import { CustomerPortalApiInterceptor } from './interceptors/customer-portal-api/customer-portal-api.interceptor';
import { Location } from '@angular/common';
import { GenericErrorComponent } from './components/global/errors/generic-error/generic-error.component';
import { EBarChartComponent } from './components/misc/graph/e-bar-chart/e-bar-chart.component';
import { ELineGraphComponent } from './components/misc/graph/e-line-graph/e-line-graph.component';
import { CommentsComponent } from './components/misc/comments/comments.component';
import { DashboardServiceStatusComponent } from './components/misc/dashboard/dashboard-service-status/dashboard-service-status.component';
import { ChangeRejectCommentComponent } from './components/misc/pop-up/change-reject-comment/change-reject-comment.component';
// Prime NG
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TabMenuModule } from 'primeng/tabmenu';
import { ChipModule } from 'primeng/chip';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { RoleInformationDialogComponent } from 'src/app/components/misc/pop-up/role-information/role-information.component';
import { CreateWebHookComponent } from './components/misc/pop-up/create-web-hook/create-web-hook.component';
import { environment } from 'src/environments/environment';
import { TicketsChartComponent } from './components/misc/graph/incident-chart/tickets-chart.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { OverlayModule } from 'primeng/overlay';
import { FilterDropdownPrimeComponent } from './components/misc/pop-up/filters/filter-dropdown/filter-dropdown.component';
import { AddEditFiltersComponent } from './components/misc/pop-up/filters/filter-dropdown/prime-ng-filters/add-edit-filters/add-edit-filters.component';
import { DropdownModule } from 'primeng/dropdown';
import { TagModule } from 'primeng/tag';
import { MenuModule } from 'primeng/menu';
import { MegaMenuModule } from 'primeng/megamenu';
import { StepsModule } from 'primeng/steps';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextModule } from 'primeng/inputtext';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { DomainAdminComponent } from './components/service-hub/products-and-services/domains/domain-admin/domain-admin.component';
import { DomainChipComponent } from './components/service-hub/products-and-services/domains/domain-chip/domain-chip.component';
import { ManageRoute53HostedzoneComponent } from './components/misc/pop-up/manage-route53-hostedzone/manage-route53-hostedzone.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { PaginatorModule } from 'primeng/paginator';
import { ScienceLogicVisualizationComponent } from './components/service-hub/products-and-services/science-logic/science-logic-visualization/science-logic-visualization.component';
import { AvatarModule } from 'primeng/avatar';
import { CardModule } from 'primeng/card';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { HumanisePipe } from './pipes/humanise.pipe';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { PasswordModule } from 'primeng/password';
import { DividerModule } from 'primeng/divider';
import { FileUploadModule } from 'primeng/fileupload';
import { BadgeModule } from 'primeng/badge';
import { MessageService } from 'primeng/api';
import { AccordionModule } from 'primeng/accordion';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TooltipModule } from 'primeng/tooltip';
import { FloatLabelModule } from 'primeng/floatlabel';

import { AddEditDNSRecordComponent } from './components/misc/pop-up/add-edit-dns-record/add-edit-dns-record.component';
import { ExportPdfComponent } from './components/misc/pop-up/export-pdf/export-pdf.component';
import { DateRangeSelectorComponent } from './components/misc/pop-up/filters/filter-dropdown/date-range-selecter/date-range-selector.component';
import { AddEditUsersComponent } from './components/service-hub/company/users/add-edit-users/add-edit-users.component';
import { ContactsService } from './services/api/contacts/contacts.service';
import { EditUserPermissionsComponent } from './components/misc/pop-up/edit-user-permissions/edit-user-permissions.component';
import { DigitalSpaceTabMenuComponent } from './components/misc/digital-space-tab-menu/digital-space-tab-menu.component';
import { DashboardStatisticCardComponent } from './components/misc/dashboard/dashboard-statistic-card/dashboard-statistic-card.component';
import { DashboardMonthCompareComponent } from './components/misc/dashboard/dashboard-month-compare/dashboard-month-compare.component';
import { OrderLineItemComponent } from './components/service-hub/tickets/orders/order-line-single/order-line-item.component';
import { CaseComponent } from './components/service-hub/tickets/case/case.component';
import { OrderCharacteristicsComponent } from './components/misc/order-characteristics/order-characteristics.component';
import { OrderInternalInformationComponent } from './components/misc/order-internal-information/order-internal-information.component';
import { IdentifyIncidentIssueComponent } from './components/service-hub/tickets/raise/identify-incident-issue/identify-incident-issue.component';
import { ExpediteIncidentComponent } from './components/misc/pop-up/expedite-incident/expedite-incident.component';
import { TicketItemDisplayComponent } from './components/misc/tickets/ticket-item-display/ticket-item-display/ticket-item-display.component';
import { OrderSingleComponent } from './components/service-hub/tickets/orders/order-single/order-single.component';
import { EscalateIncidentComponent } from './components/misc/pop-up/escalate-incident/escalate-incident.component';
import { ChangeRequestDetailsComponent } from './components/service-hub/tickets/change-requests/change-request-details/change-request-details.component';
import { ServiceStatusWidgetComponent } from './components/misc/dashboard/service-status-widget/service-status-widget.component';
import { ServiceMonitorComponent } from './components/service-hub/products-and-services/services/status/service-monitor/service-monitor.component';
import { ServiceRequestOptionsComponent } from './components/service-hub/tickets/raise/service-request-options/service-request-options.component';
import { MobileServiceRequestComponent } from './components/service-hub/tickets/raise/mobile-service-request/mobile-service-request.component';
import { SubmitServiceRequestComponent } from './components/service-hub/tickets/raise/submit-service-request/submit-service-request.component';
import { GeneralServiceRequestComponent } from './components/service-hub/tickets/raise/general-service-request/general-service-request.component';
import { ServiceCatalogComponent } from './components/service-hub/tickets/raise/service-catalog/service-catalog.component';
import { ServiceCatalogItemComponent } from './components/service-hub/tickets/raise/service-catalog-item/service-catalog-item.component';

@NgModule({
  declarations: [
    AppComponent,
    MenuInfoComponent,
    DashboardComponent,
    CmdbDevicesComponent,
    PermissionDirective,
    TicketsComponent,
    IncidentsComponent,
    EscapeHtmlPipe,
    ServiceRequestsComponent,
    OrdersComponent,
    BillingOverviewComponent,
    InvoicesComponent,
    CreditNotesComponent,
    BillingUsageComponent,
    BroadbandComponent,
    DomainsComponent,
    ServiceStatusComponent,
    UsersComponent,
    ContactsComponent,
    AddContactComponent,
    CredentialsComponent,
    ShowApiCredentialsComponent,
    GenerateApiCredentialsComponent,
    WebHooksComponent,
    ConfigureWebHookComponent,
    AccountComponent,
    AdminsComponent,
    NotificationsComponent,
    DeviceHistoryComponent,
    RaiseTicketComponent,
    AddCommentComponent,
    FileManagerComponent,
    SingleServiceRequestComponent,
    ServiceHubGraphComponent,
    DomainComponent,
    AddEditDNSRecordComponent,
    ServiceInformationComponent,
    InvoiceOnlineViewComponent,
    LogoutComponent,
    ChangePasswordComponent,
    MiscNotificationComponent,
    SearchComponent,
    NotFoundComponent,
    NotAuthorisedComponent,
    SubmitSurveyComponent,
    FilterComponent,
    DatePickerDirective,
    EqualValidator,
    DownloadExportComponent,
    TelephonyComponent,
    TelephonyDataComponent,
    ProgressCircle,
    SupportComponent,
    KnowledgeArticleComponent,
    ScienceLogicComponent,
    ScienceLogicGraphReportingComponent,
    ScienceLogicGraphServersReportingComponent,
    LoginHistoryComponent,
    DeviceHistoryComponent,
    ReleaseNotesComponent,
    ReleaseNotesNotificationComponent,
    CmdbDevicesComponent,
    ChangeRequestsComponent,
    SingleChangeRequestComponent,

    RestrictedComponent,
    DeveloperPageLandingComponent,
    UpgradeBroadbandComponent,
    InformationDetailsComponent,
    KnowledgeArticleDetailsComponent,
    UserActivityComponent,
    ExportPdfComponent,
    DateRangeSelectorComponent,
    CommentsComponent,
    CaseComponent,
    //CompanyActivityComponent,
    UsageInformationComponent,
    EditBarsComponent,
    CookiesPolicyComponent,
    CreateServiceRequestComponent,
    CreateIncidentComponent,
    AuthorisationDialogComponent,
    DeviceServerTableComponent,
    FileDragDirective,
    AddEditUserComponent,
    AddEditUserDetailsComponent,
    AddEditUserReviewComponent,
    AddEditUserRoleComponent,
    AddEditUserPermissionsComponent,
    AddEditUserAdditionalComponent,
    RoleManagementComponent,
    RoleInformationComponent,
    ReplaceUnderscorePipe,
    ReplacePdfPipe,
    BroadbandUsageComponent,
    AddEditRoleComponent,
    ServicePoliciesComponent,
    DeleteDnsComponent,
    ServiceNotificationsDialogComponent,
    UserDetailsDialogComponent,
    CloudHostingComponent,
    ConnectivityComponent,
    UCCComponent,
    SecurityComponent,
    ServicesComponent,
    ViewFilesComponent,
    ImpersonateUserComponent,
    SupportContactsComponent,
    SupportArticlesComponent,
    SupportCategoryComponent,
    RemoveContactComponent,
    NotificationTableComponent,
    EChartPieComponent,
    EBarChartComponent,
    ELineGraphComponent,
    CostcentresUsersComponent,
    DeveloperPageRestrictionsComponent,
    AmendCostcentresUsersComponent,
    EditMultiBarComponent,
    ViewNumbersComponent,
    TicketsReportingComponent,
    TicketConfirmationComponent,
    LocationDialogComponent,
    CredentialConfirmComponent,
    EditCostCentreComponent,
    EditCostUserComponent,
    AmendCostcentresGroupComponent,
    GenericErrorComponent,
    RoleInformationDialogComponent,
    CreateWebHookComponent,
    TicketsChartComponent,
    DomainAdminComponent,
    DomainChipComponent,
    ManageRoute53HostedzoneComponent,
    ScienceLogicVisualizationComponent,
    ExportPdfComponent,
    AddEditUsersComponent,
    EditUserPermissionsComponent,
    FilterDropdownPrimeComponent,
    HumanisePipe,
    SafeHtmlPipe,
    AddEditFiltersComponent,
    DigitalSpaceTabMenuComponent,
    DashboardStatisticCardComponent,
    DashboardMonthCompareComponent,
    OrderLineItemComponent,
    OrderCharacteristicsComponent,
    OrderInternalInformationComponent,
    IdentifyIncidentIssueComponent,
    ExpediteIncidentComponent,
    TicketItemDisplayComponent,
    OrderSingleComponent,
    SingleIncidentComponent,
    EscalateIncidentComponent,
    ChangeRequestDetailsComponent,
    DashboardServiceStatusComponent,
    ServiceStatusWidgetComponent,
    ChangeRejectCommentComponent,
    ServiceMonitorComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routing, { enableTracing: false }),
    NgCircleProgressModule.forRoot(),
    NgxPaginationModule,
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      authorizationParams: {
        redirect_uri: window.location.origin,
        scope: 'openid profile email',
      },
    }),
    // Prime NG
    ButtonModule,
    MultiSelectModule,
    TableModule,
    SplitButtonModule,
    TabMenuModule,
    ChipModule,
    CalendarModule,
    CheckboxModule,
    OverlayModule,
    DropdownModule,
    DynamicDialogModule,
    TagModule,
    MenuModule,
    MegaMenuModule,
    StepsModule,
    RadioButtonModule,
    InputTextModule,
    DropdownModule,
    MessagesModule,
    ToastModule,
    TagModule,
    InputSwitchModule,
    PaginatorModule,
    AvatarModule,
    OverlayPanelModule,
    CardModule,
    BreadcrumbModule,
    TabViewModule,
    ToggleButtonModule,
    PasswordModule,
    DividerModule,
    FileUploadModule,
    BadgeModule,
    StepsModule,
    AccordionModule,
    SelectButtonModule,
    TooltipModule,
    FloatLabelModule,
    //Components
    ServiceRequestOptionsComponent,
    MobileServiceRequestComponent,
    SubmitServiceRequestComponent,
    GeneralServiceRequestComponent,
    LoadingSpinnerComponent,
    FileUploadComponent,
    ServiceCatalogComponent,
    ServiceCatalogItemComponent,
    ServiceHubComponent,
  ],
  exports: [RouterModule],
  providers: [
    ExportService,
    FilterService,
    DictionaryService,
    UtilService,
    BlockerService,
    NotificationHelper,
    NotificationService,
    StorageService,
    CookieService,
    RestrictionService,
    DatePipe,
    PermissionService,
    ReplaceUnderscorePipe,
    ReplacePdfPipe,
    TelephonyService,
    ServiceNotificationsService,
    AccountService,
    ProductService,
    // API Services
    AbzordService,
    AuthenticationService,
    BillingEntityService,
    CallService,
    ChangeRequestService,
    CommentAPIService,
    CompanyAPIService,
    CookieService,
    DeveloperService,
    DomainService,
    ExportApiService,
    FileService,
    HelpService,
    HelpArticleService,
    IncidentService,
    InvoiceService,
    KnowledgeService,
    LocationService,
    LogsService,
    MiscService,
    NospService,
    NotificationsService,
    OrderService,
    OutageService,
    PermissionsService,
    ProductAPIService,
    RadiusService,
    ReleaseNotesService,
    RequestedItemsService,
    ScienceLogicService,
    SearchService,
    SurveyService,
    TelephonyApiService,
    TicketsService,
    UnityService,
    UserService,
    ValidationService,
    AuthService,
    ContactsService,
    Location,
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomerPortalApiInterceptor,
      multi: true,
    },
    // End API Services
    {
      provide: ApiRequest,
      deps: [HttpClient, Router, Location, DOCUMENT],
    },
    MessageService,
    DialogService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
