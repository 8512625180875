import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ServiceNotificationsService } from 'src/app/components/service-hub/products-and-services/services/status/shared/service-notifications-service.service';
import { MessageService } from 'primeng/api';
import { ServiceNotification } from 'src/app/models/outage/outage.models';
import { ToastService, ToastType } from 'src/app/services/global/toast.service';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'app-service-notifications-dialog',
  templateUrl: './service-notifications-dialog.component.html',
  styleUrls: ['./service-notifications-dialog.component.scss'],
  providers: [MessageService],
})
export class ServiceNotificationsDialogComponent implements OnInit {
  checked = false;

  loading = false;
  serviceNotifications: ServiceNotification[];
  serviceNotificationsOriginal: ServiceNotification[];

  constructor(
    public serviceNotificationsService: ServiceNotificationsService,
    public ref: DynamicDialogRef,
    private toastService: ToastService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadServiceNotifications();
  }

  async loadServiceNotifications() {
    this.loading = true;
    this.serviceNotifications =
      await this.serviceNotificationsService.loadServiceNotifications();
    this.serviceNotificationsOriginal = cloneDeep(this.serviceNotifications);
    this.loading = false;
  }

  async saveSubscriptions() {
    this.loading = true;
    const subscriptionsToUpdate: ServiceNotification[] =
      this.getChangedNotifications(
        this.serviceNotificationsOriginal,
        this.serviceNotifications
      );
    const updatePromises = subscriptionsToUpdate.map(subscription =>
      this.serviceNotificationsService.toggleServiceSubscription(subscription)
    );
    await Promise.all(updatePromises);
    await this.loadServiceNotifications();
    this.toastService.add({
      summary: 'Notification Settings',
      detail: `Changes saved`,
      severity: ToastType.success,
    });
    this.ref.close();
  }

  getChangedNotifications(
    savedNotifications: ServiceNotification[],
    updatedNotifications: ServiceNotification[]
  ): ServiceNotification[] {
    const savedMap = new Map<string, ServiceNotification>(
      savedNotifications.map(notification => [notification.sysId, notification])
    );
    const changedNotifications = updatedNotifications.filter(updated => {
      const saved = savedMap.get(updated.sysId);
      return saved && updated.subscribed !== saved.subscribed;
    });
    return changedNotifications;
  }

  cancelButton() {
    this.toastService.add({
      summary: 'Notification Settings',
      detail: `Operation cancelled`,
      severity: ToastType.warning,
    });
    this.ref.close();
  }
}
