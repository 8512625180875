<div class="flex items-center gap-2">
  <h3 class="text-gray-600 text-base m-0 mb-1">{{ label }}</h3>
  @if (chip) {
    <p-chip label="Gold Location" styleClass="p-chip-gold p-chip-small" />
  }
</div>
<p
  class="text-lg m-0 max-h-14rem overflow-auto word-break-break-word white-space-pre-line">
  {{ value }}
  @if (tooltip) {
    <i
      [pTooltip]="tooltip"
      class="pi pi-info-circle hover:text-primary"
      tooltipPosition="bottom"></i>
  }
</p>
