<p-accordion>
  <p-accordionTab>
    <ng-template pTemplate="header">
      <h1 class="font-bold white-space-nowrap text-xl m-0 p-0">
        Order Characteristics List
      </h1>
    </ng-template>
    <p-table
      #dataTable
      class="p-datatable-equal-width"
      [value]="orderCharacteristics$ | async"
      [columns]="cols"
      styleClass="p-datatable-striped"
      sortMode="single"
      scrollHeight="flex"
      [scrollable]="true">
      <ng-template pTemplate="header" let-columns>
        <tr>
          @for (col of columns; track col) {
            <th
              class="text-white bg-gray-800 p-datatable-header-first-row">
              {{ col.header }}
            </th>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <tr>
          <td [attr.colspan]="cols?.length + 1">
            <loading-spinner></loading-spinner>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingicon"></ng-template>
      <ng-template pTemplate="body" let-order let-columns="columns">
        <tr>
          @for (col of columns; track col) {
            <td>
              @if (col.display?.type === 'text') {
                {{ order[col.field] }}
              }
            </td>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="cols?.length + 1">No results were found.</td>
        </tr>
      </ng-template>
    </p-table>
  </p-accordionTab>
</p-accordion>
