@if (!sites) {
  <loading-spinner />
}

@if (sites) {
  <div class="flex flex-column h-full">
    <div
      class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200">
      <div class="flex align-items-center px-3">
        <h1 class="text-xl mr-3">Billing Overview</h1>
        <p-floatLabel>
          <p-dropdown
            [(ngModel)]="selectedSite"
            [options]="sites"
            optionLabel="name"
            [disabled]="loading">
          </p-dropdown>
          <label for="float-label">Account</label>
          <p-button
            styleClass="p-button"
            (onClick)="setSelectedSite()"
            label="Search"
            [disabled]="loading"></p-button>
        </p-floatLabel>
        @if (canEditCostCenters) {
          <p-button
            (onClick)="goToCostCentresAndUsers()"
            icon="pi pi-users"
            label="Cost Centre & Users"
            [disabled]="loading">
          </p-button>
        }
      </div>
    </div>
    <div
      class="flex-grow-1 overflow-auto mx-3 overflow-hidden bg-white border-solid border-1 border-gray-200 border-top-none border-bottom-none px-3">
      <div class="h-full flex flex-column">
        <h1 class="text-xl">{{ selectedSite?.name }}</h1>
        <p-tabMenu
          #tab
          [model]="menuItems"
          [activeItem]="activeMenuItem"
          (activeItemChange)="changeTab($event)">
        </p-tabMenu>
        @if (activeMenuItem.label === productCategories?.Cloud) {
          <app-cloud-hosting
            #cloud
            [selectedSite$]="selectedSiteId$"
            (loadingChange)="onLoadingStateChange($event)"></app-cloud-hosting>
        }
        @if (activeMenuItem.label === productCategories?.Connectivity) {
          <app-connectivity
            #connectivity
            [selectedSite$]="selectedSiteId$"
            (loadingChange)="onLoadingStateChange($event)"></app-connectivity>
        }
        @if (activeMenuItem.label === productCategories?.Services) {
          <app-services
            #services
            [selectedSite$]="selectedSiteId$"
            (loadingChange)="onLoadingStateChange($event)"></app-services>
        }
        @if (activeMenuItem.label === productCategories?.Security) {
          <app-security
            #security
            [selectedSite$]="selectedSiteId$"
            (loadingChange)="onLoadingStateChange($event)"></app-security>
        }
        @if (activeMenuItem.label === productCategories?.UCC) {
          <app-ucc
            #ucc
            [selectedSite$]="selectedSiteId$"
            (loadingChange)="onLoadingStateChange($event)"></app-ucc>
        }
      </div>
    </div>
  </div>
}
