import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { RequestedItemsService } from 'src/app/services/api/requested-items/requested-items.service';
import { ServiceRequestService } from 'src/app/services/api/service-request/service-request.service';

@Component({
  selector: 'upgrade-broadband',
  templateUrl: 'upgrade-broadband.component.html',
  styleUrls: ['upgrade-broadband.component.scss'],
})
export class UpgradeBroadbandComponent implements OnInit {
  upgradeType: String = '';
  name: String = '';
  reference: String = '';
  requestRaised: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private requestedItemsService: RequestedItemsService,
    private serviceRequestService: ServiceRequestService
  ) {}
  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.upgradeType = params['type'];
      this.name = params['id'];
      this.raiseRequest(this.name, this.upgradeType);
    });
  }

  async raiseRequest(radiusAccountName: String, type: String) {
    let catalogVariables = await firstValueFrom(
      this.requestedItemsService.getRequestItemVariablesFull()
    );
    let details = 'Name: ' + radiusAccountName + ';' + ' Upgrade type: ' + type;
    catalogVariables[13].value = details;
    let request = {
      catalogId: '2afad9fddb320b004686f7461d961998',
      variables: catalogVariables,
    };

    const response = await firstValueFrom(
      this.serviceRequestService.create(request)
    );

    this.reference = response.number;
    this.requestRaised = true;
  }
}
