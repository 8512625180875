export interface IServiceColumn {
  field?: string;
  header?: string;
  default: boolean;
  display?: any;
  filter?: {
    type: string;
    values: { label: string; value: string }[];
  };
  checkBoxVisible?: boolean;
  showSortIcon: boolean;
  template?: boolean;
}

export const IServiceColumns: IServiceColumn[] = [
  {
    field: 'name',
    header: 'Policy Name',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
  },
];
