@if (loading) {
  <loading-spinner />
}
@if (!loading && !messages?.length) {
  <div class="flex flex-column">
    @if (pageMessages?.length) {
      <div class="mx-3">
        <p-messages [(value)]="pageMessages" />
      </div>
    }
    <div
      class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200 border-bottom-1">
      <h1 class="px-3 text-xl flex justify-content-between align-items-center">
        Incident - {{ incident?.number }}
        <span class="pl-1">
          <p-chip
            [label]="incident?.state"
            [styleClass]="'p-chip-' + getColor(incident?.state)">
          </p-chip>
        </span>
      </h1>
      <div class="pr-3 flex">
        <span
          class="block"
          pTooltip="Adding attachments is not allowed while impersonating"
          tooltipPosition="bottom"
          [tooltipDisabled]="!isImpersonating"
          appendTo="body">
          <p-button
            icon="pi pi-paperclip"
            label="Attachment"
            [disabled]="
              incident?.state === 'Resolved' ||
              incident?.state === 'Closed' ||
              !canUpdateTicket ||
              isImpersonating
            "
            (onClick)="openFileManager()"></p-button>
        </span>
        <p-button
          label="Resolve"
          styleClass="p-button-third"
          icon="pi pi-check-circle"
          (onClick)="openConfirmation(true)"
          [hidden]="incident?.state === 'Resolved'"
          [disabled]="
            !canUpdateTicket || incident?.state === 'Closed'
          "></p-button>
        <p-button
          styleClass="p-button-third"
          [hidden]="incident?.state !== 'Resolved'"
          label="Re-open"
          icon="pi pi-check-circle"
          (onClick)="openConfirmation(false)"
          [disabled]="!canUpdateTicket"></p-button>
        @if (canExpediteIncident) {
          <span
            class="block"
            pTooltip="Expedite incident not available while impersonating"
            tooltipPosition="bottom"
            [tooltipDisabled]="!isImpersonating">
            <p-button
              [disabled]="
                incident?.state === 'Resolved' ||
                incident?.state === 'Closed' ||
                isImpersonating
              "
              label="Expedite"
              icon="pi pi-bolt"
              (onClick)="openExpediteIncident()"></p-button>
          </span>
        }
        @if (canEscalateIncident) {
          <span
            pTooltip="Escalate incident not available while impersonating"
            tooltipPosition="bottom"
            [tooltipDisabled]="!isImpersonating">
            <p-button
              [disabled]="
                incident?.state === 'Resolved' ||
                incident?.state === 'Closed' ||
                isImpersonating
              "
              label="Escalate"
              icon="pi pi-arrow-up"
              (onClick)="openEscalateIncident()"></p-button>
          </span>
        }
      </div>
    </div>
    <div
      class="mx-3 overflow-hidden bg-white border-solid border-1 border-gray-200 border-top-none">
      <div class="flex bg-white flex-wrap">
        <div class="col-12 md:col-6 flex flex-wrap align-content-start">
          <div
            class="col-12 border-none border-bottom-1 border-solid border-gray-200">
            <h2 class="text-gray-600 text-base m-0 mb-1">Subject</h2>
            <p class="text-lg m-0">
              {{ incident?.shortDescription }}
            </p>
          </div>
          <div class="col-12 md:col-6 flex flex-wrap pl-0 align-content-start">
            <app-ticket-item-display
              [ngClass]="
                getTicketItemDisplayContainerClasses({
                  border: false,
                })
              "
              [label]="'Request For'"
              [value]="incident?.callerName || '&zwnj;'">
            </app-ticket-item-display>
            <app-ticket-item-display
              [ngClass]="
                getTicketItemDisplayContainerClasses({
                  border: true,
                })
              "
              [label]="'Priority'"
              [value]="incident?.priority"></app-ticket-item-display>
            <app-ticket-item-display
              [ngClass]="
                getTicketItemDisplayContainerClasses({
                  border: true,
                })
              "
              [label]="'Service'"
              [value]="incident?.serviceComponent"></app-ticket-item-display>
            <app-ticket-item-display
              [ngClass]="
                getTicketItemDisplayContainerClasses({
                  border: true,
                  borderBottom: true,
                })
              "
              [label]="'Location'"
              [chip]="incident?.attributes.goldLocation"
              [value]="
                incident?.location || '&zwnj;'
              "></app-ticket-item-display>
          </div>
          <div class="col-12 md:col-6 flex flex-wrap pl-0 align-content-start">
            <app-ticket-item-display
              [ngClass]="
                getTicketItemDisplayContainerClasses({
                  border: false,
                })
              "
              [label]="'Opened At'"
              [value]="incident?.sysCreatedOn"></app-ticket-item-display>
            <app-ticket-item-display
              [ngClass]="
                getTicketItemDisplayContainerClasses({
                  border: true,
                })
              "
              [label]="'Target Time Remaining'"
              [value]="incident?.businessTimeLeft || 'N/A'"
              [tooltip]="
                incident?.schedule
                  ? 'Working Hours - ' + incident.schedule
                  : null
              "></app-ticket-item-display>
            <app-ticket-item-display
              [ngClass]="
                getTicketItemDisplayContainerClasses({
                  border: true,
                })
              "
              [label]="'Symptom'"
              [value]="incident?.symptom"></app-ticket-item-display>
            <app-ticket-item-display
              [ngClass]="
                getTicketItemDisplayContainerClasses({
                  border: true,
                  borderBottom: true,
                })
              "
              [label]="'Configuration Item'"
              [value]="incident?.cmdbCi || '&zwnj;'"></app-ticket-item-display>
          </div>
        </div>
        <div class="col-12 md:col-6 flex flex-wrap align-content-start">
          <app-ticket-item-display
            [ngClass]="
              getTicketItemDisplayContainerClasses({
                border: false,
              })
            "
            [label]="'Description'"
            [value]="incident?.description"></app-ticket-item-display>
          <app-ticket-item-display
            [ngClass]="
              getTicketItemDisplayContainerClasses({
                border: true,
              })
            "
            [label]="'Attachments'"></app-ticket-item-display>
          @for (file of attachmentFiles | slice: 0 : 3; track file) {
            <ng-container class="pt-1">
              <div class="inline-flex">
                <a
                  class="hover:text-primary m-1 pl-1 text-lg text-gray-900 font-bold cursor-pointer"
                  (keyup.enter)="fileManagerService.downloadFile($event, file)"
                  role="button"
                  tabindex="0"
                  (click)="fileManagerService.downloadFile($event, file)"
                  ><i
                    class="text-lg mr-1 pi {{
                      fileManagerService.setFileIconPrimeIcon(file)
                    }}"
                    ><span class="ml-1">{{ file.fileName }}</span></i
                  ></a
                >
              </div>
            </ng-container>
          }
          @if (attachmentFiles?.length > 3) {
            <p-badge
              class="cursor-pointer"
              severity="success"
              (click)="openAttachments()"
              value="{{ attachmentFiles?.length - 3 }}"></p-badge>
          }
        </div>
      </div>
    </div>
  </div>
}

@if (!loading && !messages?.length) {
  <div class="pb-5 pt-2">
    <app-comments
      [comments$]="comments$"
      (addCommentEvent)="createComment($event)"></app-comments>
  </div>
}

@if (messages?.length) {
  <div class="text-center">
    <p-messages [(value)]="messages" />
    <p-button
      label="Return to Incidents"
      routerLink="/secure/tickets/incidents"></p-button>
  </div>
}
