<circle-progress
  [percent]="percent"
  [title]="title"
  [subtitle]="subtitle"
  [radius]="radius"
  [outerStrokeWidth]="outerStrokeWidth"
  [innerStrokeWidth]="innerStrokeWidth"
  [outerStrokeColor]="outerStrokeColor"
  [innerStrokeColor]="innerStrokeColor"
  [animation]="true"
  [animationDuration]="300"
  space="-10"
  [showUnits]="showUnits"></circle-progress>
