import { Inject, Injectable } from '@angular/core';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';

@Injectable({
  providedIn: 'root',
})
export class CallService {
  constructor(private apiRequest: ApiRequest) {}

  createCall(body: any) {
    const requestProps: IRequestOptions = {
      endpoint: 'calls/createCall',
      body: body,
    };
    return this.apiRequest.request(requestProps);
  }
}
