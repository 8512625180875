import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replacePdf',
})
export class ReplacePdfPipe implements PipeTransform {
  transform(value: string): string {
    return value ? value.replace(/\.[^/.]+$/, '') : value;
  }
}
