import { Injectable } from '@angular/core';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';
import {
  IServiceNowRoleCreate,
  IServiceNowRoleUpdate,
} from 'src/app/components/service-hub/company/roles/shared/role-models';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(private apiRequest: ApiRequest) {}

  getPermissions(filterColumn: any, value: any) {
    const requestProps: IRequestOptions = {
      endpoint: 'Permissions/GetPermissions',
      body: {
        Column: filterColumn,
        Value: value,
      },
    };

    return this.apiRequest.request(requestProps);
  }

  getPermissionsFromRoleCategorised(sysId: string) {
    const requestProps: IRequestOptions = {
      endpoint: 'Permissions/GetPermissionsFromRoleCategorised',
      body: sysId,
    };

    return this.apiRequest.request(requestProps);
  }

  createPortalRole(role: IServiceNowRoleCreate) {
    const requestProps: IRequestOptions = {
      endpoint: 'Permissions/CreatePortalRole',
      body: {
        UCompany: role.company,
        UName: role.name,
        UDescription: role.description,
        UTimicoPortalPermissions: role.permissions,
      },
    };

    return this.apiRequest.request(requestProps);
  }

  updatePortalRole(role: IServiceNowRoleUpdate) {
    const requestProps: IRequestOptions = {
      endpoint: 'Permissions/UpdatePortalRole',
      body: role,
    };

    return this.apiRequest.request(requestProps);
  }

  getGroupPortalPermissions(permissions) {
    const requestProps: IRequestOptions = {
      endpoint: 'Permissions/GroupPortalPermissions',
      body: permissions,
    };

    return this.apiRequest.request(requestProps);
  }

  getRole(roleID: any) {
    const requestProps: IRequestOptions = {
      endpoint: 'Permissions/GetRole',
      body: roleID,
    };

    return this.apiRequest.request(requestProps);
  }

  getMissingPermissionsFromQuery(filter: any) {
    const requestProps: IRequestOptions = {
      endpoint: 'Permissions/GetMissingPermissionsFromQuery',
      body: filter,
    };

    return this.apiRequest.request(requestProps);
  }

  getRoles() {
    const requestProps: IRequestOptions = {
      endpoint: 'Permissions/GetRoles',
      body: {},
    };

    return this.apiRequest.request(requestProps);
  }

  checkPortalAccess(filter: any) {
    const requestProps: IRequestOptions = {
      endpoint: 'Permissions/CheckPortalAccess',
      body: filter,
    };

    return this.apiRequest.request(requestProps);
  }

  getPortalPermissionsFromUser(filter: any) {
    const requestProps: IRequestOptions = {
      endpoint: 'Permissions/GetPortalPermissionsFromUser',
      body: filter,
    };

    return this.apiRequest.request(requestProps);
  }
}
