import { Component, OnInit } from '@angular/core';
import { SupportArticleFilters } from './shared/support-models';
import { cloneDeep } from 'lodash-es';
import { HelpService } from 'src/app/services/api/help/help.service';
import { firstValueFrom } from 'rxjs';
import { TabView } from 'primeng/tabview';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import { MenuItem } from 'primeng/api/menuitem';
import { SupportCategories } from 'src/app/models/support/support-articles.models';
@Component({
  selector: 'app-support-articles',
  templateUrl: './support-articles.component.html',
  styleUrls: ['./support-articles.component.scss'],
  providers: [TabView],
})
export class SupportArticlesComponent implements OnInit {
  supportService: SupportCategories[];
  filter = cloneDeep(SupportArticleFilters);
  supportDetails: SupportCategories;
  loading = false;

  constructor(
    private helpService: HelpService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    const breadcrumbs: MenuItem[] = [
      { label: ' Support', routerLink: '/support' },
      { label: 'Support Articles', routerLink: '/' },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.loadSupportDetails();
  }

  async loadSupportDetails(): Promise<void> {
    this.loading = true;
    this.supportDetails = await firstValueFrom(
      this.helpService.getSupportCategory()
    );
    this.loading = false;
  }
}
