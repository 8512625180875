<div class="flex flex-column h-full">
  <div
    class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200">
    <div class="flex align-items-center px-3">
      <h1 class="text-xl mr-3">Mobile</h1>
      <p-floatLabel>
        <p-dropdown
          [(ngModel)]="dropdownSelection"
          [options]="sites"
          optionLabel="name">
          ></p-dropdown
        >
        <label for="float-label">Account</label>
        <p-button
          styleClass="p-button"
          (onClick)="searchForSite()"
          label="Search">
        </p-button>
      </p-floatLabel>
    </div>
  </div>
  <div
    class="flex-grow-1 overflow-auto mx-3 overflow-hidden bg-white border-solid border-1 border-gray-200 border-top-none border-bottom-none px-3">
    <p-table
      #dataTable
      [value]="telephony$ | async"
      [columns]="selectedColumns"
      styleClass="p-datatable-striped"
      [rowHover]="true"
      sortMode="single"
      [rows]="25"
      [lazy]="true"
      [(selection)]="selectedNumbers"
      scrollHeight="flex"
      [scrollable]="true"
      (onLazyLoad)="loadMobilesForSite(dataTable, $event)">
      <ng-template pTemplate="caption">
        <h1 class="text-xl mr-3">{{ selectedSite?.name }}</h1>
        <div class="flex align-items-center justify-content-between">
          <div class="flex">
            @if (selectedColumns && dataTable && dataTable.loading === false) {
              <prime-filter-dropdown
                [selectedColumns]="cols"
                [table]="dataTable"></prime-filter-dropdown>
            }

            <p-multiSelect
              styleClass="p-multiselect-label-hide p-multiselect-border"
              [options]="cols"
              [(ngModel)]="selectedColumns"
              optionLabel="header"
              dropdownIcon="pi pi-sliders-h"
              placeholder="Choose Columns"
              selectedItemsLabel="{0} columns selected"
              (ngModelChange)="handleColumnChange(cols)"
              optionDisabled="disabled"></p-multiSelect>
            <p-button
              label="Reset"
              styleClass="p-button p-button-text p-button-secondary"
              icon="pi pi-eraser"
              (onClick)="reset(dataTable, false)"></p-button>
          </div>
          <div class="flex gap-1">
            @if (amendBars) {
              <p-button
                label="Amend Options"
                iconPos="left"
                icon="pi pi-pencil"
                (onClick)="onMultipleNumbersEdit()"
                appendTo="body"
                [disabled]="selectedNumbers.length === 0">
              </p-button>
            }
            <p-splitButton
              label="Export"
              icon="pi pi-download"
              iconPos="left"
              (onClick)="defaultExport()"
              [model]="exportOptions"
              styleClass="p-button"
              appendTo="body"></p-splitButton>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width: 4rem" class="bg-gray-800">
            <p-tableHeaderCheckbox />
          </th>
          @for (col of columns; track col) {
            <th
              class="text-white bg-gray-800 p-datatable-header-first-row"
              [pSortableColumn]="col.field">
              {{ col.header }}
              @if (col.showSortIcon) {
                <p-sortIcon class="text-white" [field]="col.field"></p-sortIcon>
              }
            </th>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <tr>
          <td [attr.colspan]="selectedColumns?.length + 1">
            <loading-spinner></loading-spinner>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingicon"></ng-template>
      <ng-template pTemplate="body" let-number let-columns="columns">
        <tr (click)="handleRowClicked(number)" class="cursor-pointer">
          <td (click)="$event.stopPropagation()">
            <p-tableCheckbox [value]="number" />
          </td>
          @for (col of columns; track col) {
            <td>
              @if (col.display?.type === 'text') {
                {{ number[col.field] }}
              }
            </td>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td [attr.colspan]="selectedColumns.length + 1" class="bg-white">
            <div class="flex align-items-center justify-content-center">
              <p-paginator
                #tablePaginator
                (onPageChange)="loadMobilesForSite(dataTable, $event)"
                [first]="dataTable.first"
                [rows]="dataTable.rows"
                [totalRecords]="totalRecords"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} - {last} of {totalRecords}"
                [rowsPerPageOptions]="[25, 50, 100]"></p-paginator>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="selectedColumns.length + 1">
            No results were found.
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
