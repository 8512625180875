<div class="flex flex-column p-3 m-3 shadow-1 surface-0 border-round-sm">
  <div class="flex align-items-center gap-3">
    <strong class="duration-time">Time Range:</strong>

    <p-button (onClick)="handleDurationChange('1d')" label="24 Hours">
    </p-button>
    <p-button (onClick)="handleDurationChange('7d')" label="7 Days"> </p-button>
    <p-button (onClick)="handleDurationChange('30d')" label="30 Days">
    </p-button>
    <p-button (onClick)="handleDurationChange('60d')" label="60 Days">
    </p-button>
    <p-button (onClick)="handleDurationChange('90d')" label="90 Days">
    </p-button>
  </div>
  <div #availabilityContainer class="mt-3">
    <p-tabView>
      <p-tabPanel header="Vitals">
        @if (loadingVitalGraphs) {
          <loading-spinner />
        }

        <!-- TODO: Replace with observability -->
        @if (!loadingVitalGraphs) {
          <div class="flex flex-column gap-3">
            <div>
              <h4>
                Availability -
                @if (percentageAvailable) {
                  {{ percentageAvailable }}%
                }
              </h4>
              <div>
                @for (point of availabilityPoints; track point; let i = $index) {
                  <div
                    [ngStyle]="{
                      width: availabilityWidth,
                      'background-color':
                        point.value == 1 ? 'green' : '#E31C79',
                    }"
                  style="height: 50px; display: inline-block"></div>
                }
              </div>
            </div>
            <servicehub-graph
              [data]="cpuData"
              [legend]="true"
            [onlyGraph]="true"></servicehub-graph>
            <servicehub-graph
              [data]="memoryData"
              [applyPadding]="true"
              [legend]="true"
            [onlyGraph]="true"></servicehub-graph>
          </div>
        }
      </p-tabPanel>
      <p-tabPanel header="Network & Storage">
        @if (loadingMiscGraphs) {
          <loading-spinner />
        }

        <!-- TODO: Replace with observability -->
        @if (!loadingMiscGraphs) {
          <h4 class="file-system-title">File System Information</h4>
          <p-table [value]="diskDrives">
            <ng-template pTemplate="header">
              <tr>
                <th>Drive Name</th>
                <th>Drive Full Capacity</th>
                <th>Drive Space Used</th>
                <th>Drive Percentage Used</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-drive>
              <tr>
                <td>{{ drive.driveInfo.name }}</td>
                <td>
                  {{ drive.driveFullCapacity }}
                  {{ drive.fullCapacityMeasurement }}
                </td>
                <td>
                  {{ drive.driveUsedCapacity }}
                  {{ drive.usedCapacityMeasurement }}
                </td>
                <td>{{ drive.driveInfo.percentUsed }}%</td>
              </tr>
            </ng-template>
          </p-table>
          <servicehub-graph
            [data]="networkData"
          [onlyGraph]="true"></servicehub-graph>
        }
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
