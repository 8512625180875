import { ServiceHubMenuItem } from './menu';

export const ProfileMenuItems: ServiceHubMenuItem[] = [
  {
    label: 'Account',
    routerLink: '/secure/account',
  },
  {
    label: 'Developer',
    routerLink: '/secure/account/developer',
  },
  {
    label: 'Impersonate',
  },
  {
    label: 'End Impersonation',
  },
  {
    label: 'Change Password',
    routerLink: '/secure/account/change-password',
  },
  {
    label: 'Release Notes',
    routerLink: '/secure/release-notes',
  },
  {
    label: 'Service Policies',
    routerLink: '/secure/company/service-policies',
  },
  {
    label: 'Logout',
    routerLink: '/logout',
  },
];
