import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IRequestOptions,
  ApiRequest,
} from 'src/app/helpers/api.request.helper';
import {
  GetServiceCatalogCategory,
  GetServiceCatalogVariablesResponse,
  ServiceCatalogOrderResponse,
} from 'src/app/models/service-catalog/service-catalog.models';

@Injectable({
  providedIn: 'root',
})
export class CatalogItemsService {
  constructor(private apiRequest: ApiRequest) {}
  GetServiceCatalogVariables(
    sysId: string
  ): Observable<GetServiceCatalogVariablesResponse[]> {
    const requestProps: IRequestOptions = {
      endpoint: `ServiceCatalog/GetServiceCatalogVariables`,
      body: sysId,
    };
    return this.apiRequest.request(requestProps);
  }

  CreateServiceCatalogOrder(
    order: any
  ): Observable<ServiceCatalogOrderResponse> {
    const requestProps: IRequestOptions = {
      endpoint: `ServiceCatalog/CreateServiceCatalogOrder`,
      body: order,
    };
    return this.apiRequest.request(requestProps);
  }

  CheckForOrderRequestItemTask(
    number: string
  ): Observable<ServiceCatalogOrderResponse> {
    const requestProps: IRequestOptions = {
      endpoint: `ServiceCatalog/CheckForOrderRequestItem`,
      body: number,
    };
    return this.apiRequest.request(requestProps);
  }

  GetServiceCatalogCategories(): Observable<GetServiceCatalogCategory[]> {
    const requestProps: IRequestOptions = {
      endpoint: `ServiceCatalog/GetServiceCatalogCategories`,
      body: '',
    };
    return this.apiRequest.request(requestProps);
  }
}
