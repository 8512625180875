<div class="page-content">
  <div class="page-information" style="text-align: center">
    <p>
      <strong>
        This is a list of the administrators within your company who can elevate
        your permissions if required.
      </strong>
    </p>
    <p>
      Your current role: <strong>{{ getUserRole() }}</strong>
    </p>
  </div>
  <hr />
  <div style="padding: 15px 25px">
    <!-- <servicehub-table
      [tableData]="admins"
      [tableColumns]="tableColumns"
      (loadMore)="handleLoadMore($event)"
      (rowClicked)="handleRowClicked($event)"
    >
    </servicehub-table> -->
  </div>
</div>
