export interface IWebHook {
  sysId: string;
  company: string;
  source: string;
  action: string;
  endpoint: string;
  authenticationType: string;
  oAuthToken: string;
  basicAuthUsername: string;
  basicAuthPassword: string;
}

export interface ICreateWebHookRequest {
  source: string;
  action: string;
  endpoint: string;
}

export interface ICreateWebHookOAuthRequest {
  source: string;
  action: string;
  endpoint: string;
  oAuthToken: string;
}

export interface ICreateWebHookBasicAuthRequest {
  source: string;
  action: string;
  endpoint: string;
  basicAuthUsername: string;
  basicAuthPassword: string;
}

export interface IUpdateWebHookRequest {
  sysId: string;
  source: string;
  action: string;
  endpoint: string;
}

export interface IUpdateWebHookOAuthRequest {
  sysId: string;
  source: string;
  action: string;
  endpoint: string;
  oAuthToken: string;
}

export interface IUpdateWebHookBasicAuthRequest {
  sysId: string;
  source: string;
  action: string;
  endpoint: string;
  basicAuthUsername: string;
  basicAuthPassword: string;
}

export interface IDeleteWebHookRequest {
  sysId: string;
}

export interface ICreateWebHookDialogData {
  existingWebHooks: IWebHook[];
  availableWebHooks: any;
}
export interface IConfigureWebHookDialogData {
  webHook: IWebHook;
  existingWebHooks: IWebHook[];
  availableWebHooks: any;
}

export interface IWebHookTypes {
  incidents: string[];
  requests: string[];
}

export enum WebHookAuthenticationTypes {
  None = 'None',
  OAuth = 'OAuth',
  BasicAuth = 'Basic Auth',
}
