import { Component, OnInit } from '@angular/core';

import { MenuItem } from 'primeng/api/menuitem';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { ConfigureWebHookComponent } from 'src/app/components/misc/pop-up/configure-web-hook/configure-web-hook.component';
import { CreateWebHookComponent } from 'src/app/components/misc/pop-up/create-web-hook/create-web-hook.component';
import { DeveloperService } from 'src/app/services/api/developer/developer.service';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import { ToastService, ToastType } from 'src/app/services/global/toast.service';
import { CredentialConfirmComponent } from '../credential-confirm/credential-confirm.component';
import {
  IConfigureWebHookDialogData,
  ICreateWebHookDialogData,
  IDeleteWebHookRequest,
  IWebHook,
  WebHookAuthenticationTypes,
} from './models/web-hook.model';

@Component({
  selector: 'web-hooks',
  templateUrl: 'web-hooks.component.html',
  styleUrls: ['web-hooks.component.scss'],
  providers: [DialogService],
})
export class WebHooksComponent implements OnInit {
  webHookSubject = new BehaviorSubject<IWebHook[]>([]);
  webHooks$ = this.webHookSubject.asObservable();
  loading: boolean = true;
  webHooks: IWebHook[];
  availableWebHooks = [];
  ref: DynamicDialogRef | undefined;

  get totalAvailableWebhooks() {
    return this.availableWebHooks.reduce((acc, webHook) => {
      return acc + webHook.actions.length;
    }, 0);
  }

  cols = [
    { field: 'source', header: 'Source', display: true, showSortIcon: true },
    { field: 'action', header: 'Action', display: true, showSortIcon: true },
    {
      field: 'endpoint',
      header: 'Endpoint',
      display: true,
      showSortIcon: true,
    },
    { field: 'edit', header: 'Edit', display: true, showSortIcon: false },
    { field: 'remove', header: 'Remove', display: true, showSortIcon: false },
  ];

  constructor(
    private developerService: DeveloperService,
    private breadcrumbService: BreadcrumbService,
    public dialogService: DialogService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    const breadcrumbs: MenuItem[] = [
      {
        label: 'Webhooks',
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.loadWebHooks();
  }

  async loadWebHooks() {
    try {
      this.loading = true;
      const webHooks: IWebHook[] = await firstValueFrom(
        this.developerService.getWebHooks([], 0, 0)
      );

      webHooks.forEach(webHook => {
        webHook.authenticationType = WebHookAuthenticationTypes.None;
        if (webHook.oAuthToken)
          webHook.authenticationType = WebHookAuthenticationTypes.OAuth;
        if (webHook.basicAuthUsername || webHook.basicAuthPassword)
          webHook.authenticationType = WebHookAuthenticationTypes.BasicAuth;
      });

      this.webHookSubject.next(webHooks);
      this.setAvailableWebHooks(webHooks);
      this.loading = false;
    } catch (error) {}
  }

  setAvailableWebHooks(currentWebHooks: IWebHook[]) {
    this.availableWebHooks = [
      {
        source: 'requests',
        actions: ['insert', 'update', 'attachment', 'comment'],
      },
      {
        source: 'incidents',
        actions: ['insert', 'update', 'attachment', 'comment'],
      },
    ];
    this.availableWebHooks = this.updateAvailableWebHooks(
      this.availableWebHooks,
      currentWebHooks
    );
  }

  editButtonClicked(webhook: IWebHook) {
    this.editWebHook(webhook);
  }

  deleteButtonClicked(webhook: IWebHook) {
    this.deleteWebhook(webhook);
  }

  updateAvailableWebHooks(availableWebHooks, existingWebHooks) {
    // loop through current availableWebHooks
    return availableWebHooks.map(availableWebHook => {
      // return a new object for each availableWebHook
      return {
        // copy all properties of availableWebHook
        ...availableWebHook,
        // overwrite actions based on filter comparision
        actions: availableWebHook.actions.filter(
          action =>
            // filter existing webbooks to get a list of actions for the same source
            // don't include if matched
            !existingWebHooks
              .filter(
                existingWebHook =>
                  existingWebHook.source.toLowerCase() ===
                  availableWebHook.source
              )
              // change format to also be an array of existing action strings
              .map(existingWebHook => existingWebHook.action.toLowerCase())
              // compare actions to existing actions
              .includes(action)
        ),
      };
    });
  }

  createWebHook() {
    const dialogData: ICreateWebHookDialogData = {
      existingWebHooks: this.webHooks,
      availableWebHooks: this.availableWebHooks,
    };

    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    this.ref = this.dialogService.open(CreateWebHookComponent, {
      showHeader: false,
      data: { dialog: dialogData },
      styleClass: 'p-dialog-custom',
    });
    this.ref.onClose.subscribe(async result => {
      if (result && result !== 'Cancel') {
        this.loadWebHooks();
      }
    });
  }

  editWebHook(webHook: IWebHook) {
    const dialogData: IConfigureWebHookDialogData = {
      webHook: webHook,
      existingWebHooks: this.webHooks,
      availableWebHooks: this.availableWebHooks,
    };
    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    this.ref = this.dialogService.open(ConfigureWebHookComponent, {
      showHeader: false,
      data: { dialog: dialogData },
      styleClass: 'p-dialog-custom',
    });
    this.ref.onClose.subscribe(async result => {
      if (result && result !== 'Cancel') {
        this.loadWebHooks();
      }
    });
  }

  async deleteWebhook(webhook: IWebHook) {
    const deleteWebHookRequest: IDeleteWebHookRequest = {
      sysId: webhook.sysId,
    };

    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    this.ref = this.dialogService.open(CredentialConfirmComponent, {
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      showHeader: false,
      width: '35%',
      data: {
        title: 'Are you sure?',
        content: 'You are about to remove the webhook with the endpoint ',
        authData: webhook?.endpoint,
        contentEnd: '. Do you wish to continue?',
      },
      styleClass: 'p-dialog-custom',
    });
    this.ref.onClose.subscribe(async action => {
      if (action) {
        this.loading = true;
        const deleted = await firstValueFrom(
          this.developerService.deleteWebHook(deleteWebHookRequest)
        );
        if (deleted) {
          this.loadWebHooks();
          this.toastService.add({
            severity: ToastType.success,
            summary: 'Remove webhook',
            detail: 'Operation completed.',
          });
        }
      }
    });
  }
}
