@if (loading) {
  <loading-spinner />
}
<div class="flex flex-column h-full">
  <div
    class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200">
    <h1 class="px-3 text-xl">Notification List</h1>
    <div class="pr-3">
      <p-selectButton
        [options]="stateOptions"
        [(ngModel)]="allToggle"
        (ngModelChange)="onToggleChange(dataTable)"
        [unselectable]="true"
        optionValue="value"
        class="selectbutton" />
    </div>
  </div>
  <div
    class="flex-grow-1 overflow-auto mx-3 overflow-hidden bg-white border-solid border-1 border-gray-200 border-top-none border-bottom-none px-3">
    <p-table
      #dataTable
      class="p-datatable-striped"
      [value]="notifications$ | async"
      [columns]="selectedColumns"
      styleClass="p-datatable-striped"
      [rowHover]="true"
      sortMode="single"
      [rows]="25"
      [lazy]="true"
      (onLazyLoad)="loadNotifications(dataTable, $event)"
      scrollHeight="flex"
      [scrollable]="true">
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between">
          <div class="flex">
            @if (selectedColumns && dataTable && dataTable.loading === false) {
              <prime-filter-dropdown
                [selectedColumns]="cols"
                [table]="dataTable"></prime-filter-dropdown>
            }
            <p-button
              label="Reset"
              styleClass="p-button p-button-text p-button-secondary"
              icon="pi pi-eraser"
              (onClick)="reset(dataTable, false)"></p-button>
          </div>
          <div class="flex pr-1">
            <p-button
              label="Mark All Read"
              icon="pi pi-check-circle"
              iconPos="left"
              styleClass="p-button w-full"
              appendTo="body"
              (onClick)="markAllReadSelected()"
              [disabled]="!markAllReadAvailable">
            </p-button>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="header" let-columns>
        <tr>
          @for (col of columns; track col) {
            <th
              [class.w-2rem]="col.field === 'notificationRead'"
              class="text-white bg-gray-800 p-datatable-header-first-row"
              [pSortableColumn]="col.field">
              {{ col.header }}
              @if (col.showSortIcon) {
                <p-sortIcon class="text-white" [field]="col.field"></p-sortIcon>
              }
            </th>
          }
        </tr>
      </ng-template>

      <ng-template pTemplate="loadingbody">
        <tr>
          <td [attr.colspan]="selectedColumns?.length + 1">
            <loading-spinner></loading-spinner>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingicon"></ng-template>

      <ng-template pTemplate="body" let-notification let-columns="columns">
        <tr class="cursor-pointer" (click)="openNotification(notification)">
          @for (col of columns; track col) {
            <td [class.w-2rem]="col.field === 'notificationRead'">
              @if (col.display?.type === 'text') {
                {{ notification[col.field] }}
              }
              @if (col.display?.type === 'date') {
                {{ notification[col.field] }}
              }
              @if (col.display?.type === 'icon') {
                @if (!notification?.notificationRead) {
                  <i class="pi pi-circle-fill text-primary p-0 m-0"></i>
                }
              }
              @if (col.display.type === 'chip') {
                <div class="text-center">
                  <p-chip
                    [label]="notification[col.field]"
                    [styleClass]="
                      getColor(col.display.colors, notification[col.field])
                        ? 'p-chip-' +
                          getColor(
                            col.display.colors,
                            notification[col.field]
                          ) +
                          ' block'
                        : 'block'
                    "></p-chip>
                </div>
              }
            </td>
          }
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="selectedColumns?.length + 1">
            No results were found.
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="footer">
        <tr>
          <td [attr.colspan]="selectedColumns?.length + 1" class="bg-white">
            <div class="flex align-items-center justify-content-center">
              <p-paginator
                #tablePaginator
                (onPageChange)="loadNotifications(dataTable, $event)"
                [first]="dataTable.first"
                [rows]="dataTable.rows"
                [totalRecords]="totalRecords"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} - {last} of {totalRecords}"
                [rowsPerPageOptions]="[25, 50, 100]"></p-paginator>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
