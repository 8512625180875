import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { KnowledgeArticleResponse } from 'src/app/components/service-hub/misc/knowledge-article/shared/knowledge-models';
import { IncidentIssues } from 'src/app/components/service-hub/tickets/raise/models/identify-models';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';
import { ServiceNowFilterQuery } from 'src/app/models/servicenow.model';

const taskDirectory = 'knowledge';

@Injectable({
  providedIn: 'root',
})
export class KnowledgeService {
  constructor(private apiRequest: ApiRequest) {}

  getKnowledgeBase(sysId: string, shortDescription: string, helpful: boolean) {
    return this.apiRequest.request({
      endpoint: `${taskDirectory}/PostKnowledgeArticleHelpfulness`,
      body: {
        SysId: sysId,
        ShortDescription: shortDescription,
        Helpful: helpful,
      },
    });
  }

  getKnowledgeArticles(
    request: ServiceNowFilterQuery
  ): Observable<KnowledgeArticleResponse> {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetKnowledgeArticles`,
      body: request,
    };

    return this.apiRequest.request(requestProps);
  }

  getKnowledgeArticle(sysId: string, companyId: string) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetKnowledgeArticle`,
      body: {
        SysId: sysId,
        UCompany: companyId,
      },
    };

    return this.apiRequest.request(requestProps);
  }

  getKnowledgeArticlesByMeta(searchCriteria: string[]) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetKnowledgeArticlesByMeta`,
      body: {
        searchCriteria,
      },
    };
    return this.apiRequest.request(requestProps);
  }

  logKnowledgeArticleHelpful(request: IncidentIssues) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/LogKnowledgeArticleHelpful`,
      body: request,
    };
    return this.apiRequest.request(requestProps);
  }

  logKnowledgeArticleNotHelpful(request: IncidentIssues) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/LogKnowledgeArticleNotHelpful`,
      body: request,
    };
    return this.apiRequest.request(requestProps);
  }
}
