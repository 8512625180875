import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ServiceCatalogItemComponent } from '../service-catalog-item/service-catalog-item.component';
import { CatalogItemsService } from 'src/app/services/api/catalog-items/catalog-items.service';
import { firstValueFrom } from 'rxjs';
import { GetServiceCatalogVariablesResponse } from 'src/app/models/service-catalog/service-catalog.models';
import { LoadingSpinnerComponent } from 'src/app/components/misc/loading-spinner/loading-spinner.component';

@Component({
  selector: 'service-catalog',
  templateUrl: './service-catalog.component.html',
  styleUrls: ['./service-catalog.component.scss'],
  standalone: true,
  imports: [
    ButtonModule,
    ReactiveFormsModule,
    InputTextModule,
    FormsModule,
    ServiceCatalogItemComponent,
    LoadingSpinnerComponent,
  ],
})
export class ServiceCatalogComponent implements OnInit {
  @Input() serviceNowCatalogForm: FormGroup;
  @Output() childIsLoading = new EventEmitter<boolean>();
  structure: GetServiceCatalogVariablesResponse[];
  loading = false;

  constructor(
    private fb: FormBuilder,
    private catalogItemsService: CatalogItemsService
  ) {}

  async ngOnInit() {
    await this.getServiceCatalog();
  }

  async getServiceCatalog() {
    this.childIsLoading.emit(true);
    this.loading = true;
    const response = await firstValueFrom(
      this.catalogItemsService.GetServiceCatalogVariables(
        this.serviceNowCatalogForm.get('request').value.value
      )
    );
    this.structure = response;
    this.loading = false;
    this.childIsLoading.emit(false);
  }
}
