import { TableColumnDisplay } from '../table';

export const ChangeRequestRequestSizeLimit = 1000;

export enum ChangeRequestTabs {
  open = 'Open',
  closed = 'Closed',
}

export const ChangeRequestOpenStateValues = [
  'Approval',
  'Implementation',
  'On Hold',
  'PIR',
];

export const ChangeRequestClosedStateValues = ['Closed'];

export type ChangeColumn = {
  field: string;
  header: string;
  display: TableColumnDisplay;
  showSortIcon: boolean;
  default: boolean;
  disabled?: boolean;
  serviceNowField: string;
};

export type GetChangeRequestsResponseItem = {
  sysId: string;
  number: string;
  company: string;
  shortDescription: string;
  description: string;
  startDate: string;
  endDate: string;
  requestedBy: string;
  uRequestedFor: string;
  state: string;
  openedAt: string;
  uPortalLastAction: string;
  type: string;
  uImpactDetails: string;
  public: boolean;
  uTemplate: string;
  testPlan: string;
  active: boolean;
  openedBy: string;
  closeNotes: string;
  changePlan: string;
  backoutPlan: string;
  uImpactedUsers: string;
  uRiskDetails: string;
};

export type GetSingleChangeRequestResponse = {
  backoutPlan: string;
  number: string;
  sysId: string;
  changePlan: string;
  openedBy: string;
  company: string;
  companyId: string;
  active: boolean;
  endDate?: string;
  requestedBy: string;
  requestedFor: string;
  requestedForSysId: string;
  startDate?: string;
  testPlan: string;
  changeReason: string;
  type: string;
  public: boolean;
  impactedUsers: string;
  riskDetails: string;
  communicationPlan: string;
  additionalInformation: string;
  openedAt: string;
  state: string;
  shortDescription: string;
  riskImpactAnalysis: string;
  closedStatus: string;
  closeNotes: string;
};

export type GetMultipleChangeRequestResponse = {
  sysId: string;
  number: string;
  state: string;
  requestedFor: string;
  shortDescription: string;
  startDate?: Date;
  endDate?: Date;
  closedStatus: string;
};

//Column names match export DTOs in Customer portal
export enum ChangeRequestTableColumnName {
  number = 'number',
  state = 'State',
  requestedFor = 'URequestedFor.name',
  shortDescription = 'shortDescription',
  startDate = 'StartDate',
  endDate = 'EndDate',
  closedStatus = 'UClosedStatus',
}

export type ChangeRequestDetails = {
  name: string;
  details: string;
};

export type GetApproverStateResponse = {
  state: string;
};

export enum ChangeNames {
  CHANGE_PLAN = 'Change Plan',
  BACKOUT_PLAN = 'Backout Plan',
  TEST_PLAN = 'Test Plan',
  COMMUNICATION_PLAN = 'Communication Plan',
  CHANGE_REASON = 'Change Reason',
  RISK_DETAILS = 'Risk Analysis',
  RISK_IMPACT_ANALYSIS = 'Impact Analysis',
  CLOSED_STATUS = 'Change Outcome',
  CLOSE_NOTES = 'Closure Notes',
}

export type ApproveChangeRequestResponse = {
  approver: string;
  comments: string;
  sourceTable: string;
  sysapproval: string;
  documentId: string;
  sysId: string;
  state: string;
};

export type RejectChangeRequestResponse = ApproveChangeRequestResponse;

export type RejectChangeRequestRequest = {
  comments: string;
  documentId: string;
  sysId?: string;
  state: string;
};
