<div class="row justify-content-center" style="margin-top: 25px">
  <div
    class="col-xl-8 col-lg-8 col-md-10 col-sm-12 invoice-container"
    style="background-color: white; border-radius: 5px"
    blocker="Invoice Information">
    <div class="type-logo">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <h1 style="text-align: left">{{ invoiceData?.type }}</h1>
          </div>
          <div
            class="col-xl-6 col-lg-6 col-md-6 col-sm-12 digitalspace-logo"
            style="text-align: right">
            <img
              style="margin-top: 5px; margin-right: 5px"
              src="../../../../../assets/images/digitalspace-logo/service-hub-logo.svg" />
          </div>
        </div>
      </div>
    </div>
    <div class="address-info">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div class="address">
              @if (
                invoiceData?.address1 != undefined &&
                invoiceData?.address1 != null
                ) {
                <p
                  >
                  {{ invoiceData?.address1 }}
                </p>
              }
              @if (
                invoiceData?.address2 != undefined &&
                invoiceData?.address2 != null
                ) {
                <p
                  >
                  {{ invoiceData?.address2 }}
                </p>
              }
              @if (
                invoiceData?.address3 != undefined &&
                invoiceData?.address3 != null
                ) {
                <p
                  >
                  {{ invoiceData?.address3 }}
                </p>
              }
              @if (
                invoiceData?.address4 != undefined &&
                invoiceData?.address4 != null
                ) {
                <p
                  >
                  {{ invoiceData?.address4 }}
                </p>
              }
              @if (
                invoiceData?.address5 != undefined &&
                invoiceData?.address5 != null
                ) {
                <p
                  >
                  {{ invoiceData?.address5 }}
                </p>
              }
              @if (
                invoiceData?.address6 != undefined &&
                invoiceData?.address6 != null
                ) {
                <p
                  >
                  {{ invoiceData?.address6 }}
                </p>
              }
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div class="invoice-details">
              <p>
                <strong>Invoice No:</strong> {{ invoiceData?.invoiceNumber }}
              </p>
              <p>
                <strong>Invoice Date:</strong> {{ invoiceData?.invoiceDate }}
              </p>
              <p><strong>Account Name:</strong> {{ invoiceData?.siteName }}</p>
              <p><strong>Account Id:</strong> {{ invoiceData?.companyId }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="invoice-summary">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <h3>Summary</h3>
        <div class="" [ngClass]="{ 'table-responsive': util.mobile }">
          <table class="table" id="table">
            <thead class="thead bg-primary">
              <tr style="color: azure">
                <th>Description</th>
                <th>Gross</th>
                <th>Net</th>
                <th>VAT</th>
              </tr>
            </thead>
            <tbody>
              @for (row of invoiceData?.invoiceLines; track row) {
                <tr
                  class="rowBackgroundLink"
                  style="cursor: default">
                  <td>{{ row.description }}</td>
                  <td>{{ row.gross | currency: 'GBP' }}</td>
                  <td>{{ row.net | currency: 'GBP' }}</td>
                  <td>{{ row.vat | currency: 'GBP' }}</td>
                </tr>
              }
            </tbody>
            <tfoot>
              <tr>
                <td><strong>Total</strong></td>
                <td>
                  <strong>{{
                    invoiceData?.totalGross | currency: 'GBP'
                  }}</strong>
                </td>
                <td>
                  <strong>{{ invoiceData?.totalNet | currency: 'GBP' }}</strong>
                </td>
                <td>
                  <strong>{{ invoiceData?.totalVat | currency: 'GBP' }}</strong>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    <div class="footer-details">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <p>
          <strong
            >Digital Space Group Limited, BEACON HILL PARK, NEWARK,
            NOTTINGHAMSHIRE NG24 2TN</strong
            >
          </p>
          <small
            ><strong>Tel:</strong> 08700 949 600 - <strong>Web:</strong>
            <a href="https://www.digitalspace.co.uk"
              >www.digitalspace.co.uk</a
              ></small
              ><br />
              <small><strong>VAT Number:</strong> 852 3688 04</small><br />
              <small><strong>Company Registration Number:</strong> 4841830</small>
            </div>
          </div>
        </div>
      </div>
