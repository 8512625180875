import { FileUpload } from 'src/app/components/misc/file-upload/file-upload';

export type TicketData = {
  shortDescription: string;
  description: string;
  files?: FileUpload[];
  tableName: string;
  location?: string;
  businessService?: string;
  serviceComponent?: string;
  symptom?: string;
  impact?: string;
  urgency?: string;
};

export type TicketResponse = {
  contactType: string;
  number: string;
  shortDescription: string;
  sysId: string;
  uExternalReferenceNumber: string;
};

export type Pac = {
  catalogId: string;
  variables: object;
};

export type IncidentResponse = {
  sysId: string;
  number: string;
};

export type Location = {
  buildingName: string;
  city: string;
  zip: string;
  fullName: string;
  sysID: string;
};

export enum LocationTableColumns {
  city = 'City',
  fullName = 'FullName',
  buildingName = 'UBuildingName',
  postcode = 'Zip',
}

export enum RaiseHeading {
  identify = 'Identify the issue',
  incident = 'Raise an Incident',
}

export type IncidentSeverity = {
  name: string;
  impact: string;
  urgency: string;
};
