<div class="bg-primary h-7rem flex justify-content-center align-content-center">
  <h1
    class="text-3xl flex align-items-center justify-content-center font-semibold">
    How can we help?
  </h1>
</div>
<div class="flex flex-column align-items-center justify-content-center pt-4">
  @if (ticketPermissions?.incident) {
    <div
      (click)="openIdentifyIncident()"
      (keyup.enter)="openIdentifyIncident()"
      role="button"
      tabindex="0"
      class="max-w-32rem pr-4 bg-white border-round hover:border-primary border-3 border-white shadow-2 cursor-pointer mb-4">
      <div class="flex gap-6 items-center">
        <div
          class="pl-5 flex justify-content-center align-content-center flex-wrap">
          <img
            src="assets/images/icons/icon-tickets-support.svg"
            alt="incident logo"
            class="h-4rem w-3rem" />
        </div>
        <div class="flex flex-column">
          <h2 class="text-gray-900 text-xl">Raise an Incident</h2>
          <p class="text-gray-900 text-lg mt-0">
            Report a fault or issue on one of your Digital Space services.
          </p>
        </div>
      </div>
    </div>
  }
  @if (ticketPermissions?.request) {
    <div
      (click)="openRaiseRequest()"
      (keyup.enter)="openRaiseRequest()"
      role="button"
      tabindex="0"
      class="max-w-32rem pr-4 bg-white border-round hover:border-primary border-3 border-white shadow-2 cursor-pointer">
      <div class="flex gap-6">
        <div
          class="pl-5 flex justify-content-center align-content-center flex-wrap"
          routerLink="/secure/tickets/raise/create-service-request ">
          <img
            src="assets/images/icons/icon-tickets-info.svg"
            alt="request logo"
            class="h-4rem w-3rem" />
        </div>
        <div class="flex flex-column">
          <h2 class="text-gray-900 text-xl">Raise a Service Request</h2>
          <p class="text-gray-900 text-lg mt-0">
            Request a service from Digital Space or make a change to an existing
            service.
          </p>
        </div>
      </div>
    </div>
  }
</div>
