@if (selectedSiteId) {
  <p-table
    #dataTable
    class="p-datatable-equal-width"
    styleClass="p-datatable-striped"
    [value]="tableData$ | async"
    [columns]="selectedColumns"
    [rowHover]="true"
    sortMode="single"
    [rows]="25"
    [lazy]="true"
    [scrollable]="true"
    scrollHeight="flex"
    (onLazyLoad)="loadCloudHostingData(dataTable, $event)">
    <ng-template pTemplate="caption">
      <div class="flex justify-content-between align-content-center">
        <div class="flex">
          @if (selectedColumns && dataTable && dataTable.loading === false) {
            <prime-filter-dropdown
              [selectedColumns]="cols"
              [table]="dataTable"></prime-filter-dropdown>
          }
          <p-multiSelect
            styleClass="p-multiselect-label-hide p-multiselect-border"
            [options]="cols"
            [(ngModel)]="selectedColumns"
            optionLabel="header"
            dropdownIcon="pi pi-sliders-h"
            placeholder="Choose Columns"
            selectedItemsLabel="{0} columns selected"
            (ngModelChange)="handleColumnChange(cols)"
            appendTo="body"
            optionDisabled="disabled"></p-multiSelect>
          <p-button
            label="Reset"
            styleClass="p-button p-button-text p-button-secondary"
            icon="pi pi-eraser"
            (onClick)="reset(dataTable, false)"></p-button>
        </div>
        <div class="flex gap-1">
          <p-splitButton
            label="Export"
            icon="pi pi-download"
            iconPos="left"
            (onClick)="defaultExport()"
            [model]="exportOptions"
            styleClass="p-button"
            appendTo="body" />
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        @for (col of columns; track col) {
          <th
            class="text-white bg-gray-800 p-datatable-header-first-row"
            [pSortableColumn]="col.field">
            {{ col.header }}
            @if (col.showSortIcon) {
              <p-sortIcon class="text-white" [field]="col.field"></p-sortIcon>
            }
          </th>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="loadingbody">
      <tr>
        <td [attr.colspan]="selectedColumns?.length + 1">
          <loading-spinner></loading-spinner>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="loadingicon"></ng-template>
    <ng-template pTemplate="body" let-billing let-columns="columns">
      <tr (click)="handleRowClicked(number)" class="cursor-pointer">
        @for (col of columns; track col) {
          <td>
            @if (col.display?.type === 'text') {
              {{ billing[col.field] }}
            }
            @if (col.display?.type === 'number') {
              {{ billing[col.field] }}
            }
            @if (col.display?.type === 'date') {
              {{ billing[col.field] }}
            }
          </td>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="selectedColumns?.length + 1">
          No results were found.
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr>
        <td class="bg-white" [attr.colspan]="selectedColumns.length + 1">
          <div class="flex align-items-center justify-content-center">
            <p-paginator
              #tablePaginator
              (onPageChange)="loadCloudHostingData(dataTable, $event)"
              [first]="dataTable.first"
              [rows]="dataTable.rows"
              [totalRecords]="totalRecords"
              [showCurrentPageReport]="true"
              currentPageReportTemplate="{first} - {last} of {totalRecords}"
              [rowsPerPageOptions]="[25, 50, 100]"></p-paginator>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
}
