import { Inject, Injectable } from '@angular/core';
import {
  ApiRequest,
  IRequestOptions,
} from '../../../helpers/api.request.helper';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  constructor(private apiRequest: ApiRequest) {}

  checkRoleExists(control) {
    const requestProps: IRequestOptions = {
      endpoint: 'Validation/CheckRoleExists',
      body: control,
    };
    return this.apiRequest.request(requestProps);
  }
}
