<div
  [ngClass]="paddingArray"
  class="flex justify-content-center loading-spinner">
  <div class="flex flex-column">
    <div class="text-center">
      <img
        class="loading-gif"
        src="../../../../assets/gifs/loading/servicehub-loading-ani.gif" />
    </div>
    @if (displayMessage) {
      <div class="text-center">
        <p class="font-bold mt-2">{{ message$ | async }}</p>
      </div>
    }
  </div>
</div>
