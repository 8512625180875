<div class="flex">
  <!-- <button (click)="export();" class="data-button" style="float: right;" mat-raised-button
              color="primary"> Download </button> -->

  <p-button (onClick)="loadGraphData(0)" label="24 Hours"> </p-button>
  <p-button (onClick)="loadGraphData(1)" label="7 Days"> </p-button>
  <p-button (onClick)="loadGraphData(2)" label="30 Days"> </p-button>
</div>

<servicehub-graph
  [data]="graphData"
  [applyPadding]="false"
  [legend]="false"></servicehub-graph>
