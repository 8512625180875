import { Injectable } from '@angular/core';
import { ServiceHubMenuItem, menu } from './menu';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private userMenuItems: ServiceHubMenuItem[] = [];

  private showMenuSubject = new BehaviorSubject<boolean>(false);
  showMenu$ = this.showMenuSubject.asObservable();

  toggleMenu() {
    this.showMenuSubject.next(!this.showMenuSubject.getValue());
  }

  closeMenu() {
    this.showMenuSubject.next(false);
  }

  setUserMenuItems(user) {
    this.userMenuItems = this.filterMenuItemsByPermission(
      menu,
      user.uTimicoPortalPermissions
    );
  }

  getUserMenuItems(): ServiceHubMenuItem[] {
    return this.userMenuItems;
  }

  filterMenuItemsByPermission(
    menuItems: ServiceHubMenuItem[],
    userPermissions: string[]
  ): ServiceHubMenuItem[] {
    return menuItems
      .map(menuItem => {
        if (!menuItem?.permissions?.length) {
          return menuItem;
        }
        const filteredItems = menuItem.items
          ? this.filterMenuItemsByPermission(menuItem.items, userPermissions)
          : [];
        const hasPermission = menuItem.permissions.some(permission =>
          userPermissions.includes(permission)
        );
        if (hasPermission || filteredItems.length > 0) {
          const clonedMenuItem = { ...menuItem };
          if (filteredItems.length > 0) {
            clonedMenuItem.items = filteredItems;
          }
          return clonedMenuItem;
        }
        return null;
      })
      .filter(menuItem => menuItem !== null) as ServiceHubMenuItem[];
  }
}
