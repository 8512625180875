<div class="flex flex-column h-full">
  <h1 class="text-xl px-3">{{ dialogData?.title }}</h1>
  <hr class="w-full" />
  <div class="pl-3 pr-3">
    <p class="text-xl">
      {{ dialogData?.content
      }}<span class="text-primary font-bold text-xl">{{
      dialogData?.authData
      }}</span
      >{{ dialogData?.contentEnd }}
    </p>
  </div>
  @if (addBreak) {
    <br />
  }
  <div class="flex justify-content-center align-content-center pb-3">
    <p-button
      (onClick)="cancelButton()"
      styleClass="p-button-secondary"
    label="No"></p-button>
    <p-button (onClick)="submitButton()" label="Yes"></p-button>
  </div>
</div>
