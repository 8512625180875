import { Injectable } from '@angular/core';
import { Subject, firstValueFrom } from 'rxjs';
import { IDownloadFile } from 'src/app/models/export-models';
import { ExportService } from 'src/app/services/export.service';
import {
  FileUpload,
  UploadAttachmentRequest,
  ViewFile,
} from './file-manager.models';
import { FileService } from 'src/app/services/api/file/file.service';
import { TableNames } from 'src/app/models/servicenow.model';
import { CaseService } from 'src/app/services/api/case/case.service';
import { OrderLineItemService } from 'src/app/services/api/order-line-item/order-line-item.service';

@Injectable({
  providedIn: 'root',
})
export class FileManagerService {
  uploadingEnabled: boolean = true;
  currentFiles: ViewFile[] = [];
  uploadedFiles: FileUpload[] = [];
  fileChecker: any[] = [];
  table: TableNames;
  sysId: string;
  upload$ = new Subject<boolean>();
  observe: 'events';
  fileMaxSize: number = 5;

  constructor(
    private exportService: ExportService,
    private fileService: FileService,
    private caseService: CaseService,
    private orderLineItemService: OrderLineItemService
  ) {}

  async filesSelected(files: File[]): Promise<void> {
    for (const file of files) {
      await this.convertFile(file);
      this.fileChecker.push(file);
    }
  }

  async convertFile(file: File): Promise<void> {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      var errorMessage = this.FileUploadErrorChecker(file);
      reader.onload = e => {
        var convertedFile: FileUpload = {
          name: file.name,
          size: Math.ceil(file.size / 1024),
          base64Encoding: (e.target as FileReader).result,
          contentType: file.type,
          errorMessage: errorMessage,
          tableName: this.table,
          tableSysId: this.sysId,
        };
        this.uploadedFiles.push(convertedFile);
        resolve();
      };
    });
  }

  private FileUploadErrorChecker(file: File): string {
    var errorMessage = '';
    if (file.size == 0) {
      errorMessage = 'No content in file! Please try again.';
    }

    if (file.size / 1024 / 1024 > this.fileMaxSize) {
      errorMessage =
        'File exceeds the ' +
        this.fileMaxSize +
        'MB attachment limit. Please try again.';
    }
    return errorMessage;
  }

  checkFileSize(): boolean {
    const legitFiles = this.fileChecker.filter(upLoadedFile => {
      if (
        upLoadedFile.size / 1024 / 1024 < this.fileMaxSize &&
        upLoadedFile.size > 0
      )
        return upLoadedFile;
    });

    if (legitFiles.length) {
      return true;
    } else {
      return false;
    }
  }

  clearFilesToUpload(): void {
    this.uploadedFiles = [];
    this.fileChecker = [];
  }

  deleteFile(index): void {
    this.uploadedFiles.splice(index, 1);
    this.fileChecker.splice(index, 1);
    this.checkFileSize();
  }

  async uploadFile(): Promise<void> {
    if (this.uploadedFiles.length < 0) return;

    switch (this.table) {
      case TableNames.CASE: {
        const request: UploadAttachmentRequest = {
          itemSysId: this.sysId,
          files: this.uploadedFiles.filter(f => f.errorMessage == ''),
        };
        await firstValueFrom(this.caseService.uploadAttachment(request));
        break;
      }

      case TableNames.ORDER_LINE: {
        const request: UploadAttachmentRequest = {
          itemSysId: this.sysId,
          files: this.uploadedFiles.filter(f => f.errorMessage == ''),
        };
        await firstValueFrom(
          this.orderLineItemService.uploadAttachment(request)
        );
        break;
      }

      default: {
        const request = {
          Files: this.uploadedFiles.filter(f => f.errorMessage == ''),
          TableName: this.table,
          TableSysId: this.sysId,
        };
        await firstValueFrom(this.fileService.uploadFileToServiceNow(request));
      }
    }
    this.clearFilesToUpload();
    this.upload$.next(true);
  }

  noFilesAdded(): boolean {
    if (this.uploadedFiles.length == 0) {
      return true;
    }
  }

  async downloadFile(event, file): Promise<void> {
    event.preventDefault();
    // TODO fix typing
    let downloadFile: IDownloadFile = (await firstValueFrom(
      this.fileService.downloadFile(file.sysId)
    )) as unknown as IDownloadFile;
    downloadFile.fileName = file.fileName;
    this.exportService.downloadFile(downloadFile);
  }

  //TODO:Remove this after all moved to prime icons
  setFileIcon(file): string {
    const fileExtension = file.fileName.split('.').pop();
    if (
      fileExtension === 'png' ||
      fileExtension === 'jpeg' ||
      fileExtension === 'gif' ||
      fileExtension === 'bmp'
    ) {
      return 'photo';
    } else {
      return 'description';
    }
  }

  setFileIconPrimeIcon(file): string {
    const fileExtension = file.fileName.split('.').pop();
    if (
      fileExtension === 'png' ||
      fileExtension === 'jpeg' ||
      fileExtension === 'gif' ||
      fileExtension === 'bmp'
    ) {
      return 'pi-image';
    } else {
      return 'pi-file';
    }
  }
}
