import { TableColumn, TableSort } from 'src/app/models/table';

export type User = {
  sysId?: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  active: boolean;
  uServicehubAccess: boolean;
  companyId?: string;
  lastLogin?: string;
  phone?: string;
  permission?: string;
  typesOfContact?: any[];
  uAccessiblePortals?: string;
  uBillingEntities?: string;
  uOrdersBillingEntities?: string;
  portalAccess?: boolean;
  roleSysId?: string;
  uAuthorisationTypes?: string;
  availablePortals?: string;
};

export interface IAddUser {
  active: boolean;
  companyId: string;
  email: string;
  firstName: string;
  lastLogin?: string;
  lastName: string;
  mobilePhone: string;
  permission: string;
  phone: string;
  sysId: string;
  uAccessiblePortals: string;
  uBillingEntities: string;
  uOrdersBillingEntities: string;
  uServicehubAccess: boolean;
  DenyServicehubAccess?: boolean;
  portalAccess: boolean;
  roleSysId: string;
  uAuthorisationTypes: string;
  availablePortals: string;
}

export interface IUserTableResponse {
  name: string;
  email: string;
  role: string;
  mobile: string;
  servicehubAccess: boolean;
  active: boolean;
}

export interface IServicenowUser {
  firstName: string;
  lastName: string;
  title: string;
  companyName: string;
  companyId: string;
  company: string;
  email: string;
  userName: string;
  phone: string;
  mobilePhone: string;
  homePhone: string;
  created: string;
  sysId: string;
  active: boolean;
  unityCompanyId: string;
  uRegistered: boolean;
  uTimicoPortalPermissions: string[];
  uAccessiblePortals: string;
  uTimicoPortalRole: string;
  uTimicoPortalRoleSysId: string;
  uTypesOfContact: string;
  typesOfContact: string[];
  uReleaseNoteViewed: string;
  multiFactorAuthentication: string;
  companyRequiresMfa: boolean;
  companyAssignmentGroup: any;
  impersonatedBy: string;
  companyServiceManager: string;
  uBillingEntities: string;
  uOrdersBillingEntities: string;
  uServicehubAccess: boolean;
  companyAbzorbIds: string[];
  passwordNeedsReset: boolean;
  uPasswordResetReason: string;
  lockedOut: boolean;
  uLockedOutReason: string;
  uAuthorisationTypes: string;
}

export interface IPortalOption {
  uLabel: string;
  uName: string;
  uDescription?: string;
}

export enum IUserTableColumns {
  name = 'Name',
  email = 'Email',
  role = 'UTimicoPortalRole',
  mobile = 'MobilePhone',
  access = 'UServicehubAccess',
  status = 'status',
  active = 'Active',
}

export interface IStateColor {
  [key: string]: string;
}

export interface IUsersColumn {
  field: string;
  header: string;
  display: IServiceDisplay;
  showSortIcon: boolean;
  default: boolean;
  disabled?: boolean;
  serviceNowField: string;
}

enum snowExportField {
  name = 'Name',
  email = 'Email',
  role = 'Role',
  phone = 'Mobile',
  access = 'PortalAccess',
  active = 'Status',
}

export const usersColumns: TableColumn[] = [
  {
    field: 'name',
    header: 'Name ',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
    disabled: true,
    serviceNowField: IUserTableColumns.name,
    sortOrder: TableSort.asc,
    exportField: snowExportField.name,
  },
  {
    field: 'email',
    header: 'Email Address',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
    serviceNowField: IUserTableColumns.email,
    exportField: snowExportField.email,
  },
  {
    field: 'role',
    header: 'Role',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
    serviceNowField: IUserTableColumns.role,
    exportField: snowExportField.role,
  },
  {
    field: 'mobile',
    header: 'Mobile',
    display: { type: 'text' },
    showSortIcon: false,
    default: true,
    serviceNowField: IUserTableColumns.mobile,
    exportField: snowExportField.phone,
  },
  {
    field: 'active',
    header: 'Status',
    filter: {
      type: 'dropdown',
      values: [
        { label: 'Active', value: true },
        { label: 'Inactive', value: false },
      ],
      preset: {
        matchMode: 'equals',
        value: true,
      },
    },
    display: {
      type: 'chip',
      colors: {
        true: 'true',
        inactive: 'false',
      },
    },
    showSortIcon: true,
    default: true,
    serviceNowField: IUserTableColumns.active,
    exportField: snowExportField.active,
  },
];

interface IServiceColor {
  [key: string]: string;
}

interface IServiceDisplay {
  type: string;
  colors?: IServiceColor | null;
}

export interface ITableDataApiResponse<T> {
  overallQueryCount: number;
  result: Array<T>;
}
