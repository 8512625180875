import { Injectable } from '@angular/core';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const host: string = environment.microservices.observability;

@Injectable({
  providedIn: 'root',
})
export class ObservabilityService {
  constructor(
    private apiRequest: ApiRequest,
    private http: HttpClient
  ) {}

  getDashboardEmbedUrl(dashboard: string, companyId?: string) {
    const requestProps: IRequestOptions = {
      method: 'GET',
      endpoint: `/dashboard/${dashboard}${
        companyId ? '?company=' + companyId : ''
      }`,
      host: host,
    };
    return this.apiRequest.request(requestProps);
  }

  getCompanyList() {
    const requestProps: IRequestOptions = {
      endpoint: `ScienceLogic/GetCompany`,
      body: '',
    };
    return this.apiRequest.request(requestProps);
  }
}
