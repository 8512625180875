import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';

import { ServiceNowFilterQuery } from 'src/app/models/servicenow.model';
import { Notification } from 'src/app/models/notification/notification.models';

const taskDirectory = 'Notifications';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private apiRequest: ApiRequest) {}

  setAllNotificationsAsRead() {
    const requestProps: IRequestOptions = {
      endpoint: 'Notifications/SetAllNotificationsAsRead',
    };

    return this.apiRequest.request(requestProps);
  }

  setNotificationAsRead(notification) {
    const requestProps: IRequestOptions = {
      endpoint: 'Notifications/SetNotificationAsRead',
      body: notification,
    };

    return this.apiRequest.request(requestProps);
  }

  getUnreadNotifications(notifications: any) {
    const requestProps: IRequestOptions = {
      endpoint: 'Notifications/GetUnreadNotifications',
      body: notifications,
    };

    return this.apiRequest.request(requestProps);
  }

  getNotifications(filter: ServiceNowFilterQuery): Observable<Notification[]> {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetAllNotifications`,
      body: filter,
    };
    return this.apiRequest.request(requestProps);
  }

  getNotification(sysId: string) {
    const requestProps: IRequestOptions = {
      endpoint: 'Notifications/GetNotification',
      body: sysId,
    };

    return this.apiRequest.request(requestProps);
  }

  getAllNotifications(request: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: 'Notifications/GetAllNotifications',
      body: request,
    };

    return this.apiRequest.request(requestProps);
  }

  getCount(filter: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetNotificationsCount`,
      body: filter,
    };
    return this.apiRequest.request(requestProps);
  }
}
