<div class="flex flex-column h-full bg-white">
  <h1 class="text-xl px-3">{{ headingText }}</h1>
  <hr class="w-full" />

  <identify-incident-issue-component
    [hidden]="showCreateIncident"
    (formComplete)="onFormComplete($event)"
    [resetTrigger$]="reset$" />

  @if (!submit) {
    @if (showCreateIncident) {
      <div class="formgrid grid w-full bg-white">
        <div class="field col flex justify-content-center">
          <div class="flex align-items-center">
            <form [formGroup]="raiseIncidentControl">
              <div class="formgrid grid">
                <div class="field col">
                  <div class="pb-1">
                    <span
                      ><span class="text-primary text-lg">*</span
                      ><label for="incident-title" class="text-lg">Subject </label
                      ><span class=""
                      >Please enter a subject to describe your issue, which will
                      be included in the subject line of all email
                      communications</span
                      ></span
                      >
                    </div>
                    <input
                      placeholder="Subject"
                      id="incident-title"
                      formControlName="shortDescription"
                      type="text"
                      id="firstName"
                      type="text"
                      pInputText
                      aria-label="Title"
                      class="w-full" />
                    @if (
                      raiseIncidentControl.get('shortDescription').invalid &&
                      raiseIncidentControl.get('shortDescription').touched
                      ) {
                      <small
                        class="p-error text-base">
                        Incident title is required
                      </small>
                    }
                  </div>
                </div>
                <div class="formgrid grid">
                  <div class="field col">
                    <div class="pb-1">
                      <span
                        ><span class="text-primary text-lg">*</span
                        ><label for="description" class="text-lg"
                        >Description </label
                        ><span class="text-base"
                        >Please provide us with as much detail as possible of your
                        issue, including technical details, to assist us with the
                        investigation</span
                        ></span
                        >
                      </div>
                      <textarea
                        pInputText
                        id="description"
                        placeholder="Description"
                        formControlName="description"
                        aria-label="Description"
                        type="text"
                        rows="8"
                      class="w-full"></textarea>
                      @if (
                        raiseIncidentControl.get('description').invalid &&
                        raiseIncidentControl.get('description').touched
                        ) {
                        <small
                          class="p-error text-base">
                          Incident description is required
                        </small>
                      }
                    </div>
                  </div>
                  <div class="formgrid grid">
                    <div class="field col">
                      <div class="pb-1">
                        <span
                          ><span class="text-primary text-lg">*</span
                          ><label for="severity" class="text-lg"
                          >Severity</label
                          ></span
                          >
                          <p-dropdown
                            id="severity"
                            formControlName="severity"
                            [options]="severities"
                            placeholder="Select"
                            optionLabel="name"
                            styleClass="w-full" />
                        </div>
                      </div>
                    </div>
                    <br />
                    <span
                      ><span class="text-xl">Location </span
                      ><span class="text-base"
                      >Please provide the location to help us understand the
                      issue.</span
                      ></span
                      >
                      <div class="formgrid grid">
                        <div class="field col">
                          <label for="location"></label>
                          <span class="p-input-icon-right field w-full">
                            <i
                              class="pi pi-search cursor-pointer"
                            (click)="openLocationDialog()"></i>
                            <input
                              placeholder="Select"
                              id="location"
                              formControlName="location"
                              (click)="openLocationDialog()"
                              type="text"
                              pInputText
                              aria-label="Title"
                              class="w-full cursor-pointer"
                              readonly />
                          </span>
                          @if (raiseIncidentControl.get('location')?.value) {
                            <i
                              class="pi pi-trash hover:text-primary pl-2"
                            (click)="removeLocation()"></i>
                          }
                        </div>
                      </div>
                      <div class="file-upload">
                        <div class="file-upload-text">
                          <span class="text-xl">Attachment </span
                            ><span class="text-base"
                            >Please attach any relevant files to help us understand your
                            issue</span
                            >
                          </div>
                        </div>
                        <app-file-upload></app-file-upload>
                      </form>
                    </div>
                  </div>
                </div>
              }
              @if (showCreateIncident) {
                <div
                  class="flex justify-content-center pb-4 bg-white">
                  <p-button
                    styleClass="p-button-secondary"
                    label="Back"
                    (onClick)="goBackButton()">
                  </p-button>
                  <p-button
                    styleClass="p-button"
                    label="Submit"
                    (onClick)="submitIncident()"
                    [disabled]="raiseIncidentControl.invalid">
                  </p-button>
                </div>
              }
            }

            @if (showLoader) {
              <loading-spinner />
            }
            @if (!showLoader && submit) {
              <div class="flex flex-column h-full">
                <div class="flex flex-column align-items-center justify-content-center">
                  <p class="text-lg">
                    Your incident has been created with the reference number
                  </p>
                  <h2 class="text-3xl m-0">
                    {{ response?.number }}
                    <i
                      title="Copy Incident Number"
                      (click)="copyIncidentNumber()"
                    class="pi pi-copy hover:text-primary text-3xl"></i>
                  </h2>
                  <p class="text-3xl text-primary font-bold m-0">{{ textAlert }}</p>
                  @if (fileUploadErrors?.length > 0) {
                    <div class="p-error">
                      The files below were not uploaded successfully. You can go to the
                      incident to upload the files anytime.
                      <br />
                      @for (error of fileUploadErrors; track error) {
                        <ul>
                          <li>
                            <p>{{ error.fileName }}</p>
                          </li>
                        </ul>
                      }
                    </div>
                  }
                  <div class="pt-4">
                    <p-button
                      styleClass="p-button-secondary"
                      (onClick)="viewIncident()"
                      label="View Ticket">
                    </p-button>
                    <p-button
                      styleClass="p-button"
                      label="Raise another Incident"
                      (onClick)="raiseAnotherIncident()">
                    </p-button>
                  </div>
                </div>
              </div>
            }
          </div>
