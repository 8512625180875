<div class="flex flex-column h-full">
  <h1 class="text-xl px-3">Developer Configuration</h1>
  <hr class="w-full" />
  <div class="grid mr-0">
    <div class="col-12">
      <div class="px-3">
        <h4 class="m-0">Page Restrictions</h4>
        <hr class="w-full" />
      </div>
    </div>
    <div class="grid px-5 pt-3">
      <div class="flex justify-content-center">
        <div class="flex flex-column gap-2">
          @for (route of routes; track route; let i = $index) {
            <div>
              <div>
                @if (route.path && route.data) {
                  <div class="pb-2">
                    <p-checkbox
                      [(ngModel)]="route.data.developerRestricted"
                      [label]="route.path"
                      [binary]="true"
                    (onChange)="toggleRoute(i)"></p-checkbox>
                  </div>
                }
              </div>
              @for (child of route.children; track child; let k = $index) {
                <div>
                  @if (child.path) {
                    <div class="pb-2">
                      <p-checkbox
                        [(ngModel)]="child.data.developerRestricted"
                        [label]="child.path"
                        [binary]="true"
                      (onChange)="toggleChild(i, k)"></p-checkbox>
                    </div>
                  }
                </div>
              }
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>
