import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { UtilService } from './util.service';
import { Filter } from '../components/misc/filters/filter/filter.component';

@Injectable()
export class FilterService {
  constructor(
    private util: UtilService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe(params => (this.params = params));
  }

  params: Params;

  // Adds all the collected parameters to the given filters object.
  filterParams(filters: Array<Filter>) {
    let parameters = Object.entries(this.params);
    let i = 0;
    parameters.forEach(element => {
      if (filters.filter(x => x.Column == parameters[i][0])[0] != null) {
        filters.filter(x => x.Column == parameters[i][0])[0].Value =
          element[1].split(',');
      }
      i++;
    });

    // Give all the necessary parameters for view all. (not ideal!)
    var text = filters.filter(function (x) {
      if (x.Column == 'Text') {
        return x.Value;
      }
    })[0];

    if (text != undefined) {
      if (text.Value.length > 0) {
        filters.filter(function (x) {
          if (x.Column == 'State') {
            x.Value.push('Closed');
          }
        });
      }
    }
    return filters;
  }
}
