import { DOCUMENT, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ToastService, ToastType } from '../services/global/toast.service';

// declare var toastr: any;

export type IRequestOptions = {
  method?: string;
  endpoint: string;
  body?: any;
  suppressErrors?: boolean;
  requiresVpc?: boolean;
  host?: string;
  errorCallback?: (error: any, context: any) => void;
  errorContext?: any;
};

@Injectable()
export class ApiRequest {
  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected location: Location,
    private toastService: ToastService,
    @Inject(DOCUMENT) protected document: Document
  ) {}

  public request(options: IRequestOptions): Observable<any> {
    const {
      body = '',
      method = 'POST',
      endpoint = '',
      suppressErrors = false,
      requiresVpc = false,
    } = options;
    let host = options.host ?? environment.apiUrl;
    // TODO depreciate this
    if (requiresVpc) {
      host = environment.apiUrlVpc;
    }
    const httpOptions = {
      ...(body && { body: JSON.stringify(body) }),
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return this.http.request<any>(method, `${host}${endpoint}`, httpOptions);
  }

  private handleResponseError(response: any, suppressErrors: boolean): void {
    switch (response.status) {
      case 400:
        this.toastService.add({
          severity: ToastType.error,
          summary: 'Error',
          detail: response.error,
        });
        break;
      // TODO ensure this is correct as this will chain
      case 401:
        this.router.navigateByUrl('/logout');
        break;
      case 403:
        // if (this.router.url !== "/secure/dashboard") Use this when the profile and menu bars are impersonating properly
        this.router.navigateByUrl('/unauthorised');
        break;
      case 404:
        this.router.navigateByUrl('/404');
        break;
      case 413:
      case 494:
      case 0:
        if (this.document.location.href.includes('dashboard'))
          this.router.navigateByUrl('/logout');
        else this.location.back();
        this.toastService.add({
          severity: ToastType.error,
          summary: 'Sorry',
          detail: 'There was an unexpected error loading that page.',
        });
        break;
      case 500:
        if (!suppressErrors) {
        }
        break;
    }
  }

  private async handleError(error: any, options: IRequestOptions) {
    const { endpoint, requiresVpc, errorCallback, errorContext } = options;
    try {
      return await this.request({
        endpoint: 'Exception/FrontEndError',
        body: { OriginalEndPoint: endpoint, Error: error.toString() },
        requiresVpc: requiresVpc,
      });
    } catch (secondError) {
      this.toastService.add({
        severity: ToastType.error,
        summary: 'Error',
        detail: 'Occurred while logging issue',
      });

      console.error(error);
      console.error(secondError);
    }
    if (errorCallback != undefined && errorContext != undefined) {
      options.errorCallback(error, options.errorContext);
    }
  }
}
