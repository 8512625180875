import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { firstValueFrom, from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ValidationService } from './api/validation/validation.service';
import { ContactsService } from './api/contacts/contacts.service';

@Injectable({
  providedIn: 'root',
})
export class ServiceNowValidatorService {
  constructor(
    private contactsService: ContactsService,
    private validationService: ValidationService
  ) {}

  emailExists(
    initialEmail: string,
    localEmailList: Map<string, string> = new Map<string, string>([])
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (initialEmail === control.value) {
        return of(null);
      }
      return from(
        this.contactsService.checkContactEmailExists(control.value).pipe(
          map(value => {
            const emails = Array.from(localEmailList.values());
            const dupEmails = emails.some((element, index) => {
              return emails.indexOf(element) !== index;
            });
            const result =
              value || dupEmails
                ? { forbiddenEmail: { value: control.value } }
                : null;
            return result;
          })
        )
      );
    };
  }

  checkUserExistsByEmail(
    initialEmail: string,
    localEmailList: Map<string, string> = new Map<string, string>([])
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (initialEmail === control.value) {
        return of(null);
      }
      return from(
        this.contactsService.checkContactEmailExists(control.value).pipe(
          map(value => {
            const emails = Array.from(localEmailList.values());
            const dupEmails = emails.some((element, index) => {
              return emails.indexOf(element) !== index;
            });
            const result =
              value || dupEmails
                ? null
                : { userEmailNotFound: { value: control.value } };
            return result;
          })
        )
      );
    };
  }

  roleExists(inputRoleName: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      //Save value and check whether exists
      const value = control.value;
      if (!value) {
        return null;
      }

      if (inputRoleName === value) {
        return of(null);
      }

      return from(
        firstValueFrom(this.validationService.checkRoleExists(control.value))
      ).pipe(
        map(value => {
          let result = value;
          if (value === null) {
            result = null;
          } else {
            result = { roleExists: { name: value.name, sysId: value.sysId } };
          }
          return result;
        })
      );
    };
  }
}
