import { Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { FileUpload } from 'src/app/components/misc/file-upload/file-upload';
import { FileUploadComponent } from 'src/app/components/misc/file-upload/file-upload.component';

@Component({
  selector: 'general-service-request',
  templateUrl: './general-service-request.component.html',
  styleUrls: ['./general-service-request.component.scss'],
  standalone: true,
  imports: [
    ButtonModule,
    ReactiveFormsModule,
    InputTextModule,
    FormsModule,
    FileUploadComponent,
  ],
})
export class GeneralServiceRequestComponent {
  @Input() serviceRequestForm: FormGroup;

  constructor(private fileUploadService: FileUploadService) {
    this.fileUploadService.filesToTransmit.subscribe((data: FileUpload[]) => {
      this.serviceRequestForm.value.files = data;
    });
  }
}
