import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { NospService } from 'src/app/services/api/nosp/nosp.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AlertService } from 'src/app/services/api/alert/alert.service';
import { DateTime } from 'luxon';
import { BroadBandUsage } from 'src/app/models/broadband/broadband.models';

@Injectable({
  providedIn: 'root',
})
export class BroadbandUsageService {
  constructor(
    private nospService: NospService,
    private alertService: AlertService,
    private authService: AuthService
  ) {}

  async loadUsageMonthly(username: string): Promise<BroadBandUsage[]> {
    const ADSLUserFilter = [
      {
        Column: 'Year',
        Value: [],
        Type: 'text',
        Options: [],
        Visibility: true,
      },
      { Column: 'Input', Value: [], Type: '', Options: [], Visibility: true },
      { Column: 'Output', Value: [], Type: '', Options: [], Visibility: true },
      {
        Column: 'Id',
        Value: [username],
        Type: '',
        Options: [],
        Visibility: false,
      },
    ];
    const usageData = await firstValueFrom(
      this.nospService.getAnnualTransferBreakdown(ADSLUserFilter, 0)
    );
    const formattedUsageData = usageData.results.map(item => {
      const parsedFormattedDate = DateTime.fromFormat(
        item.formattedDate,
        'MM/yyyy'
      );
      return {
        ...item,
        formattedDate: parsedFormattedDate.toJSDate(),
      };
    });
    return formattedUsageData;
  }

  //Loads table and graph display for daily data
  async loadUsageDaily(date: string, username: string) {
    const dateSplit = date.slice(1).split('/');

    const filter = {
      Username: username,
      Month: dateSplit[1],
      Year: dateSplit[2],
    };

    const data = await firstValueFrom(
      this.nospService.getDailyTransferBreakdown(filter)
    );

    const graph = await firstValueFrom(
      this.nospService.getDailyTransferBreakdownGraph(filter)
    );

    return { graph, data: data?.results || [] };
  }
}
