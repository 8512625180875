import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { NotificationsService } from 'src/app/services/api/notifications/notifications.service';
import { RadiusService } from 'src/app/services/api/radius/radius.service';
import { RequestedItemsService } from 'src/app/services/api/requested-items/requested-items.service';
import { ServiceRequestService } from 'src/app/services/api/service-request/service-request.service';
//import { RadiusService } from "src/app/services/api/radius/radius.service";

@Component({
  selector: 'misc-notification',
  templateUrl: 'misc-notification.component.html',
})
export class MiscNotificationComponent implements OnInit {
  notificationSysId: any;
  notification: any;
  radiusAccounts: any = [];
  reference: string = '';
  requestRaised: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private notificationsService: NotificationsService,
    private serviceRequestService: ServiceRequestService,
    private requestedItemsService: RequestedItemsService,
    private radiusService: RadiusService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['id'] != null || params['id'] != '') {
        this.notificationSysId = params['id'];
        this.loadNotification();
      } else {
        this.route.navigateByUrl('/secure/dashboard');
      }
    });
  }

  async loadNotification() {
    this.notification = await firstValueFrom(
      this.notificationsService.getNotification(this.notificationSysId)
    );

    if (this.notification != null && this.notification.category == 'Other') {
      let radiusAccountArr = this.notification.content.split(',');
      radiusAccountArr.forEach(async (element: any) => {
        const radiusAccount = await firstValueFrom(
          this.radiusService.getRadiusAccount(element)
        );
        this.radiusAccounts.push(radiusAccount);
      });
    }
  }

  async raiseRequest(radiusAccount: any, type: string) {
    let catalogVariables = await firstValueFrom(
      this.requestedItemsService.getRequestItemVariablesFull()
    );
    let details =
      'Name: ' + radiusAccount.name + ';' + ' Upgrade type: ' + type;
    catalogVariables[13].value = details;
    let request = {
      catalogId: '2afad9fddb320b004686f7461d961998',
      variables: catalogVariables,
    };

    const response = await firstValueFrom(
      this.serviceRequestService.create(request)
    );
    this.reference = response.number;
    this.requestRaised = true;

    this.radiusAccounts.splice(this.radiusAccounts.indexOf(radiusAccount), 1);
  }
}
