<div
  class="px-3 flex align-items-center h-4rem border-solid border-1 border-gray-200 border-bottom-1">
  <div class="flex align-items-center flex-grow-1">
    <div>
      <p-button
        styleClass="m-0"
        icon="pi pi-bars"
        aria-label="Menu"
        (click)="toggleMenu()"
        (keydown.enter)="toggleMenu()" />
    </div>
    <div class="ml-4 cursor-pointer" [routerLink]="['/secure/dashboard']">
      <strong class="text-xl">ServiceHub</strong>
      <div class="flex align-items-center">
        <small class="font-bold">By</small>
        <img
          class="w-6rem cursor-pointerm ml-1"
          src="../../../../assets/images/digitalspace-logo/digital-space-logo-black-text.svg"
          alt="Digital Space" />
      </div>
    </div>
  </div>
  <div class="flex align-items-center">
    <div class="hidden md:block mr-4">
      <p-iconField iconPosition="right">
        <p-inputIcon
          styleClass="pi pi-search cursor-pointer"
          (click)="search()"
          (keydown.enter)="search()" />
        <input
          type="text"
          pInputText
          [(ngModel)]="searchValue"
          pattern="^[a-zA-Z0-9 _.-...@]*$"
          (keyup.enter)="search()"
          type="text"
          placeholder="Search" />
      </p-iconField>
    </div>
    <a
      class="flex align-items-center justify-content-center border-circle bg-primary no-underline mr-3 p-1 help-icon"
      routerLink="/support">
      <i class="pi pi-question prime-help-icon"></i>
    </a>
    <app-menu-notifications class="mr-3"></app-menu-notifications>
    <p-avatar
      [label]="user?.firstName[0]?.toUpperCase()"
      shape="circle"
      size="large"
      (click)="avatarMenu.toggle($event)"
      class="color-primary cursor-pointer avatar-hover"
      [class.border-primary]="avatarMenu?.visible"
      [class.border-1]="avatarMenu?.visible"
      [class.border-circle]="avatarMenu?.visible"></p-avatar>
    <p-tieredMenu
      #avatarMenu
      [model]="profileMenuItems"
      [popup]="true"></p-tieredMenu>
  </div>
</div>
