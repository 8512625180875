import { IDeviceColumn } from 'src/app/components/service-hub/products-and-services/science-logic/cmdb-devices/shared/device-category-interfaces';

export const DeviceNetworkPausedColumns: IDeviceColumn[] = [
  {
    field: 'name',
    header: 'Name',
    display: { type: 'text' },
    default: true,
    order: 1,
    source: 'ServiceNow',
    disabled: true,
  },
  {
    field: 'class',
    header: 'Class',
    display: { type: 'text' },
    filter: {
      type: 'multiSelect',
      values: [],
    },
    default: true,
    source: 'ServiceNow',
  },
  {
    field: 'ipAddress',
    header: 'IP Address',
    display: { type: 'text' },
    default: true,
    source: 'ServiceNow',
  },
  {
    field: 'serialNumber',
    header: 'Serial Number',
    display: { type: 'text' },
    default: true,
    source: 'ServiceNow',
  },
  {
    field: 'pollingStatus',
    header: 'Polling Status',
    display: { type: 'text' },
    filter: {
      type: 'multiSelect',
      values: [],
    },
    default: true,
    source: 'ScienceLogic',
  },
  {
    field: 'available',
    header: 'Availability',
    display: {
      type: 'chip',
      colors: { Available: 'success', Unknown: 'dark' },
    },
    filter: {
      type: 'multiSelect',
      values: [],
    },
    default: true,
    source: 'ScienceLogic',
  },
];
