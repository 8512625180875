@if (loading) {
  <loading-spinner />
}

@if (!errorMessage?.length && !loading) {
  <div class="grid my-0 mx-3 h-full">
    <div class="col-12 lg:col-4 flex flex-column h-full m-0">
      <div
        class="flex justify-content-between align-items-center bg-white py-3 border-solid border-1 border-gray-200">
        <h1 class="px-3 text-xl">Monthly</h1>
      </div>
      <div
        class="bg-white border-solid border-1 border-gray-200 border-top-none">
        <div class="mx-3 py-3">
          <p-table
            #dt1
            [value]="usageMonthly$ | async"
            [columns]="monthTableColumn"
            styleClass="p-datatable-striped"
            [rowHover]="true"
            sortMode="multiple"
            selectionMode="single"
            [(selection)]="selectedMonth"
            dataKey="date"
            (onRowSelect)="onRowSelect($event)">
            <ng-template pTemplate="header" let-columns>
              <tr>
                @for (col of columns; track col) {
                  <th
                    class="text-white bg-gray-800 p-datatable-header-first-row"
                    [pSortableColumn]="col.field">
                    {{ col.header }}
                    @if (col.showSortIcon) {
                      <p-sortIcon
                        class="text-white"
                        [field]="col.field"></p-sortIcon>
                    }
                  </th>
                }
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-month let-columns="columns">
              <tr [pSelectableRow]="month" class="cursor-pointer">
                @for (col of columns; track col) {
                  <td>
                    @if (col.display?.type === 'text') {
                      {{ month[col.field] }}
                    }
                    @if (col.display?.type === 'date') {
                      {{ month[col.field] | date: 'MM/yyyy' }}
                    }
                  </td>
                }
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-8 h-full flex flex-column m-0">
      <div
        class="flex justify-content-between align-items-center bg-white py-3 border-solid border-1 border-gray-200">
        <h1 class="px-3 text-xl">Report - {{ reportDate }}</h1>
        <div class="mr-3">
          <p-selectButton
            [options]="stateOptions"
            [(ngModel)]="graphToggle"
            optionLabel="label"
            optionValue="value"
            class="selectbutton" />
        </div>
      </div>
      @if (!graphToggle) {
        <div
          class="flex-grow-1 overflow-hidden bg-white px-3 border-solid border-1 border-gray-200 border-top-none py-3">
          <p-table
            #dt1
            [value]="usageDaily$ | async"
            [columns]="reportTableColumns"
            styleClass="p-datatable-striped"
            [rowHover]="true"
            sortMode="multiple"
            [scrollable]="true"
            scrollHeight="flex"
            [loading]="tableLoading">
            <ng-template pTemplate="header" let-columns>
              <tr>
                @for (col of columns; track col) {
                  <th
                    class="text-white bg-gray-800 p-datatable-header-first-row"
                    [pSortableColumn]="col.field">
                    {{ col.header }}
                    @if (col.showSortIcon) {
                      <p-sortIcon
                        class="text-white"
                        [field]="col.field"></p-sortIcon>
                    }
                  </th>
                }
              </tr>
            </ng-template>
            <ng-template pTemplate="loadingbody">
              <tr>
                <td [attr.colspan]="reportTableColumns?.length + 1">
                  <loading-spinner></loading-spinner>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="loadingicon"></ng-template>
            <ng-template pTemplate="body" let-month let-columns="columns">
              <tr>
                @for (col of columns; track col) {
                  <td>
                    @if (col.display?.type === 'text') {
                      {{ month[col.field] }}
                    }
                    @if (col.display?.type === 'date') {
                      {{ month[col.field] }}
                    }
                  </td>
                }
              </tr>
            </ng-template>
          </p-table>
        </div>
      }
      @if (graphToggle) {
        <div
          class="bg-white border-solid border-1 border-gray-200 border-top-none">
          <div class="pt-3">
            <app-e-line-graph [graphData$]="graphData$"></app-e-line-graph>
          </div>
        </div>
      }
    </div>
  </div>
}
@if (errorMessage?.length) {
  <div class="flex align-items-center justify-content-center">
    <p-messages
      [(value)]="errorMessage"
      [enableService]="false"
      [closable]="false"
      [escape]="false"></p-messages>
  </div>
}
