<div class="flex flex-column h-full">
  <div
    class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200">
    <h1 class="px-3 text-xl">
      {{ pageTitle }}
    </h1>
  </div>
  <div
    class="flex flex-column items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200 border-top-none">
    <p-steps
      [model]="menuItems"
      [readonly]="true"
      [activeIndex]="activeStepIndex"
      (activeIndexChange)="onActiveIndexChange($event)"
      class="mx-auto w-full max-w-100rem">
    </p-steps>
    <div [hidden]="activeIndex?.label !== 'Request'">
      <service-request-options
        [serviceNowCatalogForm]="serviceNowCatalogForm"
        (formComplete)="onFormComplete()"
        [resetTrigger$]="reset$" />
    </div>
    @if (activeIndex?.label === 'Details') {
      @if (
        serviceNowCatalogForm.get('request').value.value ===
        OtherServiceRequestCategoryItem.VALUE
      ) {
        <general-service-request
          [serviceRequestForm]="generalRequestForm.get('general')" />
      }
      @if (
        serviceNowCatalogForm.get('request').value.value !==
        OtherServiceRequestCategoryItem.VALUE
      ) {
        <service-catalog
          (childIsLoading)="setChildIsLoading($event)"
          [serviceNowCatalogForm]="serviceNowCatalogForm" />
      }
    }
    @if (activeIndex?.label === 'Submit') {
      <submit-service-request
        [generalRequestForm]="generalRequestForm"
        [serviceNowCatalogForm]="serviceNowCatalogForm"
        (raiseAnotherEvent)="raiseAnotherRequest()" />
    }
    <div class="flex justify-content-center py-3">
      <div class="flex justify-content-center max-w-60rem w-full">
        @if (activeStepIndex !== 0 && activeStepIndex !== 2) {
          <p-button
            styleClass="p-button-secondary"
            label="Back"
            (onClick)="stepBack()">
          </p-button>
        }
        @if (
          activeStepIndex !== menuItems.length - 1 &&
          activeStepIndex !== 0 &&
          activeStepIndex !== 1 &&
          activeStepIndex !== 2
        ) {
          <p-button label="Next" (click)="stepForward()"> </p-button>
        }
        @if (activeStepIndex === 1) {
          <p-button
            (click)="stepForward()"
            label="Submit"
            [disabled]="childIsLoading"></p-button>
        }
      </div>
    </div>
  </div>
</div>
