<p-fileUpload
  [multiple]="true"
  accept=".jpeg,.jpg,.gif,.png,.bmp,.tiff,.csv,.xlsx,.docx,.pdf,.txt,.rtf,.doc,.xls"
  maxFileSize="5242880"
  (onSelect)="filesSelected($event)">
  <ng-template
    pTemplate="header"
    let-files
    let-chooseCallback="chooseCallback"
    let-clearCallback="clearCallback">
    <div
      class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
      <div class="flex gap-2">
        <p-button
          (onClick)="choose($event, chooseCallback)"
          icon="pi pi-images"
          [outlined]="true"
          label="Choose" />
        <p-button
          (onClick)="onClearTemplatingUpload(clearCallback)"
          icon="pi pi-times"
          [outlined]="true"
          severity="danger"
          [disabled]="!files || files.length === 0"
          label="Remove All" />
      </div>
    </div>
  </ng-template>
  <ng-template
    pTemplate="content"
    let-files
    let-removeFileCallback="removeFileCallback"
    let-removeUploadedFileCallback="removeUploadedFileCallback">
    @if (files?.length > 0) {
      <div>
        <h5 class="text-base">Selected Attachments</h5>
        <div class="flex flex-column p-0 gap-1">
          @for (file of files; track file; let i = $index) {
            <div class="">
              <i class="pi {{ fileIcon(file.name) }} pr-2"></i>
              <span class="pr-1">{{ file.name }}</span>
              <span>{{ formatSize(file.size) }}</span>
              <i
            (click)="
              onRemoveTemplatingFile($event, file, removeFileCallback, index)
            "
              class="pi pi-trash pl-2 hover:text-primary"></i>
            </div>
          }
        </div>
      </div>
    }
  </ng-template>
  <ng-template pTemplate="file"> </ng-template>
  <ng-template pTemplate="empty">
    <div class="flex align-items-center justify-content-center flex-column">
      <i
      class="pi pi-cloud-upload border-2 border-circle p-5 text-8xl text-400 border-400"></i>
      <p class="mt-4 mb-0">Drag and drop files to here to upload.</p>
    </div>
  </ng-template>
</p-fileUpload>
