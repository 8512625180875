import { Inject, Injectable } from '@angular/core';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';
import { IBarContainer } from 'src/app/components/service-hub/products-and-services/telephony/models/telephony-models';
import { APIEndpoints } from 'src/app/models/endpoints';

@Injectable({
  providedIn: 'root',
})
export class AbzordService {
  constructor(private apiRequest: ApiRequest) {}

  getBars(mobileNumber: string) {
    const requestProps: IRequestOptions = {
      endpoint: APIEndpoints.GetBars,
      body: mobileNumber,
    };
    return this.apiRequest.request(requestProps);
  }

  getActiveBars(mobileNumber: string) {
    const requestProps: IRequestOptions = {
      endpoint: APIEndpoints.GetActiveBars,
      body: mobileNumber,
    };
    return this.apiRequest.request(requestProps);
  }

  getBarsCategorised(mobileNumber: string) {
    const requestProps: IRequestOptions = {
      endpoint: APIEndpoints.GetBarsCategorised,
      body: mobileNumber,
    };
    return this.apiRequest.request(requestProps);
  }

  toggleBars(request: IBarContainer) {
    const requestProps: IRequestOptions = {
      endpoint: APIEndpoints.ToggleBars,
      body: request,
    };
    return this.apiRequest.request(requestProps);
  }

  //ToDO add type for request
  toggleMultipleBars(request: any) {
    const requestProps: IRequestOptions = {
      endpoint: APIEndpoints.ToggleMultipleBars,
      body: request,
    };
    return this.apiRequest.request(requestProps);
  }
}
