import { Injectable } from '@angular/core';
import { Filter } from 'src/app/components/misc/filters/filter/filter.component';
import { DictionaryService } from 'src/app/services/dictionary.service';
import {
  IBundleDetailsRequest,
  ISelectedSite,
} from '../models/telephony-models';
import { UserService } from 'src/app/services/api/user/user.service';
import { TelephonyApiService } from 'src/app/services/api/telephony-api/telephony-api.service';
import { firstValueFrom } from 'rxjs';
import { ServiceNowFilterQuery } from 'src/app/models/servicenow.model';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';

@Injectable({
  providedIn: 'root',
})
export class TelephonyService {
  constructor(
    private dictionary: DictionaryService,
    private userService: UserService,
    private telephonyApiService: TelephonyApiService,
    private apiRequest: ApiRequest
  ) {}

  selectedSite: ISelectedSite;

  async loadSites() {
    return await firstValueFrom(
      this.userService.getContactBillingEntities('invoice')
    );
  }

  async loadBundleUsage(requestBody: IBundleDetailsRequest) {
    const bundleData = await firstValueFrom(
      this.telephonyApiService.getBundleDetailsByCli(requestBody)
    );
    return bundleData;
  }

  async loadGraphData(graphDataType: string, mobileNumber: string) {
    let graphUrl = this.dictionary.mobileGraphUrlByDataType(graphDataType);
    if (graphUrl) {
      // TODO fix for live
      // return this.api.post(graphUrl, mobileNumber, [], false, true);
    }
  }

  async loadMonthlyUsageGraphData(mobileNumber) {
    return await firstValueFrom(
      this.telephonyApiService.getMonthlyUsageGraphs(mobileNumber)
    );
  }
}
