@if (!supportContacts) {
  <div>
    <loading-spinner />
  </div>
}

@if (supportContacts) {
  <div class="flex flex-column h-full">
    <div
      class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200">
      <h1 class="px-3 text-xl">Contact Digital Space</h1>
    </div>

    <div
      class="overflow-auto mx-3 px-3 overflow-hidden bg-white border-solid border-1 border-gray-200 border-top-none">
      <div class="flex flex-wrap gap-8 justify-content-center pb-3">
        <div class="flex-item w-full md:w-4 lg:w-3">
          <h2 class="text-lg font-bold">Account Manager</h2>
          <p class="m-0">Name: {{ accountManager?.Name }}</p>
          @if (
            accountManager?.BusinessNumber !== undefined &&
            accountManager?.BusinessNumber !== ''
          ) {
            <p class="m-0">
              Business Number: {{ accountManager?.BusinessNumber }}
            </p>
          }
          @if (
            accountManager?.MobileNumber !== undefined &&
            accountManager?.MobileNumber !== ''
          ) {
            <p class="m-1">Mobile Number: {{ accountManager?.MobileNumber }}</p>
          }
          @if (accountManager?.Email !== '') {
            <p class="m-0">
              Email:
              <a
                class="text-primary font-bold m-1"
                href="mailto:{{ accountManager?.Email }}"
                >{{ accountManager?.Email }}</a
              >
            </p>
          }
        </div>

        <div class="flex-item w-full md:w-4 lg:w-3">
          <h2 class="text-lg font-bold">Service Manager</h2>
          <p class="m-0">Name: {{ clientManager?.Name }}</p>
          @if (
            clientManager?.BusinessNumber !== undefined &&
            clientManager?.BusinessNumber !== ''
          ) {
            <p class="m-0">
              Business Number: {{ clientManager?.BusinessNumber }}
            </p>
          }
          @if (
            clientManager?.MobileNumber !== undefined &&
            clientManager?.MobileNumber !== ''
          ) {
            <p class="m-0">Mobile Number: {{ clientManager?.MobileNumber }}</p>
          }
          @if (clientManager?.Email !== '') {
            <p class="m-0">
              Email:
              <a
                class="text-primary font-bold"
                href="mailto:{{ clientManager?.Email }}"
                >{{ clientManager?.Email }}</a
              >
            </p>
          }
        </div>

        <div class="flex-item w-full md:w-4 lg:w-3">
          <h2 class="text-lg font-bold">Credit Controller</h2>
          <p class="m-0">Name: {{ creditController?.Name }}</p>
          @if (
            creditController?.BusinessNumber !== undefined &&
            creditController?.BusinessNumber !== ''
          ) {
            <p class="m-0">
              Business Number: {{ creditController?.BusinessNumber }}
            </p>
          }
          @if (
            creditController?.MobileNumber !== undefined &&
            creditController?.MobileNumber !== ''
          ) {
            <p class="m-0">
              Mobile Number: {{ creditController?.MobileNumber }}
            </p>
          }
          @if (creditController?.Email !== '') {
            <p class="m-0">
              Email:
              <a
                class="text-primary font-bold"
                href="mailto:{{ creditController?.Email }}"
                >{{ creditController?.Email }}</a
              >
            </p>
          }
        </div>
      </div>
    </div>
  </div>
}
