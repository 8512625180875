import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Pac, TicketData } from '../../shared/incident-model';
import { ButtonModule } from 'primeng/button';
import { LoadingSpinnerComponent } from 'src/app/components/misc/loading-spinner/loading-spinner.component';
import { Router } from '@angular/router';
import { ICreateInteractionRequest } from 'src/app/services/api/interaction/interaction-models';
import { InteractionService } from 'src/app/services/api/interaction/interaction.service';
import { FormGroup } from '@angular/forms';
import { DateTime } from 'luxon';
import { MessagesModule } from 'primeng/messages';
import { CatalogItemsService } from 'src/app/services/api/catalog-items/catalog-items.service';
import { ServiceCatalogOrderResponse } from 'src/app/models/service-catalog/service-catalog.models';
import { Clipboard } from '@angular/cdk/clipboard';
import { OtherServiceRequestCategoryItem } from '../service-request-options/service-request-options.component';

@Component({
  selector: 'submit-service-request',
  templateUrl: './submit-service-request.component.html',
  styleUrls: ['./submit-service-request.component.scss'],
  standalone: true,
  imports: [ButtonModule, LoadingSpinnerComponent, MessagesModule],
})
export class SubmitServiceRequestComponent implements OnInit {
  @Input() generalRequestForm: FormGroup;
  @Input() serviceNowCatalogForm: FormGroup;

  @Output() raiseAnotherEvent = new EventEmitter<unknown>();

  serviceNowMobileNumberVariables: object = {};
  pacRequestInfo: Pac;
  requestNumber: string;
  requestSysId: string;
  fileUploadErrors: object;
  serviceRequestInfo: TicketData;
  isRecentVisit = false;
  showLoader = false;
  orderResponse: ServiceCatalogOrderResponse;
  textAlert = '';
  pollMaxAttemptsReached = false;

  catalogItemId: string;

  OtherServiceRequestCategoryItem = OtherServiceRequestCategoryItem;
  isDcAccessRequest = false;

  constructor(
    private interactionService: InteractionService,
    private catalogItemsService: CatalogItemsService,
    private router: Router,
    private clipboard: Clipboard
  ) {}

  async ngOnInit() {
    this.isDcAccessRequest = this.getSubmitLabel(
      this.serviceNowCatalogForm.get('request').value.label
    );
    await this.submitRequest();
    this.isRecentVisit = this.getEndDate();
  }

  async submitRequest(): Promise<void> {
    this.showLoader = true;
    this.catalogItemId = this.serviceNowCatalogForm.value.request.value;
    switch (this.catalogItemId) {
      case OtherServiceRequestCategoryItem.VALUE: {
        const request: ICreateInteractionRequest = {
          title: this.generalRequestForm.value.general.shortDescription,
          description: this.generalRequestForm.value.general.description,
          files: this.generalRequestForm.value.general.files,
        };
        this.fileUploadErrors =
          this.generalRequestForm.value.general.files?.filter(
            f => f.errorMessage != ''
          );
        const response = await firstValueFrom(
          this.interactionService.createInteraction(request)
        );
        if (response) {
          this.showLoader = false;
        }
        break;
      }
      default: {
        const request = {
          catalogItemId: this.catalogItemId,
          variables: { ...this.serviceNowCatalogForm.value.variables },
        };

        this.orderResponse = await firstValueFrom(
          this.catalogItemsService.CreateServiceCatalogOrder(request)
        );

        if (this.orderResponse) {
          const pollingInterval = 2000;
          const maxAttempts = 5;
          let attempts = 0;

          const poll = async () => {
            if (attempts >= maxAttempts) {
              this.showLoader = false;
              this.pollMaxAttemptsReached = true;
              return;
            }

            attempts++;

            try {
              const statusResponse = await firstValueFrom(
                this.catalogItemsService.CheckForOrderRequestItemTask(
                  this.orderResponse.number
                )
              );

              if (statusResponse) {
                this.requestNumber = statusResponse.number;
                this.requestSysId = statusResponse.sysId;
                this.showLoader = false;
                return;
              }
            } catch (error) {
              console.error(error);
            }

            setTimeout(poll, pollingInterval);
          };

          await poll();
        }

        break;
      }
    }
  }

  viewTicket(): void {
    this.router.navigateByUrl(
      `secure/tickets/service-request/${this.requestSysId}`
    );
  }

  viewServiceRequests() {
    this.router.navigateByUrl(`secure/tickets/service-requests`);
  }

  raiseAnotherRequest(): void {
    this.generalRequestForm?.reset();
    this.serviceNowCatalogForm?.reset();
    this.raiseAnotherEvent.emit();
  }

  copyRequestNumber(): void {
    this.clipboard.copy(this.requestNumber);
    this.textAlert = 'Request number copied to clipboard';
  }

  getEndDate(): boolean {
    if (
      this.serviceNowCatalogForm.value.category.label !== 'DC Access Request'
    ) {
      return;
    }

    const now = DateTime.now();
    const visitTime =
      this.serviceNowCatalogForm?.value?.variables.date_and_time_of_access;
    const luxonVisitTime = DateTime.fromJSDate(new Date(visitTime));
    return Math.abs(now.diff(luxonVisitTime, 'minutes').minutes) < 1440;
  }

  getSubmitLabel(requestForm: string): boolean {
    return !!requestForm && requestForm === 'DC Access Request';
  }
}
