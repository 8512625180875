import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';
import { ProductCategories } from 'src/app/models/billing/billing.models';
import { ITableToFile } from 'src/app/models/export-models';
import { ServiceNowFilterQuery } from 'src/app/models/servicenow.model';

@Injectable({
  providedIn: 'root',
})
export class ProductAPIService {
  constructor(private apiRequest: ApiRequest) {}

  getProduct(request: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: 'Product/GetProduct',
      body: request,
      requiresVpc: true,
    };

    return this.apiRequest.request(requestProps);
  }

  getProductCategories(request:string):Observable<ProductCategories[]>{
    const requestProps: IRequestOptions = {
      endpoint: 'Product/GetProductCategories',
      body: request,
      requiresVpc: true,
    };

    return this.apiRequest.request(requestProps);
  }

  getProductsAdditionalData(productResults: any, filter: any, siteId: string) {
    const requestProps: IRequestOptions = {
      endpoint: 'Product/GetProductsAdditionalData',
      body: {
        products: productResults,
        filter: filter,
      },
      requiresVpc: true,
    };

    return this.apiRequest.request(requestProps);
  }

  GetProduct(
    categoryID: string,
    filter: any,
    pageNumbner: number,
    pageSize: number,
    pageNation: any
  ) {
    const requestProps: IRequestOptions = {
      endpoint: 'Product/GetProduct',
      body: {
        SiteId: categoryID,
        filter: filter,
        currentPage: pageNumbner,
        amount: pageSize,
        pagination: pageNation,
      },
      requiresVpc: true,
    };

    return this.apiRequest.request(requestProps);
  }

  GetProductsAdditionalData(
    product: any,
    filter: any,
    siteId: string,
    exportColums: any,
    exportValue: boolean
  ) {
    const requestProps: IRequestOptions = {
      endpoint: 'Product/GetProductsAdditionalData',
      body: {
        products: product,
        filter: filter,
        siteId: siteId,
        exportColums: exportColums,
        export: exportValue,
      },
      requiresVpc: true,
    };

    return this.apiRequest.request(requestProps);
  }
}
