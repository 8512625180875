<div class="flex flex-column h-full">
  <h1 class="text-xl px-3">Expedite Incident</h1>
  <hr class="w-full" />
  <div class="px-4">
    <p class="text-base m-0 pb-2">
      Raising an Expedite request will alert our team that you would like the priority on this incident to be increased.
      The request to expedite will be reviewed by the team and the priority changed if appropriate.<br />
      Please provide your reasons below to help us fully understand the situation and review.
    </p>
    <p-messages [(value)]="detail" [enableService]="false" [closable]="false" />
    <div class="pb-1">
      <span
        ><span class="text-primary text-lg">*</span
        ><label for="Reason" class="text-lg font-bold"
        >Request Reason
      </label></span
      >
      <form [formGroup]="expediteForm">
        <textarea
          pInputText
          id="Reason"
          placeholder="Reason"
          formControlName="expediteReason"
          aria-label="reason"
          type="text"
          rows="4"
        class="w-full"></textarea>
        @if (
          expediteForm.get('expediteReason').invalid &&
          expediteForm.get('expediteReason').touched
          ) {
          <small
            class="p-error text-base">
            Reason is required.
          </small>
        }
      </form>
    </div>
    <div class="flex align-content-center justify-content-center py-3">
      <p-button
        styleClass="p-button-secondary"
        label="Cancel"
      (onClick)="closeButton()"></p-button>
      <p-button
        styleClass="p-button"
        [disabled]="expediteForm.invalid"
        label="Submit"
      (onClick)="expediteIncident()"></p-button>
    </div>
  </div>
</div>
