import { Inject, Injectable } from '@angular/core';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor(private apiRequest: ApiRequest) {}

  getUserCookiesPreferences(sysId: string, cookiesType: string) {
    const requestProps: IRequestOptions = {
      endpoint: 'UserCookies/GetUserCookiesPreferences',
      body: {
        SysId: sysId,
        UCookiesType: cookiesType,
      },
    };
    return this.apiRequest.request(requestProps);
  }

  GetUserCookiesPreferences() {
    const requestProps: IRequestOptions = {
      endpoint: 'UserCookies/GetUserCookiesPreferences',
    };
    return this.apiRequest.request(requestProps);
  }

  updateUserCookiesPreferences(settings: any) {
    const requestProps: IRequestOptions = {
      endpoint: 'UserCookies/UpdateUserCookiesPreferences',
      body: settings,
    };
    return this.apiRequest.request(requestProps);
  }
}
