import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DateTime } from 'luxon';
import { GetServiceCatalogVariablesResponse } from 'src/app/models/service-catalog/service-catalog.models';
import { RadioButtonModule } from 'primeng/radiobutton';
@Component({
  selector: 'app-service-catalog-item',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextModule,
    TableModule,
    ButtonModule,
    CalendarModule,
    RadioButtonModule,
  ],
  templateUrl: './service-catalog-item.component.html',
  styleUrl: './service-catalog-item.component.scss',
})
export class ServiceCatalogItemComponent implements OnInit {
  @Input() item: GetServiceCatalogVariablesResponse;
  @Input() formGroup: FormGroup;
  today: Date;
  usedItemTypes = [
    'one_to_one',
    'one_to_many',
    'email',
    '1',
    '3',
    '5',
    '10',
    '2',
    '6',
    '9',
  ];

  @ViewChild('containerTemplate', { static: true })
  containerTemplate!: TemplateRef<unknown>;
  @ViewChild('emailInputTemplate', { static: true })
  emailInputTemplate!: TemplateRef<unknown>;
  @ViewChild('radioButtonTemplate', { static: true })
  radioButtonTemplate!: TemplateRef<unknown>;
  @ViewChild('selectTemplate', { static: true })
  selectTemplate!: TemplateRef<unknown>;
  @ViewChild('textInputTemplate', { static: true })
  textInputTemplate!: TemplateRef<unknown>;
  @ViewChild('numberInputTemplate', { static: true })
  numberInputTemplate!: TemplateRef<unknown>;
  @ViewChild('tableInputTemplate', { static: true })
  tableInputTemplate!: TemplateRef<unknown>;
  @ViewChild('dateTimeTemplate', { static: true })
  dateTimeTemplate!: TemplateRef<unknown>;
  @ViewChild('textAreaTemplate', { static: true })
  textAreaTemplate!: TemplateRef<unknown>;
  @ViewChild('dateTemplate', { static: true })
  dateTemplate!: TemplateRef<unknown>;

  constructor(
    private fb: FormBuilder,
    private formBuilder: UntypedFormBuilder
  ) {}

  async ngOnInit(): Promise<void> {
    this.today = DateTime.now().toJSDate();
    if (!['one_to_one', 'one_to_many'].includes(this.item.type)) {
      const control = this.createFormControl(this.item);
      if (control) {
        this.formGroup.addControl(this.item.name, control);
      }
    }
    if (this.item.type === 'one_to_many') {
      this.item.mandatory = this.item.children.find(child => child.mandatory)
        ? true
        : this.item.mandatory;
      const formArray = this.addRow(
        this.fb.array<FormGroup>([], {
          validators: this.item.mandatory ? Validators.required : null,
        })
      );
      this.formGroup.addControl(this.item.internalName, formArray);
    }
  }

  createFormControl(item: GetServiceCatalogVariablesResponse) {
    if (!this.usedItemTypes.includes(this.item?.type)) {
      return;
    }

    const validators = [];
    if (item.mandatory) {
      validators.push(Validators.required);
    }
    if (item.validateRegex) {
      validators.push(Validators.pattern(item.validateRegex.regex));
    }
    return this.formBuilder.control(item.visible ? '' : 'n/a', validators);
  }

  setItemField(
    item: GetServiceCatalogVariablesResponse,
    field: string,
    value: boolean
  ) {
    item[field] = value;
    return item;
  }

  addRow(formArray: FormArray) {
    const rowGroup = this.fb.group({});
    for (const child of this.item.children) {
      const control = this.createFormControl(child);
      rowGroup.addControl(child.name, control);
    }
    formArray.push(rowGroup);
    return formArray;
  }

  async deleteUser(formArray: FormArray, index: number) {
    formArray.removeAt(index);
  }

  getTemplate(): TemplateRef<unknown> | null {
    if (!this.item.visible) {
      return null;
    }
    const templateMap = new Map<string | number, TemplateRef<unknown>>([
      ['one_to_one', this.containerTemplate],
      ['one_to_many', this.tableInputTemplate],
      ['email', this.emailInputTemplate],
      ['3', this.radioButtonTemplate],
      ['1', this.selectTemplate], // yes/no
      ['5', this.selectTemplate], // select box
      ['10', this.dateTimeTemplate], //Date & time
      ['2', this.textAreaTemplate],
      ['9', this.dateTemplate], //Date only
    ]);

    if (this.item.type === '6') {
      return this.item.validateRegex?.name === 'Number' ||
        this.item.validateRegex?.name === 'Number (1-100)'
        ? this.numberInputTemplate
        : this.textInputTemplate;
    }

    return templateMap.get(this.item.type) || null;
  }

  getErrorMessage(item: GetServiceCatalogVariablesResponse): string {
    const field = this.formGroup.get(item.name);
    if (field?.hasError('required')) {
      return `${item.questionText} is required.`;
    }
    if (field?.hasError('pattern')) {
      return `${item.questionText} ${item.validateRegex?.validationMessage.toLowerCase()}`;
    }
    return '';
  }

  checkLimit(item: GetServiceCatalogVariablesResponse): boolean {
    const value = this.formGroup.get(item?.internalName)?.value;
    if (!Array.isArray(value) || !value.length) return false;

    const first = value[0];
    return 'name' in first && 'visitor_company' in first
      ? value.length === 5
      : 'number' in first
        ? value.length === 25
        : false;
  }
}
