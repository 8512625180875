import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import {
  GetOutageResponse,
  OutageResponse,
  service,
} from 'src/app/models/outage/outage.models';

@Component({
  selector: 'service-status-widget',
  templateUrl: './service-status-widget.component.html',
  styleUrls: ['./service-status-widget.component.scss'],
})
export class ServiceStatusWidgetComponent {
  @Input() service: GetOutageResponse;
  @Input() iconClick = true;
  constructor(private router: Router) {}

  getServiceLabel(service: GetOutageResponse, state: string): string {
    const states = {
      Outage: 'Planned Maintenance',
      'Planned Maintenance': 'Degradation',
      Degradation: 'Good',
    };

    if (!states[state]) {
      return 'Good';
    }

    const detail = service.details.find(d => d.type === state);

    const openEvents = service.details
      .filter(d => d.type === state || states[state])
      .reduce((total, d) => total + (d.open?.length || 0), 0);

    if (!detail || !detail.open.length) {
      return this.getServiceLabel(service, states[state]);
    }

    if (openEvents > 1) {
      return `${openEvents} events`;
    }

    if (state === 'Planned Maintenance') {
      const hasActivePlan = detail.open.some(plan =>
        this.checkIsActivePlanned(plan)
      );
      return hasActivePlan ? 'Planned Maintenance' : 'Upcoming Maintenance';
    }

    return state;
  }

  getServiceIcon(service: GetOutageResponse, state: string): string {
    const statePriority = [
      'Outage',
      'Planned Maintenance',
      'Degradation',
      'Good',
    ];

    const nextState = currentState => {
      const currentIndex = statePriority.indexOf(currentState);
      return statePriority[currentIndex + 1] || 'Good';
    };

    const details = service.details.find(d => d.type === state);

    if (details && details.open.length > 0) {
      return 'exclamation-triangle';
    } else if (state !== 'Good') {
      return this.getServiceIcon(service, nextState(state));
    } else {
      return 'check-circle';
    }
  }

  checkIsActivePlanned(outageDetail: OutageResponse): boolean {
    const today = DateTime.now().setLocale('en-GB').toFormat('dd/MM/yyyy');
    const start = DateTime.fromFormat(
      outageDetail.start.split(' ')[0],
      'dd/MM/yyyy'
    );
    return start < DateTime.fromFormat(today, 'dd/MM/yyyy');
  }

  handleIconClicked(event: service) {
    const name: string = event.name;
    this.router.navigateByUrl(
      `secure/products-and-services/services/status/${name}`
    );
  }
}
