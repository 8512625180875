@if (loading) {
  <loading-spinner />
}
@if (!loading) {
  <div class="flex flex-column h-full bg-white">
    <h1 class="text-xl px-3">Amend Options</h1>
    <hr class="w-full" />
    <div class="flex flex-column items-center">
      <p-steps
        [model]="items"
        [readonly]="true"
        [activeIndex]="activeStepIndex"
        (activeIndexChange)="onActiveIndexChange($event)"
        class="mx-auto w-full max-w-60rem">
      </p-steps>
      @if (activeIndex?.label === 'O2 Options') {
        <div
          class="flex flex-column align-items-center justify-content-center bg-white"
          >
          @if (formLoadedO2 === true) {
            <div
              class="flex flex-column align-items-center bg-white">
              <div class="flex align-items-center">
                <p class="text-lg m-0">
                  Numbers:
                  {{ o2Mobiles?.slice(0, 3).join(', ').split(',').join(', ') }}
                </p>
                @if (o2Mobiles?.length > 3) {
                  <p-badge
                    class="ml-2 cursor-pointer"
                    severity="success"
                    (click)="openNumbers(o2Mobiles)"
                  [value]="o2Mobiles?.length - 3"></p-badge>
                }
              </div>
              @if (
                getFormCategory(barringFormO2, formSectionStrings.general).value
                .length > 0
                ) {
                <div
                  class="px-6">
                  <h3 class="font-normal">General Bars</h3>
                  <div class="border-1 border-500">
                    <div class="grid">
                      @for (
                        bar of getFormCategory(
                        barringFormO2,
                        formSectionStrings.general
                        ).controls
                        ; track
                        bar) {
                        <div class="col-12 md:col-6 p-3">
                          <div class="flex align-items-center ml-3">
                            <p class="mr-3">{{ bar.value.name }}</p>
                            <div
                              [ngClass]="cssSetDropdownStyle(bar.get('active')?.value)"
                              class="flex align-items-center">
                              <p-dropdown
                                [formControl]="bar.get('active')"
                                [options]="dropdownOptions"
                                optionLabel="label"
                              optionValue="value"></p-dropdown>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                  @if (
                    getFormCategory(barringFormO2, formSectionStrings.roaming).value
                    .length > 0
                    ) {
                    <div
                      >
                      <h3 class="font-normal">Roaming Bars</h3>
                      <div class="border-1 border-500">
                        <div class="grid">
                          @for (
                            bar of getFormCategory(
                            barringFormO2,
                            formSectionStrings.roaming
                            ).controls
                            ; track
                            bar) {
                            <div class="col-12 md:col-6 p-3">
                              <div class="flex align-items-center ml-3">
                                <p class="mr-3">{{ bar.value.name }}</p>
                                <div
                        [ngClass]="
                          cssSetDropdownStyle(bar.get('active')?.value)
                        "
                                  class="flex align-items-center">
                                  <p-dropdown
                                    [formControl]="bar.get('active')"
                                    [options]="dropdownOptions"
                                    optionLabel="label"
                                  optionValue="value"></p-dropdown>
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                      @if (
                        getFormCategory(barringFormO2, formSectionStrings.calling).value
                        .length > 0
                        ) {
                        <div
                          >
                          <h3 class="font-normal">Calling Features and Services</h3>
                          <div class="border-1 border-500">
                            <div class="grid">
                              @for (
                                bar of getFormCategory(
                                barringFormO2,
                                formSectionStrings.calling
                                ).controls
                                ; track
                                bar) {
                                <div class="col-12 md:col-6 p-3">
                                  <div class="flex align-items-center ml-3">
                                    <p class="mr-3">{{ bar.value.name }}</p>
                                    <div
                          [ngClass]="
                            cssSetDropdownStyle(bar.get('active')?.value)
                          "
                                      class="flex align-items-center">
                                      <p-dropdown
                                        [formControl]="bar.get('active')"
                                        [options]="dropdownOptions"
                                        optionLabel="label"
                                      optionValue="value"></p-dropdown>
                                    </div>
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                          @if (formLoadedO2 === true) {
                            <div
                              class="flex align-content-center justify-content-center py-4">
                              <p-button
                                styleClass="p-button-secondary"
                                (onClick)="navigateToMobileTable()"
                              label="Back"></p-button>
                              <p-button
                                label="Next"
                              (onClick)="updateDirtyValues()"></p-button>
                            </div>
                          }
                        </div>
                      }
                    </div>
                  }
                </div>
              }
            </div>
          }
        </div>
      }
      <!--Vodafone-->
      @if (activeIndex?.label === 'Vodafone Options') {
        <div
          class="flex flex-column align-items-center justify-content-center bg-white"
          >
          @if (FormLoadedVF) {
            <div class="flex flex-column align-items-center">
              <div class="flex align-items-center">
                <p class="text-lg m-0">
                  Numbers:
                  {{ vfMobiles?.slice(0, 3).join(', ').split(',').join(', ') }}
                </p>
                @if (vfMobiles?.length > 3) {
                  <p-badge
                    class="ml-2 cursor-pointer"
                    severity="success"
                    (click)="openNumbers(vfMobiles)"
                  [value]="vfMobiles?.length - 3"></p-badge>
                }
              </div>
              <div class="px-6">
                <h3 class="font-normal">General Bars</h3>
                <div class="border-1 border-500">
                  <div class="grid">
                    @for (
                      bar of getFormCategory(
                      barringFormVF,
                      formSectionStrings.general
                      ).controls
                      ; track
                      bar) {
                      <div class="col-12 md:col-6 p-3">
                        <div class="flex align-items-center ml-3">
                          <p class="mr-3">{{ bar.value.name }}</p>
                          <div
                            [ngClass]="cssSetDropdownStyle(bar.get('active')?.value)"
                            class="flex align-items-center">
                            <p-dropdown
                              [formControl]="bar.get('active')"
                              [options]="dropdownOptions"
                              optionLabel="label"
                            optionValue="value"></p-dropdown>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                @if (
                  getFormCategory(barringFormVF, formSectionStrings.roaming).value
                  .length > 0
                  ) {
                  <div
                    >
                    <h3 class="font-normal">Roaming Bars</h3>
                    <div class="border-1 border-500">
                      <div class="grid">
                        @for (
                          bar of getFormCategory(
                          barringFormVF,
                          formSectionStrings.roaming
                          ).controls
                          ; track
                          bar) {
                          <div class="col-12 md:col-6 p-3">
                            <div class="flex align-items-center ml-3">
                              <p class="mr-3">{{ bar.value.name }}</p>
                              <div
                        [ngClass]="
                          cssSetDropdownStyle(bar.get('active')?.value)
                        "
                                class="flex align-items-center">
                                <p-dropdown
                                  [formControl]="bar.get('active')"
                                  [options]="dropdownOptions"
                                  optionLabel="label"
                                optionValue="value"></p-dropdown>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                    @if (
                      getFormCategory(barringFormVF, formSectionStrings.roamingCap)
                      .value.length > 0
                      ) {
                      <div
                        >
                        <h3 class="font-normal">Data Roaming Caps</h3>
                        <div class="border-1 border-500">
                          <div class="grid">
                            @for (
                              bar of getFormCategory(
                              barringFormVF,
                              formSectionStrings.roamingCap
                              ).controls
                              ; track
                              bar) {
                              <div class="col-12 md:col-6 p-3">
                                <div class="flex align-items-center ml-3">
                                  <p class="mr-3">{{ bar.value.name }}</p>
                                  <div
                          [ngClass]="
                            cssSetDropdownStyle(bar.get('active')?.value)
                          "
                                    class="flex align-items-center">
                                    <p-dropdown
                                      [formControl]="bar.get('active')"
                                      [options]="dropdownOptions"
                                      optionLabel="label"
                                    optionValue="value"></p-dropdown>
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                        @if (
                          getFormCategory(barringFormVF, formSectionStrings.calling)
                          .value.length > 0
                          ) {
                          <div
                            >
                            <h3 class="font-normal">Calling Features and Services</h3>
                            <div class="border-1 border-500">
                              <div class="grid">
                                @for (
                                  bar of getFormCategory(
                                  barringFormVF,
                                  formSectionStrings.calling
                                  ).controls
                                  ; track
                                  bar) {
                                  <div class="col-12 md:col-6 p-3">
                                    <div class="flex align-items-center ml-3">
                                      <p class="mr-3">{{ bar.value.name }}</p>
                                      <div
                            [ngClass]="
                              cssSetDropdownStyle(bar.get('active')?.value)
                            "
                                        class="flex align-items-center">
                                        <p-dropdown
                                          [formControl]="bar.get('active')"
                                          [options]="dropdownOptions"
                                          optionLabel="label"
                                        optionValue="value"></p-dropdown>
                                      </div>
                                    </div>
                                  </div>
                                }
                              </div>
                            </div>
                            @if (FormLoadedVF) {
                              <div
                                class="flex align-content-center justify-content-center py-4">
                                <p-button
                                  styleClass="p-button-secondary"
                                  (onClick)="stepBack()"
                                label="Back"></p-button>
                                <p-button
                                  label="Next"
                                (onClick)="updateDirtyValuesVF()"></p-button>
                              </div>
                            }
                          </div>
                        }
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
          }
        </div>
      }
      <!--Confirm-->
      @if (activeIndex?.label === 'Confirm') {
        <div
          class="flex flex-column align-items-center justify-content-center"
          >
          <div>
            @if (o2Mobiles.length > 0) {
              <p class="text-xl font-bold">O2 Option Changes</p>
              <div class="flex align-items-center">
                <p class="text-lg m-0">
                  Numbers:
                  {{ o2Mobiles.slice(0, 3).join(', ').split(',').join(', ') }}
                </p>
                @if (o2Mobiles?.length > 3) {
                  <p-badge
                    class="ml-2 cursor-pointer"
                    severity="success"
                    (click)="openNumbers(o2Mobiles)"
                  [value]="o2Mobiles?.length - 3"></p-badge>
                }
              </div>
              @if (updatedControlsO2?.nonCategorised.length === 0) {
                <div
                  class="pt-4"
                  >
                  No updates have been selected for your current bar settings.
                </div>
              }
              @for (controlCategory of updatedControlsO2?.categorised; track controlCategory) {
                @if (controlCategory.updatedValues.length > 0) {
                  <div class="">
                    <h3>{{ controlCategory.name | titlecase }}</h3>
                    <ul class="list-none">
                      @for (control of controlCategory.updatedValues; track control) {
                        <li>
                          {{ control.value.name }}
                          <span
                            class="ml-1"
                    [ngStyle]="{
                      color: barringService.getConfirmTextColor(
                        control.value.active
                      ),
                    }">
                            {{ convertActive(control.value.active) }}
                          </span>
                        </li>
                      }
                    </ul>
                  </div>
                }
              }
            }
            @if (vfMobiles.length > 0) {
              <div>
                <p class="text-xl font-bold">Vodafone Option Changes</p>
                <div class="flex align-items-center">
                  <p class="text-lg m-0">
                    Numbers:
                    {{ vfMobiles.slice(0, 3).join(', ').split(',').join(', ') }}
                  </p>
                  @if (vfMobiles?.length > 3) {
                    <p-badge
                      class="ml-2 cursor-pointer"
                      severity="success"
                      (click)="openNumbers(vfMobiles)"
                    [value]="vfMobiles?.length - 3"></p-badge>
                  }
                </div>
                @if (updatedControlsVF?.nonCategorised.length === 0) {
                  <div
                    class="pt-4"
                    >
                    No updates have been selected for your current bar settings.
                  </div>
                }
                @for (controlCategory of updatedControlsVF?.categorised; track controlCategory) {
                  @if (controlCategory.updatedValues.length > 0) {
                    <div class="">
                      <h3>{{ controlCategory.name | titlecase }}</h3>
                      <ul class="list-none">
                        @for (control of controlCategory.updatedValues; track control) {
                          <li>
                            {{ control.value.name }}
                            <span
                              class="ml-1"
                      [ngStyle]="{
                        color: barringService.getConfirmTextColor(
                          control.value.active
                        ),
                      }">
                              {{ convertActive(control.value.active) }}
                            </span>
                          </li>
                        }
                      </ul>
                    </div>
                  }
                }
              </div>
            }
            <div class="flex align-content-center justify-content-center py-4">
              <p-button
                styleClass="p-button-secondary"
                (onClick)="stepBack()"
              label="Back"></p-button>
              <p-button
                label="Submit"
                (onClick)="submitForm()"
            [disabled]="
              updatedControlsO2?.nonCategorised.length === 0 &&
              updatedControlsVF?.nonCategorised.length === 0
            "></p-button>
            </div>
          </div>
        </div>
      }
      <!--Submit-->
      @if (activeIndex?.label === 'Submit') {
        <div
          class="flex flex-column align-items-center justify-content-center"
          >
          @if (!submitFinished) {
            <div>
              <loading-spinner />
            </div>
          }
          @if (submitFinished) {
            <div>
              <p>The barring settings have been updated.</p>
              <div class="flex align-content-center justify-content-center">
                <p-button
                  label="Finish"
                (onClick)="navigateToMobileData()"></p-button>
              </div>
            </div>
          }
        </div>
      }
    </div>
  </div>
}
