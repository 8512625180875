import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { Subscription, firstValueFrom } from 'rxjs';
import { ObservabilityService } from 'src/app/services/api/observability/observability.service';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, Message } from 'primeng/api';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import { ScienceLogicCompanys } from '../cmdb-devices/shared/company-models';
import { PermissionService } from 'src/app/services/permissions.service';
interface ErrorOptions {
  severity: string;
  summary?: string;
  detailDefault?: string;
}
@Component({
  selector: 'app-science-logic-visualization',
  templateUrl: './science-logic-visualization.component.html',
  styleUrls: ['./science-logic-visualization.component.scss'],
})
export class ScienceLogicVisualizationComponent implements OnInit, OnDestroy {
  @ViewChild('dashboardContainer') dashboardContainer: ElementRef;
  private navigationSubscription: Subscription;
  companys: ScienceLogicCompanys[];
  selectedCompany: ScienceLogicCompanys;
  loading = true;
  embeddingContext;
  canViewAllCompanys = false;
  selectedDashboard = 'last-24-hours';

  errors: Message[] = [];

  constructor(
    public observabilityService: ObservabilityService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private breadcrumbService: BreadcrumbService,
    private permissionService: PermissionService
  ) {}
  async ngOnInit() {
    if (
      this.permissionService.checkPermission(
        'DEVICES_GRAPHICAL_ALL_ORGANIZATIONS_READ'
      ) &&
      this.permissionService.digitalSpaceID()
    ) {
      this.canViewAllCompanys = true;
      await this.getCompaines();
    }
    const breadcrumbs: MenuItem[] = [
      {
        label: 'Device Insight',
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);

    this.embeddingContext = await createEmbeddingContext();
    this.route.params.subscribe(async params => {
      this.selectedDashboard = params?.dashboard;
      await this.getEmbedUrl(this.selectedDashboard);
    });
  }

  async onDashboardChange(event) {
    const dashboard = event?.value;
    this.router.navigate([
      `/secure/products-and-services/monitoring/devices/science-logic-visualization/${dashboard}`,
    ]);
  }

  async getEmbedUrl(dashboard: string) {
    this.renderer.setProperty(
      this.dashboardContainer.nativeElement,
      'innerHTML',
      ''
    );
    this.loading = true;
    try {
      const embed = await firstValueFrom(
        this.observabilityService.getDashboardEmbedUrl(
          dashboard,
          this.selectedCompany?.company
        )
      );
      if (!embed?.url) {
        throw new Error('Dashboard cannot be loaded');
      }
      const frameOptions = {
        url: embed?.url,
        container: this.dashboardContainer.nativeElement,
        height: '1000px',
        width: '100%',
        parameters: {
          filterControlsEnabled: false,
        },
        // resizeHeightOnSizeChangedEvent: true,
      };
      this.embeddingContext.embedDashboard(frameOptions);
    } catch (error) {
      this.errors = this.handleError(error, {
        severity: 'warn',
        detailDefault: 'Error loading dashboard',
      });
    }
    this.loading = false;
  }

  handleError(
    error,
    options: ErrorOptions = {
      severity: 'error',
    }
  ): Message[] {
    let detail: string = options?.detailDefault ?? '';
    if (error?.message) {
      detail = error?.message;
    }
    if (error?.error?.message) {
      detail = error?.error?.message;
    }
    if (typeof error?.error === 'string') {
      detail = error?.error;
    }
    if (!detail) {
      return [];
    }
    return [
      {
        severity: options.severity,
        ...(options.summary ? { summary: options.summary } : {}),
        detail:
          detail.startsWith('[') && detail.endsWith(']')
            ? detail.slice(1, -1)
            : detail,
      },
    ];
  }

  ngOnDestroy(): void {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  async getCompaines() {
    const response = await firstValueFrom(
      this.observabilityService.getCompanyList()
    );
    this.companys = response.map(company => ({
      name: company['company.Name'],
      company: company['company'],
    }));
  }

  async setCompanyId() {
    await this.getEmbedUrl(this.selectedDashboard);
  }
}
