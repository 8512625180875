import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable({ providedIn: 'root' })
export class DateParseService {
  /**
   * Parses a date string from the credentials response
   * @param date The date string to parse.
   * @returns A formatted date string.
   */

  parseDate(date: string): Date {
    return DateTime.fromFormat(date, 'dd/MM/yyyy HH:mm').toJSDate();
  }
}
