import { Injectable } from '@angular/core';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';

@Injectable({
  providedIn: 'root',
})
export class ScienceLogicService {
  constructor(private apiRequest: ApiRequest) {}

  getScienceLogicDeviceFilters(category: string) {
    const requestProps: IRequestOptions = {
      endpoint: 'ScienceLogic/GetScienceLogicDeviceFilters',
      body: {
        category: category,
      },
    };

    return this.apiRequest.request(requestProps);
  }

  getVitalsLatency(
    deviceId: string,
    start: any,
    end: any,
    lengthType: any,
    in24Hour: any
  ) {
    const requestProps: IRequestOptions = {
      endpoint: 'ScienceLogic/GetVitalsLatency',
      body: {
        DeviceId: deviceId,
        Start: start,
        End: end,
        LengthType: lengthType,
        in24Hours: in24Hour,
      },
    };

    return this.apiRequest.request(requestProps);
  }

  getVitalsAvailability(
    deviceId: string,
    start: any,
    end: any,
    lengthType: any,
    in24Hour: any
  ) {
    const requestProps: IRequestOptions = {
      endpoint: 'ScienceLogic/GetVitalsAvailability',
      body: {
        DeviceId: deviceId,
        Start: start,
        End: end,
        LengthType: lengthType,
        in24Hours: in24Hour,
      },
    };

    return this.apiRequest.request(requestProps);
  }

  getVitalsTimeOnline(
    deviceId: string,
    startUNIX: any,
    endUNIX: any,
    lengthType: any,
    in24Hour: any
  ) {
    const requestProps: IRequestOptions = {
      endpoint: 'ScienceLogic/GetVitalsTimeOnline',
      body: {
        DeviceId: deviceId,
        StartUNIX: startUNIX,
        endUNIX: endUNIX,
        LengthType: lengthType,
        in24Hours: in24Hour,
      },
    };

    return this.apiRequest.request(requestProps);
  }

  getVMVitals(
    name: string,
    device: string,
    availabilityDuration: string,
    cpuDuration: string,
    memoryDuration: string
  ) {
    const requestProps: IRequestOptions = {
      endpoint: 'ScienceLogic/GetVMVitals',
      body: {
        DeviceName: name,
        DeviceId: device,
        AvailabilityDuration: availabilityDuration,
        CpuDuration: cpuDuration,
        MemoryDuration: memoryDuration,
      },
    };

    return this.apiRequest.request(requestProps);
  }

  getVMMisc(deviceName: string, deviceId: string, networkUtilization: string) {
    const requestProps: IRequestOptions = {
      endpoint: 'ScienceLogic/GetVMMisc',
      body: {
        DeviceName: deviceName,
        DeviceId: deviceId,
        NetworkUtilizationDuration: networkUtilization,
      },
    };

    return this.apiRequest.request(requestProps);
  }

  getServiceNowDevices(filter: any) {
    const requestProps: IRequestOptions = {
      endpoint: 'ScienceLogic/GetServiceNowDevices',
      body: {
        Filter: filter,
      },
    };

    return this.apiRequest.request(requestProps);
  }

  getScienceLogicDevicesUnreachable(filter: any) {
    const requestProps: IRequestOptions = {
      endpoint: 'ScienceLogic/GetScienceLogicDevicesByAvailability',
      body: {
        Filter: filter,
      },
    };
    return this.apiRequest.request(requestProps);
  }

  getScienceLogicDevicesPaused(filter: any) {
    const requestProps: IRequestOptions = {
      endpoint: 'ScienceLogic/GetScienceLogicDevicesByPollingStatus',
      body: {
        Filter: filter,
      },
    };
    return this.apiRequest.request(requestProps);
  }

  getScienceLogicDevicesDetails(ids: any) {
    const requestProps: IRequestOptions = {
      endpoint: 'ScienceLogic/GetScienceLogicDevices',
      body: ids,
    };

    return this.apiRequest.request(requestProps);
  }

  exportDataPerformanceAllDevices(
    filter: any,
    duration: any,
    amount: number,
    email: string
  ) {
    const requestProps: IRequestOptions = {
      endpoint: 'ScienceLogic/ExportDataPerformanceAllDevices',
      body: {
        Filter: filter,
        DataPerformanceDuration: duration,
        Amount: amount,
        UserEmail: email,
      },
    };

    return this.apiRequest.request(requestProps);
  }
}
