import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import {
  ICostCentre,
  APIFunction,
  ICostCentrePost,
  ICostCentrePostCLI,
  IConfirmColumn,
  IConfirmColumns,
  IIndividualColumn,
  IIndividualColumns,
} from 'src/app/components/service-hub/billing/costcentres-users/shared/models';
import { CostCentreService } from 'src/app/services/cost-centre-service';
import { EditCostUserComponent } from 'src/app/components/misc/pop-up/edit-cost-user/edit-cost-user.component';
import { EditCostCentreComponent } from 'src/app/components/misc/pop-up/edit-cost-centre/edit-cost-centre.component';
import { cloneDeep } from 'lodash-es';
import { UnityService } from 'src/app/services/api/unity/unity.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MenuItem } from 'primeng/api';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-amend-costcentres-users',
  templateUrl: './amend-costcentres-users.component.html',
  styleUrls: ['./amend-costcentres-users.component.scss'],
  providers: [DialogService],
})
export class AmendCostcentresUsersComponent implements OnInit {
  costCentresSubject = new BehaviorSubject(null);
  costCentres$ = this.costCentresSubject.asObservable();
  loading: boolean = false;
  costCentresUpdated: ICostCentre[];
  costCentrePostByID: ICostCentre[] = [];
  costCentrePostByCLI: ICostCentre[] = [];
  radioGroupVisibility: string;

  selectedEditType = 'individual';
  items: MenuItem[] | undefined;
  steps: MenuItem[] | undefined = [];
  activeIndex: MenuItem = this.steps[0];
  activeStepIndex: number = 0;

  ref: DynamicDialogRef;
  tableColumn: IIndividualColumn[];
  confirmColumn: IConfirmColumn[];
  siteId: string;

  constructor(
    private router: Router,
    private costCentreService: CostCentreService,
    private unityService: UnityService,
    private dialogService: DialogService,
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute
  ) {
    this.costCentresUpdated = cloneDeep(this.costCentreService.costCentre);
  }

  async ngOnInit(): Promise<void> {
    this.siteId = this.route?.snapshot?.params?.siteId;
    const breadcrumbs: MenuItem[] = [
      { label: 'Billing Overview', url: '/secure/billing/overview' },
      {
        label: 'Cost Centre and User',
        routerLink: `/secure/billing/${this.siteId}/costcentres-users`,
      },
      { label: 'Edit Fields' },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);

    this.items = [
      {
        label: 'Amend',
      },
      {
        label: 'Confirm',
      },
      {
        label: 'Submit',
      },
    ];

    this.tableColumn = IIndividualColumns;
    this.confirmColumn = IConfirmColumns;
    this.activeIndex = this.items[this.activeStepIndex];
    this.loadTable();
  }

  async loadTable() {
    this.costCentresSubject.next(this.costCentresUpdated);
  }

  backButton(): void {
    this.clearData();
    this.router.navigateByUrl(
      `/secure/billing/${this.siteId}/costcentres-users`
    );
  }

  editCostCentreUser(event) {
    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    this.ref = this.dialogService.open(EditCostUserComponent, {
      showHeader: false,
      width: '50%',
      data: {
        id: this.siteId,
      },
      styleClass: 'p-dialog-custom',
    });
    this.ref.onClose.subscribe(result => {
      if (result) {
        const updatedCostCentreUser = result.name;
        const updatedCostCentreUserID = result.id;

        this.costCentresUpdated.forEach((item: ICostCentre) => {
          if (item.currentID === event.currentID) {
            item.siteUser = updatedCostCentreUser;
            item.userID = updatedCostCentreUserID;
          }
        });
      }
      this.costCentresSubject.next(this.costCentresUpdated);
    });
  }

  editCostCentre(event) {
    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    this.ref = this.dialogService.open(EditCostCentreComponent, {
      showHeader: false,
      width: '50%',
      data: {
        id: this.siteId,
      },
      styleClass: 'p-dialog-custom',
    });
    this.ref.onClose.subscribe(result => {
      if (result) {
        const updatedCostCentre = result.name;
        const updatedCostCentreID = result.id;

        this.costCentresUpdated.forEach((item: ICostCentre) => {
          if (item.currentID === event.currentID) {
            item.costCentre = updatedCostCentre;
            item.costCentreID = updatedCostCentreID;
          }
        });
      }
      this.costCentresSubject.next(this.costCentresUpdated);
    });
  }

  onMultiChange(): void {
    this.router.navigateByUrl(
      `/secure/billing/${this.siteId}/costcentres-users/amend/group`
    );
  }

  clearData(): void {
    this.costCentreService.costCentre = [];
    this.costCentresUpdated = [];
  }

  finishButton(): void {
    this.clearData();
    this.router.navigateByUrl(
      `/secure/billing/${this.siteId}/costcentres-users`
    );
  }

  onActiveIndexChange(event: number): void {
    const previousIndex = this.activeStepIndex;
    if (previousIndex === 1 && event === 2) {
      this.processCostCentreUpdates();
    }
    this.activeStepIndex = event;
    this.activeIndex = this.items[this.activeStepIndex];
  }

  stepForward(): void {
    if (this.items[this.activeStepIndex + 1]) {
      this.onActiveIndexChange(this.activeStepIndex + 1);
    }
  }

  stepBack(): void {
    if (this.items[this.activeStepIndex - 1]) {
      this.onActiveIndexChange(this.activeStepIndex - 1);
    }
  }

  async processCostCentreUpdates() {
    this.costCentrePostByID = this.costCentresUpdated.filter(
      item => item.apiFunction === APIFunction.UpdateSiteProductItemByID
    );
    this.costCentrePostByCLI = this.costCentresUpdated.filter(
      item => item.apiFunction === APIFunction.UpdateCLIByID
    );
    const costCentrePostByID: ICostCentrePost[] = this.costCentrePostByID.map(
      ({ userID, costCentreID, currentID }) => ({
        SiteProductID: currentID,
        SubSiteID: costCentreID.toString(),
        SiteUserID: userID.toString(),
      })
    );
    const costCentrePostByCLI: ICostCentrePostCLI[] =
      this.costCentrePostByCLI.map(({ userID, costCentreID, cliid }) => ({
        SubSiteID: costCentreID.toString(),
        SiteUserID: userID.toString(),
        CLIID: cliid,
      }));

    this.loading = true;

    if (costCentrePostByID.length > 0) {
      const productIDResult = await firstValueFrom(
        this.unityService.updateSiteProductByIDList(costCentrePostByID)
      );
    }

    if (costCentrePostByCLI.length > 0) {
      const resultCLIResult = await firstValueFrom(
        this.unityService.updateCLIDetailsByIDList(costCentrePostByCLI)
      );
    }
    this.loading = false;
  }
}
