import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash-es';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';
import {
  IUpdateWebHookBasicAuthRequest,
  IUpdateWebHookOAuthRequest,
  IUpdateWebHookRequest,
  IWebHook,
  WebHookAuthenticationTypes,
} from 'src/app/components/service-hub/developer/web-hooks/models/web-hook.model';
import { DeveloperService } from 'src/app/services/api/developer/developer.service';
import { ToastService, ToastType } from 'src/app/services/global/toast.service';
import { LoadingSpinnerService } from '../../loading-spinner/loading-spinner.service';

@Component({
  selector: 'configure-web-hook',
  templateUrl: 'configure-web-hook.component.html',
  styleUrls: ['./configure-web-hook.component.scss'],
})
export class ConfigureWebHookComponent implements OnInit {
  hideToken = true;
  hideBAUsername = true;
  hideBAPassword = true;
  loading = false;
  webHookForm: FormGroup;
  formValueChanged = false;
  webHook: IWebHook;
  existingWebHooks: IWebHook[];
  availableSources: string[] = [];
  availableWebHooks: any;

  availableActions: string[] = [];
  authenticationTypes: string[] = [
    WebHookAuthenticationTypes.None,
    WebHookAuthenticationTypes.OAuth,
    WebHookAuthenticationTypes.BasicAuth,
  ];

  get webHookFormControls(): any {
    return this.webHookForm['controls'];
  }

  constructor(
    private developerService: DeveloperService,
    private fb: FormBuilder,
    private loadingService: LoadingSpinnerService,
    private dialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private toastService: ToastService
  ) {
    this.webHook = cloneDeep(this.dialogConfig.data?.dialog.webHook);
    this.existingWebHooks = this.dialogConfig.data.dialog.existingWebHooks;
    this.availableWebHooks = this.dialogConfig.data.dialog.availableWebHooks;
  }

  ngOnInit(): void {
    this.buildWebHookForm();
    this.onFormValueUpdated();
    this.setAvailableSources();
    this.setAvailableActions();
  }

  buildWebHookForm() {
    this.webHookForm = this.fb.group({
      source: [this.webHook.source, Validators.required],
      action: [this.webHook.action, Validators.required],
      endpoint: [
        this.webHook.endpoint,
        [Validators.required, Validators.pattern(/^https:\/\/?/i)],
      ],
      authenticationType: [
        this.webHook.authenticationType,
        Validators.required,
      ],
      oAuthToken: [this.webHook.oAuthToken],
      basicAuthUsername: [this.webHook.basicAuthUsername],
      basicAuthPassword: [this.webHook.basicAuthPassword],
      sysId: [this.webHook.sysId],
    });
  }

  buildRequestModel(webHookForm) {
    const authType = webHookForm.authenticationType.value;

    if (authType === WebHookAuthenticationTypes.OAuth) {
      const request: IUpdateWebHookOAuthRequest = {
        sysId: webHookForm.sysId.value,
        source: webHookForm.source.value,
        action: webHookForm.action.value,
        endpoint: webHookForm.endpoint.value.toLowerCase(),
        oAuthToken: webHookForm.oAuthToken.value,
      };
      return request;
    }

    if (authType === WebHookAuthenticationTypes.BasicAuth) {
      const request: IUpdateWebHookBasicAuthRequest = {
        sysId: webHookForm.sysId.value,
        source: webHookForm.source.value,
        action: webHookForm.action.value,
        endpoint: webHookForm.endpoint.value.toLowerCase(),
        basicAuthUsername: webHookForm.basicAuthUsername.value,
        basicAuthPassword: webHookForm.basicAuthPassword.value,
      };
      return request;
    }

    const request: IUpdateWebHookRequest = {
      sysId: webHookForm.sysId.value,
      source: webHookForm.source.value,
      action: webHookForm.action.value,
      endpoint: webHookForm.endpoint.value.toLowerCase(),
    };
    return request;
  }

  setAuthenticationValidators(value: string) {
    this.clearAuthenticationValidators();

    if (value === WebHookAuthenticationTypes.OAuth)
      this.webHookFormControls.oAuthToken.setValidators(Validators.required);

    if (value === WebHookAuthenticationTypes.BasicAuth) {
      this.webHookFormControls.basicAuthUsername.setValidators(
        Validators.required
      );
      this.webHookFormControls.basicAuthPassword.setValidators(
        Validators.required
      );
    }
  }

  clearAuthenticationValidators() {
    this.webHookFormControls.oAuthToken.clearValidators();
    this.webHookFormControls.oAuthToken.updateValueAndValidity();
    this.webHookFormControls.basicAuthUsername.clearValidators();
    this.webHookFormControls.basicAuthUsername.updateValueAndValidity();
    this.webHookFormControls.basicAuthPassword.clearValidators();
    this.webHookFormControls.basicAuthPassword.updateValueAndValidity();
  }

  changeSource() {
    this.webHookForm.patchValue({
      action: null,
    });
    this.setAvailableActions();
  }

  setAvailableSources() {
    const sources = this.availableWebHooks
      .filter(
        availableWebHook =>
          availableWebHook.actions.length > 0 &&
          availableWebHook.source !== this.webHook.source
      )
      ?.map(availableWebHook => availableWebHook.source);

    const webHook = this.webHook;
    if (!sources.includes(webHook.source)) sources.push(webHook.source);
    this.availableSources = sources;
  }

  setAvailableActions() {
    const actions =
      this.availableWebHooks
        .find(
          availableWebHook =>
            availableWebHook.source === this.webHookForm.get('source').value
        )
        ?.actions?.map(action => action) ?? [];
    const currentWebHook = this.webHook;
    if (
      currentWebHook?.source === this.webHookForm.get('source').value &&
      !actions.includes(currentWebHook?.action)
    ) {
      actions.push(currentWebHook?.action);
    }
    this.availableActions = actions;
  }

  async onSubmit() {
    this.loading = true;
    const updateWebHookRequest = this.buildRequestModel(
      this.webHookFormControls
    );
    await firstValueFrom(
      this.developerService.updateWebHook(updateWebHookRequest)
    );
    this.toastService.add({
      severity: ToastType.success,
      summary: 'Success',
      detail: 'WebHook Configuration updated',
    });
    this.ref.close('Success');
  }

  onFormValueUpdated() {
    const initialValue = this.webHookForm.value;
    this.webHookForm.valueChanges.subscribe(value => {
      this.formValueChanged = Object.keys(initialValue).some(
        key => this.webHookForm.value[key] != initialValue[key]
      );
    });
  }

  submitButton(): void {
    this.onSubmit();
    this.ref.close(true);
  }

  close(): void {
    this.ref.close();
  }
}
