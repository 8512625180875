import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api/menuitem';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import { IUserProfile } from '../../company/users/shared/user-models';
import { TicketPermissions } from 'src/app/models/permissions/permissions.models';

@Component({
  selector: 'raise-ticket',
  templateUrl: './raise-ticket.component.html',
  styleUrls: ['./raise-ticket.component.scss'],
})
export class RaiseTicketComponent implements OnInit {
  user: IUserProfile;
  ticketPermissions: TicketPermissions = {
    incident: false,
    request: false,
  };
  constructor(
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    this.user = await this.authService.getUser();
    this.ticketPermissions = {
      incident: this.user.uTimicoPortalPermissions.includes(
        'TICKETS_INCIDENTS_CREATE'
      ),
      request: this.user.uTimicoPortalPermissions.includes(
        'TICKETS_REQUESTS_CREATE'
      ),
    };
    const breadcrumbs: MenuItem[] = [{ label: 'Raise a Ticket' }];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
  }

  openIdentifyIncident(): void {
    this.router.navigateByUrl('/secure/tickets/raise/create-incident');
  }
  openRaiseRequest() {
    this.router.navigateByUrl('/secure/tickets/raise/create-service-request');
  }
}
