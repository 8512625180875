export interface ICostCentre {
  active: number;
  apiFunction: string;
  billingDescription: string;
  billingEnd: string;
  billingFrequency: string;
  billingStart: string;
  charge: number;
  cli: string;
  cliid: number;
  contractEnd: string;
  contractLength: number;
  costCentre: string;
  costCentreID: string;
  currentID: number;
  productCategory: string;
  productGroup: string;
  siteUser: string;
  siteUserId: number;
  userID: string;
}

export enum APIFunction {
  UpdateSiteProductItemByID = 'UpdateSiteProductItemByID',
  UpdateCLIByID = 'UpdateCLIByID',
}

export interface ICostCentrePost {
  SiteProductID: number;
  SubSiteID: string;
  SiteUserID: string;
}

export interface ICostCentrePostCLI {
  SubSiteID: string;
  SiteUserID: string;
  CLIID: number;
}

export interface IConfirmColumn {
  field: string;
  header?: string;
  default: boolean;
  display?: any;
  showSortIcon: boolean;
}

export const IConfirmColumns: IConfirmColumn[] = [
  {
    field: 'productGroup',
    header: 'Practice',
    display: { type: 'text' },
    showSortIcon: false,
    default: true,
  },
  {
    field: 'productCategory',
    header: 'Category',
    display: { type: 'text' },
    showSortIcon: false,
    default: true,
  },
  {
    field: 'billingDescription',
    header: 'Description',
    display: { type: 'text' },
    showSortIcon: false,
    default: true,
  },
  {
    field: 'cli',
    header: 'CLI',
    display: { type: 'text' },
    showSortIcon: false,
    default: true,
  },
  {
    field: 'costCentre',
    header: 'Cost Centre',
    display: { type: 'text' },
    showSortIcon: false,
    default: true,
  },
  {
    field: 'siteUser',
    header: 'User',
    display: { type: 'text' },
    showSortIcon: false,
    default: true,
  },
];

export interface IIndividualColumn {
  field: string;
  header?: string;
  default: boolean;
  display?: any;
  showSortIcon: boolean;
  editType?: string;
}

export const IIndividualColumns: IIndividualColumn[] = [
  {
    field: 'productGroup',
    header: 'Practice',
    display: { type: 'text' },
    showSortIcon: false,
    default: true,
  },
  {
    field: 'productCategory',
    header: 'Category',
    display: { type: 'text' },
    showSortIcon: false,
    default: true,
  },
  {
    field: 'billingDescription',
    header: 'Description',
    display: { type: 'text' },
    showSortIcon: false,
    default: true,
  },
  {
    field: 'cli',
    header: 'CLI',
    display: { type: 'text' },
    showSortIcon: false,
    default: true,
  },
  {
    field: 'costCentre',
    header: 'Cost Centre',
    display: { type: 'input' },
    showSortIcon: false,
    default: true,
    editType: 'costCentre',
  },
  {
    field: 'siteUser',
    header: 'User',
    display: { type: 'input' },
    showSortIcon: false,
    default: true,
    editType: 'user',
  },
];
