import { IncidentTableColumnNames } from '../incident/incident.models';
import {
  CaseTableColumnName,
  ServiceRequestTableColumnName,
} from '../service-request/service-request.models';

export const incidentSearchFields = [
  IncidentTableColumnNames.number,
  IncidentTableColumnNames.description,
  IncidentTableColumnNames.shortDescription,
  IncidentTableColumnNames.raisedBy,
];

export const serviceRequestSearchFields = [
  ServiceRequestTableColumnName.number,
  ServiceRequestTableColumnName.shortDescription,
  ServiceRequestTableColumnName.description,
  ServiceRequestTableColumnName.requestedFor,
];

export const caseSearchFields = [
  CaseTableColumnName.number,
  CaseTableColumnName.shortDescription,
  CaseTableColumnName.description,
  CaseTableColumnName.requestedFor,
];
