<div class="fixed w-full bg-white h-4rem z-4">
  <top-bar (closeMenuEvent)="toggleMenu()"></top-bar>
</div>
<div class="flex h-full overflow-hidden pt-7">
  <div
    class="main-nav z-5 h-full overflow-y-auto overflow-x-hidden border-solid border-none border-right-1 border-gray-200 flex-shrink-0 flex-grow-0"
    [class.w-0]="(showMenu$ | async) === false"
    [class.show-main-nav]="showMenu$ | async">
    <div class="block md:hidden m-0 mt-3 px-3">
      <p-iconField iconPosition="right">
        <p-inputIcon
          styleClass="pi pi-search cursor-pointer"
          (click)="search()"
          (keydown.enter)="search()" />
        <input
          pInputText
          class="w-full"
          type="text"
          [(ngModel)]="searchValue"
          pattern="^[a-zA-Z0-9 _.-...@]*$"
          (keyup.enter)="search()"
          type="text"
          placeholder="Search" />
      </p-iconField>
    </div>
    <p-menu
      [model]="menuItems$ | async"
      styleClass="border-none overflow-hidden">
      <ng-template pTemplate="submenuheader" let-item>
        @if (item.routerLink) {
          <a class="cursor-pointer" [routerLink]="item.routerLink">
            @if (item.icon) {
              <i class="mr-2" [class]="item.icon"></i>
            }
            {{ item.label }}
          </a>
        } @else {
          <span class="text-primary font-bold">
            @if (item.icon) {
              <i class="mr-2" [class]="item.icon"></i>
            }
            {{ item.label }}
          </span>
        }
      </ng-template>
    </p-menu>
  </div>
  @if (showMenu$ | async) {
    <div
      class="fixed w-full h-full z-4"
      tabindex="0"
      role="button"
      (click)="closeMenu()"
      (keydown.escape)="closeMenu()"
      (keydown.enter)="closeMenu()"></div>
  }
  <main
    role="main"
    class="h-full max-h-full flex-grow-1 overflow-y-auto bg-gray-50">
    <div class="flex flex-column h-full">
      @if (isImpersonating) {
        <div
          class="flex align-items-center h-3rem text-white bg-impersonate px-3">
          <impersonate-bar></impersonate-bar>
        </div>
      }
      @if ((breadcrumbs$ | async)?.length) {
        <p-breadcrumb
          class="max-w-full"
          [model]="breadcrumbs$ | async"></p-breadcrumb>
      }
      <div class="flex-grow-1 overflow-y-auto">
        <router-outlet></router-outlet>
      </div>
    </div>
  </main>
</div>
