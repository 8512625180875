@if (loading) {
  <loading-spinner />
}
@if (!loading && !messages?.length) {
  <div class="flex flex-column">
    <div
      class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200 border-bottom-1">
      <h1 class="px-3 text-xl flex justify-content-between align-items-center">
        Service Request - {{ case?.number }}
        <span class="pl-1">
          <p-chip
            [label]="case?.state"
            [styleClass]="'p-chip-' + getColor(case?.state)">
          </p-chip>
        </span>
      </h1>
      <div class="pr-2">
        <span
          pTooltip="Adding attachments is not allowed while impersonating"
          tooltipPosition="bottom"
          [tooltipDisabled]="!isImpersonating">
          <p-button
            icon="pi pi-paperclip"
            label="Attachment"
            [disabled]="
              case?.state === 'Closed' ||
              case?.state === 'Resolved' ||
              isImpersonating ||
              !canUpdateCase
            "
            (onClick)="openFileManager()"
            class="mr-1"></p-button>
        </span>
        @if (canResolveCase(case)) {
          <p-button
            label="Resolve"
            styleClass="p-button-third"
            icon="pi pi-check-circle"
            (onClick)="openConfirmation(true)"
            [disabled]="!canUpdateCase"
            class="mr-2"></p-button>
        }
        @if (case?.state === 'Fulfilled') {
          <p-button
            styleClass="p-button-third"
            label="Re-open"
            icon="pi pi-check-circle"
            (onClick)="openConfirmation(false)"
            [disabled]="!canUpdateCase"></p-button>
        }
      </div>
    </div>
    <div
      class="mx-3 overflow-hidden bg-white border-solid border-1 border-gray-200 border-top-none">
      <div class="flex bg-white flex-wrap">
        <div class="col-12 md:col-6 flex flex-wrap align-content-start">
          <div
            class="col-12 border-none border-bottom-1 border-solid border-gray-200">
            <h2 class="text-gray-600 text-base m-0 mb-1">Subject</h2>
            <p class="text-lg m-0">
              {{ case?.shortDescription }}
            </p>
          </div>
          <div class="col-12 md:col-6 flex flex-wrap pl-0">
            <app-ticket-item-display
              [ngClass]="
                getContainerClasses({
                  border: false,
                  borderBottom: true,
                })
              "
              [label]="'Request For'"
              [value]="case?.contact || '&zwnj;'"></app-ticket-item-display>
            <app-ticket-item-display
              [ngClass]="
                getContainerClasses({
                  border: false,
                  borderBottom: true,
                })
              "
              [label]="'Category'"
              [value]="case?.category"></app-ticket-item-display>
            <app-ticket-item-display
              [ngClass]="
                getContainerClasses({
                  border: false,
                  borderBottom: true,
                })
              "
              [label]="'Priority'"
              [value]="case?.priority"></app-ticket-item-display>
          </div>
          <div class="col-12 md:col-6 flex flex-wrap pl-0">
            <app-ticket-item-display
              [ngClass]="
                getContainerClasses({
                  border: false,
                })
              "
              [label]="'Opened At'"
              [value]="case?.openedAt"></app-ticket-item-display>
            <app-ticket-item-display
              [ngClass]="
                getContainerClasses({
                  border: true,
                })
              "
              [label]="'Subcategory'"
              [value]="case?.subcategory"></app-ticket-item-display>
            <app-ticket-item-display
              [ngClass]="
                getContainerClasses({
                  border: true,
                  borderBottom: true,
                })
              "
              [label]="'Resolution'"
              [value]="
                case?.resolutionCode || '&zwnj;'
              "></app-ticket-item-display>
          </div>
        </div>
        <div class="col-12 md:col-6 flex flex-wrap align-content-start">
          <app-ticket-item-display
            [ngClass]="
              getContainerClasses({
                border: false,
              })
            "
            [label]="'Description'"
            [value]="case?.description"></app-ticket-item-display>
          <app-ticket-item-display
            [ngClass]="
              getContainerClasses({
                border: true,
              })
            "
            [label]="'Attachments'"></app-ticket-item-display>
          @for (file of attachmentFiles | slice: 0 : 3; track file) {
            <ng-container class="pt-1">
              <div class="inline-flex">
                <a
                  class="hover:text-primary m-1 pl-1 text-lg text-gray-900 font-bold cursor-pointer"
                  (click)="fileManagerService.downloadFile($event, file)"
                  ><i
                    class="text-lg mr-1 pi {{
                      fileManagerService.setFileIconPrimeIcon(file)
                    }}"
                    ><span class="ml-1">{{ file.fileName }}</span></i
                  ></a
                >
              </div>
            </ng-container>
          }
          @if (attachmentFiles?.length > 3) {
            <p-badge
              class="cursor-pointer"
              severity="success"
              (click)="openAttachments()"
              value="{{ attachmentFiles?.length - 3 }}"></p-badge>
          }
        </div>
      </div>
    </div>
  </div>
}

@if (!loading && !messages?.length) {
  <div class="pb-5 pt-2">
    @if (comments$) {
      <app-comments
        [comments$]="comments$"
        (addCommentEvent)="addComment($event)"></app-comments>
    }
  </div>
}

@if (messages?.length) {
  <div class="text-center">
    <p-messages [(value)]="messages" />
    <button pButton routerLink="/secure/tickets/service-requests">
      Return to Service Requests
    </button>
  </div>
}
