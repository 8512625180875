import { OnInit, Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UtilService } from '../../../../services/util.service';
import { InvoiceService } from 'src/app/services/api/invoice/invoice.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'invoice-online-view',
  templateUrl: './online-view.component.html',
  styleUrls: ['./online-view.component.scss'],
})
export class InvoiceOnlineViewComponent implements OnInit {
  invoiceNo: String;
  invoiceData: any;
  collapsed: Boolean = false;

  constructor(
    private route: ActivatedRoute,
    public util: UtilService,
    private router: Router,
    private invoiceService: InvoiceService
  ) {}
  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      if (params['id'] != null) {
        this.invoiceNo = params['id'];
        if (this.invoiceNo.startsWith('CN') || this.invoiceNo.startsWith('MI'))
          this.downloadInvoiceData();
        else {
          this.router.navigateByUrl('/secure/dashboard');
        }
      } else {
        this.router.navigateByUrl('/secure/dashboard');
      }
    });
  }

  async downloadInvoiceData() {
    let invoiceType = this.invoiceNo.slice(0, 2);
    if (invoiceType == 'CN') {
      // TODO fix string conversion
      this.invoiceData = await firstValueFrom(
        this.invoiceService.getCreditNote(this.invoiceNo as string)
      );
    } else if (invoiceType == 'MI') {
      // TODO fix string conversion
      this.invoiceData = await firstValueFrom(
        this.invoiceService.getManualInvoice(this.invoiceNo as string)
      );
    }
  }
}
