<div class="bg-primary h-7rem flex justify-content-center align-content-center">
  <h1 class="text-3xl flex align-items-center justify-content-center">
    How can we help?
  </h1>
</div>
<div
  class="flex flex-row flex-wrap justify-content-center align-content-center gap-5 cursor-pointer pt-4">
  <div
    class="hover:border-pink-500 border-3 border-white flex align-items-center bg-white shadow-2 flex flex-column border-round-lg m-3 w-21rem h-18rem">
    <div
      class="text-center d-flex flex-column justify-content-center pt-6"
      (click)="redirectToArticles()"
      (keyup.enter)="redirectToArticles()"
      role="button"
      tabindex="0">
      <img
        class="w-3rem h-2rem"
        src="assets/images/icons/Btn_ServiceHub_Articles.svg"
        alt="servicehub-article logo" />
      <div>
        <h2>ServiceHub Articles</h2>
        <div>
          <h3>Guides for our ServiceHub portal</h3>
        </div>
      </div>
    </div>
  </div>
  <div
    class="flex align-items-center bg-white shadow-2 flex flex-column border-round-lg m-3 w-21rem h-18rem hover:border-pink-500 border-3 border-white">
    <div
      class="text-center d-flex flex-column justify-content-center pt-6"
      (click)="redirectToKnowledgeArticles()"
      (keyup.enter)="redirectToKnowledgeArticles()"
      role="button"
      tabindex="0">
      <img
        class="w-3rem h-2rem"
        src="assets/images/icons/Btn_Knowledge_Article.svg"
        alt="knowledge-article logo" />
      <div>
        <h2>Knowledge Articles</h2>
        <div>
          <h3>Guides for our Digital Space services</h3>
        </div>
      </div>
    </div>
  </div>
  <div
    class="flex align-items-center bg-white shadow-2 flex flex-column border-round-lg m-3 w-21rem h-18rem hover:border-pink-500 border-3 border-white">
    <div
      class="text-center d-flex flex-column justify-content-center pt-6"
      (click)="redirectToContacts()"
      (keyup.enter)="redirectToContacts()"
      role="button"
      tabindex="0">
      <img
        class="w-3rem h-2rem"
        src="assets/images/icons/Btn_Contact.svg"
        alt="contact logo" />
      <div class="contact-text">
        <h2>Contact</h2>
        <div>
          <h3>Your Digital Space contacts</h3>
        </div>
      </div>
    </div>
  </div>
</div>
