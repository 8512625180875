import { TableColumn, TableSort } from 'src/app/models/table';

//Column names match export DTOs in Customer portal
enum BroadbandTableColumns {
  name = 'Name',
  model = 'ModelId',
  userName = 'UDslUsername',
  location = 'Location',
}
enum exportBroadbandColumns {
  name = 'serviceReference',
  service = 'service',
  userName = 'userName',
  location = 'location',
  download = 'maximumDownloadSpeed',
  upload = 'maximumUploadSpeed',
}

export const broadbandColumns: TableColumn[] = [
  {
    field: 'name',
    header: 'Service Reference',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
    disabled: true,
    sortColumnOrder: 1,
    sortOrder: TableSort.asc,
    serviceNowField: BroadbandTableColumns.name,
    exportField: exportBroadbandColumns.name,
  },
  {
    field: 'modelId',
    header: 'Service',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
    serviceNowField: BroadbandTableColumns.model,
    exportField: exportBroadbandColumns.service,
  },
  {
    field: 'dslUsername',
    header: 'Username ',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
    serviceNowField: BroadbandTableColumns.userName,
    exportField: exportBroadbandColumns.userName,
  },
  {
    field: 'location',
    header: 'Location  ',
    display: { type: 'text' },
    showSortIcon: true,
    default: false,
    serviceNowField: BroadbandTableColumns.location,
    exportField: exportBroadbandColumns.location,
  },
  {
    field: 'downloadSpeed',
    header: 'Maximum Download Speed',
    display: { type: 'text' },
    showSortIcon: false,
    default: false,
    exportField: exportBroadbandColumns.download,
    filter: null,
  },
  {
    field: 'uploadSpeed',
    header: 'Maximum Upload Speed',
    display: { type: 'text' },
    showSortIcon: false,
    default: false,
    exportField: exportBroadbandColumns.upload,
    filter: null,
  },
];

export const BroadbandMonthlyColumns: TableColumn[] = [
  {
    field: 'formattedDate',
    header: 'Date ',
    display: { type: 'date' },
    showSortIcon: true,
    default: true,
  },
  {
    field: 'input',
    header: '	Upload (GB)',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
  },
  {
    field: 'output',
    header: 'Download (GB)',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
  },
];

export const broadbandReportColumns: TableColumn[] = [
  {
    field: 'date',
    header: 'Date ',
    display: { type: 'date' },
    showSortIcon: true,
    default: true,
  },
  {
    field: 'input',
    header: '	Upload (GB)',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
  },
  {
    field: 'output',
    header: 'Download (GB)',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
  },
];
