import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { cloneDeep } from 'lodash-es';
import { AddEditUserPageTitle } from '../add-edit-user.component';

@Component({
  selector: 'app-add-edit-user-review',
  templateUrl: './add-edit-user-review.component.html',
  styleUrls: ['./add-edit-user-review.component.scss'],
})
export class AddEditUserReviewComponent implements OnInit {
  @Input() addEditUserForm: FormGroup;
  @Input() pageTitle = AddEditUserPageTitle.ADD;
  @Output() childIsLoading = new EventEmitter();
  review;

  loading = true;

  AddEditUserPageTitle = AddEditUserPageTitle;

  ngOnInit(): void {
    this.review = cloneDeep(this.addEditUserForm.value);
    this.review.permissions = this.organizePermissionsByCategory([
      ...this.review.permissions.rolePermissions.map(permission => ({
        ...permission,
        rolePermission: true,
      })),
      ...this.review.permissions.additionalPermissions,
    ]);
  }

  organizePermissionsByCategory(permissions): { name: string; permissions }[] {
    const categoriesWithPermissions: Record<string, unknown[]> = {};

    permissions.forEach(permission => {
      const { category } = permission;

      if (!categoriesWithPermissions[category]) {
        categoriesWithPermissions[category] = [];
      }

      categoriesWithPermissions[category].push(permission);
    });

    const resultArray: { name: string; permissions }[] = Object.entries(
      categoriesWithPermissions
    ).map(([category, permissions]) => ({ name: category, permissions }));

    return resultArray;
  }
}
