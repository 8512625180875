import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MenuItem, Message } from 'primeng/api';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import {
  ticketData,
  ticketType,
} from 'src/app/components/misc/shared/comments.model';
import { GetSingleServiceRequestResponse } from 'src/app/models/service-request/service-request.models';
import { CommentAPIService } from 'src/app/services/api/comment/comment.service';
import { FileService } from 'src/app/services/api/file/file.service';
import { ToastService, ToastType } from 'src/app/services/global/toast.service';
import { NotificationService } from 'src/app/services/notification.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileManagerComponent } from 'src/app/components/misc/pop-up/file-manager/file-manager.component';
import {
  GenericApiErrorMessage,
  IsDisplayableException,
} from 'src/app/helpers/error.helper';
import { AuthService } from 'src/app/services/auth/auth.service';
import { IUserProfile } from '../../../company/users/shared/user-models';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import { FileManagerService } from 'src/app/components/misc/pop-up/file-manager/file-manager.service';
import { ViewFilesComponent } from 'src/app/components/misc/pop-up/view-files/view-files.component';
import { ServiceRequestService } from 'src/app/services/api/service-request/service-request.service';
import { CreateCommentRequest } from 'src/app/models/comments/comment.models';

@Component({
  selector: 'app-order-single',
  templateUrl: './order-single.component.html',
  styleUrls: ['./order-single.component.scss'],
})
export class OrderSingleComponent implements OnInit {
  user: IUserProfile;
  isImpersonating = false;
  loading = false;
  messages: Message[] | undefined = [];
  orderRequestId: string;
  orderRequest: GetSingleServiceRequestResponse;
  commentSubject = new BehaviorSubject(null);
  comments$ = this.commentSubject.asObservable();
  attachmentFiles;
  ref: DynamicDialogRef;
  canUpdateTicket = false;
  canViewCommentsBoth = false;
  ticket: ticketData;
  ticketSubject = new BehaviorSubject<ticketData | null>(null);
  ticket$ = this.ticketSubject.asObservable();

  get canViewComment(): boolean {
    return (
      this.user?.uTimicoPortalPermissions?.includes('TICKETS_ORDERS_UPDATE') ||
      this.user?.uTimicoPortalPermissions?.includes('TICKETS_COMPANY_UPDATE')
    );
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private serviceRequestService: ServiceRequestService,
    private notifications: NotificationService,
    private commentAPIService: CommentAPIService,
    private fileService: FileService,
    private dialogService: DialogService,
    private toastService: ToastService,
    private authService: AuthService,
    private breadcrumbService: BreadcrumbService,
    public fileManagerService: FileManagerService
  ) {}

  async ngOnInit() {
    this.user = await this.authService.getUser();
    this.isImpersonating = this.authService.isImpersonating();

    this.activatedRoute.params.subscribe(async (params: Params) => {
      this.orderRequestId = params['id'];
      await this.loadServiceOrderRequest();

      const breadcrumbs: MenuItem[] = [
        { label: 'Orders', routerLink: '/secure/tickets/orders' },
        { label: this.orderRequest?.number },
      ];
      this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    });
    this.canUpdateTicket =
      this.user.uTimicoPortalPermissions.includes('TICKETS_ORDERS_UPDATE') ||
      this.user.uTimicoPortalPermissions.includes('TICKETS_COMPANY_UPDATE');
  }

  async loadServiceOrderRequest() {
    this.loading = true;
    try {
      const serviceRequest = await firstValueFrom(
        this.serviceRequestService.getSingle(this.orderRequestId)
      );
      this.orderRequest = serviceRequest;
      this.notifications.readNotification(serviceRequest.sysId);
      await this.loadComments();
      this.loadFiles();
      const ticket = {
        sysId: this.orderRequest.sysId,
        state: this.orderRequest.state,
        type: ticketType.request,
        canAddComments: this.canUpdateTicket,
      };
      this.ticketSubject.next(ticket);
    } catch (error) {
      console.error(error);
      let message = {
        severity: ToastType.error,
        summary: 'Error',
        detail: GenericApiErrorMessage,
        closable: false,
      };
      if (IsDisplayableException(error?.status)) {
        message = {
          severity: ToastType.error,
          summary: 'Error',
          detail: error.error,
          closable: false,
        };
      }
      this.messages = [message];
    } finally {
      this.loading = false;
    }
  }

  async addComment(comment: string) {
    const commentRequest: CreateCommentRequest = {
      itemSysId: this.orderRequest.sysId,
      comment: comment,
    };
    const addComment = await firstValueFrom(
      this.serviceRequestService.createComment(commentRequest)
    );

    if (addComment) {
      this.toastService.add({
        severity: ToastType.success,
        summary: 'Adding Comment',
        detail: 'Comment Added',
      });
      await this.loadServiceOrderRequest();
    }
  }

  async loadComments() {
    const comments = await firstValueFrom(
      this.serviceRequestService.getComments(this.orderRequest.sysId)
    );
    const canAddComments =
      this.canViewComment &&
      this.orderRequest?.state !== 'Fulfilled' &&
      this.orderRequest?.state !== 'Closed' &&
      this.orderRequest?.state !== 'Resolved' &&
      this.orderRequest.state !== 'Cancelled';

    this.commentSubject.next({
      comments: comments,
      canAddComments: canAddComments,
    });
  }

  async loadFiles() {
    const files = await firstValueFrom(
      this.fileService.getFiles(this.orderRequest.sysId)
    );
    this.attachmentFiles = files.fileList;
  }

  openFileManager() {
    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    this.ref = this.dialogService.open(FileManagerComponent, {
      showHeader: false,
      width: '45%',
      data: {
        files: this.attachmentFiles,
        sysId: this.orderRequest.sysId,
        table: 'sc_req_item',
      },
      styleClass: 'p-dialog-custom',
    });
    this.ref.onClose.subscribe(result => {
      if (result) {
        this.toastService.add({
          severity: ToastType.success,
          summary: 'File Manager',
          detail: 'File(s) Uploaded',
        });
        this.loadServiceOrderRequest();
      }
    });
  }

  openAttachments(): void {
    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    this.ref = this.dialogService.open(ViewFilesComponent, {
      showHeader: false,
      data: {
        files: this.attachmentFiles,
        sysId: this.orderRequest.sysId,
        table: 'sc_req_item',
      },
      styleClass: 'p-dialog-custom',
    });
  }

  getColor(value: string): string {
    switch (value) {
      case 'New':
        return 'new';
      case 'In Progress':
        return 'inprogress';
      case 'Awaiting Info':
        return 'awaitinginfo';
      case 'Resolved':
        return 'resolved';
      case 'Closed':
        return 'closed';
      case 'Open':
        return 'open';
      default:
        return 'closed';
    }
  }

  getContainerClasses(options: Record<string, boolean | string>) {
    const classes = {};
    if (options?.cols !== false) {
      classes[`col-${options?.cols || 12}`] = true;
    }
    if (options?.border) {
      classes['border-none'] = true;
      classes['border-top-1'] = true;
      classes['border-solid'] = true;
      classes['border-gray-200'] = true;
    }
    if (options?.borderBottom) {
      classes['border-bottom-1'] = true;
      classes['border-gray-200'] = true;
    }
    return classes;
  }
}
