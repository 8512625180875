import { TableColumn, TableSort } from 'src/app/models/table';

export enum IDomainTableColumnNames {
  name = 'name',
}

export enum IDomainDetailTableColumnNames {
  name = 'name',
  ttl = 'ttl',
  type = 'type',
  value = 'value',
  edit = 'edit',
  remove = 'remove',
}

export interface IDomain {
  domainName: string;
  id: number;
  name: string;
  text: string;
  ttl: string;
  type: string;
  value: string;
  version: number;
  operation?: string;
  confirmRemove?: boolean;
}

export interface IDomainColumn {
  field: string;
  header: string;
  display: any;
  showSortIcon: boolean;
}

enum snowExportField {
  name = 'Name',
  company = 'Company',
}

enum snowField {
  name = 'name',
  company = 'company',
}

export const adminDomainColumns: TableColumn[] = [
  {
    field: 'name',
    header: 'Name',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
    serviceNowField: snowField.name,
    sortColumnOrder: 1,
    sortOrder: TableSort.asc,
    exportField: snowExportField.name,
  },
  {
    field: 'company',
    header: 'Company',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
    serviceNowField: snowField.company,
    exportField: snowExportField.company,
  },
  // {
  //   field: "inRoute53",
  //   header: "Create",
  //   display: { type: "button" },
  //   showSortIcon: false,
  // },
];

export const domainColumns: TableColumn[] = [
  {
    field: 'name',
    header: 'Name',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
    serviceNowField: snowField.name,
    exportField: snowExportField.name,
  },
];

export interface IDomainInfo {
  name: string;
  sysId: string;
}
