import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DateTime } from 'luxon';
import { Message, MessageService } from 'primeng/api';
import { MenuItem } from 'primeng/api/menuitem';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';
import { ChangeRejectCommentComponent } from 'src/app/components/misc/pop-up/change-reject-comment/change-reject-comment.component';
import { FileManagerComponent } from 'src/app/components/misc/pop-up/file-manager/file-manager.component';
import { FileManagerService } from 'src/app/components/misc/pop-up/file-manager/file-manager.service';
import { ViewFilesComponent } from 'src/app/components/misc/pop-up/view-files/view-files.component';
import {
  GenericApiErrorMessage,
  IsDisplayableException,
} from 'src/app/helpers/error.helper';
import { GetTicketItemDisplayContainerClasses } from 'src/app/helpers/tickets.helper';
import { GetSingleChangeRequestResponse } from 'src/app/models/change/change.models';
import { TableNames } from 'src/app/models/servicenow.model';
import { ChangeRequestService } from 'src/app/services/api/change-request/change-request.service';
import { FileService } from 'src/app/services/api/file/file.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import { ToastService, ToastType } from 'src/app/services/global/toast.service';
import { NotificationService } from 'src/app/services/notification.service';
import { IUserProfile } from '../../../company/users/shared/user-models';
import { IServiceNowApproval } from './shared/change-request-models';

@Component({
  selector: 'change-request',
  templateUrl: 'change-request.component.html',
  styleUrls: ['change-request.component.scss'],
  providers: [DialogService, MessageService],
})
export class SingleChangeRequestComponent implements OnInit {
  user: IUserProfile;
  ref: DynamicDialogRef;
  changeId: any;
  changeRequest: GetSingleChangeRequestResponse;
  stateOfAcceptance: IServiceNowApproval;
  comments: any;
  attachmentFiles: any;
  collatedRelatedTickets: Array<any> = [];
  endDateCheck: boolean;
  customerApprovalState: String = 'N/A';
  loading: boolean = false;
  isImpersonating: boolean = false;

  messages: Message[] | undefined = [];

  getTicketItemDisplayContainerClasses = GetTicketItemDisplayContainerClasses;

  get canMakeChanges(): boolean {
    return (
      this.user?.uTimicoPortalPermissions.includes('TICKETS_CHANGE_UPDATE') ||
      this.user?.uTimicoPortalPermissions.includes('TICKETS_COMPANY_UPDATE')
    );
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private notifications: NotificationService,
    public fileManagerService: FileManagerService,
    private fileService: FileService,
    private changeRequestService: ChangeRequestService,
    private breadcrumbService: BreadcrumbService,
    private dialogService: DialogService,
    private toastService: ToastService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    this.user = await this.authService.getUser();
    this.isImpersonating = this.authService.isImpersonating();
    this.activatedRoute.params.subscribe(async (params: Params) => {
      this.changeId = params['id'];
      await this.loadChange();

      const breadcrumbs: MenuItem[] = [
        { label: 'Change Requests', routerLink: '/secure/tickets/changes' },
        { label: this.changeRequest?.number },
      ];
      this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    });
  }

  async loadChange() {
    this.loading = true;
    try {
      const response = await firstValueFrom(
        this.changeRequestService.getSingle(this.changeId)
      );
      this.changeRequest = response;

      this.stateOfAcceptance = await firstValueFrom(
        this.changeRequestService.getApproverState(this.changeRequest.sysId)
      );

      this.notifications.readNotification(this.changeRequest.sysId);
      this.loadFiles();
      this.checkIsActivePlanned();

      if (
        this.stateOfAcceptance === null ||
        (this.stateOfAcceptance && this.stateOfAcceptance.state === null)
      ) {
        this.stateOfAcceptance = { state: '' };
      } else if (this.stateOfAcceptance.state == 'Approved') {
        this.customerApprovalState = 'Awaiting Digital Space Acknowledgement';
      } else if (this.stateOfAcceptance.state == 'Requested') {
        this.customerApprovalState = 'Awaiting Customer Approval';
      } else if (this.stateOfAcceptance.state == 'Rejected') {
        this.customerApprovalState = 'Awaiting Digital Space Acknowledgement';
      }

      if (this.changeRequest.state == 'Implementation') {
        this.customerApprovalState =
          'Digital Space is currently implementing your change.';
      } else if (this.changeRequest.state == 'Closed') {
        this.customerApprovalState = 'This change is currently closed.';
      }
    } catch (error) {
      console.error(error);
      let message = {
        severity: ToastType.error,
        summary: 'Error',
        detail: GenericApiErrorMessage,
        closable: false,
      };
      if (IsDisplayableException(error?.status)) {
        message = {
          severity: ToastType.error,
          summary: 'Error',
          detail: error.error,
          closable: false,
        };
      }
      this.messages = [message];
    } finally {
      this.loading = false;
    }
  }

  async approve() {
    const response = await firstValueFrom(
      this.changeRequestService.approve(
        this.changeRequest.sysId,
        'approved',
        'Approved'
      )
    );

    this.loadChange();
    if (!response) {
      this.toastService.add({
        severity: ToastType.error,
        summary: 'Submit',
        detail: 'Change approval could not be completed.',
      });
      return;
    }
    this.toastService.add({
      severity: ToastType.success,
      summary: 'Submit',
      detail: 'You have submitted your approval for this change.',
    });
  }

  async reject() {
    this.openAddComment();
  }

  async openAddComment() {
    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    this.ref = this.dialogService.open(ChangeRejectCommentComponent, {
      width: '32%',
      contentStyle: { overflow: 'auto' },
      showHeader: false,
      styleClass: 'p-dialog-custom',
    });
    const result = await firstValueFrom(this.ref.onClose);

    if (result) {
      this.addComment(result);
      this.toastService.add({
        severity: ToastType.success,
        summary: 'Change rejected',
        detail: 'You have submitted your rejection for this change.',
      });
    }
  }

  async addComment(comment: string) {
    try {
      await firstValueFrom(
        this.changeRequestService.reject({
          comments: comment,
          documentId: this.changeRequest.sysId,
          state: 'rejected',
        })
      );
      this.loadChange();
    } catch (error) {
      this.toastService.add({
        severity: ToastType.error,
        summary: 'Submit',
        detail: 'An unexpected error occurred while processing the change.',
      });
    }
  }

  async checkIsActivePlanned() {
    const now = DateTime.now().setZone('Europe/London');
    const endDate = DateTime.fromFormat(
      this.changeRequest.endDate,
      'dd/MM/yyyy HH:mm',
      { zone: 'Europe/London' }
    );
    this.endDateCheck = now <= endDate;
  }

  async loadFiles() {
    let files = await firstValueFrom(
      this.fileService.getFiles(this.changeRequest.sysId)
    );
    this.attachmentFiles = files.fileList;
  }

  openAttachments(): void {
    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    this.ref = this.dialogService.open(ViewFilesComponent, {
      showHeader: false,
      data: {
        files: this.attachmentFiles,
        sysId: this.changeRequest.sysId,
        table: TableNames.REQUESTED_ITEM,
      },
      styleClass: 'p-dialog-custom',
    });
  }

  //TODO:Fix file upload
  async openFileManager(): Promise<void> {
    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    this.ref = this.dialogService.open(FileManagerComponent, {
      showHeader: false,
      width: '45%',
      data: {
        files: this.attachmentFiles,
        sysId: this.changeRequest.sysId,
        table: 'change_request',
      },
      styleClass: 'p-dialog-custom',
    });
    const result = await firstValueFrom(this.ref.onClose);
    if (result) {
      this.toastService.add({
        severity: ToastType.success,
        summary: 'File Manager',
        detail: 'File(s) Uploaded',
      });
      this.loading = true;
      await this.loadChange();
    }
  }

  getColor(value: string): string {
    switch (value) {
      case 'On Hold':
        return 'onhold';
      case 'Approval':
        return 'approval';
      case 'PIR':
        return 'pir';
      case 'Implementation':
        return 'implementation';
      case 'Closed':
        return 'closed';
      default:
        return 'closed';
    }
  }
}
