<div class="flex flex-column h-full">
  <h1 class="text-xl px-3">Add Comment</h1>
  <hr class="w-full" />
  <div class="px-4">
    <p class="text-xl m-0 pb-2">What comment would you like to add?</p>
    <form [formGroup]="addCommentFormControl">
      <textarea
        pInputText
        id="Comment"
        placeholder="Comment"
        formControlName="comment"
        aria-label="Description"
        type="text"
        rows="7"
      class="w-full comment"></textarea>
      @if (
        addCommentFormControl.get('comment').invalid &&
        addCommentFormControl.get('comment').touched
        ) {
        <small
          class="p-error text-base">
          Comment is required
        </small>
      }
    </form>
    <div class="flex align-content-center justify-content-center py-3">
      <p-button
        styleClass="p-button-secondary"
        label="Cancel"
      (onClick)="closeButton()"></p-button>
      <p-button
        styleClass="p-button"
        [disabled]="addCommentFormControl.invalid"
        label="Submit"
      (onClick)="addComment()"></p-button>
    </div>
  </div>
</div>
