import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';
import {
  CommentsResponse,
  CreateCommentRequest,
} from 'src/app/models/comments/comment.models';
import { IncidentSiteResponse } from 'src/app/models/graphs/pie.chart.models';
import {
  GetMultipleIncidentsResponse,
  GetSingleIncidentResponse,
} from 'src/app/models/incident/incident.models';
import { ServiceNowFilterQuery } from 'src/app/models/servicenow.model';

const taskDirectory = 'Incident';

@Injectable({
  providedIn: 'root',
})
export class IncidentService {
  constructor(private apiRequest: ApiRequest) {}

  getSingle(incidentId: string): Observable<GetSingleIncidentResponse> {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetSingleIncident`,
      body: incidentId,
    };
    return this.apiRequest.request(requestProps);
  }

  getMultiple(
    filter: ServiceNowFilterQuery
  ): Observable<GetMultipleIncidentsResponse[]> {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetMultipleIncidents`,
      body: filter,
    };
    return this.apiRequest.request(requestProps);
  }

  getCount(filter: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetIncidentsCount`,
      body: filter,
    };
    return this.apiRequest.request(requestProps);
  }

  //TODO:Returns null
  updateOne(incident: any) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/UpdateIncident`,
      body: incident,
    };
    return this.apiRequest.request(requestProps);
  }

  createOne(incident: any) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/CreateIncident`,
      body: incident,
    };
    return this.apiRequest.request(requestProps);
  }

  getHoldReason(filter: any) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetIncidentHoldReason`,
      body: {
        Filter: filter,
      },
    };
    return this.apiRequest.request(requestProps);
  }

  getClosedIncidentsPriority(filter: number) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetClosedIncidentsPriority`,
      body: filter,
    };
    return this.apiRequest.request(requestProps);
  }

  getIncident(incidentId: string): Observable<GetSingleIncidentResponse> {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetIncident`,
      body: incidentId,
    };
    return this.apiRequest.request(requestProps);
  }

  getIncidentHoldReason(filter: any) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetIncidentHoldReason`,
      body: {
        Filter: filter,
      },
    };
    return this.apiRequest.request(requestProps);
  }

  getSitesWithTheHighestCountOfIncidents(
    date: string
  ): Observable<IncidentSiteResponse[]> {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetSitesWithTheHighestCountOfIncidents`,
      body: date,
    };
    return this.apiRequest.request(requestProps);
  }

  getIncidentCategories() {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetIncidentCategories`,
    };
    return this.apiRequest.request(requestProps);
  }

  getComments(incidentSysId: string): Observable<CommentsResponse> {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetIncidentComments`,
      body: incidentSysId,
    };
    return this.apiRequest.request(requestProps);
  }

  createComment(request: CreateCommentRequest) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/CreateIncidentComment`,
      body: request,
    };
    return this.apiRequest.request(requestProps);
  }

  expedite(request: CreateCommentRequest) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/ExpediteIncident`,
      body: request,
    };
    return this.apiRequest.request(requestProps);
  }

  escalate(request: CreateCommentRequest) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/EscalateIncident`,
      body: request,
    };
    return this.apiRequest.request(requestProps);
  }
}
