import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject, firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import { OutageService } from 'src/app/services/api/outage/outage.service';
import { DateTime } from 'luxon';
import {
  SetMultiSelectColumnValues,
  TableReset,
} from '../../../../../helpers/table.helper';
import { TableColumn, TableColumnColor } from 'src/app/models/table';
import { Table } from 'primeng/table';
import { outageColumns } from '../../../../../models/outages-model';
import { ViewChild } from '@angular/core';
import { GetOutageServiceResponse } from 'src/app/models/outage/outage.models';

@Component({
  selector: 'service-information',
  templateUrl: 'information.component.html',
  styleUrls: ['information.component.scss'],
})
export class ServiceInformationComponent implements OnInit, OnDestroy {
  @ViewChild('dataTable') dataTable: Table;
  @Input() service;
  @Input() tableColumns: TableColumn[];
  pageData;
  outagesSubject = new BehaviorSubject([]);
  outages$ = this.outagesSubject.asObservable();
  OutageService;
  destroy$ = new Subject();
  loading = false;

  cols: TableColumn[] = cloneDeep(outageColumns);

  _selectedColumns: TableColumn[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private outAgeService: OutageService
  ) {}

  ngOnInit() {
    this.loadServices();
  }

  async loadServices() {
    this.loading = true;
    this.route.data
      .pipe(takeUntil(this.destroy$))
      .subscribe(v => (this.pageData = v));
    if (this.service != null) {
      let outages: GetOutageServiceResponse[];
      const response = await firstValueFrom(
        this.outAgeService.getOutageByService(
          this.service.businessService.sysId
        )
      );
      outages = response;

      outages = outages.map(outage => {
        const start = outage.start
          ? this.parseDate(outage.start, 'dd/MM/yyyy HH:mm')
          : null;
        const end = outage.end
          ? this.parseDate(outage.end, 'dd/MM/yyyy HH:mm')
          : null;

        return {
          ...outage,
          start: start && start.isValid ? start.toJSDate() : null,
          end: end && end.isValid ? end.toJSDate() : null,
        };
      });

      this.cols = SetMultiSelectColumnValues(outageColumns, outages);
      this.outagesSubject.next(outages);
      // TODO assume no longer needed?
      // this.outages = outages;
      this.loading = false;
    }
  }

  parseDate = (dateString, format) => {
    let date = DateTime.fromFormat(dateString, format).setZone(
      DateTime.local().zoneName
    );
    if (!date.isValid) {
      date = DateTime.fromFormat(dateString, 'dd/MM/yyyy').setZone(
        DateTime.local().zoneName
      );
    }
    return date;
  };

  handleRowClicked(event) {
    if (this.pageData.authorised) {
      this.router.navigateByUrl(
        'secure/products-and-services/service/' +
          event.name.toLowerCase() +
          '/' +
          event.sysId
      );
    } else {
      this.router.navigateByUrl(
        'service/' + event.name.toLowerCase() + '/' + event.sysId
      );
    }
  }

  getColor(colors: TableColumnColor[], value: string) {
    return colors[value] ?? null;
  }

  getColorMetric(value: number) {
    if (value >= 95) return 'danger';
    if (value >= 76 && value <= 94) return 'warning';
    return 'success';
  }

  reset(
    table: Table,
    firstLoad = false,
    columns: TableColumn[] = outageColumns
  ) {
    this.loadServices();
    TableReset(table, columns, {
      firstLoad,
    });
  }

  ngOnDestroy() {
    this.destroy$.next(void 0);
    this.destroy$.complete();
  }
}
