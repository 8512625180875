import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationsColumns } from './notifications.columns';
import { cloneDeep } from 'lodash-es';
import { takeUntil } from 'rxjs/operators';
import { firstValueFrom, Subject } from 'rxjs';
import { MenuItem } from 'primeng/api/menuitem';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import { Table } from 'primeng/table';
import { DialogService } from 'primeng/dynamicdialog';
import { DateTime } from 'luxon';
import { LazyLoadEvent } from 'primeng/api';
import {
  SetSelectedColumns,
  TableReset,
  IsPaginatorState,
} from 'src/app/helpers/table.helper';
import { Filters, TableColumn } from 'src/app/models/table';
import { BuildServiceNowQuery } from 'src/app/helpers/servicenow/filter/servicenow-filter.helper';
import { NotificationsService } from 'src/app/services/api/notifications/notifications.service';
import { Paginator, PaginatorState } from 'primeng/paginator';
import { NotificationHelper } from 'src/app/helpers/notifications/notification.helper';
import { Notification } from 'src/app/models/notification/notification.models';

@Component({
  selector: 'app-notification-table',
  templateUrl: './notification-table.component.html',
  styleUrls: ['./notification-table.component.scss'],
  providers: [DialogService],
})
export class NotificationTableComponent implements OnInit {
  @ViewChild('dataTable') dataTable: Table;
  @ViewChild('tablePaginator') paginator: Paginator;
  stateOptions: any[] = [
    { label: 'All', value: true },
    { label: 'Unread', value: false },
  ];
  loading: boolean = false;

  notificationsSubject = new BehaviorSubject<Array<Notification>>([]);
  notifications$ = this.notificationsSubject.asObservable();
  notifications: Array<Notification> = [];
  currentPage: number = 0;
  rowCount: number = 20;
  markAllReadAvailable: boolean = false;
  destroy$ = new Subject();
  firstLoad: boolean = true;
  cols: TableColumn[] = cloneDeep(NotificationsColumns);
  totalRecords: number = 0;
  allToggle: boolean = true;

  _selectedColumns: TableColumn[] = [];

  get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter(col =>
      val.map(valCol => valCol.header).includes(col.header)
    );
  }

  currentTableFilters = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public notificationService: NotificationService,
    private notificationsService: NotificationsService,
    private notificationHelper: NotificationHelper,
    private breadcrumbService: BreadcrumbService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  async ngOnInit() {
    this.notifications$.subscribe(notifications => {
      this.notifications = notifications || [];
    });
    const breadcrumbs: MenuItem[] = [
      { label: 'Notifications', routerLink: '/secure/account/notifications' },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
  }

  loadTableFromURL(): Filters {
    const filters: Filters = {};
    const params = this.route.snapshot.params;
    if (params['OpenedAt.StartDate'] && params['OpenedAt.EndDate']) {
      const startDateDateTime = DateTime.fromFormat(
        params['OpenedAt.StartDate'],
        'dd/MM/yyyy'
      );
      // Convert to a JavaScript Date object
      const startDate = startDateDateTime.toJSDate();
      const endDateDateTime = DateTime.fromFormat(
        params['OpenedAt.EndDate'],
        'dd/MM/yyyy'
      );
      // Convert to a JavaScript Date object
      const endDate = endDateDateTime.toJSDate();
      const values = [startDate, endDate];
      let column = this.cols.find(
        currentFilter => currentFilter.serviceNowField === 'ULastUpdated'
      );
      if (column) {
        filters[column.field] = {
          value: values,
          matchMode: 'between',
        };
      }
    }
    if (params['UTicket']) {
      let column = this.cols.find(
        currentFilter => currentFilter.serviceNowField === 'UTicket'
      );
      if (column) {
        filters[column.field] = {
          value: params['UTicket'],
          matchMode: 'in',
        };
      }
    }
    if (params['Priority']) {
      let column = this.cols.find(
        currentFilter => currentFilter.serviceNowField === 'Priority'
      );
      if (column) {
        filters[column.field] = {
          value: params['Priority'],
          matchMode: 'in',
        };
      }
    }
    return filters;
  }

  async loadNotifications(
    table: Table,
    event?: LazyLoadEvent | PaginatorState
  ) {
    if (IsPaginatorState(event)) {
      table.rows = event?.rows;
      table.first = event?.first;
    }

    const isFirstLoad = this.firstLoad ? true : false;
    table.loading = true;

    this.notificationsSubject.next([]);

    if (isFirstLoad) {
      this.reset(table, true, cloneDeep(NotificationsColumns));
      this.firstLoad = false;
    }

    const currentPage =
      event?.first != null && event?.rows != null
        ? event.first / event.rows
        : table.first / table.rows;
    const currentPerPage = event?.rows ? event.rows : table.rows;

    let serviceNowFilter = BuildServiceNowQuery(
      table,
      this.cols,
      currentPerPage,
      currentPage,
      event
    );

    if (!this.allToggle) {
      serviceNowFilter.Filter.push({
        Column: 'uNotificationRead',
        Value: ['false'],
      });
    }

    const notificationCount =
      (await firstValueFrom(
        this.notificationsService.getCount(serviceNowFilter)
      )) ?? 0;

    const notifications = await firstValueFrom(
      this.notificationsService.getNotifications(serviceNowFilter)
    );

    this.totalRecords = notificationCount;
    this.notificationsSubject.next(notifications);
    this.setNotification();
    table.loading = false;
  }

  async onToggleChange(table: Table): Promise<void> {
    await this.loadNotifications(table);
  }

  setNotification() {
    this.notificationService
      .getMarkAllReadUpdate()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.markAllReadUpdate();
      });
    if (
      this.notifications.some(
        notification => notification.notificationRead === false
      )
    )
      this.markAllReadAvailable = true;
  }

  handleRowClicked(notification: Notification) {
    this.openNotification(notification);
  }

  openNotification(notification: Notification) {
    this.notificationHelper.openNotification(notification);
  }

  markAllReadUpdate() {
    this.notifications.forEach(notification => {
      notification.notificationRead = true;
    });
    this.markAllReadAvailable = false;
  }

  markAllReadSelected() {
    this.notificationHelper.markAllAsRead();
    this.markAllReadAvailable = false;
    this.allToggle = true;
    if (this.dataTable) {
      this.loadNotifications(this.dataTable);
    }
  }

  reset(
    table: Table,
    firstLoad: boolean = false,
    columns: TableColumn[] = NotificationsColumns,
    additionalFilters?: Filters
  ) {
    this._selectedColumns = SetSelectedColumns(
      this.cols,
      columns.filter(column => column?.default).map(column => column?.field)
    );
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
      additionalFilters: additionalFilters,
    });
  }
}
