import { TableColumn } from 'src/app/models/table';

export interface IDomainColumn {
  field: string;
  header: string;
  display: any;
  filter?: {
    type: string;
  };
  showSortIcon: boolean;
}

export const domainColumns: IDomainColumn[] = [
  {
    field: 'name',
    header: 'Name',
    display: { type: 'text' },
    filter: { type: 'text' },
    showSortIcon: true,
  },
  {
    field: 'ttl',
    header: 'TTL',
    display: { type: 'text' },
    filter: { type: 'text' },
    showSortIcon: true,
  },
  {
    field: 'type',
    header: 'Type',
    display: { type: 'text' },
    filter: { type: 'text' },
    showSortIcon: true,
  },
  {
    field: 'value',
    header: 'Value',
    display: { type: 'text' },
    filter: { type: 'text' },
    showSortIcon: true,
  },
  {
    field: 'edit',
    header: 'Edit',
    display: { type: 'button' },
    filter: { type: 'none' },
    showSortIcon: false,
  },
  {
    field: 'remove',
    header: 'Remove',
    display: { type: 'button' },
    filter: { type: 'none' },
    showSortIcon: false,
  },
];

export const adminDomainColumns: IDomainColumn[] = [
  {
    field: 'name',
    header: 'Name',
    display: { type: 'text' },
    showSortIcon: true,
  },
];

export interface IDomainStatus {
  name: string;
  zoneId: string;
  suspendedStatus: string;
}

export interface IChipStatus {
  color: string;
  text: string;
}

export interface IDomainRecord {
  name: string;
  type: string;
  ttl: number;
  value: string[];
  route53Record: Route53Record | null;
  canEdit: boolean;
  canDelete: boolean;
}

export type Route53Record = {
  name: string;
  type: string;
  ttl: number;
  value: string | string[];
};

export interface domainInfo {
  name: string;
  company: string;
  reverseDns: boolean;
}

export interface IDomainsColumn {
  field: string;
  header?: string;
  default: boolean;
  display?: any;
  filter?: {
    type: string;
    values: { label: string; value: string }[];
  };
  checkBoxVisible?: boolean;
  showSortIcon: boolean;
}

export const IDomainsColumns: IDomainsColumn[] = [
  {
    field: 'name',
    header: 'Domain Name',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
  },
];

export const IDomainColums: TableColumn[] = [
  {
    field: 'name',
    header: 'Name',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
  },
  {
    field: 'ttl',
    header: 'TTL',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
  },
  {
    field: 'type',
    header: 'Type',
    display: { type: 'text' },
    filter: {
      type: 'multiSelect',
      values: [
        { label: 'SOA', value: 'SOA' },
        { label: 'A', value: 'A' },
        { label: 'AAAA', value: 'AAAA' },
        { label: 'CNAME', value: 'CNAME' },
        { label: 'MX', value: 'MX' },
        { label: 'SRV', value: 'SRV' },
        { label: 'TXT', value: 'TXT' },
      ],
    },
    showSortIcon: true,
    default: true,
  },
  {
    field: 'value',
    header: 'Value',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
  },
  {
    field: 'edit',
    header: 'Edit',
    display: { type: 'button' },
    showSortIcon: false,
    default: true,
  },
  {
    field: 'remove',
    header: 'Remove',
    display: { type: 'icon' },
    showSortIcon: false,
    default: true,
  },
];
