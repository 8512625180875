<div class="flex flex-column h-full">
  <h1 class="text-xl px-3">Escalate Incident</h1>
  <hr class="w-full" />
  <div class="px-4">
    <p class="text-base m-0 pb-2">
      Raising an Escalation will advise your Service Delivery Manager that we have fallen short of expectations on this incident.
      This escalation will be reviewed by the SDM who will keep you updated on the situation and next steps. <br />
      Please provide all necessary details which can assist in this review.
    </p>
    <p-messages [(value)]="detail" [enableService]="false" [closable]="false" />
    <div class="pb-1">
      <span
        ><span class="text-primary text-lg">*</span
        ><label for="Reason" class="text-lg font-bold"
        >Request Reason
      </label></span
      >
      <form [formGroup]="escalateForm">
        <textarea
          pInputText
          id="Reason"
          placeholder="Reason"
          formControlName="escalateReason"
          aria-label="reason"
          type="text"
          rows="4"
        class="w-full"></textarea>
        @if (
          escalateForm.get('escalateReason').invalid &&
          escalateForm.get('escalateReason').touched
          ) {
          <small
            class="p-error text-base">
            Reason is required.
          </small>
        }
      </form>
    </div>
    <div class="flex align-content-center justify-content-center py-3">
      <p-button
        styleClass="p-button-secondary"
        label="Cancel"
      (onClick)="closeButton()"></p-button>
      <p-button
        styleClass="p-button"
        [disabled]="escalateForm.invalid"
        label="Submit"
      (onClick)="expediteIncident()"></p-button>
    </div>
  </div>
</div>
