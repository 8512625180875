import { Injectable } from '@angular/core';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IDomainInfo } from 'src/app/components/service-hub/products-and-services/domains/shared/domains-models';
import { Route53Record } from 'src/app/components/service-hub/products-and-services/domains/shared/domain-models';
import { Observable } from 'rxjs';
import { ServiceNowFilterQuery } from 'src/app/models/servicenow.model';
const host: string = environment.microservices.domainManagement;

type ServiceNowDomain = {
  sysId: string;
  name: string;
  company: string;
  reverseDns: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class DomainService {
  constructor(
    private apiRequest: ApiRequest,
    private http: HttpClient
  ) {}

  getDomain(domainSysId: string): Observable<ServiceNowDomain> {
    const requestProps: IRequestOptions = {
      host: host,
      method: 'GET',
      endpoint: `/${domainSysId}`,
    };
    return this.apiRequest.request(requestProps);
  }

  isDomainDsManaged(domain: any) {
    const requestProps: IRequestOptions = {
      endpoint: 'Domains/GetDomainInformation',
      body: domain,
    };
    return this.apiRequest.request(requestProps);
  }

  getDomains(request: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: '/list',
      body: {
        filter: request.Filter,
        currentPerPage: request.Amount,
        currentPage: request.CurrentPage,
      },
      host: host,
    };
    return this.apiRequest.request(requestProps);
  }

  addDomain(domainSysId: string) {
    const requestProps: IRequestOptions = {
      body: { domainSysId },
      endpoint: '/',
      host: host,
    };
    return this.apiRequest.request(requestProps);
  }

  deleteDomain(domainSysId: string) {
    const requestProps: IRequestOptions = {
      host: host,
      method: 'DELETE',
      endpoint: `/${domainSysId}`,
    };
    return this.apiRequest.request(requestProps);
  }

  suspendDomain(domainSysId: string) {
    const requestProps: IRequestOptions = {
      host: host,
      method: 'POST',
      endpoint: `/${domainSysId}/suspend`,
    };
    return this.apiRequest.request(requestProps);
  }

  resumeDomain(domainSysId: string) {
    const requestProps: IRequestOptions = {
      host: host,
      method: 'POST',
      endpoint: `/${domainSysId}/resume`,
    };
    return this.apiRequest.request(requestProps);
  }

  getDomainStatus(domainName: string) {
    const requestProps: IRequestOptions = {
      host: host,
      method: 'GET',
      endpoint: `/${domainName}/status`,
    };
    return this.apiRequest.request(requestProps);
  }

  getDnsCheck(domainName: string) {
    const requestProps: IRequestOptions = {
      host: host,
      method: 'GET',
      endpoint: `/dns-check/${domainName}`,
    };
    return this.apiRequest.request(requestProps);
  }

  getDomainRecords(domain: string) {
    const requestProps: IRequestOptions = {
      method: 'GET',
      endpoint: `/${domain}/records`,
      host: host,
      body: '',
    };
    return this.apiRequest.request(requestProps);
  }

  addDomainRecord(domainSysId: string, record: Route53Record) {
    const requestProps: IRequestOptions = {
      endpoint: `/${domainSysId}/records`,
      host: host,
      method: 'POST',
      body: record,
    };
    return this.apiRequest.request(requestProps);
  }

  editDomainRecord(domainSysId, record: Route53Record) {
    const requestProps: IRequestOptions = {
      endpoint: `/${domainSysId}/records`,
      host: host,
      method: 'PUT',
      body: record,
    };
    return this.apiRequest.request(requestProps);
  }

  deleteDomainRecord(domainName: string, record: Route53Record) {
    const requestProps: IRequestOptions = {
      endpoint: `/${domainName}/records`,
      host: host,
      method: 'DELETE',
      body: record,
    };
    return this.apiRequest.request(requestProps);
  }
}
