import { IDeviceColumn } from 'src/app/components/service-hub/products-and-services/science-logic/cmdb-devices/shared/device-category-interfaces';

export const DeviceNetworkDetailColumns: IDeviceColumn[] = [
  {
    field: 'name',
    header: 'Name',
    display: { type: 'text' },
    default: true,
    order: 1,
    source: 'ServiceNow',
    disabled: true,
  },
  {
    field: 'class',
    header: 'Class',
    display: { type: 'text' },
    filter: {
      type: 'multiSelect',
      values: [],
    },
    default: true,
    source: 'ServiceNow',
  },
  {
    field: 'make',
    header: 'Make',
    display: { type: 'text' },
    default: false,
    source: 'ScienceLogic',
  },
  {
    field: 'model',
    header: 'Model',
    display: { type: 'text' },
    default: true,
    source: 'ScienceLogic',
  },
  {
    field: 'ipAddress',
    header: 'IP Address',
    display: { type: 'text' },
    default: true,
    source: 'ServiceNow',
  },
  {
    field: 'serialNumber',
    header: 'Serial Number',
    display: { type: 'text' },
    default: true,
    source: 'ServiceNow',
  },
  {
    field: 'firmwareVersion',
    header: 'Firmware Version',
    display: { type: 'text' },
    default: true,
    source: 'ScienceLogic',
  },
  {
    field: 'pollingStatus',
    header: 'Polling Status',
    display: { type: 'text', colors: { Active: 'teal' } },
    filter: {
      type: 'multiSelect',
      values: [],
    },
    default: true,
    source: 'ScienceLogic',
  },
  {
    field: 'available',
    header: 'Availability',
    display: {
      type: 'chip',
      colors: { Available: 'success', Unknown: 'dark' },
    },
    filter: {
      type: 'multiSelect',
      values: [],
    },
    default: true,
    source: 'ScienceLogic',
  },
  {
    field: 'cpuUsage',
    header: 'CPU Usage',
    display: {
      type: 'chipMetric',
    },
    filter: {
      type: 'multiSelectMetric',
      values: [
        {
          label: '<= 75%',
          value: '<=75',
        },
        {
          label: '76% to 94%',
          value: '>=76&<95',
        },
        {
          label: '> 95%',
          value: '>=95',
        },
      ],
    },
    default: false,
    source: 'ScienceLogic',
  },
  {
    field: 'memoryUsage',
    header: 'Memory Usage',
    display: {
      type: 'chipMetric',
    },
    filter: {
      type: 'multiSelectMetric',
      values: [
        {
          label: '<= 75%',
          value: '<=75',
        },
        {
          label: '76% to 94%',
          value: '>=76&<95',
        },
        {
          label: '> 95%',
          value: '>=95',
        },
      ],
    },
    default: false,
    source: 'ScienceLogic',
  },
];
