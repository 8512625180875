import { Directive, ElementRef, Output, Input, OnInit } from '@angular/core';
import { EventEmitter } from 'protractor';
declare var flatpickr: any;

@Directive({
  selector: '[appDatePicker]',
})
export class DatePickerDirective implements OnInit {
  element: any;

  @Input() mode: String = 'range';
  @Input() includeTime: Boolean = false;
  @Input() minDate: String;
  @Input() maxDate: String;

  constructor(el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    this.element.classList.add('form-control');
    this.initFlatPickr();
  }

  ngOnChanges() {
    if (this.minDate != '' || this.maxDate != '') this.initFlatPickr();
  }

  initFlatPickr() {
    if (
      !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      flatpickr(this.element, {
        mode: this.mode,
        dateFormat: this.includeTime ? 'd/m/y H:i' : 'd/m/Y',
        allowInput: true,
        enableTime: this.includeTime,
        minDate: this.minDate,
        maxDate: this.maxDate,
      });
    }
  }
}
