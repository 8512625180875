import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FileUpload } from 'src/app/components/misc/file-upload/file-upload';
import { MenuItem } from 'primeng/api/menuitem';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import { RequestCategory } from 'src/app/models/service-request/service-request.models';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { OtherServiceRequestCategoryItem } from '../service-request-options/service-request-options.component';

@Component({
  selector: 'create-service-request',
  templateUrl: './create-service-request.component.html',
  styleUrls: ['./create-service-request.component.scss'],
})
export class CreateServiceRequestComponent implements OnInit {
  @ViewChild('requestStepper') stepper;

  resetSubject = new BehaviorSubject<boolean | null>(null);
  reset$ = this.resetSubject.asObservable();

  loading = false;

  requestNumber: string;
  requestSysId: string;

  uploadedFiles: FileUpload[];

  fileUploadErrors: object;

  menuItems: MenuItem[] = [
    {
      label: 'Request',
    },
    {
      label: 'Details',
    },
    {
      label: 'Submit',
    },
  ];

  pageTitle = 'Request';

  activeIndex: MenuItem = this.menuItems[0];
  activeStepIndex = 0;

  requestCategory = RequestCategory;

  generalRequestForm: FormGroup = new FormGroup({
    general: new FormGroup({
      shortDescription: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      files: new FormControl([]),
    }),
  });

  serviceNowCatalogForm: FormGroup = new FormGroup({
    category: new FormControl(''),
    request: new FormControl(''),
    variables: new FormGroup({}),
  });

  childIsLoading = false;

  OtherServiceRequestCategoryItem = OtherServiceRequestCategoryItem;

  constructor(
    private router: Router,
    public breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    const breadcrumbs: MenuItem[] = [
      { label: 'Raise a Ticket', routerLink: '/secure/tickets/raise' },
      { label: 'Raise a Service Request' },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
  }

  onActiveIndexChange(event: number): void {
    this.activeStepIndex = event;
    this.activeIndex = this.menuItems[this.activeStepIndex];
  }

  stepForward(): void {
    if (this.menuItems[this.activeStepIndex + 1]) {
      if (this.activeStepIndex !== 0 && this.isFormInvalid()) {
        this.markAllFormsAsTouched();
        return;
      }
      this.onActiveIndexChange(this.activeStepIndex + 1);
    }
  }

  backButton(): void {
    this.router.navigate(['secure/tickets/raise']);
  }

  stepBack(): void {
    const step = this.activeStepIndex - 1;
    if (step === 0) {
      this.serviceNowCatalogForm.setControl('variables', new FormGroup({}));
    }
    if (this.menuItems[step]) {
      this.onActiveIndexChange(step);
    }
  }

  viewTicket(): void {
    this.router.navigateByUrl(
      `secure/tickets/service-request/${this.requestSysId}`
    );
  }

  onFormComplete(): void {
    this.pageTitle = this.serviceNowCatalogForm.value?.request?.label
      ? `Request - ${this.serviceNowCatalogForm.value.request.label}`
      : 'Request';
    this.stepForward();
  }

  onStepBack() {
    this.stepBack();
  }

  triggerReset() {
    this.resetSubject.next(true);
  }

  raiseAnotherRequest(): void {
    this.activeStepIndex = 0;
    this.generalRequestForm.reset();
    this.triggerReset();
    this.onActiveIndexChange((this.activeStepIndex = 0));
  }

  markAllFormsAsTouched() {
    if (
      this.serviceNowCatalogForm.get('request').value.value ===
      OtherServiceRequestCategoryItem.VALUE
    ) {
      return this.generalRequestForm.markAllAsTouched();
    }
    return this.serviceNowCatalogForm.markAllAsTouched();
  }

  isFormInvalid(): boolean {
    if (
      this.serviceNowCatalogForm.get('request').value.value ===
      OtherServiceRequestCategoryItem.VALUE
    ) {
      return this.generalRequestForm.invalid;
    }
    return this.serviceNowCatalogForm.invalid;
  }

  setChildIsLoading(event: boolean) {
    this.childIsLoading = event;
  }
}
