import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { AddEditFiltersComponent } from 'src/app/components/misc/pop-up/filters/filter-dropdown/prime-ng-filters/add-edit-filters/add-edit-filters.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { FilterMetadata, FilterService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import {
  Filters,
  TableColumn,
  TableColumnFilter,
  TableFiltersWithColumns,
} from 'src/app/models/table';
import {
  GetFiltersWithColumns,
  IsFilterMetadata,
  TableApplyFilters,
} from 'src/app/helpers/table.helper';

@Component({
  selector: 'prime-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.scss'],
})
export class FilterDropdownPrimeComponent implements OnChanges {
  constructor(
    private dialogService: DialogService,
    private filterService: FilterService
  ) {}
  @Input() selectedColumns: TableColumn[];
  @Input() table: Table;
  @Input() filterPreset: Filters = {};
  @Input() futurePeriodFilter: number = 0;
  @Output() filterChange: EventEmitter<Filters> = new EventEmitter<Filters>();

  ref: DynamicDialogRef;
  filters: TableFiltersWithColumns;

  get filterKeys(): string[] {
    return Object.keys(this.filters);
  }

  ngOnChanges() {
    this.filters = GetFiltersWithColumns(this.table, this.selectedColumns);
    if (
      this.selectedColumns?.some(
        item => item?.filter?.type === 'multiSelectMetric'
      )
    ) {
      this.registerMetricRangeFilter();
    }
  }

  async showEditFilters(table) {
    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    this.ref = this.dialogService.open(AddEditFiltersComponent, {
      data: {
        table,
        cols: this.selectedColumns,
        futurePeriodFilter: this.futurePeriodFilter,
      },
      width: '70%',
      showHeader: false,
      styleClass: 'p-dialog-custom',
    });
    let filters: { [key: string]: FilterMetadata | FilterMetadata[] } =
      await firstValueFrom(this.ref.onClose);
    if (filters) {
      this.filterChanged(table, filters);
      this.filters = GetFiltersWithColumns(table, this.selectedColumns);
    }
  }

  removeMultiSelect(table: Table, field: string, value: string) {
    const selectedFilter = this.filters[field];
    if (IsFilterMetadata(selectedFilter)) {
      selectedFilter.value = selectedFilter?.value?.filter(
        item => item !== value
      );
      const currentFilter = this.filters[field];
      if (
        selectedFilter.matchMode === 'between' &&
        selectedFilter.value.length === 1 &&
        selectedFilter.value[0] instanceof Date &&
        IsFilterMetadata(currentFilter)
      ) {
        currentFilter.matchMode = 'dateIs';
        currentFilter.value = selectedFilter.value[0];
        delete currentFilter.operator;
      }
      if (selectedFilter.value.length === 0) {
        delete this.filters[field];
      }
      this.filterChanged(table, this.filters);
    }
  }

  registerMetricRangeFilter() {
    this.filterService.register(
      'multiSelectMetric',
      (value, filter): boolean => {
        if (!value) return false;
        if (filter.includes('<=75') && value <= 75) return true;
        if (filter.includes('>=76&<95') && value >= 76 && value < 95)
          return true;
        if (filter.includes('>=95') && value >= 95) return true;
        return false;
      }
    );
  }

  async editFilters() {
    await this.showEditFilters(this.table);
  }

  async openFilters() {
    await this.showEditFilters(this.table);
  }

  isArray(value: any): value is any[] {
    return Array.isArray(value);
  }

  removeSingleFilter(table: Table, field: string) {
    delete this.filters[field];
    this.filterChanged(table, this.filters);
  }

  clearFilters(table: Table) {
    this.filters = this.filterPreset;
    this.filterChanged(table, this.filterPreset);
  }

  filterChanged(table, filters) {
    TableApplyFilters(table, filters);
    this.filterChange.emit(filters);
  }

  getFilterLabel(filter: TableColumnFilter, value) {
    return (
      filter?.values?.find(filterValue => filterValue.value === value)?.label ??
      value
    );
  }
}
