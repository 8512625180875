<div class="flex flex-column h-full">
  <div
    class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200">
    <h1 class="px-3 text-xl">Invoices</h1>
  </div>
  <div
    class="flex-grow-1 overflow-auto mx-3 overflow-hidden bg-white border-solid border-1 border-gray-200 border-top-none border-bottom-none px-3">
    <p-table
      #dataTable
      [columns]="selectedColumns"
      [value]="invoices$ | async"
      [lazy]="true"
      [rows]="25"
      (onLazyLoad)="loadInvoices(dataTable, $event)"
      styleClass="p-datatable-striped"
      [rowsPerPageOptions]="[25, 50, 100]"
      [rowHover]="true"
      [tableStyle]="{ 'min-width': '50rem' }"
      [selectionPageOnly]="true"
      [(selection)]="selectedInvoices"
      selectionMode="single"
      (onRowSelect)="handleRowClicked($event)"
      (onRowUnselect)="handleRowUnselect($event)"
      scrollHeight="flex"
      [scrollable]="true">
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between">
          <div class="flex">
            @if (selectedColumns && dataTable && dataTable.loading === false) {
              <prime-filter-dropdown
                [selectedColumns]="cols"
                [table]="dataTable"></prime-filter-dropdown>
            }
            <p-multiSelect
              styleClass="p-multiselect-label-hide p-multiselect-border"
              [options]="cols"
              [(ngModel)]="selectedColumns"
              optionLabel="header"
              dropdownIcon="pi pi-sliders-h"
              placeholder="Choose Columns"
              selectedItemsLabel="{0} columns selected"
              (ngModelChange)="handleColumnChange(cols)"
              optionDisabled="disabled"></p-multiSelect>
            <p-button
              label="Reset"
              styleClass="p-button p-button-text p-button-secondary"
              icon="pi pi-eraser"
              (onClick)="reset(dataTable, false)"></p-button>
          </div>
          <p-button
            icon="pi pi-download"
            label="Export"
            iconPos="left"
            (onClick)="openExportDialog()"
            [disabled]="selectedInvoice === 0"
            styleClass="p-button export-button"></p-button>
        </div>
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <tr>
          <td [attr.colspan]="selectedColumns?.length + 1">
            <loading-spinner></loading-spinner>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingicon"></ng-template>

      <ng-template pTemplate="header" let-columns let-invoice>
        <tr>
          <th style="width: 4rem" class="bg-gray-800">
            <p-tableHeaderCheckbox
              (click)="selectAllToggle()"></p-tableHeaderCheckbox>
          </th>
          @for (col of columns; track col) {
            <th
              class="text-white bg-gray-800 p-datatable-header-first-row"
              [pSortableColumn]="col.field">
              @if (!col.field?.visible) {
                {{ col.header }}
              }
              @if (col.showSortIcon) {
                <p-sortIcon class="text-white" [field]="col.field"></p-sortIcon>
              }
            </th>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-invoice let-columns="columns">
        <tr></tr>
        <tr>
          <td>
            <p-tableCheckbox
              [value]="invoice"
              (onChange)="handleCheckboxChange($event, invoice)" />
          </td>
          @for (col of columns; track col) {
            <td>
              @if (col.display?.type === 'text') {
                {{ invoice[col.field] }}
              }
              @if (col.display?.type === 'date') {
                {{ invoice[col.field] | date: 'dd/MM/yyyy' }}
              }
              @if (col.display?.type === 'number') {
                {{ invoice[col.field] | currency: 'GBP' }}
              }
            </td>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="selectedColumns?.length + 1">
            No results were found.
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td [attr.colspan]="selectedColumns.length + 1" class="bg-white">
            <div class="flex align-items-center justify-content-center">
              <p-paginator
                #tablePaginator
                (onPageChange)="loadInvoices(dataTable, $event)"
                [first]="dataTable.first"
                [rows]="dataTable.rows"
                [totalRecords]="totalRecords"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} - {last} of {totalRecords}"
                [rowsPerPageOptions]="[25, 50, 100]"></p-paginator>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
