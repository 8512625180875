import { Component, Input } from '@angular/core';
import { EChartsOption } from 'echarts';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-e-line-graph',
  templateUrl: './e-line-graph.component.html',
  styleUrl: './e-line-graph.component.scss',
})
export class ELineGraphComponent {
  destroy$ = new Subject<any>();
  newChart: EChartsOption;
  @Input() graphData$: Observable<any>;
  noData: boolean = false;
  data: any;
  constructor() {}

  ngOnInit(): void {
    this.graphData$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.data = data;
      this.buildBarChart();
    });
  }

  async buildBarChart(): Promise<EChartsOption> {
    const title = this.data?.title.split('-').pop().trim();
    const seriesData = this.data.series;
    const day = seriesData[0].items.map((item: any) => item.label);
    const uploadData = seriesData[0].items.map((item: any) => item.count);
    const downloadData = seriesData[1].items.map((item: any) => item.count);
    const yAxis = this.data?.options.scales.yAxes[0].labelString;

    return (this.newChart = {
      title: {
        text: title,
        left: '14px',
      },

      legend: {
        data: [seriesData[0].name, seriesData[1].name],
        bottom: 10,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      xAxis: {
        type: 'category',
        data: day,
      },
      yAxis: {
        type: 'value',
        nameLocation: 'middle',
        nameGap: 40,
        name: yAxis,
      },
      series: [
        {
          name: seriesData[0].name,
          type: 'line',
          color: seriesData[0].colour,
          stack: 'Total',
          data: uploadData,
          smooth: true,
        },
        {
          name: seriesData[1].name,
          type: 'line',
          color: seriesData[1].colour,
          stack: 'Total',
          data: downloadData,
          smooth: true,
        },
      ],
    });
  }
  //TODO:Rework backend
}
