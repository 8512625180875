import { Injectable } from '@angular/core';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  constructor(private apiRequest: ApiRequest) {}

  takeSurvey(
    surveyId: string,
    options: { additionalComments?: string; score: number }
  ) {
    const { additionalComments, score } = options;
    const requestProps: IRequestOptions = {
      endpoint: 'survey/',
      method: 'POST',
      body: {
        sysId: surveyId,
        AdditionalComments: additionalComments,
        Score: score,
      },
    };

    return this.apiRequest.request(requestProps);
  }

  getSurvey(surveyId: string) {
    const requestProps: IRequestOptions = {
      endpoint: `survey/${surveyId}`,
      method: 'GET',
    };

    return this.apiRequest.request(requestProps);
  }
}
