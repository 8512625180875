<div
  [ngClass]="
    getServiceLabel(service, 'Outage') === 'Good'
      ? 'text-teal-500'
      : 'text-primary'
  ">
  <p class="text-xl font-bold text-gray-900 align-items-center">
    {{ service?.businessService.name }}
  </p>
  @if (iconClick) {
    <i
      (keyup.enter)="handleIconClicked(service?.businessService)"
      (click)="handleIconClicked(service?.businessService)"
      role="button"
      tabindex="0"
      class="cursor-pointer text-6xl my-3 pi pi-{{
        getServiceIcon(service, 'Outage')
      }}"></i>
  } @else {
    <i class="text-6xl my-3 pi pi-{{ getServiceIcon(service, 'Outage') }}"></i>
  }
  <p class="m-0 p-0">{{ getServiceLabel(service, 'Outage') }}</p>
</div>
