<div class="flex m-3 gap-3">
  <div class="w-6 flex-shrink-1">
    <div class="bg-white flex flex-column border-1 border-gray-200 min-h-36rem">
      <div class="flex border-bottom-1 mb-2 border-gray-200 p-3">
        <h1 class="col text-xl m-0 pl-0">Tickets by month</h1>
        <div class="flex flex-column justify-content-center">
          <p-button
            icon="pi pi-download"
            iconPos="left"
            class="h-auto"
            styleClass="p-button export-button"
            label="Export"
            [disabled]="showLoaderTickets"
            (onClick)="exportTicket()">
          </p-button>
        </div>
      </div>
      @if (!showLoaderTickets && !loadFailedTickets) {
        <p-tabView
          styleClass="tabs-evenly-spaced"
          (onChange)="updateActiveTab($event)">
          @for (ticketType of ticketTypes; track ticketType) {
            <p-tabPanel [header]="ticketType.label">
              <ng-template pTemplate="content">
                @if (ticketsOpenClosed) {
                  <app-tickets-chart
                    [graphData]="ticketType.data"></app-tickets-chart>
                }
              </ng-template>
            </p-tabPanel>
          }
        </p-tabView>
        {{ ticketType?.data }}
      }
      @if (showLoaderTickets && !loadFailedTickets) {
        <loading-spinner></loading-spinner>
      }
      @if (!showLoaderTickets && loadFailedTickets) {
        <div
          class="flex align-content-center justify-content-center h-full w-full flex-wrap">
          <p>No Data to load</p>
        </div>
      }
    </div>
    <div class="bg-white flex flex-column border-1 border-gray-200 mt-3">
      <div class="flex border-bottom-1 mb-2 border-gray-200 p-3">
        <h1 class="col text-xl m-0 pl-0">Closed Incidents by priority</h1>
        <div class="flex flex-column justify-content-center">
          <p-button
            icon="pi pi-download"
            iconPos="left"
            class="h-auto"
            styleClass="p-button export-button"
            label="Export"
            [disabled]="showLoaderTickets"
            (onClick)="exportTicketPriority()">
          </p-button>
        </div>
      </div>
      @if (!showLoaderPriority && !loadFailedPriority) {
        <div class="flex flex-column justify-content-end h-full">
          <div
            class="echarts flex m-2"
            echarts
            [options]="incidentPriorityChart"></div>
        </div>
      }
      @if (!showLoaderPriority && loadFailedPriority) {
        <div
          class="flex align-content-center justify-content-center h-full w-full flex-wrap">
          <p>No Data to load</p>
        </div>
      }
      @if (showLoaderPriority && !loadFailedPriority) {
        <loading-spinner />
      }
    </div>
  </div>

  <div class="w-6 flex-shrink-1">
    <div
      class="bg-white flex flex-column border-1 border-gray-200 h-36rem overflow-auto">
      <div
        class="flex justify-content-between align-items-center border-bottom-1 border-gray-200 mb-2 p-3">
        <div class="flex align-items-center gap-3">
          <h1 class="col text-xl m-0 pl-0">Incident Count by site</h1>
          <p-dropdown
            [options]="months"
            [(ngModel)]="selectedMonth"
            (onChange)="loadSitesWithTheHighestCountOfIncidents()"
            optionLabel="name"
            placeholder="Select a Month">
          </p-dropdown>
        </div>
        <p-button
          icon="pi pi-download"
          iconPos="left"
          styleClass="p-button export-button"
          label="Export"
          (onClick)="export()">
        </p-button>
      </div>
      @if (!sitesLoading) {
        <div class="overflow-auto overflow-hidden">
          <p-table
            #dt1
            [value]="sites$ | async"
            styleClass="p-datatable-striped w-full p-3"
            [resizableColumns]="true"
            columnResizeMode="expand"
            [scrollable]="true"
            [rowHover]="true"
            scrollHeight="flex"
            [scrollable]="true"
            [paginator]="true"
            [showCurrentPageReport]="true"
            [rows]="10"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} sites"
            paginatorDropdownAppendTo="body">
            <ng-template pTemplate="header">
              <tr class="text-white bg-gray-800 p-datatable-header-first-row">
                <th>Site</th>
                <th>Incidents</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-site>
              <tr>
                <td>
                  {{ site.fullName ?? '-' }}
                  <br />
                  <small>{{ site.address }}</small>
                </td>
                <td>{{ site.total }}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="2">No results were found.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      }
      @if (sitesLoading) {
        <loading-spinner />
      }
    </div>
  </div>
</div>
