@if (loading) {
  <loading-spinner />
}
@if (!loading) {
  <div>
    <div class="pt-4">
      <div class="shadow-2 p-4 flex justify-content-between align-items-center">
        <h1 class="text-2xl font-bold p-0 m-0">
          Search results for "{{ displayLabel }}"
        </h1>
        <p-button routerLink="/secure/dashboard" label="Home"></p-button>
      </div>
    </div>
    <div class="grid mr-0">
      @if (resultList && resultList.length > 0) {
        @for (result of resultList; track result) {
          @if (result.recordCount > 0) {
            <div class="col-12 pl-4">
              <h2>{{ result?.labelPlural }}</h2>
              <p>
                {{ result?.recordCount }} result{{
                  result?.recordCount > 1 ? 's' : ''
                }}
                found in
                <span class="font-bold">{{ result?.labelPlural }}</span>
                {{
                  result?.recordCount > 10 ? '(showing first 10 results)' : ''
                }}
              </p>
              <div class="border-top-1 border-gray-200 mt-2 mb-2"></div>
              <div class="p-3">
                @for (record of result.records; track record) {
                  <div
                    class="notification p-2 mb-3 cursor-pointer"
                    (click)="navigateToRecord(result.label, record)">
                    <div class="hover:bg-gray-200">
                      <p class="m-0 font-bold">
                        {{ record.number }}
                      </p>
                      @if (record.title) {
                        <p class="m-0">{{ record.title }}</p>
                      }
                      @if (!record.title) {
                        <p class="m-0">
                          <i>No description available.</i>
                        </p>
                      }
                      @if (record.openedAt) {
                        <p class="mt-1 m-0">
                          {{ record.openedAt }}
                        </p>
                      }
                    </div>
                  </div>
                  <div class="border-top-1 border-gray-200 my-2"></div>
                }
              </div>
              <div class="text-center">
                <a
                  [routerLink]="['../' + getUrl(result.labelPlural)]"
                  class="font-bold text-primary">
                  See all
                </a>
              </div>
            </div>
          }
        }
      }
      @if (!hasResults) {
        <div class="shadow-2 col-12 mt-4 pl-5">
          <div class="text-left">
            <p class="text-2xl">
              Your search -
              <span class="font-bold text-2xl">{{ displayLabel }}</span> - did
              not match any documents
            </p>
          </div>
          <div class="border-top-1 border-gray-200 mt-3 mb-3"></div>
          <div>
            <p>Suggestions:</p>
            <ul>
              <li>Make sure that all words are spelled correctly.</li>
              <li>Try different keywords.</li>
              <li>Try more general keywords.</li>
              <li>Try fewer keywords.</li>
            </ul>
          </div>
        </div>
      }
    </div>
  </div>
}
