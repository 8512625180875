<div class="bg-white flex flex-column h-full">
  <h1 class="text-xl px-3">Edit Cost Centre and User</h1>
  <hr class="w-full" />
  <div class="flex flex-column items-center">
    <p-steps
      [model]="items"
      [readonly]="true"
      [activeIndex]="activeStepIndex"
      (activeIndexChange)="onActiveIndexChange($event)"
      class="mx-auto w-full max-w-60rem">
    </p-steps>
    @if (activeIndex?.label == 'Amend') {
      <div
        class="flex align-items-center justify-content-center"
        >
        <div class="grid mr-0">
          <div class="field col-12 flex gap-5 justify-content-center">
            <div class="flex align-items-center">
              <p-radioButton
                name="editCostCenterGroup"
                value="group"
              [(ngModel)]="selectedEditType"></p-radioButton>
              <label for="editCostCenterGroup" class="ml-2">Group</label>
            </div>
            <div class="flex align-items-center">
              <p-radioButton
                (onClick)="onMultiChange()"
                name="EditCostCenterIndividual"
                value="individual"
              [(ngModel)]="selectedEditType"></p-radioButton>
              <label for="EditCostCenterIndividual" class="ml-2"
                >Individual</label
                >
              </div>
            </div>
            <div class="flex justify-content-center w-full m-0 p-0">
              <div class="col-12 md:col-4">
                <p class="text-lg">
                  Amending as group will set the same cost centre or user for all
                  selected items. If you wish to set a different cost centre / user,
                  we recommend you amend these individually.
                </p>
              </div>
            </div>
            <div class="flex justify-content-center w-full">
              <form [formGroup]="costCentreForm">
                <div class="field">
                  <label for="costCentre">Cost Centre</label>
                  <br />
                  <span
                    (click)="openCostCentreDialog()"
                    class="p-input-icon-right mr-2 md:w-22rem cursor-pointer">
                    <i class="pi pi-search"></i>
                    <input
                      formControlName="costCentre"
                      readonly
                      id="costCentre"
                      type="text"
                      class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none w-full" />
                  </span>
                  <i
                    class="pi pi-history text-lg hover:text-primary cursor-pointer"
                  (click)="resetCostCentre()"></i>
                </div>
                <div class="field">
                  <label for="user">User</label>
                  <br />
                  <span
                    (click)="openUserDialog()"
                    class="p-input-icon-right mr-2 md:w-22rem cursor-pointer">
                    <i class="pi pi-search"></i>
                    <input
                      formControlName="user"
                      readonly
                      id="user"
                      type="text"
                      class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none w-full" />
                  </span>
                  <i
                    class="pi pi-history text-lg hover:text-primary cursor-pointer"
                  (click)="resetUser()"></i>
                </div>
              </form>
            </div>
            <div
              class="flex align-content-center justify-content-center w-full pt-3">
              <p-button
                styleClass="p-button-secondary"
                label="Back"
              (onClick)="backButton()"></p-button>
              <p-button
                styleClass="p-button"
                label="Next"
              (onClick)="stepForward()"></p-button>
            </div>
          </div>
        </div>
      }
      @if (activeIndex?.label == 'Confirm') {
        <div>
          <div class="px-5">
            <p-table
              [value]="costCentres$ | async"
              [columns]="tableColumn"
              styleClass="p-datatable-striped"
              [rowHover]="true"
              [rows]="25">
              <ng-template pTemplate="header" let-columns>
                <tr>
                  @for (col of columns; track col) {
                    <th
                      class="text-white bg-gray-800 p-datatable-header-first-row">
                      {{ col.header }}
                    </th>
                  }
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-items let-columns="columns">
                <tr>
                  @for (col of columns; track col) {
                    <td>
                      @if (col.display?.type === 'text') {
                        {{ items[col.field] }}
                      }
                    </td>
                  }
                </tr>
              </ng-template>
            </p-table>
          </div>
          <div class="flex align-content-center justify-content-center w-full pt-3">
            <p-button
              styleClass="p-button-secondary"
              label="Back"
            (onClick)="stepBack()"></p-button>
            <p-button
              styleClass="p-button"
              label="Next"
            (onClick)="stepForward()"></p-button>
          </div>
        </div>
      }
      @if (activeIndex?.label == 'Submit') {
        <div
          class="flex flex-column align-items-center justify-content-center"
          >
          @if (loading) {
            <loading-spinner />
          }
          @if (!loading) {
            <div
              class="flex flex-column align-items-center justify-content-center">
              <div class="text-left">
                <h2 class="font-bold text-xl m-0 text-left">Submitted.</h2>
                <p class="m-0 text-lg">
                  The updates requested to the Cost Centre and User fields are being
                  processed.
                </p>
                <p class="m-0 text-lg">
                  These will be visible in ServiceHub within 2 hours.
                </p>
              </div>
              <div class="pt-4">
                <p-button (onClick)="finishButton()" label="Finished"></p-button>
              </div>
            </div>
          }
        </div>
      }
    </div>
  </div>
