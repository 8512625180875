import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isValidPhoneNumber } from 'libphonenumber-js';

export function mobileNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return !control.value || !isValidPhoneNumber(control.value, 'GB')
      ? { mobileNumberForbidden: { value: control.value } }
      : null;
  };
}
