<div class="page-content">
  <div style="padding: 15px 25px">
    <div class="page-information" style="text-align: center">
      <p>
        <strong> This is a list of authorised devices. </strong>
      </p>
    </div>
    <hr />
  </div>
</div>
