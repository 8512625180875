@if (!loading) {
  <p-table
    #dt1
    [value]="devices$ | async"
    [paginator]="true"
    [rows]="100"
    styleClass="p-datatable-striped"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} devices"
    [columns]="selectedColumns"
    [rowsPerPageOptions]="[25, 50, 100]"
    sortMode="multiple"
    [resizableColumns]="true"
    columnResizeMode="expand"
    [scrollable]="true"
    [multiSortMeta]="initialSortColumns"
    [rowHover]="true"
    scrollHeight="flex">
    <ng-template pTemplate="caption">
      <div class="flex align-items-center justify-content-between">
        <div class="align-items-center flex">
          <p>Latest update: {{ loadDateTime | date: 'dd/MM/yyyy HH:mm' }}</p>
          <p-button
            (onClick)="openScienceGraph()"
            styleClass="p-button p-button-text p-button-secondary"
            icon="pi pi-refresh">
          </p-button>
          <div class="flex">
            <prime-filter-dropdown
              [selectedColumns]="cols"
              [table]="dt1"></prime-filter-dropdown>
            <p-multiSelect
              styleClass="p-multiselect-label-hide p-multiselect-border"
              [options]="cols"
              [(ngModel)]="selectedColumns"
              optionLabel="header"
              dropdownIcon="pi pi-sliders-h"
              placeholder="Choose Columns"
              selectedItemsLabel="{0} columns selected"
              (ngModelChange)="handleColumnChange(cols)"
              optionDisabled="disabled"></p-multiSelect>
            <p-button
              label="Reset"
              styleClass="p-button p-button-text p-button-secondary "
              icon="pi pi-filter-slash"
              (click)="reset(dt1)"></p-button>
          </div>
        </div>

        <div class="flex gap-1">
          <p-splitButton
            label="Export"
            icon="pi pi-download"
            iconPos="left"
            [model]="exportOptions"
            styleClass="p-button"
            appendTo="body"
            (onClick)="defaultExport()"></p-splitButton>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        @for (col of columns; track col) {
          <th
            class="text-white bg-gray-800 p-datatable-header-first-row"
            [pSortableColumn]="col.field">
            {{ col.header }}
            <p-sortIcon class="text-white" [field]="col.field"></p-sortIcon>
          </th>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-device let-columns="columns">
      <tr>
        @for (col of columns; track col; let firstItem = $first) {
          <td>
            @if (firstItem) {
              <a
                (click)="navigateToDevice(device)"
                class="cursor-pointer"
                (keyup.enter)="navigateToDevice(device)"
                role="button"
                tabindex="0">
                <ng-container
                  *ngTemplateOutlet="
                    fieldTemplate;
                    context: { field: device[col.field], col: col }
                  "></ng-container>
              </a>
            } @else {
              <ng-container
                *ngTemplateOutlet="
                  fieldTemplate;
                  context: { field: device[col.field], col: col }
                "></ng-container>
            }
            <ng-template #fieldTemplate let-field="field" let-col="col">
              @if (field === undefined) {
                <i
                  class="pi pi-exclamation-triangle"
                  style="color: 'var(--yellow-600)'"
                  pTooltip="There was an issue loading this data, please refresh. If this issue persists, please contact support."></i>
              }
              @if (col.display.type === 'text' && col?.display?.colors) {
                <span
                  [class]="
                    getColor(col.display.colors, field)
                      ? 'text-' + getColor(col.display.colors, field) + '-400'
                      : null
                  "
                  class="font-bold"
                  >{{ field }}</span
                >
              }
              @if (col.display.type === 'text' && !col?.display?.colors) {
                {{ device[col.field] }}
              }
              @if (col.display.type === 'chip') {
                <div class="text-center">
                  <p-chip
                    [label]="device[col.field]"
                    [styleClass]="
                      getColor(col.display.colors, device[col.field])
                        ? 'p-chip-' +
                          getColor(col.display.colors, device[col.field]) +
                          ' block'
                        : 'block'
                    "></p-chip>
                </div>
              }
              @if (col.display.type === 'chipMetric') {
                <div class="text-center">
                  <p-chip
                    [label]="device[col.field]"
                    [styleClass]="
                      'p-chip-' + getColorMetric(device[col.field]) + ' block'
                    "></p-chip>
                </div>
              }
            </ng-template>
          </td>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="totalSelectedColumns">No results were found.</td>
      </tr>
    </ng-template>
  </p-table>
}
@if (loading) {
  <loading-spinner />
}
