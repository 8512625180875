@if (!loading && domainInfo) {
  <domain-admin-view
    (domainAdminEvent)="handleDomainAdminEvent($event)"
    [domainName]="domainInfo.name"
    [company]="domainInfo.company"
    [domainSysId]="domainSysId"
    [domainStatus]="domainStatus$ | async"
    [recordsSource]="recordsSource"></domain-admin-view>
}
@if (!loading) {
  <div class="flex flex-column pt-3">
    @if (!loading) {
      <div
        class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200">
        <div class="px-3">
          <h1 class="text-xl">DNS List</h1>
        </div>
      </div>
    }
    @if (!loading) {
      <div
        class="flex-grow-1 overflow-auto mx-3 mb-3 pb-3 overflow-hidden bg-white border-solid border-1 border-gray-200 border-top-none px-3">
        @if (!changeInProgress) {
          <p-messages
            [(value)]="messages"
            [enableService]="false"
            [closable]="false"
            [escape]="false" />
        }
        @if (changeInProgress) {
          <p-messages severity="warn">
            <ng-template pTemplate>
              <div>
                The domain is changing. Please
                <a
                  class="font-bold cursor-pointer underline"
                  (click)="loadDomain()"
                  >click here</a
                >
                to refresh the page.
              </div>
            </ng-template>
          </p-messages>
        }
        @if (canView) {
          <p-table
            #dt1
            [value]="domainRecords$ | async"
            [columns]="tableColumns"
            styleClass="p-datatable-striped"
            [rowHover]="true"
            sortMode="multiple"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} domains"
            [loading]="loading"
            [globalFilterFields]="['name', 'value', 'type']">
            <ng-template pTemplate="caption">
              @if (recordsSource) {
                <div class="flex justify-content-end align-content-center">
                  <div class="flex-grow-1">
                    @if (tableColumns && dt1) {
                      <prime-filter-dropdown
                        [selectedColumns]="cols"
                        [table]="dt1"></prime-filter-dropdown>
                      <p-button
                        label="Reset"
                        styleClass="p-button p-button-text p-button-secondary"
                        icon="pi pi-eraser"
                        (click)="reset(dt1, false)"></p-button>
                    }
                  </div>
                  <p-button
                    (onClick)="addEditRecord()"
                    icon="pi pi-plus"
                    iconPos="left"
                    label="Add Record">
                  </p-button>
                  <p-button
                    label="Export"
                    icon="pi pi-download"
                    iconPos="left"
                    (onClick)="defaultExport()"
                    styleClass="p-button"
                    appendTo="body"></p-button>
                  <span class="p-input-icon-left ml-1">
                    <i class="pi pi-search"></i>
                    <input
                      pInputText
                      type="text"
                      (input)="
                        dt1.filterGlobal($event.target.value, 'contains')
                      "
                      placeholder="Search..." />
                  </span>
                </div>
              }
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                @for (col of columns; track col) {
                  <th
                    class="text-white bg-gray-800 p-datatable-header-first-row"
                    [pSortableColumn]="col.field">
                    {{ col.header }}
                    @if (col.showSortIcon) {
                      <p-sortIcon
                        class="text-white"
                        [field]="col.field"></p-sortIcon>
                    }
                  </th>
                }
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-domainRecord>
              <tr>
                <td>
                  {{ domainRecord?.name }}
                  @if (isDsAdmin) {
                    <br />
                  }
                </td>
                <td>{{ domainRecord?.ttl }}</td>
                <td>{{ domainRecord?.type }}</td>
                <td>
                  @for (value of domainRecord.value; track value) {
                    {{ value }}<br />
                  }
                </td>
                <td>
                  @if (domainRecord.canEdit) {
                    <p-button
                      (onClick)="addEditRecord(domainRecord)"
                      label="Edit"></p-button>
                  }
                </td>
                <td>
                  @if (domainRecord.canDelete) {
                    <i
                      (click)="deleteRecord(domainRecord)"
                      class="pi pi-trash transition-color text-black hover:text-pink-500 cursor-pointer"></i>
                  }
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td class="text-center" [attr.colspan]="tableColumns.length">
                  No results were found.
                </td>
              </tr>
            </ng-template>
          </p-table>
        }
      </div>
    }
  </div>
}
@if (loading) {
  <loading-spinner></loading-spinner>
}
