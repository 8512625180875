<div class="flex flex-column h-full">
  <h1 class="text-xl px-3">Add Attachment</h1>
  <hr class="w-full" />
</div>
<div class="px-3">
  <p>
    Use the box below to upload additional files, then Confirm Upload to save.
    (Recommended file formats: JPEG, GIF, PNG, BMP, TIFF, CSV, XLSX, DOCX)
  </p>
  <div class="pb-5">
    <p-fileUpload
      name="attachment[]"
      [maxFileSize]="maxFileSizeBytes"
      customUpload="true"
      multiple="true"
      accept=".jpeg,.jpg,.gif,.png,.bmp,.tiff,.csv,.xlsx,.docx,.pdf,.txt,.rtf,.doc,.xls"
      (uploadHandler)="onUpload($event)"></p-fileUpload>
  </div>
</div>
<div class="flex align-items-center justify-content-center pb-2">
  <p-button
    styleClass="p-button-secondary"
    (onClick)="closeDialog()"
    label="Cancel">
  </p-button>
</div>
