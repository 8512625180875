<button
  class="flex align-items-center justify-content-center p-1 border-none bg-transparent"
  (click)="op.toggle($event)"
  (keydown.enter)="op.toggle($event)">
  <i
    pBadge
    class="pi pi-bell text-primary text-2xl cursor-pointer"
    severity="success"
    [value]="notificationCount"></i>
</button>
<p-overlayPanel #op styleClass="notifications-overlay-panel">
  <div class="w-22rem">
    @if (notificationCount > 0) {
      <div
        class="flex justify-content-end align-items-center p-2 border-solid border-gray-200 border-none border-bottom-1">
        <p-button
          (onClick)="markAllAsRead()"
          label="Mark All Read"
          [link]="true"></p-button>
      </div>
      @for (notification of notifications; track notification) {
        <div
          (click)="openNotification(notification)"
          (keydown.enter)="openNotification(notification)"
          (keydown.space)="openNotification(notification)"
          tabindex="0"
          role="button"
          class="cursor-pointer hover:bg-gray-200 p-3 flex align-items-center">
          @if (!notification?.uNotificationRead) {
            <div class="w-2rem">
              <i class="pi pi-circle-fill text-primary"></i>
            </div>
          }
          <div>
            <p class="m-0">
              {{ notification?.lastUpdated }} {{ notification?.category }}
            </p>
            <p class="font-bold m-0">{{ notification?.number }}</p>
          </div>
        </div>
      }
    }
    @if (notificationCount === 0) {
      <div class="text-center text-gray-500 text-lg px-2 py-3">
        You have no unread notifications
      </div>
    }
    <div
      class="flex align-content-center justify-content-center border-solid border-gray-200 border-none border-top-1 p-3">
      <p-button
        [link]="true"
        (onClick)="viewAllNotifications()"
        label="View All"></p-button>
    </div>
  </div>
</p-overlayPanel>
