<p-button
  class="mr-1"
  styleClass="p-filterbutton"
  (click)="fo.toggle($event)"
  badge="{{ filterKeys.length }}"
  label="Filters"
  iconPos="left"
  icon="pi pi-filter-slash">
</p-button>
<p-overlayPanel #fo styleClass="w-30rem">
  <div class="flex justify-content-between align-items-center">
    <div class="text-xl font-bold">Filter list</div>
    <div>
      @if (filterKeys.length > 0) {
        <p-button
          label="Clear"
          styleClass="p-button p-button-text p-button-secondary"
          icon="pi pi-filter-slash"
          (click)="clearFilters(table)"
        ></p-button>
      }
      @if (filterKeys.length > 0) {
        <p-button
          styleClass="p-button"
          label="Edit"
          icon="pi pi-pencil"
        (click)="editFilters()"></p-button>
      }
    </div>
  </div>
  <hr class="w-full" />
  @if (filterKeys.length === 0) {
    <div class="mb-3">
      You don't have any filters yet.
    </div>
  }
  @for (field of filterKeys; track field) {
    <div class="pb-3">
      <strong>{{ filters[field].column.header | titlecase }}</strong>
      {{ filters[field].matchMode | humanise | lowercase }}
      @if (
        isArray(filters[field]?.value) &&
        filters[field]?.column?.display?.type !== 'date'
        ) {
        @for (value of filters[field]?.value; track value) {
          <p-tag
            class="cursor-pointer mr-2"
            styleClass="mb-2"
            (click)="removeMultiSelect(table, field, value)">
            {{ value }}
            <i class="pi pi-trash ml-2"></i>
          </p-tag>
        }
      }
      @if (
        isArray(filters[field]?.value) &&
        filters[field]?.column?.display?.type === 'date'
        ) {
        @for (value of filters[field]?.value; track value) {
          <p-tag
            class="cursor-pointer mr-2"
            styleClass="mb-2"
            (click)="removeMultiSelect(table, field, value)">
            {{ value | date: 'dd/MM/yyyy' }}
            <i class="pi pi-trash ml-2"></i>
          </p-tag>
        }
      }
      @if (filters[field]?.column?.display?.type === 'date') {
        @if (!isArray(filters[field]?.value)) {
          <p-tag
            class="cursor-pointer mr-2"
            styleClass="mb-2"
            (click)="removeSingleFilter(table, field)">
            {{ filters[field]?.value | date: 'dd/MM/yyyy' }}
            <i class="pi pi-trash ml-2"></i>
          </p-tag>
        }
      }
      @if (
        filters[field]?.column?.display?.type !== 'date' &&
        filters[field]?.column?.filter?.type !== 'dropdown'
        ) {
        @if (!isArray(filters[field]?.value)) {
          <p-tag
            class="cursor-pointer mr-2"
            styleClass="mb-2"
            (click)="removeSingleFilter(table, field)">
            {{ filters[field]?.value }}
            <i class="pi pi-trash ml-2"></i>
          </p-tag>
        }
      }
      @if (
        filters[field]?.column?.display?.type !== 'date' &&
        filters[field]?.column?.filter?.type === 'dropdown'
        ) {
        @if (!isArray(filters[field]?.value)) {
          <p-tag
            class="cursor-pointer mr-2"
            styleClass="mb-2"
            (click)="removeSingleFilter(table, field)">
            {{
            getFilterLabel(filters[field]?.column?.filter, filters[field]?.value)
            }}
            <i class="pi pi-trash ml-2"></i>
          </p-tag>
        }
      }
    </div>
  }
  @if (filterKeys.length === 0) {
    <div
      class="flex justify-content-center align-content-center">
      <p-button
        label="Add Filter"
        styleClass="p-button-outlined"
        icon="pi pi-plus"
      (click)="openFilters()"></p-button>
    </div>
  }
</p-overlayPanel>
