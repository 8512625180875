import { Table } from 'primeng/table';
import { FilterMetadata, LazyLoadEvent } from 'primeng/api';
import { TableColumn } from '../../../models/table';
import {
  ServiceNowFilterQuery,
  ServiceNowFilterQueryColumn,
  ServiceNowFilterQueryOrderBy,
  ServiceNowFilterQueryOrderByColumn,
} from 'src/app/models/servicenow.model';
import { DateTime } from 'luxon';
import { IsFilterMetadata } from '../../table.helper';

export const BuildServiceNowQuery = (
  table?: Table | null,
  columns?: TableColumn[] | null,
  items?: number,
  page?: number,
  event?: LazyLoadEvent,
  additonalQueryFields: { [key: string]: any } = {},
  presetOrderBy: ServiceNowFilterQueryOrderBy[] = []
): ServiceNowFilterQuery => {
  let snowQueryFilter: ServiceNowFilterQuery = {
    ...(items !== undefined &&
      items > -1 && {
        Amount: items,
      }),
    ...(page !== undefined &&
      page > -1 && {
        CurrentPage: page,
      }),
    ...additonalQueryFields,
    Filter: presetOrderBy,
  };
  if (table) {
    for (const field in table.filters) {
      const tableFilter = table.filters[field];
      const column: TableColumn = columns.find(
        column => column.field.toLowerCase() === field.toLowerCase()
      );
      if (!column || !IsFilterMetadata(tableFilter)) {
        continue;
      }
      // TODO check if this breaks on other tables as FilterMetadata can also be an array
      let value = GetFilterValue(tableFilter);
      if (!value || !value?.length) {
        continue;
      }

      const matchMode = MapToServiceNowFilter(tableFilter.matchMode);
      value[0] = matchMode + value[0];

      const filter: ServiceNowFilterQueryColumn = {
        Column: column.serviceNowField,
        Value: value,
      };
      snowQueryFilter.Filter.push(filter);
    }
    snowQueryFilter = HandleSort(snowQueryFilter, table, event, columns);
  }
  return snowQueryFilter;
};

const GetFilterValue = (filter: FilterMetadata): any[] => {
  if (Array.isArray(filter?.value)) {
    return filter?.value.map(value => {
      if (isDate(value)) {
        return DateTime.fromJSDate(value).toFormat('dd/MM/yyyy');
      }
      return value;
    });
  }
  if (filter?.value || filter?.value === false) {
    if (isDate(filter?.value)) {
      return [DateTime.fromJSDate(filter?.value).toFormat('dd/MM/yyyy')];
    }
    return [filter?.value];
  }
  return null;
};

const isDate = (value: any): value is Date => {
  return value instanceof Date;
};

const HandleSort = (
  snowQueryFilter: ServiceNowFilterQuery,
  table: Table,
  event: LazyLoadEvent,
  columns: TableColumn[]
) => {
  const [asc, desc] = SetSort(snowQueryFilter.Filter);
  if (table.sortMode === 'single') {
    let sort;
    if (event?.sortField && event?.sortOrder) {
      sort = {
        field: event?.sortField,
        order: event?.sortOrder,
      };
    }
    if (table?._sortField && table?._sortOrder) {
      sort = {
        field: table?._sortField,
        order: table?._sortOrder,
      };
    }
    if (sort?.field) {
      const column: TableColumn = columns.find(
        column => column.field.toLowerCase() === sort.field.toLowerCase()
      );
      if (column && sort.order === 1) {
        asc.Value.push(column.serviceNowField);
      }
      if (column && sort.order === -1) {
        desc.Value.push(column.serviceNowField);
      }
    }
  }
  // TODO handle sort multiple
  // if (table.sortMode === "multiple" && context?.multiSortMeta) {
  //   for (const multiSortMetaItem of context?.multiSortMeta) {
  //     const column: TableColumn = columns.find(
  //       (column) =>
  //         column.field.toLowerCase() === multiSortMetaItem.field.toLowerCase()
  //     );
  //     if (column && multiSortMetaItem.order === 1) {
  //       asc.Value.push(column.serviceNowField);
  //     }
  //     if (column && multiSortMetaItem.order === -1) {
  //       desc.Value.push(column.serviceNowField);
  //     }
  //   }
  // }
  if (desc.Value.length > 0) {
    snowQueryFilter.Filter.unshift(desc);
  }
  if (asc.Value.length > 0) {
    snowQueryFilter.Filter.unshift(asc);
  }
  return snowQueryFilter;
};

const SetSort = filters => {
  let desc: ServiceNowFilterQueryOrderBy = filters.find(
    item => item.Column === ServiceNowFilterQueryOrderByColumn.orderByDesc
  ) as ServiceNowFilterQueryOrderBy;
  if (desc) {
    desc.Value = [];
  }
  if (!desc) {
    desc = {
      Column: ServiceNowFilterQueryOrderByColumn.orderByDesc,
      Value: [],
    };
  }
  let asc: ServiceNowFilterQueryOrderBy = filters.find(
    item => item.Column === ServiceNowFilterQueryOrderByColumn.orderByAsc
  ) as ServiceNowFilterQueryOrderBy;
  if (asc) {
    asc.Value = [];
  }
  if (!asc) {
    asc = {
      Column: ServiceNowFilterQueryOrderByColumn.orderByAsc,
      Value: [],
    };
  }
  return [asc, desc];
};

const MapToServiceNowFilter = (matchMode: string): string => {
  const matchModeLower = matchMode.toLowerCase();
  if (matchModeLower === 'contains') return 'LIKE';
  if (matchModeLower === 'startswith') return 'STARTSWITH';
  if (matchModeLower === 'notcontains') return 'NOT LIKE';
  if (matchModeLower === 'endswith') return 'ENDSWITH';
  if (matchModeLower === 'equals') return '=';
  if (matchModeLower === 'notequals') return '!=';

  return matchMode;
};
