import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RoleManagementService } from '../../../roles/shared/role-management.service';
import { ISelectedRole } from '../../../roles/shared/role-models';
import { RoleInformationDialogComponent } from 'src/app/components/misc/pop-up/role-information/role-information.component';
import { FormGroup } from '@angular/forms';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-add-edit-user-role',
  templateUrl: './add-edit-user-role.component.html',
  styleUrls: ['./add-edit-user-role.component.scss'],
  providers: [DialogService],
})
export class AddEditUserRoleComponent implements OnInit {
  @Input() addEditUserRoleForm: FormGroup;
  @Input() roles: any[] = [];
  @Output() childIsLoading = new EventEmitter();
  loading: boolean = true;
  ref: DynamicDialogRef | undefined;

  constructor(
    private roleManagementService: RoleManagementService,
    public dialogService: DialogService
  ) {}

  ngOnInit(): void {
    const selectRoleRole = this.addEditUserRoleForm.get('role').value;
    const selectRoleRoleSysId = selectRoleRole?.sysId ?? null;
    const selectedRole = this.roles.find(
      role => role.sysId === selectRoleRoleSysId
    );
    this.addEditUserRoleForm.get('role').setValue(selectedRole);
  }

  openRoleInfo(event: MouseEvent, roleInfo: ISelectedRole) {
    this.roleManagementService.selectedRole = roleInfo;
    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    this.ref = this.dialogService.open(RoleInformationDialogComponent, {
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      showHeader: false,
      width: '80%',
      data: {
        record: roleInfo,
      },
      styleClass: 'p-dialog-custom',
    });
  }
}
