<div [formGroup]="formGroup">
  <ng-container
    [ngTemplateOutlet]="getTemplate()"
    [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
  <ng-template #containerTemplate let-item>
    <div class="container">
      @if (item.displayTitle) {
        <h3>{{ item.title }}</h3>
      }
      @for (child of item.children; track child) {
        <app-service-catalog-item [item]="child" [formGroup]="formGroup">
        </app-service-catalog-item>
      }
    </div>
  </ng-template>

  <ng-template #questionTextHelpTextTemplate let-item>
    @if (item?.displayQuestionText) {
      <div class="mb-2">
        <label class="text-lg font-bold" [for]="item.name">
          @if (item?.mandatory) {
            <span class="text-primary text-lg">*</span>
          }
          {{ item?.questionText }}
        </label>
      </div>
    }
    @if (item.showHelp) {
      <p class="m-0 mt-2 mb-2 text-sm text-700">{{ item?.helpText }}</p>
    }
  </ng-template>

  <ng-template #errorMessageTemplate let-item>
    @if (formGroup.get(item.name).touched && formGroup.get(item.name).invalid) {
      <small class="p-error">{{ getErrorMessage(item) }}</small>
    }
  </ng-template>

  <ng-template #emailInputTemplate let-item>
    <div class="mb-2">
      <ng-container
        [ngTemplateOutlet]="questionTextHelpTextTemplate"
        [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
      <input
        pInputText
        type="email"
        [id]="item.name"
        [formControlName]="item.name" />
      <ng-container
        [ngTemplateOutlet]="errorMessageTemplate"
        [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
    </div>
  </ng-template>

  <ng-template #radioButtonTemplate let-item>
    <div class="mb-2">
      <ng-container
        [ngTemplateOutlet]="questionTextHelpTextTemplate"
        [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>

      <div class="field col flex gap-3 justify-content-left">
        <div class="flex items-center">
          @for (choice of item.choices; track choice) {
            <p-radioButton
              [inputId]="'radio-' + choice.value"
              [value]="choice.value"
              [formControlName]="item.name" />
            <label [for]="'radio-' + choice.value" class="ml-2 mr-3">
              {{ choice.label }}
            </label>
          }
        </div>
      </div>

      <ng-container
        [ngTemplateOutlet]="errorMessageTemplate"
        [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
    </div>
  </ng-template>

  <ng-template #selectTemplate let-item>
    <div class="mb-2">
      <ng-container
        [ngTemplateOutlet]="questionTextHelpTextTemplate"
        [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
      <p-dropdown
        [id]="item?.name"
        [options]="item?.choices"
        [formControlName]="item?.name"
        placeholder="Select"
        styleClass="w-full" />
      <ng-container
        [ngTemplateOutlet]="errorMessageTemplate"
        [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
    </div>
  </ng-template>

  <ng-template #textInputTemplate let-item>
    <div class="mb-2">
      <ng-container
        [ngTemplateOutlet]="questionTextHelpTextTemplate"
        [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
      <input
        pInputText
        placeholder="{{ item?.questionText }}"
        type="text"
        [id]="item?.name"
        [formControlName]="item?.name"
        class="w-full" />
      <ng-container
        [ngTemplateOutlet]="errorMessageTemplate"
        [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
    </div>
  </ng-template>

  <ng-template #numberInputTemplate let-item>
    <div class="mb-2">
      <ng-container
        [ngTemplateOutlet]="questionTextHelpTextTemplate"
        [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
      <input
        pInputText
        type="number"
        [id]="item?.name"
        [formControlName]="item?.name"
        class="w-full" />
      <ng-container
        [ngTemplateOutlet]="errorMessageTemplate"
        [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
    </div>
  </ng-template>

  <ng-template #tableInputTemplate let-item>
    <div class="mb-2">
      <h3 class="m-0">
        @if (item?.mandatory) {
          <span class="text-primary text-lg">*</span>
        }
        {{ item?.title }}
      </h3>
      @if (item?.description) {
        <p class="m-0 mt-2 mb-2 text-sm text-700">{{ item?.description }}</p>
      }
      <p-table
        #dataTable
        class="p-datatable-equal-width"
        styleClass="p-datatable-striped"
        [value]="formGroup.get(item.internalName).controls">
        <ng-template pTemplate="header">
          <tr>
            @for (child of item.children; track child) {
              <th class="text-white bg-gray-800 p-datatable-header-first-row">
                {{ child?.questionText }}
              </th>
            }
            <th>Remove</th>
          </tr>
        </ng-template>
        <ng-template
          pTemplate="body"
          let-form
          let-editing="editing"
          let-rowIndex="rowIndex">
          <tr>
            @for (child of item.children; track child) {
              <td>
                <app-service-catalog-item
                  [item]="setItemField(child, 'displayQuestionText', false)"
                  [formGroup]="formGroup.get(item?.internalName).at(rowIndex)">
                </app-service-catalog-item>
              </td>
            }
            <td>
              @if (formGroup.get(item.internalName).length > 1) {
                <i
                  class="pi pi-times text-xl hover:text-primary cursor-pointer pt-2"
                  (keyup.enter)="
                    deleteUser(formGroup.get(item?.internalName), rowIndex)
                  "
                  role="button"
                  tabindex="0"
                  (click)="
                    deleteUser(formGroup.get(item?.internalName), rowIndex)
                  "></i>
              }
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div class="pt-4">
        <p-button
          [disabled]="checkLimit(item)"
          icon="pi pi-plus"
          [label]="'Add'"
          (click)="addRow(formGroup.get(item?.internalName))"></p-button>
      </div>
    </div>
  </ng-template>

  <ng-template #dateTimeTemplate let-item>
    <div class="mb-2">
      <ng-container
        [ngTemplateOutlet]="questionTextHelpTextTemplate"
        [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
      <p-calendar
        [minDate]="today"
        [showIcon]="true"
        [formControlName]="item?.name"
        [showTime]="true"
        [hourFormat]="24"
        [readonlyInput]="true"
        dateFormat="dd/mm/yy"
        styleClass="w-full"
        placeholder="Select" />
      <ng-container
        [ngTemplateOutlet]="errorMessageTemplate"
        [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
    </div>
  </ng-template>

  <ng-template #dateTemplate let-item>
    <div class="mb-2">
      <ng-container
        [ngTemplateOutlet]="questionTextHelpTextTemplate"
        [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
      <p-calendar
        [minDate]="today"
        [showIcon]="true"
        [formControlName]="item?.name"
        [showTime]="false"
        [readonlyInput]="true"
        dateFormat="dd/mm/yy"
        styleClass="w-full"
        placeholder="Select" />
      <ng-container
        [ngTemplateOutlet]="errorMessageTemplate"
        [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
    </div>
  </ng-template>

  <ng-template #textAreaTemplate let-item>
    <div class="mb-2">
      <ng-container
        [ngTemplateOutlet]="questionTextHelpTextTemplate"
        [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
      <textarea
        placeholder="{{ item?.questionText }}"
        pInputText
        [formControlName]="item?.name"
        type="text"
        rows="3"
        class="w-full"></textarea>
      <ng-container
        [ngTemplateOutlet]="errorMessageTemplate"
        [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
    </div>
  </ng-template>
</div>
