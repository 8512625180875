<div class="flex justify-content-center">
  <div class="max-w-48rem flex-grow-1">
    <div class="flex flex-column h-full">
      <div
        class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200">
        <h1 class="px-3 text-xl">Change Password</h1>
      </div>
      <div class="flex-grow-1 mx-3 bg-white border-solid border-1 border-gray-200 border-top-none p-3">
        <p class="text-xl m-0 mb-4">
          Your new password needs to have, one uppercase character,
          at least 1 symbol, one number and at least 8 characters long.
        </p>
        <form [formGroup]="profileFormControl">
          <div class="field">
            <label class="text-lg" for="currentPassword">
              <span class="text-primary p-0 text-lg">*</span>Current Password
            </label>
            <p-password
              styleClass="w-full"
              inputStyleClass="w-full"
              formControlName="currentPassword"
            [feedback]="false"></p-password>
            @if (
              profileFormControl.get('currentPassword').touched &&
              profileFormControl.controls['currentPassword'].hasError(
              'required'
              )
              ) {
              <small
                class="p-error text-base"
                >
                Current Password is required
              </small>
            }
            @if (profileFormControl.get('currentPassword').untouched) {
              <small
                class="p-error text-base"
                >
                {{ currentPasswordError }}
              </small>
            }
          </div>
          <div class="field">
            <label for="newPassword" class="text-lg">
              <span class="text-primary p-0 text-lg">*</span>New Password</label
              >
              <p-password
                styleClass="w-full"
                inputStyleClass="w-full"
                formControlName="password"
                [feedback]="true"
            [ngClass]="{
              'ng-invalid ng-dirty':
                profileFormControl.hasError('currentSame'),
            }"
                ><ng-template pTemplate="footer">
                <p-divider></p-divider>
                <p class="mt-2">Requirements</p>
                <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                  <li>At least one lowercase</li>
                  <li>At least one uppercase</li>
                  <li>At least one numeric</li>
                  <li>At least one special character</li>
                  <li>Minimum 8 characters</li>
                </ul>
              </ng-template>
            </p-password>
            @if (
              profileFormControl.get('password').touched &&
              profileFormControl.get('password').hasError('notSame')
              ) {
              <small
                class="p-error text-base"
                >
                New password can't be the same as old.
              </small>
            }
          </div>
          <div class="field">
            <label for="confirmPassword" class="text-lg">
              <span class="text-primary p-0 text-lg">*</span>Confirm New
              Password</label
              >
              <p-password
                styleClass="w-full"
                inputStyleClass="w-full"
                formControlName="confirmPassword"
            [ngClass]="{
              'ng-invalid ng-dirty': profileFormControl
                .get('confirmPassword')
                .hasError('notMatch'),
            }"
                ><ng-template pTemplate="footer">
                <p-divider></p-divider>
                <p class="mt-2">Requirements</p>
                <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                  <li>At least one lowercase</li>
                  <li>At least one uppercase</li>
                  <li>At least one numeric</li>
                  <li>At least one special character</li>
                  <li>Minimum 8 characters</li>
                </ul>
              </ng-template>
            </p-password>
            @if (
              profileFormControl.get('confirmPassword').touched &&
              profileFormControl.get('confirmPassword').hasError('notMatch')
              ) {
              <small
                class="p-error text-base"
                >
                Passwords do not match.
              </small>
            }
          </div>
        </form>
        <div class="flex align-items-center justify-content-center">
          <p-button
            label="Submit"
            [disabled]="changePasswordDisabled()"
            (onClick)="changePassword()">
          </p-button>
        </div>
      </div>
    </div>
  </div>
</div>