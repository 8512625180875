import { Injectable } from '@angular/core';
import * as tldts from 'tldts';

@Injectable({ providedIn: 'root' })
export class DomainHelpersService {
  ttlConverter(ttl: number): string {
    switch (ttl) {
      case 300:
        return '5m';
      case 900:
        return '15m';
      case 3600:
        return '1h';
      case 14400:
        return '4h';
      case 43200:
        return '12h';
      case 86400:
        return '1d';
      case 259200:
        return '3d';
      case 604800:
        return '1w';
      default:
        return '5m';
    }
  }

  convertType = (type: string) => {
    switch (type) {
      case 'A':
        return 'IN A';
      case 'AAAA':
        return 'IN AAAA';
      case 'NS':
        return 'IN NS';
      case 'CNAME':
        return 'IN CNAME';
      case 'MX':
        return 'IN MX';
      case 'TXT':
        return 'IN TXT';
      case 'SRV':
        return 'IN SRV';
      case 'NAPTR':
        return 'IN NAPTR';
      default:
        return type as '';
    }
  };

  parseSubdomain(domain): string {
    return tldts.parse(domain).subdomain;
  }

  parseDomain(domain): string {
    return tldts.parse(domain).domain;
  }
}
