import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
//import { AccountService } from 'src/app/components/service-hub/account/shared/services/account.service';
@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  user: any;
  constructor(private authService: AuthService) {
    this.getUser();
  }

  async getUser() {
    this.user = await this.authService.getUser();
  }

  /**
   * @deprecated This method is deprecated in favor of using the authService function hasPermission.
   * Checks if the current user has the specified permission.
   *
   * @param {string} permission - The permission to check.
   * @returns {boolean} - True if the user has the permission, otherwise false.
   */
  checkPermission(permission: string): boolean {
    if (this.authService.isImpersonating()) {
      return this.authService
        .getImpersonatedUser()
        .uTimicoPortalPermissions.includes(permission);
    } else {
      return this.user.uTimicoPortalPermissions.includes(permission);
    }
  }

  digitalSpaceID(): boolean {
    // TODO change to check for digital space email?
    if (this.authService.isImpersonating()) {
      return (
        this.authService.getImpersonatedUser().companyId ===
        '29d3545cdb474740bdd1708dae961933'
      );
    } else {
      return this.user.companyId === '29d3545cdb474740bdd1708dae961933';
    }
  }
}
