@if (loading) {
  <loading-spinner />
}
@if (!loading) {
  <div class="grid my-0 mx-3 h-full">
    <div class="col-12 lg:col-4 flex flex-column h-full m-0">
      <div
        class="flex justify-content-between align-items-center bg-white py-3 border-solid border-1 border-gray-200">
        <h1 class="px-3 text-xl">Profile</h1>
        @if (checkPermission('PROFILE_PERSONAL_WRITE')) {
          <p-button
            icon="pi pi-pencil"
            label="Edit"
            (onClick)="openUserDetailsDialog()"
            class="mr-2"></p-button>
        }
      </div>
      <div
        class="pb-3 bg-white border-solid border-1 border-gray-200 border-top-none">
        <div class="mx-2 bg-white flex flex-column">
          <app-ticket-item-display
            [ngClass]="
              getTicketItemDisplayContainerClasses({
                border: false,
              })
            "
            [label]="'First Name'"
            [value]="profile?.firstName" />
          <app-ticket-item-display
            [ngClass]="
              getTicketItemDisplayContainerClasses({
                border: true,
              })
            "
            [label]="'Last Name'"
            [value]="profile?.lastName" />
          <app-ticket-item-display
            [ngClass]="
              getTicketItemDisplayContainerClasses({
                border: true,
              })
            "
            [label]="'Email'"
            [value]="profile?.email" />
          <app-ticket-item-display
            [ngClass]="
              getTicketItemDisplayContainerClasses({
                border: true,
              })
            "
            [label]="'Mobile Phone'"
            [value]="profile?.mobilePhone" />
          <app-ticket-item-display
            [ngClass]="
              getTicketItemDisplayContainerClasses({
                border: true,
                borderBottom: true,
              })
            "
            [label]="'Business Phone'"
            [value]="profile?.phone || '&zwnj;'" />
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-8 h-full flex flex-column m-0">
      <div
        class="flex justify-content-between align-items-center bg-white py-3 border-solid border-1 border-gray-200">
        <h1 class="px-3 text-xl">My Activity</h1>
      </div>
      <div
        class="flex-grow-1 overflow-hidden bg-white px-3 border-solid border-1 border-gray-200 border-top-none">
        <div class="h-full flex flex-column">
          <p-tabMenu
            [model]="menuItems"
            [activeItem]="activeMenuItem"
            (activeItemChange)="changeTab($event)">
          </p-tabMenu>
          @if (activeMenuItem.label === 'Login History') {
            <login-history></login-history>
          }
          @if (activeMenuItem.label === 'Browsing History') {
            <user-crud-activity></user-crud-activity>
          }
        </div>
      </div>
    </div>
  </div>
}
