<div class="flex align-content-center justify-content-center">
  <p class="text-xl">Type of PAC</p>
</div>

<form [formGroup]="serviceRequestForm">
  <div class="card flex justify-content-center">
    <div class="flex flex-wrap gap-3">
      @for (category of pacCategories; track category) {
        <div class="field-checkbox">
          <p-radioButton
            [inputId]="category"
            [value]="category"
            [(ngModel)]="selectedPacCategory"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="pacInputSetValidators()" />
          <label [for]="category.name" class="ml-2 text-xl">
            {{ category.name }}
          </label>
        </div>
      }
    </div>
  </div>
  <div class="flex align-items-center justify-content-center">
    <div class="formgrid w-7">
      <div class="field col">
        <div class="pb-1">
          <span>
            <span class="text-primary text-lg">*</span>
            <label for="description" class="text-lg">Mobile Number(s)</label>
          </span>
        </div>
        <textarea
          pInputText
          formControlName="mobileNumber"
          placeholder="Mobile Number"
          aria-label="Description"
          type="text"
          rows="1"
          class="w-full"
          style="resize: none">
        </textarea>
        @if (
          serviceRequestForm.get('mobileNumber')?.hasError('required') &&
          serviceRequestForm.get('mobileNumber')?.touched
        ) {
          <small class="p-error text-base">
            At least one mobile number is required.
          </small>
        }
        @if (
          serviceRequestForm.get('mobileNumber')?.hasError('pattern') &&
          serviceRequestForm.get('mobileNumber')?.touched
        ) {
          <small class="p-error text-base">
            Mobile number must start with 07 and have 11 digits.
          </small>
        }
      </div>
    </div>
  </div>
</form>
