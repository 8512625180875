import { TableColumn } from 'src/app/models/table';

export const supportColumns: TableColumn[] = [
  {
    field: 'question',
    header: 'Title',
    default: true,
    display: { type: 'text' },
    showSortIcon: true,
    cell: data => data.uQuestion,
    expand: data => data.uAnswer,
  },
];

export const SupportArticleFilters = [
  {
    Column: 'UQuestion',
    Value: [],
    Type: 'text',
    Options: [],
    Visibility: true,
  },
];
