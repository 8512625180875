import { Inject, Injectable } from '@angular/core';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private apiRequest: ApiRequest) {}

  downloadExport() {
    const requestProps: IRequestOptions = {
      endpoint: 'Files/DownloadExport',
    };
    return this.apiRequest.request(requestProps);
  }

  uploadFileToServiceNow(request: any) {
    const requestProps: IRequestOptions = {
      endpoint: 'Files/UploadFileToServiceNow',
      body: request,
    };
    return this.apiRequest.request(requestProps);
  }

  downloadFile(sysId: string) {
    const requestProps: IRequestOptions = {
      endpoint: 'Files/DownloadFile',
      body: sysId,
    };
    return this.apiRequest.request(requestProps);
  }

  getThumbnail(sysIds: string[]) {
    const requestProps: IRequestOptions = {
      endpoint: 'Files/GetThumbnail',
      body: sysIds,
    };
    return this.apiRequest.request(requestProps);
  }

  getDownloadPolicy(eventClick: string) {
    const requestProps: IRequestOptions = {
      endpoint: 'Files/DownloadPolicy',
      body: eventClick,
    };
    return this.apiRequest.request(requestProps);
  }

  getServicePoliciesFile() {
    const requestProps: IRequestOptions = {
      endpoint: 'Files/ServicePoliciesFile',
    };
    return this.apiRequest.request(requestProps);
  }

  getFiles(sysId: string) {
    const requestProps: IRequestOptions = {
      endpoint: 'Files/getFiles',
      body: sysId,
    };
    return this.apiRequest.request(requestProps);
  }
}
