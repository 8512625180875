import { Injectable } from '@angular/core';
import {
  ApiRequest,
  IRequestOptions,
} from '../../../helpers/api.request.helper';
import { ServiceNowFilterQuery } from 'src/app/models/servicenow.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private apiRequest: ApiRequest) {}

  getBillingEntities(type: string) {
    const requestProps: IRequestOptions = {
      endpoint: 'Contact/GetContactBillingEntities',
      body: type,
    };
    return this.apiRequest.request(requestProps);
  }

  getCompanyAdmins() {
    const requestProps: IRequestOptions = {
      endpoint: 'users/GetCompanyAdmins',
    };
    return this.apiRequest.request(requestProps);
  }

  getContactBillingEntities(type: string) {
    const requestProps: IRequestOptions = {
      endpoint: 'Contact/getContactBillingEntities',
      body: type,
    };
    return this.apiRequest.request(requestProps);
  }

  getUsers(request: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: 'Users/GetUsers',
      body: request,
    };
    return this.apiRequest.request(requestProps);
  }

  getRolePermissionsWithAdditional(roleId: string) {
    const requestProps: IRequestOptions = {
      endpoint: 'Permissions/GetRolePermissionsWithAdditional',
      body: roleId,
    };
    return this.apiRequest.request(requestProps);
  }

  setContactDetail(profile: any) {
    const requestProps: IRequestOptions = {
      endpoint: 'users/setContactDetails',
      body: profile,
    };
    return this.apiRequest.request(requestProps);
  }

  getLoginHistory(request: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: 'Contact/GetContactLoginHistory',
      body: request,
    };
    return this.apiRequest.request(requestProps);
  }

  getContactDetail(profile: string) {
    const requestProps: IRequestOptions = {
      endpoint: 'Contact/GetContactAccountDetails',
      body: profile,
    };
    return this.apiRequest.request(requestProps);
  }
}
