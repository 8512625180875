import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api/menuitem';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IDialogResult } from 'src/app/models/dialog-models';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import { ToastService, ToastType } from 'src/app/services/global/toast.service';
import { PermissionService } from 'src/app/services/permissions.service';
import { UserDetailsDialogComponent } from '../../misc/pop-up/user-details-dialog/user-details-dialog.component';
import { ICookieSettings, IUserDetails } from './shared/models/account-models';
import { AccountService } from './shared/services/account.service';
import { GetTicketItemDisplayContainerClasses } from 'src/app/helpers/tickets.helper';

@Component({
  selector: 'account',
  templateUrl: 'account.component.html',
  styleUrls: ['account.component.scss'],
  providers: [DialogService],
})
export class AccountComponent implements OnInit {
  loading = false;
  profile: IUserDetails;
  cookieSettings: ICookieSettings = { cookiesStatus: false };
  cookiesStatus = false;
  checked;
  ref: DynamicDialogRef;
  cookieModel = false;
  getTicketItemDisplayContainerClasses = GetTicketItemDisplayContainerClasses;

  selectedTab = 'Login History';
  menuItems: MenuItem[] = [
    { label: 'Login History' },
    { label: 'Browsing History' },
  ];

  activeMenuItem: MenuItem = this.menuItems[0];

  stateOptions = [
    { label: 'On', value: true },
    { label: 'Off', value: false },
  ];

  onTabChanged(selectedTab) {
    this.selectedTab = selectedTab.tab.textLabel;
  }

  constructor(
    private accountService: AccountService,
    private permissionService: PermissionService,
    private breadcrumbService: BreadcrumbService,
    private dialogService: DialogService,
    private toastService: ToastService
  ) {}

  async ngOnInit() {
    const breadcrumbs: MenuItem[] = [
      {
        label: 'Account Settings',
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    await this.loadProfile();
  }

  async loadProfile() {
    this.loading = true;
    this.profile = await this.accountService.loadProfile();
    this.loading = false;
  }

  openUserDetailsDialog() {
    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    this.ref = this.dialogService.open(UserDetailsDialogComponent, {
      showHeader: false,
      data: {
        profile: this.profile,
      },
      styleClass: 'p-dialog-custom',
    });

    this.ref.onClose.subscribe(async (result: IDialogResult<IUserDetails>) => {
      if (result.updateSuccessful) {
        this.profile = result.updatedData;
        this.toastService.add({
          severity: ToastType.success,
          summary: 'Success',
          detail: 'User details updated.',
        });
      }
    });
  }

  checkPermission(permission: string) {
    return this.permissionService.checkPermission(permission);
  }

  changeTab(event: MenuItem) {
    this.activeMenuItem = event;
  }
}
