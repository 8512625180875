@if (!loading) {
  <div class="mx-3">
    <p>Please select a request type.</p>
    <form [formGroup]="serviceNowCatalogForm">
      <div class="field">
        <span class="text-primary text-lg">*</span>
        <label for="category" class="text-lg font-bold">Category</label>
        <p-dropdown
          id="category"
          styleClass="w-full"
          class="w-full"
          placeholder="Select"
          [options]="requestCategories"
          [filter]="true"
          formControlName="category"
          optionLabel="label"
          (onChange)="setSubCategory($event)" />
      </div>
      <div class="field">
        <span class="text-primary text-lg">*</span>
        <label for="Request" class="text-lg font-bold">Request</label>
        <p-dropdown
          formControlName="request"
          [options]="requestSubCategories"
          id="subcategory"
          placeholder="Select"
          styleClass="w-full"
          [filter]="true"
          optionLabel="label"
          class="w-full" />
      </div>
    </form>
    <div class="flex justify-content-center">
      <p-button
        styleClass="p-button-secondary"
        [routerLink]="['/secure/tickets/raise']"
        label="Back"></p-button>
      <p-button
        [disabled]="!serviceNowCatalogForm?.get('request')?.value"
        (onClick)="progressForm()"
        label="Next"></p-button>
    </div>
  </div>
}
@if (loading) {
  <loading-spinner />
}
