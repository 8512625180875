import { TableColumn } from 'src/app/models/table';

export const ChangeRequestColumns: TableColumn[] = [
  {
    field: 'name',
    header: 'Name',
    display: { type: 'text' },
    showSortIcon: false,
    default: true,
  },
  {
    field: 'details',
    header: 'Details',
    display: { type: 'text' },
    showSortIcon: false,
    default: true,
  },
];
