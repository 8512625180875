@if (loading) {
  <loading-spinner />
}

@if (!loading) {
  <div class="flex justify-content-center overflow-hidden flex-grow-1">
    <div class="max-w-100rem flex-grow-1">
      <service-monitor />
    </div>
  </div>
  <div
    class="mx-3 py-3 overflow-hidden border-solid border-1 border-gray-200 border-top-none border-left-none border-right-none border-bottom-none">
    @if (services?.length > 1) {
      <div
        class="flex pt-2 pb-2 flex-column shadow-1 surface-0 border-round-sm border-solid border-1 border-gray-200">
        <p-tabView styleClass="tabs-evenly-spaced" [activeIndex]="tabIndex">
          @for (service of services; track service; let i = $index) {
            <p-tabPanel
              styleClass="tabs-evenly-spaced"
              [header]="service?.businessService?.name">
              <service-information
                [service]="service"
                [tableColumns]="tableColumns" />
            </p-tabPanel>
          }
        </p-tabView>
      </div>
    }
  </div>
}
