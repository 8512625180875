<div
  class="bg-white h-full border-solid border-1 border-top-none border-gray-200">
  <div class="flex flex-column">
    <h2
      class="text-center m-0 py-3 text-xl font-bold border-solid border-1 border-gray-200 border-right-none border-left-none border-bottom-1">
      {{ title }}
    </h2>
    @if (permission) {
      <div class="p-3">
        @if (statistics?.thisMonth.loading || statistics?.lastMonth.loading) {
          <loading-spinner
            class="col"
            [padding]="'0 0 0 0'"
            [displayMessage]="false"></loading-spinner>
        }
        @if (!statistics.thisMonth.loading && !statistics.lastMonth.loading) {
          <div
            class="flex cursor-pointer"
            (click)="statistics?.thisMonth.command()"
            (keyup.enter)="statistics?.thisMonth.command()"
            role="button"
            tabindex="0">
            <div
              class="flex justify-content-between align-items-center mt-1 px-3 w-full">
              <h1 class="text-xl m-0">This Month</h1>
              <div class="flex align-items-center">
                <h1 class="text-5xl m-0">
                  {{ statistics?.thisMonth.value }}
                </h1>
              </div>
            </div>
          </div>
          <div
            class="flex cursor-pointer"
            (click)="statistics?.lastMonth.command()"
            (keyup.enter)="statistics?.lastMonth.command()"
            role="button"
            tabindex="0">
            <div
              class="flex justify-content-between align-items-center mt-1 px-3 w-full">
              <h1 class="text-xl m-0">Last Month</h1>
              <div class="flex align-items-center">
                <h1 class="m-0 text-5xl">
                  {{ statistics?.lastMonth.value }}
                </h1>
              </div>
            </div>
          </div>
        }
      </div>
    } @else {
      <div
        class="h-full flex justify-content-center align-items-center min-h-8rem">
        <p class="text-2xl text-600">No access to view the data.</p>
      </div>
    }
  </div>
</div>
