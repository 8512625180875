<p-table
  #dataTable
  class="p-datatable-equal-width"
  styleClass="p-datatable-striped"
  [value]="log$ | async"
  [lazy]="true"
  (onLazyLoad)="getLogs(dataTable, $event)"
  [rows]="25"
  [columns]="columns"
  sortMode="single"
  [scrollable]="true"
  scrollHeight="flex"
  [rowHover]="true">
  <ng-template pTemplate="caption">
    <div class="flex justify-content-start align-content-center">
      <div class="flex">
        @if (columns && dataTable && dataTable.loading === false) {
          <prime-filter-dropdown
            [selectedColumns]="columns"
            [table]="dataTable"></prime-filter-dropdown>
        }
      </div>
      <p-button
        label="Reset"
        styleClass="p-button p-button-text p-button-secondary"
        icon="pi pi-filter-slash"
        (click)="reset(dataTable, false)"></p-button>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns let-invoice>
    <tr>
      @for (col of columns; track col) {
        <th
          class="text-white bg-gray-800 p-datatable-header-first-row"
          [pSortableColumn]="col.field"
          [pSortableColumnDisabled]="!col.showSortIcon">
          @if (!col.field?.visible && col?.field !== 'checkbox') {
            {{ col.header }}
          }
          @if (col.showSortIcon) {
            <p-sortIcon class="text-white" [field]="col.field"></p-sortIcon>
          }
        </th>
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-crud let-columns="columns">
    <tr>
      @for (col of columns; track col) {
        <td>
          @if (col.display?.type === 'date') {
            {{ crud[col.field] }}
          }
          @if (col.display?.type === 'text') {
            {{ crud[col.field] }}
          }
        </td>
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="footer">
    <tr>
      <td class="bg-white" [attr.colspan]="tableColumn?.length + 1">
        <div class="flex align-items-center justify-content-center">
          <p-paginator
            #tablePaginator
            (onPageChange)="getLogs(dataTable, $event)"
            [first]="dataTable.first"
            [rows]="dataTable.rows"
            [totalRecords]="totalRecords"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            [rowsPerPageOptions]="[25, 50, 100]"></p-paginator>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="columns?.length + 1">No results were found.</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="loadingbody">
    <tr>
      <td [attr.colspan]="columns?.length + 1">
        <loading-spinner></loading-spinner>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="loadingicon"></ng-template>
</p-table>
