<div class="flex flex-column h-full">
  <h1 class="text-xl px-3">Cookies Policy Information</h1>
  <hr class="w-full" />

  <div class="px-3">
    <h2 class="text-xl">Use of Cookies</h2>
    <p class="text-base">
      Cookies are small text files that are placed on your computer by websites
      that you visit. They are widely used to make websites work, or work more
      efficiently, as well as to provide information to the owners of the site.
      The table below explains the cookies we use and why.
    </p>
    <br />
  </div>

  <div class="table-container">
    <table>
      <tbody>
        <tr>
          <td class="heading-one title">
            <p><span>Cookie</span></p>
          </td>
          <td class="heading-two title">
            <p><span>Name</span></p>
          </td>
          <td class="heading-three title">
            <p><span>Purpose</span></p>
          </td>
        </tr>
        <tr>
          <td class="heading-one">
            <p><span>Account Security</span></p>
          </td>
          <td class="heading-two">
            <p><span>account-sec</span></p>
          </td>
          <td class="third-col">
            <p>
              <span
                >This cookie is optional and increases account security by
                identifying the current browser and session. Upon account
                authentication we will ensure that the account is being used by
                a trusted device and if unrecognised, we&rsquo;ll send you an
                email for your reassurance.</span
              >
            </p>
            <p><span>&nbsp;</span></p>
            <p>
              <span
                >This feature/cookie can be disabled by opting out of additional
                cookies via your account.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td class="heading-one">
            <p><span>Account Authentication</span></p>
          </td>
          <td class="heading-two">
            <p><span style="font-size: 16px">token</span></p>
          </td>
          <td class="third-col">
            <p>
              <span>
                This cookie is essential for use of our website, it holds your
                login session and is used to provide/deliver the core
                functionality of our portal.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td class="heading-one">
            <p><span>Local Storage</span></p>
          </td>
          <td class="heading-two">
            <p><span>token</span></p>
            <p><span>fromALBO</span></p>
            <p><span>currentNavigation</span></p>
            <p><span>science-logic-device</span></p>
            <p><span>outage</span></p>
            <p><span>companyToggle</span></p>
            <p><span>user</span></p>
          </td>
          <td class="third-col">
            <p>
              <span
                >This is essential for use of our website, it holds your login
                session and is used to provide/deliver the core functionality of
                our portal.</span
              >
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="align-content-start p-3">
    <div class="manage-content">
      <p class="manage-text">
        Most browsers allow some control of most cookies through the browser
        setting. To find out more about cookies, including how to see what
        cookies have been set, visit
        <a target="_blank" href="http://www.aboutcookies.org"
          >www.aboutcookies.org</a
        >
        or
        <a target="_blank" href="http://www.allaboutcookies.org"
          >www.allaboutcookies.org</a
        >
      </p>
      <br />
      <h3>Find out how to manage cookies on popular browsers:</h3>
      <ul>
        <li>
          <a
            target="_blank"
            href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en"
            >Google Chrome</a
          >
        </li>
        <li>
          <a
            target="_blank"
            href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd"
            >Microsoft Edge</a
          >
        </li>
        <li>
          <a
            target="_blank"
            href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
            >Mozilla Firefox</a
          >
        </li>
        <li>
          <a
            target="_blank"
            href="https://help.opera.com/en/latest/web-preferences/"
            >Opera</a
          >
        </li>
        <li>
          <a target="_blank" href="https://support.apple.com/en-gb/safari"
            >Apple Safari</a
          >
        </li>
      </ul>
      <p class="manage-text">
        To find information relating to other browsers, visit the browser
        developer’s website.
      </p>
      <br />
      <br />
    </div>
  </div>
  <div class="flex justify-content-center align-items-center pt-2 pb-2">
    <p class="text-lg font-bold">
      More cookie usage information, please click
      <a
        class="text-lg font-bold"
        target="_blank"
        href="https://www.digitalspace.co.uk/information/privacy-policy/"
        >here</a
      >
    </p>
  </div>
</div>
