export const GetTicketItemDisplayContainerClasses = (options: {
  [key: string]: boolean | string;
}): {
  [key: string]: boolean | string;
} => {
  const classes = {};
  if (options?.cols !== false) {
    classes[`col-${options?.cols || 12}`] = true;
  }
  if (options?.border) {
    classes['border-none'] = true;
    classes['border-top-1'] = true;
    classes['border-solid'] = true;
    classes['border-gray-200'] = true;
  }
  if (options?.borderBottom) {
    classes['border-bottom-1'] = true;
    classes['border-gray-200'] = true;
  }
  return classes;
};
