<p-accordion>
  <p-accordionTab>
    <ng-template pTemplate="header">
      <h1 class="font-bold white-space-nowrap text-xl m-0 p-0">
        Internal Information
      </h1>
    </ng-template>
    @if (!orderDetails) {
      <loading-spinner></loading-spinner>
    }
    @if (orderDetails) {
      <div class="grid pl-2 gap-1">
        <div
          class="col border-none border-bottom-1 border-solid border-gray-200">
          <label class="text-gray-600 text-lg">Address Reference</label>
          <p class="text-lg m-0">{{ orderDetails?.addressReference }}</p>
        </div>
        <div
          class="col border-none border-bottom-1 border-solid border-gray-200">
          <label class="text-gray-600 text-lg">Opportunity Reference</label>
          <p class="text-lg m-0">{{ orderDetails?.externalId }}</p>
        </div>
        <div
          class="col border-none border-bottom-1 border-solid border-gray-200">
          <label class="text-gray-600 text-lg">Quote Reference</label>
          <p class="text-lg m-0">{{ orderDetails?.quoteId }}</p>
        </div>
      </div>
    }
  </p-accordionTab>
</p-accordion>
