import { Component, Inject, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileManagerService } from 'src/app/components/misc/pop-up/file-manager/file-manager.service';

@Component({
  selector: 'app-view-files',
  templateUrl: './view-files.component.html',
  styleUrls: ['./view-files.component.scss'],
})
export class ViewFilesComponent implements OnInit {
  constructor(
    public fileManagerService: FileManagerService,
    public ref: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.fileManagerService.currentFiles = this.dialogConfig?.data?.files;
  }

  closeDialog() {
    this.ref.close();
  }
}
