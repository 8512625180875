<div class="flex flex-column h-full">
  <div
    class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200">
    <h1 class="px-3 text-xl">{{ context }} Users</h1>
  </div>
  <div
    class="flex-grow-1 overflow-auto mx-3 bg-white border-solid border-1 border-gray-200 border-top-none border-bottom-none px-3">
    <div class="flex justify-content-center">
      <p-steps
        class="w-full max-w-60rem"
        [model]="steps"
        [readonly]="true"
        [activeIndex]="activeStepIndex">
      </p-steps>
    </div>

    @if (!loading && activeStepIndex === 0) {
      <div class="flex-grow-1 overflow-auto mx-3">
        @if (context === PageContext.Add) {
          <div class="field col flex gap-3 justify-content-center">
            <div class="flex align-items-center">
              <p-radioButton
                (onClick)="onSingleUserSelect()"
                name="AddEditUserType"
                value="single"
                inputId="AddEditUserSingle"
                [(ngModel)]="selectedAddEditUserType"></p-radioButton>
              <label for="AddEditUserSingle" class="ml-2">Single User</label>
            </div>
            <div class="flex align-items-center">
              <p-radioButton
                name="AddEditUserType"
                value="multiple"
                inputId="AddEditUserMultiple"
                [(ngModel)]="selectedAddEditUserType"></p-radioButton>
              <label for="AddEditUserMultiple" class="ml-2"
                >Multiple Users</label
              >
            </div>
          </div>
        }
        <form [formGroup]="usersForm">
          <p-table
            styleClass="p-datatable-striped"
            class="p-datatable-equal-width"
            [value]="users.controls">
            <ng-template pTemplate="header">
              <tr>
                <th class="text-white bg-gray-800 p-datatable-header-first-row">
                  First Name
                </th>
                <th class="text-white bg-gray-800 p-datatable-header-first-row">
                  Last Name
                </th>
                <th class="text-white bg-gray-800 p-datatable-header-first-row">
                  Email
                </th>
                <th class="text-white bg-gray-800 p-datatable-header-first-row">
                  Mobile Number
                </th>
                <th class="text-white bg-gray-800 p-datatable-header-first-row">
                  Active
                </th>
                <th class="text-white bg-gray-800 p-datatable-header-first-row">
                  Role
                </th>
                <th class="text-white bg-gray-800 p-datatable-header-first-row">
                  Additional Permissions
                </th>
                <th class="text-white bg-gray-800 p-datatable-header-first-row">
                  Remove
                </th>
              </tr>
            </ng-template>
            <ng-template
              pTemplate="body"
              let-user
              let-editing="editing"
              let-rowIndex="rowIndex">
              <tr [formGroup]="user">
                <td>
                  <input
                    pInputText
                    class="w-full ng-invalid ng-dirty"
                    type="text"
                    formControlName="firstName"
                    [ngClass]="{
                      'ng-invalid ng-dirty': user.get('firstName').invalid,
                    }" />
                  @if (user.get('firstName').invalid) {
                    <small class="p-error">First Name is required.</small>
                  }
                </td>
                <td>
                  <input
                    pInputText
                    class="w-full ng-invalid ng-dirty"
                    type="text"
                    formControlName="lastName"
                    [ngClass]="{
                      'ng-invalid ng-dirty': user.get('lastName').invalid,
                    }" />
                  @if (user.get('lastName').invalid) {
                    <small class="p-error">Last Name is required.</small>
                  }
                </td>
                <td>
                  <input
                    pInputText
                    class="w-full ng-invalid ng-dirty"
                    type="text"
                    formControlName="email"
                    [ngClass]="{
                      'ng-invalid ng-dirty': !user.get('email').valid,
                    }" />
                  @if (user.get('email').errors?.email) {
                    <small class="p-error"
                      >Provided email address does not have the correct
                      format.</small
                    >
                  }
                  @if (user.get('email').errors?.forbiddenEmail) {
                    <small class="p-error"
                      >A User with this email address already exist.</small
                    >
                  }
                  @if (user.get('email').errors?.required) {
                    <small class="p-error">Email address is required.</small>
                  }
                </td>
                <td>
                  <input
                    pInputText
                    class="w-full"
                    type="text"
                    formControlName="mobilePhone"
                    [ngClass]="{
                      'ng-invalid ng-dirty':
                        user.get('mobilePhone').errors?.mobileNumberForbidden,
                    }" />
                </td>
                <td>
                  <p-dropdown
                    styleClass="w-full"
                    [options]="activeOptions"
                    formControlName="active"
                    placeholder=""
                    appendTo="body"></p-dropdown>
                </td>
                <td>
                  <p-dropdown
                    styleClass="w-full"
                    [options]="roles"
                    optionLabel="name"
                    optionValue="sysId"
                    formControlName="role"
                    placeholder=""
                    appendTo="body"></p-dropdown>
                </td>
                <td>
                  <div>
                    @if (user.value.role) {
                      <p-button
                        (onClick)="addEditPermissions(user)"
                        label="Edit"></p-button>
                    }
                  </div>
                </td>
                <td>
                  @if (users.length > 1) {
                    <i
                      class="pi pi-times text-xl hover:text-primary cursor-pointer pt-2"
                      (keyup.enter)="deleteUser(rowIndex)"
                      role="button"
                      tabindex="0"
                      (click)="deleteUser(rowIndex)"></i>
                  }
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="footer">
              @if (context === 'Add') {
                <p-button
                  label="Add"
                  icon="pi pi-plus"
                  iconPos="left"
                  (onClick)="addUser()" />
              }
            </ng-template>
          </p-table>
          <div class="flex justify-content-center py-3">
            @if (activeStepIndex === 0) {
              <p-button
                styleClass="p-button-secondary"
                label="Back"
                (onClick)="backButton()" />
            }
            <p-button
              label="Next"
              (onClick)="changeView(activeStepIndex + 1)"
              [disabled]="!this.usersForm.valid" />
          </div>
        </form>
      </div>
    }

    @if (!loading && activeStepIndex === 1) {
      <div class="flex-grow-1 overflow-auto mx-3">
        @if (!loading) {
          <p-table
            styleClass="p-datatable-striped"
            class="p-datatable-equal-width"
            [value]="usersForm.value.users">
            <ng-template pTemplate="header">
              <tr>
                <th class="text-white bg-gray-800 p-datatable-header-first-row">
                  First Name
                </th>
                <th class="text-white bg-gray-800 p-datatable-header-first-row">
                  Last Name
                </th>
                <th class="text-white bg-gray-800 p-datatable-header-first-row">
                  Email
                </th>
                <th class="text-white bg-gray-800 p-datatable-header-first-row">
                  Mobile Number
                </th>
                <th class="text-white bg-gray-800 p-datatable-header-first-row">
                  Active
                </th>
                <th class="text-white bg-gray-800 p-datatable-header-first-row">
                  Role
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-user>
              <tr>
                <td>
                  {{ user.firstName }}
                </td>
                <td>
                  {{ user.lastName }}
                </td>
                <td>
                  {{ user.email }}
                </td>
                <td>
                  {{ user.mobilePhone }}
                </td>
                <td>
                  {{ user.active ? 'Yes' : 'No' }}
                </td>
                <td>
                  {{ getRoleName(user.role) }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        }
        <div class="flex justify-content-center py-3">
          <p-button
            styleClass="p-button-secondary"
            label="Back"
            (onClick)="changeView(activeStepIndex - 1)" />
          <p-button
            [disabled]="!this.usersForm.valid"
            label="Submit"
            (onClick)="submit()" />
        </div>
      </div>
    }
    @if (loading) {
      <loading-spinner></loading-spinner>
    }
  </div>
</div>
