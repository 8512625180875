@if (loading) {
  <loading-spinner />
}
@if (!loading) {
  <p-table
    #dataTable
    [value]="outages$ | async"
    [paginator]="true"
    [rows]="25"
    styleClass="p-datatable-striped"
    [columns]="cols"
    [rowsPerPageOptions]="[25, 50, 100]"
    sortMode="single"
    [resizableColumns]="true"
    columnResizeMode="expand"
    scrollHeight="flex"
    [scrollable]="true"
    [rowHover]="true"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} devices">
    <ng-template pTemplate="caption">
      <div class="flex justify-content-end align-content-center">
        <div class="flex-grow-1 align-items-center flex">
          @if (!loading) {
            <prime-filter-dropdown
              [selectedColumns]="cols"
              [table]="dataTable"
            [futurePeriodFilter]="3"></prime-filter-dropdown>
          }
          <p-button
          label="Reset"
          styleClass="p-button p-button-text p-button-secondary"
          icon="pi pi-eraser"
        (click)="reset(dataTable, false)"></p-button>
        </div>

      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        @for (col of columns; track col) {
          <th
            class="text-white bg-gray-800 p-datatable-header-first-row"
            [pSortableColumn]="col.field">
            {{ col.header }}
            <p-sortIcon class="text-white" [field]="col.field"></p-sortIcon>
          </th>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="loadingbody">
      <tr>
        <td>
          <loading-spinner></loading-spinner>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="loadingicon"></ng-template>
    <ng-template pTemplate="body" let-outage let-columns="columns">
      <tr (click)="handleRowClicked(outage)" class="cursor-pointer">
        @for (col of columns; track col; let firstItem = $first) {
          <td>
            <ng-container
            *ngTemplateOutlet="
              fieldTemplate;
              context: { field: outage[col.field], col: col }
            "></ng-container>
            <ng-template #fieldTemplate let-field="field" let-col="col">
              @if (field === undefined) {
                <i
                  class="pi pi-exclamation-triangle"
                  style="color: 'var(--yellow-600)'"
                pTooltip="There was an issue loading this data, please refresh. If this issue persists, please contact support."></i>
              }
              @if (col.display.type === 'text' && col?.display?.colors) {
                <span
                [class]="
                  getColor(col.display.colors, field)
                    ? 'text-' + getColor(col.display.colors, field) + '-400'
                    : null
                "
                  class="font-bold"
                  >{{ field }}</span
                  >
                }
                @if (col.display.type === 'text' && !col?.display?.colors) {
                  {{ outage[col.field] }}
                }
                @if (col.display.type === 'date') {
                  {{ outage[col.field] | date: 'dd/MM/yyyy HH:mm' }}
                }
                @if (col.display.type === 'chip') {
                  <div class="text-center">
                    <p-chip
                      [label]="outage[col.field]"
                  [styleClass]="
                    getColor(col.display.colors, outage[col.field])
                      ? 'p-chip-' +
                        getColor(col.display.colors, outage[col.field]) +
                        ' block'
                      : 'block'
                  "></p-chip>
                  </div>
                }
                @if (col.display.type === 'chipMetric') {
                  <div class="w-full text-center">
                    <p-chip
                      [label]="outage[col.field]"
                      styleClass="mr-2 block"
                  [styleClass]="
                    'p-chip-' + getColorMetric(outage[col.field])
                  "></p-chip>
                  </div>
                }
              </ng-template>
            </td>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="cols.length">No results were found.</td>
        </tr>
      </ng-template>
    </p-table>
  }

