import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api/menuitem';
import { firstValueFrom } from 'rxjs';
import { CustomValidator } from 'src/app/form-validation/custom-validator';
import { RequiredValidation } from 'src/app/form-validation/validators';
import { AuthenticationService } from 'src/app/services/api/authentication/authentication.service';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import { ToastService, ToastType } from 'src/app/services/global/toast.service';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  profileFormControl: any;
  customValidator = new CustomValidator();
  currentPasswordError: string;
  profile: any = {};

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private breadcrumbService: BreadcrumbService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    const breadcrumbs: MenuItem[] = [
      { label: 'Accounts', url: '/secure/account' },
      { label: 'Change Password' },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.profileFormControl = this.formBuilder.group(
      {
        currentPassword: new UntypedFormControl('', RequiredValidation),
        //currentPassword:new UntypedFormControl (["",RequiredValidation]),
        password: new UntypedFormControl(
          '',
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            CustomValidator.patternValidator(/\d/, {
              hasNumber: true,
            }),
            // check whether the entered password has upper case letter
            CustomValidator.patternValidator(/[A-Z]/, {
              hasCapitalCase: true,
            }),
            // check whether the entered password has a special character
            CustomValidator.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true,
              }
            ),
            Validators.minLength(8),
          ])
        ),
        confirmPassword: new UntypedFormControl('', RequiredValidation),
      },

      { validator: this.checkPasswords }
    );

    this.profileFormControl.get('currentPassword').reset();
  }

  checkPasswords(group: UntypedFormGroup) {
    const currentPass = group.controls.currentPassword.value;
    const newPassword = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;

    if (currentPass && newPassword && currentPass === newPassword) {
      group.controls.password.setErrors({ notSame: true });
    } else {
      group.controls.password.setErrors(null);
    }

    if (newPassword && confirmPass && newPassword !== confirmPass) {
      group.controls.confirmPassword.setErrors({ notMatch: true });
    } else {
      group.controls.confirmPassword.setErrors(null);
    }

    return null;
  }

  changePasswordDisabled(): boolean {
    if (
      this.profileFormControl.controls['currentPassword'].hasError(
        'required'
      ) ||
      this.profileFormControl.controls['password'].hasError('minlength') ||
      this.profileFormControl.controls['password'].hasError('required') ||
      this.profileFormControl.controls['password'].hasError('hasNumber') ||
      this.profileFormControl.controls['password'].hasError('hasCapitalCase') ||
      this.profileFormControl.controls['password'].hasError(
        'hasSpecialCharacters'
      ) ||
      this.profileFormControl.controls['confirmPassword'].hasError('notSame') ||
      this.profileFormControl.controls['password'].hasError('notSame') ||
      this.profileFormControl.hasError('notSame') ||
      this.profileFormControl.controls['confirmPassword'].hasError('notMatch')
    ) {
      return true;
    }
    return false;
  }

  async changePassword(): Promise<void> {
    const response = await firstValueFrom(
      this.authenticationService.changePassword(
        this.profile.email,
        this.profileFormControl.controls['currentPassword'].value,
        this.profileFormControl.controls['password'].value,
        this.profileFormControl.controls['confirmPassword'].value,
        false
      )
    );

    if (response.success) {
      this.toastService.add({
        severity: ToastType.success,
        summary: 'Change Password',
        detail: 'Your password has been updated.',
      });
      this.router.navigateByUrl('/secure/account');
    } else {
      this.currentPasswordError = response.errorMessage;
      this.profileFormControl.get('currentPassword').reset();
    }
  }
}
