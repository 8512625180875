import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ServiceNowFilterQuery } from 'src/app/models/servicenow.model';
import { OutageService } from 'src/app/services/api/outage/outage.service';

@Injectable({
  providedIn: 'root',
})
export class ServiceNotificationsService {
  constructor(private outAgeService: OutageService) {}

  async loadServiceNotifications() {
    const serviceNotificationsFilter: ServiceNowFilterQuery = {
      Filter: [
        { Column: 'UHidden', Value: ['false'] },
        { Column: 'InstallStatus', Value: ['!=7'] },
      ],
      CurrentPage: 0,
      Amount: 20,
    };
    const serviceNotifications = await firstValueFrom(
      this.outAgeService.getBusinessServiceSubscriptions(
        serviceNotificationsFilter
      )
    );

    return serviceNotifications;
  }

  //TODO Add interface
  async toggleServiceSubscription(service) {
    await firstValueFrom(
      this.outAgeService.updateSubscription(service.sysId, service.subscribed)
    );
  }
}
