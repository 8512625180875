<div class="flex flex-column h-full">
  <h1 class="text-xl px-3">
    Default Permissions for Role - {{ selectedRole?.name }}
  </h1>
  <hr class="w-full" />
  @if (loading) {
    <div>
      <loading-spinner />
    </div>
  }
  <div class="pl-3 pr-3">
    @if (!loading) {
      <div>
        <p class="text-xl font-bold">Description</p>
        <p class="text-xl">{{ selectedRole?.description }}</p>
        <p class="text-xl font-bold">Permissions</p>
      </div>
    }
    @if (!false) {
      <div class="flex flex-row flex-wrap">
        <div class="col">
          @for (
            category of permissionsForSelectedRole
            | slice: 0 : getColumnLength(); track
            category; let i = $index) {
            <div>
              <div class="font-bold">
                {{ category?.category | titlecase | replaceUnderscore }}
              </div>
              @for (permission of category.permissions; track permission) {
                <ul>
                  <li>
                    {{ permission?.label }} <br />
                    {{ permission?.description }}
                  </li>
                </ul>
              }
            </div>
          }
        </div>
        <div class="col">
          @for (
            category of permissionsForSelectedRole
            | slice: getColumnLength() : permissionsForSelectedRole?.length; track
            category; let i = $index) {
            <div>
              <div class="font-bold">
                {{ category?.category | titlecase | replaceUnderscore }}
              </div>
              @for (permission of category.permissions; track permission) {
                <ul>
                  <li>
                    {{ permission?.label }} <br />
                    {{ permission?.description }}
                  </li>
                </ul>
              }
            </div>
          }
        </div>
      </div>
    }
    @if (!loading) {
      <div
        class="flex align-content-center justify-content-center pb-2">
        <p-button
          label="Close"
          (onClick)="closelButton()"
        icon="pi pi-times"></p-button>
      </div>
    }
  </div>
</div>
