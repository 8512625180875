<div class="flex justify-content-center">
  <div class="max-w-90rem flex-grow-1">
    @if (canDisplayStatusWidgets) {
      <dashboard-service-status />
    }
  </div>
</div>

<div class="flex justify-content-center">
  <div class="flex max-w-90rem col-12 flex-wrap pb-0">
    <e-chart-pie [chart]="chart?.incident" />
    <e-chart-pie [chart]="chart?.request" />
    <e-chart-pie [chart]="chart?.order" />
  </div>
</div>

<div class="flex justify-content-center">
  <div class="flex max-w-90rem col-12 flex-wrap pt-0">
    <app-dashboard-month-compare
      title="Incidents Opened"
      [permission]="ticketPermissions.incident"
      [statistics]="{
        thisMonth: statistics.incidents.openedThisMonth,
        lastMonth: statistics.incidents.openedLastMonth,
      }"></app-dashboard-month-compare>
    <app-dashboard-month-compare
      title="Service Requests Opened"
      [permission]="ticketPermissions.request"
      [statistics]="{
        thisMonth: statistics.serviceRequests.openedThisMonth,
        lastMonth: statistics.serviceRequests.openedLastMonth,
      }"></app-dashboard-month-compare>
    <app-dashboard-month-compare
      title="Orders Opened"
      [permission]="ticketPermissions.order"
      [statistics]="{
        thisMonth: statistics.orders.openedThisMonth,
        lastMonth: statistics.orders.openedLastMonth,
      }"></app-dashboard-month-compare>
  </div>

  @if (showCookieBanner) {
    <div
      class="fixed bottom-0 right-0 left-0 bg-gray-900 opacity-90 p-4 flex items-center">
      <p class="text-white text-base">
        We use necessary cookies to make our site work. For more detailed
        information about the cookies we use, see our
        <a href="/secure/cookies-policy" target="_blank" class="text-white"
          >Cookies page</a
        >.
      </p>
      <p-button
        (onClick)="acceptCookies()"
        label="Accept and close"
        class="ml-auto"></p-button>
    </div>
  }
</div>
