import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Message } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-change-reject-comment',
  templateUrl: './change-reject-comment.component.html',
  styleUrls: ['./change-reject-comment.component.scss'],
})
export class ChangeRejectCommentComponent {
  detail: Message[] | undefined;
  addCommentFormControl = this.fb.group({
    comment: ['', Validators.required],
  });

  comment: string;

  constructor(
    private fb: FormBuilder,
    public ref: DynamicDialogRef
  ) {}

  ngOnInit() {
    this.detail = [
      {
        severity: 'warn',
        detail: `Please do not submit a further expedite request until a review has taken place.<br />
        You will be notified when this is ready for the re-approval.`,
      },
    ];
  }

  addComment(): void {
    this.comment = this.addCommentFormControl.value?.comment;
    this.ref.close(this.comment);
  }

  closeButton(): void {
    this.ref.close();
  }
}
