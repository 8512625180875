import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';
import { GetSingleServiceRequestResponse } from 'src/app/models/service-request/service-request.models';
import { ServiceNowFilterQuery } from 'src/app/models/servicenow.model';

const taskDirectory = 'RequestedItems';

@Injectable({
  providedIn: 'root',
})
export class RequestedItemsService {
  constructor(private apiRequest: ApiRequest) {}

  // TODO check is this still needed
  getRequestItemVariablesFull() {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/getRequestItemVariablesFull2afad9fddb320b004686f7461d961998`,
    };

    return this.apiRequest.request(requestProps);
  }

  getRequestItemVariables(pacType: any) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetRequestItemVariables`,
      body: pacType,
    };

    return this.apiRequest.request(requestProps);
  }
}
