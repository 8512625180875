import { Injectable } from '@angular/core';
import { IDownloadFile, ITableToFile } from '../models/export-models';
import { ExportApiService } from './api/export-api/export-api.service';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class ExportService {
  constructor(private ExportApiService: ExportApiService) {}

  async exportTableToFile(endpoint: string, body: ITableToFile) {
    const downloadFile: IDownloadFile = await firstValueFrom(
      this.ExportApiService.exportTableToFile(endpoint, body)
    );
    return downloadFile;
  }

  downloadFile(
    downloadFile: IDownloadFile,
    fileExtension?: string,
    fileName?: string
  ) {
    if (downloadFile.byteArray.length == 0) {
      return false;
    }

    const file = this.b64toBlob(
      downloadFile.byteArray,
      downloadFile.contentType
    );

    const download = document.createElement('a');
    download.style.display = 'none';
    document.body.appendChild(download);
    download.href = window.URL.createObjectURL(file);

    if (fileName && fileExtension) {
      download.download = `${fileName}report - All.${fileExtension.toLowerCase()}`;
    } else {
      download.download = downloadFile.fileName;
    }

    download.click();
    document.body.removeChild(download);
  }

  // Calls the url given by the endpoint and applies the filters given on the body.
  fileExportList(endpoint: string, body, type: ExportType): void {
    body.ExportType = type;
    firstValueFrom(this.ExportApiService.fileExportList(endpoint, body)).then(
      data => {
        this.downloadFile(data);
      }
    );
  }

  b64toBlob(b64Data, contentType): Blob {
    let sliceSize = 64;
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}

export enum ExportFileName {
  BillingCloud = 'Billing - Cloud',
  BillingConnectivity = 'Billing - Connectivity',
  BillingServices = 'Billing - Services',
  BillingSecurity = 'Billing - Security',
  BillingUCC = 'Billing - UCC',
  broadband = 'Broadband ',
  changeRequests = 'Change Requests',
  connectivityUsage = 'Usage',
  devices = 'Devices',
  Domain = 'DNS',
  Domains = 'Domains',
  incidents = 'Incidents',
  orders = 'Orders',
  serviceRequests = 'Service Requests',
  Telephony = 'Mobile',
  users = 'Users',
}

export enum ExportType {
  Pdf = 'Pdf',
  Excel = 'Excel',
  Csv = 'Csv',
}

// TODO what is this for? Still needed? Unsure if this references endpoints or not
export enum ExportTableEndpoint {
  Broadband = 'Broadband/ExportBroadband',
  ChangeRequests = 'ChangeRequest/ExportChangeRequests',
  ConnectivityUsage = 'Invoices/ExportCDRCalls',
  DeviceUtilisation = 'ScienceLogic/ExportDeviceTable',
  Domain = 'Domains/ExportDomain',
  Domains = 'Domains/ExportDomains',
  Incidents = 'Incident/ExportIncidents',
  Orders = 'Order/ExportOrders',
  ServiceRequests = 'RequestedItems/ExportRequestedItems',
  Telephony = 'Telephony/ExportMobilePhones',
  Contact = 'Contact/GetContactsExport',
}

export class File {
  id: string;
  name: string;
}
