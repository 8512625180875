@if (loading) {
  <loading-spinner></loading-spinner>
}

@if (!loading) {
  <div class="flex flex-column h-full">
    <h1 class="text-xl px-3">ServiceHub Webhook - Developer Credentials</h1>
    <hr class="w-full" />
    @if (!loading) {
      <div class="pl-3 pr-3">
        <form [formGroup]="webHookForm" (ngSubmit)="onSubmit()">
          <p class="text-xl">Please enter the information below for the webhook.</p>
          <div class="field">
            <!-- Source -->
            <label for="source" class="text-xl"
              ><span class="text-primary text-xl">*</span>Source</label
              >
              <br />
              <p-dropdown
                class="capitalize"
                formControlName="source"
                placeholder="Select Source"
                [options]="availableSources"
                (onChange)="changeSource()"
                autoWidth="false"
                [style]="{ width: '100%' }">
              </p-dropdown>
            </div>
            <div class="field">
              <!-- Action -->
              <label for="action" class="text-xl"
                ><span class="text-primary text-xl">*</span>Action</label
                >
                <br />
                <p-dropdown
                  formControlName="action"
                  placeholder="Select Action"
                  [options]="availableActions"
                  autoWidth="false"
                  [style]="{ width: '100%' }">
                </p-dropdown>
              </div>
              <div class="field">
                <!-- Endpoints -->
                <label for="endpoint" class="text-xl"
                  ><span class="text-primary text-xl">*</span>Endpoint</label
                  >
                  <input
                    placeholder="Endpoint"
                    id="endpoint"
                    formControlName="endpoint"
                    type="text"
                    pInputText
                    aria-label="Endpoint"
                    class="w-full" />
                  @if (
                    webHookFormControls.endpoint.errors?.pattern &&
                    webHookFormControls.endpoint.touched
                    ) {
                    <small
                      class="p-error text-base">
                      Endpoint must begin with "https://
                    </small>
                  }
                </div>
                <div class="field">
                  <!-- Authentication Type -->
                  <label for="authentication" class="text-xl"
                    ><span class="text-primary text-xl">*</span>Authentication Type</label
                    >
                    <br />
                    <p-dropdown
                      appendTo="body"
                      formControlName="authenticationType"
                      placeholder="Select Authentication Type"
                      (onChange)="setAuthenticationValidators($event.value)"
                      [options]="authenticationTypes"
                      autoWidth="false"
                      [style]="{ width: '100%' }">
                    </p-dropdown>
                  </div>
                  <!-- OAuth Token -->
                  @if (webHookFormControls.authenticationType.value === 'OAuth') {
                    <div class="field">
                      <label class="text-xl" for="oauthtoken">OAuth Token</label>
                      <br />
                      <p-password
                        id="oauthtoken"
                        formControlName="value"
                        [feedback]="false"
                        formControlName="oAuthToken"
                        placeholder="OAuth Token"
                      [toggleMask]="true"></p-password>
                      <br />
                      @if (
                        webHookFormControls.oAuthToken.errors?.required &&
                        webHookFormControls.oAuthToken.touched
                        ) {
                        <small
                          class="p-error text-base"
                          >OAuth Token is required.</small
                          >
                        }
                      </div>
                    }
                    <!-- UserName -->
                    @if (webHookFormControls.authenticationType.value === 'Basic Auth') {
                      <div class="field">
                        <label for="username" class="text-xl"
                          ><span class="text-primary text-xl">*</span>Username</label
                          >
                          <br />
                          <p-password
                            id="username"
                            formControlName="basicAuthUsername"
                            [feedback]="false"
                            placeholder="Username"
                          [toggleMask]="true"></p-password>
                          <br />
                          @if (
                            webHookFormControls.basicAuthUsername.errors?.required &&
                            webHookFormControls.basicAuthUsername.touched
                            ) {
                            <small
                              class="p-error text-base"
                              >Username is required.</small
                              >
                            }
                          </div>
                          <!-- Password -->
                          <div class="field">
                            <label for="password" class="text-xl"
                              ><span class="text-primary text-xl">*</span>Password</label
                              >
                              <br />
                              <p-password
                                id="password"
                                formControlName="value"
                                [feedback]="false"
                                formControlName="basicAuthPassword"
                                placeholder="Username"
                              [toggleMask]="true"></p-password>
                              <br />
                              @if (
                                webHookFormControls.basicAuthPassword.errors?.required &&
                                webHookFormControls.basicAuthPassword.touched
                                ) {
                                <small
                                  class="p-error text-base text-xl"
                                  >Password is required.</small
                                  >
                                }
                              </div>
                            }
                          </form>
                        </div>
                      }
                      @if (!loading) {
                        <div
                          class="flex align-content-center justify-content-center pt-4 pb-4"
                          >
                          <p-button
                            styleClass="p-button-secondary"
                            label="Cancel"
                          (onClick)="close()"></p-button>
                          <p-button
                            styleClass="p-button"
                            [disabled]="!formValueChanged || !webHookForm.valid"
                            label="Submit"
                          (onClick)="onSubmit()"></p-button>
                        </div>
                      }
                    </div>
                  }
