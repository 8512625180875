export interface DashBoardStatistic {
  value: string | number | null;
  loading: boolean;
  command?: () => void;
}

export type DashBoardStatisticTotal = Record<string, DashBoardStatistic>;

export type DashboardStatistics = Record<string, DashBoardStatisticTotal>;

export const dashboardStatistics: DashboardStatistics = {
  orders: {
    openedThisMonth: {
      value: null,
      loading: true,
    },
    openedLastMonth: {
      value: null,
      loading: true,
    },
  },
  serviceRequests: {
    openedThisMonth: {
      value: null,
      loading: true,
    },
    openedLastMonth: {
      value: null,
      loading: true,
    },
  },
  incidents: {
    openedThisMonth: {
      value: null,
      loading: true,
    },
    openedLastMonth: {
      value: null,
      loading: true,
    },
  },
};
