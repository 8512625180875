import {
  Component,
  OnInit,
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { Router } from '@angular/router';
import { IUserProfile } from '../../../company/users/shared/user-models';
import { KnowledgeService } from 'src/app/services/api/knowledge/knowledge.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { firstValueFrom } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import { NotificationHelper } from 'src/app/helpers/notifications/notification.helper';

@Component({
  selector: 'knowledge-article-details',
  templateUrl: 'knowledge-article-details.component.html',
  styleUrls: ['knowledge-article-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnowledgeArticleDetailsComponent
  implements OnInit, AfterContentChecked
{
  articleId: any;
  article: any;
  files: any;
  linkVisible: boolean = false;
  user: IUserProfile;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private knowledgeService: KnowledgeService,
    private authService: AuthService,
    private breadcrumbService: BreadcrumbService,
    private notificationHelper: NotificationHelper
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async (params: Params) => {
      this.user = await this.authService.getUser();
      this.articleId = params['id'];
      await this.loadArticle();

      const breadcrumbs: MenuItem[] = [
        { label: ' Support', routerLink: '/support' },
        {
          label: 'Knowledge Articles',
          routerLink: '/support/knowledge-articles/',
        },
        { label: this.article?.number },
      ];
      this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    });
  }

  ngAfterContentChecked() {
    if (this.article != undefined) {
      this.checkArticleImages();
    }
  }

  async loadArticle() {
    this.article = await firstValueFrom(
      this.knowledgeService.getKnowledgeArticle(
        this.articleId,
        this.user.companyId
      )
    );
    //we aren't authorised or there is a problem with the article
    if (this.article == undefined) {
      this.router.navigateByUrl('support/knowledge-articles');
      return;
    }

    this.notificationHelper.readNotification(this.article.sysId);
    this.cdr.detectChanges();
  }

  checkArticleImages() {
    let article = document.querySelector('.article-content');

    document.querySelectorAll('.article-image').forEach(function (image) {
      if (
        (<HTMLElement>image).offsetWidth >= (<HTMLElement>article).offsetWidth
      ) {
        image.setAttribute('class', 'article-image image-resize');

        if (this != undefined) {
          this.cdr.detectChanges();
        }
      }
    });
  }
}
