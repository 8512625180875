import { Component, Input, OnInit } from '@angular/core';
import { ChangeRequestColumns } from '../change-request-details.columns';
import {
  ChangeRequestDetails,
  ChangeNames,
  GetSingleChangeRequestResponse,
} from 'src/app/models/change/change.models';

@Component({
  selector: 'change-request-details',
  templateUrl: './change-request-details.component.html',
  styleUrls: ['./change-request-details.component.scss'],
})
export class ChangeRequestDetailsComponent implements OnInit {
  @Input() changeRequest;
  tableColumn = ChangeRequestColumns;
  changeRequestDetails: ChangeRequestDetails[];

  constructor() {}

  ngOnInit() {
    this.changeRequestDetails = this.getChangeDetails(this.changeRequest);
  }

  getChangeDetails(
    change: GetSingleChangeRequestResponse
  ): ChangeRequestDetails[] {
    if (!change) {
      return null;
    }

    return Object.entries(change)
      .map(([key, value]) => {
        return { key: key.replace(/([A-Z])/g, '_$1').toUpperCase(), value };
      })
      .filter(({ key }) => key in ChangeNames)
      .map(({ key, value }) => ({
        name: ChangeNames[key as keyof typeof ChangeNames],
        details: value as string | '',
      }))
      .sort((a, b) => {
        const enumValues = Object.values(ChangeNames);
        return enumValues.indexOf(a.name) - enumValues.indexOf(b.name);
      });
  }
}

