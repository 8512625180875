import { TableColumn } from 'src/app/models/table';

export const CommentColumns: TableColumn[] = [
  {
    field: 'value',
    header: 'Comment',
    display: { type: 'text' },
    showSortIcon: false,
    default: true,
    cell: data => data.uQuestion,
    expand: data => data.uAnswer,
  },
  {
    field: 'sysCreatedOn',
    header: 'Date',
    display: { type: 'date' },
    showSortIcon: false,
    default: true,
  },
  {
    field: 'uCommentedBy',
    header: 'Commented By',
    display: { type: 'text' },
    showSortIcon: false,
    default: true,
  },
  {
    field: 'type',
    header: 'Type',
    display: {
      type: 'chip',
      colors: {
        Comment: 'Comment',
      },
    },
    showSortIcon: false,
    default: true,
  },
];

export interface Comment {
  element: string;
  elementId: string;
  name: string;
  sysCreatedBy: string;
  sysCreatedOn: Date;
  sysId: string;
  sysTags: string;
  value: string;
}

export interface ticketData {
  sysId: string;
  state: string;
  type: string;
  canAddComments?: boolean;
}

export enum ticketType {
  incident = 'incident',
  request = 'sc_req_item',
  case = 'sn_customerservice_case',
  orderLineItem = 'sn_ind_tmt_orm_order_line_item',
}
