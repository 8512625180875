export const CallAmount = 20;

export const IncidentFilters = [
  {
    Column: 'OrderByDesc',
    Value: ['UPortalLastUpdated', 'Number'],
    // Type: "text",
    // Options: [],
    // Visibility: false,
  },
  {
    Column: 'Number',
    Value: [],
    // Type: "text", Options: [], Visibility: true
  },
  {
    Column: 'ShortDescription',
    Value: [],
    // Type: "text",
    // Options: [],
    // Visibility: true,
  },
  {
    Column: 'CallerId.name',
    Value: [],
    // Type: "text",
    // Options: [],
    // Visibility: true,
  },
  {
    Column: 'CallerId',
    Value: [],
    // Type: "text",
    // Options: [],
    // Visibility: false,
  },
  {
    Column: 'SysCreatedOn',
    Value: [],
    // Type: "dateRange",
    // Options: [],
    // Visibility: true,
  },
  {
    Column: 'Priority',
    Value: [],
    // Type: "dropdown",
    // Options: ["1 - Critical", "2 - High", "3 - Moderate", "4 - Low"],
    // Visibility: true,
  },
  {
    Column: 'State',
    Value: ['New', 'In Progress', 'Awaiting Info'],
    // Type: "dropdown",
    // Options: ["New", "In Progress", "Awaiting Info", "Resolved", "Closed"],
    // Visibility: true,
  },
  {
    Column: 'UServiceComponent',
    Value: [],
    // Type: "text",
    // Options: [],
    // Visibility: true,
  },
  {
    Column: 'USymptom',
    Value: [],
    // Type: "text",
    // Options: [],
    // Visibility: true,
  },
  {
    Column: 'UPortalLastAction',
    Value: [],
    // Type: "text",
    // Options: [],
    // Visibility: false,
  },
  {
    Column: 'SurveyStatus',
    Value: [],
    // Type: "",
    // Options: [],
    // Visibility: true,
  },
  {
    Column: 'ColumnSelector',
    Value: [],
    // Type: "",
    // Options: [],
    // Visibility: true,
  },
  {
    Column: 'Location',
    Value: [],
    // Type: "text",
    // Options: [],
    // Visibility: true,
  },
  {
    Column: 'HoldReason',
    Value: [],
    // Type: "dropdown",
    // Options: [
    //   "Awaiting Customer",
    //   "Awaiting Digital Space Vendor",
    //   "Awaiting Engineer Visit",
    //   "Awaiting Change",
    //   "Awaiting Service Request",
    //   "Awaiting Customer 3rd Party Supplier",
    //   "Awaiting Resolution Confirmation",
    //   "Awaiting Review Date",
    //   "Awaiting Private Task",
    // ],
    // Visibility: true,
  },
];

export const graphIncidentFilter = [
  {
    Column: 'State',
    Value: ['Awaiting Info'],
    Type: 'dropdown',
    Options: [],
    Visibility: true,
  },
];

export const costCentreFilter = [
  {
    Column: 'OrderByDesc',
    Value: [],
    Type: 'text',
    Options: [],
    Visibility: false,
  },
  { Column: 'Name', Value: [], Type: 'text', Options: [], Visibility: true },
];

export const costCentreUserFilter = [
  {
    Column: 'OrderByDesc',
    Value: [],
    Type: 'text',
    Options: [],
    Visibility: false,
  },
  { Column: 'Name', Value: [], Type: 'text', Options: [], Visibility: true },
];

// export const ServiceRequestFilters = [
//   {
//     Column: "OrderByDesc",
//     Value: ["UPortalLastUpdated", "Number"],
//     Type: "text",
//     Options: [],
//     Visibility: false,
//   },
//   { Column: "Number", Value: [], Type: "text", Options: [], Visibility: true },
//   { Column: "Request", Value: [], Type: "text", Options: [], Visibility: true },
//   {
//     Column: "ShortDescription",
//     Value: [],
//     Type: "text",
//     Options: [],
//     Visibility: true,
//   },
//   {
//     Column: "URequestedFor.name",
//     Value: [],
//     Type: "text",
//     Options: [],
//     Visibility: true,
//   },
//   {
//     Column: "OpenedAt",
//     Value: [],
//     Type: "dateRange",
//     Options: [],
//     Visibility: true,
//   },
//   {
//     Column: "State",
//     Value: ["Pending", "Open", "Work in progress", "Awaiting info"],
//     Type: "dropdown",
//     Options: [
//       "Pending",
//       "Open",
//       "Work in progress",
//       "Awaiting info",
//       "Fulfilled",
//       "Closed",
//     ],
//     Visibility: true,
//   },
//   {
//     Column: "UPortalLastAction",
//     Value: [],
//     Type: "text",
//     Options: [],
//     Visibility: false,
//   },
//   {
//     Column: "SurveyStatus",
//     Value: [],
//     Type: "",
//     Options: [],
//     Visibility: true,
//   },
//   {
//     Column: "ColumnSelector",
//     Value: [],
//     Type: "",
//     Options: [],
//     Visibility: true,
//   },
// ];

export const CreditNotesFilter = [
  {
    Column: 'InvoiceNo',
    Value: [],
    Type: 'text',
    Options: [],
    Visibility: true,
  },
  {
    Column: 'InvoiceDate',
    Value: [],
    Type: 'dateRange',
    Options: [],
    Visibility: true,
  },
  { Column: 'Net', Value: [], Type: 'text', Options: [], Visibility: true },
  { Column: 'VAT', Value: [], Type: 'text', Options: [], Visibility: true },
  {
    Column: 'OrderByDesc',
    Value: ['InvoiceDate'],
    Type: 'text',
    Options: [],
    Visibility: false,
  },
  {
    Column: 'SiteId',
    Value: [],
    Type: 'dropdown',
    Options: [],
    Visibility: true,
    DynamicOptions: {
      service: 'userService',
      endpoint: 'getContactBillingEntities',
      parameter: 'credit',
    },
  },
];

export const CommentFilters = [
  { Column: 'Type', Value: [], Type: 'text', Options: [], Visibility: true },
  { Column: 'Date', Value: [], Type: 'text', Options: [], Visibility: true },
  {
    Column: 'CommentedBy',
    Value: [],
    Type: 'text',
    Options: [],
    Visibility: true,
  },
  { Column: 'Content', Value: [], Type: 'text', Options: [], Visibility: true },
  {
    Column: 'ColumnSelector',
    Value: [],
    Type: '',
    Options: [],
    Visibility: true,
  },
];

export const InvoicesFilter = [
  {
    Column: 'InvoiceNo',
    Value: [],
    Type: 'text',
    Options: [],
  },
  {
    Column: 'InvoiceTag',
    Value: [],
    Type: 'text',
    Options: [],
  },
  {
    Column: 'InvoiceDate',
    Value: [],
    Type: 'dateRange',
    Options: [],
  },
  { Column: 'Net', Value: [], Type: 'text', Options: [], Visibility: true },
  { Column: 'VAT', Value: [], Type: 'text', Options: [], Visibility: true },
  {
    Column: 'OrderByDesc',
    Value: ['InvoiceDate'],
    Type: 'text',
    Options: [],
  },
  {
    Column: 'SiteId',
    Value: [],
    Type: 'dropdown',
    Options: [],
    DynamicOptions: {
      service: 'userService',
      endpoint: 'getContactBillingEntities',
      parameter: 'invoice',
    },
  },
];

export const DomainsFilter = [
  {
    Column: 'OrderBy',
    Value: ['name'],
    Type: 'text',
    Options: [],
    Visibility: false,
  },
  {
    Column: 'Name',
    Value: [],
    Type: 'text',
    Options: [],
    Visibility: true,
  },
  {
    Column: 'Company',
    Value: [],
    Type: 'text',
    Options: [],
    Visibility: true,
  },
];

export const DevicesFilter = [
  // {
  //   Column: "OrderBy",
  //   Value: ["Name"],
  //   Type: "text",
  //   Options: [],
  //   Visibility: false,
  // },
  // {
  //   Column: "XScloScilogicId",
  //   Value: ["ISNOTEMPTY"],
  //   Type: "text",
  //   Options: [],
  //   Visibility: false,
  // },
  // { Column: "Name", Value: [], Type: "text", Options: [], Visibility: true },
  // {
  //   Column: "SysClassName",
  //   Value: [],
  //   Type: "dropdown",
  //   Options: [],
  //   Visibility: true,
  // },
  {
    Column: 'UServicehubCategory',
    Value: [],
    Type: 'text',
    Options: [],
    Visibility: false,
  },
  // {
  //   Column: "FirmwareVersion",
  //   Value: [],
  //   Type: "text",
  //   Options: [],
  //   Visibility: true,
  // },
  // {
  //   Column: "SerialNumber",
  //   Value: [],
  //   Type: "text",
  //   Options: [],
  //   Visibility: true,
  // },
  // {
  //   Column: "PollingStatus",
  //   Value: [],
  //   Type: "dropdown",
  //   Options: ["Active", "Paused", "Maintenance", "Unknown"],
  //   Visibility: true,
  // },
  // {
  //   Column: "Availability",
  //   Value: [],
  //   Type: "dropdown",
  //   Options: ["Available", "Unavailable", "Unknown"],
  //   Visibility: true,
  // },
  // {
  //   Column: "AntiVirusInformationResponseName",
  //   Value: [],
  //   Type: "",
  //   Options: [],
  //   Visibility: true,
  // },
  // {
  //   Column: "AntiVirusInformationResponseDefinition",
  //   Value: [],
  //   Type: "",
  //   Options: [],
  //   Visibility: true,
  // },
  // {
  //   Column: "AntiVirusInformationResponseLastUpdated",
  //   Value: [],
  //   Type: "",
  //   Options: [],
  //   Visibility: true,
  // },
  // {
  //   Column: "ColumnSelector",
  //   Value: [],
  //   Type: "",
  //   Options: [],
  //   Visibility: true,
  // },
];

export const DevicesServerFilter = [
  // {
  //   Column: "OrderBy",
  //   Value: ["Name"],
  //   Type: "text",
  //   Options: [],
  //   Visibility: false,
  // },
  // {
  //   Column: "XScloScilogicId",
  //   Value: ["ISNOTEMPTY"],
  //   Type: "text",
  //   Options: [],
  //   Visibility: false,
  // },
  // { Column: "Name", Value: [], Type: "text", Options: [], Visibility: true },
  // {
  //   Column: "SysClassName",
  //   Value: [],
  //   Type: "dropdown",
  //   Options: [],
  //   Visibility: true,
  //   DynamicOptions: {
  //     service: "scienceLogicService",
  //     endpoint: "getScienceLogicDeviceFilters",
  //     parameter: { category: "Server" },
  //     isNull: true,
  //   },
  // },
  {
    Column: 'UServicehubCategory',
    Value: ['Server'],
    Type: 'text',
    Options: [],
    Visibility: false,
  },
  // {
  //   Column: "firmwareVersion",
  //   Value: [],
  //   Type: "text",
  //   Options: [],
  //   Visibility: true,
  // },
  // {
  //   Column: "SerialNumber",
  //   Value: [],
  //   Type: "text",
  //   Options: [],
  //   Visibility: true,
  // },
  // {
  //   Column: "PollingStatus",
  //   Value: [],
  //   Type: "dropdown",
  //   Options: ["Active", "Paused", "Maintenance", "Unknown"],
  //   Visibility: true,
  // },
  // {
  //   Column: "Availability",
  //   Value: [],
  //   Type: "dropdown",
  //   Options: ["Available", "Unavailable", "Unknown"],
  //   Visibility: true,
  // },
  // {
  //   Column: "AntiVirusInformationResponseName",
  //   Value: [],
  //   Type: "",
  //   Options: [],
  //   Visibility: true,
  // },
  // {
  //   Column: "AntiVirusInformationResponseDefinition",
  //   Value: [],
  //   Type: "",
  //   Options: [],
  //   Visibility: true,
  // },
  // {
  //   Column: "AntiVirusInformationResponseLastUpdated",
  //   Value: [],
  //   Type: "",
  //   Options: [],
  //   Visibility: true,
  // },
  // {
  //   Column: "ColumnSelector",
  //   Value: [],
  //   Type: "",
  //   Options: [],
  //   Visibility: true,
  // },
];

export const DevicesStorageFilter = [
  // {
  //   Column: "OrderBy",
  //   Value: ["Name"],
  //   Type: "text",
  //   Options: [],
  //   Visibility: false,
  // },
  // {
  //   Column: "XScloScilogicId",
  //   Value: ["ISNOTEMPTY"],
  //   Type: "text",
  //   Options: [],
  //   Visibility: false,
  // },
  // { Column: "Name", Value: [], Type: "text", Options: [], Visibility: true },
  // {
  //   Column: "SysClassName",
  //   Value: [],
  //   Type: "dropdown",
  //   Options: ["Storage Device", "Storage Server", "VMware vCenter Datastore"],
  //   Visibility: true,
  //   DynamicOptions: {
  //     service: "scienceLogicService",
  //     endpoint: "getScienceLogicDeviceFilters",
  //     parameter: { category: "Storage" },
  //     isNull: true,
  //   },
  // },
  {
    Column: 'UServicehubCategory',
    Value: ['Storage'],
    Type: 'text',
    Options: [],
    Visibility: false,
  },
  // {
  //   Column: "firmwareVersion",
  //   Value: [],
  //   Type: "text",
  //   Options: [],
  //   Visibility: true,
  // },
  // {
  //   Column: "SerialNumber",
  //   Value: [],
  //   Type: "text",
  //   Options: [],
  //   Visibility: true,
  // },
  // {
  //   Column: "PollingStatus",
  //   Value: [],
  //   Type: "dropdown",
  //   Options: ["Active", "Paused", "Maintenance", "Unknown"],
  //   Visibility: true,
  // },
  // {
  //   Column: "Availability",
  //   Value: [],
  //   Type: "dropdown",
  //   Options: ["Available", "Unavailable", "Unknown"],
  //   Visibility: true,
  // },
  // {
  //   Column: "AntiVirusInformationResponseName",
  //   Value: [],
  //   Type: "",
  //   Options: [],
  //   Visibility: true,
  // },
  // {
  //   Column: "AntiVirusInformationResponseDefinition",
  //   Value: [],
  //   Type: "",
  //   Options: [],
  //   Visibility: true,
  // },
  // {
  //   Column: "AntiVirusInformationResponseLastUpdated",
  //   Value: [],
  //   Type: "",
  //   Options: [],
  //   Visibility: true,
  // },
  // {
  //   Column: "ColumnSelector",
  //   Value: [],
  //   Type: "",
  //   Options: [],
  //   Visibility: true,
  // },
];

export const DevicesNetworkFilter = [
  // {
  //   Column: "OrderBy",
  //   Value: ["Name"],
  //   Type: "text",
  //   Options: [],
  //   Visibility: false,
  // },
  // {
  //   Column: "XScloScilogicId",
  //   Value: ["ISNOTEMPTY"],
  //   Type: "text",
  //   Options: [],
  //   Visibility: false,
  // },
  // { Column: "Name", Value: [], Type: "text", Options: [], Visibility: true },
  // {
  //   Column: "SysClassName",
  //   Value: [],
  //   Type: "dropdown",
  //   Options: [
  //     "Citrix Gateway",
  //     "IP Camera",
  //     "IP Firewall",
  //     "Network Load Balancer",
  //     "IP Router",
  //     "IP Switch",
  //     "Storage Switch",
  //     "Modem Hardware",
  //   ],
  //   Visibility: true,
  //   DynamicOptions: {
  //     service: "scienceLogicService",
  //     endpoint: "getScienceLogicDeviceFilters",
  //     parameter: { category: "Network" },
  //     isNull: true,
  //   },
  // },
  {
    Column: 'UServicehubCategory',
    Value: ['Network'],
    Type: 'text',
    Options: [],
    Visibility: false,
  },
  // {
  //   Column: "firmwareVersion",
  //   Value: [],
  //   Type: "text",
  //   Options: [],
  //   Visibility: true,
  // },
  // {
  //   Column: "SerialNumber",
  //   Value: [],
  //   Type: "text",
  //   Options: [],
  //   Visibility: true,
  // },
  // {
  //   Column: "PollingStatus",
  //   Value: [],
  //   Type: "dropdown",
  //   Options: ["Active", "Paused", "Maintenance", "Unknown"],
  //   Visibility: true,
  // },
  // {
  //   Column: "Availability",
  //   Value: [],
  //   Type: "dropdown",
  //   Options: ["Available", "Unavailable", "Unknown"],
  //   Visibility: true,
  // },
  // {
  //   Column: "AntiVirusInformationResponseName",
  //   Value: [],
  //   Type: "",
  //   Options: [],
  //   Visibility: true,
  // },
  // {
  //   Column: "AntiVirusInformationResponseDefinition",
  //   Value: [],
  //   Type: "",
  //   Options: [],
  //   Visibility: true,
  // },
  // {
  //   Column: "AntiVirusInformationResponseLastUpdated",
  //   Value: [],
  //   Type: "",
  //   Options: [],
  //   Visibility: true,
  // },
  // {
  //   Column: "ColumnSelector",
  //   Value: [],
  //   Type: "",
  //   Options: [],
  //   Visibility: true,
  // },
];

export const DevicesGeneralFilter = [
  {
    Column: 'OrderBy',
    Value: ['Name'],
    Type: 'text',
    Options: [],
    Visibility: false,
  },
  {
    Column: 'XScloScilogicId',
    Value: ['ISNOTEMPTY'],
    Type: 'text',
    Options: [],
    Visibility: false,
  },
  { Column: 'Name', Value: [], Type: 'text', Options: [], Visibility: true },
  {
    Column: 'SysClassName',
    Value: [],
    Type: 'dropdown',
    Options: ['Generic Network Device', 'PDU', 'UPS'],
    Visibility: true,
    DynamicOptions: {
      service: 'scienceLogicService',
      endpoint: 'getScienceLogicDeviceFilters',
      parameter: { category: 'General' },
      isNull: true,
    },
  },
  {
    Column: 'UServicehubCategory',
    Value: ['General'],
    Type: 'text',
    Options: [],
    Visibility: false,
  },
  {
    Column: 'firmwareVersion',
    Value: [],
    Type: 'text',
    Options: [],
    Visibility: true,
  },
  {
    Column: 'SerialNumber',
    Value: [],
    Type: 'text',
    Options: [],
    Visibility: true,
  },
  {
    Column: 'PollingStatus',
    Value: [],
    Type: 'dropdown',
    Options: ['Active', 'Paused', 'Maintenance', 'Unknown'],
    Visibility: true,
  },
  {
    Column: 'Availability',
    Value: [],
    Type: 'dropdown',
    Options: ['Available', 'Unavailable', 'Unknown'],
    Visibility: true,
  },
  {
    Column: 'AntiVirusInformationResponseName',
    Value: [],
    Type: '',
    Options: [],
    Visibility: true,
  },
  {
    Column: 'AntiVirusInformationResponseDefinition',
    Value: [],
    Type: '',
    Options: [],
    Visibility: true,
  },
  {
    Column: 'AntiVirusInformationResponseLastUpdated',
    Value: [],
    Type: '',
    Options: [],
    Visibility: true,
  },
  {
    Column: 'ColumnSelector',
    Value: [],
    Type: '',
    Options: [],
    Visibility: true,
  },
];

export const BarFilter = [
  {
    Column: 'selectHidden',
    Value: [],
    Type: '',
    Options: [],
    Visibility: true,
  },
  {
    Column: 'BarFilter-mobileNumber',
    Value: [],
    Type: 'text',
    Options: [],
    Visibility: true,
  },
  {
    Column: 'BarFilter-siteUser',
    Value: [],
    Type: 'text',
    Options: [],
    Visibility: true,
  },
];

export const ServicesFilter = [{ Column: 'UHidden', Value: ['false'] }];

export const AddressFinderAnalogueOrderFilters = [
  { Column: 'Name', Value: [], Type: 'text', Options: [], Visibility: true },
  {
    Column: 'UBuildingName',
    Value: [],
    Type: 'text',
    Options: [],
    Visibility: true,
  },
  { Column: 'Street', Value: [], Type: 'text', Options: [], Visibility: true },
  { Column: 'State', Value: [], Type: 'text', Options: [], Visibility: true },
  { Column: 'Zip', Value: [], Type: 'text', Options: [], Visibility: true },
  { Column: 'OrderBy', Value: ['Name'], Options: [], Visibility: false },
  { Column: 'SysId', Value: [], Type: 'text', Options: [], Visibility: false },
];

export const CompanyContactUserFilters = [
  { Column: 'Name', Value: [], Type: 'text', Options: [], Visibility: true },
  { Column: 'Email', Value: [], Type: 'text', Options: [], Visibility: true },
  {
    Column: 'UTimicoPortalRole',
    Value: [],
    Type: 'dropdown',
    Options: [],
    Visibility: true,
    DynamicOptions: {
      service: 'userService',
      endpoint: 'getRoles',
      parameter: '',
    },
  },
  { Column: 'Delete', Value: [], Type: '', Options: [], Visibility: true },
  {
    Column: 'OrderBy',
    Value: ['FirstName'],
    Type: '',
    Options: [],
    Visibility: false,
  },
];

export const AddContactFilters = [
  { Column: 'Name', Value: [], Type: 'text', Options: [], Visibility: true },
  { Column: 'Email', Value: [], Type: 'text', Options: [], Visibility: true },
  {
    Column: 'UTimicoPortalRole',
    Value: [],
    Type: 'dropdown',
    Options: [],
    Visibility: true,
    DynamicOptions: {
      service: 'userService',
      endpoint: 'getRoles',
      parameter: '',
    },
  },
  {
    Column: 'OrderBy',
    Value: ['FirstName'],
    Type: '',
    Options: [],
    Visibility: false,
  },
];

export const SurverysTodayCountFilter = [
  { Column: 'UState', Value: ['=2'] },
  {
    Column: 'SysUpdatedOn',
    Value: [
      'ONTODAY@javascript:gs.beginningOfToday()@javascript:gs.endOfToday()',
    ],
  },
];

export const SurveysYesterdayCountFilter = [
  { Column: 'UState', Value: ['=2'] },
  {
    Column: 'SysUpdatedOn',
    Value: [
      'ONYESTERDAY@javascript:gs.beginningOfYesterday()@javascript:gs.endOfYesterday()',
    ],
  },
];

export const SurveysTotalFilter = [
  { Column: 'UState', Value: ['=2'] },
  {
    Column: 'SysUpdatedOn',
    Value: [
      'ONYESTERDAY@javascript:gs.monthsAgoStart(3)@javascript:gs.endOfToday()',
    ],
  },
];

export const SurveysNpsFilter = [
  { Column: 'Table', Value: ['u_survey'] },
  { Column: 'UState', Value: ['=2'] },
  {
    Column: 'SysUpdatedOn',
    Value: [
      'ONYESTERDAY@javascript:gs.monthsAgoStart(3)@javascript:gs.endOfToday()',
    ],
  },
];

export const LatestSurveysFilter = [
  { Column: 'UCategory', Value: ['=3'], Visibility: false },
  { Column: 'OrderByDesc', Value: ['SysUpdatedOn'], Visibility: false },
  {
    Column: 'UScore',
    Value: ['9', '10'],
    Type: 'text',
    Options: [],
    Visibility: true,
  },
];

export const RagStatusFilter = [
  { Column: 'UCreatedOn', Value: [''], Visibility: true },
  { Column: 'UOverallRelationship', Value: [''], Visibility: true },
  { Column: 'UCustomerService', Value: [''], Visibility: true },
  { Column: 'UProvisioning', Value: [''], Visibility: true },
  { Column: 'UBilling', Value: [''], Visibility: true },
  { Column: 'UNoc', Value: [''], Visibility: true },
  { Column: 'UProjects', Value: [''], Visibility: true },
];

export const SurveyOverviewFilter = [
  { Column: 'OrderByDesc', Value: ['UScore'], Visibility: false },
  {
    Column: 'UUser.Company.SysId',
    Value: ['=29d3545cdb474740bdd1708dae961933'],
    Visibility: false,
  },
  {
    Column: 'UUser.Name',
    Value: [],
    Type: 'text',
    Options: [],
    Visibility: true,
  },
  {
    Column: 'UAssignmentGroup',
    Value: [],
    Type: 'text',
    Options: [],
    Visibility: true,
  },
  { Column: '', Value: [], Type: '', Options: [], Visibility: true },
  { Column: 'UScore', Value: [], Type: 'text', Options: [], Visibility: true },
];

export const TopFiveSurveyUsersFilter = [
  { Column: 'OrderByDesc', Value: ['UScore'] },
  {
    Column: 'UUser.Company.SysId',
    Value: ['=29d3545cdb474740bdd1708dae961933'],
  },
  { Column: 'UTotal', Value: ['>=5'] },
  { Column: 'UAssignmentGroup', Value: ['ISNOTEMPTY'] },
];

export const TopFiveSurveyAssignmentGroupsFilter = [
  { Column: 'OrderByDesc', Value: ['UScore'] },
  { Column: 'UTotal', Value: ['>=5'] },
];

export const TopFiveUsersForAssignmentGroupFitler = [
  { Column: 'UAssignmentGroup', Value: [] },
  { Column: 'OrderByDesc', Value: ['UScore'] },
  { Column: 'UTotal', Value: ['>=5'] },
];

export const CompanyActivityFilters = [
  { Column: 'OrderByDesc', Value: ['SysCreatedOn'] },
  {
    Column: 'SysCreatedBy',
    Value: [],
    Type: 'text',
    Options: [],
    Visibility: true,
  },
  {
    Column: 'UOperation',
    Value: [],
    Type: 'text',
    Options: [],
    Visibility: true,
  },
  {
    Column: 'UMessage',
    Value: [],
    Type: 'text',
    Options: [],
    Visibility: true,
  },
  {
    Column: 'UTask.Number',
    Value: [],
    Type: 'text',
    Options: [],
    Visibility: true,
  },
  {
    Column: 'SysCreatedOn',
    Value: [],
    Type: 'date',
    Options: [],
    Visibility: true,
  },
];

export const AmendUsersSubsitesFilters = [
  { Column: 'OrderByDesc', Value: ['CostCentre'] },
  {
    Column: 'AmendProductCategory',
    Value: [],
    Type: 'text',
    Options: [],
    Visibility: true,
  },
  {
    Column: 'AmendBillingDescription',
    Value: [],
    Type: 'text',
    Options: [],
    Visibility: true,
  },
  {
    Column: 'AmendCLI',
    Value: [],
    Type: 'text',
    Options: [],
    Visibility: true,
  },
  {
    Column: 'AmendCostCentre',
    Value: [],
    Type: 'text',
    Options: [],
    Visibility: true,
  },
  {
    Column: 'AmendSiteUser',
    Value: [],
    Type: 'text',
    Options: [],
    Visibility: true,
  },
];

export const UsersWithRoleFilters = [
  { Column: 'Active', Value: ['true'] },
  { Column: 'UTimicoPortalRole', Value: [] },
];
