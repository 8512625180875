<html lang="en" class="container">
  <head role="banner">
    <title>Error - something went wrong</title>
  </head>
  <main role="main" class="">
    <div class="pl-7 pt-3 w-full bg-white h-5rem" role="navigation">
      <img
        src="https://portal-uat.digitalspace.co.uk/assets/images/timico-logo/service-hub-logo.svg"
        width="218"
        alt="Customer space logo"
        style="
          -ms-interpolation-mode: bicubic;
          border: 0;
          height: auto;
          line-height: 100%;
          outline: none;
          text-decoration: none;
        " />
    </div>
    <div role="region" class="inline-flex align-items: center container">
      <div role="group" class="w-8 pl-8 pr-5 pt-8">
        <h1 class="text-3xl font-medium">Oops, something went wrong.</h1>
        <p class="text-xl">
          There could be a misconfiguration in the system or a service
          outage.<br />
          Please try to log in again and if the problem persists please contact
          us.
        </p>
        <div class="btns" role="group">
          <a href="https://portal.digitalspace.co.uk/">
            <p-button label="Log in ServiceHub"></p-button
          ></a>
          <a href="https://cmp.digitalspace.co.uk/"
            ><p-button
              class="btn-retry"
              label="Log in Cloud Monitoring"></p-button
          ></a>

          <a href="https://www.digitalspace.co.uk/contact/"
            ><p-button
              label="Contact us"
              styleClass="p-button-secondary"></p-button
          ></a>
        </div>
      </div>
      <div class="img" role="img" aria-label="server error" alt="server error">
        <svg
          class="animated"
          id="svg-internal-server-error"
          xmlns="http://www.w3.org/500/svg"
          viewBox="0 0 500 500"
          version="1.1"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xmlns:svgjs="http://svgjs.com/svgjs">
          <g
            id="freepik--background-complete--inject-15"
            class="animable"
            style="transform-origin: 250px 210.77px">
            <g id="elr4jsnbcja9">
              <rect
                x="417.41"
                y="60.85"
                width="26.48"
                height="11.19"
                style="
                  fill: none;
                  stroke: rgb(235, 235, 235);
                  stroke-miterlimit: 10;
                  stroke-width: 0.75px;
                  transform-origin: 430.65px 66.445px;
                  transform: rotate(-180deg);
                "
                class="animable"
                id="elyd5dq684at"></rect>
            </g>
            <g id="el5nch2e3lzpb">
              <rect
                x="432.18"
                y="46.74"
                width="26.48"
                height="11.19"
                style="
                  fill: none;
                  stroke: rgb(235, 235, 235);
                  stroke-miterlimit: 10;
                  stroke-width: 0.75px;
                  transform-origin: 445.42px 52.335px;
                  transform: rotate(180deg);
                "
                class="animable"
                id="elczaktobnm4"></rect>
            </g>
            <g id="elvsfdnel3a2h">
              <rect
                x="250"
                y="170.97"
                width="26.48"
                height="11.19"
                style="
                  fill: none;
                  stroke: rgb(235, 235, 235);
                  stroke-miterlimit: 10;
                  stroke-width: 0.75px;
                  transform-origin: 263.24px 176.565px;
                  transform: rotate(180deg);
                "
                class="animable"
                id="el37nu7rttsyy"></rect>
            </g>
            <g id="elkd05bnuruqo">
              <rect
                x="264.77"
                y="156.86"
                width="26.48"
                height="11.19"
                style="
                  fill: none;
                  stroke: rgb(235, 235, 235);
                  stroke-miterlimit: 10;
                  stroke-width: 0.75px;
                  transform-origin: 278.01px 162.455px;
                  transform: rotate(180deg);
                "
                class="animable"
                id="elpdj6janq3ic"></rect>
            </g>
            <g id="ely649ss8o4sn">
              <rect
                x="279.49"
                y="170.97"
                width="26.48"
                height="11.19"
                style="
                  fill: none;
                  stroke: rgb(235, 235, 235);
                  stroke-miterlimit: 10;
                  stroke-width: 0.75px;
                  transform-origin: 292.73px 176.565px;
                  transform: rotate(180deg);
                "
                class="animable"
                id="el4itvbbygoz4"></rect>
            </g>
            <g id="elngtmnlnodzs">
              <rect
                x="41.34"
                y="46.74"
                width="26.48"
                height="11.19"
                style="
                  fill: none;
                  stroke: rgb(235, 235, 235);
                  stroke-miterlimit: 10;
                  stroke-width: 0.75px;
                  transform-origin: 54.58px 52.335px;
                  transform: rotate(180deg);
                "
                class="animable"
                id="elbbg4j4rd8kf"></rect>
            </g>
            <g id="elxfmpxddi4c">
              <rect
                x="56.11"
                y="309.85"
                width="26.48"
                height="11.19"
                style="
                  fill: none;
                  stroke: rgb(235, 235, 235);
                  stroke-miterlimit: 10;
                  stroke-width: 0.75px;
                  transform-origin: 69.35px 315.445px;
                  transform: rotate(180deg);
                "
                class="animable"
                id="elfpouwsgaghe"></rect>
            </g>
            <g id="el5cmnuv3owg2">
              <rect
                x="41.34"
                y="295.74"
                width="26.48"
                height="11.19"
                style="
                  fill: none;
                  stroke: rgb(235, 235, 235);
                  stroke-miterlimit: 10;
                  stroke-width: 0.75px;
                  transform-origin: 54.58px 301.335px;
                  transform: rotate(180deg);
                "
                class="animable"
                id="ellivjzm6jl7r"></rect>
            </g>
            <g id="elyxrok1w08e">
              <rect
                x="417.41"
                y="309.85"
                width="26.48"
                height="11.19"
                style="
                  fill: none;
                  stroke: rgb(235, 235, 235);
                  stroke-miterlimit: 10;
                  stroke-width: 0.75px;
                  transform-origin: 430.65px 315.445px;
                  transform: rotate(-180deg);
                "
                class="animable"
                id="elixif9ti2czh"></rect>
            </g>
            <polyline
              points="208.82 421.54 208.82 295.88 235.55 259.39 235.55 191.06"
              style="
                fill: none;
                stroke: rgb(235, 235, 235);
                stroke-miterlimit: 10;
                transform-origin: 222.185px 306.3px;
              "
              id="elkxdpp2v7wfk"
              class="animable"></polyline>
            <path
              d="M233.18,188.73a2.38,2.38,0,1,0,2.37-2.38A2.37,2.37,0,0,0,233.18,188.73Z"
              style="
                fill: none;
                stroke: rgb(235, 235, 235);
                stroke-miterlimit: 10;
                transform-origin: 235.56px 188.73px;
              "
              id="el5flgrlav3nx"
              class="animable"></path>
            <polyline
              points="215.71 421.54 215.71 300.29 242.44 263.8 242.44 216.06"
              style="
                fill: none;
                stroke: rgb(235, 235, 235);
                stroke-miterlimit: 10;
                transform-origin: 229.075px 318.8px;
              "
              id="elrrads4ivdhl"
              class="animable"></polyline>
            <path
              d="M240.06,213.72a2.38,2.38,0,1,0,2.38-2.37A2.38,2.38,0,0,0,240.06,213.72Z"
              style="
                fill: none;
                stroke: rgb(235, 235, 235);
                stroke-miterlimit: 10;
                transform-origin: 242.44px 213.73px;
              "
              id="el0x1u39jojysc"
              class="animable"></path>
            <line
              x1="202.19"
              y1="421.54"
              x2="202.19"
              y2="255.13"
              style="
                fill: none;
                stroke: rgb(235, 235, 235);
                stroke-miterlimit: 10;
                transform-origin: 202.19px 338.335px;
              "
              id="elj3mqs8a6npc"
              class="animable"></line>
            <path
              d="M199.81,252.75a2.38,2.38,0,1,0,2.38-2.37A2.38,2.38,0,0,0,199.81,252.75Z"
              style="
                fill: none;
                stroke: rgb(235, 235, 235);
                stroke-miterlimit: 10;
                transform-origin: 202.19px 252.76px;
              "
              id="elmj563inqpkd"
              class="animable"></path>
            <polyline
              points="195.8 421.54 195.8 336.96 177.66 302.74 177.66 241.37"
              style="
                fill: none;
                stroke: rgb(235, 235, 235);
                stroke-miterlimit: 10;
                transform-origin: 186.73px 331.455px;
              "
              id="elj9edhxqevve"
              class="animable"></polyline>
            <path
              d="M175.28,239a2.38,2.38,0,1,0,2.38-2.38A2.37,2.37,0,0,0,175.28,239Z"
              style="
                fill: none;
                stroke: rgb(235, 235, 235);
                stroke-miterlimit: 10;
                transform-origin: 177.66px 239px;
              "
              id="eloa87scsp9bd"
              class="animable"></path>
            <polyline
              points="189.45 421.54 189.45 340.62 168.06 302.78 168.06 262.37"
              style="
                fill: none;
                stroke: rgb(235, 235, 235);
                stroke-miterlimit: 10;
                transform-origin: 178.755px 341.955px;
              "
              id="el23jrgr7fkcc"
              class="animable"></polyline>
            <path
              d="M165.68,260a2.38,2.38,0,1,0,2.38-2.37A2.39,2.39,0,0,0,165.68,260Z"
              style="
                fill: none;
                stroke: rgb(235, 235, 235);
                stroke-miterlimit: 10;
                transform-origin: 168.06px 260.01px;
              "
              id="elxkjvu2p28c"
              class="animable"></path>
            <line
              x1="110.72"
              y1="37.56"
              x2="110.72"
              style="
                fill: none;
                stroke: rgb(199, 199, 199);
                stroke-miterlimit: 10;
                transform-origin: 110.72px 18.78px;
              "
              id="el1xq4azsww0g"
              class="animable"></line>
            <polygon
              points="133.93 59.92 132.06 64.03 89.38 64.03 87.52 59.92 105.69 47.35 115.76 47.35 133.93 59.92"
              style="
                fill: rgb(235, 235, 235);
                transform-origin: 110.725px 55.69px;
              "
              id="eldkf7udgogr8"
              class="animable"></polygon>
            <polygon
              points="117.02 35.23 115.76 47.35 105.69 47.35 104.43 35.23 117.02 35.23"
              style="
                fill: rgb(219, 219, 219);
                transform-origin: 110.725px 41.29px;
              "
              id="elhzfmscfj1t"
              class="animable"></polygon>
            <polygon
              points="133.93 59.93 132.06 64.03 89.38 64.03 87.52 59.93 133.93 59.93"
              style="
                fill: rgb(219, 219, 219);
                transform-origin: 110.725px 61.98px;
              "
              id="ely0v77if9w"
              class="animable"></polygon>
            <line
              x1="203.58"
              y1="37.56"
              x2="203.58"
              style="
                fill: none;
                stroke: rgb(199, 199, 199);
                stroke-miterlimit: 10;
                transform-origin: 203.58px 18.78px;
              "
              id="el87f8eosi16u"
              class="animable"></line>
            <polygon
              points="226.78 59.92 224.91 64.03 182.23 64.03 180.37 59.92 198.54 47.35 208.61 47.35 226.78 59.92"
              style="
                fill: rgb(235, 235, 235);
                transform-origin: 203.575px 55.69px;
              "
              id="elvtru4c40zp9"
              class="animable"></polygon>
            <polygon
              points="209.87 35.23 208.61 47.35 198.54 47.35 197.28 35.23 209.87 35.23"
              style="
                fill: rgb(219, 219, 219);
                transform-origin: 203.575px 41.29px;
              "
              id="el16hbgid17ti"
              class="animable"></polygon>
            <polygon
              points="226.78 59.93 224.91 64.03 182.23 64.03 180.37 59.93 226.78 59.93"
              style="
                fill: rgb(219, 219, 219);
                transform-origin: 203.575px 61.98px;
              "
              id="elblrqest9k2l"
              class="animable"></polygon>
            <line
              x1="296.43"
              y1="37.56"
              x2="296.43"
              style="
                fill: none;
                stroke: rgb(199, 199, 199);
                stroke-miterlimit: 10;
                transform-origin: 296.43px 18.78px;
              "
              id="elnhw1gjzgk9"
              class="animable"></line>
            <polygon
              points="319.63 59.92 317.77 64.03 275.08 64.03 273.22 59.92 291.39 47.35 301.46 47.35 319.63 59.92"
              style="
                fill: rgb(235, 235, 235);
                transform-origin: 296.425px 55.69px;
              "
              id="elszislbud3mb"
              class="animable"></polygon>
            <polygon
              points="302.72 35.23 301.46 47.35 291.39 47.35 290.13 35.23 302.72 35.23"
              style="
                fill: rgb(219, 219, 219);
                transform-origin: 296.425px 41.29px;
              "
              id="eltqiw5tzp8yr"
              class="animable"></polygon>
            <polygon
              points="319.63 59.93 317.76 64.03 275.08 64.03 273.22 59.93 319.63 59.93"
              style="
                fill: rgb(219, 219, 219);
                transform-origin: 296.425px 61.98px;
              "
              id="elfewkeoqsol"
              class="animable"></polygon>
            <line
              x1="389.28"
              y1="37.56"
              x2="389.28"
              style="
                fill: none;
                stroke: rgb(199, 199, 199);
                stroke-miterlimit: 10;
                transform-origin: 389.28px 18.78px;
              "
              id="elyo256i5h13p"
              class="animable"></line>
            <polygon
              points="412.49 59.92 410.62 64.03 367.93 64.03 366.07 59.92 384.25 47.35 394.31 47.35 412.49 59.92"
              style="
                fill: rgb(235, 235, 235);
                transform-origin: 389.28px 55.69px;
              "
              id="eluk7tvcqa3m"
              class="animable"></polygon>
            <polygon
              points="395.57 35.23 394.31 47.35 384.25 47.35 382.98 35.23 395.57 35.23"
              style="
                fill: rgb(219, 219, 219);
                transform-origin: 389.275px 41.29px;
              "
              id="elben1555i9re"
              class="animable"></polygon>
            <polygon
              points="412.48 59.93 410.62 64.03 367.93 64.03 366.07 59.93 412.48 59.93"
              style="
                fill: rgb(219, 219, 219);
                transform-origin: 389.275px 61.98px;
              "
              id="elva3fignx6ei"
              class="animable"></polygon>
          </g>
          <g
            id="freepik--Shadow--inject-15"
            class="animable"
            style="transform-origin: 183.79px 443.41px">
            <ellipse
              cx="183.79"
              cy="443.41"
              rx="106.41"
              ry="16.99"
              style="
                fill: rgb(235, 235, 235);
                transform-origin: 183.79px 443.41px;
              "
              id="elz1z1jyw5ez"
              class="animable"></ellipse>
          </g>
          <g
            id="freepik--Server--inject-15"
            class="animable"
            style="transform-origin: 359.71px 295.225px">
            <rect
              x="267.37"
              y="176.42"
              width="65.02"
              height="245.17"
              style="
                fill: rgb(55, 71, 79);
                transform-origin: 299.88px 299.005px;
              "
              id="el1crdb6mlinh"
              class="animable"></rect>
            <rect
              x="332.39"
              y="176.42"
              width="119.66"
              height="245.17"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 392.22px 299.005px;
              "
              id="ellzj2vxc5ve"
              class="animable"></rect>
            <rect
              x="341.37"
              y="389.79"
              width="103.21"
              height="23.84"
              style="
                fill: rgb(55, 71, 79);
                transform-origin: 392.975px 401.71px;
              "
              id="elkao61pj2cyp"
              class="animable"></rect>
            <rect
              x="345.71"
              y="393.73"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 394.735px;
              "
              id="el8gr4wkt37fj"
              class="animable"></rect>
            <rect
              x="345.71"
              y="397.22"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 398.225px;
              "
              id="el5lqcdwkrmyq"
              class="animable"></rect>
            <rect
              x="345.71"
              y="400.71"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 401.715px;
              "
              id="elni9jr9lj2ki"
              class="animable"></rect>
            <rect
              x="345.71"
              y="404.19"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 405.195px;
              "
              id="elm08k2409oh8"
              class="animable"></rect>
            <rect
              x="345.71"
              y="407.68"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 408.685px;
              "
              id="eltxziidyqa"
              class="animable"></rect>
            <path
              d="M415.17,395.73a2,2,0,1,1-2-2A2,2,0,0,1,415.17,395.73Z"
              style="fill: #fb8fbf; transform-origin: 413.17px 395.73px"
              id="elhs0r6h0gjy6"
              class="animable"></path>
            <path
              d="M421.75,395.73a2,2,0,1,1-2-2A2,2,0,0,1,421.75,395.73Z"
              style="fill: #fb8fbf; transform-origin: 419.75px 395.73px"
              id="elhy7hchiwbtk"
              class="animable"></path>
            <path
              d="M428.33,395.73a2,2,0,1,1-2-2A2,2,0,0,1,428.33,395.73Z"
              style="fill: #fb8fbf; transform-origin: 426.33px 395.73px"
              id="elo2dxct3jq3a"
              class="animable"></path>
            <path
              d="M434.9,395.73a2,2,0,1,1-2-2A2,2,0,0,1,434.9,395.73Z"
              style="fill: #fb8fbf; transform-origin: 432.9px 395.73px"
              id="el2gyuln12idf"
              class="animable"></path>
            <path
              d="M441.48,395.73a2,2,0,1,1-2-2A2,2,0,0,1,441.48,395.73Z"
              style="fill: #fb8fbf; transform-origin: 439.48px 395.73px"
              id="elgg9iw2w30rt"
              class="animable"></path>
            <path
              d="M415.17,401.71a2,2,0,1,1-2-2A2,2,0,0,1,415.17,401.71Z"
              style="fill: #fb8fbf; transform-origin: 413.17px 401.71px"
              id="el0wpmhr67hqli"
              class="animable"></path>
            <path
              d="M421.75,401.71a2,2,0,1,1-2-2A2,2,0,0,1,421.75,401.71Z"
              style="fill: #fb8fbf; transform-origin: 419.75px 401.71px"
              id="el8nqbxfaon4m"
              class="animable"></path>
            <path
              d="M428.33,401.71a2,2,0,1,1-2-2A2,2,0,0,1,428.33,401.71Z"
              style="fill: #fb8fbf; transform-origin: 426.33px 401.71px"
              id="eljn1g8l9cses"
              class="animable"></path>
            <path
              d="M434.9,401.71a2,2,0,1,1-2-2A2,2,0,0,1,434.9,401.71Z"
              style="fill: #fb8fbf; transform-origin: 432.9px 401.71px"
              id="elfjw7zexbugt"
              class="animable"></path>
            <path
              d="M441.48,401.71a2,2,0,1,1-2-2A2,2,0,0,1,441.48,401.71Z"
              style="fill: #fb8fbf; transform-origin: 439.48px 401.71px"
              id="el7n47pm7r1at"
              class="animable"></path>
            <circle
              cx="413.18"
              cy="407.7"
              r="2"
              style="fill: #28ddcf; transform-origin: 413.18px 407.7px"
              id="el30nkvuq3vjg"
              class="animable"></circle>
            <path
              d="M421.75,407.7a2,2,0,1,1-2-2A2,2,0,0,1,421.75,407.7Z"
              style="fill: #fb8fbf; transform-origin: 419.75px 407.7px"
              id="elarpxtux5yg9"
              class="animable"></path>
            <circle
              cx="426.33"
              cy="407.7"
              r="2"
              style="fill: #28ddcf; transform-origin: 426.33px 407.7px"
              id="elt24s2vw6uq"
              class="animable"></circle>
            <path
              d="M434.9,407.7a2,2,0,1,1-2-2A2,2,0,0,1,434.9,407.7Z"
              style="fill: #fb8fbf; transform-origin: 432.9px 407.7px"
              id="elnjw0m2k4wk"
              class="animable"></path>
            <path
              d="M441.48,407.7a2,2,0,1,1-2-2A2,2,0,0,1,441.48,407.7Z"
              style="fill: #fb8fbf; transform-origin: 439.48px 407.7px"
              id="elqy7lcoq7sy"
              class="animable"></path>
            <rect
              x="407.4"
              y="393.73"
              width="1.15"
              height="15.96"
              style="
                fill: rgb(38, 50, 56);
                transform-origin: 407.975px 401.71px;
              "
              id="el5ox5dk1ht33"
              class="animable"></rect>
            <rect
              x="341.37"
              y="361.07"
              width="103.21"
              height="23.84"
              style="
                fill: rgb(55, 71, 79);
                transform-origin: 392.975px 372.99px;
              "
              id="elt594sjxeife"
              class="animable"></rect>
            <rect
              x="345.71"
              y="365"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 366.005px;
              "
              id="elvqfxjnf7p1b"
              class="animable"></rect>
            <rect
              x="345.71"
              y="368.49"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 369.495px;
              "
              id="elwwny0g7e7nq"
              class="animable"></rect>
            <rect
              x="345.71"
              y="371.98"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 372.985px;
              "
              id="elih9uk0qajgl"
              class="animable"></rect>
            <rect
              x="345.71"
              y="375.47"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 376.475px;
              "
              id="ellraxd89f7ol"
              class="animable"></rect>
            <rect
              x="345.71"
              y="378.95"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 379.955px;
              "
              id="elc0j2wg24i28"
              class="animable"></rect>
            <path
              d="M415.17,367a2,2,0,1,1-2-2A2,2,0,0,1,415.17,367Z"
              style="fill: #fb8fbf; transform-origin: 413.17px 367px"
              id="elu8gt28qnqa"
              class="animable"></path>
            <path
              d="M421.75,367a2,2,0,1,1-2-2A2,2,0,0,1,421.75,367Z"
              style="fill: #fb8fbf; transform-origin: 419.75px 367px"
              id="el6qq6c4bkreq"
              class="animable"></path>
            <path
              d="M428.33,367a2,2,0,1,1-2-2A2,2,0,0,1,428.33,367Z"
              style="fill: #fb8fbf; transform-origin: 426.33px 367px"
              id="elhwn97bi6ulr"
              class="animable"></path>
            <path
              d="M434.9,367a2,2,0,1,1-2-2A2,2,0,0,1,434.9,367Z"
              style="fill: #fb8fbf; transform-origin: 432.9px 367px"
              id="elat40lil9qr"
              class="animable"></path>
            <path
              d="M441.48,367a2,2,0,1,1-2-2A2,2,0,0,1,441.48,367Z"
              style="fill: #fb8fbf; transform-origin: 439.48px 367px"
              id="eliq494bxqpg"
              class="animable"></path>
            <path
              d="M415.17,373a2,2,0,1,1-2-2A2,2,0,0,1,415.17,373Z"
              style="fill: #fb8fbf; transform-origin: 413.17px 373px"
              id="eltu41omebsu"
              class="animable"></path>
            <path
              d="M421.75,373a2,2,0,1,1-2-2A2,2,0,0,1,421.75,373Z"
              style="fill: #fb8fbf; transform-origin: 419.75px 373px"
              id="el704170no3ma"
              class="animable"></path>
            <path
              d="M428.33,373a2,2,0,1,1-2-2A2,2,0,0,1,428.33,373Z"
              style="fill: #fb8fbf; transform-origin: 426.33px 373px"
              id="elgz73iz8tn9"
              class="animable"></path>
            <path
              d="M434.9,373a2,2,0,1,1-2-2A2,2,0,0,1,434.9,373Z"
              style="fill: #fb8fbf; transform-origin: 432.9px 373px"
              id="elhe6m5eq0bps"
              class="animable"></path>
            <path
              d="M441.48,373a2,2,0,1,1-2-2A2,2,0,0,1,441.48,373Z"
              style="fill: #fb8fbf; transform-origin: 439.48px 373px"
              id="el8ne1ezp27n9"
              class="animable"></path>
            <path
              d="M415.17,379a2,2,0,1,1-2-2A2,2,0,0,1,415.17,379Z"
              style="fill: #fb8fbf; transform-origin: 413.17px 379px"
              id="elp9orwpwu1og"
              class="animable"></path>
            <path
              d="M421.75,379a2,2,0,1,1-2-2A2,2,0,0,1,421.75,379Z"
              style="fill: #fb8fbf; transform-origin: 419.75px 379px"
              id="elr6477amo96"
              class="animable"></path>
            <path
              d="M428.33,379a2,2,0,1,1-2-2A2,2,0,0,1,428.33,379Z"
              style="fill: #fb8fbf; transform-origin: 426.33px 379px"
              id="el3u27w31zqzh"
              class="animable"></path>
            <path
              d="M434.9,379a2,2,0,1,1-2-2A2,2,0,0,1,434.9,379Z"
              style="fill: #fb8fbf; transform-origin: 432.9px 379px"
              id="elpce9rfg2x1e"
              class="animable"></path>
            <path
              d="M441.48,379a2,2,0,1,1-2-2A2,2,0,0,1,441.48,379Z"
              style="fill: #fb8fbf; transform-origin: 439.48px 379px"
              id="elvhbjalvwxqk"
              class="animable"></path>
            <rect
              x="407.4"
              y="365"
              width="1.15"
              height="15.96"
              style="
                fill: rgb(38, 50, 56);
                transform-origin: 407.975px 372.98px;
              "
              id="eltmoyre695gs"
              class="animable"></rect>
            <rect
              x="341.37"
              y="332.34"
              width="103.21"
              height="23.84"
              style="
                fill: rgb(55, 71, 79);
                transform-origin: 392.975px 344.26px;
              "
              id="el8k2ordcxpox"
              class="animable"></rect>
            <rect
              x="345.71"
              y="336.28"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 337.285px;
              "
              id="el7g9pb33ncb"
              class="animable"></rect>
            <rect
              x="345.71"
              y="339.76"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 340.765px;
              "
              id="elpyshjrdu1sd"
              class="animable"></rect>
            <rect
              x="345.71"
              y="343.25"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 344.255px;
              "
              id="elggrr2bz77wf"
              class="animable"></rect>
            <rect
              x="345.71"
              y="346.74"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 347.745px;
              "
              id="el7neokgexh9"
              class="animable"></rect>
            <rect
              x="345.71"
              y="350.23"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 351.235px;
              "
              id="elib4s66ebfu"
              class="animable"></rect>
            <circle
              cx="413.18"
              cy="338.27"
              r="2"
              style="fill: #28ddcf; transform-origin: 413.18px 338.27px"
              id="el0l1vfusphxrc"
              class="animable"></circle>
            <path
              d="M421.75,338.27a2,2,0,1,1-2-2A2,2,0,0,1,421.75,338.27Z"
              style="fill: #fb8fbf; transform-origin: 419.75px 338.27px"
              id="elk98r67uzkz"
              class="animable"></path>
            <circle
              cx="426.33"
              cy="338.27"
              r="2"
              style="fill: #28ddcf; transform-origin: 426.33px 338.27px"
              id="elvh3dqggfi1b"
              class="animable"></circle>
            <path
              d="M434.9,338.27a2,2,0,1,1-2-2A2,2,0,0,1,434.9,338.27Z"
              style="fill: #fb8fbf; transform-origin: 432.9px 338.27px"
              id="elvcnt3y8z6vm"
              class="animable"></path>
            <path
              d="M441.48,338.27a2,2,0,1,1-2-2A2,2,0,0,1,441.48,338.27Z"
              style="fill: #fb8fbf; transform-origin: 439.48px 338.27px"
              id="elemyecj24st"
              class="animable"></path>
            <path
              d="M415.17,344.26a2,2,0,1,1-2-2A2,2,0,0,1,415.17,344.26Z"
              style="fill: #fb8fbf; transform-origin: 413.17px 344.26px"
              id="eln6j4h00y0v8"
              class="animable"></path>
            <path
              d="M421.75,344.26a2,2,0,1,1-2-2A2,2,0,0,1,421.75,344.26Z"
              style="fill: #fb8fbf; transform-origin: 419.75px 344.26px"
              id="elumzch5cbj6d"
              class="animable"></path>
            <path
              d="M428.33,344.26a2,2,0,1,1-2-2A2,2,0,0,1,428.33,344.26Z"
              style="fill: #fb8fbf; transform-origin: 426.33px 344.26px"
              id="elvyn81ydbjno"
              class="animable"></path>
            <path
              d="M434.9,344.26a2,2,0,1,1-2-2A2,2,0,0,1,434.9,344.26Z"
              style="fill: #fb8fbf; transform-origin: 432.9px 344.26px"
              id="elg0fng71q5kn"
              class="animable"></path>
            <path
              d="M441.48,344.26a2,2,0,1,1-2-2A2,2,0,0,1,441.48,344.26Z"
              style="fill: #fb8fbf; transform-origin: 439.48px 344.26px"
              id="eld9i1qjh4pbg"
              class="animable"></path>
            <path
              d="M415.17,350.24a2,2,0,1,1-2-2A2,2,0,0,1,415.17,350.24Z"
              style="fill: #fb8fbf; transform-origin: 413.17px 350.24px"
              id="elot5tonl2fnj"
              class="animable"></path>
            <path
              d="M421.75,350.24a2,2,0,1,1-2-2A2,2,0,0,1,421.75,350.24Z"
              style="fill: #fb8fbf; transform-origin: 419.75px 350.24px"
              id="elt19y4vdibbi"
              class="animable"></path>
            <path
              d="M428.33,350.24a2,2,0,1,1-2-2A2,2,0,0,1,428.33,350.24Z"
              style="fill: #fb8fbf; transform-origin: 426.33px 350.24px"
              id="elh31j8bfqmpm"
              class="animable"></path>
            <path
              d="M434.9,350.24a2,2,0,1,1-2-2A2,2,0,0,1,434.9,350.24Z"
              style="fill: #fb8fbf; transform-origin: 432.9px 350.24px"
              id="elgixgz72xjzj"
              class="animable"></path>
            <path
              d="M441.48,350.24a2,2,0,1,1-2-2A2,2,0,0,1,441.48,350.24Z"
              style="fill: #fb8fbf; transform-origin: 439.48px 350.24px"
              id="el6202ovliasp"
              class="animable"></path>
            <rect
              x="407.4"
              y="336.28"
              width="1.15"
              height="15.96"
              style="
                fill: rgb(38, 50, 56);
                transform-origin: 407.975px 344.26px;
              "
              id="elv6y3y34by77"
              class="animable"></rect>
            <rect
              x="341.37"
              y="303.61"
              width="103.21"
              height="23.84"
              style="
                fill: rgb(55, 71, 79);
                transform-origin: 392.975px 315.53px;
              "
              id="elbbc5qwlyrmm"
              class="animable"></rect>
            <rect
              x="345.71"
              y="307.55"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 308.555px;
              "
              id="elck8x12gsyzd"
              class="animable"></rect>
            <rect
              x="345.71"
              y="311.03"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 312.035px;
              "
              id="el2m1r3fgqtkb"
              class="animable"></rect>
            <rect
              x="345.71"
              y="314.52"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 315.525px;
              "
              id="ele441k4yktl"
              class="animable"></rect>
            <rect
              x="345.71"
              y="318.01"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 319.015px;
              "
              id="elyknhg251djf"
              class="animable"></rect>
            <rect
              x="345.71"
              y="321.5"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 322.505px;
              "
              id="eltzi672n2b6"
              class="animable"></rect>
            <path
              d="M415.17,309.54a2,2,0,1,1-2-2A2,2,0,0,1,415.17,309.54Z"
              style="fill: #fb8fbf; transform-origin: 413.17px 309.54px"
              id="elrh01ffve9je"
              class="animable"></path>
            <path
              d="M421.75,309.54a2,2,0,1,1-2-2A2,2,0,0,1,421.75,309.54Z"
              style="fill: #fb8fbf; transform-origin: 419.75px 309.54px"
              id="elchvwvfuo6xm"
              class="animable"></path>
            <path
              d="M428.33,309.54a2,2,0,1,1-2-2A2,2,0,0,1,428.33,309.54Z"
              style="fill: #fb8fbf; transform-origin: 426.33px 309.54px"
              id="eldep5j0kha8"
              class="animable"></path>
            <path
              d="M434.9,309.54a2,2,0,1,1-2-2A2,2,0,0,1,434.9,309.54Z"
              style="fill: #fb8fbf; transform-origin: 432.9px 309.54px"
              id="elcq7suzxp38p"
              class="animable"></path>
            <path
              d="M441.48,309.54a2,2,0,1,1-2-2A2,2,0,0,1,441.48,309.54Z"
              style="fill: #fb8fbf; transform-origin: 439.48px 309.54px"
              id="elpohwpcpcvhj"
              class="animable"></path>
            <circle
              cx="413.18"
              cy="315.53"
              r="2"
              style="fill: #28ddcf; transform-origin: 413.18px 315.53px"
              id="elvuxpiqzgu7m"
              class="animable"></circle>
            <path
              d="M421.75,315.53a2,2,0,1,1-2-2A2,2,0,0,1,421.75,315.53Z"
              style="fill: #fb8fbf; transform-origin: 419.75px 315.53px"
              id="elnuta5eki4f"
              class="animable"></path>
            <circle
              cx="426.33"
              cy="315.53"
              r="2"
              style="fill: #28ddcf; transform-origin: 426.33px 315.53px"
              id="eltptoifg940k"
              class="animable"></circle>
            <path
              d="M434.9,315.53a2,2,0,1,1-2-2A2,2,0,0,1,434.9,315.53Z"
              style="fill: #fb8fbf; transform-origin: 432.9px 315.53px"
              id="elnhlazynsuc"
              class="animable"></path>
            <path
              d="M441.48,315.53a2,2,0,1,1-2-2A2,2,0,0,1,441.48,315.53Z"
              style="fill: #fb8fbf; transform-origin: 439.48px 315.53px"
              id="elac763onuasg"
              class="animable"></path>
            <circle
              cx="413.18"
              cy="321.51"
              r="2"
              style="fill: #28ddcf; transform-origin: 413.18px 321.51px"
              id="elx79qd1yphsb"
              class="animable"></circle>
            <path
              d="M421.75,321.51a2,2,0,1,1-2-2A2,2,0,0,1,421.75,321.51Z"
              style="fill: #fb8fbf; transform-origin: 419.75px 321.51px"
              id="ell1c9v43zybr"
              class="animable"></path>
            <circle
              cx="426.33"
              cy="321.51"
              r="2"
              style="fill: #28ddcf; transform-origin: 426.33px 321.51px"
              id="elht4lyl18f7l"
              class="animable"></circle>
            <path
              d="M434.9,321.51a2,2,0,1,1-2-2A2,2,0,0,1,434.9,321.51Z"
              style="fill: #fb8fbf; transform-origin: 432.9px 321.51px"
              id="elt2wwqez63kd"
              class="animable"></path>
            <path
              d="M441.48,321.51a2,2,0,1,1-2-2A2,2,0,0,1,441.48,321.51Z"
              style="fill: #fb8fbf; transform-origin: 439.48px 321.51px"
              id="elafevcidcz"
              class="animable"></path>
            <rect
              x="407.4"
              y="307.55"
              width="1.15"
              height="15.96"
              style="
                fill: rgb(38, 50, 56);
                transform-origin: 407.975px 315.53px;
              "
              id="elznnlumrchwd"
              class="animable"></rect>
            <rect
              x="341.37"
              y="274.88"
              width="103.21"
              height="23.84"
              style="fill: rgb(55, 71, 79); transform-origin: 392.975px 286.8px"
              id="el9eounxqat6i"
              class="animable"></rect>
            <rect
              x="345.71"
              y="278.82"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 279.825px;
              "
              id="elr4va0ljr2ch"
              class="animable"></rect>
            <rect
              x="345.71"
              y="282.31"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 283.315px;
              "
              id="elr1ml13ip1o"
              class="animable"></rect>
            <rect
              x="345.71"
              y="285.79"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 286.795px;
              "
              id="elvy2ph4266h"
              class="animable"></rect>
            <rect
              x="345.71"
              y="289.28"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 290.285px;
              "
              id="el9xhxg085bk9"
              class="animable"></rect>
            <rect
              x="345.71"
              y="292.77"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 293.775px;
              "
              id="elug1wmnvd07"
              class="animable"></rect>
            <circle
              cx="413.18"
              cy="280.82"
              r="2"
              style="fill: #28ddcf; transform-origin: 413.18px 280.82px"
              id="el7ltjs6p9uek"
              class="animable"></circle>
            <path
              d="M421.75,280.82a2,2,0,1,1-2-2A2,2,0,0,1,421.75,280.82Z"
              style="fill: #fb8fbf; transform-origin: 419.75px 280.82px"
              id="el4bu86l7f9it"
              class="animable"></path>
            <circle
              cx="426.33"
              cy="280.82"
              r="2"
              style="fill: #28ddcf; transform-origin: 426.33px 280.82px"
              id="eld40n1xusdoq"
              class="animable"></circle>
            <path
              d="M434.9,280.82a2,2,0,1,1-2-2A2,2,0,0,1,434.9,280.82Z"
              style="fill: #fb8fbf; transform-origin: 432.9px 280.82px"
              id="elayrs25nbvsp"
              class="animable"></path>
            <path
              d="M441.48,280.82a2,2,0,1,1-2-2A2,2,0,0,1,441.48,280.82Z"
              style="fill: #fb8fbf; transform-origin: 439.48px 280.82px"
              id="elmo26zy1wkaa"
              class="animable"></path>
            <circle
              cx="413.18"
              cy="286.8"
              r="2"
              style="fill: #28ddcf; transform-origin: 413.18px 286.8px"
              id="elccbk0thzvnc"
              class="animable"></circle>
            <path
              d="M421.75,286.8a2,2,0,1,1-2-2A2,2,0,0,1,421.75,286.8Z"
              style="fill: #fb8fbf; transform-origin: 419.75px 286.8px"
              id="elwzzrqv1sswd"
              class="animable"></path>
            <circle
              cx="426.33"
              cy="286.8"
              r="2"
              style="fill: #28ddcf; transform-origin: 426.33px 286.8px"
              id="el8cdu6o4esli"
              class="animable"></circle>
            <path
              d="M434.9,286.8a2,2,0,1,1-2-2A2,2,0,0,1,434.9,286.8Z"
              style="fill: #fb8fbf; transform-origin: 432.9px 286.8px"
              id="elq16rlgnpw8s"
              class="animable"></path>
            <path
              d="M441.48,286.8a2,2,0,1,1-2-2A2,2,0,0,1,441.48,286.8Z"
              style="fill: #fb8fbf; transform-origin: 439.48px 286.8px"
              id="elu0sb314oq6n"
              class="animable"></path>
            <path
              d="M415.17,292.79a2,2,0,1,1-2-2A2,2,0,0,1,415.17,292.79Z"
              style="fill: #fb8fbf; transform-origin: 413.17px 292.79px"
              id="el3x1g20j6p2o"
              class="animable"></path>
            <path
              d="M421.75,292.79a2,2,0,1,1-2-2A2,2,0,0,1,421.75,292.79Z"
              style="fill: #fb8fbf; transform-origin: 419.75px 292.79px"
              id="elgqjo3r0qrij"
              class="animable"></path>
            <path
              d="M428.33,292.79a2,2,0,1,1-2-2A2,2,0,0,1,428.33,292.79Z"
              style="fill: #fb8fbf; transform-origin: 426.33px 292.79px"
              id="elbgfqpwe43yu"
              class="animable"></path>
            <path
              d="M434.9,292.79a2,2,0,1,1-2-2A2,2,0,0,1,434.9,292.79Z"
              style="fill: #fb8fbf; transform-origin: 432.9px 292.79px"
              id="elzcqu6ijih1a"
              class="animable"></path>
            <path
              d="M441.48,292.79a2,2,0,1,1-2-2A2,2,0,0,1,441.48,292.79Z"
              style="fill: #fb8fbf; transform-origin: 439.48px 292.79px"
              id="el8s7c4biucuv"
              class="animable"></path>
            <rect
              x="407.4"
              y="278.82"
              width="1.15"
              height="15.96"
              style="fill: rgb(38, 50, 56); transform-origin: 407.975px 286.8px"
              id="elns3o9txaoem"
              class="animable"></rect>
            <rect
              x="341.37"
              y="246.15"
              width="103.21"
              height="23.84"
              style="
                fill: rgb(55, 71, 79);
                transform-origin: 392.975px 258.07px;
              "
              id="el3pm4f25fd61"
              class="animable"></rect>
            <rect
              x="345.71"
              y="250.09"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 251.095px;
              "
              id="eljei5gw1u9ha"
              class="animable"></rect>
            <rect
              x="345.71"
              y="253.58"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 254.585px;
              "
              id="elc4shmhwuh5q"
              class="animable"></rect>
            <rect
              x="345.71"
              y="257.07"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 258.075px;
              "
              id="elzo2xz57x4nt"
              class="animable"></rect>
            <rect
              x="345.71"
              y="260.55"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 261.555px;
              "
              id="el6bqbxb62if7"
              class="animable"></rect>
            <rect
              x="345.71"
              y="264.04"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 265.045px;
              "
              id="elilhkocuej9i"
              class="animable"></rect>
            <path
              d="M415.17,252.09a2,2,0,1,1-2-2A2,2,0,0,1,415.17,252.09Z"
              style="fill: #fb8fbf; transform-origin: 413.17px 252.09px"
              id="elx8tohhruiuo"
              class="animable"></path>
            <path
              d="M421.75,252.09a2,2,0,1,1-2-2A2,2,0,0,1,421.75,252.09Z"
              style="fill: #fb8fbf; transform-origin: 419.75px 252.09px"
              id="elne9jq28tl8"
              class="animable"></path>
            <path
              d="M428.33,252.09a2,2,0,1,1-2-2A2,2,0,0,1,428.33,252.09Z"
              style="fill: #fb8fbf; transform-origin: 426.33px 252.09px"
              id="el6w53etn6k9x"
              class="animable"></path>
            <path
              d="M434.9,252.09a2,2,0,1,1-2-2A2,2,0,0,1,434.9,252.09Z"
              style="fill: #fb8fbf; transform-origin: 432.9px 252.09px"
              id="elndelqtpyyhr"
              class="animable"></path>
            <path
              d="M441.48,252.09a2,2,0,1,1-2-2A2,2,0,0,1,441.48,252.09Z"
              style="fill: #fb8fbf; transform-origin: 439.48px 252.09px"
              id="el0in4rlc0sg4i"
              class="animable"></path>
            <path
              d="M415.17,258.07a2,2,0,1,1-2-2A2,2,0,0,1,415.17,258.07Z"
              style="fill: #fb8fbf; transform-origin: 413.17px 258.07px"
              id="elj56ifiketf8"
              class="animable"></path>
            <path
              d="M421.75,258.07a2,2,0,1,1-2-2A2,2,0,0,1,421.75,258.07Z"
              style="fill: #fb8fbf; transform-origin: 419.75px 258.07px"
              id="el1tdxomf4g9x"
              class="animable"></path>
            <path
              d="M428.33,258.07a2,2,0,1,1-2-2A2,2,0,0,1,428.33,258.07Z"
              style="fill: #fb8fbf; transform-origin: 426.33px 258.07px"
              id="elq727y5qy7jo"
              class="animable"></path>
            <path
              d="M434.9,258.07a2,2,0,1,1-2-2A2,2,0,0,1,434.9,258.07Z"
              style="fill: #fb8fbf; transform-origin: 432.9px 258.07px"
              id="el8v7wbi6z50s"
              class="animable"></path>
            <path
              d="M441.48,258.07a2,2,0,1,1-2-2A2,2,0,0,1,441.48,258.07Z"
              style="fill: #fb8fbf; transform-origin: 439.48px 258.07px"
              id="el0liuynwcs7a"
              class="animable"></path>
            <path
              d="M415.17,264.06a2,2,0,1,1-2-2A2,2,0,0,1,415.17,264.06Z"
              style="fill: #fb8fbf; transform-origin: 413.17px 264.06px"
              id="elby7xr3lj21"
              class="animable"></path>
            <path
              d="M421.75,264.06a2,2,0,1,1-2-2A2,2,0,0,1,421.75,264.06Z"
              style="fill: #fb8fbf; transform-origin: 419.75px 264.06px"
              id="elh28jvq3kmb"
              class="animable"></path>
            <path
              d="M428.33,264.06a2,2,0,1,1-2-2A2,2,0,0,1,428.33,264.06Z"
              style="fill: #fb8fbf; transform-origin: 426.33px 264.06px"
              id="elqtee2f1192n"
              class="animable"></path>
            <path
              d="M434.9,264.06a2,2,0,1,1-2-2A2,2,0,0,1,434.9,264.06Z"
              style="fill: #fb8fbf; transform-origin: 432.9px 264.06px"
              id="el7c4ggu76uv"
              class="animable"></path>
            <path
              d="M441.48,264.06a2,2,0,1,1-2-2A2,2,0,0,1,441.48,264.06Z"
              style="fill: #fb8fbf; transform-origin: 439.48px 264.06px"
              id="eltkdb36tzbs"
              class="animable"></path>
            <rect
              x="407.4"
              y="250.09"
              width="1.15"
              height="15.96"
              style="
                fill: rgb(38, 50, 56);
                transform-origin: 407.975px 258.07px;
              "
              id="elizyaffg21ye"
              class="animable"></rect>
            <rect
              x="341.37"
              y="217.43"
              width="103.21"
              height="23.84"
              style="
                fill: rgb(55, 71, 79);
                transform-origin: 392.975px 229.35px;
              "
              id="elal99bgf6g7t"
              class="animable"></rect>
            <rect
              x="345.71"
              y="221.36"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 222.365px;
              "
              id="elbudydr2pnh"
              class="animable"></rect>
            <rect
              x="345.71"
              y="224.85"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 225.855px;
              "
              id="elyqa35hphlz"
              class="animable"></rect>
            <rect
              x="345.71"
              y="228.34"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 229.345px;
              "
              id="elbhfcpgrsauo"
              class="animable"></rect>
            <rect
              x="345.71"
              y="231.83"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 232.835px;
              "
              id="elcml5a5rr8hc"
              class="animable"></rect>
            <rect
              x="345.71"
              y="235.31"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 236.315px;
              "
              id="eltww3hu8cggp"
              class="animable"></rect>
            <path
              d="M415.17,223.36a2,2,0,1,1-2-2A2,2,0,0,1,415.17,223.36Z"
              style="fill: #fb8fbf; transform-origin: 413.17px 223.36px"
              id="el99zgjzh62ir"
              class="animable"></path>
            <path
              d="M421.75,223.36a2,2,0,1,1-2-2A2,2,0,0,1,421.75,223.36Z"
              style="fill: #fb8fbf; transform-origin: 419.75px 223.36px"
              id="el44zlord33z5"
              class="animable"></path>
            <path
              d="M428.33,223.36a2,2,0,1,1-2-2A2,2,0,0,1,428.33,223.36Z"
              style="fill: #fb8fbf; transform-origin: 426.33px 223.36px"
              id="el1b7vacxlfq"
              class="animable"></path>
            <path
              d="M434.9,223.36a2,2,0,1,1-2-2A2,2,0,0,1,434.9,223.36Z"
              style="fill: #fb8fbf; transform-origin: 432.9px 223.36px"
              id="el70str77kmrs"
              class="animable"></path>
            <path
              d="M441.48,223.36a2,2,0,1,1-2-2A2,2,0,0,1,441.48,223.36Z"
              style="fill: #fb8fbf; transform-origin: 439.48px 223.36px"
              id="elatv664bnq"
              class="animable"></path>
            <path
              d="M415.17,229.35a2,2,0,1,1-2-2A2,2,0,0,1,415.17,229.35Z"
              style="fill: #fb8fbf; transform-origin: 413.17px 229.35px"
              id="ela6jxn5ec229"
              class="animable"></path>
            <path
              d="M421.75,229.35a2,2,0,1,1-2-2A2,2,0,0,1,421.75,229.35Z"
              style="fill: #fb8fbf; transform-origin: 419.75px 229.35px"
              id="elkbl63lrfygr"
              class="animable"></path>
            <path
              d="M428.33,229.35a2,2,0,1,1-2-2A2,2,0,0,1,428.33,229.35Z"
              style="fill: #fb8fbf; transform-origin: 426.33px 229.35px"
              id="elzbca6lrl9s"
              class="animable"></path>
            <path
              d="M434.9,229.35a2,2,0,1,1-2-2A2,2,0,0,1,434.9,229.35Z"
              style="fill: #fb8fbf; transform-origin: 432.9px 229.35px"
              id="eljsdywc9my0g"
              class="animable"></path>
            <path
              d="M441.48,229.35a2,2,0,1,1-2-2A2,2,0,0,1,441.48,229.35Z"
              style="fill: #fb8fbf; transform-origin: 439.48px 229.35px"
              id="el1b4jdugkq3j"
              class="animable"></path>
            <path
              d="M415.17,235.33a2,2,0,1,1-2-2A2,2,0,0,1,415.17,235.33Z"
              style="fill: #fb8fbf; transform-origin: 413.17px 235.33px"
              id="el7rpa6s9f384"
              class="animable"></path>
            <path
              d="M421.75,235.33a2,2,0,1,1-2-2A2,2,0,0,1,421.75,235.33Z"
              style="fill: #fb8fbf; transform-origin: 419.75px 235.33px"
              id="elkzkrdqpwd1b"
              class="animable"></path>
            <path
              d="M428.33,235.33a2,2,0,1,1-2-2A2,2,0,0,1,428.33,235.33Z"
              style="fill: #fb8fbf; transform-origin: 426.33px 235.33px"
              id="ellqz5552bn1i"
              class="animable"></path>
            <path
              d="M434.9,235.33a2,2,0,1,1-2-2A2,2,0,0,1,434.9,235.33Z"
              style="fill: #fb8fbf; transform-origin: 432.9px 235.33px"
              id="el0x1d3ky2yejj"
              class="animable"></path>
            <path
              d="M441.48,235.33a2,2,0,1,1-2-2A2,2,0,0,1,441.48,235.33Z"
              style="fill: #fb8fbf; transform-origin: 439.48px 235.33px"
              id="elculbqn8asmh"
              class="animable"></path>
            <rect
              x="407.4"
              y="221.36"
              width="1.15"
              height="15.96"
              style="
                fill: rgb(38, 50, 56);
                transform-origin: 407.975px 229.34px;
              "
              id="elew514q7yt37"
              class="animable"></rect>
            <rect
              x="341.37"
              y="188.7"
              width="103.21"
              height="23.84"
              style="
                fill: rgb(55, 71, 79);
                transform-origin: 392.975px 200.62px;
              "
              id="elkh2d47x7lu"
              class="animable"></rect>
            <rect
              x="345.71"
              y="192.64"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 193.645px;
              "
              id="elinsvw1k5od8"
              class="animable"></rect>
            <rect
              x="345.71"
              y="196.12"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 197.125px;
              "
              id="el79qyforcfan"
              class="animable"></rect>
            <rect
              x="345.71"
              y="199.61"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 200.615px;
              "
              id="elhiat34esmmh"
              class="animable"></rect>
            <rect
              x="345.71"
              y="203.1"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 204.105px;
              "
              id="el4x6pvuc2xp"
              class="animable"></rect>
            <rect
              x="345.71"
              y="206.59"
              width="58.75"
              height="2.01"
              style="
                fill: rgb(69, 90, 100);
                transform-origin: 375.085px 207.595px;
              "
              id="elapjxl7hp4p"
              class="animable"></rect>
            <path
              d="M415.17,194.63a2,2,0,1,1-2-2A2,2,0,0,1,415.17,194.63Z"
              style="fill: #fb8fbf; transform-origin: 413.17px 194.63px"
              id="elg4ut2pxyzg9"
              class="animable"></path>
            <path
              d="M421.75,194.63a2,2,0,1,1-2-2A2,2,0,0,1,421.75,194.63Z"
              style="fill: #fb8fbf; transform-origin: 419.75px 194.63px"
              id="elp933chjp2v"
              class="animable"></path>
            <path
              d="M428.33,194.63a2,2,0,1,1-2-2A2,2,0,0,1,428.33,194.63Z"
              style="fill: #fb8fbf; transform-origin: 426.33px 194.63px"
              id="elscn1i6xiw7"
              class="animable"></path>
            <path
              d="M434.9,194.63a2,2,0,1,1-2-2A2,2,0,0,1,434.9,194.63Z"
              style="fill: #fb8fbf; transform-origin: 432.9px 194.63px"
              id="el820lz87w7wi"
              class="animable"></path>
            <path
              d="M441.48,194.63a2,2,0,1,1-2-2A2,2,0,0,1,441.48,194.63Z"
              style="fill: #fb8fbf; transform-origin: 439.48px 194.63px"
              id="elsk0xxwivc2"
              class="animable"></path>
            <circle
              cx="413.18"
              cy="200.62"
              r="2"
              style="fill: #28ddcf; transform-origin: 413.18px 200.62px"
              id="elwvtnumevnmc"
              class="animable"></circle>
            <path
              d="M421.75,200.62a2,2,0,1,1-2-2A2,2,0,0,1,421.75,200.62Z"
              style="fill: #fb8fbf; transform-origin: 419.75px 200.62px"
              id="eluxq9rjl6qm"
              class="animable"></path>
            <circle
              cx="426.33"
              cy="200.62"
              r="2"
              style="fill: #28ddcf; transform-origin: 426.33px 200.62px"
              id="elobojqe6gic"
              class="animable"></circle>
            <path
              d="M434.9,200.62a2,2,0,1,1-2-2A2,2,0,0,1,434.9,200.62Z"
              style="fill: #fb8fbf; transform-origin: 432.9px 200.62px"
              id="elwo2v172wmz"
              class="animable"></path>
            <path
              d="M441.48,200.62a2,2,0,1,1-2-2A2,2,0,0,1,441.48,200.62Z"
              style="fill: #fb8fbf; transform-origin: 439.48px 200.62px"
              id="el1f4t7dn9kom"
              class="animable"></path>
            <path
              d="M415.17,206.6a2,2,0,1,1-2-2A2,2,0,0,1,415.17,206.6Z"
              style="fill: #fb8fbf; transform-origin: 413.17px 206.6px"
              id="el7jh1zmddguj"
              class="animable"></path>
            <path
              d="M421.75,206.6a2,2,0,1,1-2-2A2,2,0,0,1,421.75,206.6Z"
              style="fill: #fb8fbf; transform-origin: 419.75px 206.6px"
              id="elioclqmgy8k"
              class="animable"></path>
            <path
              d="M428.33,206.6a2,2,0,1,1-2-2A2,2,0,0,1,428.33,206.6Z"
              style="fill: #fb8fbf; transform-origin: 426.33px 206.6px"
              id="elkneualrp2k"
              class="animable"></path>
            <path
              d="M434.9,206.6a2,2,0,1,1-2-2A2,2,0,0,1,434.9,206.6Z"
              style="fill: #fb8fbf; transform-origin: 432.9px 206.6px"
              id="elsp5y6dt2ot8"
              class="animable"></path>
            <path
              d="M441.48,206.6a2,2,0,1,1-2-2A2,2,0,0,1,441.48,206.6Z"
              style="fill: #fb8fbf; transform-origin: 439.48px 206.6px"
              id="elx6chjvqpq3"
              class="animable"></path>
            <rect
              x="407.4"
              y="192.64"
              width="1.15"
              height="15.96"
              style="
                fill: rgb(38, 50, 56);
                transform-origin: 407.975px 200.62px;
              "
              id="el6lvdb69tjb8"
              class="animable"></rect>
            <rect
              x="291.69"
              y="168.86"
              width="136.05"
              height="7.56"
              style="
                fill: rgb(55, 71, 79);
                transform-origin: 359.715px 172.64px;
              "
              id="eltf7zwsh92y"
              class="animable"></rect>
            <rect
              x="277.86"
              y="384.07"
              width="15.19"
              height="15.19"
              style="
                fill: rgb(38, 50, 56);
                transform-origin: 285.455px 391.665px;
              "
              id="elmm4u2pk3ch"
              class="animable"></rect>
          </g>
          <g
            id="freepik--wall-plug--inject-15"
            class="animable"
            style="transform-origin: 56.74px 367.02px">
            <rect
              x="43.46"
              y="353.74"
              width="26.56"
              height="26.56"
              style="fill: rgb(55, 71, 79); transform-origin: 56.74px 367.02px"
              id="elus1kahzpntl"
              class="animable"></rect>
            <circle
              cx="56.74"
              cy="367.01"
              r="8.87"
              style="fill: rgb(69, 90, 100); transform-origin: 56.74px 367.01px"
              id="elcmtnrmdpd6"
              class="animable"></circle>
            <rect
              x="53.12"
              y="363.43"
              width="2"
              height="7.32"
              style="fill: rgb(38, 50, 56); transform-origin: 54.12px 367.09px"
              id="elhntulwj7hi6"
              class="animable"></rect>
            <rect
              x="58.68"
              y="363.43"
              width="2"
              height="7.32"
              style="fill: rgb(38, 50, 56); transform-origin: 59.68px 367.09px"
              id="el032qbp0ip0po"
              class="animable"></rect>
          </g>
          <g
            id="freepik--Floor--inject-15"
            class="animable"
            style="transform-origin: 250px 421.59px">
            <polygon
              points="36.85 421.59 90.14 421.35 143.43 421.26 250 421.09 356.57 421.26 409.86 421.35 463.15 421.59 409.86 421.84 356.57 421.93 250 422.09 143.43 421.92 90.14 421.83 36.85 421.59"
              style="fill: rgb(38, 50, 56); transform-origin: 250px 421.59px"
              id="el8u1bqify51p"
              class="animable"></polygon>
          </g>
          <g
            id="freepik--Dog--inject-15"
            class="animable"
            style="transform-origin: 188.222px 382.285px">
            <path
              d="M128.08,396.52c1,2.12-2.84,17.31-4,20.43s-5.39,20.53-7.43,21.28-11.93.54-13.33,0,0-3.51,1.61-4.55,3.54-2.63,3.54-2.63-2.89-26.89-2.35-29.9S125.48,390.83,128.08,396.52Z"
              style="fill: #bcb5bc; transform-origin: 115.495px 416.565px"
              id="eliesnkpads2o"
              class="animable"></path>
            <g id="elubec0faa5nl">
              <path
                d="M128.08,396.52c1,2.12-2.84,17.31-4,20.43s-5.39,20.53-7.43,21.28-11.93.54-13.33,0,0-3.51,1.61-4.55,3.54-2.63,3.54-2.63-2.89-26.89-2.35-29.9S125.48,390.83,128.08,396.52Z"
                style="opacity: 0.1; transform-origin: 115.495px 416.565px"
                class="animable"
                id="elqsma4x87ll"></path>
            </g>
            <g id="eljlj9h2lae1k">
              <path
                d="M107.4,437.06a3.9,3.9,0,0,1,.65-1.49,5.6,5.6,0,0,1,1.13-1.15,3.68,3.68,0,0,1,1.42-.69.07.07,0,0,1,.07.12c-.18.16-.38.3-.55.47s-.36.35-.52.53a6.07,6.07,0,0,0-.85,1.16c-.25.41-.48.83-.7,1.27s-.33.91-.5,1.37a.1.1,0,0,1-.2,0A3.67,3.67,0,0,1,107.4,437.06Z"
                style="opacity: 0.1; transform-origin: 108.992px 436.237px"
                class="animable"
                id="elwl719n2i9qe"></path>
            </g>
            <g id="eldn2f96wepoj">
              <path
                d="M104.51,435.48a5,5,0,0,1,2.41-2.11c.07,0,.14.05.08.1a8.13,8.13,0,0,0-1.92,2.17,3.66,3.66,0,0,0-.38,2.85s0,.06-.07,0A2.92,2.92,0,0,1,104.51,435.48Z"
                style="opacity: 0.1; transform-origin: 105.586px 435.943px"
                class="animable"
                id="elaxgfxy2dl97"></path>
            </g>
            <g id="elp4lhy0k1z7f">
              <path
                d="M106.94,416l13.59,12.86c1.5-4.93,2.9-10.29,3.51-11.88,1.2-3.12,5-18.31,4-20.43-2.61-5.69-21.48,1.62-22,4.63C105.83,402.57,106.33,409.27,106.94,416Z"
                style="opacity: 0.1; transform-origin: 117.099px 411.652px"
                class="animable"
                id="eljemdmfmllas"></path>
            </g>
            <path
              d="M179.41,411.72c.55.52,5.9-4.54,6.72-6.91s-2.6-9.06-2-10.2,3.55-2.05,5.06-.3,5.38,11.34,4.58,15.44S184.74,423,183.87,423,179.41,411.72,179.41,411.72Z"
              style="fill: #bcb5bc; transform-origin: 186.644px 408.166px"
              id="elo9s44o9fnx"
              class="animable"></path>
            <g id="eltigqeb1pkvh">
              <path
                d="M179.41,411.72s3.61,11.2,4.46,11.25c.63,0,5.21-4.86,7.95-9.09l-7.49-6.31C182.44,409.8,179.79,412.08,179.41,411.72Z"
                style="opacity: 0.1; transform-origin: 185.615px 415.27px"
                class="animable"
                id="el096gz0lsz7tg"></path>
            </g>
            <path
              d="M149.19,365.46h-44.4s-3.3,29.24-2.94,33.11,19.87,32.54,25.92,36.24,40.83,3.88,49.29,2c6.58-1.45,12.07-9.85,9.34-22.78-1.19-5.66-16.61-19.14-18.76-21.72S149.19,365.46,149.19,365.46Z"
              style="
                fill: rgb(199, 199, 199);
                transform-origin: 144.47px 401.722px;
              "
              id="el255kgvc8gdk"
              class="animable"></path>
            <path
              d="M101.85,398.57c.36,3.87,19.87,32.54,25.93,36.24s40.82,3.88,49.29,2c6.57-1.45,12.07-9.85,9.33-22.79-1.19-5.65-16.6-19.13-18.75-21.71-1.32-1.58-7.94-11.3-12.88-18.6-3.13-4.62-5.58-8.27-5.58-8.27h-44.4s-.83,7.34-1.61,15.15S101.67,396.64,101.85,398.57Z"
              style="fill: #bcb5bc; transform-origin: 144.471px 401.712px"
              id="els45n48o4rm"
              class="animable"></path>
            <g id="elk01pkhsg5f">
              <path
                d="M103.18,380.61c23.86,7.36,45.08-3.11,51.59-6.88-3.13-4.62-5.58-8.27-5.58-8.27h-44.4S104,372.8,103.18,380.61Z"
                style="opacity: 0.1; transform-origin: 128.975px 374.315px"
                class="animable"
                id="elve1frj5s02"></path>
            </g>
            <path
              d="M177.91,412.64s-12.22-7.67-20.79-6.29-11.6,19-7.49,28.89c0,0-7.82,2.32-7.82,6.1,0,2.15,4.47,2.84,10.91,2.68s19.26-.28,19.54-1.9a7.68,7.68,0,0,0-.84-3.85s11.57-1.3,13.84-9S179.84,413.93,177.91,412.64Z"
              style="fill: #bcb5bc; transform-origin: 163.743px 425.113px"
              id="el2kh6nc7jdi1"
              class="animable"></path>
            <g id="elxahx5zmmxo">
              <path
                d="M161.22,436.11a31.06,31.06,0,0,0,10.29,2.28c.07,0,.09.12,0,.13a16.82,16.82,0,0,1-10.33-2.33C161.14,436.17,161.17,436.08,161.22,436.11Z"
                style="opacity: 0.1; transform-origin: 166.366px 437.353px"
                class="animable"
                id="elg8vpg8u8u4"></path>
            </g>
            <g id="el0053em9t1dhxt">
              <path
                d="M146.84,444.07a6,6,0,0,1,2.66-3.44,9.23,9.23,0,0,1,2.26-1.14c.92-.31,1.86-.48,2.79-.77.07,0,.13.09,0,.12a15,15,0,0,0-2.3,1.06,22.45,22.45,0,0,0-2.17,1.28,13.51,13.51,0,0,0-3,3C147.05,444.31,146.8,444.24,146.84,444.07Z"
                style="opacity: 0.1; transform-origin: 150.731px 441.484px"
                class="animable"
                id="el7b2ro6628ww"></path>
            </g>
            <g id="el3cvqw53drui">
              <path
                d="M148.42,438a.06.06,0,0,1,0,.12,9.84,9.84,0,0,0-5.26,5.22c0,.1-.22.1-.2,0A6.27,6.27,0,0,1,148.42,438Z"
                style="opacity: 0.1; transform-origin: 145.719px 440.708px"
                class="animable"
                id="eljimzfapqeg"></path>
            </g>
            <g id="elpe3tpasnsz">
              <path
                d="M144.61,437.45s0,.06,0,.05a25.61,25.61,0,0,1,4.81-2.21c.2.35.41.7.64,1,0,.07.15,0,.11-.06a22.3,22.3,0,0,1-2.14-9.88,48.93,48.93,0,0,1,.32-5.41,0,0,0,0,1,0,0,26.51,26.51,0,0,1,2.9-9,10.84,10.84,0,0,1,3.77-4.34,10.46,10.46,0,0,1,5.65-1,26,26,0,0,1,5.9,1.09,45.74,45.74,0,0,1,5.72,2,31.69,31.69,0,0,0-11.56-3.87,10.58,10.58,0,0,0-6.16,1,11.77,11.77,0,0,0-4.2,4.64,27.93,27.93,0,0,0-3.07,11.88c0,.47,0,.95,0,1.42v0c0,.63,0,1.25,0,1.88a18.69,18.69,0,0,0,2.11,8.53A9.08,9.08,0,0,0,144.61,437.45Z"
                style="opacity: 0.1; transform-origin: 158.45px 421.61px"
                class="animable"
                id="elatzz2aov4x"></path>
            </g>
            <path
              d="M142.43,400.29c1,2.11-2.84,17.3-4,20.42S133,441.24,131,442s-11.93.54-13.33,0,0-3.51,1.61-4.55,3.53-2.63,3.53-2.63-2.88-26.89-2.35-29.9S139.83,394.59,142.43,400.29Z"
              style="fill: #bcb5bc; transform-origin: 129.845px 420.335px"
              id="ele3s1l9h97oi"
              class="animable"></path>
            <g id="elh773fivgxb7">
              <path
                d="M135.77,429.15c.91-2.74,2.1-5.4,3-8.13,0-.11.21-.06.17.05-.7,2.2-1.23,4.46-1.89,6.68.1-.15.19-.31.28-.46a10.87,10.87,0,0,0,1.57-2.77.09.09,0,0,1,.18.05,6.42,6.42,0,0,1-.59,1.34c-.26.49-.53,1-.8,1.47s-.71,1.26-1.1,1.87c0,.05,0,.09,0,.13a78.59,78.59,0,0,1-3,8.13.13.13,0,0,1-.24-.07A85.77,85.77,0,0,1,135.77,429.15Z"
                style="opacity: 0.1; transform-origin: 136.217px 429.265px"
                class="animable"
                id="elsd8j6kh4efa"></path>
            </g>
            <g id="el715ra9ykr7x">
              <path
                d="M120.05,409.2c0-.05.11-.07.12,0,.29,2,.41,4.1.62,6.15s.47,4.25.7,6.38c.47,4.23,1.18,8.45,1.52,12.69,0,.18-.31.2-.36,0A77.9,77.9,0,0,1,120.6,422c-.22-2.08-.35-4.16-.45-6.24A59.07,59.07,0,0,1,120.05,409.2Z"
                style="opacity: 0.1; transform-origin: 121.506px 421.859px"
                class="animable"
                id="elo7lqoh6jgq"></path>
            </g>
            <g id="elgxd0k3yz927">
              <path
                d="M121.27,440.61a3.73,3.73,0,0,1,.64-1.49,5.4,5.4,0,0,1,1.14-1.15,3.55,3.55,0,0,1,1.42-.69.07.07,0,0,1,.06.12c-.18.17-.38.3-.55.47l-.52.53a6.91,6.91,0,0,0-.85,1.16c-.24.41-.48.83-.69,1.27s-.34.91-.51,1.37a.1.1,0,0,1-.19,0A3.52,3.52,0,0,1,121.27,440.61Z"
                style="opacity: 0.1; transform-origin: 122.852px 439.774px"
                class="animable"
                id="el1slaap5fpbr"></path>
            </g>
            <g id="elqya5a3s2ya9">
              <path
                d="M118.37,439a5,5,0,0,1,2.42-2.11c.06,0,.13.05.07.1a7.92,7.92,0,0,0-1.91,2.17,3.57,3.57,0,0,0-.38,2.85s0,.06-.07,0A2.92,2.92,0,0,1,118.37,439Z"
                style="opacity: 0.1; transform-origin: 119.449px 439.463px"
                class="animable"
                id="elfie5kwhxkmf"></path>
            </g>
            <path
              d="M121.78,324.81c.48-1.76-3.9-8-5.57-10.05s-10.86-5.22-13.8-3.61c-1.15.63-3.29,5.05-6.18,8.88-4.48,5.94-9.7,12.17-7.8,13.84,4.6,4.07,15.11-2.35,18.52-4.5s6.52-4.78,7.35-4.69c1.1.12,3.4,1.86,4.33,2A3.23,3.23,0,0,0,121.78,324.81Z"
              style="fill: #bcb5bc; transform-origin: 104.923px 322.951px"
              id="el32qr1gf5h9d"
              class="animable"></path>
            <g id="elwpnlgq5syfl">
              <path
                d="M121.78,324.81c.48-1.76-3.9-8-5.57-10.05s-10.86-5.22-13.8-3.61c-1.15.63-3.29,5.05-6.18,8.88-4.48,5.94-9.7,12.17-7.8,13.84,4.6,4.07,15.11-2.35,18.52-4.5s6.52-4.78,7.35-4.69c1.1.12,3.4,1.86,4.33,2A3.23,3.23,0,0,0,121.78,324.81Z"
                style="opacity: 0.1; transform-origin: 104.923px 322.951px"
                class="animable"
                id="eluuw1kc04it"></path>
            </g>
            <path
              d="M143,318.59c10.73,6.42,15.49,23.48,15.78,26.21.24,2.22,5.35,10.1,4.44,18.57-.59,5.44-10.37,12.67-18.33,12.6-1.46,0-13.85,4.48-24.32,3.76s-18.39-8.15-18.7-8.78S93,369.1,92.5,360.35c-.85-14,13.4-37.76,17.47-41C116.72,314,135.66,314.23,143,318.59Z"
              style="fill: #bcb5bc; transform-origin: 127.896px 347.567px"
              id="elnb4znu2k5eg"
              class="animable"></path>
            <path
              d="M137.9,326c-.2-1.9,5.37-7.61,7.45-9.5s12.14-3.61,14.92-1.45c1.09.85,2.58,5.78,4.94,10.24,3.65,6.91,8,14.24,5.8,15.66-5.46,3.46-15.3-4.94-18.48-7.75s-6-6-6.85-6.08c-1.16-.06-3.84,1.36-4.81,1.31A3.41,3.41,0,0,1,137.9,326Z"
              style="fill: #bcb5bc; transform-origin: 154.75px 327.909px"
              id="ellh4n9sahg0k"
              class="animable"></path>
            <g id="el8gbkmwua218">
              <path
                d="M137.9,326c-.2-1.9,5.37-7.61,7.45-9.5s12.14-3.61,14.92-1.45c1.09.85,2.58,5.78,4.94,10.24,3.65,6.91,8,14.24,5.8,15.66-5.46,3.46-15.3-4.94-18.48-7.75s-6-6-6.85-6.08c-1.16-.06-3.84,1.36-4.81,1.31A3.41,3.41,0,0,1,137.9,326Z"
                style="opacity: 0.1; transform-origin: 154.75px 327.909px"
                class="animable"
                id="elptdlhzv6898"></path>
            </g>
            <g id="el00h5micxdi7g7">
              <path
                d="M118.3,349.93c6.58.06,9.58,7.11,10.37,8.94s4.86,6,5,10.43-8.23,9.3-17,7.75-13.4-6.49-13.35-10.95c0-3.59,3.42-6.7,3.8-7.64S112.31,349.87,118.3,349.93Z"
                style="opacity: 0.1; transform-origin: 118.496px 363.637px"
                class="animable"
                id="eldf5khbn1b0j"></path>
            </g>
            <g id="elo1rbtm75i5">
              <path
                d="M134.48,362.31a15.06,15.06,0,0,1,2.21,3.31,14,14,0,0,1,1.29,3.82,6.9,6.9,0,0,1-.43,4.1,4.83,4.83,0,0,1-1.28,1.65,5.75,5.75,0,0,1-1.79.92,5.67,5.67,0,0,0,2.35-2.87,7.45,7.45,0,0,0,.16-3.64,20.22,20.22,0,0,0-1-3.67C135.52,364.72,135,363.51,134.48,362.31Z"
                style="opacity: 0.1; transform-origin: 136.298px 369.21px"
                class="animable"
                id="elg71us8z8xi"></path>
            </g>
            <g id="elvf8c1l9wws">
              <path
                d="M104.33,359.68c-.57.71-1.11,1.4-1.61,2.1a13,13,0,0,0-1.27,2.18,7,7,0,0,0-.58,2.36,7.36,7.36,0,0,0,.47,2.51,4.41,4.41,0,0,1-1.25-2.46,5.51,5.51,0,0,1,.45-2.81,8.3,8.3,0,0,1,3.79-3.88Z"
                style="opacity: 0.1; transform-origin: 102.196px 364.255px"
                class="animable"
                id="elipb34i5vcpr"></path>
            </g>
            <path
              d="M207.14,453.86c-10.77,0-22.66-.19-34.61-.41-6.16-.11-11-.2-14.06-.2-15.53,0-20-20-24.32-39.26-.6-2.69-1.2-5.38-1.83-8-1.24-5.17-2.25-10.38-3.24-15.43-3-15.61-5.67-29.08-12.55-29.82-9.09-1-17.18,12.62-20.13,33.83l-2-.27c3.11-22.36,12.08-36.66,22.32-35.55,8.35.89,11.11,15,14.3,31.43,1,5,2,10.22,3.22,15.34.64,2.65,1.24,5.36,1.85,8.06,4.34,19.38,8.44,37.69,22.36,37.69,3.05,0,7.93.09,14.1.2,13.52.26,32,.6,47.7.29,25.42-.5,28.43-2.5,28.74-3.11,0-4.16-26.84-9.16-36.94-11.05s-10.6-2-10.49-3.23,1.35-1.13,3.25-1.13h41.94c13.15,0,17.39-18.92,19.93-30.23a42.34,42.34,0,0,1,1.93-7.13c2.94-6.12,10.38-5.23,10.69-5.19l-.26,2c-.26,0-6.32-.74-8.63,4.07a44,44,0,0,0-1.78,6.7c-2.67,11.89-7.12,31.79-21.88,31.79H210.35l2.09.39c24.42,4.56,38.56,8.17,38.56,13.1a1.94,1.94,0,0,1-.65,1.42C247.38,453.05,229.71,453.86,207.14,453.86Z"
              style="
                fill: rgb(38, 50, 56);
                transform-origin: 186.85px 406.275px;
              "
              id="ele1slyb11zv8"
              class="animable"></path>
            <g id="el3kbnwk13neu">
              <rect
                x="89.27"
                y="392.73"
                width="12.28"
                height="7.37"
                style="
                  fill: rgb(69, 90, 100);
                  transform-origin: 95.41px 396.415px;
                  transform: rotate(3.43deg);
                "
                class="animable"
                id="elad5vm1a60yu"></rect>
            </g>
            <g id="el1x8tb60qqt3">
              <rect
                x="91.87"
                y="390.4"
                width="7.66"
                height="2.34"
                style="
                  fill: rgb(55, 71, 79);
                  transform-origin: 95.7px 391.57px;
                  transform: rotate(3.43deg);
                "
                class="animable"
                id="elr3aofgu825q"></rect>
            </g>
            <g id="elvlfc75rpfji">
              <rect
                x="91.51"
                y="399.92"
                width="1.45"
                height="5.62"
                style="
                  fill: #bcb5bc;
                  transform-origin: 92.235px 402.73px;
                  transform: rotate(3.43deg);
                "
                class="animable"
                id="elwlbwdks9vmf"></rect>
            </g>
            <g id="ele9geu86djh6">
              <rect
                x="97.06"
                y="400.25"
                width="1.44"
                height="5.62"
                style="
                  fill: #bcb5bc;
                  transform-origin: 97.78px 403.06px;
                  transform: rotate(3.43deg);
                "
                class="animable"
                id="elcwha8orvzja"></rect>
            </g>
            <g id="elt2ylfivpsjp">
              <rect
                x="279.59"
                y="388.98"
                width="12.28"
                height="5.37"
                style="
                  fill: rgb(69, 90, 100);
                  transform-origin: 285.73px 391.665px;
                  transform: rotate(-90deg);
                "
                class="animable"
                id="elirxk9w3acq7"></rect>
            </g>
            <g id="elwnk5lo9o42">
              <rect
                x="277.05"
                y="389.5"
                width="7.66"
                height="4.34"
                style="
                  fill: rgb(55, 71, 79);
                  transform-origin: 280.88px 391.67px;
                  transform: rotate(-90deg);
                "
                class="animable"
                id="elmci97z3xvg"></rect>
            </g>
            <path
              d="M110,360.6s2.61,3,3.62,2.38,3.54-6.58,3.54-6.58.61,7.56,2.56,7.77,5.29-2.78,5.29-2.78l-7.89-5.47Z"
              style="fill: #bcb5bc; transform-origin: 117.505px 360.05px"
              id="elwt5e2yre3nl"
              class="animable"></path>
            <g id="ellcgacm1d1t">
              <path
                d="M110,360.6s2.61,3,3.62,2.38,3.54-6.58,3.54-6.58.61,7.56,2.56,7.77,5.29-2.78,5.29-2.78l-7.89-5.47Z"
                style="opacity: 0.1; transform-origin: 117.505px 360.05px"
                class="animable"
                id="elmg4wz9ytu3"></path>
            </g>
            <g id="el9lg8ebne277">
              <path
                d="M148.65,351.21a18.76,18.76,0,0,0,.8-7.54,13.36,13.36,0,0,0-.91-3.83,9.38,9.38,0,0,0-1-1.79,8.76,8.76,0,0,0-1.34-1.58,7.2,7.2,0,0,0-.81-.65c-.29-.2-.59-.36-.88-.54l-.45-.25-.47-.19a8.56,8.56,0,0,0-1-.36,10.59,10.59,0,0,0-4-.38,13.75,13.75,0,0,0-7.08,2.79,20,20,0,0,1,7.13-1.31,10.52,10.52,0,0,1,3.37.6c.27.08.52.23.78.33l.38.17.36.23c.23.15.48.28.71.44a6,6,0,0,1,.63.52,8.14,8.14,0,0,1,1.94,2.69,14.77,14.77,0,0,1,1.09,3.33A35.93,35.93,0,0,1,148.65,351.21Z"
                style="opacity: 0.1; transform-origin: 140.525px 342.631px"
                class="animable"
                id="el8z8cxcttx86"></path>
            </g>
            <g id="elrvvf7fivg2r">
              <path
                d="M99.87,346.1a28.54,28.54,0,0,1,1-6.15,9.2,9.2,0,0,1,3-4.76,6.73,6.73,0,0,1,1.18-.69,4.66,4.66,0,0,1,1.27-.35,10.83,10.83,0,0,1,2.89.06,40.09,40.09,0,0,1,6.06,1.57,15.32,15.32,0,0,0-2.69-1.82c-.48-.25-1-.48-1.48-.7a16.12,16.12,0,0,0-1.57-.54,9.22,9.22,0,0,0-3.42-.38,5.58,5.58,0,0,0-1.8.44,7.54,7.54,0,0,0-1.56.9,7.93,7.93,0,0,0-2.26,2.71,10.63,10.63,0,0,0-1,3.23A14.87,14.87,0,0,0,99.87,346.1Z"
                style="opacity: 0.1; transform-origin: 107.283px 339.206px"
                class="animable"
                id="elnmiru62mh19"></path>
            </g>
            <path
              d="M132.36,348.07c0-.11.26.15.25.28-.24,3,0,6.5,3,7.63.09,0,.07.19,0,.17C131.94,355.52,131.61,350.89,132.36,348.07Z"
              style="
                fill: rgb(38, 50, 56);
                transform-origin: 133.852px 352.098px;
              "
              id="elqhnyuk4ah5"
              class="animable"></path>
            <path
              d="M135.46,345.05c5.34-.14,5.33,9.61.38,9.74S131,345.16,135.46,345.05Z"
              style="
                fill: rgb(38, 50, 56);
                transform-origin: 135.815px 349.92px;
              "
              id="elbngiytj3z3w"
              class="animable"></path>
            <path
              d="M111.73,345.7c0-.11-.27.12-.28.25-.18,3-1,6.43-4,7.14-.09,0-.1.17,0,.17C111.12,353.14,112.09,348.6,111.73,345.7Z"
              style="
                fill: rgb(38, 50, 56);
                transform-origin: 109.589px 349.466px;
              "
              id="elf6a1eyhfnd"
              class="animable"></path>
            <path
              d="M109.09,342.28c-5.26-.88-6.62,8.78-1.74,9.59S113.51,343,109.09,342.28Z"
              style="
                fill: rgb(38, 50, 56);
                transform-origin: 108.064px 347.071px;
              "
              id="elnz955p3rxhr"
              class="animable"></path>
            <path
              d="M116.91,356.12c1,.31,7.54-1.77,7-3.77-.55-2.16-9.81-2.67-11-1.27S115.92,355.8,116.91,356.12Z"
              style="
                fill: rgb(38, 50, 56);
                transform-origin: 118.321px 353.224px;
              "
              id="elwbo0ldqzn8"
              class="animable"></path>
            <path
              d="M125.13,361.29a22.13,22.13,0,0,1-3.35,2.09,4.46,4.46,0,0,1-1.82.52c-.38.07-.7-.44-.92-.93a14.25,14.25,0,0,1-.83-3.19c-.2-1.09-.31-2.21-.43-3.3l-.31-3-.91,2.95c-.28.91-.61,1.82-.94,2.73a20.82,20.82,0,0,1-1.18,2.64,3.85,3.85,0,0,1-.39.6,1.77,1.77,0,0,1-.46.48.83.83,0,0,1-.55.06,3.15,3.15,0,0,1-.72-.3,10.27,10.27,0,0,1-2.37-2.13,7.52,7.52,0,0,0,2,2.46,2.86,2.86,0,0,0,.87.46,2.71,2.71,0,0,0,.59.08,1.4,1.4,0,0,0,.64-.15,2.72,2.72,0,0,0,.74-.6,5.94,5.94,0,0,0,.5-.65,16.62,16.62,0,0,0,1.43-2.69c.4-.91.74-1.83,1-2.76l-1.23-.09a29.65,29.65,0,0,0,.46,3.37,16.5,16.5,0,0,0,.42,1.68,8.7,8.7,0,0,0,.67,1.67,3.5,3.5,0,0,0,.59.82,1.71,1.71,0,0,0,.53.37,1.31,1.31,0,0,0,.41.12,1.51,1.51,0,0,0,.37,0,4.89,4.89,0,0,0,2.16-.77A13.91,13.91,0,0,0,125.13,361.29Z"
              style="
                fill: rgb(38, 50, 56);
                transform-origin: 117.54px 359.046px;
              "
              id="el2xjac3kqeg6"
              class="animable"></path>
          </g>
          <g
            id="freepik--Lines--inject-15"
            class="animable"
            style="transform-origin: 92.145px 395.465px">
            <path
              d="M74.9,395.89a47.15,47.15,0,0,1,11.86.81,47.23,47.23,0,0,1-11.86-.81Z"
              style="fill: #fe418d; transform-origin: 80.83px 396.295px"
              id="el830axjs72cr"
              class="animable"></path>
            <path
              d="M103.69,402.22a42.64,42.64,0,0,1,5.47,2.34,41.43,41.43,0,0,1,5.13,3,47.1,47.1,0,0,1-10.6-5.35Z"
              style="fill: #fe418d; transform-origin: 108.99px 404.885px"
              id="elzrj5l5s2r59"
              class="animable"></path>
            <path
              d="M81.25,416a46.89,46.89,0,0,1,7.14-9.5,41.37,41.37,0,0,1-3.27,5A41.53,41.53,0,0,1,81.25,416Z"
              style="fill: #fe418d; transform-origin: 84.82px 411.25px"
              id="elz11pra1fzt"
              class="animable"></path>
            <path
              d="M70,384.07c1.55.49,3.06,1.05,4.56,1.63s3,1.2,4.48,1.83,3,1.28,4.41,2,2.92,1.39,4.34,2.17c-1.55-.5-3.06-1.06-4.56-1.64s-3-1.2-4.48-1.83-3-1.28-4.41-2S71.43,384.84,70,384.07Z"
              style="fill: #fe418d; transform-origin: 78.895px 387.885px"
              id="elgb5mr5pul77"
              class="animable"></path>
            <path
              d="M105.13,392.93a26.48,26.48,0,0,1,4.34-1.72,25.63,25.63,0,0,1,4.55-1,28.44,28.44,0,0,1-8.89,2.73Z"
              style="fill: #fe418d; transform-origin: 109.575px 391.575px"
              id="ellxlpsrapbf"
              class="animable"></path>
            <path
              d="M90.75,387.87c-.82-1.3-1.56-2.63-2.29-4s-1.43-2.7-2.12-4.07-1.35-2.74-2-4.14-1.25-2.79-1.79-4.23c.82,1.3,1.57,2.64,2.3,4s1.43,2.7,2.12,4.06,1.34,2.74,2,4.14S90.21,386.43,90.75,387.87Z"
              style="fill: #fe418d; transform-origin: 86.65px 379.65px"
              id="elx0thbz239wl"
              class="animable"></path>
            <path
              d="M111,419.5c-.89-1-1.72-2.09-2.53-3.16s-1.59-2.18-2.37-3.28-1.51-2.23-2.22-3.38-1.42-2.3-2.06-3.49c.89,1,1.72,2.09,2.53,3.16s1.6,2.18,2.37,3.28,1.51,2.23,2.22,3.38S110.36,418.3,111,419.5Z"
              style="fill: #fe418d; transform-origin: 106.41px 412.845px"
              id="elifue5d86pn9"
              class="animable"></path>
          </g>
          <g
            id="freepik--Device--inject-15"
            class="animable"
            style="transform-origin: 352.03px 140.795px">
            <polygon
              points="379.94 168.86 297.28 168.86 290.88 112.72 373.54 112.72 379.94 168.86"
              style="
                fill: rgb(166, 166, 166);
                transform-origin: 335.41px 140.79px;
              "
              id="el3ccct2r6ph4"
              class="animable"></polygon>
            <polygon
              points="375.44 165.1 300.93 165.1 295.39 116.49 369.9 116.49 375.44 165.1"
              style="
                fill: rgb(255, 255, 255);
                transform-origin: 335.415px 140.795px;
              "
              id="elivitoxpjegs"
              class="animable"></polygon>
            <polygon
              points="329.42 142.06 328.23 143.48 316.51 133.36 317.7 131.94 329.42 142.06"
              style="fill: #fb8fbf; transform-origin: 322.965px 137.71px"
              id="elvl29kd8mh9a"
              class="animable"></polygon>
            <polygon
              points="326.4 131.94 328.04 133.36 319.53 143.48 317.89 142.06 326.4 131.94"
              style="fill: #fb8fbf; transform-origin: 322.965px 137.71px"
              id="eloo7fvhi5uu"
              class="animable"></polygon>
            <polygon
              points="354.48 142.06 353.29 143.48 341.57 133.36 342.76 131.94 354.48 142.06"
              style="fill: #fb8fbf; transform-origin: 348.025px 137.71px"
              id="eld0hymstzwfk"
              class="animable"></polygon>
            <polygon
              points="351.46 131.94 353.1 133.36 344.59 143.48 342.95 142.06 351.46 131.94"
              style="fill: #fb8fbf; transform-origin: 348.025px 137.71px"
              id="elfvvs1fdf35o"
              class="animable"></polygon>
            <polygon
              points="315.01 151.79 314.69 149.79 360.12 149.79 360.44 151.79 315.01 151.79"
              style="fill: #fb8fbf; transform-origin: 337.565px 150.79px"
              id="ell3vmhc2n4w"
              class="animable"></polygon>
            <rect
              x="297.86"
              y="165.1"
              width="106.34"
              height="3.77"
              style="
                fill: rgb(166, 166, 166);
                transform-origin: 351.03px 166.985px;
              "
              id="elmslj58hdz7b"
              class="animable"></rect>
            <rect
              x="320.96"
              y="165.1"
              width="92.22"
              height="3.77"
              style="
                fill: rgb(199, 199, 199);
                transform-origin: 367.07px 166.985px;
              "
              id="elna3zlqu9mxe"
              class="animable"></rect>
            <polygon
              points="370.17 118.85 369.9 116.49 295.39 116.49 295.66 118.85 370.17 118.85"
              style="fill: rgb(38, 50, 56); transform-origin: 332.78px 117.67px"
              id="elaj5qosl66xo"
              class="animable"></polygon>
            <path
              d="M301.66,117.67a.63.63,0,0,0,.59.58.48.48,0,0,0,.46-.58.63.63,0,0,0-.59-.59A.49.49,0,0,0,301.66,117.67Z"
              style="
                fill: rgb(199, 199, 199);
                transform-origin: 302.185px 117.665px;
              "
              id="elue04dnc6y8"
              class="animable"></path>
            <path
              d="M299.5,117.67a.64.64,0,0,0,.6.58.49.49,0,0,0,.46-.58.64.64,0,0,0-.59-.59A.5.5,0,0,0,299.5,117.67Z"
              style="
                fill: rgb(255, 255, 255);
                transform-origin: 300.03px 117.665px;
              "
              id="elv1eymakaig8"
              class="animable"></path>
            <path
              d="M297.35,117.67a.63.63,0,0,0,.59.58.48.48,0,0,0,.46-.58.63.63,0,0,0-.59-.59A.49.49,0,0,0,297.35,117.67Z"
              style="fill: #fb8fbf; transform-origin: 297.875px 117.665px"
              id="eliqaoqn6b1qp"
              class="animable"></path>
          </g>
          <defs>
            <filter id="active" height="200%">
              <feMorphology
                in="SourceAlpha"
                result="DILATED"
                operator="dilate"
                radius="2"></feMorphology>
              <feFlood
                flood-color="#32DFEC"
                flood-opacity="1"
                result="PINK"></feFlood>
              <feComposite
                in="PINK"
                in2="DILATED"
                operator="in"
                result="OUTLINE"></feComposite>
              <feMerge>
                <feMergeNode in="OUTLINE"></feMergeNode>
                <feMergeNode in="SourceGraphic"></feMergeNode>
              </feMerge>
            </filter>
            <filter id="hover" height="200%">
              <feMorphology
                in="SourceAlpha"
                result="DILATED"
                operator="dilate"
                radius="2"></feMorphology>
              <feFlood
                flood-color="#ff0000"
                flood-opacity="0.5"
                result="PINK"></feFlood>
              <feComposite
                in="PINK"
                in2="DILATED"
                operator="in"
                result="OUTLINE"></feComposite>
              <feMerge>
                <feMergeNode in="OUTLINE"></feMergeNode>
                <feMergeNode in="SourceGraphic"></feMergeNode>
              </feMerge>
              <feColorMatrix
                type="matrix"
                values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "></feColorMatrix>
            </filter>
          </defs>
        </svg>

        <a href="https://storyset.com/internet" style="display: none"
          >Internet illustrations by Storyset</a
        >
      </div>
    </div>
  </main>
</html>
