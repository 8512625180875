import { MenuModule } from 'primeng/menu';
import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { MenuService } from 'src/app/services/general/menu/menu.service';
import { TopBarComponent } from '../global/top-bar/top-bar.component';
import { CommonModule } from '@angular/common';
import { ToastService, ToastType } from 'src/app/services/global/toast.service';
import { Router } from '@angular/router';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { IUserProfile } from './company/users/shared/user-models';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ImpersonateBarComponent } from '../global/impersonate-bar/impersonate-bar.component';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import { BreadcrumbModule } from 'primeng/breadcrumb';

@Component({
  selector: 'service-hub',
  templateUrl: 'service-hub.component.html',
  styleUrls: ['service-hub.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MenuModule,
    BreadcrumbModule,
    TopBarComponent,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    FormsModule,
    ImpersonateBarComponent,
  ],
})
export class ServiceHubComponent implements OnInit {
  private menuItems: BehaviorSubject<MenuItem[]> = new BehaviorSubject<
    MenuItem[]
  >([]);
  menuItems$: Observable<MenuItem[]> = this.menuItems.asObservable();

  showMenu$: Observable<boolean> = this.menuService.showMenu$;

  searchValue = '';

  user: IUserProfile;
  isImpersonating = false;

  get breadcrumbs$() {
    return this.breadcrumbService.breadcrumbs$;
  }

  constructor(
    private menuService: MenuService,
    private router: Router,
    private toastService: ToastService,
    private authService: AuthService,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    this.user = await this.authService.getUser();
    this.isImpersonating = this.authService.isImpersonating();
    const userMenuItems = this.menuService.getUserMenuItems();
    this.menuItems.next(userMenuItems);
  }

  toggleMenu() {
    this.menuService.toggleMenu();
  }

  closeMenu() {
    this.menuService.closeMenu();
  }

  search() {
    if (!this.searchValue.length) {
      this.toastService.add({
        severity: ToastType.warning,
        summary: 'Sorry',
        detail: 'Please enter a search term to search',
      });
      return;
    }
    this.router.navigate(['/secure/search'], {
      queryParams: { query: this.searchValue },
    });
  }
}
