import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReleaseNotesNotificationComponent } from '../../misc/pop-up/release-notes-notification/release-notes-notification.component';
import { CookieService as NgxCookieService } from 'ngx-cookie-service';
import { AccountService } from '../account/shared/services/account.service';
import { ICookieSettings } from '../account/shared/models/account-models';
import { ReleaseNotesService } from 'src/app/services/api/release-notes/release-notes.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { firstValueFrom } from 'rxjs';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DateTime } from 'luxon';
import { OrderService } from 'src/app/services/api/order/order.service';
import { BuildServiceNowQuery } from 'src/app/helpers/servicenow/filter/servicenow-filter.helper';
import { IUserProfile } from '../company/users/shared/user-models';
import { ServiceNowFilterQueryGeneric } from 'src/app/models/servicenow.model';
import { CaseService } from 'src/app/services/api/case/case.service';
import { IncidentService } from 'src/app/services/api/incident/incident.service';
import { DashBoardStatistic, dashboardStatistics } from './dashboard.model';
import {
  IncidentClosedStateValues,
  IncidentOpenStateValues,
  IncidentTableColumnNames,
} from 'src/app/models/incident/incident.models';
import {
  ServiceRequestOpenStateValues,
  ServiceRequestTableColumnName,
} from 'src/app/models/service-request/service-request.models';
import {
  OrderOpenStateValues,
  OrderTableColumnNames,
} from 'src/app/models/order/order.model';
import { MenuItem } from 'primeng/api';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import { ServiceRequestService } from 'src/app/services/api/service-request/service-request.service';
import { OrderLineItemService } from 'src/app/services/api/order-line-item/order-line-item.service';
import { GraphResponse } from 'src/app/models/graphs/pie.chart.models';
import { ReleaseNotes } from 'src/app/models/release-notes/release-notes.models';
import { ChartArea } from '../../misc/graph/e-chart-pie/e-chart-pie.component';
import { TicketCountPermissions } from 'src/app/models/permissions/permissions.models';

@Component({
  selector: 'dashboard',
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.component.scss'],
  providers: [DialogService],
})
export class DashboardComponent implements OnInit {
  user: IUserProfile;

  cookieSettings: ICookieSettings;
  additionalCookiesAccepted = false;
  showCookieBanner = false;
  additionalCookies = true;

  graphs: GraphResponse[] = [];
  releaseNotes: ReleaseNotes[];
  ref: DynamicDialogRef | undefined;
  canDisplayStatusWidgets = false;

  chart = {
    incident: ChartArea.Incidents,
    request: ChartArea.Requests,
    order: ChartArea.Orders,
  };

  statistics = dashboardStatistics;
  ticketPermissions: TicketCountPermissions = {
    incident: true,
    request: true,
    order: true,
  };

  constructor(
    private router: Router,
    private ngxCookieService: NgxCookieService,
    private accountService: AccountService,
    private releaseNotesService: ReleaseNotesService,
    private authService: AuthService,
    public dialogService: DialogService,
    private orderService: OrderService,
    private serviceRequestService: ServiceRequestService,
    private orderLineItemService: OrderLineItemService,
    private caseService: CaseService,
    private incidentService: IncidentService,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    const breadcrumbs: MenuItem[] = [
      {
        icon: 'pi pi-home',
        command: () => window.location.reload(),
      },
      { label: 'Dashboard' },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.user = await this.authService.getUser();
    this.canDisplayStatusWidgets = this.user.uTimicoPortalPermissions.includes(
      'PRODUCTS_SERVICES_SERVICE_STATUS_READ'
    );

    this.getOrdersOpenedThisMonthStatistic();
    this.getIncidentsOpenedThisMonthStatistic();
    this.getIncidentsOpenedLastMonthStatistic();
    this.getServiceRequestsOpenedThisMonthStatistic();
    this.getServiceRequestsOpenedLastMonthStatistic();
    this.getOrdersOpenedLastMonthStatistic();
    // Cookie Banner
    this.checkShowCookieBanner();
    // Release Notes
    this.loadReleaseNotes();
  }

  async getIncidentsOpenedThisMonthStatistic() {
    if (!this.authService.hasPermission(this.user, 'TICKETS_INCIDENTS_READ')) {
      this.ticketPermissions.incident = false;
      return;
    }

    const incidentsFilters = [
      {
        Column: IncidentTableColumnNames.state,
        Value: [...IncidentOpenStateValues, ...IncidentClosedStateValues],
      },
      {
        Column: IncidentTableColumnNames.createdOn,
        Value: [
          `between${DateTime.now().startOf('month').toFormat('dd/MM/yyyy')}`,
          DateTime.now().endOf('month').toFormat('dd/MM/yyyy'),
        ],
      },
    ];
    if (!this.user.uTimicoPortalPermissions.includes('TICKETS_COMPANY_READ')) {
      incidentsFilters.push({
        Column: IncidentTableColumnNames.callerID,
        Value: [this.user?.sysId],
      });
    }
    this.getStatistic(
      this.statistics.incidents.openedThisMonth,
      [this.incidentService.getCount.bind(this.incidentService)],
      incidentsFilters
    );

    this.statistics.incidents.openedThisMonth.command = () => {
      this.router.navigate([`/secure/tickets/incidents`], {
        queryParams: {
          opened_at_start_date: DateTime.now()
            .startOf('month')
            .toFormat('dd/MM/yyyy'),
          opened_at_end_date: DateTime.now()
            .endOf('month')
            .toFormat('dd/MM/yyyy'),
        },
      });
    };
  }

  async getIncidentsOpenedLastMonthStatistic() {
    if (!this.authService.hasPermission(this.user, 'TICKETS_INCIDENTS_READ')) {
      this.ticketPermissions.incident = false;
      return;
    }

    const incidentsFilters = [
      {
        Column: IncidentTableColumnNames.state,
        Value: [...IncidentOpenStateValues, ...IncidentClosedStateValues],
      },
      {
        Column: IncidentTableColumnNames.createdOn,
        Value: [
          `between${DateTime.now()
            .minus({ month: 1 })
            .startOf('month')
            .toFormat('dd/MM/yyyy')}`,
          DateTime.now()
            .minus({ month: 1 })
            .endOf('month')
            .toFormat('dd/MM/yyyy'),
        ],
      },
    ];
    if (!this.user.uTimicoPortalPermissions.includes('TICKETS_COMPANY_READ')) {
      incidentsFilters.push({
        Column: IncidentTableColumnNames.callerID,
        Value: [this.user?.sysId],
      });
    }
    this.getStatistic(
      this.statistics.incidents.openedLastMonth,
      [this.incidentService.getCount.bind(this.incidentService)],
      incidentsFilters
    );

    this.statistics.incidents.openedLastMonth.command = () => {
      this.router.navigate([`/secure/tickets/incidents`], {
        queryParams: {
          opened_at_start_date: DateTime.now()
            .minus({ month: 1 })
            .startOf('month')
            .toFormat('dd/MM/yyyy'),
          opened_at_end_date: DateTime.now()
            .minus({ month: 1 })
            .endOf('month')
            .toFormat('dd/MM/yyyy'),
        },
      });
    };
  }

  async getServiceRequestsOpenedThisMonthStatistic() {
    if (!this.authService.hasPermission(this.user, 'TICKETS_REQUESTS_READ')) {
      this.ticketPermissions.request = false;
      return;
    }

    const serviceRequestFilters = [
      {
        Column: ServiceRequestTableColumnName.state,
        Value: ServiceRequestOpenStateValues,
      },
      {
        Column: ServiceRequestTableColumnName.openedAt,
        Value: [
          `between${DateTime.now().startOf('month').toFormat('dd/MM/yyyy')}`,
          DateTime.now().endOf('month').toFormat('dd/MM/yyyy'),
        ],
      },
    ];
    if (!this.user.uTimicoPortalPermissions.includes('TICKETS_COMPANY_READ')) {
      serviceRequestFilters.push({
        Column: ServiceRequestTableColumnName.requestedFor,
        Value: [this.user?.sysId],
      });
    }
    this.getStatistic(
      this.statistics.serviceRequests.openedThisMonth,
      [
        this.serviceRequestService.getCount.bind(this.serviceRequestService),
        this.caseService.getCount.bind(this.caseService),
      ],
      serviceRequestFilters
    );
    this.statistics.serviceRequests.openedThisMonth.command = () => {
      this.router.navigate([`/secure/tickets/service-requests`], {
        queryParams: {
          opened_at_start_date: DateTime.now()
            .startOf('month')
            .toFormat('dd/MM/yyyy'),
          opened_at_end_date: DateTime.now()
            .endOf('month')
            .toFormat('dd/MM/yyyy'),
        },
      });
    };
  }

  async getServiceRequestsOpenedLastMonthStatistic() {
    if (!this.authService.hasPermission(this.user, 'TICKETS_REQUESTS_READ')) {
      this.ticketPermissions.request = false;
      return;
    }

    const serviceRequestFilters = [
      {
        Column: ServiceRequestTableColumnName.state,
        Value: ServiceRequestOpenStateValues,
      },
      {
        Column: ServiceRequestTableColumnName.openedAt,
        Value: [
          `between${DateTime.now()
            .minus({ month: 1 })
            .startOf('month')
            .toFormat('dd/MM/yyyy')}`,
          DateTime.now()
            .minus({ month: 1 })
            .endOf('month')
            .toFormat('dd/MM/yyyy'),
        ],
      },
    ];
    if (!this.user.uTimicoPortalPermissions.includes('TICKETS_COMPANY_READ')) {
      serviceRequestFilters.push({
        Column: ServiceRequestTableColumnName.requestedFor,
        Value: [this.user?.sysId],
      });
    }
    this.getStatistic(
      this.statistics.serviceRequests.openedLastMonth,
      [
        this.serviceRequestService.getCount.bind(this.serviceRequestService),
        this.caseService.getCount.bind(this.caseService),
      ],
      serviceRequestFilters
    );
    this.statistics.serviceRequests.openedLastMonth.command = () => {
      this.router.navigate([`/secure/tickets/service-requests`], {
        queryParams: {
          opened_at_start_date: DateTime.now()
            .minus({ month: 1 })
            .startOf('month')
            .toFormat('dd/MM/yyyy'),
          opened_at_end_date: DateTime.now()
            .minus({ month: 1 })
            .endOf('month')
            .toFormat('dd/MM/yyyy'),
        },
      });
    };
  }

  async getOrdersOpenedThisMonthStatistic() {
    if (!this.authService.hasPermission(this.user, 'TICKETS_ORDERS_READ')) {
      this.ticketPermissions.order = false;
      return;
    }

    const orderFilters = [
      {
        Column: OrderTableColumnNames.state,
        Value: OrderOpenStateValues,
      },
      {
        Column: ServiceRequestTableColumnName.openedAt,
        Value: [
          `between${DateTime.now().startOf('month').toFormat('dd/MM/yyyy')}`,
          DateTime.now().endOf('month').toFormat('dd/MM/yyyy'),
        ],
      },
    ];
    if (!this.user.uTimicoPortalPermissions.includes('TICKETS_COMPANY_READ')) {
      orderFilters.push({
        Column: OrderTableColumnNames.requestedFor,
        Value: [this.user?.sysId],
      });
    }
    this.getStatistic(
      this.statistics.orders.openedThisMonth,
      [
        this.orderService.getCount.bind(this.orderService),
        this.orderLineItemService.getCount.bind(this.orderService),
      ],
      orderFilters
    );
    this.statistics.orders.openedThisMonth.command = () => {
      this.router.navigate([`/secure/tickets/orders`], {
        queryParams: {
          opened_at_start_date: DateTime.now()
            .startOf('month')
            .toFormat('dd/MM/yyyy'),
          opened_at_end_date: DateTime.now()
            .endOf('month')
            .toFormat('dd/MM/yyyy'),
        },
      });
    };
  }

  async getOrdersOpenedLastMonthStatistic() {
    if (!this.authService.hasPermission(this.user, 'TICKETS_ORDERS_READ')) {
      this.ticketPermissions.order = false;
      return;
    }

    const orderFilters = [
      {
        Column: OrderTableColumnNames.state,
        Value: OrderOpenStateValues,
      },
      {
        Column: ServiceRequestTableColumnName.openedAt,
        Value: [
          `between${DateTime.now()
            .minus({ month: 1 })
            .startOf('month')
            .toFormat('dd/MM/yyyy')}`,
          DateTime.now()
            .minus({ month: 1 })
            .endOf('month')
            .toFormat('dd/MM/yyyy'),
        ],
      },
    ];
    if (!this.user.uTimicoPortalPermissions.includes('TICKETS_COMPANY_READ')) {
      orderFilters.push({
        Column: OrderTableColumnNames.requestedFor,
        Value: [this.user?.sysId],
      });
    }
    this.getStatistic(
      this.statistics.orders.openedLastMonth,
      [
        this.orderService.getCount.bind(this.orderService),
        this.orderLineItemService.getCount.bind(this.orderService),
      ],
      orderFilters
    );
    this.statistics.orders.openedLastMonth.command = () => {
      this.router.navigate([`/secure/tickets/orders`], {
        queryParams: {
          opened_at_start_date: DateTime.now()
            .minus({ month: 1 })
            .startOf('month')
            .toFormat('dd/MM/yyyy'),
          opened_at_end_date: DateTime.now()
            .minus({ month: 1 })
            .endOf('month')
            .toFormat('dd/MM/yyyy'),
        },
      });
    };
  }

  async getStatistic(
    statistic: DashBoardStatistic,
    apiCalls,
    filters: ServiceNowFilterQueryGeneric[] = []
  ): Promise<void> {
    // Set loading to true
    statistic.loading = true;

    // Build the ServiceNow query and add the filters
    const serviceNowFilterQuery = BuildServiceNowQuery();
    serviceNowFilterQuery.Filter = [
      ...serviceNowFilterQuery.Filter,
      ...filters,
    ];

    try {
      // Ensure that the results are valid numbers and sum them up
      statistic.value = (
        await Promise.all(
          apiCalls.map(fn => firstValueFrom(fn(serviceNowFilterQuery)))
        )
      )
        .map(value => (typeof value === 'number' && value > 0 ? value : 0))
        .reduce((total, value) => total + value, 0);
    } catch (error) {
      console.error('Error while fetching statistics', error);
      statistic.value = 0;
    } finally {
      statistic.loading = false;
    }
  }

  async loadReleaseNotes() {
    this.releaseNotes = await firstValueFrom(
      this.releaseNotesService.getReleaseNote()
    );
    // TODO do we still need this?
    if (
      this.releaseNotes[0].sysId !=
      (await this.authService.getLoggedInUser()).uReleaseNoteViewed
    ) {
      this.ref = this.dialogService.open(ReleaseNotesNotificationComponent, {
        contentStyle: { overflow: 'auto' },
        showHeader: false,
        width: '80%',
        data: {
          releaseNote: this.releaseNotes[0],
        },
      });

      firstValueFrom(
        this.releaseNotesService.releaseNoteViewed(this.releaseNotes[0].sysId)
      );
      this.ref.onClose.subscribe(result => {
        this.authService.refreshUser();
        if (result) {
          this.router.navigateByUrl('secure/release-notes');
        }
      });
    }
  }

  acceptCookies() {
    this.showCookieBanner = false;
    this.ngxCookieService.set(
      'Accept-DigitalSpace-Necessary-Cookies',
      'true',
      10000
    );
  }

  checkShowCookieBanner(): void {
    const storedCookie = this.ngxCookieService.get(
      'Accept-DigitalSpace-Necessary-Cookies'
    );
    if (storedCookie == null || storedCookie == '' || storedCookie == 'false') {
      setTimeout(() => {
        this.showCookieBanner = true;
      }, 2000);
    }
  }
}
