<div class="flex flex-column">
  <div
    class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200">
    <h1 class="text-xl px-3">Domain Information</h1>
    @if (isDsAdmin) {
      <div class="flex pr-3">
        @if (recordsSource === null) {
          <p-button
            label="Create Hosted Zone"
            icon="pi pi-bolt"
            (onClick)="createHostedZone()"></p-button>
        }
        @if (recordsSource === 'route53') {
          @if (domainStatus?.suspendedStatus === 'RESUMED') {
            <p-button
              label="Suspend"
              icon="pi pi-ban"
              (onClick)="suspendHostedZone()"></p-button>
          }
          @if (domainStatus?.suspendedStatus === 'SUSPENDED') {
            <p-button
              label="Resume"
              styleClass="p-button-third"
              icon="pi pi-bolt"
              (onClick)="resumeHostedZone()"></p-button>
          }
          @if (
            domainStatus?.suspendedStatus !== 'RESUME_IN_PROGRESS' &&
            domainStatus?.suspendedStatus !== 'SUSPEND_IN_PROGRESS'
          ) {
            <p-button
              label="Delete"
              icon="pi pi-trash"
              styleClass="p-button-third"
              (onClick)="removeHostedZone()"></p-button>
          }
        }
      </div>
    }
  </div>
  <div
    class="flex-grow-1 overflow-auto mx-3 overflow-hidden bg-white border-solid border-1 border-gray-200 border-top-none px-3">
    <div class="flex flex-row flex-wrap" style="gap: 18rem">
      <div>
        <p class="font-light pb-1">Domain</p>
        <p class="font-medium">{{ domainName }}</p>
        <hr class="w-full" />
      </div>
      @if (company && isDsAdmin) {
        <div>
          <p class="font-light pb-1">Company</p>
          <p class="font-medium">{{ company }}</p>
          <hr class="w-full" />
        </div>
      }
      @if (
        domainStatus?.suspendedStatus &&
        recordsSource === 'route53' &&
        isDsAdmin
      ) {
        <div>
          <p class="font-light pb-1">Status</p>
          <domain-chip
            [domainStatus]="domainStatus?.suspendedStatus"></domain-chip>
          <hr class="w-full" />
        </div>
      }
    </div>
  </div>
</div>
