<!-- <div class="page-content" style="min-height: 16em">
  <div class="page-information" style="text-align: center">
    <p>
      <strong>
        Personalize your notification settings for individual services. You can
        'Opt Out' of notifications per service by unticking the option.
      </strong>
    </p>
  </div>
  <div style="padding: 10px 25px">
    <service-notifications></service-notifications>
  </div>
</div> -->
