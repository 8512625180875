/* src/app/components/service-hub/misc/cookies-policy/cookies-policy.component.scss */
.table-container {
  padding-left: 16px;
  padding-right: 16px;
}
table {
  width: 100% !important;
  height: 100%;
  border-collapse: collapse !important;
  margin-right: 5% !important;
}
.heading-one {
  width: 96.85pt;
  border-left: 1pt solid windowtext;
  border-bottom: 1pt solid windowtext;
  border-top: none;
  padding: 0cm 5.4pt;
  vertical-align: top;
}
.title {
  background-color: black !important;
  color: white !important;
  padding-top: 10px;
  vertical-align: middle !important;
  padding-bottom: 10px;
  border-right: 1px solid black;
  font-family: "Nunito", sans-serif !important;
  font-weight: lighter !important;
}
.heading-two {
  width: 72.95pt;
  border-bottom: 1pt solid windowtext;
  border-image: initial;
  border-left: none;
  padding: 0cm 5.4pt;
  vertical-align: top;
}
.heading-three {
  width: 340.25pt;
  border-bottom: 1pt solid windowtext;
  border-image: initial;
  border-left: none;
  padding: 0cm 5.4pt;
  vertical-align: top;
}
.first-col {
  width: 96.85pt;
  border-bottom: 1pt solid windowtext;
  border-left: 1pt solid windowtext;
  border-image: initial;
  border-top: none;
  padding: 0cm 5.4pt;
  vertical-align: top;
}
.second-col {
  width: 72.95pt;
  border-top: none;
  border-left: none;
  border-bottom: 1pt solid windowtext;
  padding: 0cm 5.4pt;
  vertical-align: top;
}
.third-col {
  width: 340.25pt;
  border-top: none;
  border-left: none;
  border-bottom: 1pt solid windowtext;
  border-right: 1pt solid;
  padding: 0cm 5.4pt;
  vertical-align: top;
}
td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
p {
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 0.0001pt;
  margin-left: 0cm;
  line-height: normal;
  font-size: 15px;
  font-family: "Nunito", sans-serif !important;
}
span {
  font-size: 19px !important;
}
a {
  color: #e31c79 !important;
  font-size: 1rem !important;
}
li {
  font-size: 1rem !important;
}
.info-span {
  font-size: 16px;
}
.manage-container {
  padding-left: 16px;
  margin: 0 auto;
}
.manage-heading {
  font-size: 1.25rem !important;
  font-family: "Nunito", sans-serif !important;
  font-weight: 600 !important;
}
.manage-text {
  font-size: 1rem !important;
}
.cookies-link {
  margin: 0 auto;
  text-align: center;
  padding-top: 10px;
}
.info-text {
  font-size: 1rem !important;
  font-weight: bold !important;
}
/*# sourceMappingURL=cookies-policy.component-ZO3GPZAP.css.map */
