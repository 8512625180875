import { NotificationTableColumnNames } from 'src/app/models/notification/notification.models';
import { TableColumn, TableSort } from 'src/app/models/table';

export const NotificationsColumns: TableColumn[] = [
  {
    field: 'notificationRead',
    display: { type: 'icon' },
    showSortIcon: false,
    default: true,
    filter: null,
  },
  {
    field: 'lastUpdated',
    header: 'Date',
    display: { type: 'date' },
    filter: {
      type: 'dateRange',
    },
    showSortIcon: true,
    sortColumnOrder: 1,
    sortOrder: TableSort.desc,
    disabled: true,
    default: true,
    serviceNowField: NotificationTableColumnNames.lastUpdated,
  },
  {
    field: 'category',
    header: 'Type',
    display: { type: 'text' },
    filter: {
      type: 'multiSelect',
      values: [
        { label: 'Incidents', value: 'Incident' },
        { label: 'Requested Item', value: 'Requested Item' },
      ],
    },
    showSortIcon: true,
    default: true,
    serviceNowField: NotificationTableColumnNames.category,
  },
  {
    field: 'ticketNumber',
    header: 'Number',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
    serviceNowField: NotificationTableColumnNames.number,
  },
  {
    field: 'description',
    header: 'Subject',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
    serviceNowField: NotificationTableColumnNames.description,
  },
];
