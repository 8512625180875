import { Component, OnInit, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { LazyLoadEvent, MenuItem, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Paginator, PaginatorState } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { CompanyContactUserFilters } from 'src/app/components/misc/filters/filters';
import { AddContactComponent } from 'src/app/components/misc/pop-up/add-contact/add-contact.component';
import { RemoveContactComponent } from 'src/app/components/misc/pop-up/remove-contact/remove-contact.component';
import { BuildServiceNowQuery } from 'src/app/helpers/servicenow/filter/servicenow-filter.helper';
import { IsPaginatorState, TableReset } from 'src/app/helpers/table.helper';
import { TableColumn } from 'src/app/models/table';
import { CompanyAPIService } from 'src/app/services/api/company/company.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import { ToastService, ToastType } from 'src/app/services/global/toast.service';
import { PermissionService } from 'src/app/services/permissions.service';
import { IUserProfile } from '../users/shared/user-models';
import { IServicenowUser } from '../users/users.model';
import { CompanyService } from './shared/company-service.service';
import {
  ContactType,
  ICategoryColumn,
  ICompanyContactCategory,
  IRemoveContactDialogData,
  IUserColumn,
  categoryColumns,
  userColumns,
} from './shared/models';

@Component({
  selector: 'company-contacts',
  templateUrl: 'company-contacts.component.html',
  styleUrls: ['company-contacts.component.scss'],
  providers: [DialogService, MessageService],
})
export class ContactsComponent implements OnInit {
  @ViewChild('dataTable', { static: true }) dataTable: Table;
  @ViewChild('categoryTable') categoryTable: Table;
  @ViewChild('tablePaginator') paginator: Paginator;

  userTableFilters = cloneDeep(CompanyContactUserFilters);

  contactGroups: ICompanyContactCategory[] = [];
  authorisationGroups: ICompanyContactCategory[] = [];

  categorySubject = new BehaviorSubject<ICompanyContactCategory[]>([]);
  category$ = this.categorySubject.asObservable();

  categoryTableLoading = false;

  userSubject = new BehaviorSubject(null);
  user$ = this.userSubject.asObservable();
  // contacts: ICompanyContactCategory[];
  selectedCategory!: ICompanyContactCategory;
  categoryCols!: ICategoryColumn[];
  categoryTableColumn: ICategoryColumn[];
  ref: DynamicDialogRef;
  totalRecords = 0;
  cols!: IUserColumn[];
  defaultSelectedColumns: string[] = [];
  tableFilters: LazyLoadEvent;
  selectedUsers: IServicenowUser[] = [];
  _selectedColumns!: IUserColumn[];

  userTableColumn: TableColumn[] = userColumns;
  firstLoad = true;
  user: IUserProfile;
  staticTable: Table;

  get disableAddUserIcon(): boolean {
    return (
      this.user.uTimicoPortalRole === 'Admin' ||
      this.user.uTimicoPortalRole === 'Digital Space Admin'
    );
  }

  get disabledAddUserButton(): boolean {
    if (this.selectedCategory?.name === 'Emergency Contact') {
      return !(
        this.user.uTimicoPortalRole === 'Admin' ||
        this.user.uTimicoPortalRole === 'Digital Space Admin'
      );
    }
    return false;
  }

  menuItems: MenuItem[] = [
    {
      label: 'Contact Group',
      command: () => this.switchTab(this.contactGroups),
    },
    {
      label: 'Authorisation Group',
      command: () => this.switchTab(this.authorisationGroups),
    },
  ];

  activeMenuItem = this.menuItems[0];

  constructor(
    private companyService: CompanyService,
    public permissionService: PermissionService,
    private breadcrumbService: BreadcrumbService,
    private dialogService: DialogService,
    private toastService: ToastService,
    private companyAPIService: CompanyAPIService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    this.user = await this.authService.getUser();
    const breadcrumbs: MenuItem[] = [
      { label: 'Contact and Authorisation Groups' },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    await this.loadContacts();
    this.selectedCategory = this.categorySubject.value[0];
  }

  async switchTab(categories: ICompanyContactCategory[]) {
    this.categorySubject.next(categories);
    this.selectedCategory = this.categorySubject.value[0];
    await this.loadUsers(this.staticTable);
    this.reset(this.staticTable);
  }

  async loadContacts() {
    this.categoryTableLoading = true;
    this.categoryTableColumn = categoryColumns;

    this.authorisationGroups = (
      await firstValueFrom(this.companyAPIService.getContacts())
    )?.map(contact => {
      return { ...contact, type: ContactType.authorisation };
    });

    this.contactGroups = (
      await firstValueFrom(this.companyAPIService.getContactTypes())
    )?.map(contact => {
      return { ...contact, type: ContactType.contact };
    });

    this.categorySubject.next(this.contactGroups);
    this.categoryTableLoading = false;
  }

  async loadUsers(table: Table, event?: LazyLoadEvent | PaginatorState) {
    if (IsPaginatorState(event)) {
      table.rows = event?.rows;
      table.first = event?.first;
    }

    const isFirstLoad = this.firstLoad ? true : false;
    table.loading = true;
    this.userSubject.next([]);
    if (isFirstLoad) {
      this.reset(table, true, cloneDeep(userColumns));
      this.firstLoad = false;
    }
    const currentPage =
      event?.first != null && event?.rows != null
        ? event.first / event.rows
        : table.first / table.rows;
    const currentPerPage = event?.rows ? event.rows : table.rows;

    const serviceNowFilter = BuildServiceNowQuery(
      table,
      this.userTableColumn,
      currentPerPage,
      currentPage,
      event,
      { CategoryID: this.selectedCategory?.sysId }
    );
    const response =
      this.selectedCategory.type === ContactType.contact
        ? await this.companyService.loadUsersForContactCategory(
            serviceNowFilter
          )
        : await this.companyService.loadUsersForCategory(serviceNowFilter);
    this.totalRecords = response.overallQueryCount;
    this.userSubject.next(response.result);

    this.staticTable = table;
    if (!IsPaginatorState(event)) {
      if (this.paginator) {
        this.paginator.first = 0;
      }
    }
    table.loading = false;
  }

  async handleCategorySelection(selectedCategory: ICompanyContactCategory) {
    if (selectedCategory.sysId !== null) {
      this.selectedCategory = selectedCategory;
      this.reset(this.staticTable);
      await this.loadUsers(this.staticTable);
    }
  }

  addContactButtonClicked() {
    this.showAddContactDialog(this.selectedCategory);
  }

  addContactIconClicked(category: ICompanyContactCategory) {
    this.showAddContactDialog(category);
  }

  showAddContactDialog(category: ICompanyContactCategory) {
    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    this.ref = this.dialogService.open(AddContactComponent, {
      contentStyle: { overflow: 'auto' },
      showHeader: false,
      width: '70%',
      data: {
        categorySysID: category.sysId,
        categoryName: category.name,
        type: this.selectedCategory.type,
      },
      styleClass: 'p-dialog-custom',
    });
    this.ref.onClose.subscribe(result => {
      if (result !== undefined && result) {
        this.handleCategorySelection(this.selectedCategory);
        this.toastService.add({
          severity: ToastType.success,
          summary: category.name,
          detail: 'User(s) added.',
        });
      }
    });
  }

  removeContactIconClicked(selectedUser) {
    const dialogData: IRemoveContactDialogData = {
      categorySysID: this.selectedCategory.sysId,
      categoryName: this.selectedCategory.name,
      userSysID: selectedUser.sysId,
      username: selectedUser.firstName + ' ' + selectedUser.lastName,
      type: this.selectedCategory.type,
    };

    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    this.ref = this.dialogService.open(RemoveContactComponent, {
      showHeader: false,
      data: dialogData,
      styleClass: 'p-dialog-custom',
    });
    this.ref.onClose.subscribe(result => {
      if (result !== undefined && result) {
        this.handleCategorySelection(this.selectedCategory);
        this.toastService.add({
          severity: ToastType.success,
          summary: 'Remove user',
          detail: 'Operation completed.',
        });
      } else {
        this.toastService.add({
          severity: ToastType.warning,
          summary: 'Remove user',
          detail: 'Operation cancelled.',
        });
      }
    });
  }

  reset(table: Table, firstLoad = false, columns: TableColumn[] = userColumns) {
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
    });
  }
}
