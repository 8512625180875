import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import { DateTime } from 'luxon';
import { MessageService } from 'primeng/api';
import { MenuItem } from 'primeng/api/menuitem';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { DigitalSpaceTabMenuItem } from 'src/app/components/misc/digital-space-tab-menu/digital-space-tab-menu.component';
import { ConvertServiceNowDateToJsDate } from 'src/app/helpers/date.helper';
import { BuildServiceNowQuery } from 'src/app/helpers/servicenow/filter/servicenow-filter.helper';
import { SetSelectedColumns, TableReset } from 'src/app/helpers/table.helper';
import {
  GetMultipleIncidentsResponse,
  IncidentClosedStateValues,
  IncidentOpenStateValues,
  IncidentRequestSizeLimit,
  IncidentTableColumnNames,
  IncidentTabs,
} from 'src/app/models/incident/incident.models';
import { ServiceNowFilterQuery } from 'src/app/models/servicenow.model';
import { Filters, TableColumn, TableColumnColor } from 'src/app/models/table';
import { IncidentService } from 'src/app/services/api/incident/incident.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import { IUserProfile } from '../../company/users/shared/user-models';
import {
  ExportTable,
  GetExportItemsFromFullArray,
} from '../../products-and-services/science-logic/cmdb-devices/shared/export-devices';
import {
  IncidentsClosedColumns,
  IncidentsOpenColumns,
} from './incidents.columns';
import { ExportName, ExportView } from 'src/app/models/export/export-pdf.model';

@Component({
  selector: 'incidents',
  templateUrl: 'incidents.component.html',
  styleUrls: ['incidents.component.scss'],
  providers: [MessageService],
})
export class IncidentsComponent implements OnInit {
  @ViewChild('dataTable', { static: true }) dataTable: Table;
  @ViewChild('tablePaginator') paginator: Paginator;
  stateOptions: { label: string; value: boolean }[] = [
    { label: 'Company', value: true },
    { label: 'Mine', value: false },
  ];
  incidentsSubject = new BehaviorSubject<GetMultipleIncidentsResponse[]>([]);
  incidents$ = this.incidentsSubject.asObservable();
  user: IUserProfile;
  hasCompanyToggle: boolean;
  isImpersonating = false;
  totalRecords = 0;
  cols: TableColumn[] = cloneDeep(IncidentsOpenColumns);
  defaultRowsShown = 25;

  companyToggle = true;

  _selectedColumns: TableColumn[] = [];

  get selectedColumns(): TableColumn[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: TableColumn[]) {
    this._selectedColumns = this.cols.filter(col =>
      val.map(valCol => valCol.header).includes(col.header)
    );
  }

  tabs: DigitalSpaceTabMenuItem[] = [
    {
      label: IncidentTabs.open,
      hovered: false,
    },
    {
      label: IncidentTabs.closed,
      hovered: false,
    },
  ];

  activeTab: DigitalSpaceTabMenuItem = this.tabs[0];

  exportOptions = [
    {
      label: 'All',
      value: 'all',
      command: () => this.exportAll(),
    },
    {
      label: 'In View',
      value: 'in_view',
      command: () => this.defaultExport(),
    },
  ];

  currentTableFilters = {};

  permissions = ['TICKETS_COMPANY_READ', 'TICKETS_INCIDENTS_UPDATE'];
  firstLoad = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private incidentService: IncidentService,
    private authService: AuthService,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    this.user = await this.authService.getUser();
    const breadcrumbs: MenuItem[] = [
      { label: 'Incidents', routerLink: '/secure/tickets/incidents' },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.hasCompanyToggle = this.user.uTimicoPortalPermissions.includes(
      'TICKETS_COMPANY_READ'
    );
    this.isImpersonating = this.authService.isImpersonating();
    await this.loadIncidents();
    this.filterTable();
  }

  async loadIncidents() {
    this.dataTable.loading = true;
    this.incidentsSubject.next([]);

    if (this.firstLoad) {
      this.reset(this.dataTable, false, this.cols);
      this.firstLoad = false;
    }

    const incidentCount =
      (await firstValueFrom(
        this.incidentService.getCount(
          this.getQueryFilter(this.dataTable, this.cols)
        )
      )) ?? 0;

    const incidentRequests = [];
    const totalPages = Math.ceil(incidentCount / IncidentRequestSizeLimit);
    for (let pageNumber = 0; pageNumber < totalPages; pageNumber++) {
      incidentRequests.push(
        firstValueFrom(
          this.incidentService.getMultiple(
            this.getQueryFilter(
              this.dataTable,
              this.cols,
              IncidentRequestSizeLimit,
              pageNumber
            )
          )
        )
      );
    }

    const results = (await Promise.all(incidentRequests))
      .flatMap(result => result)
      .map(incident => {
        const attributeChips =
          incident.attributes && typeof incident.attributes === 'object'
            ? Object.entries(incident.attributes)
                .filter(([, value]) => value !== false)
                .map(([key, value]) => {
                  const label = key
                    .replace(/([A-Z])/g, ' $1')
                    .replace(/^./, str => str.toUpperCase())
                    .trim();
                  let chipClass = 'p-chip-info';
                  if (key === 'goldLocation') {
                    chipClass = value ? 'p-chip-gold' : 'p-chip-info';
                  }
                  return { label, class: chipClass };
                })
            : [];
        return {
          ...incident,
          attributeChips,
          sysCreatedOn: ConvertServiceNowDateToJsDate(incident.sysCreatedOn),
        };
      });

    this.totalRecords = incidentCount;
    this.incidentsSubject.next(results);
    this.dataTable.loading = false;
  }

  getQueryFilter(
    table: Table,
    columns: TableColumn[],
    amount?: number,
    page?: number
  ): ServiceNowFilterQuery {
    const serviceNowFilterQuery = BuildServiceNowQuery(
      this.activeTab.label === IncidentTabs.closed ? table : null,
      columns,
      amount,
      page
    );
    if (!this.companyToggle) {
      serviceNowFilterQuery.Filter.push({
        Column: IncidentTableColumnNames.callerID,
        Value: [this.user?.sysId],
      });
    }
    if (this.activeTab.label === IncidentTabs.open) {
      serviceNowFilterQuery.Filter.push({
        Column: IncidentTableColumnNames.state,
        Value: IncidentOpenStateValues,
      });
    }
    if (this.activeTab.label === IncidentTabs.closed) {
      this.router.navigate([], {
        queryParams: {},
      });
      serviceNowFilterQuery.Filter.push({
        Column: IncidentTableColumnNames.state,
        Value: IncidentClosedStateValues,
      });
    }

    return serviceNowFilterQuery;
  }

  async onFiltersChanged() {
    if (this.activeTab.label === IncidentTabs.closed) {
      await this.loadIncidents();
    }
  }

  async onToggleChange(): Promise<void> {
    await this.loadIncidents();
  }

  getColor(colors: TableColumnColor[], value: string) {
    return colors[value] ?? null;
  }

  handleRowClicked(event) {
    this.router.navigateByUrl(`secure/tickets/incident/${event.sysId}`);
  }

  handleColumnChange(val: TableColumn[]) {
    this.cols = this.cols.filter(col =>
      val.map(valCol => valCol.header).includes(col.header)
    );
  }

  tabChange(tab: DigitalSpaceTabMenuItem) {
    this.activeTab = tab;
    this.firstLoad = true;
    if (tab.label === IncidentTabs.open) {
      this.cols = cloneDeep(IncidentsOpenColumns);
    }
    if (tab.label === IncidentTabs.closed) {
      this.cols = cloneDeep(IncidentsClosedColumns);
    }
    this.loadIncidents();
  }

  defaultExport() {
    const displayedRows = this.dataTable.filteredValue || this.dataTable.value;
    ExportTable(
      this.dataTable,
      GetExportItemsFromFullArray(
        displayedRows.map(incident => {
          return {
            ...incident,
            sysCreatedOn: DateTime.fromJSDate(incident.sysCreatedOn).toFormat(
              'dd/MM/yyyy HH:ss'
            ),
          };
        }),
        this.dataTable.first ?? 0,
        this.dataTable._rows ?? this.defaultRowsShown
      ),
      this.selectedColumns,
      'all',
      `${ExportName.incident} - ${ExportView.inView}`
    );
  }

  async exportAll() {
    const displayedRows = this.dataTable.filteredValue || this.dataTable.value;
    ExportTable(
      this.dataTable,
      displayedRows.map(incident => {
        return {
          ...incident,
          sysCreatedOn: DateTime.fromJSDate(incident.sysCreatedOn).toFormat(
            'dd/MM/yyyy HH:ss'
          ),
        };
      }),
      this.selectedColumns,
      'all',
      `${ExportName.incident} - ${ExportView.all}`
    );
  }

  async resetTable() {
    this.router.navigate([], {
      queryParams: {},
    });
    this.cols =
      this.activeTab.label === IncidentTabs.closed
        ? cloneDeep(IncidentsClosedColumns)
        : cloneDeep(IncidentsOpenColumns);
    this.firstLoad = true;
    await this.loadIncidents();
  }

  reset(
    table: Table,
    firstLoad = false,
    columns: TableColumn[],
    additionalFilters?: Filters
  ) {
    this._selectedColumns = SetSelectedColumns(
      this.cols,
      columns.filter(column => column?.default).map(column => column?.field)
    );
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
      additionalFilters: additionalFilters,
    });
  }

  filterTable(): void {
    const queryParams = this.route.snapshot.queryParams;
    if (!queryParams) return;

    if (queryParams.opened_at_start_date && queryParams.opened_at_end_date) {
      const startDateDateTime = DateTime.fromFormat(
        queryParams.opened_at_start_date,
        'dd/MM/yyyy'
      );
      // Convert to a JavaScript Date object
      const startDate = startDateDateTime.toJSDate();
      const endDateDateTime = DateTime.fromFormat(
        queryParams.opened_at_end_date,
        'dd/MM/yyyy'
      ).endOf('day');
      // Convert to a JavaScript Date object
      const endDate = endDateDateTime.toJSDate();
      const values = [startDate, endDate];
      const column = this.cols.find(
        currentFilter => currentFilter.serviceNowField === 'SysCreatedOn'
      );
      if (column) {
        this.dataTable.filter(values, column.field, 'between');
      }
    }

    if (queryParams.state) {
      const column = this.cols.find(
        currentFilter => currentFilter.serviceNowField === 'State'
      );
      if (column) {
        const value = [queryParams.state];
        this.dataTable.filter(value, column.field, 'in');
      }
    }
  }
}
