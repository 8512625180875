<div class="bg-white flex">
  @for (tab of tabs; track tab) {
    <p-button
      class="w-full border-solid border-none border-bottom-2"
      styleClass="text-lg font-semibold w-full m-0 border-none"
      [label]="tab.label"
      [text]="true"
      [severity]="activeTab?.label === tab.label ? 'primary' : 'secondary'"
      (mouseenter)="tab.hovered = true"
      (mouseleave)="tab.hovered = false"
      [class.border-gray-200]="
        activeTab?.label !== tab.label && tab.hovered === false
      "
      [class.border-primary]="activeTab?.label === tab.label"
      [class.border-gray-600]="
        activeTab?.label !== tab.label && tab.hovered === true
      "
      [disabled]="disabled"
      (click)="switchTab(tab)"></p-button>
  }
</div>
