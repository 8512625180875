import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';
import {
  ApproveChangeRequestResponse,
  GetApproverStateResponse,
  GetMultipleChangeRequestResponse,
  GetSingleChangeRequestResponse,
  RejectChangeRequestRequest,
  RejectChangeRequestResponse,
} from 'src/app/models/change/change.models';
import { ServiceNowFilterQuery } from 'src/app/models/servicenow.model';

const taskDirectory = 'ChangeRequest';

@Injectable({
  providedIn: 'root',
})
export class ChangeRequestService {
  constructor(private apiRequest: ApiRequest) {}

  getSingle(changeId: string): Observable<GetSingleChangeRequestResponse> {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetSingleChangeRequest`,
      body: changeId,
    };
    return this.apiRequest.request(requestProps);
  }

  getCount(filter: ServiceNowFilterQuery): Observable<number> {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetChangeRequestsCount`,
      body: filter,
    };
    return this.apiRequest.request(requestProps);
  }

  getMultiple(request): Observable<GetMultipleChangeRequestResponse[]> {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetMultipleChangeRequests`,
      body: request,
    };
    return this.apiRequest.request(requestProps);
  }

  getApproverState(changeId: string): Observable<GetApproverStateResponse> {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetApproverState`,
      body: {
        DocumentId: changeId,
      },
    };
    return this.apiRequest.request(requestProps);
  }

  approve(
    changeId: string,
    newState: string,
    approvalComments: string
  ): Observable<ApproveChangeRequestResponse> {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/ApproveChangeRequest`,
      body: {
        DocumentId: changeId,
        state: newState,
        comments: approvalComments,
      },
    };
    return this.apiRequest.request(requestProps);
  }

  reject(
    update: RejectChangeRequestRequest
  ): Observable<RejectChangeRequestResponse> {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/RejectChangeRequest`,
      body: update,
    };
    return this.apiRequest.request(requestProps);
  }
}
