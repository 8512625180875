import parsePhoneNumberFromString from 'libphonenumber-js';

export const addEditNumberFormatter = (number: string): string => {
  const phoneNumber = parsePhoneNumberFromString(
    number.replace(/[^\d+]/g, ''),
    'GB'
  );
  if (!phoneNumber?.isValid) {
    return number;
  }
  return phoneNumber.formatInternational().replace(/\s/g, '');
};
