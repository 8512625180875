<menu-bar-info>
  <div class="menu-bar-container">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-sm-6 col-xs-6" style="margin-bottom: 10px">
          <div class="dashboard-stats">
            <div class="dashboard-info-container">
              <div class="dashboard-info-title">
                <h4>Incidents</h4>
              </div>
              <div class="dashboard-info-break"></div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-6" style="margin-bottom: 10px">
          <div class="dashboard-stats">
            <div class="dashboard-info-container">
              <div class="dashboard-info-title">
                <h4>Service Requests</h4>
              </div>
              <div class="dashboard-info-break"></div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-6" style="margin-bottom: 10px">
          <div class="dashboard-stats">
            <div class="dashboard-info-container">
              <div class="dashboard-info-title">
                <h4>Orders</h4>
              </div>
              <div class="dashboard-info-break"></div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-6" style="margin-bottom: 10px">
          <div class="dashboard-stats">
            <div class="dashboard-info-container">
              <div class="dashboard-info-title">
                <h4>Service Outages</h4>
              </div>
              <div class="dashboard-info-break"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</menu-bar-info>
