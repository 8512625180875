<div class="flex flex-column h-full">
  <h1 class="text-xl px-3">
    {{ dialogData?.title }}
  </h1>
  <hr class="w-full" />
  <div class="px-4">
    <p class="text-xl m-0 mt-1">
      {{ dialogData?.message }}
      <span class="text-xl text-primary font-bold">
        {{ dialogData?.messageReason }}</span
      >{{ dialogData?.messageEnd }}
    </p>
  </div>
  <div class="flex align-content-center justify-content-center py-3 gap-2">
    <p-button
      styleClass="p-button-secondary"
      label="Cancel"
      (onClick)="cancelButton()"></p-button>
    <p-button
      styleClass="p-button"
      label="Submit"
      (onClick)="submitButton()"></p-button>
  </div>
</div>
