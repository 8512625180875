import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { AddEditUserPageTitle } from '../add-edit-user.component';

@Component({
  selector: 'app-add-edit-user-details',
  templateUrl: './add-edit-user-details.component.html',
  styleUrls: ['./add-edit-user-details.component.scss'],
})
export class AddEditUserDetailsComponent {
  @Input() pageTitle = AddEditUserPageTitle.ADD;
  @Input() addEditUserDetailsForm: FormGroup;
  @Input() userPortals;
  @Input() checkEmailExistsStatus = 'VALID';
  @Output() childIsLoading = new EventEmitter();

  selectedAddEditUserType = 'single';
  AddEditUserPageTitle = AddEditUserPageTitle;

  constructor(private router: Router) {}

  onMultipleUserSelect() {
    this.router.navigate(['secure/company/users/multiple/add']);
  }
}
