import { Injectable } from '@angular/core';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';
import { ServiceNowFilterQuery } from 'src/app/models/servicenow.model';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private apiRequest: ApiRequest) {}

  createLocation(
    name: string,
    nickName: string,
    buildingName: string,
    street: string,
    city: string,
    zip: string
  ) {
    const requestProps: IRequestOptions = {
      endpoint: 'Locations/CreateLocation',
      body: {
        Name: name,
        UNickName: nickName,
        UBuildingName: buildingName,
        Street: street,
        City: city,
        Zip: zip,
      },
    };

    return this.apiRequest.request(requestProps);
  }

  getIncidentLocations(request: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: 'Locations/GetIncidentLocations',
      body: request,
    };
    return this.apiRequest.request(requestProps);
  }
}
