import { Injectable } from '@angular/core';

@Injectable()
export class DictionaryService {
  constructor() {}

  /*********************************************************************************************/
  translateTables(value: any): string {
    switch (value) {
      case 'incident':
        return 'Incident';
      case 'sc_request':
        return 'Request';
      case 'sc_req_item':
        return 'Request';
      case 'change_request':
        return 'Change';
      case 'problem':
        return 'Problem';
      default:
        return '';
    }
  }

  translateTTL(value: string): string {
    switch (value) {
      case '5m':
        return '5 minutes';
      case '15m':
        return '15 minutes';
      case '1h':
        return '1 hour';
      case '4h':
        return '4 hours';
      case '12h':
        return '12 hours';
      case '1d':
        return '1 day';
      case '3d':
        return '3 days';
      case '1w':
        return '1 week';
      default:
        return value;
    }
  }

  translateMonth(value: string): string {
    switch (value) {
      case 'Jan':
        return '01';
      case 'Feb':
        return '02';
      case 'Mar':
        return '03';
      case 'Apr':
        return '04';
      case 'May':
        return '05';
      case 'Jun':
        return '06';
      case 'Jul':
        return '07';
      case 'Aug':
        return '08';
      case 'Sep':
        return '09';
      case 'Oct':
        return '10';
      case 'Nov':
        return '11';
      case 'Dec':
        return '12';
    }
  }

  // Gets the right badge depending on the result.
  colourByPriority(value): string {
    switch (value.toLocaleLowerCase()) {
      case Priority.Critical:
        return Colour.Danger;
      case Priority.High:
        return Colour.Warning;
      case Priority.Moderate:
        return Colour.Accent;
      case Priority.Low:
        return Colour.Primary;
      default:
        return Colour.Primary;
    }
  }

  colourByDeviceUtilisationPollingStatus(value): string {
    switch (value.toLocaleLowerCase()) {
      case DeviceUtilisationPollingStatus.Paused:
        return Colour['#33333'];
      case DeviceUtilisationPollingStatus.Unknown:
        return Colour['#33333'];
      case DeviceUtilisationPollingStatus.Active:
        return Colour['#00B0A3'];
      case DeviceUtilisationPollingStatus.Maintenance:
        return Colour['#A40D49'];
      default:
        return Colour.Primary;
    }
  }

  staeByDeviceUtilisationAvailability(value): string {
    switch (value.toLocaleLowerCase()) {
      case DeviceUtilisationAvailability.Unavailability:
        return Style.Unavailability;
      case DeviceUtilisationAvailability.Availability:
        return Style.Available;
      case DeviceUtilisationAvailability.Unknown:
        return Style.Unknown;
      default:
        return Colour.Primary;
    }
  }

  styleByPriority(value): string {
    switch (value.toLocaleLowerCase()) {
      case Priority.Critical:
        return Style['DangerWith#33333'];
      case Priority.High:
        return Style['WarningWith#33333'];
      case Priority.Moderate:
        return Style['AccentWith#33333'];
      case Priority.Low:
        return Style['PrimaryWith#33333'];
      default:
        return Style['PrimaryWith#33333'];
    }
  }

  colourByState(value: string): string {
    switch (value.toLocaleLowerCase()) {
      case State.Open:
        return Colour.Primary;
      case State.New:
        return Colour.Primary;
      case State.Draft:
        return Colour.Primary;
      case State.Validation:
        return Colour.Primary;
      case State.ImplementationPlanning:
        return Colour.Primary;
      case State.Approval:
        return Colour['#202124'];
      case State.InProgress:
        return Colour.Warning;
      case State.WorkInProgress:
        return Colour.Warning;
      case State.Implementation:
        return Colour.Warning;
      case State.AwaitingInfo:
        return Colour.Warning;
      case State.OnHold:
        return Colour.Danger;
      case State.Resolved:
        return Colour.Success;
      case State.PIR:
        return Colour.Success;
      case State.Closed:
        return Colour['#33333'];
      case State.Cancelled:
        return Colour.Danger;
      case State.Outage:
        return Colour.Warning;
      case State.Degradation:
        return Colour.Warning;
      case State.PlannedOutage:
        return Colour.Warning;
      case State.ClosedComplete:
        return Colour.Success;
      case State.CompletedMaintenance:
        return Colour.Success;
      case State.PlannedMaintenance:
        return Colour.Primary;
      case State.UpcomingMaintenance:
        return Colour.Warning;
      default:
        return Colour.Primary;
    }
  }

  colourByChangeOutcome(value: string): string {
    switch (value.toLowerCase()) {
      case Changeoutcome.CompletedSuccessful.toLowerCase():
        return Colour['#E31C79'];
      case Changeoutcome.Cancelled.toLowerCase():
        return Colour['#33333'];
      default:
        return Colour.Primary;
    }
  }

  styleByState(value: string): string {
    switch (value.toLocaleLowerCase()) {
      case State.Open:
        return Style.Open;
      case State.New:
        return Style.NEW;
      case State.Draft:
        return Style.Primary;
      case State.Validation:
        return Style.Primary;
      case State.ImplementationPlanning:
        return Style.Primary;
      case State.Approval:
        return Style.Approval;
      case State.InProgress:
        return Style.InProgress;
      case State.WorkInProgress:
        return Style.Warning;
      case State.Implementation:
        return Style.Implementation;
      case State.AwaitingInfo:
        return Style.AwaitingInfo;
      case State.OnHold:
        return Style.HoldOn;
      case State.Resolved:
        return Style.Resolved;
      case State.PIR:
      case State.Pending:
        return Style.PIR;
      case State.Closed:
        return Style.Closed;
      case State.Cancelled:
        return Style.Danger;
      case State.Amber:
        return Style.Warning;
      case State.Red:
        return Style.Danger;
      case State.Green:
        return Style.Success;
      case State.Outage:
        return Style.Warning;
      case State.Degradation:
        return Style.Warning;
      case State.PlannedOutage:
        return Style.Warning;
      case State.ClosedComplete:
        return Style.Success;
      case State.CompletedMaintenance:
        return Style.Success;
      case State.PlannedMaintenance:
        return Style.Primary;
      case State.UpcomingMaintenance:
        return Style.Primary;
      case State.Fulfilled:
        return Style.Fulfilled;
      default:
        return Style.Primary;
    }
  }

  badgeByRequestState(name: string): string {
    switch (name.toLocaleLowerCase()) {
      case RequestState.InProcess:
        return Colour.Warning;
      case RequestState.PendingApproval:
        return Colour.Primary;
      case RequestState.Approved:
        return Colour.Success;
      case RequestState.CloseCancelled:
        return Colour.Danger;
      case RequestState.ClosedComplete:
        return Colour.Success;
      case RequestState.CloseRejected:
        return Colour.Danger;
      default:
        return Colour.Primary;
    }
  }

  getFormattedStateText(name: string): string {
    switch (name.toLocaleLowerCase()) {
      case State.Open:
        return StateFormatted.Open;
      case State.New:
        return StateFormatted.New;
      case State.Draft:
        return StateFormatted.Draft;
      case State.Validation:
        return StateFormatted.Validation;
      case State.ImplementationPlanning:
        return StateFormatted.ImplementationPlanning;
      case State.Approval:
        return StateFormatted.Approval;
      case State.InProgress:
        return StateFormatted.InProgress;
      case State.WorkInProgress:
        return StateFormatted.WorkInProgress;
      case State.Implementation:
        return StateFormatted.Implementation;
      case State.AwaitingInfo:
        return StateFormatted.AwaitingInfo;
      case State.OnHold:
        return StateFormatted.OnHold;
      case State.Resolved:
        return StateFormatted.Resolved;
      case State.PIR:
        return StateFormatted.PIR;
      case State.Closed:
        return StateFormatted.Closed;
      case State.Cancelled:
        return StateFormatted.Cancelled;
      case State.Amber:
        return StateFormatted.Amber;
      case State.Green:
        return StateFormatted.Green;
      case State.Red:
        return StateFormatted.Red;
      case State.Outage:
        return StateFormatted.Outage;
      case State.Degradation:
        return StateFormatted.Degradation;
      case State.PlannedOutage:
        return StateFormatted.PlannedOutage;
      case State.ClosedComplete:
        return StateFormatted.ClosedComplete;
      case State.CompletedMaintenance:
        return StateFormatted.CompletedMaintenance;
      case State.PlannedMaintenance:
        return StateFormatted.PlannedMaintenance;
      case State.UpcomingMaintenance:
        return StateFormatted.UpcomingMaintenance;
      case State.Planning:
        return StateFormatted.Planning;
      case State['N/A']:
        return StateFormatted['N/A'];
      case State.Fulfilled:
        return StateFormatted.Fulfilled;
      default:
        return StateFormatted.Open;
    }
  }

  badgeByActive(name: Boolean): string {
    switch (name) {
      case true:
        return Colour.Success;
      case false:
        return Colour.Danger;
      default:
        return Colour.Primary;
    }
  }

  colorByStatus(value: string): string {
    switch (value.toLocaleLowerCase()) {
      case Status.Active:
        return statusColor.Active;
      case Status.Inactive:
        return statusColor.Inactive;
    }
  }
  styleByStatus(value: string): string {
    switch (value.toLocaleLowerCase()) {
      case Status.Active:
        return statusStyle.Active;
      case Status.Inactive:
        return statusStyle.Inactive;
    }
  }
  styleByPortalAccess(value: string): string {
    switch (value.toLocaleLowerCase()) {
      case Portal.Yes:
        return portalStyle.Yes;
      case Portal.No:
        return portalStyle.No;
    }
  }
  colorByPortalAccess(value: string): string {
    switch (value.toLocaleLowerCase()) {
      case Portal.Yes:
        return portalColor.Yes;
      case Portal.No:
        return portalColor.No;
    }
  }

  colorByCommentType(value: string): string {
    switch (value.toLocaleLowerCase()) {
      case commentType.Comment:
        return CommentColor.Comment;
      case commentType.Record:
        return CommentColor.Record;
      default:
        return CommentColor.Comment;
    }
  }

  styleByCommentType(value: string): string {
    switch (value.toLocaleLowerCase()) {
      case commentType.Comment:
        return CommentStyle.Comment;
      // case commentType.Record : return CommentStyle.Record
      default:
        return CommentStyle.Comment;
    }
  }

  mobileGraphUrlByDataType(graphType: string) {
    switch (graphType) {
      case MobileGraphDataType.Data:
        return MobileGraphUrl.Data;
      case MobileGraphDataType.Minutes:
        return MobileGraphUrl.Minutes;
      case MobileGraphDataType.Texts:
        return MobileGraphUrl.Texts;
    }
  }
}

export enum MobileGraphDataType {
  Data = 'Data',
  Minutes = 'Minutes',
  Texts = 'Texts',
}

export enum MobileGraphUrl {
  Data = 'Telephony/GetDataGraph',
  Minutes = 'Telephony/GetMinutesGraph',
  Texts = 'Telephony/GetTextsGraph',
}

// Enumerators used.
export enum Priority {
  'CoreOutage' = '0 - core outage',
  'Critical' = '1 - critical',
  'High' = '2 - high',
  'Moderate' = '3 - moderate',
  'Low' = '4 - low',
  'Planning' = '5 - planning',
}

export enum DeviceUtilisationPollingStatus {
  Paused = 'paused',
  Unknown = 'unknown',
  Active = 'active',
  Maintenance = 'maintenance',
}

export enum DeviceUtilisationAvailability {
  Unavailability = 'unavailable',
  Availability = 'available',
  Unknown = 'unknown',
}

export enum State {
  'New' = 'new',
  'Open' = 'open',
  'Draft' = 'draft',
  'Validation' = 'validation',
  'ImplementationPlanning' = 'implementation planning',
  'Approval' = 'approval',
  'Implementation' = 'implementation',
  'PIR' = 'pir',
  'WorkInProgress' = 'work in progress',
  'InProgress' = 'in progress',
  'AwaitingInfo' = 'awaiting info',
  'OnHold' = 'on hold',
  'Resolved' = 'resolved',
  'Closed' = 'closed',
  'Cancelled' = 'cancelled',
  'Green' = 'green',
  'Amber' = 'amber',
  'Red' = 'red',
  'Outage' = 'outage',
  'Degradation' = 'degradation',
  'PlannedOutage' = 'planned maintenance',
  'ClosedComplete' = 'closed complete',
  'CompletedMaintenance' = 'completed maintenance',
  'PlannedMaintenance' = 'planned maintenance',
  'UpcomingMaintenance' = 'upcoming maintenance',
  'Pending' = 'pending',
  'Planning' = 'planning',
  'N/A' = 'n/a',
  Fulfilled = 'fulfilled',
}

export enum Changeoutcome {
  CompletedSuccessful = 'Completed Successful',
  Cancelled = 'Cancelled',
}

export enum StateFormatted {
  'New' = 'New',
  'Open' = 'Open',
  'Draft' = 'Draft',
  'Validation' = 'Validation',
  'ImplementationPlanning' = 'Implementation Planning',
  'Approval' = 'Approval',
  'Implementation' = 'Implementation',
  'PIR' = 'PIR',
  'WorkInProgress' = 'In Progress',
  'InProgress' = 'In Progress',
  'AwaitingInfo' = 'Awaiting Info',
  'OnHold' = 'On Hold',
  'Resolved' = 'Resolved',
  'Closed' = 'Closed',
  'Cancelled' = 'Cancelled',
  'Amber' = 'Amber',
  'Green' = 'Green',
  'Red' = 'Red',
  'Outage' = 'Outage',
  'Degradation' = 'Degradation',
  'PlannedOutage' = 'Planned Maintenance',
  'ClosedComplete' = 'Closed',
  'CompletedMaintenance' = 'Completed Maintenance',
  'PlannedMaintenance' = 'Planned Maintenance',
  'UpcomingMaintenance' = 'Upcoming Maintenance',
  'Planning' = 'Planning',
  'N/A' = 'N/A',
  Fulfilled = 'Fulfilled',
}

export enum RequestState {
  'InProcess' = 'in process',
  'PendingApproval' = 'pending approval',
  'Approved' = 'approved',
  'CloseCancelled' = 'close cancelled',
  'ClosedComplete' = 'closed complete',
  'CloseRejected' = 'close rejected',
}

export enum Urgency {
  'High' = '1',
  'Medium' = '2',
  'Low' = '3',
}

export enum Active {
  'true' = '1',
  'false' = '2',
}

export enum Colour {
  'Danger' = 'mat-danger',
  'Warning' = 'mat-warn',
  'Accent' = 'mat-accent',
  'Primary' = 'mat-primary',
  'Success' = 'mat-success',
  '#33333' = '#33333',
  '#202124' = '#202124',
  '#E31C79' = '#E31C79',
  '#00B0A3' = '#00B0A3',
  '#A40D49' = '#A40D49',
  '#F6F5F8' = ' #F6F5F8',
}

export enum Style {
  'Danger' = `background-color: #E31C79 !important; color: #fff;`,
  'Warning' = 'background-color: #e31c79 !important;',
  'Accent' = 'background-color: #00a4e4 !important;',
  'Primary' = 'background-color: #333333 !important;',
  'Success' = 'background-color: #333333 !important; color: #fff;',
  'DangerWith#33333' = 'font-weight: bold; background-color: #E31C79 !important; color: rgba( 51, 51, 58) !important;',
  'WarningWith#33333' = 'font-weight: bold;background-color: #e31c79 !important; color: rgba( 51, 51, 58) !important;',
  'AccentWith#33333' = 'font-weight: bold; background-color: #00a4e4 !important; color: rgba( 51, 51, 58) !important;',
  'PrimaryWith#33333' = 'font-weight: bold; background-color: #333333 !important; color: rgba( 51, 51, 58) !important;',
  'SuccessWith#33333' = 'font-weight: bold; font-weight: bold; background-color: #333333 !important; color: rgba( 51, 51, 58) !important;',
  'Closed' = 'background-color: #E0E0E0 !important; color: rgba( 51, 51, 58) !important;',
  'Approval' = 'background-color: #28DDCF !important; color: rgba( 32, 33, 36) !important;',
  'HoldOn' = 'background-color: #532973 !important; color: rgba( 246, 245, 248) !important;',
  'Implementation' = 'background-color: #FE418D !important; color: rgba( 32, 33, 36) !important;',
  'PIR' = 'background-color: #A40D49 !important; color: rgba( 246, 245, 248) !important;',
  'NEW' = 'background-color: #FB760D !important; color: rgba( 51, 51, 58) !important;',
  'InProgress' = 'background-color: #FE418D !important; color: rgba( 32, 33, 36) !important;',
  'AwaitingInfo' = 'background-color: #532973 !important; color: rgba( 246, 245, 248) !important;',
  'Resolved' = 'background-color: #28DDCF !important; color: rgba( 32, 33, 36) !important;',
  'Open' = 'background-color: #28DDCF !important; color: rgba( 51, 51, 58) !important;',
  'Fulfilled' = 'background-color: #28DDCF !important; color: rgba( 32, 33, 36) !important;',
  'Unavailability' = 'background-color: #E0E0E0 !important; color: rgba( 51, 51, 51) !important;border-radius: 0.5rem;',
  'Available' = 'background-color: #28DDCF !important; color: rgba( 32, 33, 36) !important;border-radius: 0.5rem;',
  'Unknown' = 'background-color: #333333 !important; color: rgba( 246, 245, 248) !important;border-radius: 0.5rem;',
}
export enum statusColor {
  'Active' = 'mat-active',
  'Inactive' = 'mat-inactive',
}
export enum portalColor {
  'Yes' = 'mat-yes',
  'No' = 'mat-no',
}

export enum CommentColor {
  'Comment' = 'color: #202124',
  'Record' = 'color: #333333',
}

// Record background not yet provided
export enum CommentStyle {
  'Comment' = 'background-color: #28DDCF; border-radius: 0.5rem; padding: 2px 8px;',
  // "Record" = ""
}

export enum Status {
  'Active' = 'active',
  'Inactive' = 'inactive',
}

export enum statusStyle {
  'Active' = 'background-color: #28DDCF  !important; color:#000000;',
  'Inactive' = 'background-color:#E0E0E0 !important; color:#333333;',
}

export enum Portal {
  'Yes' = 'yes',
  'No' = 'no',
}

export enum portalStyle {
  'Yes' = 'color:#00B0A3 !important; background-color:#FFFFFF !important; font-weight: 700;',
  'No' = 'color:#333333; font-weight: 700; background-color:#FFFFFF !important;',
}

export enum commentType {
  'Comment' = 'comment',
  'Record' = 'record',
}
