import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { PermissionService } from 'src/app/services/permissions.service';
import { RoleManagementService } from '../shared/role-management.service';
import { PermissionsService } from 'src/app/services/api/permissions/permissions.service';
import {
  IRoleInterface,
  ISelectedRole,
  RolesColumns,
} from '../shared/role-models';
import { MenuItem } from 'primeng/api/menuitem';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.scss'],
})
export class RoleManagementComponent implements OnInit {
  tableColums: IRoleInterface[];
  rolesSubject = new BehaviorSubject(null);
  roles$ = this.rolesSubject.asObservable();
  selectedRoleId: string | null = null;
  roles: any[];
  companyRoles: any[];
  showEditColumn: boolean = false;
  loading: boolean = false;

  constructor(
    public router: Router,
    public roleManagementService: RoleManagementService,
    private permissionService: PermissionService,
    private permissionsService: PermissionsService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit(): Promise<void> {
    this.selectedRoleId = this.route.snapshot.paramMap.get('id');
    const breadcrumbs: MenuItem[] = [
      {
        label: 'Roles',
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.tableColums = RolesColumns;
    this.showEditColumn = this.loadEditColumn();
    await this.loadRoles();
  }

  async loadRoles(): Promise<void> {
    this.loading = true;
    this.companyRoles = await firstValueFrom(
      this.permissionsService.getRoles()
    );
    this.companyRoles.sort((a, b) => (a.name > b.name ? 1 : -1));
    let result = this.companyRoles;

    this.rolesSubject.next(result);
    this.loading = false;
  }

  loadEditColumn(): boolean {
    return this.permissionService.checkPermission(
      'COMPANY_ROLES_CREATE_UPDATE'
    );
  }

  viewRole(selectedRow: ISelectedRole): void {
    this.router.navigateByUrl('secure/company/roles/' + selectedRow.sysId);
  }

  openAddRole(): void {
    this.router.navigateByUrl('secure/company/roles/add');
  }

  openEditRole(selectedRow: ISelectedRole): void {
    this.router.navigateByUrl(`secure/company/roles/edit/${selectedRow.sysId}`);
    //this.router.navigateByUrl("secure/company/roles/edit");
  }

  editRoleClicked(selectedRow: ISelectedRole): void {
    this.openEditRole(selectedRow);
  }
}
