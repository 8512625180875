import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { ContactsService } from 'src/app/services/api/contacts/contacts.service';
import { Contact } from 'src/app/models/contacts/contacts-model';
import { PermissionsService } from 'src/app/services/api/permissions/permissions.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EditUserPermissionsComponent } from 'src/app/components/misc/pop-up/edit-user-permissions/edit-user-permissions.component';
import { ServiceNowValidatorService } from 'src/app/services/existing-service-now-validator.service';
import { MenuItem } from 'primeng/api';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import { mobileNumberValidator } from 'src/app/helpers/mobile-validator.directive';
import { addEditNumberFormatter } from 'src/app/helpers/mobile.helper';

enum PageContext {
  Add = 'Add',
  Edit = 'Edit',
}

const entryStep = {
  label: 'Details',
  visible: true,
};
const reviewStep = {
  label: 'Review',
  visible: true,
};

interface Role {
  sysId: string;
  name: string;
  default: boolean;
  description: string;
}

@Component({
  selector: 'app-add-edit-users',
  templateUrl: './add-edit-users.component.html',
  styleUrls: ['./add-edit-users.component.scss'],
})
export class AddEditUsersComponent implements OnInit {
  steps: MenuItem[] | undefined = [entryStep, reviewStep];

  activeStepIndex = 0;
  activeStep: MenuItem = this.steps[0];

  selectedAddEditUserType = 'multiple';

  ref: DynamicDialogRef | undefined;

  PageContext = PageContext;
  context: PageContext = PageContext.Add;

  activeOptions: { label: string; value }[] = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  portalAccessOptions: { label: string; value }[] = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  usersForm: FormGroup = this.formBuilder.group({
    users: this.formBuilder.array([]),
  });

  loading = true;

  roles: Role[] = [];

  get users(): FormArray {
    return this.usersForm.get('users') as FormArray;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contactsService: ContactsService,
    private formBuilder: UntypedFormBuilder,
    private permissionsService: PermissionsService,
    private dialogService: DialogService,
    private existingServiceNowValidator: ServiceNowValidatorService,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    const breadcrumbs: MenuItem[] = [
      { label: 'Users', routerLink: '/secure/company/users/' },
      { label: this.getBreadCrumbHeading() },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.loading = true;
    const params: Params = this.route.snapshot.params;
    const userIds: string[] = params?.users?.length
      ? JSON.parse(params?.users)
      : null;
    await this.getRoles();
    if (userIds?.length) {
      await this.getContacts(userIds);
    }
    if (!userIds) {
      this.addUser();
    }
    this.loading = false;
  }

  addUser(user?: Contact) {
    const userForm = this.formBuilder.group({
      firstName: [user?.firstName ?? '', Validators.required],
      lastName: [user?.lastName ?? '', Validators.required],
      email: [
        user?.email ?? '',
        {
          validators: [Validators.required, Validators.email],
          updateOn: 'blur',
          asyncValidators: [
            this.existingServiceNowValidator.emailExists(user?.email ?? ''),
          ],
        },
      ],
      mobilePhone: [
        user?.mobilePhone ? addEditNumberFormatter(user?.mobilePhone) : '',
        [mobileNumberValidator()],
      ],
      active: [user?.active ?? true, Validators.required],
      role: [user?.role ?? null],
      permissions: [user?.permissions ?? []],
    });
    if (this.context === PageContext.Edit) {
      userForm.setControl('sysId', new FormControl(user?.sysId ?? ''));
    }
    this.users.push(userForm);
  }

  async addEditPermissions(userForm: FormGroup) {
    const user = userForm.value;
    const role = this.roles.find(role => role.sysId === user.role);
    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    const ref = this.dialogService.open(EditUserPermissionsComponent, {
      header: 'Additional Permissions',
      width: '80%',
      data: {
        role: role,
        userPermissions: user.permissions,
      },
    });
    ref.onClose.subscribe(async permissions => {
      if (!Array.isArray(permissions)) {
        return;
      }
      userForm.get('permissions').setValue(permissions);
    });
  }

  async getContacts(userIds: string[]) {
    this.context = PageContext.Edit;
    const users = await firstValueFrom(
      this.contactsService.getContacts(userIds)
    );
    if (users) {
      for (const user of users) {
        this.addUser(user);
      }
    }
  }

  async getRoles() {
    this.roles = [
      ...[{ sysId: null, name: 'None' }],
      ...(await firstValueFrom(this.permissionsService.getRoles())),
    ];
  }

  getRoleName(sysId): string {
    return this.roles.find(role => role.sysId === sysId).name;
  }

  async deleteUser(index: number) {
    this.users.removeAt(index);
  }

  async changeView(step: number) {
    this.activeStepIndex = step;
  }

  async submit() {
    if (!this.usersForm.valid) {
      return;
    }
    this.loading = true;
    try {
      const users = this.usersForm.value.users.map(user => {
        if (user.mobilePhone) {
          user.mobilePhone = addEditNumberFormatter(user.mobilePhone);
        }
        return user;
      });
      if (this.context === PageContext.Add) {
        await firstValueFrom(
          this.contactsService.createContacts(
            users.map(user => {
              return {
                ...user,
                permissions:
                  user.role && user.permissions?.length
                    ? [...new Set(user.permissions)]
                    : [],
              };
            })
          )
        );
      }
      if (this.context === PageContext.Edit) {
        await firstValueFrom(
          this.contactsService.editContacts(
            users.map(user => {
              return {
                ...user,
                permissions: user.role ? [...new Set(user.permissions)] : [],
              };
            })
          )
        );
      }
      this.router.navigate(['secure/company/users']);
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
  }

  onSingleUserSelect() {
    this.router.navigate(['secure/company/users/add']);
  }

  getBreadCrumbHeading(): string {
    if (window.location.href.includes('edit')) {
      return 'Edit Users';
    } else {
      return 'Add Users';
    }
  }

  backButton(): void {
    this.router.navigate(['secure/company/users']);
  }
}
