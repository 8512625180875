import { Injectable } from '@angular/core';
import { APIEndpoints } from 'src/app/models/endpoints';
import {
  IBar,
  IBarCategory,
  IBarCategoryContainer,
  IBarContainer,
  IMultiBarContainer,
  IUpdatedBarControls,
} from '../models/telephony-models';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { AbzordService } from 'src/app/services/api/abzord/abzord.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BarringService {
  storedMobileNumber: string = '';

  constructor(private abzordService: AbzordService) {}

  async loadMutipleNumbers(mobileNumber) {
    const cliNumbers: string[] = mobileNumber.map(
      mobileNumber => mobileNumber.cliNumber
    );
    const combinedCliNumbers: string = cliNumbers.join(', ');
    return combinedCliNumbers;
  }

  async loadMutipleBars(mobileNumber: string) {
    const bars: IBarContainer = await firstValueFrom(
      this.abzordService.getBars(mobileNumber)
    );

    return bars;
  }

  async loadBars(mobileNumber: string) {
    const bars: IBarContainer = await firstValueFrom(
      this.abzordService.getBars(mobileNumber)
    );

    return bars;
  }

  async loadActiveBars(mobileNumber: string) {
    const bars: IBarContainer = await firstValueFrom(
      this.abzordService.getActiveBars(mobileNumber)
    );

    return bars;
  }

  async loadBarsCategorised(mobileNumber: string) {
    const barData: IBarCategoryContainer = await firstValueFrom(
      this.abzordService.getBarsCategorised(mobileNumber)
    );

    return barData;
  }

  async toggleBars(request: IBarContainer) {
    const response = await firstValueFrom(
      this.abzordService.toggleBars(request)
    );
    return response;
  }

  async toggleBarsForMultipleCLI(requests: IMultiBarContainer[]) {
    let toggleBarRequests: Promise<any>[] = [];
    requests.forEach(request => {
      toggleBarRequests.push(
        firstValueFrom(this.abzordService.toggleMultipleBars(request))
      );
    });
    let response = await Promise.all(toggleBarRequests);
    return response;
  }

  cssSetDropdownStyle(dropdownValue) {
    if (dropdownValue === true) return 'dropdown-on';
    else if (dropdownValue === false) return 'dropdown-off';
    else return 'dropdown-no-change';
  }

  cssDisableSelectedOption(bar: FormControl, option: boolean) {
    if (bar.value.active === option) return 'existing-selection';
    else return '';
  }

  getConfirmTextColor(isActive: boolean): string {
    return isActive ? '#E31C79' : '#333333';
  }
}
