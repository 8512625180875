<div class="row" style="margin-top: 25px">
  <div class="col-xs-12">
    <div class="page-content-container" blocker="Current Usage">
      <div class="row">
        <div class="col-xs-12">
          <h4 style="font-size: 26px; padding-bottom: 5px">Current Usage</h4>
        </div>
      </div>
      <div class="card-border-break"></div>
      <br />
      <div style="padding: 0 25px">
        <div class="row">
          @for (usage of currentUsage; track usage) {
            <div class="col-xs-12 col-md-4" style="text-align: center">
              <progress-circle
                [percent]="usage.percentage"
                [title]="usage.title"
              [subtitle]="usage.subtitle"></progress-circle>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" style="margin-top: 25px">
  <div class="col-xs-12">
    <div class="page-content-container" style="padding-bottom: 15px">
      <div class="row">
        <div class="col-xs-12">
          <h4 style="font-size: 26px; padding-bottom: 5px; overflow: auto">
            Monthly Usage
          </h4>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row nowrap" style="margin-top: 25px">
  <div class="col-xs-12 col-md-4 margin-both" blocker="Loading Data">
    <servicehub-graph
      [data]="graphs['Data']"
    [legend]="false"></servicehub-graph>
  </div>
  <div class="col-xs-12 col-md-4 margin-right" blocker="Loading Minutes">
    <servicehub-graph
      [data]="graphs['Minutes']"
    [legend]="false"></servicehub-graph>
  </div>
  <div class="col-xs-12 col-md-4 margin-left" blocker="Loading Texts">
    <servicehub-graph
      [data]="graphs['Texts']"
    [legend]="false"></servicehub-graph>
  </div>
</div>
