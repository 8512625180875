import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Message } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-expedite-incident',
  templateUrl: './expedite-incident.component.html',
  styleUrls: ['./expedite-incident.component.scss'],
})
export class ExpediteIncidentComponent implements OnInit {
  detail: Message[] | undefined;
  expediteForm = new FormGroup({
    expediteReason: new FormControl('', Validators.required),
  });

  constructor(public ref: DynamicDialogRef) {}

  ngOnInit() {
    this.detail = [
      {
        severity: 'warn',
        detail:
          'Please do not submit a further expedite request until a review has taken place.',
      },
    ];
  }

  closeButton(): void {
    this.ref.close();
  }

  expediteIncident(): void {
    this.ref.close(
      `Expedite Request: ${this.expediteForm.value?.expediteReason}`
    );
  }
}
