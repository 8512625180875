import { Component, ViewChild } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { Location } from 'src/app/components/service-hub/tickets/shared/incident-model';
import { LocationService } from 'src/app/services/api/location/location.service';
import {
  DynamicDialogConfig,
  DynamicDialogRef,
  DialogService,
} from 'primeng/dynamicdialog';
import {
  IGetLocationsResponse,
  ILocationColumn,
  locationColumns,
} from '../models/location-model';
import { Table } from 'primeng/table';
import { LazyLoadEvent } from 'primeng/api';
import { TableColumn } from 'src/app/models/table';
import { IsPaginatorState, TableReset } from 'src/app/helpers/table.helper';
import { BuildServiceNowQuery } from 'src/app/helpers/servicenow/filter/servicenow-filter.helper';
import { Paginator, PaginatorState } from 'primeng/paginator';

@Component({
  selector: 'app-location-dialog',
  templateUrl: './location-dialog.component.html',
  styleUrls: ['./location-dialog.component.scss'],
  providers: [DialogService],
})
export class LocationDialogComponent {
  @ViewChild('dataTable', { static: true }) dataTable: Table;
  @ViewChild('tablePaginator') paginator: Paginator;
  firstLoad: boolean = true;
  location: Location;
  locationSubject = new BehaviorSubject<Array<IGetLocationsResponse>>([]);
  locations$ = this.locationSubject.asObservable();
  columns: TableColumn[] = locationColumns;
  tableColumn: ILocationColumn[];
  selectedLocation!: Location;
  totalRecords: number = 0;
  loading: boolean = false;

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private locationService: LocationService
  ) {}

  async ngOnInit() {}

  async getLocations(
    table: Table,
    event?: LazyLoadEvent | PaginatorState
  ): Promise<void> {
    if (IsPaginatorState(event)) {
      table.rows = event?.rows;
      table.first = event?.first;
    }
    this.loading = true;
    const isFirstLoad = this.firstLoad ? true : false;
    table.loading = true;

    this.locationSubject.next([]);

    if (isFirstLoad) {
      this.reset(table, true, cloneDeep(locationColumns));
      this.firstLoad = false;
    }
    const currentPage =
      event?.first != null && event?.rows != null
        ? event.first / event.rows
        : table.first / table.rows;
    const currentPerPage = event?.rows ? event.rows : table.rows;

    let serviceNowFilter = BuildServiceNowQuery(
      table,
      this.columns,
      currentPerPage,
      currentPage,
      event
    );
    const response = await firstValueFrom(
      this.locationService.getIncidentLocations(serviceNowFilter)
    );
    this.locationSubject.next(response.result);
    this.totalRecords = response.overallQueryCount;
    table.loading = false;

    if (!IsPaginatorState(event)) {
      if (this.paginator) {
        this.paginator.first = 0;
      }
    }
    this.loading = false;
  }

  handleLocationRowClicked(event: Location): void {
    this.location = event;
  }

  closeDialog(): void {
    this.ref.close(this.selectedLocation);
  }

  cancelButton(): void {
    this.ref.close();
  }

  reset(
    table: Table,
    firstLoad: boolean = false,
    columns: TableColumn[] = locationColumns
  ) {
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
    });
  }
}
