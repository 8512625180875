<div class="bg-white shadow-2 m-2 py-2">
  @if (canViewAllCompanys) {
    @if (!loading) {
      <p-dropdown
        [options]="companys"
        optionLabel="name"
        [filter]="true"
        filterBy="name"
        placeholder="Select a Company"
        [(ngModel)]="selectedCompany"
      (onChange)="setCompanyId()"></p-dropdown>
    }
  }
  @if (loading) {
    <loading-spinner />
  }
  @if (!loading && errors?.length) {
    <p-messages
      [(value)]="errors"
      [enableService]="false"
      [closable]="true"
    [escape]="false"></p-messages>
  }
  <div #dashboardContainer id="dashboard-container"></div>
</div>
