<div class="flex flex-column h-full">
  <div
    class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200">
    <h1 class="px-3 text-xl">{{ pageTitle }}</h1>
  </div>
  <div
    class="flex-grow-1 overflow-auto mx-3 bg-white border-solid border-1 border-gray-200 border-top-none border-bottom-none px-3">
    <div class="flex justify-content-center">
      <p-steps
        class="w-full max-w-60rem"
        [model]="steps"
        [readonly]="true"
        [activeIndex]="activeStepIndex"
        (activeIndexChange)="onActiveIndexChange($event)"></p-steps>
    </div>
    <div class="flex justify-content-center flex-grow-1 w-full">
      @if (loading) {
        <loading-spinner></loading-spinner>
      }
      @if (!loading) {
        @if (activeStep.label === 'Details') {
          <app-add-edit-user-details
            class="w-full max-w-60rem"
            (emailValueChange)="onEmailValueChange($event)"
            (childIsLoading)="setChildIsLoading($event)"
            [addEditUserDetailsForm]="addEditUserForm.get('details')"
            [checkEmailExistsStatus]="checkEmailExistsStatus"
            [userPortals]="userPortals"
            [pageTitle]="pageTitle"></app-add-edit-user-details>
        }
        @if (activeStep.label === 'Role') {
          <app-add-edit-user-role
            class="w-full max-w-30rem"
            (childIsLoading)="setChildIsLoading($event)"
            [roles]="roles"
            [addEditUserRoleForm]="
              addEditUserForm.get('selectRole')
            "></app-add-edit-user-role>
        }
        @if (activeStep.label === 'Permissions') {
          <app-add-edit-user-permissions
            class="w-full max-w-60rem"
            (childIsLoading)="setChildIsLoading($event)"
            [selectedRole]="
              addEditUserForm?.get('selectRole')?.get('role')?.value
            "
            [addEditUserPermissionsForm]="
              addEditUserForm?.get('permissions')
            "></app-add-edit-user-permissions>
        }
        @if (activeStep.label === 'Additional') {
          <app-add-edit-user-additional
            class="w-full max-w-60rem"
            (childIsLoading)="setChildIsLoading($event)"
            [addEditUserPermissionsForm]="addEditUserForm?.get('permissions')"
            [addEditUserAdditionalForm]="
              addEditUserForm?.get('additional')
            "></app-add-edit-user-additional>
        }
        @if (activeStep.label === 'Review') {
          <app-add-edit-user-review
            class="w-full max-w-60rem"
            (childIsLoading)="setChildIsLoading($event)"
            [addEditUserForm]="addEditUserForm"
            [pageTitle]="pageTitle"></app-add-edit-user-review>
        }
      }
    </div>
    @if (!loading) {
      <div class="flex justify-content-center py-3">
        <div class="flex justify-content-center max-w-60rem w-full">
          @if (activeStepIndex !== 0) {
            <p-button
              styleClass="p-button-secondary"
              label="Back"
              (onClick)="stepBack()" />
          }
          @if (activeStepIndex === 0) {
            <p-button
              styleClass="p-button-secondary"
              label="Back"
              (onClick)="backButton()" />
          }
          @if (activeStepIndex !== steps.length - 1) {
            <p-button
              label="Next"
              [disabled]="
                (activeStepIndex === 0 &&
                  addEditUserForm.get('details').invalid) ||
                (activeStepIndex === 1 &&
                  addEditUserForm.get('selectRole').invalid) ||
                checkEmailExistsStatus === 'PENDING' ||
                childIsLoading
              "
              (onClick)="stepForward()" />
          }
          @if (activeStepIndex === steps.length - 1) {
            <p-button label="Save" (onClick)="saveUser()" />
          }
        </div>
      </div>
    }
  </div>
</div>
