@if (!tariffData) {
  <loading-spinner />
}
@if (tariffData) {
  <div class="grid flex-nowrap pl-3">
    <div class="col-4">
      <div class="shadow-2">
        <div class="flex flex-column h-full">
          <div class="flex justify-content-between align-items-center px-3">
            <h1 class="text-xl m-0">Tariff Information</h1>
            @if (amendBars) {
              <p-button
                (onClick)="navigateToEditBars()"
                label="Amend Options"
                icon="pi pi-pencil"
              iconPos="left"></p-button>
            }
          </div>
          <hr class="w-full" />
          <div class="flex flex-wrap justify-center pl-4">
            <div class="col">
              <div class="my-3">
                <label class="text-gray-600 text-lg m-0">Number</label>
                <p class="text-lg m-0 h-2rem">{{ mobileNumber }}</p>
                <hr class="w-full" />
              </div>
              <div class="my-3">
                <label class="text-gray-600 text-lg m-0">Service Provider</label>
                <p class="text-lg m-0">{{ activeBars?.network }}</p>
                <hr class="w-full" />
              </div>
              <div class="my-3">
                <label class="font-bold text-xl m-0">Bundles</label>
                <ul>
                  @for (bundleName of tariffData?.bundleNames; track bundleName) {
                    <li>
                      {{ bundleName }}
                    </li>
                  }
                </ul>
                <hr class="w-full" />
              </div>
              <div class="my-3">
                <label class="font-bold text-xl m-0">Options and Bars</label>
                @if (activeBars?.bars?.length > 0) {
                  <ul>
                    @for (bar of activeBars?.bars; track bar) {
                      <li>{{ bar.name }}</li>
                    }
                  </ul>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-8 mt-2">
      <div class="grid">
        <div class="col-12 shadow-2">
          <div class="flex flex-column h-full px-3">
            <h1 class="text-xl m-0">Current Usage</h1>
            <hr class="w-full" />
            <div class="grid">
              <div class="col-4">
                <div class="text-center">
                  <label class="text-lg">Data Used</label>
                  <p class="text-5xl m-0 pt-2">
                    {{ tariffData?.bundleDetails?.usedMB ?? 'N/A' }}
                    /
                  </p>
                  <p>
                    {{ tariffData?.bundleMB }}
                  </p>
                </div>
              </div>
              <div class="col-4">
                <div class="text-center">
                  <label class="text-lg">Minutes</label>
                  <p class="text-5xl m-0 pt-2">
                    {{ tariffData?.bundleDetails?.usedMinutes ?? 'N/A' }}
                    /
                  </p>
                  <p>
                    {{ tariffData?.bundleMins }}
                  </p>
                </div>
              </div>
              <div class="col-4">
                <div class="text-center">
                  <label class="text-lg">Texts</label>
                  <p class="text-5xl m-0 pt-2">
                    {{ tariffData?.bundleDetails?.usedTexts ?? 'N/A' }}
                    /
                  </p>
                  <p>
                    {{ tariffData?.bundleTexts }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 shadow-2 mt-4">
          <div class="flex flex-column h-full px-3">
            <h1 class="text-xl m-0">Usage Overview</h1>
            <hr class="w-full" />
            <div class="text-center">
              <p-tabView>
                <p-tabPanel header="Data Used">
                  <ng-template pTemplate="content">
                    <app-e-bar-chart [graphData$]="dataGraph$"></app-e-bar-chart>
                  </ng-template>
                </p-tabPanel>
                <p-tabPanel header="Minutes">
                  <ng-template pTemplate="content"
                    ><app-e-bar-chart
                    [graphData$]="minuteGraph$"></app-e-bar-chart
                  ></ng-template>
                </p-tabPanel>
                <p-tabPanel header="Texts">
                  <ng-template pTemplate="content"
                    ><app-e-bar-chart [graphData$]="textGraph$"></app-e-bar-chart
                  ></ng-template>
                </p-tabPanel>
              </p-tabView>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
