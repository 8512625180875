@if (loading) {
  <loading-spinner />
}
@if (!loading) {
  <p class="m-0 mb-2">
    To stay informed, we encourage you to subscribe to our notifications for
    upcoming service maintenance. The notifications will be sent via email.
  </p>
  <h2 class="text-xl m-0 mb-2">Subscribe Service</h2>
  <div class="flex flex-column pb-4 gap-3 pl-2">
    @for (service of serviceNotifications; track service) {
      <div class="flex items-center gap-2">
        <p-checkbox [binary]="true" [(ngModel)]="service.subscribed" />
        <p class="flex-1 p-0 m-0">{{ service.name }}</p>
      </div>
    }
  </div>
  <div class="flex align-content-center justify-content-center pt-1 pb-2">
    <p-button
      severity="secondary"
      label="Cancel"
      (onClick)="cancelButton()"></p-button>
    <p-button label="Save" (onClick)="saveSubscriptions()"></p-button>
  </div>
}
