<div
  class="flex align-items-center justify-content-center h-screen bg-gray-100">
  <div class="grid mr-0 p-3">
    <div class="col-12 md:col-8 flex align-items-center">
      <div>
        <h2 class="text-2xl m-0">
          <h2 class="block text-2xl m-0">Opps,</h2>
          you don't have the permission of this portal.
        </h2>
        <p>Please contact your company administrator to setup your account.</p>
        <p-button (onClick)="goBackButton()" label="Go Back"></p-button>
        <p-button
          styleClass="p-button-secondary"
          (onClick)="backToDashBoardButton()"
          label="Go to Dashboard"></p-button>
      </div>
    </div>
    <div class="col-12 md:col-4">
      <img
        src="../../../../assets/gifs/403/403.svg"
        class="error-403"
        alt="403 Image" />
    </div>
  </div>
</div>
