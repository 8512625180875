<ng-container>
  <h2>What role would you like to assign the user?</h2>
  <form class="flex flex-column gap-3" [formGroup]="addEditUserRoleForm">
    @for (role of roles; track role) {
      <div class="field-checkbox">
        <p-radioButton
          [inputId]="role.sysId"
          [value]="role"
        formControlName="role"></p-radioButton>
        <label [for]="role.sysId" class="ml-2">{{ role.name }}</label>
        @if (role?.name !== 'None') {
          <i
            class="pi pi-info-circle ml-2"
          (click)="openRoleInfo($event, role)"></i>
        }
      </div>
    }
  </form>
</ng-container>
