import { Injectable } from '@angular/core';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';

@Injectable({
  providedIn: 'root',
})
export class TicketsService {
  constructor(private apiRequest: ApiRequest) {}

  getTicketsOpenedClosed(amount: number) {
    const requestProps: IRequestOptions = {
      endpoint: 'Tickets/GetTicketsOpenedClosed',
      body: amount,
    };
    return this.apiRequest.request(requestProps);
  }

  getClosedIncidentsPriority(amount: number) {
    const requestProps: IRequestOptions = {
      endpoint: 'Incident/GetClosedIncidentsPriority',
      body: amount,
    };
    return this.apiRequest.request(requestProps);
  }
}
