import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import { LazyLoadEvent } from 'primeng/api';
import { MenuItem } from 'primeng/api/menuitem';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { BuildServiceNowQuery } from 'src/app/helpers/servicenow/filter/servicenow-filter.helper';
import { SetSelectedColumns, TableReset } from 'src/app/helpers/table.helper';
import {
  ChangeRequestClosedStateValues,
  ChangeRequestOpenStateValues,
  ChangeRequestRequestSizeLimit,
  ChangeRequestTableColumnName,
  ChangeRequestTabs,
  GetMultipleChangeRequestResponse,
} from 'src/app/models/change/change.models';
import { TableColumn, TableColumnColor } from 'src/app/models/table';
import { ChangeRequestService } from 'src/app/services/api/change-request/change-request.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import { IUserProfile } from '../../company/users/shared/user-models';
import {
  ExportTable,
  GetExportItemsFromFullArray,
} from '../../products-and-services/science-logic/cmdb-devices/shared/export-devices';
import {
  ChangeRequestClosedColumns,
  ChangeRequestOpenColumns,
} from './change-request.columns';
import { DigitalSpaceTabMenuItem } from 'src/app/components/misc/digital-space-tab-menu/digital-space-tab-menu.component';
import { ServiceNowFilterQuery } from 'src/app/models/servicenow.model';
import { ConvertServiceNowDateToJsDate } from 'src/app/helpers/date.helper';
import { DateTime } from 'luxon';
import { ExportName, ExportView } from 'src/app/models/export/export-pdf.model';

@Component({
  selector: 'change-requests',
  templateUrl: 'change-requests.component.html',
  styleUrls: ['change-requests.component.scss'],
})
export class ChangeRequestsComponent implements OnInit {
  @ViewChild('dataTable') dataTable: Table;
  @ViewChild('tablePaginator') paginator: Paginator;
  change;
  user: IUserProfile;

  changeRequestsSubject = new BehaviorSubject(
    [] as GetMultipleChangeRequestResponse[]
  );
  changeRequests$ = this.changeRequestsSubject.asObservable();
  totalRecords = 0;
  tableLoading = false;
  loading: boolean;
  companyToggle = true;
  hasCompanyToggle: boolean;
  tableFilters: LazyLoadEvent;
  firstLoad = true;
  cols: TableColumn[] = cloneDeep(ChangeRequestOpenColumns);
  defaultRowsShown = 25;
  _selectedColumns: TableColumn[] = [];
  staticTable: Table;

  get selectedColumns(): TableColumn[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: TableColumn[]) {
    this._selectedColumns = this.cols.filter(col =>
      val.map(valCol => valCol.header).includes(col.header)
    );
  }

  tabs: DigitalSpaceTabMenuItem[] = [
    {
      label: ChangeRequestTabs.open,
      hovered: false,
    },
    {
      label: ChangeRequestTabs.closed,
      hovered: false,
    },
  ];

  activeTab: DigitalSpaceTabMenuItem = this.tabs[0];

  exportOptions = [
    {
      label: 'All',
      value: 'all',
      command: () => this.exportAll(),
    },
    {
      label: 'In View',
      value: 'in_view',
      command: () => this.defaultExport(),
    },
  ];

  permissions = ['TICKETS_COMPANY_READ'];

  stateOptions: { label: string; value: boolean }[] = [
    { label: 'Company', value: true },
    { label: 'Mine', value: false },
  ];

  constructor(
    private router: Router,
    private authService: AuthService,
    private changeRequestService: ChangeRequestService,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    this.tableLoading = true;
    const breadcrumbs: MenuItem[] = [
      { label: 'Change Requests', routerLink: '/secure/tickets/changes' },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.user = await this.authService.getUser();

    this.hasCompanyToggle = this.authService.hasPermission(
      this.user,
      'TICKETS_COMPANY_READ'
    );
    await this.loadChangeRequests();
  }

  async loadChangeRequests() {
    this.dataTable.loading = true;
    this.changeRequestsSubject.next([]);

    if (this.firstLoad) {
      this.reset(this.dataTable, false, this.cols);
      this.firstLoad = false;
    }

    const changeRequestCount =
      (await firstValueFrom(
        this.changeRequestService.getCount(
          this.getQueryFilter(this.dataTable, this.cols)
        )
      )) ?? 0;

    const changeRequestRequests = [];
    const totalPages = Math.ceil(
      changeRequestCount / ChangeRequestRequestSizeLimit
    );
    for (let pageNumber = 0; pageNumber < totalPages; pageNumber++) {
      changeRequestRequests.push(
        firstValueFrom(
          this.changeRequestService.getMultiple(
            this.getQueryFilter(
              this.dataTable,
              this.cols,
              ChangeRequestRequestSizeLimit,
              pageNumber
            )
          )
        )
      );
    }

    const results = (await Promise.all(changeRequestRequests))
      .flatMap(result => result)
      .map(changeRequest => {
        return {
          ...changeRequest,
          startDate: changeRequest.startDate
            ? ConvertServiceNowDateToJsDate(changeRequest.startDate)
            : null,
          endDate: changeRequest.endDate
            ? ConvertServiceNowDateToJsDate(changeRequest.endDate)
            : null,
        };
      });

    this.totalRecords = changeRequestCount;
    this.changeRequestsSubject.next(results);
    this.dataTable.loading = false;
  }

  getQueryFilter(
    table: Table,
    columns: TableColumn[],
    amount?: number,
    page?: number
  ): ServiceNowFilterQuery {
    const serviceNowFilterQuery = BuildServiceNowQuery(
      this.activeTab.label === ChangeRequestTabs.closed ? table : null,
      columns,
      amount,
      page
    );
    if (!this.companyToggle) {
      serviceNowFilterQuery.Filter.push({
        Column: ChangeRequestTableColumnName.requestedFor,
        Value: [this.user?.sysId],
      });
    }
    if (this.activeTab.label === ChangeRequestTabs.open) {
      serviceNowFilterQuery.Filter.push({
        Column: ChangeRequestTableColumnName.state,
        Value: ChangeRequestOpenStateValues,
      });
    }
    if (this.activeTab.label === ChangeRequestTabs.closed) {
      this.router.navigate([], {
        queryParams: {},
      });
      serviceNowFilterQuery.Filter.push({
        Column: ChangeRequestTableColumnName.state,
        Value: ChangeRequestClosedStateValues,
      });
    }

    return serviceNowFilterQuery;
  }

  async onToggleChange(): Promise<void> {
    await this.loadChangeRequests();
  }

  async onFiltersChanged() {
    if (this.activeTab.label === ChangeRequestTabs.closed) {
      await this.loadChangeRequests();
    }
  }

  tabChange(tab: DigitalSpaceTabMenuItem) {
    this.activeTab = tab;
    this.firstLoad = true;
    if (tab.label === ChangeRequestTabs.open) {
      this.cols = cloneDeep(ChangeRequestOpenColumns);
    }
    if (tab.label === ChangeRequestTabs.closed) {
      this.cols = cloneDeep(ChangeRequestClosedColumns);
    }
    this.loadChangeRequests();
  }

  async resetTable() {
    this.router.navigate([], {
      queryParams: {},
    });
    this.cols =
      this.activeTab.label === ChangeRequestTabs.closed
        ? cloneDeep(ChangeRequestClosedColumns)
        : cloneDeep(ChangeRequestOpenColumns);
    this.firstLoad = true;
    await this.loadChangeRequests();
  }

  getTextColor(value: string): string {
    return value === 'Completed Successful' ||
      value === 'Successfully Completed'
      ? 'text-teal-700'
      : 'text-gray-800';
  }

  handleRowClicked(event) {
    this.router.navigateByUrl('secure/tickets/change/' + event.sysId);
  }

  handleColumnChange(val: TableColumn[]) {
    this.cols = this.cols.filter(col =>
      val.map(valCol => valCol.header).includes(col.header)
    );
  }

  getColor(colors: TableColumnColor[], value: string) {
    return colors[value.toLowerCase()] ?? null;
  }

  defaultExport() {
    const displayedRows = this.dataTable.filteredValue || this.dataTable.value;
    ExportTable(
      this.dataTable,
      GetExportItemsFromFullArray(
        displayedRows.map(changeRequest => {
          return {
            ...changeRequest,
            startDate: changeRequest.startDate
              ? DateTime.fromJSDate(changeRequest.startDate).toFormat(
                  'dd/MM/yyyy HH:ss'
                )
              : null,
            endDate: changeRequest.endDate
              ? DateTime.fromJSDate(changeRequest.endDate).toFormat(
                  'dd/MM/yyyy HH:ss'
                )
              : null,
          };
        }),
        this.dataTable.first ?? 0,
        this.dataTable._rows ?? this.defaultRowsShown
      ),
      this.selectedColumns,
      'all',
      `${ExportName.change} - ${ExportView.inView}`
    );
  }

  async exportAll() {
    const displayedRows = this.dataTable.filteredValue || this.dataTable.value;
    ExportTable(
      this.dataTable,
      displayedRows.map(changeRequest => {
        return {
          ...changeRequest,
          startDate: changeRequest.startDate
            ? DateTime.fromJSDate(changeRequest.startDate).toFormat(
                'dd/MM/yyyy HH:ss'
              )
            : null,
          endDate: changeRequest.endDate
            ? DateTime.fromJSDate(changeRequest.endDate).toFormat(
                'dd/MM/yyyy HH:ss'
              )
            : null,
        };
      }),
      this.selectedColumns,
      'all',
      `${ExportName.change} - ${ExportView.all}`
    );
  }

  reset(table: Table, firstLoad = false, columns: TableColumn[]) {
    this._selectedColumns = SetSelectedColumns(
      this.cols,
      columns.filter(column => column?.default).map(column => column?.field)
    );
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
    });
  }
}
