import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { PacOptions } from 'src/app/models/service-request/service-request.models';

@Component({
  selector: 'mobile-service-request',
  templateUrl: './mobile-service-request.component.html',
  standalone: true,
  imports: [
    ButtonModule,
    RadioButtonModule,
    ReactiveFormsModule,
    InputTextModule,
    FormsModule,
  ],
  styleUrls: ['./mobile-service-request.component.scss'],
})
export class MobileServiceRequestComponent implements OnInit {
  @Input() serviceRequestForm: FormGroup;

  pacCategories: PacOptions[] = [
    {
      name: 'Single',
      pacType: '2926b76adbb0bb409ccaf58dae9619df',
    },
    {
      name: 'Multiple',
    },
  ];

  selectedPacCategory: PacOptions;

  ngOnInit() {
    this.selectedPacCategory = this.pacCategories[0];
    this.pacInputSetValidators();
  }

  pacInputSetValidators(): void {
    const pacType = this.selectedPacCategory?.name;
    this.serviceRequestForm.get('mobileNumber').clearValidators();

    if (pacType === 'Single') {
      this.serviceRequestForm
        .get('mobileNumber')
        .setValidators([Validators.required, Validators.pattern(/^07\d{9}$/)]);
    } else {
      this.serviceRequestForm
        .get('mobileNumber')
        .setValidators(Validators.required);
    }

    this.serviceRequestForm.get('mobileNumber').updateValueAndValidity();
  }
}
