import { Injectable, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { interval, Observable, Subscription } from 'rxjs';

@Injectable()
export class RestrictionService {
  intervalSubscription: Subscription;
  subscribed: Boolean = false;

  constructor() {}

  restrictions: Array<any> = [];

  restrictionsUpdate: EventEmitter<Array<any>> = new EventEmitter();

  async getRestrictions() {
    /* var data = await this.api.post(
      "Misc/GetServiceHubPageRestrictions",
      "",
      [],
      true,
      false
    );
    this.restrictions = data;

    this.updateRestrictions();
    if (!this.subscribed) {
      this.intervalSubscription = interval(60000).subscribe(async () => {
        this.getRestrictions();
        this.checkLockedOut();
      });
      this.subscribed = true;
    }
    return data; */
  }

  async checkLockedOut() {
    // TODO need to add new end point for this commented out on the backend
    //await this.api.post("Authentication/CheckLockedOut", "", [], false, false);
  }

  updateRestrictions() {
    this.restrictionsUpdate.emit(this.restrictions);
  }

  clearInterval() {
    this.intervalSubscription?.unsubscribe();
  }
}
