@if (!submittingData) {
  <div class="flex flex-column h-full">
    <h1 class="text-xl px-3">Edit Profile</h1>
    <hr class="w-full" />
    <div class="px-4">
      <form [formGroup]="profileForm">
        <div class="formgrid grid">
          <div class="field col">
            <label class="text-lg" for="firstname">First Name</label>
            <input
              formControlName="firstName"
              id="firstname"
              type="text"
              class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
              placeholder="Firstname"
              aria-label="first name" />
            @if (
              profileForm.controls.firstName.errors?.required &&
              profileForm.get('firstName').touched
              ) {
              <small
                class="p-error text-base block">
                First name is required.
              </small>
            }
          </div>
          <div class="field col">
            <label class="text-lg" for="lastname">Last Name</label>
            <input
              formControlName="lastName"
              id="lastname"
              type="text"
              class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
              placeholder="Lastname"
              aria-label="last name" />
            @if (
              profileForm.controls.lastName.errors?.required &&
              profileForm.get('lastName').touched
              ) {
              <small
                class="p-error text-base block">
                Last name is required.
              </small>
            }
          </div>
        </div>
        <div class="field">
          <label class="text-lg" for="email">Email Address</label>
          <input
            formControlName="email"
            placeholder="Email Address"
            id="email"
            type="text"
            pInputText
            aria-label="email"
            class="w-full" />
          @if (
            profileForm.get('email').invalid && profileForm.get('email').touched
            ) {
            <small
              class="p-error text-base block"
              >{{ emailErrorMessage() }}
            </small>
          }
          @if (checkRoleExistsStatus === 'PENDING') {
            <div class="py-2">
              <small class="p-info p-2">
                <i class="pi pi-spin pi-spinner"></i>
                Checking email unique...
              </small>
            </div>
          }
        </div>
        <div class="field">
          <label class="text-lg" for="mobile">Mobile Number</label>
          <input
            formControlName="mobilePhone"
            id="mobile"
            type="text"
            pInputText
            placeholder="Mobile Number"
            aria-label="mobile-input"
            class="w-full" />
          @if (
            profileForm.controls.mobilePhone.errors?.mobileNumberForbidden &&
            profileForm.get('mobilePhone').touched
            ) {
            <small
              class="p-error text-base block"
              >
              Invalid number. Ensure the number is a valid international number.
            </small>
          }
        </div>
        <div class="field">
          <label for="business">Business Phone</label>
          <br />
          <input
            formControlName="phone"
            id="business"
            type="text"
            pInputText
            placeholder="Business Phone"
            aria-label="business-input"
            class="w-full" />
        </div>
      </form>
    </div>
    <div class="flex justify-content-center align-content-center py-3 gap-2">
      <p-button
        styleClass="p-button-secondary"
        label="Cancel"
      (onClick)="close()"></p-button>
      <p-button
        styleClass="p-button"
        [disabled]="!formValid(profileForm)"
        label="Submit"
      (onClick)="onSubmit(profileForm.value)"></p-button>
    </div>
  </div>
}

@if (submittingData) {
  <div class="flex-column align-content-center py-5 px-6">
    <div class="flex align-content-center justify-content-center">
      <img src="../../assets/gifs/loading/servicehub-loading-ani.gif" />
    </div>
    <h4 class="flex align-content-center justify-content-center text-xl">
      Submitting User Detail
    </h4>
  </div>
}
