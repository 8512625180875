import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'footer',
  standalone: true,
  imports: [ButtonModule, ReactiveFormsModule, InputTextModule, TooltipModule],
  templateUrl: './provide-incident-information-footer.component.html',
  styleUrls: ['./provide-incident-information-footer.component.scss'],
})
export class ProvideIncidentInformationFooterComponent implements OnInit {
  ticketState: string;
  showInput = false;
  canRaiseIncident = false;

  infoForm = new FormGroup({
    infoComment: new FormControl('', Validators.required),
  });

  constructor(
    public ref: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private authService: AuthService
  ) {
    this.ticketState = this.dialogConfig.data?.ticketState;
    this.showInput = this.dialogConfig.data?.showInput;
  }

  async ngOnInit(): Promise<void> {
    const user = await this.authService.getUser();
    this.canRaiseIncident = this.authService.hasPermission(
      user,
      'TICKETS_INCIDENTS_CREATE'
    );
  }

  close(): void {
    this.ref.close();
  }

  cancel(): void {
    this.ref.close({
      action: 'cancel',
    });
  }

  redirect(): void {
    this.ref.close({
      action: 'redirect',
    });
  }

  submitInformation(): void {
    this.ref.close({
      action: 'comment',
      value: this.infoForm.value?.infoComment,
    });
  }
}
