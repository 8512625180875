@if (!loading) {
  @if (canShowContactTypes) {
    <div class="flex flex-column gap-3">
      <div class="flex align-content-center flex-wrap mb-3">
        <h3 class="m-0">Available Contact Groups</h3>
        @if (contactTypes?.length) {
          <button
            pButton
            class="p-button-sm ml-2 p-button-outlined"
            (click)="toggleSelectDeselectContactTypes()">
            {{
            selectedContactTypes.length === contactTypes.length
            ? 'Deselect All'
            : 'Select All'
            }}
          </button>
        }
      </div>
      <div
        class="grid mb-3 border border-1 border-gray-500 p-3 max-h-24rem overflow-auto">
        @for (contactType of contactTypes; track contactType) {
          <div class="col-4">
            <div class="flex">
              <p-checkbox
                [value]="contactType"
                [inputId]="contactType.name"
                [(ngModel)]="selectedContactTypes"
                (click)="onContactTypesChange()"
              [disabled]="contactType.disabled"></p-checkbox>
              <label class="ml-2" [for]="contactType.name">
                {{ contactType?.name }}
              </label>
            </div>
          </div>
        }
      </div>
    </div>
  }
  <div class="flex flex-column gap-3">
    <div class="flex align-content-center flex-wrap mb-3">
      <h3 class="m-0">Available Authorisation Groups</h3>
      @if (authorisationTypes?.length) {
        <button
          pButton
          class="p-button-sm ml-2 p-button-outlined"
          (click)="toggleSelectDeselectAuthorisationTypes()">
          {{
          selectedAuthorisationTypes.length === authorisationTypes.length
          ? 'Deselect All'
          : 'Select All'
          }}
        </button>
      }
    </div>
    <div
      class="grid mb-3 border border-1 border-gray-500 p-3 max-h-24rem overflow-auto">
      @for (authorisationType of authorisationTypes; track authorisationType) {
        <div class="col-4">
          <div class="flex">
            <p-checkbox
              [value]="authorisationType"
              [inputId]="authorisationType.name"
              [(ngModel)]="selectedAuthorisationTypes"
            (click)="onAuthorisationTypesChange()"></p-checkbox>
            <label class="ml-2" [for]="authorisationType.name">
              {{ authorisationType.name }}
            </label>
          </div>
        </div>
      }
    </div>
  </div>
  @if (hasSelectedBillingPermissionsToShowBillingEntities) {
    <div
      class="flex flex-column gap-3">
      <div class="flex align-content-center flex-wrap mb-3">
        <h3 class="m-0">Available Invoicing Accounts</h3>
        @if (billingEntities?.length) {
          <button
            pButton
            (click)="toggleSelectDeselectBillingEntities()"
            class="p-button-sm ml-2 p-button-outlined">
            {{
            selectedBillingEntities.length === billingEntities.length
            ? 'Deselect All'
            : 'Select All'
            }}
          </button>
        }
      </div>
      <div
        class="grid mb-3 border border-1 border-gray-500 p-3 max-h-24rem overflow-auto">
        @for (billingEntry of billingEntities; track billingEntry) {
          <div class="col-4">
            <div class="flex">
              <p-checkbox
                [value]="billingEntry"
                [inputId]="billingEntry.uName"
                [(ngModel)]="selectedBillingEntities"
              (click)="onBillingEntityChange()"></p-checkbox>
              <label class="ml-2" [for]="billingEntry.uName">
                {{ billingEntry.uName }}<br />{{ billingEntry.uUnitySiteId }}
              </label>
            </div>
          </div>
        }
      </div>
    </div>
  }
}
@if (loading) {
  <loading-spinner></loading-spinner>
}
