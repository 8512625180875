@if (loading) {
  <loading-spinner />
}
@if (!loading) {
  <div class="flex flex-column h-full">
    <div
      class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200">
      <h1 class="px-3 text-xl">Role Information</h1>
    </div>
    <div
      class="flex-grow-1 overflow-auto mx-3 bg-white border-solid border-1 border-gray-200 border-top-none border-bottom-none px-3">
      <div class="flex justify-content-end align-content-center">
        <div class="align-items-right flex pr-4">
          @if (!selectedRole.default) {
            <ng-container [permissions]="['COMPANY_ROLES_CREATE_UPDATE']">
              <p-button
                label="Edit"
                styleClass="p-button"
                icon="pi pi-pencil"
                (onClick)="openEditRole()"></p-button>
            </ng-container>
          }
        </div>
      </div>
      <div class="flex gap-3 mb-3">
        <div
          class="col border-solid border-1 border-gray-200 border-top-none border-left-none border-right-none">
          <h1 class="text-lg m-0 font-normal">Name</h1>
          <h2 class="text-lg m-0 font-normal">{{ selectedRole?.name }}</h2>
        </div>
        <div
          class="col border-solid border-1 border-gray-200 border-top-none border-left-none border-right-none">
          <h1 class="text-lg m-0 font-normal">Description</h1>
          <h2 class="text-lg m-0 font-normal">
            {{ selectedRole?.description }}
          </h2>
        </div>
      </div>
      <p-tabView>
        <p-tabPanel header="Permissions">
          <div class="flex">
            <div class="col">
              @for (
                category of permissionsForSelectedRole
                  | slice: 0 : getColumnLength();
                track category;
                let i = $index
              ) {
                <div>
                  <div>
                    <h1 class="text-xl">
                      {{ category.category | titlecase | replaceUnderscore }}
                    </h1>
                  </div>
                  @for (permission of category.permissions; track permission) {
                    <ul>
                      <li>
                        {{ permission.label }} <br />
                        {{ permission.description }}
                      </li>
                    </ul>
                  }
                </div>
              }
            </div>
            <div class="col">
              @for (
                category of permissionsForSelectedRole
                  | slice
                    : getColumnLength()
                    : permissionsForSelectedRole?.length;
                track category;
                let i = $index
              ) {
                <div>
                  <div>
                    <h1 class="text-xl">
                      {{ category.category | titlecase | replaceUnderscore }}
                    </h1>
                  </div>
                  @for (permission of category.permissions; track permission) {
                    <ul>
                      <li>
                        {{ permission.label }} <br />
                        {{ permission.description }}
                      </li>
                    </ul>
                  }
                </div>
              }
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="User List">
          <div class="flex-grow-1 overflow-auto mx-3 overflow-hidden">
            <p-table
              #dataTable
              [value]="users$ | async"
              [columns]="selectedColumns"
              class="p-datatable-equal-width"
              styleClass="p-datatable-striped"
              [rowHover]="false"
              sortMode="single"
              [rows]="25"
              [lazy]="true"
              (onLazyLoad)="loadUsersWithRole(dataTable, $event)"
              scrollHeight="flex"
              [scrollable]="true">
              <ng-template pTemplate="header" let-columns>
                <tr>
                  @for (col of columns; track col) {
                    <th
                      class="text-white bg-gray-800 p-datatable-header-first-row">
                      {{ col.header }}
                    </th>
                  }
                </tr>
              </ng-template>
              <ng-template pTemplate="loadingbody">
                <tr>
                  <td [attr.colspan]="selectedColumns?.length + 1">
                    <loading-spinner></loading-spinner>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="loadingicon"></ng-template>
              <ng-template pTemplate="body" let-user let-columns="columns">
                <tr>
                  @for (col of columns; track col) {
                    <td>
                      @if (col.display?.type === 'text') {
                        {{ user[col.field] }}
                      }
                      @if (col.display?.type === 'textName') {
                        {{ formatName(user) }}
                      }
                    </td>
                  }
                </tr>
              </ng-template>
              <ng-template pTemplate="footer">
                <tr>
                  <td [attr.colspan]="selectedColumns?.length + 1">
                    <div class="flex align-items-center justify-content-center">
                      <p-paginator
                        (onPageChange)="loadUsersWithRole(dataTable, $event)"
                        [first]="dataTable.first"
                        [rows]="dataTable.rows"
                        [totalRecords]="totalRecords"
                        [showCurrentPageReport]="true"
                        currentPageReportTemplate="{first} - {last} of {totalRecords}"
                        [rowsPerPageOptions]="[25, 50, 100]"></p-paginator>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
}
