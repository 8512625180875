<div
  class="flex align-items-center justify-content-center h-screen bg-gray-100">
  <div class="grid mr-0 p-3">
    <div class="col-12 md:col-6 flex align-items-center">
      <div>
        <h2 class="text-2xl m-0">
          <h2 class="block text-2xl m-0">Opps,</h2>
          page not found
        </h2>
        <p>
          The page you are looking for doesn't exist or an other error occur.
          Please go back or head over to the dashboard page.
        </p>
        <p-button (onClick)="goBackButton()" label="Go Back"></p-button>
        <p-button
          styleClass="p-button-secondary"
          (onClick)="backToDashBoardButton()"
          label="Go to Dashboard"></p-button>
      </div>
    </div>
    <div class="col-12 md:col-6">
      <img
        src="../../../../assets/gifs/404/404.svg"
        class="error-img"
        alt="404 Image" />
    </div>
  </div>
</div>
