@if (loading) {
  <loading-spinner />
}
@if (!loading) {
  <p-table
    #dataTable
    [value]="supportArticles$ | async"
    [columns]="cols"
    dataKey="sysId"
    rowExpandMode="single">
    <ng-template pTemplate="caption"> </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        @for (col of columns; track col) {
          <th
            colspan="2"
            class="text-white bg-gray-800 p-datatable-header-first-row"
            [pSortableColumn]="col.field">
            {{ col.header }}
            @if (col.showSortIcon) {
              <p-sortIcon class="text-white" [field]="col.field"></p-sortIcon>
            }
          </th>
        }
      </tr>
      <tr>
        @for (col of columns; track col) {
          <th colspan="2" class="bg-gray-100">
            @if (!col?.filter?.type || col?.filter?.type === 'text') {
              <p-columnFilter
                type="text"
                [field]="col.field"
                [matchModeOptions]="matchModeOptions"
                [matchMode]="'contains'"></p-columnFilter>
            }
          </th>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-support let-expanded="expanded">
      <tr [pRowToggler]="support" class="cursor-pointer">
        <td>{{ support.question }}</td>
        <td>
          <button
            aria-label="Toggle Expand"
            type="button"
            pButton
            pRipple
            class="p-button-text p-button-rounded p-button-plain"
            [icon]="
              expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
            "></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-support>
      <tr>
        <td colspan="2" [innerHTML]="support.answer | keepHtml"></td>
      </tr>
    </ng-template>
  </p-table>
}
