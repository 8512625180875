@if (!article) {
  <loading-spinner />
}

@if (article) {
  <div class="flex flex-column h-full">
    <div
      class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200">
      <h1 class="px-3 text-xl">Knowledge Articles</h1>
    </div>

    <div
      class="flex-grow-1 overflow-auto mx-3 overflow-hidden bg-white border-solid border-1 border-gray-200 border-top-none border-bottom-none px-3">
      <div class="mx-3">
        <p [innerHTML]="article?.text | safeHtml" class="m-0"></p>
      </div>
    </div>
  </div>
}
