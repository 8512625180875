import { Routes } from '@angular/router';
import { ServiceHubComponent } from './components/service-hub/service-hub.component';
import { DashboardComponent } from './components/service-hub/dashboard/dashboard.component';
import { DynamicGuard } from './guards/dynamic.guard';
import { TicketsComponent } from './components/service-hub/tickets/tickets.component';
import { IncidentsComponent } from './components/service-hub/tickets/incidents/incidents.component';
import { ServiceRequestsComponent } from './components/service-hub/tickets/service-requests/service-requests.component';
import { OrdersComponent } from './components/service-hub/tickets/orders/orders.component';
import { BillingOverviewComponent } from './components/service-hub/billing/billing.component';
import { InvoicesComponent } from './components/service-hub/billing/invoices/invoices.component';
import { CreditNotesComponent } from './components/service-hub/billing/credit-notes/credit-notes.component';
import { BillingUsageComponent } from './components/service-hub/billing/usage/usage.component';
import { BroadbandComponent } from './components/service-hub/products-and-services/broadband/broadband.component';
import { DomainsComponent } from './components/service-hub/products-and-services/domains/domains.component';
import { ServiceStatusComponent } from './components/service-hub/products-and-services/services/status/status.component';
import { UsersComponent } from './components/service-hub/company/users/users.component';
import { ContactsComponent } from './components/service-hub/company/company-contacts/company-contacts.component';
import { CredentialsComponent } from './components/service-hub/developer/credentials/credentials.component';
import { WebHooksComponent } from './components/service-hub/developer/web-hooks/web-hooks.component';
import { AccountComponent } from './components/service-hub/account/account.component';
import { RaiseTicketComponent } from './components/service-hub/tickets/raise/raise-ticket.component';
import { SingleIncidentComponent } from './components/service-hub/tickets/incidents/single/incident.component';
import { SingleServiceRequestComponent } from './components/service-hub/tickets/service-requests/single/service-request.component';
import { DomainComponent } from './components/service-hub/products-and-services/domains/domain/domain.component';
import { ServiceInformationComponent } from './components/service-hub/products-and-services/services/information/information.component';
import { InvoiceOnlineViewComponent } from './components/service-hub/billing/online-view/online-view.component';
import { LogoutComponent } from './components/user-access/logout/logout.component';
import { ChangePasswordComponent } from './components/service-hub/account/change-password/change-password.component';
//import { ResetPasswordComponent } from "./components/user-access/reset-password/reset-password.component";
import { MiscNotificationComponent } from './components/service-hub/notifications/misc/misc-notification.component';
import { SearchComponent } from './components/service-hub/misc/search/search.component';
import { NotFoundComponent } from './components/global/errors/not-found/not-found.component';
import { NotAuthorisedComponent } from './components/global/errors/not-authorised/not-authorised.component';
import { SubmitSurveyComponent } from './components/service-hub/survey/submit-survey/submit-survey.component';
import { DownloadExportComponent } from './components/misc/download-export/download-export.component';
import { TelephonyComponent } from './components/service-hub/products-and-services/telephony/telephony.component';
import { TelephonyDataComponent } from './components/service-hub/products-and-services/telephony/data/telephony-data.component';
import { SupportComponent } from './components/service-hub/misc/support/support.component';
import { ScienceLogicComponent } from './components/service-hub/products-and-services/science-logic/science-logic/science-logic.component';
import { ReleaseNotesComponent } from './components/misc/release-notes/release-notes.component';
import { CmdbDevicesComponent } from './components/service-hub/products-and-services/science-logic/cmdb-devices/cmdb-devices.component';
import { ChangeRequestsComponent } from './components/service-hub/tickets/change-requests/change-requests.component';
import { SingleChangeRequestComponent } from './components/service-hub/tickets/change-requests/single/change-request.component';
import { RestrictedComponent } from './components/global/errors/restricted/restricted.component';
import { DeveloperPageLandingComponent } from './components/service-hub/account/developers/developer-landing/developer-landing.component';
import { UpgradeBroadbandComponent } from './components/service-hub/products-and-services/adsl/upgrade/upgrade-broadband.component';
import { InformationDetailsComponent } from './components/service-hub/products-and-services/services/details/information-details.component';
import { KnowledgeArticleDetailsComponent } from './components/service-hub/misc/knowledge-article/knowledge-article-details/knowledge-article-details.component';
import { KnowledgeArticleComponent } from './components/service-hub/misc/knowledge-article/knowledge-article.component';
//import { CompanyActivityComponent } from './components/service-hub/misc/logs/company/company-crud-activity.component';
import { CookiesPolicyComponent } from './components/service-hub/misc/cookies-policy/cookies-policy.component';
import { CreateIncidentComponent } from './components/service-hub/tickets/raise/incidents/create-incident.component';
import { CreateServiceRequestComponent } from './components/service-hub/tickets/raise/service-request/create-service-request.component';
import { AddEditUserComponent } from './components/service-hub/company/users/add-edit-user/add-edit-user.component';
import { RoleManagementComponent } from './components/service-hub/company/roles/role-management/role-management.component';
import { RoleInformationComponent } from './components/service-hub/company/roles/role-information/role-information.component';
import { BroadbandUsageComponent } from './components/service-hub/products-and-services/broadband/broadband-usage/broadband-usage.component';
import { AddEditRoleComponent } from './components/service-hub/company/roles/add-edit-role/add-edit-role.component';
import { ServicePoliciesComponent } from './components/service-hub/company/service-policies/service-policies.component';
import { EditBarsComponent } from './components/service-hub/products-and-services/telephony/data/edit-bars/edit-bars.component';
import { SupportContactsComponent } from './components/service-hub/misc/support-contacts/support-contacts.component';
import { SupportArticlesComponent } from './components/service-hub/misc/support-articles/support-articles.component';
import { NotificationTableComponent } from './components/service-hub/notifications/notification-table/notification-table.component';
import { CostcentresUsersComponent } from './components/service-hub/billing/costcentres-users/costcentres-users.component';
import { AmendCostcentresUsersComponent } from './components/service-hub/billing/costcentres-users/amend-costcentres-users/amend-costcentres-users/amend-costcentres-users.component';
import { AmendCostcentresGroupComponent } from './components/service-hub/billing/costcentres-users/amend-costcentres-group/amend-costcentres-group.component';
import { EditMultiBarComponent } from './components/service-hub/products-and-services/telephony/data/edit-multi-bar/edit-multi-bar.component';
import { TicketsReportingComponent } from './components/service-hub/tickets/tickets-reporting/tickets-reporting.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { GenericErrorComponent } from './components/global/errors/generic-error/generic-error.component';
import { AddEditUsersComponent } from './components/service-hub/company/users/add-edit-users/add-edit-users.component';
import { ScienceLogicVisualizationComponent } from './components/service-hub/products-and-services/science-logic/science-logic-visualization/science-logic-visualization.component';
import { OrderLineItemComponent } from './components/service-hub/tickets/orders/order-line-single/order-line-item.component';
import { CaseComponent } from './components/service-hub/tickets/case/case.component';
import { OrderSingleComponent } from './components/service-hub/tickets/orders/order-single/order-single.component';

export const routing: Routes = [
  { path: '', redirectTo: '/secure/dashboard', pathMatch: 'full' },
  {
    path: 'login',
    data: { developerRestricted: false },
    redirectTo: '/secure/dashboard',
  },
  {
    path: 'logout',
    data: { developerRestricted: false },
    component: LogoutComponent,
  },
  {
    path: 'support',
    canActivate: [AuthGuard, DynamicGuard],
    data: { developerRestricted: false },
    component: ServiceHubComponent,
    children: [
      {
        path: '',
        data: { developerRestricted: false },
        component: SupportComponent,
      },
      {
        path: 'articles',
        data: { developerRestricted: false },
        component: SupportArticlesComponent,
      },
      {
        path: 'contacts',
        data: { developerRestricted: false },
        component: SupportContactsComponent,
      },
      {
        path: 'knowledge-articles',
        data: { developerRestricted: false },
        component: KnowledgeArticleComponent,
      },
    ],
  },
  {
    path: 'survey/:id',
    data: { developerRestricted: false },
    component: SubmitSurveyComponent,
  },
  {
    path: 'secure',
    data: { developerRestricted: false },
    component: ServiceHubComponent,
    canActivate: [AuthGuard, DynamicGuard],
    canActivateChild: [AuthGuard, DynamicGuard],
    children: [
      {
        path: 'dashboard',
        data: { developerRestricted: false, permissions: [] },
        component: DashboardComponent,
      },
      {
        path: 'search',
        data: { developerRestricted: false, permissions: [] },

        component: SearchComponent,
      },
      {
        path: 'account',
        data: {
          developerRestricted: false,
          permissions: ['PROFILE_PERSONAL_READ'],
        },

        component: AccountComponent,
      },
      {
        path: 'account/notifications',
        data: { developerRestricted: false, permissions: [] },

        component: NotificationTableComponent,
      },
      {
        path: 'account/developer',
        data: { developerRestricted: false, permissions: ['TIMICO_DEVELOPER'] },

        component: DeveloperPageLandingComponent,
      },
      {
        path: 'account/change-password',
        data: {
          developerRestricted: false,
          permissions: ['PROFILE_PERSONAL_WRITE'],
        },

        component: ChangePasswordComponent,
      },
      {
        path: 'account/notification/:id',
        data: { developerRestricted: false, permissions: [] },

        component: MiscNotificationComponent,
      },
      {
        path: 'account/:id',
        data: {
          developerRestricted: false,
          permissions: ['PROFILE_PERSONAL_READ'],
        },

        component: AccountComponent,
      },
      {
        path: 'tickets',
        data: { developerRestricted: false, permissions: [] },

        component: TicketsComponent,
      },
      {
        path: 'tickets/raise',
        data: {
          developerRestricted: false,
          permissions: ['TICKETS_INCIDENTS_CREATE', 'TICKETS_REQUESTS_CREATE'],
        },

        component: RaiseTicketComponent,
      },
      {
        path: 'tickets/incidents',
        data: {
          developerRestricted: false,
          permissions: ['TICKETS_INCIDENTS_READ', 'TICKETS_COMPANY_READ'],
        },

        component: IncidentsComponent,
      },
      {
        path: 'tickets/incident/:id',
        data: {
          developerRestricted: false,
          permissions: ['TICKETS_INCIDENTS_READ', 'TICKETS_COMPANY_READ'],
        },

        component: SingleIncidentComponent,
      },
      {
        path: 'tickets/service-requests',
        data: {
          developerRestricted: false,
          permissions: ['TICKETS_REQUESTS_READ', 'TICKETS_COMPANY_READ'],
        },

        component: ServiceRequestsComponent,
      },
      {
        path: 'tickets/case/:id',
        data: {
          developerRestricted: false,
          permissions: ['TICKETS_REQUESTS_READ', 'TICKETS_COMPANY_READ'],
        },

        component: CaseComponent,
      },
      {
        path: 'tickets/service-request/:id',
        data: {
          developerRestricted: false,
          permissions: ['TICKETS_REQUESTS_READ', 'TICKETS_COMPANY_READ'],
        },

        component: SingleServiceRequestComponent,
      },
      {
        path: 'tickets/orders',
        data: {
          developerRestricted: false,
          permissions: ['TICKETS_ORDERS_READ', 'TICKETS_COMPANY_READ'],
        },

        component: OrdersComponent,
      },
      {
        path: 'tickets/orderlineitem/:id',
        data: {
          developerRestricted: false,
          permissions: ['TICKETS_ORDERS_READ', 'TICKETS_COMPANY_READ'],
        },

        component: OrderLineItemComponent,
      },
      {
        path: 'tickets/order/:id',
        data: {
          developerRestricted: false,
          permissions: ['TICKETS_ORDERS_READ', 'TICKETS_COMPANY_READ'],
        },

        component: OrderSingleComponent,
      },
      {
        path: 'tickets/changes',
        data: {
          developerRestricted: false,
          permissions: ['TICKETS_CHANGE_READ', 'TICKETS_COMPANY_READ'],
        },

        component: ChangeRequestsComponent,
      },
      {
        path: 'tickets/change/:id',
        data: {
          developerRestricted: false,
          permissions: ['TICKETS_CHANGE_READ', 'TICKETS_COMPANY_READ'],
        },

        component: SingleChangeRequestComponent,
      },
      {
        path: 'tickets/order/:id',
        data: {
          developerRestricted: false,
          permissions: ['TICKETS_ORDERS_READ', 'TICKETS_COMPANY_READ'],
        },

        component: SingleServiceRequestComponent,
      },
      {
        path: 'billing/overview',
        data: {
          developerRestricted: false,
          permissions: ['BILLING_OVERVIEW_READ'],
        },
        component: BillingOverviewComponent,
      },
      {
        path: 'billing/:siteId/overview',
        data: {
          developerRestricted: false,
          permissions: ['BILLING_OVERVIEW_READ'],
        },
        component: BillingOverviewComponent,
      },
      {
        path: 'billing/:sideId/credit-notes',
        data: {
          developerRestricted: false,
          permissions: [
            'BILLING_CREDIT_NOTES_READ',
            'BILLING_CREDIT_NOTES_READ_COMPANY',
          ],
        },

        component: CreditNotesComponent,
      },
      {
        path: 'billing/:siteId/costcentres-users',
        data: { developerRestricted: true, permissions: [] },
        component: CostcentresUsersComponent,
      },
      {
        path: 'billing/invoices',
        data: {
          developerRestricted: false,
          permissions: [
            'BILLING_INVOICES_READ',
            'BILLING_INVOICE_READ_COMPANY',
          ],
        },

        component: InvoicesComponent,
      },
      {
        path: 'billing/invoice/:id',
        data: {
          developerRestricted: false,
          permissions: [
            'BILLING_INVOICES_READ',
            'BILLING_INVOICE_READ_COMPANY',
          ],
        },

        component: InvoiceOnlineViewComponent,
      },
      {
        path: 'billing/credit-notes',
        data: {
          developerRestricted: false,
          permissions: [
            'BILLING_CREDIT_NOTES_READ',
            'BILLING_CREDIT_NOTES_READ_COMPANY',
          ],
        },
        component: CreditNotesComponent,
      },
      {
        path: 'billing/credit-note/:id',
        data: {
          developerRestricted: false,
          permissions: [
            'BILLING_CREDIT_NOTES_READ',
            'BILLING_CREDIT_NOTES_READ_COMPANY',
          ],
        },

        component: InvoiceOnlineViewComponent,
      },
      {
        path: 'billing/usage',
        data: {
          developerRestricted: false,
          permissions: ['BILLING_USAGE_READ'],
        },
        component: BillingUsageComponent,
      },
      {
        path: 'billing/:siteId/costcentres-users/amend/individual',
        data: { developerRestricted: true, permissions: [] },

        component: AmendCostcentresUsersComponent,
      },
      {
        path: 'billing/:siteId/costcentres-users/amend/group',
        data: { developerRestricted: true, permissions: [] },
        component: AmendCostcentresGroupComponent,
      },
      {
        path: 'tickets/raise/create-incident',
        data: {
          developerRestricted: false,
          permissions: [
            'TICKETS_INCIDENTS_CREATE',
            'TICKETS_REQUESTS_CREATE',
            'TICKETS_ORDERS_CREATE',
          ],
        },

        component: CreateIncidentComponent,
      },
      {
        path: 'tickets/raise/create-service-request',
        data: {
          developerRestricted: false,
          permissions: [
            'TICKETS_INCIDENTS_CREATE',
            'TICKETS_REQUESTS_CREATE',
            'TICKETS_ORDERS_CREATE',
          ],
        },

        component: CreateServiceRequestComponent,
      },
      {
        path: 'products-and-services/broadband',
        data: {
          developerRestricted: false,
          permissions: [
            'PRODUCTS_SERVICES_BROADBAND_READ',
            'PRODUCTS_SERVICES_BROADBAND_READ_COMPANY',
          ],
        },

        component: BroadbandComponent,
      },
      {
        path: 'products-and-services/broadband/:id',
        data: {
          developerRestricted: false,
          permissions: [
            'PRODUCTS_SERVICES_BROADBAND_READ',
            'PRODUCTS_SERVICES_BROADBAND_READ_COMPANY',
          ],
        },

        component: BroadbandUsageComponent,
      },
      {
        path: 'products-and-services/broadband/upgrade/:type/:id',
        data: {
          developerRestricted: false,
          permissions: [
            'PRODUCTS_SERVICES_BROADBAND_READ',
            'PRODUCTS_SERVICES_BROADBAND_READ_COMPANY',
          ],
        },

        component: UpgradeBroadbandComponent,
      },
      {
        path: 'products-and-services/domains',
        data: {
          developerRestricted: false,
          permissions: ['PRODUCTS_SERVICES_DOMAINS_READ'],
        },

        component: DomainsComponent,
      },
      {
        path: 'products-and-services/domain/:id',
        data: {
          developerRestricted: false,
          permissions: ['PRODUCTS_SERVICES_DOMAINS_READ'],
        },

        component: DomainComponent,
      },
      {
        path: 'products-and-services/services/status',
        data: {
          authorised: true,
          developerRestricted: false,
          permissions: ['PRODUCTS_SERVICES_SERVICE_STATUS_READ'],
        },

        component: ServiceStatusComponent,
      },
      {
        path: 'products-and-services/services/status/:id',
        data: {
          authorised: true,
          developerRestricted: false,
          permissions: ['PRODUCTS_SERVICES_SERVICE_STATUS_READ'],
        },

        component: ServiceStatusComponent,
      },
      {
        path: 'products-and-services/service/:id',
        data: {
          authorised: true,
          developerRestricted: false,
          permissions: ['PRODUCTS_SERVICES_SERVICE_STATUS_READ'],
        },

        component: ServiceInformationComponent,
      },
      {
        path: 'products-and-services/service/:type/:id',
        data: {
          authorised: true,
          developerRestricted: false,
          permissions: ['PRODUCTS_SERVICES_SERVICE_STATUS_READ'],
        },

        component: InformationDetailsComponent,
      },
      {
        path: 'products-and-services/telephony',
        data: {
          developerRestricted: false,
          permissions: ['PRODUCTS_SERVICES_MOBILE_READ'],
        },

        component: TelephonyComponent,
      },
      {
        path: 'products-and-services/telephony/data',
        data: {
          developerRestricted: false,
          permissions: ['PRODUCTS_SERVICES_MOBILE_READ'],
        },

        component: TelephonyDataComponent,
      },
      {
        path: 'products-and-services/telephony/edit-mobile-options',
        data: { developerRestricted: false },

        component: EditBarsComponent,
      },
      {
        path: 'products-and-services/telephony/edit-multi-mobile-options',
        data: { developerRestricted: false },

        component: EditMultiBarComponent,
      },
      {
        path: 'tickets/tickets-reporting',
        data: { developerRestricted: false },

        component: TicketsReportingComponent,
      },
      {
        path: 'products-and-services/monitoring/devices/device-insight/:dashboard',
        data: {
          developerRestricted: false,
          permissions: ['DEVICES_GRAPHICAL_READ'],
        },
        component: ScienceLogicVisualizationComponent,
      },
      {
        path: 'products-and-services/monitoring',
        data: { developerRestricted: false, permissions: [] },

        children: [
          {
            path: 'devices',
            redirectTo: 'devices/server',
            pathMatch: 'full',
          },
          {
            path: 'devices/:category',
            data: {
              developerRestricted: false,
              permissions: ['PRODUCTS_SERVICES_SCIENCE_LOGIC_READ'],
            },
            component: CmdbDevicesComponent,
          },
          {
            path: 'devices/:category/:subCategory',
            data: {
              developerRestricted: false,
              permissions: ['PRODUCTS_SERVICES_SCIENCE_LOGIC_READ'],
            },
            component: CmdbDevicesComponent,
          },
          {
            path: 'reports',
            data: {
              developerRestricted: false,
              permissions: ['PRODUCTS_SERVICES_SCIENCE_LOGIC_READ'],
            },

            component: ScienceLogicComponent,
          },
        ],
      },
      {
        path: 'company/users',
        data: {
          developerRestricted: false,
          permissions: ['COMPANY_USERS_READ'],
        },

        component: UsersComponent,
      },
      {
        path: 'company/users/add',
        data: {
          developerRestricted: false,
          permissions: ['COMPANY_USERS_CREATE'],
        },

        component: AddEditUserComponent,
      },
      {
        path: 'company/users/multiple/edit',
        data: {
          developerRestricted: false,
          permissions: ['COMPANY_USERS_UPDATE'],
        },

        component: AddEditUsersComponent,
      },
      {
        path: 'company/users/multiple/add',
        data: {
          developerRestricted: false,
          permissions: ['COMPANY_USERS_UPDATE'],
        },

        component: AddEditUsersComponent,
      },
      {
        path: 'company/users/edit/:id',
        data: {
          developerRestricted: false,
          permissions: ['COMPANY_USERS_UPDATE'],
        },

        component: AddEditUserComponent,
      },
      {
        path: 'company/roles/add',
        data: {
          developerRestricted: false,
          permissions: ['COMPANY_ROLES_CREATE_UPDATE'],
        },

        component: AddEditRoleComponent,
      },
      {
        path: 'company/roles/edit/:id',
        data: {
          developerRestricted: false,
          permissions: ['COMPANY_ROLES_CREATE_UPDATE'],
        },

        component: AddEditRoleComponent,
      },
      {
        path: 'company/roles',
        data: { developerRestricted: false, permissions: [] },

        component: RoleManagementComponent,
      },
      {
        path: 'company/roles/:id',
        data: { developerRestricted: false, permissions: [] },

        component: RoleInformationComponent,
      },
      {
        path: 'company/contacts',
        data: {
          developerRestricted: false,
          permissions: ['AUTHORISATION_TYPES_READ'],
        },
        component: ContactsComponent,
      },
      {
        path: 'company/service-policies',
        data: { developerRestricted: false, permissions: [] },
        canActivate: [AuthGuard, DynamicGuard],
        component: ServicePoliciesComponent,
      },
      {
        path: 'developer/credentials',
        data: {
          developerRestricted: false,
          permissions: ['DEVELOPER_CREDENTIALS_READ'],
        },
        component: CredentialsComponent,
      },
      {
        path: 'developer/web-hooks',
        data: {
          developerRestricted: false,
          permissions: ['DEVELOPER_WEB_HOOKS_READ'],
        },
        component: WebHooksComponent,
      },
      {
        path: 'digitalspace/survey/rag-status',
        data: { developerRestricted: false, permissions: [] },
        component: DashboardComponent,
      },
      {
        path: 'download',
        data: { developerRestricted: false, permissions: [] },
        component: DownloadExportComponent,
      },
      {
        path: 'release-notes',
        data: { developerRestricted: false },
        component: ReleaseNotesComponent,
      },
      {
        path: 'knowledge-article/:id',
        data: { developerRestricted: false, permissions: [] },
        component: KnowledgeArticleDetailsComponent,
      },
      {
        path: 'cookies-policy',
        data: { developerRestricted: false, permissions: [] },
        component: CookiesPolicyComponent,
      },
    ],
  },
  {
    path: 'unauthorised',
    component: NotAuthorisedComponent,
    canActivate: [AuthGuard, DynamicGuard],
  },
  { path: '404', component: NotFoundComponent },
  { path: '403', component: NotAuthorisedComponent },
  { path: 'error', component: GenericErrorComponent },
  { path: 'restricted', component: RestrictedComponent },
  { path: '**', component: NotFoundComponent },
];
