import { Component, Input, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import { ITicketsOpenedClosed } from 'src/app/components/service-hub/tickets/tickets-reporting/shared/tickets-reporting.model';

@Component({
  selector: 'app-tickets-chart',
  templateUrl: './tickets-chart.component.html',
  styleUrls: ['./tickets-chart.component.scss'],
})
export class TicketsChartComponent implements OnInit {
  @Input() graphData: ITicketsOpenedClosed[];
  ticketsOpenChart: EChartsOption;

  ngOnInit(): void {}

  ngOnChanges() {
    this.ticketsOpenClosedChart();
  }

  constructor() {}

  async ticketsOpenClosedChart(): Promise<EChartsOption> {
    const ticketData: ITicketsOpenedClosed[] = await this.graphData;

    return (this.ticketsOpenChart = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        top: 15,
        left: '15',
        data: ['Opened', 'Closed'],
      },
      toolbox: {
        feature: {
          saveAsImage: { show: true, name: 'Chart Download' },
          magicType: { show: true, type: ['line', 'bar'] },
          restore: {},

          dataView: {
            show: true,
            readOnly: false,
            buttonColor: '#E31C79',
            contentToOption: function () {
              return {
                dataZoom: [
                  {
                    start: 0,
                    end: 100,
                  },
                ],
              };
            },
            optionToContent: function (opt) {
              let series = opt.series;
              let dataZoom = opt.dataZoom[0];
              let table =
                '<table style="width:97%; margin-left:3%; user-select: text;border: solid 1px #BDBDBD ;border-collapse: collapse; font-family: nunito; font-weight: 100; font-size:16px;"><tbody><tr>' +
                '<td style="background-color: black; color: white; padding:12px 8px;">Date</td>' +
                '<td style="background-color: black; color: white; padding:12px 8px;">Opened</td>' +
                '<td style="background-color: black; color: white; padding:12px 8px;">Closed</td>' +
                '</tr>';
              let startIndex = Math.round(
                ((series[0].data.length - 1) * dataZoom.start) / 100
              );
              let endIndex = Math.round(
                ((series[0].data.length - 1) * dataZoom.end) / 100
              );
              for (let x = startIndex; x <= endIndex; x++) {
                table +=
                  '<tr>' +
                  '<td style="padding:12px 8px; border-bottom: 1px solid #BDBDBD !important;">' +
                  series[0].data[x][0] +
                  '</td>' +
                  '<td style="padding:12px 8px; border-bottom: 1px solid #BDBDBD !important;">' +
                  series[0].data[x][1] +
                  '</td>' +
                  '<td style="padding:12px 8px; border-bottom: 1px solid #BDBDBD !important;">' +
                  series[1].data[x][1] +
                  '</td>' +
                  '</tr>';
              }
              table += '</tbody></table>';
              return table;
            },
          },
        },
      },
      dataZoom: {
        type: 'slider',
        start: 0,
        end: (13 / ticketData.length) * 100,
        height: 20,
        bottom: '3%',
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '10%',
        containLabel: true,
      },
      series: [
        {
          name: 'Opened',
          type: 'bar',
          data: ticketData.map(({ date, opened }) => [date, opened]),
          color: '#E31C79',
          silent: true,
        },
        {
          name: 'Closed',
          type: 'bar',
          data: ticketData.map(({ date, closed }) => [date, closed]),
          color: '#00B0A3',
          silent: true,
        },
      ],
      xAxis: {
        type: 'category',
        data: ticketData.map(({ date }) => date),
        axisLabel: {
          interval: 0,
          rotate: -45,
        },
      },
      yAxis: {
        type: 'value',
      },
    });
  }
}
