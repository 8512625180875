import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { DateTime } from 'luxon';
import { Calendar } from 'primeng/calendar';
import { DateRangeOption } from './date-range.models';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'date-range-selector',
  templateUrl: './date-range-selector.component.html',
  styleUrls: ['./date-range-selector.component.scss'],
})
export class DateRangeSelectorComponent {
  @ViewChild('calender', { static: true }) calender: Calendar;
  @Input() futurePeriodFilter: number;
  @Input() selectedDate: Date | Date[];
  @Output() dateSelected = new EventEmitter();
  selectionMode = 'single';
  maxAllowedDate: Date;

  rangeOptions = [
    {
      label: 'Single',
      value: 'single',
    },
    {
      label: 'Range',
      value: 'range',
    },
  ];

  dateSelections: DateRangeOption[];

  constructor(private datePipe: DatePipe) {}

  ngOnInit() {
    // Set the maximum allowed date
    this.maxAllowedDate = DateTime.now()
      .plus({ months: this.futurePeriodFilter })
      .toJSDate();

    // Check the selected date type and set the selection mode
    if (Array.isArray(this.selectedDate)) {
      if (this.selectedDate.length > 1) {
        this.selectionMode = 'range';
      }
      if (this.selectedDate.length === 1) {
        this.selectedDate = this.selectedDate[0];
      }
    }

    // Initialize the dateSelections array with conditional logic
    this.dateSelections = [
      { label: 'Today', value: () => DateTime.now() },
      { label: 'Last 7 Days', value: () => DateTime.now().minus({ days: 6 }) },
      {
        label: 'Last 1 Month',
        value: () => DateTime.now().minus({ months: 1 }),
      },
      {
        label: 'Last 3 Months',
        value: () => DateTime.now().minus({ months: 3 }),
      },
      {
        label: 'Last 6 Months',
        value: () => DateTime.now().minus({ months: 6 }),
      },
      ...(this.futurePeriodFilter !== 0
        ? [
            {
              label: 'Next 1 Month',
              value: () => DateTime.now().plus({ months: 1 }),
            },
          ]
        : []),
      ...(this.futurePeriodFilter > 1
        ? [
            {
              label: `Next ${this.futurePeriodFilter} Months`,
              value: () =>
                DateTime.now().plus({ months: this.futurePeriodFilter }),
            },
          ]
        : []),
      { label: 'Custom', value: () => this.changeSelectionMode('range') },
      { label: 'Clear', value: () => this.clearDate() },
    ];
  }

  get selectedDateView(): string {
    if (this.selectedDate instanceof Date) {
      return this.datePipe.transform(this.selectedDate, 'dd/MM/yyyy');
    }
    if (Array.isArray(this.selectedDate)) {
      return this.selectedDate
        .filter(date => date !== null)
        .map(date => this.datePipe.transform(date, 'dd/MM/yyyy'))
        .join(' - ');
    }
    return null;
  }

  setDate(selection: () => DateTime) {
    let selectedDate = selection();
    if (selectedDate) {
      let filterValue;
      if (selectedDate.hasSame(DateTime.now(), 'day')) {
        // Handle single value for today
        this.changeSelectionMode('single');
        selectedDate = selection();
        this.selectedDate = DateTime.now().startOf('day').toJSDate();
      } else if (selectedDate > DateTime.now()) {
        const startDate = DateTime.now().startOf('day');
        const endDate = selectedDate.plus({ months: 0 }).startOf('day');
        filterValue = [startDate.toJSDate(), endDate.toJSDate()];
        this.changeSelectionMode('range');
        this.selectedDate = filterValue;
      } else {
        // Handle the range buttons & custom button
        const startDate = selectedDate.minus({ months: 0 }).startOf('day');
        const endDate = DateTime.now().startOf('day');
        filterValue = [startDate.toJSDate(), endDate.toJSDate()];
        this.changeSelectionMode('range');
        this.selectedDate = filterValue;
      }
      this.dateSelected.emit(this.selectedDate);
    }
  }

  changeSelectionMode(selectionMode: string): void {
    this.selectionMode = selectionMode;
  }

  clearDate(): void {
    this.changeSelectionMode('single');
    this.selectedDate = undefined;
    this.dateSelected.emit(this.selectedDate);
  }

  dateChange(event: Date, selectedDate) {
    this.dateSelected.emit(selectedDate);
  }
}
