<div class="flex flex-column p-3 m-3 shadow-1 surface-0 border-round-sm">
  <p class="text-xl font-bold m-0">
    Monitoring Reports - {{ currentDevice?.name }}
  </p>
  <hr class="w-full mx-auto my-3" />
  <div>
    @if (currentDevice) {
      <div class="flex gap-3">
        <div class="flex-1">
          <p class="font-light pb-1">Model Name</p>
          <p class="font-medium">
            {{ currentDevice.model == null ? 'N/A' : currentDevice.model }}
          </p>
          <hr class="w-full" />
        </div>
        <div class="flex-1">
          <p class="font-light pb-1">Serial Number</p>
          <p class="font-medium">
            {{
            currentDevice.serialNumber == ''
            ? 'N/A'
            : currentDevice.serialNumber
            }}
          </p>
          <hr class="w-full" />
        </div>
        <div class="flex-1">
          <p class="font-light pb-1">IP Address</p>
          <p class="font-medium">
            {{
            currentDevice.ipAddress == '' ? 'N/A' : currentDevice.ipAddress
            }}
          </p>
          <hr class="w-full" />
        </div>
      </div>
    }
  </div>
</div>

@if (currentDevice && !serverMonitoring) {
  <div
    class="flex flex-column p-3 m-3 shadow-1 surface-0 border-round-sm"
    >
    <p style="text-align: center">
      Please provide both a start and end date to run a report, otherwise data is
      defaulted to 24 hours, you can still use the quick time buttons
    </p>
    <div>
      <app-filter
        [(filter)]="filter[0]"
      (refresh)="refreshWithDates()"></app-filter>
      <app-filter
        [(filter)]="filter[1]"
      (refresh)="refreshWithDates()"></app-filter>
    </div>
  </div>
}

@if (currentDevice && !serverMonitoring) {
  <div
    class="flex flex-column p-3 m-3 shadow-1 surface-0 border-round-sm"
    >
    <p-tabView>
      <p-tabPanel header="Latency">
        <science-logic-graph-reporting
          [device]="currentDevice"
          [startDate]="startDate"
          [endDate]="endDate"
        [dataType]="0"></science-logic-graph-reporting>
      </p-tabPanel>
      <p-tabPanel header="Availability %">
        <science-logic-graph-reporting
          [device]="currentDevice"
          [startDate]="startDate"
          [endDate]="endDate"
        [dataType]="1"></science-logic-graph-reporting>
      </p-tabPanel>
      <p-tabPanel header="Availability Time Online">
        <science-logic-graph-reporting
          [device]="currentDevice"
          [startDate]="startDate"
          [endDate]="endDate"
        [dataType]="2"></science-logic-graph-reporting>
      </p-tabPanel>
    </p-tabView>
  </div>
}

@if (currentDevice && serverMonitoring) {
  <science-logic-graph-servers-reporting
  [device]="currentDevice"></science-logic-graph-servers-reporting>
}
