import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RequestOptions } from 'src/app/models/service-request/service-request.models';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { RouterModule } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { firstValueFrom, Observable, Subject, takeUntil } from 'rxjs';
import { CatalogItemsService } from 'src/app/services/api/catalog-items/catalog-items.service';
import {
  GetServiceCatalogCategory,
  GetServiceCatalogCategoryItem,
} from 'src/app/models/service-catalog/service-catalog.models';
import { LoadingSpinnerComponent } from 'src/app/components/misc/loading-spinner/loading-spinner.component';

export enum OtherServiceRequestCategoryItem {
  LABEL = 'Other Service Request',
  VALUE = 'other-service-request',
}

@Component({
  selector: 'service-request-options',
  templateUrl: './service-request-options.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DropdownModule,
    ButtonModule,
    RouterModule,
    LoadingSpinnerComponent,
  ],
  styleUrls: ['./service-request-options.component.scss'],
})
export class ServiceRequestOptionsComponent implements OnInit {
  @Output() formComplete = new EventEmitter<RequestOptions>();
  @Input() resetTrigger$: Observable<boolean>;
  @Input() serviceNowCatalogForm: FormGroup;
  destroy$ = new Subject<unknown>();
  requestCategories: GetServiceCatalogCategory[];

  requestSubCategories: GetServiceCatalogCategoryItem[];

  loading = false;

  constructor(
    private authService: AuthService,
    private catalogItemsService: CatalogItemsService
  ) {}

  async ngOnInit() {
    this.loading = true;
    const requestCategories = await this.getServiceCategories();

    const generalCategory = requestCategories.find(
      category => category.label === 'General'
    );

    const otherServiceRequestItem = {
      label: OtherServiceRequestCategoryItem.LABEL,
      value: OtherServiceRequestCategoryItem.VALUE,
    };

    if (generalCategory) {
      generalCategory.items.push(otherServiceRequestItem);
    }
    if (!generalCategory) {
      requestCategories.push({
        label: 'General',
        value: 'general',
        items: [
          {
            label: OtherServiceRequestCategoryItem.LABEL,
            value: OtherServiceRequestCategoryItem.VALUE,
          },
        ],
      });
    }

    this.requestCategories = requestCategories
      .map(category => ({
        ...category,
        items: category.items.sort((a, b) => a.label.localeCompare(b.label)),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    this.resetTrigger$
      .pipe(takeUntil(this.destroy$))
      .subscribe(resetTrigger => {
        if (resetTrigger) {
          this.resetDropDowns();
        }
      });
    this.loading = false;
  }

  async getServiceCategories() {
    return await firstValueFrom(
      this.catalogItemsService.GetServiceCatalogCategories()
    );
  }

  async progressForm(): Promise<void> {
    const incidentIssue: RequestOptions = {
      category: this.serviceNowCatalogForm.value.category?.value,
      request: this.serviceNowCatalogForm.value.request?.value,
    };
    this.formComplete.emit(incidentIssue);
  }

  resetDropDowns(): void {
    this.serviceNowCatalogForm.reset();
    this.requestSubCategories = [];
  }

  setSubCategory(event) {
    const category: GetServiceCatalogCategory = event.value;
    this.requestSubCategories = category.items;
  }
}
