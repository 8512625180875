<div class="flex flex-column h-full">
  <div
    class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200">
    <h1 class="px-3 text-xl">{{ pageTitle }}</h1>
  </div>
  <div
    class="flex-grow-1 overflow-auto mx-3 bg-white border-solid border-1 border-gray-200 border-top-none border-bottom-none px-3">
    <div class="flex flex-column items-center">
      <p-steps
        [model]="items"
        [readonly]="true"
        [activeIndex]="activeStepIndex"
        (activeIndexChange)="onActiveIndexChange($event)"
        class="mx-auto w-full max-w-60rem">
      </p-steps>
      @if (activeIndex?.label === 'Details') {
        <div class="flex align-items-center justify-content-center bg-white">
          <form [formGroup]="detailsForm">
            <div class="formgrid">
              <div class="field col">
                <span>
                  <span class="text-primary text-lg">*</span>
                  <label for="role-name" class="text-lg"
                    >Name of the role
                  </label>
                </span>
                <input
                  placeholder="Role name"
                  id="role-name"
                  formControlName="roleName"
                  pInputText
                  aria-label="Role Name"
                  class="w-full" />
                @if (
                  detailsForm.get('roleName').invalid &&
                  detailsForm.get('roleName').touched
                ) {
                  <small class="p-error text-base">
                    {{ roleErrorMessage() }}
                  </small>
                }
                @if (checkRoleExistsStatus === 'PENDING') {
                  <div class="py-2">
                    <small class="p-info p-2">
                      <i class="pi pi-spin pi-spinner"></i>
                      Checking role unique...
                    </small>
                  </div>
                }
              </div>
              <div class="field">
                <div class="field col">
                  <span>
                    <span class="text-primary text-lg">*</span>
                    <label for="role-description" class="text-lg"
                      >Description of the role
                    </label>
                  </span>
                  <textarea
                    pInputText
                    id="role-description"
                    placeholder="Role description"
                    formControlName="roleDescription"
                    aria-label="Description"
                    type="text"
                    rows="6"
                    class="w-full"
                    style="resize: none"></textarea>
                  @if (
                    detailsForm.get('roleDescription').invalid &&
                    detailsForm.get('roleDescription').touched
                  ) {
                    <small class="p-error text-base">
                      Description of the role is required
                    </small>
                  }
                </div>
                <div
                  class="flex align-content-center justify-content-center pb-4 gap-2">
                  <p-button
                    styleClass="p-button-secondary"
                    label="Back"
                    (onClick)="backButton()"></p-button>
                  <p-button
                    styleClass="p-button"
                    label="Next"
                    (onClick)="stepForward()"
                    [disabled]="
                      detailsForm.invalid || detailsForm.pending
                    "></p-button>
                </div>
              </div>
            </div>
          </form>
        </div>
      }
      <!--Permissions-->
      @if (activeIndex?.label === 'Permissions') {
        <div class="flex align-items-center justify-content-center">
          @if (loading) {
            <loading-spinner />
          }
          @if (!loading) {
            <div class="col-8 p-0 max-h-30rem overflow-auto">
              <div class="border-1 border-gray-500 p-3">
                @for (category of allPermissions; track category) {
                  <div class="flex align-content-center flex-wrap mb-3">
                    <h3 class="m-0">
                      {{ category.header | titlecase | replaceUnderscore }}
                    </h3>
                    <button
                      pButton
                      (click)="
                        toggleSelectCategoryPermissions(category.permissions)
                      "
                      class="p-button-sm p-button-outlined ml-2">
                      {{
                        allCategoryPermissionsSelected(category.permissions)
                          ? 'Deselect All'
                          : 'Select All'
                      }}
                    </button>
                  </div>
                  <div class="grid mb-3">
                    @for (
                      permission of category.permissions;
                      track permission
                    ) {
                      <div class="col-6">
                        <div class="flex">
                          <p-checkbox
                            [value]="permission"
                            [inputId]="permission.label"
                            [(ngModel)]="selectedPermissions"></p-checkbox>
                          <label class="ml-2" [for]="permission.uLabel"
                            >{{ permission.uLabel }}<br />{{
                              permission.uDescription
                            }}</label
                          >
                        </div>
                      </div>
                    }
                  </div>
                }
              </div>
              @if (selectedPermissions.length === 0) {
                <div class="flex align-content-center justify-content-center">
                  <small class="p-error text-base"
                    >No permissions have been selected</small
                  >
                </div>
              }
            </div>
          }
        </div>
      }

      @if (activeIndex?.label === 'Permissions') {
        <div>
          <div
            class="flex align-content-center justify-content-center pt-4 pb-4 gap-2 bg-white">
            <p-button
              styleClass="p-button-secondary"
              label="Back"
              (onClick)="stepBack()"></p-button>
            <p-button
              styleClass="p-button"
              label="Next"
              (onClick)="submitButton()"
              [disabled]="selectedPermissions.length === 0"></p-button>
          </div>
        </div>
      }
      <!--Submit-->
      @if (activeIndex?.label === 'Submit') {
        <div class="flex align-items-center justify-content-center">
          @if (loading) {
            <loading-spinner />
          }
          @if (!loading) {
            <div>
              <h2 class="text-lg font-light">{{ submitHeading }}</h2>
              <div class="flex align-items-center justify-content-center">
                <p-button
                  (onClick)="finishButton()"
                  label="Finish"
                  styleClass="p-button"></p-button>
              </div>
            </div>
          }
        </div>
      }
    </div>
  </div>
</div>
