import { Injectable } from '@angular/core';
import { ICreateInteractionRequest } from './interaction-models';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';

@Injectable({
  providedIn: 'root',
})
export class InteractionService {
  constructor(private apiRequest: ApiRequest) {}

  createInteraction(body: ICreateInteractionRequest) {
    const requestProps: IRequestOptions = {
      endpoint: 'interaction/createInteraction',
      body: body,
    };
    return this.apiRequest.request(requestProps);
  }
}
