export interface IProductCategory {
  category: string;
  products: IProductWithUsage[];
}
export interface IProductWithUsage {
  siteId: string;
  usage: number;
  invoiceDate: Date;
  isDirty: boolean;
  productGroup: string;
  productCategory: string;
  billingDescription: string;
  cli: string;
  costCentre: string;
  siteUser: string;
  billingFrequency: string;
  currentID: number;
  costCentreID: number;
  userID: number;
  cliID: number;
  apiFunction: string;
  charge: number;
  billingStart: string;
  billingEnd: string;
  active: number;
  contractLength: number;
  contractEnd: string;
}

export interface IProductsGetUsageDataRequest {
  products: IProductWithUsage[];
  filter: any;
  startDate?: Date;
  endDate?: Date;
  siteID: string;
}

export enum ProductCategories {
  connectivity = 'Connectivity',
  ucc = 'UC&C',
  cloudAndHosting = 'Cloud & Hosting',
  security = 'Security',
  services = 'End-User Services',
}
