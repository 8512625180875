import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DevicesService } from 'src/app/services/devices/devices.service';
import { MenuItem } from 'primeng/api';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';

@Component({
  selector: 'science-logic',
  templateUrl: 'science-logic.component.html',
  styleUrls: ['science-logic.component.scss'],
})
export class ScienceLogicComponent implements OnInit {
  filter = [
    {
      Column: 'Start',
      Value: [],
      Type: 'date',
      Options: [],
      Visibility: false,
    },
    { Column: 'End', Value: [], Type: 'date', Options: [], Visibility: false },
  ];
  currentDevice: any;

  startDate: any;
  endDate: any;

  serverMonitoring: boolean = false;

  constructor(
    private router: Router,
    private devicesService: DevicesService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    const breadcrumbs: MenuItem[] = [{ label: 'Device' }];
    this.currentDevice = JSON.parse(
      localStorage.getItem('science-logic-device')
    );
    if (this.currentDevice == null || this.currentDevice == undefined) {
      this.router.navigate([
        'secure/products-and-services/monitoring/devices/server',
      ]);
      return;
    }
    if (this.currentDevice.category === 'server') {
      this.serverMonitoring = true;
    }
  }

  refreshWithDates() {
    if (this.filter[0].Value.length > 0 && this.filter[1].Value.length > 0) {
      this.startDate = this.filter[0].Value;
      this.endDate = this.filter[1].Value;
    }
  }

  returnToDevices() {
    this.router.navigate([
      this.devicesService.devicesUrlBuilder({
        category: this.currentDevice?.category,
        subCategory: this.currentDevice?.subCategory,
      }),
    ]);
  }

  ngOnDestroy() {
    localStorage.removeItem('science-logic-device');
  }
}
