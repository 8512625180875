import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ReleaseNotesService } from 'src/app/services/api/release-notes/release-notes.service';
import { firstValueFrom } from 'rxjs';
import { IReleaseNotes } from 'src/app/components/misc/shared/release-notes.model';
import { MenuItem } from 'primeng/api';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
@Component({
  selector: 'release-notes',
  templateUrl: 'release-notes.component.html',
  styleUrls: ['release-notes.component.scss'],
})
export class ReleaseNotesComponent implements OnInit {
  releaseNotes: IReleaseNotes[];
  loading = false;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private releaseNotesService: ReleaseNotesService,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    const breadcrumbs: MenuItem[] = [
      {
        label: 'Release Notes',
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.loading = true;
    this.releaseNotes = await this.getReleaseNotes();
    this.loading = false;
  }

  async getReleaseNotes(): Promise<IReleaseNotes[]> {
    const releseNotes = firstValueFrom(
      await this.releaseNotesService.getReleaseNotes(
        this.document.location.href
      )
    );
    return releseNotes;
  }
}
