@if (loading) {
  <loading-spinner />
}
@if (!loading) {
  <div class="mx-3 py-3">
    <form [formGroup]="serviceNowCatalogForm">
      @for (item of structure; track item) {
        <app-service-catalog-item
          [item]="item"
          [formGroup]="
            serviceNowCatalogForm.get('variables')
          "></app-service-catalog-item>
      }
    </form>
  </div>
}
