import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  IBar,
  IBarCategoryContainer,
  IBarContainer,
  IBarDropdownOption,
  IUpdatedBarControls,
  IMultiBarContainer,
  IMobile,
} from '../../models/telephony-models';
import { BarringService } from '../../shared/barring.service';
import { EditBarsService } from 'src/app/services/edit-bars.service';
import { ViewNumbersComponent } from 'src/app/components/misc/pop-up/view-numbers/view-numbers.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import { MenuItem } from 'primeng/api';
@Component({
  selector: 'app-edit-multi-bar',
  templateUrl: './edit-multi-bar.component.html',
  styleUrls: ['./edit-multi-bar.component.scss'],
  providers: [DialogService],
})
export class EditMultiBarComponent implements OnInit {
  loading: boolean = false;
  vfMobiles: string[] = [];
  o2Mobiles: string[] = [];
  o2Bars: IBar[];
  vfBars: IBar[];
  ref: DynamicDialogRef;

  items: MenuItem[] | undefined;
  steps: MenuItem[] | undefined = [];
  activeStepIndex: number = 0;
  activeIndex: MenuItem = this.steps[0];

  updatedControlsO2: IUpdatedBarControls = {
    categorised: [],
    nonCategorised: [],
  };
  updatedControlsVF: IUpdatedBarControls = {
    categorised: [],
    nonCategorised: [],
  };

  dropdownOptions: IBarDropdownOption[] = [
    { label: 'No Change', value: null },
    { label: 'On', value: true },
    { label: 'Off', value: false },
  ];

  submitFinished: boolean = false;
  formLoadedO2: boolean = false;
  FormLoadedVF: boolean = false;

  formSectionStrings = {
    general: 'general',
    roaming: 'roaming',
    roamingCap: 'roamingcap',
    calling: 'calling',
  };

  barringFormO2 = this.formBuilder.group({
    general: this.formBuilder.array([]),
    roaming: this.formBuilder.array([]),
    calling: this.formBuilder.array([]),
  });

  barringFormVF = this.formBuilder.group({
    general: this.formBuilder.array([]),
    roaming: this.formBuilder.array([]),
    roamingcap: this.formBuilder.array([]),
    calling: this.formBuilder.array([]),
  });

  constructor(
    public barringService: BarringService,
    private formBuilder: FormBuilder,
    private router: Router,
    private editBarsState: EditBarsService,
    private dialogService: DialogService,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    const breadcrumbs: MenuItem[] = [
      {
        label: 'Mobile',
        routerLink: '/secure/products-and-services/telephony',
      },
      { label: 'Amend Options' },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);

    const mobiles = this.editBarsState.numbers;
    if (mobiles.length <= 0) this.navigateToMobileData();
    this.vfMobiles = mobiles
      .filter((item: IMobile) => item.network.toLowerCase() === 'vf')
      .map((mobile: IMobile) => mobile.cliNumber);
    this.o2Mobiles = mobiles
      .filter(item => item.network.toLowerCase() === 'o2')
      .map((mobile: IMobile) => mobile.cliNumber);
    if (this.o2Mobiles.length > 0) this.initialiseO2Form(this.o2Mobiles[0]);
    if (this.vfMobiles.length > 0) this.initialiseVFForm(this.vfMobiles[0]);
    this.setStepperHeadings();
    this.activeIndex = this.items[this.activeStepIndex];
  }

  setStepperHeadings() {
    this.items = [
      {
        label: 'Confirm',
      },
      {
        label: 'Submit',
      },
    ];

    const o2Options =
      this.o2Mobiles.length > 0 ? [{ label: 'O2 Options' }] : [];
    const vfOptions =
      this.vfMobiles.length > 0 ? [{ label: 'Vodafone Options' }] : [];

    this.items = [...o2Options, ...vfOptions, ...this.items];
  }

  async initialiseO2Form(cliNumber: string) {
    this.loading = true;
    this.o2Bars = await this.loadBars(cliNumber);
    let barDataO2: IBarCategoryContainer =
      await this.loadBarsCategorised(cliNumber);
    barDataO2.barCategories.forEach(categoryItem =>
      this.buildForm(this.barringFormO2, categoryItem.barList)
    );
    this.formLoadedO2 = true;
    this.loading = false;
  }

  async initialiseVFForm(cliNumber: string) {
    this.loading = true;
    this.vfBars = await this.loadBars(cliNumber);
    let barDataVF: IBarCategoryContainer =
      await this.loadBarsCategorised(cliNumber);
    barDataVF.barCategories.forEach(categoryItem =>
      this.buildForm(this.barringFormVF, categoryItem.barList)
    );
    this.formLoadedO2 = true;
    this.FormLoadedVF = true;
    this.loading = false;
  }

  buildForm(form: FormGroup, bars: IBar[]) {
    bars.forEach(item => {
      this.formAddBar(form, item);
    });
  }

  formAddBar(form: FormGroup, bar: IBar) {
    const newFormBar = this.formBuilder.group({
      name: [bar.name, '', ''],
      active: [null, '', ''],
      category: [bar.category, '', ''],
    });
    this.getFormCategory(form, bar.category.toLowerCase()).push(newFormBar);
  }

  async loadBars(mobileNumber: string) {
    let barData: IBarContainer =
      await this.barringService.loadBars(mobileNumber);
    return barData.bars;
  }

  async loadBarsCategorised(mobileNumber: string) {
    let barData: IBarCategoryContainer =
      await this.barringService.loadBarsCategorised(mobileNumber);
    return barData;
  }

  async submitForm() {
    this.stepForward();
    let toggleBarRequests: IMultiBarContainer[] = [];

    if (this.updatedControlsO2.nonCategorised.length > 0)
      toggleBarRequests.push({
        network: 'o2',
        clis: this.o2Mobiles,
        bars: this.updatedControlsO2.nonCategorised,
      });

    if (this.updatedControlsVF.nonCategorised.length > 0)
      toggleBarRequests.push({
        network: 'vodafone',
        clis: this.vfMobiles,
        bars: this.updatedControlsVF.nonCategorised,
      });

    let response =
      await this.barringService.toggleBarsForMultipleCLI(toggleBarRequests);
    this.submitFinished = true;
  }

  getFormCategory(form: FormGroup, category: string): FormArray {
    return form?.controls[category] as FormArray;
  }

  updateDirtyValues() {
    this.updatedControlsO2 = this.getDirtyFormValues(
      this.barringFormO2,
      this.o2Bars
    );
    this.stepForward();
  }

  updateDirtyValuesVF() {
    this.updatedControlsVF = this.getDirtyFormValues(
      this.barringFormVF,
      this.vfBars
    );
    this.stepForward();
  }

  private getDirtyFormValues(parentForm: FormGroup, bars: IBar[]) {
    let dirtyControls: IUpdatedBarControls = {
      categorised: [],
      nonCategorised: [],
    };
    Object.keys(parentForm.controls).forEach(control => {
      let currentFormSection = <FormArray>parentForm.get(control);

      if (currentFormSection.dirty) {
        let dirtyControlCategory = {
          name: control,
          updatedValues: [],
        };

        currentFormSection.controls.forEach(item => {
          if (item.value.active === null) {
            return;
          }
          if (this.hasControlChanged(item, bars) === true) {
            dirtyControlCategory.updatedValues.push(item);
            dirtyControls.nonCategorised.push(item.value);
          } else if (this.hasControlChanged(item, bars) === false) {
            dirtyControlCategory.updatedValues.push(item);
            dirtyControls.nonCategorised.push(item.value);
          }
        });

        dirtyControls.categorised.push(dirtyControlCategory);
        dirtyControls.nonCategorised.push();
      }
    });
    return dirtyControls;
  }

  private hasControlChanged(control, bars: IBar[]): boolean {
    if (
      control.value.active !== '' &&
      control.dirty &&
      bars.filter(x => x.name === control.value.name)[0].active !==
        control.value.active
    ) {
      return true;
    } else {
      return false;
    }
  }

  cssSubmitButtonEnabled() {
    switch (
      this.updatedControlsO2?.nonCategorised.length === 0 &&
      this.updatedControlsVF?.nonCategorised.length === 0
    ) {
      case true:
        return 'next-button-disabled';
      case false:
        return 'next-button';
    }
  }

  convertActive(active: boolean) {
    switch (active) {
      case true:
        return 'On';
      case false:
        return 'Off';
      case null:
        return 'No Change';
    }
  }

  cssSetDropdownStyle(dropdownValue) {
    return this.barringService.cssSetDropdownStyle(dropdownValue);
  }

  cssDisableSelectedOption(bar: FormControl, option: boolean) {
    return this.barringService.cssDisableSelectedOption(bar, option);
  }

  onActiveIndexChange(event: number): void {
    this.activeStepIndex = event;
    this.activeIndex = this.items[this.activeStepIndex];
  }

  stepBack(): void {
    if (this.activeStepIndex === 0) {
      this.router.navigateByUrl('secure/products-and-services/telephony');
    }
    if (this.activeStepIndex === 1 || this.activeStepIndex === 2) {
      this.onActiveIndexChange(this.activeStepIndex - 1);
    }
  }

  stepForward(): void {
    if (this.items[this.activeStepIndex + 1]) {
      this.onActiveIndexChange(this.activeStepIndex + 1);
    }
  }

  openNumbers(mobileNumbers: object): void {
    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    this.ref = this.dialogService.open(ViewNumbersComponent, {
      width: '25%',
      showHeader: false,
      data: {
        mobileNumbers: mobileNumbers,
      },
      styleClass: 'p-dialog-custom',
    });
  }

  navigateToMobileData(): void {
    this.router.navigateByUrl('secure/products-and-services/telephony/data');
  }
}
