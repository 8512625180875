import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { LazyLoadEvent } from 'primeng/api';
import { Paginator, PaginatorState } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BuildServiceNowQuery } from 'src/app/helpers/servicenow/filter/servicenow-filter.helper';
import {
  IsPaginatorState,
  SetSelectedColumns,
  TableReset,
} from 'src/app/helpers/table.helper';
import { ITableToFile } from 'src/app/models/export-models';
import { TableColumn } from 'src/app/models/table';
import {
  ExportFileName,
  ExportService,
  ExportType,
} from 'src/app/services/export.service';
import { ToastService, ToastType } from 'src/app/services/global/toast.service';
import { ExportTable } from '../../products-and-services/science-logic/cmdb-devices/shared/export-devices';
import { ProductCategories } from '../models/billing-models';
import { connectivityColumns } from '../models/connectivity.models';
import { ProductService } from '../shared/product.service';

@Component({
  selector: 'app-connectivity',
  templateUrl: './connectivity.component.html',
  styleUrls: ['./connectivity.component.scss'],
})
export class ConnectivityComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClass = 'overflow-auto';
  @ViewChild('dataTable') dataTable: Table;
  @ViewChild('tablePaginator') paginator: Paginator;
  @Input() selectedSite$ = new Observable();
  @Output() loadingChange = new EventEmitter<boolean>();
  selectedSiteId: string;

  cdr = inject(ChangeDetectorRef);
  tableDataSubject = new BehaviorSubject<any>(null);
  tableData$ = this.tableDataSubject.asObservable();
  destroy$ = new Subject<any>();
  cols: TableColumn[] = cloneDeep(connectivityColumns);
  _selectedColumns: TableColumn[] = [];
  totalRecords: number = 0;
  tableLoading: boolean = false;
  firstLoad: boolean = true;
  staticTable: Table;

  get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter(col =>
      val.map(valCol => valCol.header).includes(col.header)
    );
  }

  exportOptions = [
    {
      label: 'All',
      value: 'all',
      command: () => this.exportAll(),
    },
    {
      label: 'In View',
      value: 'in_view',
      command: () =>
        ExportTable(
          this.dataTable,
          this.tableDataSubject.value,
          this._selectedColumns,
          'all'
        ),
    },
  ];

  constructor(
    private productService: ProductService,
    private toastService: ToastService,
    private exportService: ExportService
  ) {}

  async ngOnInit() {
    this.selectedSite$
      .pipe(takeUntil(this.destroy$))
      .subscribe((siteID: string) => {
        if (siteID && siteID !== this.selectedSiteId) {
          this.selectedSiteId = null;
          this.cdr.detectChanges();
          this.selectedSiteId = siteID;
          this.cdr.detectChanges();
        }
      });
  }

  async loadProductData(table: Table, event?: LazyLoadEvent | PaginatorState) {
    if (IsPaginatorState(event)) {
      table.rows = event?.rows;
      table.first = event?.first;
    }
    const isFirstLoad = this.firstLoad ? true : false;

    this.tableDataSubject.next([]);

    this.loadingChange.emit(true);
    table.loading = true;
    if (isFirstLoad) {
      this.reset(table, true, cloneDeep(connectivityColumns));
      this.firstLoad = false;
    }
    const currentPage =
      event?.first != null && event?.rows != null
        ? event.first / event.rows
        : table.first / table.rows;
    const currentPerPage = event?.rows ? event.rows : table.rows;

    let serviceNowFilter = BuildServiceNowQuery(
      table,
      this.cols,
      currentPerPage,
      currentPage,
      event,
      { SiteID: this.selectedSiteId }
    );

    serviceNowFilter.Filter.push({
      Column: 'ProductGroup',
      Value: [ProductCategories.connectivity],
    });

    const response =
      await this.productService.loadProductCategory(serviceNowFilter);

    this.totalRecords = response.queryCount;
    this.tableDataSubject.next(response.result);
    this.staticTable = table;
    table.loading = false;
    if (!IsPaginatorState(event)) {
      if (this.paginator) {
        this.paginator.first = 0;
      }
    }
    this.loadingChange.emit(false);
    await this.productService.loadFilterDynamicValues(
      this.cols,
      ProductCategories.connectivity
    );
  }

  handleColumnChange(val: any[]) {
    this.cols = this.cols.filter(col =>
      val.map(valCol => valCol.header).includes(col.header)
    );
  }

  defaultExport() {
    ExportTable(this.dataTable, this.tableDataSubject.value, this.cols, 'all');
  }

  async exportAll() {
    this.toastService.add({
      severity: ToastType.info,
      summary: 'Downloading',
      detail:
        "We are just sorting your data out for you. This won't take long.",
    });
    ``;
    const serviceNowFilter = BuildServiceNowQuery(this.dataTable, this.cols);
    const fields = this._selectedColumns.map(item => item.serviceNowField);

    serviceNowFilter.Filter.push({
      Column: 'ProductGroup',
      Value: [ProductCategories.connectivity],
    });

    const body: ITableToFile = {
      Filter: serviceNowFilter.Filter,
      ExportType: ExportType.Csv,
      CurrentPage: 0,
      Amount: 25,
      Pagination: false,
      ExportColumns: fields,
      SiteID: this.selectedSiteId,
    };

    try {
      const download = await this.productService.exportProductCategory(body);

      this.exportService.downloadFile(
        download,
        ExportType.Csv,
        ExportFileName.BillingConnectivity
      );
    } catch {
      this.toastService.add({
        severity: ToastType.error,
        summary: 'Error',
        detail: 'Unable to download table data.',
      });
    }
  }

  async ngOnDestroy() {
    this.destroy$.next(void 0);
    this.destroy$.complete();
  }

  reset(
    table: Table,
    firstLoad: boolean = false,
    columns: TableColumn[] = connectivityColumns
  ) {
    this._selectedColumns = SetSelectedColumns(
      this.cols,
      columns.filter(column => column?.default).map(column => column?.field)
    );
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
    });
  }
}
