import { Injectable } from '@angular/core';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private apiRequest: ApiRequest) {}

  getSearchResults(filter: any) {
    const requestProps: IRequestOptions = {
      endpoint: 'search/GetSearchResults',
      body: filter,
      requiresVpc: true,
    };

    return this.apiRequest.request(requestProps);
  }
}
