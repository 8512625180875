import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'not-authorised',
  templateUrl: 'not-authorised.component.html',
  styleUrls: ['not-authorised.component.scss'],
})
export class NotAuthorisedComponent implements OnInit {
  backUrl: string;
  constructor(
    private router: Router,
    private location: Location
  ) {
    this.backUrl = this.router.getCurrentNavigation().extras?.state?.backUrl;
  }
  async ngOnInit() {}

  goBackButton(): void {
    if (this.backUrl) {
      this.router.navigate([this.backUrl]);
    } else {
      this.location.back();
    }
  }

  backToDashBoardButton(): void {
    this.router.navigate([`/secure/dashboard`]);
  }
}
