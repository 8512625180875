<div class="flex flex-column h-full">
  <div
    class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200">
    <h1 class="px-3 text-xl">Domains</h1>
  </div>
  <div
    class="flex-grow-1 overflow-auto mx-3 overflow-hidden bg-white border-solid border-1 border-gray-200 border-top-none border-bottom-none px-3">
    <p-table
      #dataTable
      [value]="domains$ | async"
      [columns]="selectedColumns"
      styleClass="p-datatable-striped"
      [rowHover]="true"
      sortMode="single"
      scrollHeight="flex"
      [scrollable]="true"
      [rows]="25"
      [lazy]="true"
      (onLazyLoad)="loadDomains(dataTable, $event)">
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between">
          <div class="flex">
            @if (selectedColumns && dataTable && dataTable.loading === false) {
              <prime-filter-dropdown
                [selectedColumns]="cols"
                [table]="dataTable"></prime-filter-dropdown>
            }
            <p-button
              label="Reset"
              styleClass="p-button p-button-text p-button-secondary"
              icon="pi pi-eraser"
              (onClick)="reset(dataTable, false)"></p-button>
          </div>
          <p-splitButton
            label="Export"
            icon="pi pi-download"
            iconPos="left"
            [model]="exportOptions"
            styleClass="p-button"
            appendTo="body"
            (onClick)="defaultExport()"></p-splitButton>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          @for (col of columns; track col) {
            <th
              class="text-white bg-gray-800 p-datatable-header-first-row"
              [pSortableColumn]="col.field">
              {{ col.header }}
              @if (col.showSortIcon) {
                <p-sortIcon class="text-white" [field]="col.field"></p-sortIcon>
              }
            </th>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <tr>
          <td [attr.colspan]="selectedColumns?.length + 1">
            <loading-spinner></loading-spinner>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingicon"></ng-template>
      <ng-template pTemplate="body" let-domain>
        <tr>
          <td (click)="openDomain(domain)" class="cursor-pointer">
            {{ domain?.name }}
          </td>
          @if (isDsAdmin) {
            <td>{{ domain?.company }}</td>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="selectedColumns?.length + 1">
            No results were found.
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td [attr.colspan]="selectedColumns.length + 1" class="bg-white">
            <div class="flex align-items-center justify-content-center">
              <p-paginator
                #tablePaginator
                (onPageChange)="loadDomains(dataTable, $event)"
                [first]="dataTable.first"
                [rows]="dataTable.rows"
                [totalRecords]="totalRecords"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} - {last} of {totalRecords}"
                [rowsPerPageOptions]="[25, 50, 100]"></p-paginator>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
