import { DateTime } from 'luxon';

export const OrderRequestSizeLimit = 1000;

export enum OrderTabs {
  open = 'Open',
  closed = 'Closed',
}

export const OrderOpenStateValues = [
  'Pending',
  'Open',
  'Work In Progress',
  'Awaiting Info',
  'Fulfilled',
];

export const OrderClosedStateValues = ['Closed'];

export type GetOrdersResponseItem = {
  sysId: string;
  shortDescription: string;
  state: string;
  openedAt?: string;
  number: string;
  serviceRequestNumber: string;
  uRequestedFor: string;
};

export type OrderLineItem = {
  sysId: string;
  requestedFor: string;
  shortDescription: string;
  productSpecification: string;
  number: string;
  state: string;
  installationAddress: OrderLineAddress;
  requiredBy: DateTime;
  committedDate: DateTime;
  completedDate: DateTime;
  order: string;
  addressReference: string;
};
export type OrderLineAddress = {
  organisationName: string;
  siteName: string;
  subsiteName: string;
  streetAddress: string;
  city: string;
  state: string;
  country: string;
  zip: string;
};

export type CreateOrderLineItemCommentRequest = {
  itemSysId: string;
  comment: string;
};

export type FileUpload = {
  name: string;
  tableName: string;
  tableSysId: string;
  base64Encoding: string | ArrayBuffer;
  contentType: string;
  errorMessage?: string;
  size: number;
};

//Column names match export DTOs in Customer portal
export enum OrderTableColumnNames {
  number = 'Number',
  request = 'Request',
  description = 'ShortDescription',
  requestedFor = 'RequestedFor',
  requestedForSysId = 'RequestedFor.SysId',
  openedAt = 'OpenedAt',
  state = 'State',
}

export enum OrderLineTableColumnNames {
  number = OrderTableColumnNames.number,
  request = OrderTableColumnNames.request,
  description = OrderTableColumnNames.description,
  requestedFor = OrderTableColumnNames.requestedFor,
  openedAt = OrderTableColumnNames.openedAt,
  state = OrderTableColumnNames.state,
}
export const searchableOrderFields = [
  OrderTableColumnNames.number,
  OrderTableColumnNames.request,
  OrderTableColumnNames.description,
  OrderTableColumnNames.requestedFor,
];

export const searchableOrderLineFields = [
  OrderLineTableColumnNames.number,
  OrderLineTableColumnNames.request,
  OrderLineTableColumnNames.description,
  OrderLineTableColumnNames.requestedFor,
];

export const searchableOrderLineItemFields = [];

export interface IStateColor {
  [key: string]: string;
}

export const ordersColors = {
  New: 'new',
  'In Progress': 'inprogress',
  Pending: 'pending',
  'Awaiting Info': 'awaitinginfo',
  Resolved: 'resolved',
  Closed: 'closed',
  Open: 'open',
};
