import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { FileService } from 'src/app/services/api/file/file.service';

@Component({
  selector: 'app-download-export',
  templateUrl: './download-export.component.html',
  styleUrls: ['./download-export.component.scss'],
})
export class DownloadExportComponent implements OnInit {
  constructor(
    private fileService: FileService,
    private router: Router
  ) {}

  async ngOnInit() {
    const fileName = await firstValueFrom(this.fileService.downloadExport());
    window.location.href = fileName;
    this.router.navigateByUrl('secure/dashboard');
  }
}
