<div class="flex flex-column">
  <div class="flex justify-content-between align-items-center pl-3">
    <h1 class="text-xl">Cost Centre and User List</h1>
    <div class="flex align-items-center">
      <p-button
        icon="pi pi-pencil"
        label="Edit"
        class="mr-2"
        (onClick)="openEditCostCentres()"
      [disabled]="selectedCostCentres.length === 0"></p-button>
    </div>
  </div>
  <hr class="w-full" />
  <div class="px-3">
    <p-table
      #dataTable
      [value]="costCentreAndUserItems$ | async"
      [columns]="selectedColumns"
      styleClass="p-datatable-striped"
      [rowHover]="true"
      sortMode="single"
      [rows]="25"
      [lazy]="true"
      [(selection)]="selectedCostCentres"
      (onLazyLoad)="loadTableData(dataTable, $event)">
      <ng-template pTemplate="caption">
        <div class="flex justify-content-end align-content-center">
          <div class="flex-grow-1">
            @if (
              selectedColumns && dataTable && dataTable.loading === false
              ) {
              <prime-filter-dropdown
                [selectedColumns]="cols"
              [table]="dataTable"></prime-filter-dropdown>
            }
          </div>
          <p-button
            label="Reset"
            styleClass="p-button p-button-text p-button-secondary"
            icon="pi pi-filter-slash"
          (click)="reset(dataTable, false)"></p-button>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width: 4rem" class="bg-gray-800">
            <p-tableHeaderCheckbox />
          </th>
          @for (col of columns; track col) {
            <th
              class="text-white bg-gray-800 p-datatable-header-first-row"
              [pSortableColumn]="col.field">
              {{ col.header }}
              @if (col.showSortIcon) {
                <p-sortIcon
                  class="text-white"
                [field]="col.field"></p-sortIcon>
              }
            </th>
          }
        </tr>
      </ng-template>

      <ng-template pTemplate="loadingbody">
        <tr>
          <td [attr.colspan]="selectedColumns?.length + 1">
            <loading-spinner></loading-spinner>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingicon"></ng-template>
      <ng-template pTemplate="body" let-items let-columns="columns">
        <tr class="cursor-pointer">
          <td (click)="$event.stopPropagation()">
            <p-tableCheckbox [value]="items" />
          </td>
          @for (col of columns; track col) {
            <td>
              @if (col.display?.type === 'text') {
                {{ items[col.field] }}
              }
            </td>
          }
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="selectedColumns?.length + 1">
            No results were found.
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td [attr.colspan]="selectedColumns.length + 1">
            <div class="flex align-items-center justify-content-center">
              <p-paginator
                #tablePaginator
                (onPageChange)="loadTableData(dataTable, $event)"
                [first]="dataTable.first"
                [rows]="dataTable.rows"
                [totalRecords]="totalRecords"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} - {last} of {totalRecords}"
              [rowsPerPageOptions]="[25, 50, 100]"></p-paginator>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
