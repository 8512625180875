@if (loading) {
  <loading-spinner />
}
@if (!loading) {
  <div class="pt-2 pl-4 pr-4">
    <div>
      <p class="text-lg">Please select the main issue you are experiencing</p>
    </div>
    <form [formGroup]="identifyForm">
      <div class="field">
        <span class="text-primary text-lg">*</span>
        <label for="category" class="text-lg font-bold">Service category</label>
        <p-dropdown
          id="category"
          styleClass="w-full"
          class="w-full"
          placeholder="Select"
          [options]="serviceCategories"
          optionLabel="name"
          formControlName="category"
          (onChange)="setSubCategory($event)" />
      </div>
      <div class="field">
        <span class="text-primary text-lg">*</span>
        <label for="subcategory" class="text-lg font-bold">Sub-category</label>
        <p-dropdown
          formControlName="subCategory"
          [options]="subCategories"
          id="subcategory"
          placeholder="Select"
          optionLabel="name"
          styleClass="w-full"
          class="w-full"
          (onChange)="setSymptom($event)" />
      </div>
      <div class="field">
        <span class="text-primary text-lg">*</span>
        <label for="symptom" class="text-lg font-bold">Symptom</label>
        <p-dropdown
          formControlName="symptom"
          [options]="symptoms"
          optionLabel="name"
          placeholder="Select"
          id="symptom"
          styleClass="w-full"
          class="w-full"
          (onChange)="suggestArticles($event)" />
      </div>
    </form>
    @if (loadingSuggestedArticles) {
      <small class="p-info p-2">
        <i class="pi pi-spin pi-spinner"></i>
        Loading suggested articles...
      </small>
    }
    @if (suggestedArticles.length) {
      <div
        class="py-2 px-4 flex flex-column gap-2 border-left-3 border-gray-500 bg-gray-100">
        <p class="text-sm font-bold m-0">
          Based on your issue, have you tried the following methods to solve it?
        </p>
        @for (article of suggestedArticles; track article) {
          <a
            href="/secure/knowledge-article/{{ article.sysId }}"
            target="_blank"
            rel="noopener noreferrer"
            class="text-teal-600"
            >{{ article.number }} - {{ article.shortDescription }}</a
          >
        }
      </div>
    }
  </div>
}

@if (!loading && !identifyForm?.invalid && !loadingSuggestedArticles) {
  <div class="pt-2 px-4">
    @if (suggestedArticles.length) {
      <p class="text-lg text-center font-bold">
        Did the above resources help you solve the issue?
      </p>
    }
    <div class="flex align-content-center justify-content-center">
      @if (suggestedArticles.length) {
        <p-button
          (onClick)="progressForm(true)"
          styleClass="p-button-secondary"
          label="No, the issue remains unresolved"></p-button>
        <p-button
          (onClick)="wasHelpful()"
          label="Yes, the issue has been solved"
          [routerLink]="['/secure/tickets/raise']"></p-button>
      }
      @if (!suggestedArticles.length) {
        <p-button
          styleClass="p-button-secondary"
          [routerLink]="['/secure/tickets/raise']"
          label="Back"></p-button>
        <p-button
          (onClick)="progressForm(false)"
          [disabled]="identifyForm?.invalid"
          label="Next"></p-button>
      }
    </div>
  </div>
}
