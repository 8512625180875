import { TableColumn } from 'src/app/models/table';

export enum CreditNoteTableFields {
  number = 'InvoiceNo',
  tag = 'InvoiceTag',
  date = 'InvoiceDate',
  sitID = 'SiteId',
  net = 'Net',
  vat = 'Vat',
  account = 'SiteName',
}

export type ICreditNote = {
  company: any;
  companyId: number;
  date: Date;
  gross: number;
  invoiceId: number;
  invoiceNo: string;
  net: number;
  siteFullName: string;
  siteId: string;
  siteName: string;
  type: string;
  vat: number;
};
export type ICreditColumn = {
  field: string;
  header?: string;
  default: boolean;
  display?: any;
  filter?: {
    type: string;
    values: { label: string; value: string }[];
  };
  checkBoxVisible?: boolean;
  showSortIcon: boolean;
};

export const creditColumns: TableColumn[] = [
  {
    field: 'invoiceNo',
    header: 'Number',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
    disabled: true,
    serviceNowField: CreditNoteTableFields.number,
    checkBoxVisible: true,
  },
  {
    field: 'date',
    header: 'Date',
    display: { type: 'date' },
    filter: {
      type: 'dateRange',
    },
    showSortIcon: true,
    default: true,
    serviceNowField: CreditNoteTableFields.date,
  },
  {
    field: 'invoiceTag',
    header: 'Invoice Tag',
    display: { type: 'text' },
    showSortIcon: true,
    default: false,
    serviceNowField: CreditNoteTableFields.tag,
  },
  {
    field: 'net',
    header: 'Net',
    display: { type: 'number' },
    showSortIcon: true,
    default: true,
    serviceNowField: CreditNoteTableFields.net,
  },
  {
    field: 'vat',
    header: 'Vat',
    display: { type: 'number' },
    showSortIcon: true,
    default: true,
    serviceNowField: CreditNoteTableFields.vat,
  },
  {
    field: 'siteFullName',
    header: 'Account',
    display: { type: 'text' },
    showSortIcon: true,
    default: true,
    serviceNowField: CreditNoteTableFields.account,
  },
];

// DynamicOptions: {
//   service: "userService",
//   endpoint: "getContactBillingEntities",
//   parameter: "credit",
// },
