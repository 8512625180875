import { Injectable } from '@angular/core';
import { Invoice } from '../models/billing/invoice-models';
import { CreditNote } from '../models/billing/credit-note.models';

@Injectable({
  providedIn: 'root',
})
export class InvoiceMultiSelectStateService {
  invoices: Invoice[] = [];
  creditNotes: CreditNote[] = [];

  constructor() {}
}
