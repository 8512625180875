import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { OutageService } from 'src/app/services/api/outage/outage.service';
import { GetOutageResponse } from 'src/app/models/outage/outage.models';
import { IUserProfile } from 'src/app/components/service-hub/company/users/shared/user-models';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ServiceNotificationsDialogComponent } from 'src/app/components/misc/pop-up/service-notifications-dialog/service-notifications-dialog.component';

@Component({
  selector: 'service-monitor',
  templateUrl: './service-monitor.component.html',
  styleUrls: ['./service-monitor.component.scss'],
  providers: [DialogService],
})
export class ServiceMonitorComponent implements OnInit {
  ref: DynamicDialogRef | undefined;
  loading = false;

  user: IUserProfile;
  canUpdateNotifications = false;

  services: GetOutageResponse;

  constructor(
    private authService: AuthService,
    private outAgeService: OutageService,
    public dialogService: DialogService
  ) {}

  async ngOnInit() {
    this.user = await this.authService.getUser();
    this.canUpdateNotifications = this.authService.hasPermission(
      this.user,
      ['PROFILE_NOTIFICATIONS_READ', 'PROFILE_NOTIFICATIONS_UPDATE'],
      true
    );
    await this.loadServiceStatus();
  }

  async loadServiceStatus() {
    this.loading = true;
    const response = await firstValueFrom(
      this.outAgeService.getOutagesInformation()
    );
    this.services = response;
    this.loading = false;
  }

  openNotificationDialog() {
    this.ref = this.dialogService.open(ServiceNotificationsDialogComponent, {
      width: '50%',
      height: 'auto',
      header: 'Notification Settings',
      closable: false,
    });
  }
}
