<div class="bg-white flex flex-column h-full">
  <h1 class="text-xl px-3">Edit Cost Centre and User</h1>
  <hr class="w-full" />
  <div class="flex flex-column items-center">
    <p-steps
      [model]="items"
      [readonly]="true"
      [activeIndex]="activeStepIndex"
      (activeIndexChange)="onActiveIndexChange($event)"
      class="mx-auto w-full max-w-60rem">
    </p-steps>
    @if (activeIndex?.label == 'Amend') {
      <div>
        <div class="grid mr-0">
          <div class="field col-12 flex gap-5 justify-content-center">
            <div class="flex align-items-center">
              <p-radioButton
                name="editCostCenterGroup"
                value="group"
                [(ngModel)]="selectedEditType"
              (onClick)="onMultiChange()"></p-radioButton>
              <label for="editCostCenterGroup" class="ml-2">Group</label>
            </div>
            <div class="flex align-items-center">
              <p-radioButton
                name="EditCostCenterIndividual"
                value="individual"
              [(ngModel)]="selectedEditType"></p-radioButton>
              <label for="EditCostCenterIndividual" class="ml-2"
                >Individual</label
                >
              </div>
            </div>
          </div>
          <div class="px-5">
            <p-table
              [value]="costCentres$ | async"
              [columns]="tableColumn"
              styleClass="p-datatable-striped"
              [rowHover]="true"
              [rows]="25">
              >
              <ng-template pTemplate="header" let-columns>
                <tr>
                  @for (col of columns; track col) {
                    <th
                      class="text-white bg-gray-800 p-datatable-header-first-row">
                      {{ col.header }}
                    </th>
                  }
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-item let-columns="columns">
                <tr>
                  @for (col of columns; track col) {
                    <td>
                      @if (col.display?.type === 'text') {
                        {{ item[col.field] }}
                      }
                      @if (col.editType === 'costCentre') {
                        <span class="p-input-icon-right mr-4">
                          <i
                            class="pi pi-search cursor-pointer"
                          (click)="editCostCentre(item)"></i>
                          <input
                            pInputText
                            type="text"
                            [value]="item[col.field]"
                            readonly
                            (click)="editCostCentre(item)"
                            class="text-base text-color surface-overlay border-1 border-solid surface-border border-round" />
                        </span>
                      }
                      @if (col.editType === 'user') {
                        <span class="p-input-icon-right mr-4">
                          <i
                            class="pi pi-search cursor-pointer"
                          (click)="editCostCentreUser(item)"></i>
                          <input
                            pInputText
                            type="text"
                            [value]="item[col.field]"
                            (click)="editCostCentreUser(item)"
                            readonly
                            class="text-base text-color surface-overlay border-1 border-solid surface-border border-round" />
                        </span>
                      }
                    </td>
                  }
                </tr>
              </ng-template>
            </p-table>
            <div class="flex align-content-center justify-content-center pt-4">
              <p-button
                styleClass="p-button-secondary"
                label="Back"
              (onClick)="backButton()"></p-button>
              <p-button
                styleClass="p-button"
                label="Next"
              (onClick)="stepForward()"></p-button>
            </div>
          </div>
        </div>
      }
      @if (activeIndex?.label == 'Confirm') {
        <div>
          <div class="px-5">
            <p-table
              [value]="costCentres$ | async"
              [columns]="confirmColumn"
              styleClass="p-datatable-striped"
              [rowHover]="true"
              [rows]="25">
              <ng-template pTemplate="header" let-columns>
                <tr>
                  @for (col of columns; track col) {
                    <th
                      class="text-white bg-gray-800 p-datatable-header-first-row">
                      {{ col.header }}
                    </th>
                  }
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-items let-columns="columns">
                <tr>
                  @for (col of columns; track col) {
                    <td>
                      @if (col.display?.type === 'text') {
                        {{ items[col.field] }}
                      }
                    </td>
                  }
                </tr>
              </ng-template>
            </p-table>
          </div>
          <div class="flex align-content-center justify-content-center pt-4">
            <p-button
              styleClass="p-button-secondary"
              label="Back"
            (onClick)="stepBack()"></p-button>
            <p-button
              styleClass="p-button"
              label="Next"
            (onClick)="stepForward()"></p-button>
          </div>
        </div>
      }
      @if (activeIndex?.label == 'Submit') {
        <div
          class="flex flex-column align-items-center justify-content-center"
          >
          @if (loading) {
            <loading-spinner />
          }
          @if (!loading) {
            <div
              class="flex flex-column align-items-center justify-content-center">
              <div class="text-left">
                <h2 class="font-bold text-xl m-0 text-left">Submitted.</h2>
                <p class="m-0 text-lg">
                  The updates requested to the Cost Centre and User fields are being
                  processed.
                </p>
                <p class="m-0 text-lg">
                  These will be visible in ServiceHub within 2 hours.
                </p>
              </div>
              <div class="pt-4">
                <p-button (onClick)="finishButton()" label="Finished"></p-button>
              </div>
            </div>
          }
        </div>
      }
    </div>
  </div>
