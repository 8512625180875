import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { Subject, firstValueFrom } from 'rxjs';
import { OutageService } from 'src/app/services/api/outage/outage.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MenuItem } from 'primeng/api';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import { GetOutageResponse } from 'src/app/models/outage/outage.models';

@Component({
  selector: 'service-status',
  templateUrl: 'status.component.html',
  styleUrls: ['status.component.scss'],
  providers: [DialogService],
})
export class ServiceStatusComponent implements OnInit, OnDestroy {
  ref: DynamicDialogRef | undefined;
  loading = false;

  services: GetOutageResponse[];
  pageData: Data;
  name: string;
  currentPage = 0;
  tabIndex = 0;

  destroy$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private outAgeService: OutageService,
    public dialogService: DialogService,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    await this.loadServicesAndStatus();
    this.name = this.route.snapshot.params['id'];
    const breadcrumbs: MenuItem[] = [{ label: 'Service Status' }];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.route.data.subscribe(v => (this.pageData = v));
    this.tabIndex = this.findTabIndexFromUrl(this.name, this.services);
  }

  async loadServicesAndStatus() {
    this.loading = true;
    this.services = await firstValueFrom(
      this.outAgeService.getOutagesInformation()
    );
    this.loading = false;
  }

  findTabIndexFromUrl(name: string, services: GetOutageResponse[]): number {
    if (!name || !services) {
      return 0;
    }
    const index = services.findIndex(
      service => service.businessService.name === name
    );
    return index;
  }

  ngOnDestroy() {
    this.destroy$.next(void 0);
    this.destroy$.complete();
  }
}
