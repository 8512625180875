import { Inject, Injectable } from '@angular/core';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';

@Injectable({
  providedIn: 'root',
})
export class BillingEntityService {
  constructor(private apiRequest: ApiRequest) {}

  getBillingEntities(filter: any) {
    const requestProps: IRequestOptions = {
      endpoint: 'BillingEntities/GetBillingEntities',
      body: {
        Filter: filter,
      },
    };
    return this.apiRequest.request(requestProps);
  }
}
