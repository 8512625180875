import { Component, Input, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-e-bar-chart',
  templateUrl: './e-bar-chart.component.html',
  styleUrl: './e-bar-chart.component.scss',
})
export class EBarChartComponent implements OnInit {
  @Input() graphData$: Observable<any>;
  destroy$ = new Subject<any>();
  data: any;
  newChart: EChartsOption;
  noData: boolean = false;

  ngOnInit(): void {
    this.graphData$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.data = data;
      this.buildBarChart();
    });
  }

  async buildBarChart(): Promise<EChartsOption> {
    if (!this.data) {
      this.noData = true;
    }
    //TODO:Refactor backend so this can be changed
    const xAxisData = this.data?.series[0].items.map(item => item.label);
    const seriesData = this.data?.series[0].items.map(item => item.count);
    const color = this.data?.series[0].colour;

    const yAsisTitle = this.data?.title;
    const xAsisTitle = this.data?.options.scales.xAxes[0].labelString;

    return (this.newChart = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      xAxis: {
        type: 'category',
        data: xAxisData,
        name: xAsisTitle,
      },
      yAxis: {
        name: yAsisTitle,
        type: 'value',
      },
      series: [
        {
          data: seriesData,
          type: 'bar',
          color: color,
        },
      ],
    });
  }
}
