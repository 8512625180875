import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceNowValidatorService } from 'src/app/services/existing-service-now-validator.service';
import { RoleManagementService } from '../shared/role-management.service';
import { PermissionsService } from 'src/app/services/api/permissions/permissions.service';
import {
  ISelectedRole,
  IServiceNowRoleCreate,
  IServiceNowRoleUpdate,
} from '../shared/role-models';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MenuItem } from 'primeng/api/menuitem';
import { firstValueFrom } from 'rxjs';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import { ToggleSelectDeselectAll } from 'src/app/helpers/select.helper';
import { ToastService, ToastType } from 'src/app/services/global/toast.service';

enum AddEditRolePageTitle {
  ADD = 'Add Role',
  EDIT = 'Edit Role',
}

@Component({
  selector: 'app-add-role',
  templateUrl: './add-edit-role.component.html',
  styleUrls: ['./add-edit-role.component.scss'],
})
export class AddEditRoleComponent implements OnInit, OnDestroy {
  detailsForm: UntypedFormGroup;
  permissionsForm: UntypedFormGroup;
  addEditUserPermissionsForm: FormGroup;
  //Categorised permissions
  allPermissions;
  submitFinished = false;
  selectedRole: ISelectedRole;
  selectedRoleId: string | null = null;

  items: MenuItem[] | undefined;
  steps: MenuItem[] | undefined = [];
  activeIndex: MenuItem = this.steps[0];
  activeStepIndex = 0;
  selectedPermissions = [];

  checkRoleExistsStatus = 'VALID';
  loading = false;
  roleName;
  currentPermissions;

  pageTitle: AddEditRolePageTitle = AddEditRolePageTitle.ADD;

  submitHeading = 'The role has been created.';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private serviceNowValidatorService: ServiceNowValidatorService,
    private roleManagementService: RoleManagementService,
    private permissionsService: PermissionsService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    this.selectedRoleId = this.route.snapshot.paramMap.get('id');
    if (this.selectedRoleId) {
      this.pageTitle = AddEditRolePageTitle.EDIT;
      this.submitHeading = 'The role has been updated.';
      this.selectedRole = await this.loadRole(this.selectedRoleId);
      if (this.selectedRole.default) {
        this.toastService.add({
          severity: ToastType.error,
          summary: 'Error',
          detail: "Default roles can't be edited.",
        });
        this.router.navigateByUrl('secure/company/roles/');
      }
      this.roleManagementService.selectedRoleEdit = await this.loadRole(
        this.selectedRoleId
      );
      this.roleName = this.roleManagementService.selectedRoleEdit.name || null;
    }

    this.items = [
      {
        label: 'Details',
      },
      {
        label: 'Permissions',
      },
      {
        label: 'Submit',
      },
    ];
    this.activeIndex = this.items[this.activeStepIndex];
    const breadcrumbs: MenuItem[] = [
      {
        label: 'Roles',
        routerLink: '/secure/company/roles',
      },
      {
        label: this.pageTitle,
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    await this.buildDetailsForm();
    if (this.selectedRole) {
      this.buildEditFormDetails();
    }
    await this.loadPermissions();
  }

  roleErrorMessage() {
    if (
      this.detailsForm.get('roleName').errors &&
      this.detailsForm.get('roleName').errors.required
    ) {
      return 'Name of the role is required';
    }
    if (
      this.detailsForm.get('roleName').errors &&
      this.detailsForm.get('roleName').errors.roleExists
    ) {
      return 'A role with this name already exists';
    }
  }

  onActiveIndexChange(event: number): void {
    this.activeStepIndex = event;
    this.activeIndex = this.items[this.activeStepIndex];
  }

  allCategoryPermissionsSelected(categoryPermissions): boolean {
    return categoryPermissions.every(permission =>
      this.selectedPermissions.includes(permission)
    );
  }

  toggleSelectCategoryPermissions(categoryPermissions) {
    this.selectedPermissions = ToggleSelectDeselectAll(
      categoryPermissions,
      this.selectedPermissions
    );
  }

  async loadRole(roleId: string) {
    try {
      return await firstValueFrom(this.permissionsService.getRole(roleId));
    } catch (error) {
      console.error(error);
    }
  }

  async loadPermissions() {
    this.loading = true;
    this.allPermissions =
      await this.roleManagementService.getAllPortalPermissions();
    if (this.roleManagementService.selectedRoleEdit != null) {
      await this.isPermissionSelected();
    }
    this.loading = false;
  }

  async buildDetailsForm() {
    this.detailsForm = this.formBuilder.group({
      roleName: [
        '',
        {
          updateOn: 'blur',
          validators: [Validators.required],
          asyncValidators: [
            this.serviceNowValidatorService.roleExists(this.roleName),
          ],
        },
      ],
      roleDescription: ['', [Validators.required, Validators.maxLength(100)]],
    });

    this.detailsForm.get('roleName').statusChanges.subscribe(status => {
      this.checkRoleExistsStatus = status;
    });
  }

  async isPermissionSelected() {
    this.currentPermissions =
      await this.roleManagementService.getPermissionsForSelectedRole(
        this.selectedRole.sysId
      );
    const allPermissionsArray = this.allPermissions.flatMap(
      company => company.permissions
    );
    const currentPermissionsArray = this.currentPermissions.flatMap(
      category => category.permissions
    );
    this.selectedPermissions = allPermissionsArray.filter(permission =>
      currentPermissionsArray.some(
        currentPermission => currentPermission.sysId === permission.sysId
      )
    );
  }

  buildEditFormDetails() {
    this.detailsForm.setValue({
      roleName: this.selectedRole.name,
      roleDescription: this.selectedRole.description,
    });
    this.detailsForm.markAllAsTouched();
  }

  backButton(): void {
    this.router.navigate(['/secure/company/roles']);
  }

  stepForward(): void {
    if (this.items[this.activeStepIndex + 1]) {
      this.onActiveIndexChange(this.activeStepIndex + 1);
    }
  }

  stepBack(): void {
    if (this.items[this.activeStepIndex - 1]) {
      this.onActiveIndexChange(this.activeStepIndex - 1);
    }
  }

  navigateToRoleTable() {
    this.router.navigateByUrl('secure/company/roles');
  }

  submitButton(): void {
    if (this.items[this.activeStepIndex + 1]) {
      this.onActiveIndexChange(this.activeStepIndex + 1);
      this.submitForm();
    }
  }

  finishButton() {
    this.router.navigateByUrl('/secure/company/roles');
  }

  async submitForm() {
    let permissions = '';
    this.selectedPermissions.forEach(permission => {
      if (permission) {
        permissions = permissions + permission.sysId + ',';
      }
    });

    // TODO reevaluate for Impersonation
    //this.user = await this.authService.getUser();

    if (!this.roleManagementService.selectedRoleEdit) {
      const roleToCreate: IServiceNowRoleCreate = {
        company: (await this.authService.getUser()).companyId,
        name: this.detailsForm.value.roleName,
        description: this.detailsForm.value.roleDescription,
        permissions: permissions,
      };
      this.loading = true;
      await this.roleManagementService.createRole(roleToCreate);
      this.loading = false;
    } else {
      const roleToUpdate: IServiceNowRoleUpdate = {
        sysId: this.selectedRole.sysId,
        name: this.detailsForm.value.roleName,
        description: this.detailsForm.value.roleDescription,
        permissions: permissions,
      };
      this.loading = true;
      await this.roleManagementService.saveRole(roleToUpdate);
      this.loading = false;
    }

    //if (result) {
    //this.submitFinished = true;
    //}
  }

  selectAll(permissionCategory) {
    let active = false;
    const permissions = permissionCategory.controls.permissions.controls;
    permissions.forEach(permission => {
      if (permission.controls.active.value != true) {
        active = true;
      }
    });
    permissions.forEach(permission => {
      permission.controls.active.setValue(active);
    });
  }

  ngOnDestroy() {
    this.roleManagementService.selectedRoleEdit = null;
  }
}
