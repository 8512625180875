import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'edit-user-permissions',
  templateUrl: 'edit-user-permissions.component.html',
  styleUrls: ['edit-user-permissions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditUserPermissionsComponent implements OnInit {
  loading = false;

  role: {
    sysId: string;
    name: string;
  };

  userPermissions: string[];

  permissionsForm = new FormGroup({
    rolePermissions: new FormControl([]),
    additionalPermissions: new FormControl([]),
  });

  constructor(
    public ref: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig
  ) {
    this.role = this.dialogConfig?.data?.role;
    this.userPermissions = this.dialogConfig?.data?.userPermissions;
  }

  async ngOnInit() {
    this.permissionsForm.get('additionalPermissions').setValue(
      this.userPermissions.map(userPermission => {
        return {
          sysId: userPermission,
        };
      })
    );
  }

  submit() {
    const rolePermissions = this.permissionsForm.value.rolePermissions.map(
      rolePermission => rolePermission.sysId
    );
    const additionalPermissions =
      this.permissionsForm.value.additionalPermissions.map(
        additionalPermission => additionalPermission.sysId
      );
    this.ref.close([...rolePermissions, ...additionalPermissions]);
  }

  close(): void {
    this.ref.close();
  }
}
