<div class="flex flex-column h-full">
  <h1 class="text-xl px-3">{{ dialogData?.title }}</h1>
  <hr class="w-full" />
  <div class="pl-3 pr-3">
    <p class="text-xl">
      {{ dialogData?.message }}
      <span class="text-xl text-primary font-bold">{{ dialogData?.key }}</span>
    </p>
  </div>
  <div class="flex justify-content-center align-content-center pb-3">
    <p-button label="Close" (onClick)="cancelButton()" icon="pi pi-times">
    </p-button>
  </div>
</div>
