import { Component, OnInit } from '@angular/core';
import { routing } from 'src/app/routing';
import { RestrictionService } from 'src/app/services/restriction.service';
import { MiscService } from 'src/app/services/api/misc/misc.service';
import { firstValueFrom } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { MenuItem } from 'primeng/api';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';

@Component({
  selector: 'developer-page-restrictions',
  templateUrl: 'page-restrictions.component.html',
  styleUrls: ['./page-restrictions.component.scss'],
})
export class DeveloperPageRestrictionsComponent implements OnInit {
  routes = cloneDeep(routing);
  restrictions: Array<any> = [];

  constructor(
    private restrictionService: RestrictionService,
    private miscService: MiscService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    const breadcrumbs: MenuItem[] = [
      {
        label: 'Page Restrictions',
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.restrictionService.restrictionsUpdate.subscribe(n => {
      this.restrictions = n;
      this.toggleRestrictedIndicator();
    });
    this.restrictionService.getRestrictions();
  }

  toggleRestrictedIndicator() {
    if (this.restrictions.length > 0) {
      this.routes.forEach(route => {
        this.restrictions.forEach(restriction => {
          if (route.path == restriction.path) {
            if (route.data) {
              route.data.developerRestricted = true;
            }
          } else if (route.path.includes(restriction.path)) {
            if (route.data) {
              route.data.developerRestrictedChild = true;
            }
          }
        });

        if (route.children) {
          route.children.forEach(child => {
            this.restrictions.forEach(restriction => {
              if (child.path == restriction.path) {
                if (child.data) {
                  child.data.developerRestricted = true;
                }
              } else if (child.path.includes(restriction.path)) {
                if (child.data) {
                  child.data.developerRestrictedChild = true;
                }
              }
            });
          });
        }
      });
    } else {
      this.routes.forEach(route => {
        if (route.data) {
          route.data.developerRestricted = false;
        }
        if (route.children) {
          route.children.forEach(child => {
            if (child.data) {
              child.data.developerRestricted = false;
            }
          });
        }
      });
    }
  }

  async toggleRoute(i) {
    this.routes[i].data.developerRestricted =
      !this.routes[i].data.developerRestricted;
    await firstValueFrom(
      this.miscService.updateServiceHubPageRestrictions(routing[i].path)
    );
    await this.restrictionService.getRestrictions();
  }

  async toggleChild(i, k) {
    this.routes[i].children[k].data.developerRestricted =
      !this.routes[i].children[k].data.developerRestricted;

    let childPath = this.routes[i].children[k].path;
    let relatedChildren = this.routes[i].children.filter(c =>
      c.path.includes(childPath)
    );

    relatedChildren.forEach(rChild => {
      rChild.data.developerRestrictedChild =
        this.routes[i].children[k].data.developerRestricted;
    });

    await firstValueFrom(
      this.miscService.updateServiceHubPageRestrictions(
        routing[i].children[k].path
      )
    );
    await this.restrictionService.getRestrictions();
  }
}
