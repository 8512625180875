<div class="">
  <form [formGroup]="serviceRequestForm">
    <div class="flex align-items-center justify-content-center">
      <div class="formgrid">
        <div class="field col">
          <span
            ><span class="text-primary text-lg">*</span
            ><label for="request-title" class="text-lg">Subject </label
            ><span
              >Please enter a subject to describe your request, which will be
              included in the subject line of all email communications.</span
            ></span
          >
          <input
            placeholder="Subject"
            id="request-title"
            formControlName="shortDescription"
            type="text"
            pInputText
            aria-label="Title"
            class="w-full" />
          @if (
            serviceRequestForm.get('shortDescription').invalid &&
            serviceRequestForm.get('shortDescription').touched
          ) {
            <small class="p-error text-base">
              Request subject is required
            </small>
          }
        </div>
        <div class="formgrid">
          <div class="field col">
            <div class="pb-1">
              <span
                ><span class="text-primary text-lg">*</span
                ><label for="description" class="text-lg">Description </label
                ><span class="text-base"
                  >Please provide us with as much detail as possible of your
                  request.</span
                ></span
              >
            </div>
            <textarea
              pInputText
              id="description"
              placeholder="Description"
              formControlName="description"
              aria-label="Description"
              type="text"
              rows="10"
              class="w-full"></textarea>
            @if (
              serviceRequestForm.get('description').invalid &&
              serviceRequestForm.get('description').touched
            ) {
              <small class="p-error text-base">
                Request description is required
              </small>
            }
          </div>
        </div>
        <div class="field col">
          <div class="file-upload-text">
            <span class="text-xl">Attachment </span
            ><span class="text-base"
              >Please attach any relevant files to help us understand your
              issue</span
            >
          </div>
          <app-file-upload></app-file-upload>
        </div>
      </div>
    </div>
  </form>
</div>
