@if (loading) {
  <loading-spinner />
}
@if (!loading) {
  <div class="mx-3">
    @if (
      canMakeChanges && stateOfAcceptance?.state == 'Requested' && endDateCheck
    ) {
      <p-messages severity="info">
        <ng-template pTemplate>
          <div class="flex align-items-center justify-content-between">
            <p class="flex justify-content-between align-items-center">
              <i class="pi pi-exclamation-circle pr-2"></i
              ><span class="font-bold pr-1">Approve ticket</span> We need your
              response to proceed with the change request
            </p>
            <p-button
              icon="pi pi-check-circle"
              iconPos="left"
              label="Approve"
              severity="success"
              (onClick)="approve()"></p-button>
            <p-button
              icon="pi pi-times"
              iconPos="left"
              label="Reject"
              severity="danger"
              (onClick)="reject()"></p-button>
          </div>
        </ng-template>
      </p-messages>
    }
  </div>
}
@if (!loading && !messages?.length) {
  <div class="flex flex-column">
    <div
      class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200 border-bottom-1">
      <h1 class="px-3 text-xl flex justify-content-between align-items-center">
        Change Request - {{ changeRequest?.number }}
        <span class="pl-1">
          <p-chip
            [label]="changeRequest?.state"
            [styleClass]="'p-chip-' + getColor(changeRequest?.state)">
          </p-chip>
        </span>
      </h1>
      <div class="pr-2">
        <span
          pTooltip="Adding attachments is not allowed while impersonating"
          tooltipPosition="bottom"
          [tooltipDisabled]="!isImpersonating">
          @if (canMakeChanges) {
            <p-button
              icon="pi pi-paperclip"
              label="Attachment"
              [disabled]="changeRequest?.state === 'Closed'"
              (onClick)="openFileManager()"
              class="mr-1"></p-button>
          }
        </span>
      </div>
    </div>
    <div
      class="mx-3 overflow-hidden bg-white border-solid border-1 border-gray-200 border-top-none">
      <div class="flex bg-white flex-wrap align-content-start">
        <div class="col-12 md:col-6 flex flex-wrap align-content-start">
          <div
            class="col-12 border-none border-bottom-1 border-solid border-gray-200">
            <h2 class="text-gray-600 text-base m-0 mb-1">Subject</h2>
            <p class="text-lg m-0">
              {{ changeRequest?.shortDescription }}
            </p>
          </div>
          <div class="col-12 md:col-6 flex flex-wrap pl-0">
            <app-ticket-item-display
              [ngClass]="
                getTicketItemDisplayContainerClasses({
                  border: false,
                })
              "
              [label]="'Request For'"
              [value]="
                changeRequest?.requestedFor || '&zwnj;'
              "></app-ticket-item-display>
            <app-ticket-item-display
              [ngClass]="
                getTicketItemDisplayContainerClasses({
                  border: true,
                  borderBottom: true,
                })
              "
              [label]="'Planned Start Date'"
              [value]="changeRequest?.startDate"></app-ticket-item-display>
          </div>
          <div class="col-12 md:col-6 flex flex-wrap">
            <app-ticket-item-display
              [ngClass]="
                getTicketItemDisplayContainerClasses({
                  border: false,
                })
              "
              [label]="'Opened At'"
              [value]="changeRequest?.openedAt"></app-ticket-item-display>
            <app-ticket-item-display
              [ngClass]="
                getTicketItemDisplayContainerClasses({
                  border: true,
                  borderBottom: true,
                })
              "
              [label]="'Planned End Date'"
              [value]="changeRequest?.endDate"></app-ticket-item-display>
          </div>
          <div
            class="col-12 border-none border-bottom-1 border-solid border-gray-200">
            <h2 class="text-gray-600 text-base m-0">Type of Change</h2>
            <p class="text-lg m-0 pt-1 pb-1">
              {{ changeRequest?.type }}
            </p>
          </div>
        </div>
        <div class="col-12 md:col-6 flex flex-wrap align-content-start">
          <app-ticket-item-display
            [ngClass]="
              getTicketItemDisplayContainerClasses({
                border: false,
              })
            "
            [label]="'Description'"
            [value]="changeRequest?.description"></app-ticket-item-display>
          <app-ticket-item-display
            [ngClass]="
              getTicketItemDisplayContainerClasses({
                border: true,
              })
            "
            [label]="'Additional Information'"
            [value]="
              changeRequest?.additionalInformation || '&zwnj;'
            "></app-ticket-item-display>
          <app-ticket-item-display
            [ngClass]="
              getTicketItemDisplayContainerClasses({
                border: true,
              })
            "
            [label]="'Attachments'"></app-ticket-item-display>
          @for (file of attachmentFiles | slice: 0 : 3; track file) {
            <ng-container class="pt-1">
              <div class="inline-flex">
                <a
                  class="hover:text-primary m-1 pl-1 text-lg text-gray-900 font-bold cursor-pointer"
                  (click)="fileManagerService.downloadFile($event, file)">
                  <i
                    class="text-lg mr-1 pi {{
                      fileManagerService.setFileIconPrimeIcon(file)
                    }}">
                    <span class="ml-1">{{ file.fileName }}</span>
                  </i>
                </a>
              </div>
            </ng-container>
          }
          @if (attachmentFiles?.length > 3) {
            <p-badge
              class="cursor-pointer"
              severity="success"
              (click)="openAttachments()"
              [value]="attachmentFiles?.length - 3"></p-badge>
          }
        </div>
      </div>
    </div>
  </div>
}

@if (!loading && !messages?.length) {
  <div class="pb-5 pt-2">
    @if (changeRequest) {
      <change-request-details [changeRequest]="changeRequest" />
    }
  </div>
}

@if (messages?.length) {
  <div class="text-center">
    <p-messages [(value)]="messages" />
    <p-button
      label="Return to Changes"
      routerLink="/secure/tickets/changes"></p-button>
  </div>
}
