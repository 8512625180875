import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'usage-information',
  templateUrl: 'usage-information.component.html',
})
export class UsageInformationComponent {
  @Input('usageInformation') currentUsage: any;
  @Input('graphInformation') graphs: any;

  constructor() {}
}
