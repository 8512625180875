import { CreditNote } from '../billing/credit-note.models';
import { Invoice } from '../billing/invoice-models';
import { IDownloadFile } from '../export-models';

export type BillingItem = Invoice | CreditNote;

export type DownloadBillingItemResponse = {
  billingItem: Invoice;
  file: IDownloadFile;
};

export type ExportPdfRequest = {
  billingItems: BillingItem[];
  itemType: BillingItemTypes;
};

export type DownloadStatus = {
  [key: string]: ExportStatusStrings;
};

export enum ExportStatusStrings {
  Loading = 'loading',
  Success = 'fulfilled',
  Failed = 'rejected',
}

export enum BillingItemTypes {
  Invoice = 'invoice',
  CreditNote = 'credit note',
}

export interface TicketData {
  date: string;
  opened?: number;
  closed?: number;
  critical?: number;
  high?: number;
  low?: number;
  moderate?: number;
}

export interface ExportData {
  label: string;
  data: TicketData[];
}

export enum ExportName {
  incident = 'Incidents report',
  request = 'Service Requests',
  order = 'Orders report',
  change = 'Change Requests report',
  broadband = 'Broadband report',
}

export enum ExportView {
  all = 'All',
  inView = 'In View',
}
