import { Injectable } from '@angular/core';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';

@Injectable({
  providedIn: 'root',
})
export class MiscService {
  constructor(private apiRequest: ApiRequest) {}

  updateServiceHubPageRestrictions(pathURL: string) {
    const requestProps: IRequestOptions = {
      endpoint: 'Misc/UpdateServiceHubPageRestrictions',
      body: pathURL,
    };

    return this.apiRequest.request(requestProps);
  }
}
