import { Injectable } from '@angular/core';
import { ticketData } from 'src/app/components/misc/shared/comments.model';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';
import { firstValueFrom } from 'rxjs';
import { IncidentService } from '../incident/incident.service';
import { TicketComment } from 'src/app/models/comments/comment.models';

/**
 *
 * @deprecated This Class is deprecated. Please use specific services to access their comments (e.g. IncidentService/GetComments)
 */
@Injectable({
  providedIn: 'root',
})
export class CommentAPIService {
  constructor(
    private apiRequest: ApiRequest,
    private incidentService: IncidentService
  ) {}

  /**
   *
   * @deprecated This method is deprecated. Please use specific services to access their comments (e.g. IncidentService/GetComments)
   */
  getCommentsList(incidentSysId: string, itemType: string) {
    const requestProps: IRequestOptions = {
      endpoint: 'Comments/GetCommentsList',
      body: {
        id: incidentSysId,
        type: itemType,
      },
    };
    return this.apiRequest.request(requestProps);
  }

  /**
   *
   * @deprecated This method is deprecated. Please use specific services to access their comments (e.g. IncidentService/GetComments)
   */
  createComment(comment: string, requestId: string, tableName: string) {
    const requestProps: IRequestOptions = {
      endpoint: 'Comments/CreateComment',
      body: {
        comments: comment,
        elementId: requestId,
        table: tableName,
      },
    };
    return this.apiRequest.request(requestProps);
  }

  /**
   *
   * @deprecated This method is deprecated. Please use specific services to access their comments (e.g. IncidentService/GetComments)
   */
  getComments(requestId: string, tableName: string) {
    const requestProps: IRequestOptions = {
      endpoint: 'Comments/GetComments',
      body: {
        id: requestId,
        type: tableName,
      },
    };
    return this.apiRequest.request(requestProps);
  }

  /**
   *
   * @deprecated This method is deprecated. Please use specific services to access their comments (e.g. IncidentService/GetComments)
   */
  async addComment(ticket: ticketData, comment: string) {
    let addComment: TicketComment;
    addComment = await firstValueFrom(
      this.createComment(comment, ticket.sysId, ticket.type)
    );

    if (ticket.state == 'Awaiting Info') {
      const updateTicket = await firstValueFrom(
        this.incidentService.updateOne({
          SysId: ticket.sysId,
          State: '2',
        })
      );
    }
    return addComment;
  }
}
