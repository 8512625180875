<!--Was used on edit / Add user has been unused after refactor-->
<!-- <div class="authorisation-dialog-container">
  <h5>Description of the options</h5>
<div class="row">
  <div class="col-md-6">
    <ul>
      <li class="headings">Billing Queries and Changes</li>
      <p class="headings-body">Assigned users can request information on billing activities and can make
                               any financially impacting changes to the standard invoice.</p>
      <li class="headings">Placing Orders</li>
      <p class="headings-body">Assigned users can place orders of any value on your company’s account.</p>
      <li class="headings">Technical Change Approval</li>
      <p class="headings-body">Assigned users can approve/request such changes on your company’s behalf.</p>
    </ul>
  </div>
  <div class="col-md-6">
    <ul>
      <li class="headings">DC Access Requests</li>
      <p class="headings-body">Should you have services within any of our Data Centres,
                              you must request access to enter. Assigned users can approve access for any
                              colleagues within your business (please see the Digital Space access policy
                               on our website:
                                <a href="https://www.digitalspace.co.uk/information/data-centres-access-policy/" target="_blank">
                                https://www.digitalspace.co.uk/information/data-centres-access-policy/</a>).</p>
      <li class="headings">Authorising Cancellations:</li>
      <p class="headings-body">Assigned users can request any cancellations of service within
                             your Digital Space account (subject to contractual terms and conditions).</p>
    </ul>
  </div>
  <div class="close-container">
  <button class="btn-close" (click)="closeDialog()"><span >clear</span>Close</button>
  </div>
</div>
</div> -->
