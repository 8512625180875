/* src/app/components/global/errors/generic-error/generic-error.component.css */
@font-face {
  font-family: Poppins;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecg.woff2);
}
body,
.container {
  background: #f6f5f8;
}
svg {
  width: 120%;
  padding: 0 4.5rem;
}
#svg-internal-server-error:not(.animated) .animable {
  opacity: 0;
}
#svg-internal-server-error.animated #freepik--Lines--inject-15 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) zoomOut, 1.5s Infinite linear heartbeat;
  animation-delay: 0s, 1s;
}
#svg-internal-server-error.animated #freepik--Server--inject-15 path {
  animation: 2.5s Infinite linear flash;
  animation-delay: 0s, 1s;
}
@keyframes zoomOut {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.1);
  }
  30% {
    transform: scale(1);
  }
  40% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  60% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes flash {
  0% {
    fill: #fb8fbf;
  }
  30% {
    fill: #333;
  }
  50% {
    fill: #fb8fbf;
  }
  70% {
    fill: #333;
  }
  100% {
    fill: #fb8fbf;
  }
}
@media screen and (max-width: 1080px) {
  body {
    min-width: 100% !important;
  }
  .message {
    width: 100%;
  }
  .img {
    margin: auto;
  }
  svg {
    max-width: 50%;
    margin: auto;
    text-align: center;
  }
  .container {
    display: block;
    max-height: 100vh;
  }
}
/*# sourceMappingURL=generic-error.component-LY67VFAV.css.map */
