<div class="flex flex-column h-full">
  <h1 class="text-xl px-3">Are you sure?</h1>
  <hr class="w-full" />
  <div class="pl-3 text-xl">
    <p class="mb-2">
      You are about to remove the DNS entry for
      @if (!request.subDomain) {
        <span class="text-primary font-bold">{{
          domain.name
          }}</span
          >
          }<span class="text-primary font-bold">
          {{
          request.subDomain && request.subDomain.length > 10
          ? request.subDomain.substring(0, 10) + '...'
          : request.subDomain || ''
          }}</span
          >
          from the
          <span class="text-primary font-bold">{{ request.domain }}</span>
          DNS.
        </p>
        <p class="mb-2">
          This will remove the following records:
          <br />
          @for (value of request.value; track value) {
            <span
              class="text-primary font-bold word-break-break-word">
              {{ value }}<br />
            </span>
          }
        </p>
        <p class="mb-2">Do you wish to continue?</p>
      </div>
    </div>
    <div class="flex align-items-center justify-content-center gap-2 pt-1 pb-3">
      <p-button
        (onClick)="close()"
        styleClass="p-button-secondary"
      label="No"></p-button>
      <p-button
        styleClass="p-button"
        label="Yes"
      (onClick)="deleteRecord()"></p-button>
    </div>
