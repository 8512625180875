<div
  class="page-content-container"
  style="padding-bottom: 13px; margin: 10px 10px 10px 10px">
  @if (hasTitle) {
    <div class="row">
      <div class="col-xs-12">
        <h1>
          {{ title }}
        </h1>
      </div>
    </div>
    <div class="card-border-break"></div>
    <br />
  }

  <div class="chartjs" [ngClass]="{ 'apply-padding': applyPadding }" #chartBody>
    <div style="min-height: 355px; min-width: 300px; max-height: 300px">
      <canvas #chartCanvas (click)="graphClick($event)"></canvas>
      @if (noData) {
        <h1 class="text-muted" style="text-align: center">
          <i
            class="fa fa-exclamation-circle fa-2x graph-circle"
          aria-hidden="true"></i>
          No data available.
        </h1>
      }
    </div>
  </div>
</div>
