import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { ServiceNowValidatorService } from 'src/app/services/existing-service-now-validator.service';
import { LoadingSpinnerService } from 'src/app/components/misc/loading-spinner/loading-spinner.service';
import { PermissionsService } from 'src/app/services/api/permissions/permissions.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactsService } from 'src/app/services/api/contacts/contacts.service';
import { EditContact } from 'src/app/models/contacts/contacts-model';
import { ToastService, ToastType } from 'src/app/services/global/toast.service';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import { addEditNumberFormatter } from 'src/app/helpers/mobile.helper';
import { mobileNumberValidator } from 'src/app/helpers/mobile-validator.directive';
import { AuthService } from 'src/app/services/auth/auth.service';
import { IUserProfile } from '../shared/user-models';

const addDetailStep = {
  label: 'Details',
  visible: true,
};
const addRoleStep = {
  label: 'Role',
  visible: true,
};
const addPermissionsStep = {
  label: 'Permissions',
  visible: true,
};
const addReviewStep = {
  label: 'Review',
  visible: true,
};
const addAdditional = {
  label: 'Additional',
  visible: true,
};

export enum AddEditUserPageTitle {
  ADD = 'Add User',
  EDIT = 'Edit User',
}

@Component({
  selector: 'app-add-edit-user',
  templateUrl: './add-edit-user.component.html',
  styleUrls: ['./add-edit-user.component.scss'],
})
export class AddEditUserComponent implements OnInit {
  addEditUserForm: FormGroup;
  steps: MenuItem[] | undefined = [addDetailStep, addRoleStep, addReviewStep];

  activeStepIndex = 0;
  activeStep: MenuItem = this.steps[0];

  loading = true;

  roles = [];

  editUserId: string | null = null;

  checkEmailExistsStatus = 'VALID';

  pageTitle: AddEditUserPageTitle = AddEditUserPageTitle.ADD;

  childIsLoading = false;

  user: IUserProfile;

  constructor(
    private existingServiceNowValidator: ServiceNowValidatorService,
    private contactsService: ContactsService,
    private loadingSpinnerService: LoadingSpinnerService,
    private permissionsService: PermissionsService,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    this.user = await this.authService.getUser();
    this.loading = true;
    this.loadingSpinnerService.setLoadingMessage(`Loading...`);
    this.editUserId = this.route.snapshot.paramMap.get('id');
    const user = {
      firstName: null,
      lastName: null,
      email: null,
      mobilePhone: null,
      role: null,
      active: true,
      additionalPermissions: [],
      authorisationTypes: [],
      billingEntities: [],
      contactTypes: [],
    };
    if (this.editUserId) {
      await this.loadUser(this.editUserId, user);
    }
    const breadcrumbs: MenuItem[] = [
      { label: 'Users', routerLink: '/secure/company/users/' },
      { label: this.pageTitle },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.addEditUserForm = new FormGroup({
      details: new FormGroup({
        firstName: new FormControl(user.firstName, Validators.required),
        lastName: new FormControl(user.lastName, Validators.required),
        active: new FormControl(user.active, Validators.required),
        email: new FormControl(user.email, {
          validators: [Validators.required, Validators.email],
          // updateOn: "change",
          updateOn: 'blur',
          asyncValidators: [
            this.existingServiceNowValidator.emailExists(user.email),
          ],
        }),
        mobilePhone: new FormControl(
          user.mobilePhone,
          [mobileNumberValidator()]
          //Validators.pattern("^07[0-9_ ]{9,11}$")
        ),
      }),
      selectRole: new FormGroup({
        role: new FormControl(user.role, {
          validators: [Validators.required],
        }),
      }),
      permissions: new FormGroup({
        rolePermissions: new FormControl([]),
        additionalPermissions: new FormControl(user.additionalPermissions),
      }),
      additional: new FormGroup({
        authorisationTypes: new FormControl(user.authorisationTypes),
        billingEntities: new FormControl(user.billingEntities),
        contactTypes: new FormControl(user.contactTypes),
      }),
    });
    this.addEditUserForm
      .get('selectRole')
      .get('role')
      .valueChanges.subscribe(role => {
        this.steps = this.showSteps(role?.sysId);
      });
    this.addEditUserForm
      .get('details')
      .get('email')
      .statusChanges.subscribe(status => {
        this.checkEmailExistsStatus = status;
      });
    await this.loadRoles();
    this.loading = false;
  }

  async loadRoles() {
    try {
      const roles = await firstValueFrom(this.permissionsService.getRoles());
      this.roles = [
        ...[
          {
            name: 'None',
            sysId: null,
            default: false,
            description: 'No Service Hub Selected',
          },
        ],
        ...roles,
      ];
    } catch (error) {
      console.error(error);
    }
  }

  async loadUser(userId: string, user) {
    this.pageTitle = AddEditUserPageTitle.EDIT;
    try {
      const editUser = await firstValueFrom(
        this.contactsService.getContact(userId)
      );
      if (editUser.firstName) {
        user.firstName = editUser.firstName;
      }
      if (editUser.lastName) {
        user.lastName = editUser.lastName;
      }
      if (editUser.email) {
        user.email = editUser.email;
      }
      if (editUser.mobilePhone) {
        user.mobilePhone = addEditNumberFormatter(editUser.mobilePhone);
      }
      user.active = editUser.active;
      user.role = { sysId: editUser.role ?? null };
      if (user.role) {
        this.steps = this.showSteps(user.role);
      }
      if (editUser.permissions) {
        user.additionalPermissions = editUser.permissions
          .filter(permission => permission)
          .map(permission => {
            return { sysId: permission };
          });
      }
      if (editUser.authorisationTypes) {
        user.authorisationTypes = editUser.authorisationTypes
          .filter(authorisationType => authorisationType)
          .map(authorisationType => {
            return { sysId: authorisationType };
          });
      }
      if (editUser.billingEntities) {
        user.billingEntities = editUser.billingEntities
          .filter(billingEntity => billingEntity)
          .map(billingEntity => {
            return { sysId: billingEntity };
          });
      }
      if (editUser.contactTypes) {
        user.contactTypes = editUser.contactTypes
          .filter(contactType => contactType)
          .map(contactType => {
            return { sysId: contactType };
          });
        return user;
      }
    } catch (error) {
      console.error(error);
    }
  }

  showSteps(roleSysId: string | null): MenuItem[] {
    if (roleSysId) {
      return [
        addDetailStep,
        addRoleStep,
        addPermissionsStep,
        addAdditional,
        addReviewStep,
      ];
    }
    return [addDetailStep, addRoleStep, addReviewStep];
  }

  onActiveIndexChange(event: number) {
    this.activeStepIndex = event;
    this.activeStep = this.steps[this.activeStepIndex];
  }

  stepForward() {
    if (this.steps[this.activeStepIndex + 1]) {
      this.onActiveIndexChange(this.activeStepIndex + 1);
    }
  }

  stepBack() {
    if (this.steps[this.activeStepIndex - 1]) {
      this.onActiveIndexChange(this.activeStepIndex - 1);
    }
  }

  backButton() {
    this.router.navigate(['secure/company/users']);
  }

  checkStepComplete(activeStep) {
    if (activeStep.label === 'Details') {
      return this.addEditUserForm.get('details').invalid;
    }
    if (activeStep.label === 'Role') {
      return this.addEditUserForm.get('selectRole').invalid;
    }
  }

  setChildIsLoading(event: boolean) {
    this.childIsLoading = event;
  }

  async saveUser() {
    if (this.addEditUserForm.invalid) {
      return;
    }
    this.loading = true;
    this.loadingSpinnerService.setLoadingMessage(`Saving User...`);

    const mobilePhone = this.addEditUserForm
      .get('details')
      .get('mobilePhone').value;
    const saveUser: EditContact = {
      firstName: this.addEditUserForm.get('details').get('firstName').value,
      lastName: this.addEditUserForm.get('details').get('lastName').value,
      email: this.addEditUserForm.get('details').get('email').value,
      //mobilePhone: this.addEditUserForm.get("details").get("mobile").value,
      mobilePhone: mobilePhone ? addEditNumberFormatter(mobilePhone) : '',
      active: this.addEditUserForm.get('details').get('active').value,
    };
    if (this.editUserId) {
      saveUser.sysId = this.editUserId;
    }
    if (this.addEditUserForm.get('selectRole').get('role').value) {
      saveUser.role = this.addEditUserForm
        .get('selectRole')
        .get('role').value.sysId;
      saveUser.permissions = this.addEditUserForm
        .get('permissions')
        .get('additionalPermissions')
        .value.map(permission => permission.sysId);
      saveUser.billingEntities = this.addEditUserForm
        .get('additional')
        .get('billingEntities')
        .value.map(billingEntity => billingEntity.sysId);
      if (
        this.authService.hasPermission(this.user, [
          'AUTHORISATION_TYPES_READ',
        ]) &&
        this.authService.hasPermission(this.user, [
          'AUTHORISATION_TYPES_CREATE',
          'AUTHORISATION_TYPES_DELETE',
        ])
      ) {
        saveUser.authorisationTypes = this.addEditUserForm
          .get('additional')
          .get('authorisationTypes')
          .value.map(authorisationType => authorisationType.sysId);
        saveUser.contactTypes = this.addEditUserForm
          .get('additional')
          .get('contactTypes')
          .value.map(contactType => contactType.sysId);
      }
    }
    try {
      if (saveUser.sysId) {
        await firstValueFrom(this.contactsService.editContact(saveUser));
        this.toastService.add({
          severity: ToastType.success,
          summary: 'User Saved',
          detail: 'User has been saved',
        });
      } else {
        await firstValueFrom(this.contactsService.createContact(saveUser));
        this.toastService.add({
          severity: ToastType.success,
          summary: 'User Added',
          detail: 'User has been created',
        });
      }
      this.router.navigate(['secure/company/users']);
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
  }
}
