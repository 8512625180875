import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { UserService } from 'src/app/services/api/user/user.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'admins',
  templateUrl: 'admins.component.html',
})
export class AdminsComponent implements OnInit {
  admins: Array<any>;
  tableColumns = [
    {
      header: 'First Name',
      columnDef: 'firstName',
      cell: data => data.firstName,
    },
    {
      header: 'Last Name',
      columnDef: 'lastName',
      cell: data => data.lastName,
    },
    { header: 'Email Address', columnDef: 'email', cell: data => data.email },
  ];

  constructor(
    private userService: UserService,
    private authService: AuthService
  ) {}
  ngOnInit() {
    this.loadAdmins();
  }

  async loadAdmins() {
    this.admins = await firstValueFrom(this.userService.getCompanyAdmins());
  }

  async getUserRole() {
    // NOTE have left this as user or impersonated user as I don't think this component is actually used
    let userProfile = await this.authService.getUser();
    return userProfile.uTimicoPortalRole;
  }

  handleLoadMore(event) {
    this.loadAdmins();
  }

  handleRowClicked(event) {}
}
