import { Component, Input, ViewChild, OnChanges } from '@angular/core';
import Chart from 'chart.js';
import { Router } from '@angular/router';

@Component({
  selector: 'servicehub-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss'],
})
export class ServiceHubGraphComponent implements OnChanges {
  charts: [] = [];
  chart: Chart;
  noData = true;
  title = '';
  chartType: string;

  @ViewChild('chartCanvas', { static: true }) chartCanvas;
  @ViewChild('chartBody', { static: true }) chartBody;
  @Input() data;
  @Input() blocker;
  @Input() name;
  @Input() applyPadding = true;
  @Input() legend = true;
  @Input() onlyGraph = false;
  @Input() hasTitle = true;

  constructor(private router: Router) {}

  graphClick(event) {
    if (this.data.type == 'pie' || this.data.type == 'bar') {
      if (this.chart == undefined) return;
      const slice = this.chart.getElementAtEvent(event)[0];
      if (slice == undefined) return;
      this.router.navigateByUrl(
        slice._chart.config.data.datasets[0].url[slice._index]
      );
    }
  }

  pieConfig() {
    // Get the numbers, labels and colours
    const numbers = this.data.series[0].items.map(item => {
      return item.count;
    });
    const labels = this.data.series[0].items.map(item => {
      return item.label;
    });
    const colours = this.data.series[0].items.map(item => {
      return item.colour;
    });
    const url = this.data.series[0].items.map(item => {
      return item.url;
    });
    return {
      type: 'pie',
      title: this.data.title,
      data: {
        datasets: [
          {
            data: numbers,
            backgroundColor: colours,
            url: url,
            labels: labels,
          },
        ],
        labels: labels,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          align: 'start',
          textAlign: 'left',
          display: true,
          position: 'top',
          onHover: event => {
            event.target.style.cursor = 'pointer';
          },
        },
        events: ['mousemove', 'click'],
        onHover: (event, chartElement) => {
          event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
        tooltips: {
          callbacks: {
            //Show the square and show the label
            label: function (tooltipItem, data) {
              return data.labels[tooltipItem.index];
            },
          },
        },
      },
    };
  }

  barConfig() {
    // Get the numbers, labels and colours
    const numbers = this.data.series[0].items.map(item => {
      return item.count;
    });
    const labels = this.data.series[0].items.map(item => {
      return item.label;
    });
    const colours = this.data.series[0].items.map(item => {
      return item.colour;
    });
    const url = this.data.series[0].items.map(item => {
      return item.url;
    });
    return {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            data: numbers,
            backgroundColor: colours,
            url: url,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: this.legend,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                userCallback: function (label) {
                  // when the floored value is the same as the value we have a whole number
                  if (Math.floor(label) === label) {
                    return label;
                  }
                },
              },
            },
          ],
        },
      },
    };
  }

  lineConfig() {
    let step = {};
    if (this.data.options.scales.yAxes[0].stepSize > 0) {
      step = this.data.options.scales.yAxes[0].stepSize;
    }

    let lineTicksY = {};
    let lineTicksX = {};
    let elementStyle = {};
    if (this.data.options.scales.yAxes[0].useMinAndMaxLineGraphBoundaries) {
      lineTicksY = {
        suggestedMin: this.data.options.scales.yAxes[0].min,
        suggestedMax: this.data.options.scales.yAxes[0].max,
        stepSize: step,
      };
    } else if (this.onlyGraph) {
      lineTicksY = {
        maxTicksLimit:
          this.data.options.scales.yAxes[0].maxTicksLimit != 0
            ? this.data.options.scales.yAxes[0].maxTicksLimit
            : 11,
        stepSize:
          this.data.options.scales.yAxes[0].stepSize != 0
            ? this.data.options.scales.yAxes[0].stepSize
            : 5,
        min:
          this.data.options.scales.yAxes[0].min != 0
            ? this.data.options.scales.yAxes[0].min
            : 0,
        max:
          this.data.options.scales.yAxes[0].max != 0
            ? this.data.options.scales.yAxes[0].max
            : 100,
      };

      lineTicksX = {
        padding: 10,
        maxTicksLimit:
          this.data.options.scales.xAxes[0].maxTicksLimit != 0
            ? this.data.options.scales.xAxes[0].maxTicksLimit
            : 11,
      };

      elementStyle = {
        point: {
          radius: 0,
        },
      };
    }

    return {
      type: 'line',
      title: this.data.title,
      data: {
        labels: this.data.series[0].items.map(item => {
          return item.label;
        }),
        datasets: this.data.series.map(series => {
          return {
            label: series.name,
            data: series.items.map(item => {
              return item.count;
            }),
            backgroundColor: series.colour,
            borderColor: series.colour,
            borderWidth: 1,
            extraDetails: series.items.map(item => {
              return item.extraDetails;
            }),
            fill: false,
          };
        }),
      },
      options: {
        elements: elementStyle,
        maintainAspectRatio: false,
        legend: {
          display: this.legend,
        },
        scales: {
          xAxes: [
            {
              ticks: lineTicksX,
              scaleLabel: {
                display: true,
                labelString: this.data.options.scales.xAxes[0].labelString,
              },
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: this.data.options.scales.yAxes[0].labelString,
              },
              ticks: lineTicksY,
            },
          ],
        },
        tooltips: {
          callbacks: {
            afterLabel: function (tooltipItem, data) {
              return data.datasets[tooltipItem.datasetIndex].extraDetails[
                tooltipItem.index
              ];
            },
          },
        },
      },
    };
  }

  // When a parent value is received, draws the chart.
  ngOnChanges() {
    if (this.data != null) {
      this.title = this.data.title;
      if (this.data.series != null) {
        if (
          this.data.series.length == 0 ||
          this.data.series[0].items.length == 0
        ) {
          this.noData = true;
          return;
        }
      }

      if (this.chart) {
        this.chart.destroy();
      }

      // Generate the chart config
      let config = {};
      switch (this.data.type) {
        case 'pie':
          config = this.pieConfig();
          this.chart = new Chart(this.chartCanvas.nativeElement, config);
          break;
        case 'bar':
          config = this.barConfig();
          this.chart = new Chart(this.chartCanvas.nativeElement, config);
          break;
        case 'line':
          config = this.lineConfig();
          this.chart = new Chart(this.chartCanvas.nativeElement, config);
          break;
        default:
          this.chart = new Chart(this.chartCanvas.nativeElement, config);
      }
      this.chartType = this.chart.type;
      // Create the chart
      this.noData = false;
    } else {
      this.noData = true;
    }
  }
}
