import { Component, HostBinding, Input } from '@angular/core';
import { DashBoardStatistic } from 'src/app/components/service-hub/dashboard/dashboard.model';

@Component({
  selector: 'app-dashboard-month-compare',
  templateUrl: './dashboard-month-compare.component.html',
  styleUrl: './dashboard-month-compare.component.scss',
})
export class DashboardMonthCompareComponent {
  @HostBinding('class') hostClass = 'col-12 md:col-4';
  @Input() title: string;
  @Input() routerLink: string;
  @Input() statistics: {
    thisMonth: DashBoardStatistic;
    lastMonth: DashBoardStatistic;
  };
  @Input() marginBottom = true;
  @Input() permission: boolean;
}
