<div class="page-content">
  <div class="container">
    <div class="page-content-container" style="padding-bottom: 5px">
      <div class="row">
        <div class="col-xs-6">
          <h4 style="font-size: 26px; padding-bottom: 5px">
            {{ notification?.description }}
          </h4>
        </div>
        <div class="col-xs-6">
          <button
            class="login-button"
            mat-raised-button
            color="primary"
            style="margin-top: 20px; float: right; margin-right: 25px"
            routerLink="/secure/dashboard">
            Home
          </button>
        </div>
      </div>
      <div class="card-border-break"></div>
      @if (!(radiusAccounts.length > 0) && !requestRaised) {
        <div
          [innerHtml]="notification?.content | keepHtml"
        style="margin: 25px 25px"></div>
      }
      @for (radiusAccount of radiusAccounts; track radiusAccount) {
        <div>
          <div class="row">
            <div class="col-xs-6">
              <h4 style="font-size: 26px; padding-bottom: 5px">
                {{ radiusAccount?.name }}
              </h4>
            </div>
            <div class="col-xs-6">
              @if (radiusAccount?.uFttpAvailable) {
                <div>
                  <button
                    class="login-button"
                    (click)="raiseRequest(radiusAccount, 'FTTP')"
                    mat-raised-button
                    color="primary"
                    style="margin-top: 20px; float: right; margin-right: 25px">
                    Request FTTP Upgrade
                  </button>
                </div>
              }
              @if (radiusAccount?.uFttcAvailable) {
                <div>
                  <button
                    class="login-button"
                    (click)="raiseRequest(radiusAccount, 'FTTC')"
                    mat-raised-button
                    color="primary"
                    style="margin-top: 20px; float: right; margin-right: 25px">
                    Request FTTC Upgrade
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
      }
      @if (requestRaised) {
        <div class="card-border-break"></div>
      }
      @if (requestRaised) {
        <div class="row">
          @if (reference) {
            <div class="col-xs-12">
              <p style="text-align: center; padding-top: 25px">
                Your request has been raised with reference number
              </p>
              <h2 style="text-align: center">{{ reference }}</h2>
            </div>
          }
          @if (!reference) {
            <div class="col-xs-12">
              <p
                style="text-align: center; padding-top: 25px; padding-bottom: 25px">
                Your request has been raised and you will be notified of your
                reference number shortly.
              </p>
            </div>
          }
        </div>
      }
    </div>
  </div>
</div>
