@if (categoryTableLoading) {
  <loading-spinner />
}

@if (!categoryTableLoading) {
  <div class="grid my-0 mx-3 h-full">
    <div class="col-12 lg:col-4 flex flex-column h-full m-0">
      <div
        class="flex justify-content-between align-items-center bg-white py-3 border-solid border-1 border-gray-200">
        <h1 class="px-3 text-xl">Category</h1>
      </div>
      <div
        class="pb-3 pt-3 bg-white border-solid border-1 border-gray-200 border-top-none">
        <p-tabMenu #tab [model]="menuItems" [activeItem]="activeMenuItem">
        </p-tabMenu>

        <div class="mx-3 bg-white">
          <p-table
            #categoryTable
            [value]="category$ | async"
            [columns]="categoryTableColumn"
            styleClass="mt-3 p-datatable-striped"
            [rowHover]="true"
            sortMode="multiple"
            [rows]="25"
            selectionMode="single"
            [(selection)]="selectedCategory"
            dataKey="sysId"
            z
            [selectionPageOnly]="true">
            <ng-template pTemplate="header" let-columns>
              <tr>
                @for (col of columns; track col) {
                  <th
                    class="text-white bg-gray-800 p-datatable-header-first-row"
                    [pSortableColumn]="col.field">
                    {{ col.header }}
                    @if (col.showSortIcon) {
                      <p-sortIcon
                        class="text-white"
                        [field]="col.field"></p-sortIcon>
                    }
                  </th>
                }
                @if (
                  permissionService.checkPermission(
                    'AUTHORISATION_TYPES_CREATE'
                  )
                ) {
                  <th class="bg-gray-800 w-3rem"></th>
                }
                @if (
                  !permissionService.checkPermission(
                    'AUTHORISATION_TYPES_CREATE'
                  )
                ) {
                  <th class="bg-gray-800 w-3rem"></th>
                }
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-category let-columns="columns">
              <tr
                class="cursor-pointer"
                (click)="handleCategorySelection(category)"
                [pSelectableRow]="category">
                @for (col of columns; track col) {
                  <td>
                    @if (col.display?.type === 'text') {
                      {{ category[col.field] }}
                    }
                    @if (col.display?.type === 'icon') {
                      {{ category[col.field] }}
                    }
                  </td>
                }
                <td>
                  @if (
                    permissionService.checkPermission(
                      'AUTHORISATION_TYPES_CREATE'
                    ) &&
                    (disableAddUserIcon ||
                      category.name !== 'Emergency Contact')
                  ) {
                    <i
                      (keyup.enter)="addContactIconClicked(category)"
                      role="button"
                      tabindex="0"
                      (click)="addContactIconClicked(category)"
                      class="pi pi-user-plus text-primary cursor-pointer">
                    </i>
                  }
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-8 h-full flex flex-column m-0">
      <div
        class="flex justify-content-between align-items-center bg-white py-3 border-solid border-1 border-gray-200">
        <h1 class="px-3 text-xl">User List - {{ selectedCategory?.name }}</h1>
        @if (permissionService.checkPermission('AUTHORISATION_TYPES_CREATE')) {
          <div class="pr-3">
            <p-button
              label="Add"
              icon="pi pi-plus"
              [disabled]="disabledAddUserButton"
              (onClick)="addContactButtonClicked()"></p-button>
          </div>
        }
      </div>
      <div
        class="flex-grow-1 overflow-hidden bg-white px-3 border-solid border-1 border-gray-200 border-top-none">
        @if (selectedCategory) {
          <p-table
            #dataTable
            [value]="user$ | async"
            [columns]="userTableColumn"
            styleClass="p-datatable-striped"
            [rowHover]="true"
            sortMode="single"
            [rows]="25"
            [lazy]="true"
            (onLazyLoad)="loadUsers(dataTable, $event)"
            [scrollable]="true"
            scrollHeight="flex">
            <ng-template pTemplate="caption">
              <div class="flex justify-content-end align-content-center">
                <div class="flex-grow-1">
                  @if (
                    userTableColumn && dataTable && dataTable.loading === false
                  ) {
                    <prime-filter-dropdown
                      [selectedColumns]="userTableColumn"
                      [table]="dataTable"></prime-filter-dropdown>
                  }
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                @for (col of columns; track col) {
                  <th
                    class="text-white bg-gray-800 p-datatable-header-first-row"
                    [pSortableColumn]="col.field">
                    {{ col.header }}
                    @if (col.showSortIcon) {
                      <p-sortIcon
                        class="text-white"
                        [field]="col.field"></p-sortIcon>
                    }
                  </th>
                }
                <th class="bg-gray-800 w-3rem"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="loadingbody">
              <tr>
                <td [attr.colspan]="userTableColumn?.length + 1">
                  <loading-spinner></loading-spinner>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="loadingicon"></ng-template>
            <ng-template pTemplate="body" let-user let-columns="columns">
              <tr>
                @for (col of columns; track col) {
                  <td>
                    @if (col.display?.type === 'text') {
                      {{ user[col.field] }}
                    }
                  </td>
                }
                <td>
                  @if (
                    permissionService.checkPermission(
                      'AUTHORISATION_TYPES_DELETE'
                    ) &&
                    (disableAddUserIcon ||
                      selectedCategory?.name !== 'Emergency Contact')
                  ) {
                    <i
                      (keyup.enter)="removeContactIconClicked(user)"
                      role="button"
                      tabindex="0"
                      (click)="removeContactIconClicked(user)"
                      class="pi pi-trash text-primary cursor-pointer"></i>
                  }
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td [attr.colspan]="userTableColumn?.length + 1">
                  No results were found.
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="footer">
              <tr>
                <td
                  class="bg-white"
                  [attr.colspan]="userTableColumn?.length + 1">
                  <div class="flex align-items-center justify-content-center">
                    <p-paginator
                      class="pb-1"
                      #tablePaginator
                      (onPageChange)="loadUsers(dataTable, $event)"
                      [first]="dataTable.first"
                      [rows]="dataTable.rows"
                      [totalRecords]="totalRecords"
                      [showCurrentPageReport]="true"
                      currentPageReportTemplate="{first} - {last} of {totalRecords}"
                      [rowsPerPageOptions]="[25, 50, 100]"
                      dropdownAppendTo="body"></p-paginator>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        }
      </div>
    </div>
  </div>
}
