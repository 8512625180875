import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CostCentreService {
  costCentre = [];
  siteId: string;

  constructor() {}
}
