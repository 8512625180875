<div class="page-content">
  <div class="container">
    <div class="page-content-container">
      <div class="row">
        <div class="col-xs-12">
          <h4 style="font-size: 26px; padding-bottom: 5px">
            Restricted Access
          </h4>
        </div>
      </div>
      <div class="card-border-break"></div>
      <br />
      <p style="padding: 0 25px; text-align: center">
        Unfortunately, we've had to restrict access to this resource.<br />Sorry
        for any inconvience caused, we are aiming to restore full functionality
        as soon as possible.<br />Please check back soon.
      </p>
      <br />
      <div class="row justify-content-center">
        <div class="col-xs-3" style="text-align: center">
          <button
            (click)="redirectToDashboard()"
            class="login-button"
            style="margin: 0 auto"
            mat-raised-button
            color="primary">
            Return to Dashboard
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
