import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';
import {
  GetOutageServiceResponse,
  ServiceNotification,
} from 'src/app/models/outage/outage.models';
import { ServiceNowFilterQuery } from 'src/app/models/servicenow.model';

@Injectable({
  providedIn: 'root',
})
export class OutageService {
  constructor(private apiRequest: ApiRequest) {}

  getCount(filter: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: 'Outage/GetOutageCount',
      body: filter,
    };
    return this.apiRequest.request(requestProps);
  }

  getAuthorisedOutage(outAgeID: string) {
    const requestProps: IRequestOptions = {
      endpoint: 'Outage/getAuthorisedOutage',
      body: outAgeID,
    };
    return this.apiRequest.request(requestProps);
  }

  getOutage(outageId: string) {
    const requestProps: IRequestOptions = {
      endpoint: 'Outage/getOutage',
      body: outageId,
    };
    return this.apiRequest.request(requestProps);
  }

  getOutageByService(service: string): Observable<GetOutageServiceResponse[]> {
    const requestProps: IRequestOptions = {
      endpoint: 'Outage/GetOutageByService',
      body: service,
    };
    return this.apiRequest.request(requestProps);
  }

  getOutagesInformation() {
    const requestProps: IRequestOptions = {
      endpoint: 'Outage/GetOutagesInformation',
      body: '',
    };
    return this.apiRequest.request(requestProps);
  }

  getBusinessServiceSubscriptions(
    filter: ServiceNowFilterQuery
  ): Observable<ServiceNotification[]> {
    const requestProps: IRequestOptions = {
      endpoint: 'Outage/GetBusinessServiceSubscriptions',
      body: filter,
    };
    return this.apiRequest.request(requestProps);
  }

  updateSubscription(serviceToUpdate: string, isSubscribed: boolean) {
    const requestProps: IRequestOptions = {
      endpoint: 'Outage/UpdateSubscription',
      body: {
        service: serviceToUpdate,
        subscribed: isSubscribed,
      },
    };
    return this.apiRequest.request(requestProps);
  }
}
