import { Injectable } from '@angular/core';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';
import { IBundleDetailsRequest } from 'src/app/components/service-hub/products-and-services/telephony/models/telephony-models';
import { ServiceNowFilterQuery } from 'src/app/models/servicenow.model';

@Injectable({
  providedIn: 'root',
})
export class TelephonyApiService {
  constructor(private apiRequest: ApiRequest) {}

  getMobilePhones(request: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: 'Telephony/GetMobilePhones',
      body: request,
      requiresVpc: true,
    };

    return this.apiRequest.request(requestProps);
  }

  getBundleDetailsByCli(requestBody: IBundleDetailsRequest) {
    const requestProps: IRequestOptions = {
      endpoint: 'Telephony/GetBundleDetailsByCli',
      body: requestBody,
      requiresVpc: true,
    };

    return this.apiRequest.request(requestProps);
  }

  getMonthlyUsageGraphs(number: string) {
    const requestProps: IRequestOptions = {
      endpoint: 'Telephony/GetMonthlyUsageGraphs',
      body: number,
      requiresVpc: true,
    };

    return this.apiRequest.request(requestProps);
  }
}
