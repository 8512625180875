<div class="flex flex-column h-full">
  <div
    class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200">
    <h1 class="text-xl px-3">Release Notes</h1>
  </div>
  <div
    class="flex-grow-1 overflow-y-auto mx-3 bg-white border-solid border-1 border-gray-200 border-top-none border-bottom-none p-3">
    @if (loading) {
      <loading-spinner />
    }
    @if (!loading) {
      <div class="flex w-full text-white bg-gray-800 p-2">Version</div>
      <p-accordion [activeIndex]="0" styleClass="bg-yellow">
        @for (tab of releaseNotes; track tab) {
          <p-accordionTab [header]="tab.uVersionNumber">
            <p [innerHTML]="tab.uReleaseNote | safeHtml" class="m-0"></p>
          </p-accordionTab>
        }
      </p-accordion>
    }
  </div>
</div>
