@if (loading) {
  <loading-spinner />
}
@if (!loading) {
  <div class="flex flex-column h-full">
    <h1 class="text-xl px-3">Impersonate</h1>
    <hr class="w-full" />
    <div class="px-3">
      <p class="text-lg">
        Please enter the email account you wish to impersonate
      </p>
      <div class="field">
        <input
          [formControl]="emailValidation"
          id="email"
          type="text"
          class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
          (keydown)="enterKeyPressed($event)" />
        @if (emailValidation.invalid && emailValidation.touched) {
          <small
            class="p-error text-base">
            @if (emailValidation.hasError('required')) {
              Email address is required.
            }
            @if (emailValidation.hasError('userEmailNotFound')) {
              User not found.
            }
            @if (emailValidation.hasError('email')) {
              Invalid email address.
            }
          </small>
        }
        @if (checkingUserExistsByEmail === userExistsByEmail.pending) {
          <div
            class="py-2"
            >
            <small class="p-info p-2">
              <i class="pi pi-spin pi-spinner"></i>
              Checking user exists
            </small>
          </div>
        }
      </div>
      <div class="flex align-content-center justify-content-center py-3">
        <p-button
          styleClass="p-button-secondary"
          label="Cancel"
        (onClick)="close()"></p-button>
        <p-button
          styleClass="p-button"
        [disabled]="
          emailValidation.invalid ||
          checkingUserExistsByEmail === userExistsByEmail.no ||
          checkingUserExistsByEmail === userExistsByEmail.pending
        "
          label="Submit"
        (onClick)="ImpersonateUser()"></p-button>
      </div>
    </div>
  </div>
}
