<div class="flex flex-column h-full">
  <div
    class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200">
    <h1 class="px-3 text-xl">Device Utilisation</h1>
  </div>
  @if (!loading) {
    <div class="px-3">
      <p-tabMenu
        #tab
        [model]="menuItems"
        [activeItem]="activeMenuItem"></p-tabMenu>
    </div>
  }
  @if (!loading) {
    <div class="px-3">
      <p-tabMenu
        #tab
        [model]="activeMenuItem?.menuItems"
        [activeItem]="activeSubMenuItem"></p-tabMenu>
    </div>
  }
  <div class="flex-grow-1 overflow-auto px-3 mx-3 bg-white">
    <app-device-category-table
      [deviceCategory$]="deviceCategory$"></app-device-category-table>
  </div>
</div>
