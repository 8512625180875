import { Injectable } from '@angular/core';
import { IMobile } from 'src/app/components/service-hub/products-and-services/telephony/models/telephony-models';

@Injectable({
  providedIn: 'root',
})
export class EditBarsService {
  numbers = [];

  constructor() {}
}
