import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';
import {
  ICreateWebHookBasicAuthRequest,
  ICreateWebHookOAuthRequest,
  ICreateWebHookRequest,
  IWebHook,
  WebHookAuthenticationTypes,
} from 'src/app/components/service-hub/developer/web-hooks/models/web-hook.model';
import { DeveloperService } from 'src/app/services/api/developer/developer.service';
import { ToastService, ToastType } from 'src/app/services/global/toast.service';

@Component({
  selector: 'app-create-web-hook',
  templateUrl: './create-web-hook.component.html',
  styleUrls: ['./create-web-hook.component.scss'],
})
export class CreateWebHookComponent {
  hideToken = true;
  hideBAUsername = true;
  hideBAPassword = true;
  loading = false;
  webHookForm: FormGroup;
  formValueChanged = false;
  existingWebHooks: IWebHook[];
  availableWebHooks: any;
  availableSources: string[] = [];
  availableActions: string[] = [];
  authenticationTypes: string[] = [
    WebHookAuthenticationTypes.None,
    WebHookAuthenticationTypes.OAuth,
    WebHookAuthenticationTypes.BasicAuth,
  ];

  get webHookFormControls() {
    return this.webHookForm['controls'];
  }

  constructor(
    private developerService: DeveloperService,
    private fb: FormBuilder,
    private dialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private toastService: ToastService
  ) {
    this.existingWebHooks = this.dialogConfig.data.dialog.existingWebHooks;
    this.availableWebHooks = this.dialogConfig.data.dialog.availableWebHooks;
  }

  ngOnInit(): void {
    this.buildWebHookForm();
    this.onFormValueUpdated();
    this.setAvailableSources();
    this.setAvailableActions();
  }

  buildWebHookForm() {
    this.webHookForm = this.fb.group({
      source: ['', Validators.required],
      action: ['', Validators.required],
      endpoint: [
        '',
        [Validators.required, Validators.pattern(/^https:\/\/?/i)],
      ],
      authenticationType: ['', Validators.required],
      oAuthToken: [''],
      basicAuthUsername: [''],
      basicAuthPassword: [''],
    });
  }

  setAuthenticationValidators(value: string) {
    this.clearAuthenticationValidators();

    if (value === WebHookAuthenticationTypes.OAuth)
      this.webHookFormControls.oAuthToken.setValidators(Validators.required);

    if (value === WebHookAuthenticationTypes.BasicAuth) {
      this.webHookFormControls.basicAuthUsername.setValidators(
        Validators.required
      );
      this.webHookFormControls.basicAuthPassword.setValidators(
        Validators.required
      );
    }
  }

  clearAuthenticationValidators() {
    this.webHookFormControls.oAuthToken.clearValidators();
    this.webHookFormControls.oAuthToken.updateValueAndValidity();
    this.webHookFormControls.basicAuthUsername.clearValidators();
    this.webHookFormControls.basicAuthUsername.updateValueAndValidity();
    this.webHookFormControls.basicAuthPassword.clearValidators();
    this.webHookFormControls.basicAuthPassword.updateValueAndValidity();
  }

  buildRequestModel(webHookForm) {
    const authType = webHookForm.authenticationType.value;

    if (authType === WebHookAuthenticationTypes.OAuth) {
      const request: ICreateWebHookOAuthRequest = {
        source: webHookForm.source.value,
        action: webHookForm.action.value,
        endpoint: webHookForm.endpoint.value.toLowerCase(),
        oAuthToken: webHookForm.oAuthToken.value,
      };
      return request;
    }

    if (authType === WebHookAuthenticationTypes.BasicAuth) {
      const request: ICreateWebHookBasicAuthRequest = {
        source: webHookForm.source.value,
        action: webHookForm.action.value,
        endpoint: webHookForm.endpoint.value.toLowerCase(),
        basicAuthUsername: webHookForm.basicAuthUsername.value,
        basicAuthPassword: webHookForm.basicAuthPassword.value,
      };
      return request;
    }

    const request: ICreateWebHookRequest = {
      source: webHookForm.source.value,
      action: webHookForm.action.value,
      endpoint: webHookForm.endpoint.value.toLowerCase(),
    };
    return request;
  }

  async onSubmit() {
    this.loading = true;
    const createWebHookRequest = this.buildRequestModel(
      this.webHookFormControls
    );
    await firstValueFrom(
      this.developerService.createWebHook(createWebHookRequest)
    );
    this.toastService.add({
      severity: ToastType.success,
      summary: 'Success',
      detail: 'WebHook Created',
    });
    this.ref.close(true);
  }

  changeSource() {
    this.webHookForm.patchValue({
      action: null,
    });
    this.setAvailableActions();
  }

  setAvailableSources() {
    const sources = this.availableWebHooks
      .filter(availableWebHook => availableWebHook.actions.length > 0)
      ?.map(availableWebHook => availableWebHook.source);
    this.availableSources = sources;
  }

  setAvailableActions() {
    const actions =
      this.availableWebHooks
        .find(
          availableWebHook =>
            availableWebHook.source === this.webHookForm.get('source').value
        )
        ?.actions?.map(action => action) ?? [];
    this.availableActions = actions;
  }

  onFormValueUpdated() {
    const initialValue = this.webHookForm.value;
    this.webHookForm.valueChanges.subscribe(value => {
      this.formValueChanged = Object.keys(initialValue).some(
        key => this.webHookForm.value[key] != initialValue[key]
      );
    });
  }

  submitButton(): void {
    this.onSubmit();
    this.ref.close(true);
  }
  close(): void {
    this.ref.close();
  }
}
