@if (loading) {
  <loading-spinner />
}
@if (!loading) {
  <div class="flex flex-column h-full">
    <div
      class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200">
      <h1 class="text-xl px-3">Webhooks</h1>
    </div>
    <div
      class="flex-grow-1 overflow-y-auto mx-3 bg-white border-solid border-1 border-gray-200 border-top-none px-3">
      @if (!loading) {
        <p-table
          [value]="webHooks$ | async"
          [columns]="cols"
          sortMode="multiple"
          scrollHeight="flex"
          [scrollable]="true"
          [multiSortMeta]="[
            { field: 'source', order: 1 },
            { field: 'action', order: 1 },
          ]"
          styleClass="p-datatable-striped">
          <ng-template pTemplate="caption">
            <div class="flex justify-content-end align-content-center">
              <p class="m-0 text-xl">
                Configuring a webhook will allow Digital Space to send a HTTP
                POST to the specified URL with a JSON body. The data would
                depend on what you specify as the trigger. For example, you
                configure the URL
                "https://www.test.com/api/digitalspace-incidents-updated" with
                an incident updated trigger, Digital Space would POST the update
                to that URL for yourselves.
              </p>
            </div>
            <div class="flex justify-content-end align-content-center">
              @if (!loading) {
                <p-button
                  label="Add"
                  icon="pi pi-plus"
                  iconPos="left"
                  (onClick)="createWebHook()"
                  styleClass="p-button"
                  [disabled]="totalAvailableWebhooks === 0"
                  appendTo="body"></p-button>
              }
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              @for (col of cols; track col) {
                <th
                  class="text-white bg-gray-800 p-datatable-header-first-row"
                  [pSortableColumn]="col.field"
                  [ngClass]="'p-column-' + col.field">
                  {{ col.header }}
                  @if (col.showSortIcon) {
                    <p-sortIcon
                      class="text-white"
                      [field]="col.field"></p-sortIcon>
                  }
                </th>
              }
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-webhook>
            <tr>
              <td>{{ webhook.source | titlecase }}</td>
              <td>{{ webhook.action | titlecase }}</td>
              <td>{{ webhook.endpoint }}</td>
              <td>
                <p-button label="Edit" (click)="editButtonClicked(webhook)">
                </p-button>
              </td>
              <td>
                <i
                  (keyup.enter)="deleteButtonClicked(webhook)"
                  role="button"
                  tabindex="0"
                  class="pi pi-trash hover:text-primary cursor-pointer"
                  (click)="deleteButtonClicked(webhook)"></i>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td [attr.colspan]="cols.length">No results were found.</td>
            </tr>
          </ng-template>
        </p-table>
      }
    </div>
  </div>
}
