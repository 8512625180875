<div class="flex flex-column h-full">
  <div
    class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200">
    <h1 class="px-3 text-xl">Users</h1>
  </div>
  <div
    class="flex-grow-1 overflow-auto mx-3 overflow-hidden bg-white border-solid border-1 border-gray-200 border-top-none border-bottom-none px-3">
    <p-table
      #dataTable
      [value]="users$ | async"
      [columns]="selectedColumns"
      styleClass="p-datatable-striped"
      [rowHover]="true"
      sortMode="single"
      [rows]="25"
      [lazy]="true"
      (onLazyLoad)="loadUsers(dataTable, $event)"
      selectionMode="multiple"
      [(selection)]="selectedUsers"
      scrollHeight="flex"
      [scrollable]="true">
      <ng-template pTemplate="caption">
        <h1 class="text-xl mr-3">{{ selectedSite?.name }}</h1>
        <div class="flex align-items-center justify-content-between">
          <div class="flex">
            @if (selectedColumns && dataTable && dataTable.loading === false) {
              <prime-filter-dropdown
                [selectedColumns]="cols"
                [table]="dataTable"></prime-filter-dropdown>
            }

            <p-multiSelect
              styleClass="p-multiselect-label-hide p-multiselect-border"
              [options]="cols"
              [(ngModel)]="selectedColumns"
              optionLabel="header"
              dropdownIcon="pi pi-sliders-h"
              placeholder="Choose Columns"
              selectedItemsLabel="{0} columns selected"
              (ngModelChange)="handleColumnChange(cols)"
              optionDisabled="disabled"></p-multiSelect>
            <p-button
              label="Reset"
              styleClass="p-button p-button-text p-button-secondary"
              icon="pi pi-eraser"
              (onClick)="reset(dataTable, false)"></p-button>
          </div>
          <div class="flex gap-1">
            <p-splitButton
              label="Export"
              icon="pi pi-download"
              iconPos="left"
              (onClick)="defaultExport()"
              [model]="exportOptions"
              styleClass="p-button"
              appendTo="body"></p-splitButton>
            @if (canAddEditUsers) {
              <p-button
                [disabled]="selectedUsers.length < 1"
                icon="pi pi-pencil"
                iconPos="left"
                label="Edit"
                (click)="onMultipleUserEdit()"></p-button>
            }
            @if (canAddEditUsers) {
              <p-button
                styleClass="mx-0"
                [disabled]="selectedUsers.length > 0"
                icon="pi pi-plus"
                label="Add"
                (click)="openAddUser()"></p-button>
            }
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width: 4rem" class="bg-gray-800">
            <p-tableHeaderCheckbox />
          </th>
          @for (col of columns; track col) {
            <th
              class="text-white bg-gray-800 p-datatable-header-first-row"
              [pSortableColumn]="col.field"
              [pSortableColumnDisabled]="!col.showSortIcon">
              {{ col.header }}
              @if (col.showSortIcon) {
                <p-sortIcon class="text-white" [field]="col.field"></p-sortIcon>
              }
            </th>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <tr>
          <td [attr.colspan]="selectedColumns?.length + 1">
            <loading-spinner></loading-spinner>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingicon"></ng-template>
      <ng-template pTemplate="body" let-user let-columns="columns">
        <tr (click)="handleRowClicked(user)" class="cursor-pointer">
          <td (click)="$event.stopPropagation()">
            <p-tableCheckbox [value]="user" />
          </td>
          @for (col of columns; track col) {
            <td>
              @if (col.display?.type === 'text') {
                {{ user[col.field] }}
              }
              @if (col.display?.type === 'boolean') {
                <span
                  class="font-semibold"
                  [ngClass]="getPortalAccessColor(user[col.field])"
                  >{{ user[col.field] ? 'Yes' : 'No' }}</span
                >
              }
              @if (col.display.type === 'chip') {
                <div class="text-center">
                  <p-chip
                    [label]="user[col.field] ? 'Active' : 'Inactive'"
                    [styleClass]="
                      getColor(col.display.colors, user[col.field])
                        ? 'p-chip-' +
                          getColor(col.display.colors, user[col.field]) +
                          ' block'
                        : 'block'
                    "></p-chip>
                </div>
              }
            </td>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td [attr.colspan]="tableColumn?.length + 1">
            <div class="flex align-items-center justify-content-center">
              <p-paginator
                (onPageChange)="loadUsers($event, dt1)"
                [first]="dt1.first"
                [rows]="dt1.rows"
                [totalRecords]="totalRecords"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} - {last} of {totalRecords}"
                [rowsPerPageOptions]="[25, 50, 100]"></p-paginator>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="selectedColumns?.length + 1">
            No results were found.
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td [attr.colspan]="selectedColumns.length + 1" class="bg-white">
            <div class="flex align-items-center justify-content-center">
              <p-paginator
                #tablePaginator
                (onPageChange)="loadUsers(dataTable, $event)"
                [first]="dataTable.first"
                [rows]="dataTable.rows"
                [totalRecords]="totalRecords"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} - {last} of {totalRecords}"
                [rowsPerPageOptions]="[25, 50, 100]"></p-paginator>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
