import { MenuService } from 'src/app/services/general/menu/menu.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { MessageService } from 'primeng/api';
import { tap, take, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Toast, ToastService } from './services/global/toast.service';
import { NavigationStart, Router } from '@angular/router';
import { BreadcrumbService } from './services/general/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  VERSION: string = environment.appVersion;

  usingStatusDomain = false;

  private toastSubscription: Subscription;

  constructor(
    public auth: AuthService,
    public auth0Service: AuthService,
    public messageService: MessageService,
    private toastService: ToastService,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private menuService: MenuService
  ) {}

  async ngOnInit(): Promise<void> {
    console.info(`App Version: ${this.VERSION}`);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.menuService.closeMenu();
        this.breadcrumbService.clearBreadcrumbs();
      }
    });
    await new Promise<void>((resolve, reject) => {
      this.auth0Service.isAuthenticated$
        .pipe(
          take(1),
          tap(isAuthenticated => {
            if (!isAuthenticated) {
              localStorage.clear();
            }
          })
        )
        .subscribe({
          next: () => {
            resolve();
          },
          error: err => {
            reject(err);
          },
        });
    });
    this.toastSubscription = this.toastService.toastState.subscribe(
      (toast: Toast | null) => {
        if (toast) {
          this.messageService.add(toast);
        }
      }
    );
  }
  ngOnDestroy() {
    this.toastSubscription.unsubscribe();
  }
}
