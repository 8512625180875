<div class="card flex flex-column h-full p-2">
  <div class="flex justify-content-between align-items-center">
    <h1 class="text-xl">Filters</h1>
  </div>
  <div class="flex-grow-1 overflow-auto">
    <p-table
      #dataTable
      [columns]="cols"
      [value]="[{}]"
      [rows]="10"
      responsiveLayout="scroll">
      <ng-template pTemplate="header" let-columns>
        @for (col of cols; track col) {
          <tr>
            <td>
              <p>{{ col?.header }}</p>
              @if (
                col.display?.type !== 'icon' &&
                (!col.filter || col.filter?.type === 'text')
                ) {
                <p-columnFilter
                  type="text"
                  [field]="col.field"
                matchMode="contains"></p-columnFilter>
              }
              @if (col.filter?.type === 'dropdown') {
                <p-columnFilter
                  [field]="col.field"
                  matchMode="equals"
                  [showMenu]="false">
                  <ng-template
                    pTemplate="filter"
                    let-value
                    let-filter="filterCallback">
                    <p-dropdown
                      [ngModel]="value"
                      [options]="col.filter.values"
                      appendTo="body"
                      (onChange)="filter($event.value)">
                    </p-dropdown>
                  </ng-template>
                </p-columnFilter>
              }
              @if (col.filter?.type === 'multiSelect') {
                <p-multiSelect
                  [options]="col.filter.values"
                  [(ngModel)]="selectedValues[col.field]"
                appendTo="body"></p-multiSelect>
              }
              @if (col.filter?.type === 'dateRange') {
                <date-range-selector
                  [selectedDate]="dataTable?.filters[col.field]?.value"
                  [futurePeriodFilter]="futurePeriodFilter"
                (dateSelected)="
                  getDatesFromCalendar($event, dataTable, col.field)
                "></date-range-selector>
              }
              @if (col?.filter?.type === 'multiSelectMetric') {
                <p-multiSelect
                  [(ngModel)]="selectedValues[col.field]"
                  [options]="col.filter.values"
                  appendTo="body">
                </p-multiSelect>
              }
              @if (col?.filter?.message) {
                <small>{{ col.filter.message }}</small>
              }
            </td>
          </tr>
        }
      </ng-template>
    </p-table>
  </div>
  <div class="flex justify-content-end align-items-center pt-4">
    <button
      pButton
      class="p-button-secondary p-button-text"
      label="Cancel"
    (click)="cancel()"></button>
    <button pButton label="Apply" (click)="applyFilters(dataTable)"></button>
  </div>
</div>
