export enum QuestionStageLookUpType {
  None = 0,
  KnowledgeArticle = 1,
}

export enum ContactPersonType {
  AccountManager = 0,
  ClientManager = 1,
  CreditController = 2,
}

export enum AnalogueBroadbandOrderSections {
  CustomerInfo = 0,
  OrderDetails = 1,
  AddressBuildingInfo = 2,
  TechnicalInfo = 3,
  Summary = 4,
}
export enum DataDate {
  In24Hours = 0,
  In7Days = 1,
  In30Days = 2,
  CustomDates = 3,
}

export enum GraphDataType {
  Latency = 0,
  Availability = 1,
  TimeOnline = 2,
}
