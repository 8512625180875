@if (filterValue?.Type == 'dropdown') {
  <app-filter-dropdown
    [(value)]="filterValue"
    [useTitleCase]="val?.InputSettings?.useTitleCase"
    (refresh)="submit()"
  ></app-filter-dropdown>
}
@if (filterValue?.Type == 'single-dropdown') {
  <app-single-dropdown
    [(value)]="filterValue"
    (refresh)="submit()"
  ></app-single-dropdown>
}
@if (filterValue?.Type == 'text') {
  <app-search-bar
    [(value)]="filterValue"
    (refresh)="submit()"
  ></app-search-bar>
}
@if (filterValue?.Type == 'date') {
  <app-datepicker-filter
    [(value)]="filterValue"
    (refresh)="submit()"
  ></app-datepicker-filter>
}
@if (filterValue?.Type == 'dateRange') {
  <app-date-range-filter
    [(value)]="filterValue"
    [allowFutureDates]="val?.DatePickerOptions?.allowFutureDates"
    (refresh)="submit()"
  ></app-date-range-filter>
}
