import { Component } from '@angular/core';
import { Message } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessagesModule } from 'primeng/messages';
import { CommentResponse } from 'src/app/models/comments/comment.models';

@Component({
  standalone: true,
  imports: [MessagesModule],
  selector: 'app-provide-incident-information',
  templateUrl: './provide-incident-information.component.html',
  styleUrls: ['./provide-incident-information.component.scss'],
})
export class ProvideIncidentInformationComponent {
  latestComment: CommentResponse = null;
  messageDetail: Message[] | undefined;

  constructor(
    public ref: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig
  ) {
    this.latestComment = this.dialogConfig.data?.comment;
    this.messageDetail = this.dialogConfig.data?.messageDetail;
  }
}
