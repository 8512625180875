import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';
import { GetMultipleBroadbandResponse } from 'src/app/models/broadband/broadband.models';
import { ServiceNowFilterQuery } from 'src/app/models/servicenow.model';

@Injectable({
  providedIn: 'root',
})
export class BroadbandService {
  constructor(private apiRequest: ApiRequest) {}

  getCount(filter: ServiceNowFilterQuery): Observable<number> {
    const requestProps: IRequestOptions = {
      endpoint: `Broadband/GetBroadbandCount`,
      body: filter,
    };
    return this.apiRequest.request(requestProps);
  }

  getMultipleBroadband(
    filter: ServiceNowFilterQuery
  ): Observable<GetMultipleBroadbandResponse[]> {
    const requestProps: IRequestOptions = {
      endpoint: `Broadband/GetMultipleBroadband`,
      body: filter,
    };
    return this.apiRequest.request(requestProps);
  }
}
