import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TelephonyService } from '../shared/telephony-service.service';
import { BehaviorSubject } from 'rxjs';
import {
  IBundleDetailsResponse,
  IBundleDetailsRequest,
  IMonthlyUsageGraphBundle,
  IBarContainer,
} from '../models/telephony-models';
import { BarringService } from '../shared/barring.service';
import { MenuItem } from 'primeng/api/menuitem';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { IUserProfile } from '../../../company/users/shared/user-models';

@Component({
  selector: 'telephony-data',
  templateUrl: 'telephony-data.component.html',
  styleUrls: ['telephony-data.component.scss'],
})
export class TelephonyDataComponent implements OnInit {
  dataGraphSubject = new BehaviorSubject<any>(null);
  dataGraph$ = this.dataGraphSubject.asObservable();
  minuteGraphSubject = new BehaviorSubject<any>(null);
  minuteGraph$ = this.minuteGraphSubject.asObservable();
  textGraphSubject = new BehaviorSubject<any>(null);
  textGraph$ = this.textGraphSubject.asObservable();

  mobileNumber: string;
  siteID: string;
  activeBars: IBarContainer;
  tariffData: IBundleDetailsResponse;
  user: IUserProfile;
  amendBars: boolean = false;

  constructor(
    private router: Router,
    public barringService: BarringService,
    public telephonyService: TelephonyService,
    private breadcrumbService: BreadcrumbService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    this.siteID = this.telephonyService?.selectedSite?.siteID;
    this.mobileNumber = this.telephonyService?.selectedSite?.cliNumber;

    if (this.mobileNumber && this.siteID) {
      this.activeBars = await this.barringService.loadActiveBars(
        this.mobileNumber
      );
      await this.loadMonthlyUsageDataForGraphs();
      await this.loadBundleUsage();
    } else {
      //Return to mobile page
      this.navigateToMobilePage();
    }
    this.user = await this.authService.getUser();
    if (this.user.uTimicoPortalPermissions.includes('MOBILE_BARRING')) {
      this.amendBars = true;
    }

    const breadcrumbs: MenuItem[] = [
      {
        label: 'Mobile',
        routerLink: '/secure/products-and-services/telephony',
      },
      { label: this.mobileNumber },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
  }

  async loadBundleUsage() {
    let requestBody: IBundleDetailsRequest = {
      siteID: this.siteID,
      cliNumber: this.mobileNumber,
    };
    this.tariffData = await this.telephonyService.loadBundleUsage(requestBody);
  }

  async loadMonthlyUsageDataForGraphs() {
    let monthlyUsageData: IMonthlyUsageGraphBundle =
      await this.telephonyService.loadMonthlyUsageGraphData(this.mobileNumber);
    this.dataGraphSubject.next(monthlyUsageData.data);
    this.minuteGraphSubject.next(monthlyUsageData.minutes);
    this.textGraphSubject.next(monthlyUsageData.texts);
  }

  navigateToMobilePage() {
    this.router.navigateByUrl('secure/products-and-services/telephony');
  }

  navigateToEditBars() {
    this.barringService.storedMobileNumber = this.mobileNumber;
    this.router.navigateByUrl(
      'secure/products-and-services/telephony/edit-mobile-options'
    );
  }
}
