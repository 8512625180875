{
  "name": "service-hub-web-portal",
  "version": "1.2.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start:uat": "ng serve --configuration=uat",
    "start:prod": "ng serve --configuration=production",
    "build": "ng build",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "build:development-aws": "ng build --configuration=development-aws --output-hashing=all",
    "build:uat": "ng build --configuration=uat --output-hashing=all",
    "build:uat:old": "ng build --configuration=uat-old --output-hashing=all",
    "build:production": "ng build --configuration=production --output-hashing=all"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.0.6",
    "@angular/cdk": "^17.3.10",
    "@angular/common": "^17.0.6",
    "@angular/compiler": "^17.0.6",
    "@angular/core": "^17.0.6",
    "@angular/forms": "^17.0.6",
    "@angular/platform-browser": "^17.0.6",
    "@angular/platform-browser-dynamic": "^17.0.6",
    "@angular/router": "^17.0.6",
    "@auth0/auth0-angular": "^2.1.0",
    "@types/filesaver": "^0.0.30",
    "@types/luxon": "^3.3.7",
    "amazon-quicksight-embedding-sdk": "^2.6.0",
    "auth0-js": "^9.22.1",
    "change-case": "^5.4.3",
    "chart.js": "^2.9.3",
    "core-js": "^3.30.0",
    "dom-to-image": "^2.6.0",
    "echarts": "^5.4.2",
    "file-saver": "^2.0.5",
    "jspdf": "^2.5.1",
    "jszip": "^3.10.1",
    "jwt-decode": "^3.1.2",
    "libphonenumber-js": "^1.11.7",
    "lodash-es": "^4.17.21",
    "luxon": "^3.4.3",
    "moment": "^2.29.4",
    "ng-circle-progress": "^1.7.1",
    "ngx-clarity": "^2.0.0",
    "ngx-cookie-service": "^17.0.1",
    "ngx-device-detector": "^7.0.0",
    "ngx-echarts": "^15.0.3",
    "ngx-pagination": "^6.0.3",
    "path": "^0.12.7",
    "primeflex": "^3.3.1",
    "primeicons": "^6.0.1",
    "primeng": "^17.18.1",
    "rxjs": "^7.8.0",
    "stream": "0.0.2",
    "tldts": "^6.1.2",
    "toastr": "^2.1.4",
    "tslib": "^2.5.0",
    "xlsx": "^0.18.5",
    "zone.js": "^0.14.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.6",
    "@angular/cli": "^17.0.6",
    "@angular/compiler-cli": "^17.0.6",
    "@angular/language-service": "^17.0.6",
    "@semantic-release/changelog": "^6.0.3",
    "@semantic-release/commit-analyzer": "^11.0.0",
    "@semantic-release/exec": "^6.0.3",
    "@semantic-release/git": "^10.0.1",
    "@semantic-release/release-notes-generator": "^12.0.0",
    "@types/gtag.js": "^0.0.12",
    "@types/jasmine": "^4.3.1",
    "@types/jasminewd2": "^2.0.8",
    "@types/lodash-es": "^4.17.12",
    "@types/node": "^18.15.11",
    "angular-eslint": "19.0.2",
    "codelyzer": "^6.0.0",
    "eslint": "^9.16.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-prettier": "^5.2.1",
    "eslint-plugin-simple-import-sort": "^12.1.1",
    "eslint-plugin-unused-imports": "^4.1.4",
    "jasmine-core": "^4.6.0",
    "jasmine-spec-reporter": "^7.0.0",
    "karma": "^6.4.1",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage-istanbul-reporter": "~3.0.2",
    "karma-jasmine": "^5.1.0",
    "karma-jasmine-html-reporter": "^2.0.0",
    "prettier": "^3.4.2",
    "prettier-eslint": "^16.3.0",
    "protractor": "~7.0.0",
    "semantic-release": "^22.0.5",
    "source-map-explorer": "^2.5.3",
    "ts-node": "^10.9.1",
    "tslint": "~6.1.0",
    "typescript": "^5.2.2",
    "typescript-eslint": "8.18.0",
    "webpack-bundle-analyzer": "^4.8.0"
  },
  "optionalDependencies": {
    "@semantic-release/npm": "^11.0.0"
  }
}
