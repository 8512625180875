<div class="flex flex-column h-full">
  <h1 class="text-xl px-3">Numbers</h1>
  <hr class="w-full" />
</div>
<div class="pl-4">
  @for (numbers of mobileNumbersData?.mobileNumbers; track numbers) {
    <p><i class="pi pi-phone"></i> {{ numbers }}</p>
  }
</div>
<div class="flex justify-content-center align-content-center pb-3">
  <p-button label="Close" (onClick)="closelButton()" icon="pi pi-times">
  </p-button>
</div>
