@if (!loading) {
  <div class="flex flex-column h-full pt-2">
    <h1 class="text-xl px-3 m-0">Are you sure?</h1>
    <hr class="w-full" />
    <div class="pl-3">
      <p class="pb-4 pt-1">{{ remove }}</p>
    </div>
  </div>
  <div class="flex align-items-center justify-content-center pb-3">
    <p-button
      (onClick)="close()"
      styleClass="p-button-secondary"
    label="No"></p-button>
    <p-button
      styleClass="p-button"
      label="Yes"
    (onClick)="deleteHostedZone()"></p-button>
  </div>
}
@if (loading) {
  <loading-spinner />
}
