import { Injectable } from '@angular/core';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';

const taskDirectory = 'Order';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private apiRequest: ApiRequest) {}

  getMultiple(body: any) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetMultipleOrders`,
      body: body,
    };

    return this.apiRequest.request(requestProps);
  }

  getCount(body: any) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetOrdersCount`,
      body: body,
    };

    return this.apiRequest.request(requestProps);
  }

  getCustomerDetails(request: string) {
    const requestProps: IRequestOptions = {
      endpoint: 'Order/GetOrderInternalInformation',
      body: request,
    };

    return this.apiRequest.request(requestProps);
  }
}
