import { Component, OnInit, ViewChild } from '@angular/core';
import { EChartsOption } from 'echarts';
import {
  ITicketsOpenedClosed,
  IIncidentPriority,
  TicketTypes,
  IGraphDataResponse,
  ITicketType,
} from './shared/tickets-reporting.model';
import { TicketsService } from 'src/app/services/api/tickets/tickets.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { DateTime } from 'luxon';
import { IncidentService } from 'src/app/services/api/incident/incident.service';
import {
  ExportGraphData,
  ExportTable,
} from '../../products-and-services/science-logic/cmdb-devices/shared/export-devices';
import { Table } from 'primeng/table';
import { MenuItem } from 'primeng/api';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import {
  IncidentSiteResponse,
  months,
} from 'src/app/models/graphs/pie.chart.models';

@Component({
  selector: 'app-tickets-reporting',
  templateUrl: './tickets-reporting.component.html',
  styleUrls: ['./tickets-reporting.component.scss'],
})
export class TicketsReportingComponent implements OnInit {
  @ViewChild('dt1') dt1: Table;
  months: months[];
  selectedMonth: months;

  incidentPriorityChart: EChartsOption;
  showLoaderTickets = false;
  showLoaderPriority = false;
  loadFailedPriority = false;
  loadFailedTickets = false;
  TicketType = TicketTypes;
  ticketsOpenClosed: IGraphDataResponse[];

  ticketTypes: ITicketType[];

  sitesSubject = new BehaviorSubject<IncidentSiteResponse[]>([]);
  sites$ = this.sitesSubject.asObservable();
  sitesLoading = true;

  activeTabIndex = 0;

  ticketInfo: IIncidentPriority[];

  cols = [
    {
      header: 'Site Name',
      field: 'fullName',
    },
    {
      header: 'Building Name',
      field: 'buildingName',
    },
    {
      header: 'Building Number',
      field: 'buildingNumber',
    },
    {
      header: 'Street',
      field: 'street',
    },
    {
      header: 'City',
      field: 'city',
    },
    {
      header: 'County',
      field: 'county',
    },
    {
      header: 'Country',
      field: 'country',
    },
    {
      header: 'Postcode',
      field: 'postcode',
    },
    {
      header: 'Incidents count',
      field: 'total',
    },
  ];

  constructor(
    private ticketsService: TicketsService,
    private incidentService: IncidentService,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit(): Promise<void> {
    this.months = Array.from({ length: 13 }, (_, i) => {
      const date = DateTime.now()
        .minus({ months: i + 1 })
        .startOf('month');

      return {
        name: date.toFormat('MMMM yyyy'),
        value: date.toFormat('dd/MM/yyyy'),
      };
    });
    this.selectedMonth = this.months.at(0);
    const breadcrumbs: MenuItem[] = [{ label: 'Ticketing Insight' }];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.loadTicketGraphs();
    this.loadIncidentPriority();
    this.loadSitesWithTheHighestCountOfIncidents();
  }

  async loadSitesWithTheHighestCountOfIncidents(): Promise<void> {
    if (!this.selectedMonth?.value) {
      return;
    }
    this.sitesLoading = true;
    try {
      const sites = await firstValueFrom(
        this.incidentService.getSitesWithTheHighestCountOfIncidents(
          this.selectedMonth?.value
        )
      );
      this.sitesSubject.next(
        sites.map(site => ({
          ...site,
          address: [
            [site.buildingName, site.buildingNumber, site.street]
              .filter(addressDetail => addressDetail)
              .join(' ')
              .toString(),
            site.city,
            site.county,
            site.country,
            site.postcode,
          ]
            .filter(addressDetail => addressDetail)
            .join(', ')
            .toString(),
        }))
      );
    } catch (error) {
      console.error(error);
    } finally {
      this.sitesLoading = false;
    }
  }

  async loadTicketGraphs(): Promise<void> {
    this.showLoaderTickets = true;
    this.ticketsOpenClosed = await firstValueFrom(
      this.ticketsService.getTicketsOpenedClosed(13)
    ).catch(() => {
      this.loadFailedTickets = true;
    });
    try {
      const incidentsOpenClosed: ITicketsOpenedClosed[] = this.calculateTotals(
        this.ticketsOpenClosed,
        TicketTypes.INCIDENT
      );

      const changeRequestsOpenClosed: ITicketsOpenedClosed[] =
        this.calculateTotals(
          this.ticketsOpenClosed,
          TicketTypes.CHANGE_REQUEST
        );

      let serviceRequestsOpenClosed: ITicketsOpenedClosed[] =
        this.calculateTotals(
          this.ticketsOpenClosed,
          TicketTypes.SERVICE_REQUEST
        );

      const casesOpenClosed: ITicketsOpenedClosed[] = this.calculateTotals(
        this.ticketsOpenClosed,
        TicketTypes.CASE
      );

      serviceRequestsOpenClosed = this.calculateOverallTotals([
        ...serviceRequestsOpenClosed,
        ...casesOpenClosed,
      ]);

      let ordersOpenClosed: ITicketsOpenedClosed[] = this.calculateTotals(
        this.ticketsOpenClosed,
        TicketTypes.ORDER
      );

      const orderLinesOpenClosed: ITicketsOpenedClosed[] = this.calculateTotals(
        this.ticketsOpenClosed,
        TicketTypes.ORDER_LINE_ITEM
      );

      ordersOpenClosed = this.calculateOverallTotals([
        ...ordersOpenClosed,
        ...orderLinesOpenClosed,
      ]);

      this.ticketTypes = [
        { label: 'Incidents', data: incidentsOpenClosed },
        { label: 'Service Requests', data: serviceRequestsOpenClosed },
        { label: 'Orders', data: ordersOpenClosed },
        { label: 'Change Requests', data: changeRequestsOpenClosed },
      ];
    } catch {
      this.loadFailedTickets = true;
    }
    this.showLoaderTickets = false;
  }

  calculateOverallTotals(data: ITicketsOpenedClosed[]) {
    const monthlyOverall: ITicketsOpenedClosed[] = [];
    const uniqueMonths = Array.from(new Set(data.map(item => item.date)));

    uniqueMonths.forEach(month => {
      const itemsInMonth = data.filter(item => item.date === month);
      const overallOpenedTotal = itemsInMonth.reduce(
        (total, item) => total + item.opened,
        0
      );
      const overallClosedTotal = itemsInMonth.reduce(
        (total, item) => total + item.closed,
        0
      );

      monthlyOverall.push({
        date: month,
        opened: overallOpenedTotal,
        closed: overallClosedTotal,
      });
    });

    return monthlyOverall;
  }

  calculateTotals(data: any, tableName: string) {
    const openedData = data.opened.filter(
      item => item.sysClassName === tableName
    );
    const closedData = data.closed.filter(
      item => item.sysClassName === tableName
    );

    const openedTotals = openedData.map(openedItem => {
      const formattedDate = DateTime.fromObject({
        year: openedItem.year,
        month: openedItem.month,
      }).toFormat('LLL yy');

      return {
        date: formattedDate,
        opened: openedItem.total,
      };
    });

    const closedTotals = closedData.map(closedItem => {
      const formattedDate = DateTime.fromObject({
        year: closedItem.year,
        month: closedItem.month,
      }).toFormat('LLL yy');

      return {
        date: formattedDate,
        closed: closedItem.total,
      };
    });

    const combinedTotals = openedTotals.map(openedItem => ({
      date: openedItem.date,
      opened: openedItem.opened,
      closed:
        closedTotals.find(closedItem => closedItem.date === openedItem.date)
          ?.closed || 0,
    }));

    return combinedTotals;
  }

  async loadIncidentPriority(): Promise<void> {
    this.showLoaderPriority = true;
    await this.incidentPriority().catch(error => {
      this.loadFailedPriority = true;
    }),
      (this.showLoaderPriority = false);
  }

  async incidentPriority(): Promise<EChartsOption> {
    const ticketDataPriority: IIncidentPriority[] = await firstValueFrom(
      this.ticketsService.getClosedIncidentsPriority(13)
    );
    this.ticketInfo = ticketDataPriority;

    return (this.incidentPriorityChart = {
      tooltip: {
        trigger: 'axis',

        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        data: ['Critical', 'High', 'Moderate', 'Low'],
        top: 15,
        left: '15',
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        left: 'right',
        top: 'top',
        feature: {
          saveAsImage: { show: true, name: 'Chart Download' },
          magicType: { show: true, type: ['line', 'bar'] },
          restore: {},
          dataView: {
            show: true,
            readOnly: false,
            buttonColor: '#E31C79',
            contentToOption: function () {
              return {
                dataZoom: [
                  {
                    start: 0,
                    end: 100,
                  },
                ],
              };
            },
            optionToContent: function (opt) {
              const series = opt.series;
              const dataZoom = opt.dataZoom[0];
              let table =
                '<table style="width:97%; margin-left:3%; user-select: text;border: solid 1px #BDBDBD ;border-collapse: collapse; font-family: nunito; font-weight: 100; font-size:16px;"><tbody><tr>' +
                '<td style="background-color: black; color: white; padding:12px 8px; width:20%;">Date</td>' +
                '<td style="background-color: black; color: white; padding:12px 8px; width:20%;">Critical</td>' +
                '<td style="background-color: black; color: white; padding:12px 8px; width:20%;">High</td>' +
                '<td style="background-color: black; color: white; padding:12px 8px; width:20%;">Moderate</td>' +
                '<td style="background-color: black; color: white; padding:12px 8px; width:20%;">Low</td>' +
                '</tr>';
              const startIndex = Math.round(
                ((series[0].data.length - 1) * dataZoom.start) / 100
              );
              const endIndex = Math.round(
                ((series[0].data.length - 1) * dataZoom.end) / 100
              );
              for (let x = startIndex; x <= endIndex; x++) {
                table +=
                  '<tr>' +
                  '<td style="padding:12px 8px; width:20%; border-bottom: 1px solid #BDBDBD !important;">' +
                  opt.xAxis[0].data[x] +
                  '</td>' +
                  '<td style="padding:12px 8px; width:20%; border-bottom: 1px solid #BDBDBD !important;">' +
                  series[0].data[x] +
                  '</td>' +
                  '<td style="padding:12px 8px; width:20%; border-bottom: 1px solid #BDBDBD !important;">' +
                  series[1].data[x] +
                  '</td>' +
                  '<td style="padding:12px 8px; width:20%; border-bottom: 1px solid #BDBDBD !important;">' +
                  series[2].data[x] +
                  '</td>' +
                  '<td style="padding:12px 8px; width:20%; border-bottom: 1px solid #BDBDBD !important;">' +
                  series[3].data[x] +
                  '</td>' +
                  '</tr>';
              }
              table += '</tbody></table>';
              return table;
            },
          },
        },
      },
      dataZoom: {
        type: 'slider',
        start: 0,
        end: (13 / ticketDataPriority.length) * 100,
        height: 20,
        bottom: '3%',
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '10%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: ticketDataPriority.map(({ date }) => date),
        axisLabel: {
          rotate: -45,
          interval: 0,
        },
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          name: 'Critical',
          type: 'bar',
          stack: 'incidentPriority',
          data: ticketDataPriority.map(({ critical }) => critical),
          color: '#E31C79',
          silent: true,
        },
        {
          name: 'High',
          type: 'bar',
          stack: 'incidentPriority',
          data: ticketDataPriority.map(({ high }) => high),
          color: '#00B0A3',
          silent: true,
        },
        {
          name: 'Moderate',
          type: 'bar',
          stack: 'incidentPriority',
          data: ticketDataPriority.map(({ moderate }) => moderate),
          color: ' #41D8FE',
          silent: true,
        },
        {
          name: 'Low',
          type: 'bar',
          stack: 'incidentPriority',
          data: ticketDataPriority.map(({ low }) => low),
          color: ' #532973',
          silent: true,
        },
      ],
    });
  }

  export() {
    ExportTable(
      this.dt1,
      this.sitesSubject.value,
      this.cols,
      'all',
      `Incidents Count - ${this.selectedMonth?.name}`
    );
  }

  updateActiveTab(event): void {
    this.activeTabIndex = event.index;
  }

  exportTicket(): void {
    const currentTicketType = this.ticketTypes[this.activeTabIndex];
    const cols = Object.keys(currentTicketType?.data[0]);
    ExportGraphData(currentTicketType, cols);
  }

  exportTicketPriority(): void {
    const cols = Object.keys(this.ticketInfo[0]);
    ExportGraphData(this.ticketInfo, cols);
  }
}
