import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import { DateTime } from 'luxon';
import { MenuItem } from 'primeng/api/menuitem';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { firstValueFrom } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { BuildServiceNowQuery } from 'src/app/helpers/servicenow/filter/servicenow-filter.helper';
import { SetSelectedColumns, TableReset } from 'src/app/helpers/table.helper';
import { Filters, TableColumn, TableColumnColor } from 'src/app/models/table';
import { CaseService } from 'src/app/services/api/case/case.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import { IUserProfile } from '../../company/users/shared/user-models';
import {
  ExportTable,
  GetExportItemsFromFullArray,
} from '../../products-and-services/science-logic/cmdb-devices/shared/export-devices';
import { DigitalSpaceTabMenuItem } from 'src/app/components/misc/digital-space-tab-menu/digital-space-tab-menu.component';
import { ServiceNowFilterQuery } from 'src/app/models/servicenow.model';
import { ConvertServiceNowDateToJsDate } from 'src/app/helpers/date.helper';
import {
  serviceRequestClosedColumns,
  serviceRequestOpenColumns,
} from './service-request.columns';
import {
  GetServiceRequestsResponse,
  ServiceRequestClosedStateValues,
  ServiceRequestOpenStateValues,
  ServiceRequestSizeLimit,
  ServiceRequestTableColumnName,
  ServiceRequestTabs,
} from 'src/app/models/service-request/service-request.models';
import { ServiceRequestService } from 'src/app/services/api/service-request/service-request.service';
import { ExportName, ExportView } from 'src/app/models/export/export-pdf.model';

@Component({
  selector: 'service-requests',
  templateUrl: 'service-requests.component.html',
  styleUrls: ['service-requests.component.scss'],
})
export class ServiceRequestsComponent implements OnInit {
  @ViewChild('dataTable') dataTable: Table;
  @ViewChild('tablePaginator') paginator: Paginator;
  stateOptions: { label: string; value: boolean }[] = [
    { label: 'Company', value: true },
    { label: 'Mine', value: false },
  ];
  isResetting = false;
  ServiceRequestTabs = ServiceRequestTabs;

  serviceRequestSubject = new BehaviorSubject<GetServiceRequestsResponse[]>([]);
  serviceRequest$ = this.serviceRequestSubject.asObservable();

  user: IUserProfile;

  hasCompanyToggle: boolean;
  companyToggle = true;

  _selectedColumns: TableColumn[] = [];
  cols: TableColumn[] = cloneDeep(serviceRequestOpenColumns);

  defaultRowsShown = 25;

  firstLoad = true;

  first = 0;

  exportOptions = [
    {
      label: 'All',
      value: 'all',
      command: () => this.exportAll(),
    },
    {
      label: 'In View',
      value: 'in_view',
      command: () => this.defaultExport(),
    },
  ];

  tabs: DigitalSpaceTabMenuItem[] = [
    {
      label: ServiceRequestTabs.open,
      hovered: false,
    },
    {
      label: ServiceRequestTabs.closed,
      hovered: false,
    },
  ];

  activeTab: DigitalSpaceTabMenuItem = this.tabs[0];

  get selectedColumns(): TableColumn[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: TableColumn[]) {
    this._selectedColumns = this.cols.filter(col =>
      val.map(valCol => valCol.header).includes(col.header)
    );
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private serviceRequestService: ServiceRequestService,
    private caseService: CaseService,
    private authService: AuthService,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    this.user = await this.authService.getUser();
    const breadcrumbs: MenuItem[] = [
      {
        label: 'Service Requests',
        routerLink: '/secure/tickets/service-requests',
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.hasCompanyToggle = this.user.uTimicoPortalPermissions.includes(
      'TICKETS_COMPANY_READ'
    );
    await this.loadRequests();
    this.filterTable();
  }

  tabChange(tab: DigitalSpaceTabMenuItem) {
    this.activeTab = tab;
    this.firstLoad = true;
    if (tab.label === ServiceRequestTabs.open) {
      this.cols = cloneDeep(serviceRequestOpenColumns);
    }
    if (tab.label === ServiceRequestTabs.closed) {
      this.cols = cloneDeep(serviceRequestClosedColumns);
    }
    this.loadRequests();
  }

  async loadRequests() {
    this.dataTable.loading = true;

    this.serviceRequestSubject.next([]);

    if (this.firstLoad) {
      this.reset(this.dataTable, false, this.cols);
      this.firstLoad = false;
    }

    const requests = (
      await Promise.all([this.getServiceRequests(), this.getCases()])
    ).flatMap(results => results);
    this.serviceRequestSubject.next(requests);

    this.dataTable.loading = false;
  }

  async getServiceRequests() {
    const serviceRequestCount =
      (await firstValueFrom(
        this.serviceRequestService.getCount(
          this.getQueryFilter(this.dataTable, this.cols)
        )
      )) ?? 0;

    const serviceRequestRequests = [];
    const totalPages = Math.ceil(serviceRequestCount / ServiceRequestSizeLimit);
    for (let pageNumber = 0; pageNumber < totalPages; pageNumber++) {
      serviceRequestRequests.push(
        firstValueFrom(
          this.serviceRequestService.getMultiple(
            this.getQueryFilter(
              this.dataTable,
              this.cols,
              ServiceRequestSizeLimit,
              pageNumber
            )
          )
        )
      );
    }

    const results = await Promise.all(serviceRequestRequests);
    return results
      .flatMap(result => result)
      .map(item => {
        return {
          ...item,
          type: 'request',
          openedAt: ConvertServiceNowDateToJsDate(item.openedAt),
          state: item.state,
        };
      });
  }

  async getCases() {
    const count =
      (await firstValueFrom(
        this.caseService.getCount(
          this.getQueryFilter(this.dataTable, this.cols)
        )
      )) ?? 0;

    const requests = [];
    const totalPages = Math.ceil(count / ServiceRequestSizeLimit);
    for (let pageNumber = 0; pageNumber < totalPages; pageNumber++) {
      requests.push(
        firstValueFrom(
          this.caseService.getMultiple(
            this.getQueryFilter(
              this.dataTable,
              this.cols,
              ServiceRequestSizeLimit,
              pageNumber
            )
          )
        )
      );
    }

    const results = await Promise.all(requests);

    return results
      .flatMap(result => result)
      .map(item => {
        return {
          ...item,
          type: 'case',
          openedAt: ConvertServiceNowDateToJsDate(item.openedAt),
          state: item.state,
        };
      });
  }

  getQueryFilter(
    table: Table,
    columns: TableColumn[],
    amount?: number,
    page?: number
  ): ServiceNowFilterQuery {
    const serviceNowFilterQuery = BuildServiceNowQuery(
      this.activeTab.label === ServiceRequestTabs.closed ? table : null,
      columns,
      amount,
      page
    );
    if (!this.companyToggle) {
      serviceNowFilterQuery.Filter.push({
        Column: ServiceRequestTableColumnName.requestedForSysId,
        Value: [this.user?.sysId],
      });
    }
    if (this.activeTab.label === ServiceRequestTabs.open) {
      serviceNowFilterQuery.Filter.push({
        Column: ServiceRequestTableColumnName.state,
        Value: ServiceRequestOpenStateValues,
      });
    }
    if (this.activeTab.label === ServiceRequestTabs.closed) {
      this.router.navigate([], {
        queryParams: {},
      });
      serviceNowFilterQuery.Filter.push({
        Column: ServiceRequestTableColumnName.state,
        Value: ServiceRequestClosedStateValues,
      });
    }

    return serviceNowFilterQuery;
  }

  async onFiltersChanged() {
    if (this.activeTab.label === ServiceRequestTabs.closed) {
      await this.loadRequests();
    }
  }

  async onToggleChange(): Promise<void> {
    const queryParams = this.route.snapshot.queryParams;

    if (queryParams.state) {
      await this.loadRequests();
      this.filterTable();
    } else {
      await this.loadRequests();
    }
  }

  getColor(colors: TableColumnColor[], value: string) {
    return colors[value] ?? null;
  }

  handleColumnChange(val: TableColumn[]) {
    this.cols = this.cols.filter(col =>
      val.map(valCol => valCol.header).includes(col.header)
    );
  }

  handleRowClicked(event) {
    if (event.type === 'request') {
      this.router.navigateByUrl(
        `secure/tickets/service-request/${event.sysId}`
      );
    } else if (event.type === 'case') {
      this.router.navigateByUrl(`secure/tickets/case/${event.sysId}`);
    }
  }

  defaultExport() {
    const displayedRows = this.dataTable.filteredValue || this.dataTable.value;
    ExportTable(
      this.dataTable,
      GetExportItemsFromFullArray(
        displayedRows.map(item => {
          return {
            ...item,
            openedAt: DateTime.fromJSDate(item.openedAt).toFormat(
              'dd/MM/yyyy HH:ss'
            ),
          };
        }),
        this.dataTable.first ?? 0,
        this.dataTable._rows ?? this.defaultRowsShown
      ),
      this.selectedColumns,
      'all',
      `${ExportName.request} report - ${ExportView.inView}`
    );
  }

  async exportAll() {
    const displayedRows = this.dataTable.filteredValue || this.dataTable.value;
    ExportTable(
      this.dataTable,
      displayedRows.map(item => {
        return {
          ...item,
          openedAt: DateTime.fromJSDate(item.openedAt).toFormat(
            'dd/MM/yyyy HH:ss'
          ),
        };
      }),
      this.selectedColumns,
      'all',
      `${ExportName.request} report - ${ExportView.all}`
    );
  }

  async resetTable() {
    this.router.navigate([], {
      queryParams: {},
    });
    this.cols =
      this.activeTab.label === ServiceRequestTabs.closed
        ? cloneDeep(serviceRequestClosedColumns)
        : cloneDeep(serviceRequestOpenColumns);
    this.firstLoad = true;
    await this.loadRequests();
  }

  reset(
    table: Table,
    firstLoad = false,
    columns: TableColumn[],
    additionalFilters?: Filters
  ) {
    this._selectedColumns = SetSelectedColumns(
      this.cols,
      columns.filter(column => column?.default).map(column => column?.field)
    );
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
      additionalFilters: additionalFilters,
    });
  }

  filterTable(): void {
    const queryParams = this.route.snapshot.queryParams;
    if (!queryParams) return;

    if (queryParams.state) {
      const column = this.cols.find(
        currentFilter => currentFilter.serviceNowField === 'State'
      );
      if (column) {
        const value = [queryParams.state];
        this.dataTable.filter(value, column.field, 'in');
      }
    }
    if (queryParams.opened_at_start_date && queryParams.opened_at_end_date) {
      const startDateDateTime = DateTime.fromFormat(
        queryParams.opened_at_start_date,
        'dd/MM/yyyy'
      ).startOf('day');
      const startDate = startDateDateTime.toJSDate();
      const endDateDateTime = DateTime.fromFormat(
        queryParams.opened_at_end_date,
        'dd/MM/yyyy'
      ).endOf('day');

      const endDate = endDateDateTime.toJSDate();
      const values = [startDate, endDate];
      const column = this.cols.find(
        currentFilter =>
          currentFilter.serviceNowField ===
          ServiceRequestTableColumnName.openedAt
      );
      if (column) {
        this.dataTable.filter(values, column.field, 'between');
      }
    }
  }
}
