<div class="flex flex-column">
  <div
    class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200">
    <h1 class="px-3 text-xl">Change Details</h1>
  </div>
  <div
    class="flex-grow-1 overflow-auto mx-3 overflow-hidden bg-white border-solid border-1 border-gray-200 border-top-none border-bottom-none px-3 pt-2 pb-2"></div>

  <div
    class="flex-grow-1 overflow-auto mx-3 overflow-hidden bg-white border-solid border-1 border-gray-200 border-top-none pb-3 px-3 pt-2">
    <p-table
      #dataTable
      rowExpandMode="multiple"
      [value]="changeRequestDetails"
      [columns]="tableColumn"
      styleClass="p-datatable-striped"
      [rowHover]="true"
      [rows]="25"
      dataKey="details">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width: 4rem" class="bg-gray-800"></th>
          @for (col of columns; track col) {
            <th class="text-white bg-gray-800 p-datatable-header-first-row">
              {{ col.header }}
            </th>
          }
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-changeRequest let-expanded="expanded">
        <tr>
          <td class="h-3rem">
            @if (changeRequest?.details?.length > 0) {
              <button
                type="button"
                pButton
                pRipple
                [pRowToggler]="changeRequest"
                class="p-button-text p-button-rounded p-button-plain"
                [icon]="
                  expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
                "></button>
            }
          </td>
          <td>{{ changeRequest?.name }}</td>
          <td>
            {{
              changeRequest?.details && changeRequest?.details.length > 100
                ? changeRequest?.details.substring(0, 100) + '...'
                : changeRequest?.details || ''
            }}
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="loadingbody">
        <tr>
          <td [attr.colspan]="tableColumn?.length + 1">
            <loading-spinner></loading-spinner>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingicon"></ng-template>

      <ng-template pTemplate="rowexpansion" let-change>
        <tr>
          <td colspan="5" class="white-space-pre-line word-break-break-word">
            {{ change?.details }}
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="tableColumn?.length + 1">
            No results were found.
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
