import { IDomainRecord } from '../components/misc/pop-up/shared/record-model';
import { DnsRecord } from '../components/service-hub/products-and-services/domains/domain/domain.component';
import { Route53Record } from '../components/service-hub/products-and-services/domains/shared/domain-models';
import { DNSRegX } from '../form-validation/validators';

export const CleanDnsRecordString = (string, stringToRemove) => {
  return string
    .toLowerCase()
    .replace(stringToRemove.toLowerCase(), '')
    .replace(/\.*$/, '');
};

export const ConvertTTLNumberToDisplay = (tll: number): string => {
  switch (tll) {
    case 300: {
      return '5m';
    }
    case 900: {
      return '15m';
    }
    case 3600: {
      return '1h';
    }
    case 14400: {
      return '4h';
    }
    case 43200: {
      return '12h';
    }
    case 86400: {
      return '1d';
    }
    case 259200: {
      return '3d';
    }
    case 604800: {
      return '1w';
    }
    default: {
      return null;
    }
  }
};

export const ConvertTTLDisplayToNumber = (ttl: string): number => {
  switch (ttl) {
    case '5m': {
      return 300;
    }
    case '15m': {
      return 900;
    }
    case '1h': {
      return 3600;
    }
    case '4h': {
      return 14400;
    }
    case '12h': {
      return 43200;
    }
    case '1d': {
      return 86400;
    }
    case '3d': {
      return 259200;
    }
    case '1w': {
      return 604800;
    }
    default: {
      return null;
    }
  }
};

export const ConvertType = (type: string): string => {
  switch (type) {
    case 'A': {
      return 'IN A';
    }
    case 'AAAA': {
      return 'IN AAAA';
    }
    case 'CNAME': {
      return 'IN CNAME';
    }
    case 'MX': {
      return 'IN MX';
    }
    case 'NAPTR': {
      return 'IN NAPTR';
    }
    case 'NS': {
      return 'IN NS';
    }
    case 'SRV': {
      return 'IN SRV';
    }
    case 'TXT': {
      return 'IN TXT';
    }
    case 'PTR': {
      return 'IN PTR';
    }
  }
};

export const HasArrayOverlap = (arr1: string[], arr2: string[]): boolean => {
  return arr2.some(item => new Set(arr1).has(item));
};

export const IsValidDnsRecord = (type: string, value: string): boolean => {
  type = CleanDnsRecordString(type, 'in ').toUpperCase();
  value = value.toString();
  if (type === 'A' && value.match(DNSRegX.ipv4)) {
    return true;
  }
  if (type === 'AAAA' && value.match(DNSRegX.ipv6)) {
    return true;
  }
  if (type === 'CNAME' && DNSRegX.incnam.test(value)) {
    return true;
  }
  if (type === 'TXT' && value.match(DNSRegX.txt)) {
    return true;
  }
  if (type === 'SRV' && DNSRegX.srv.test(value)) {
    return true;
  }
  if (type === 'MX' && DNSRegX.mx.test(value)) {
    return true;
  }
  if (type === 'NAPTR' && !DNSRegX.isValue.test(value)) {
    return true;
  }
  if (type === 'NS' && !DNSRegX.isValue.test(value)) {
    return true;
  }
  return false;
};

export const CreateRoute53Record = (domainName, values): Route53Record => {
  const type: string = values.type.trim();

  let value: string | string[] = values.value
    .split('\n')
    .map(line => line.trim())
    .filter(line => line !== '');
  let name = values.name.trim().toLowerCase();
  if (name === '@') {
    name = domainName;
  }
  if (name !== domainName && !name.includes(`.${domainName}`)) {
    name = `${name}.${domainName}`;
  }
  return {
    name: name,
    ttl: values.ttl,
    type: type,
    value: value,
  };
};

export const CheckExistingRecord = (
  records,
  domainName,
  newRecord,
  isReverse: boolean = false
): boolean => {
  let newRecordName = newRecord.name.toString();
  if (isReverse) {
    newRecordName = newRecordName.endsWith('.')
      ? newRecordName
      : `${newRecordName}.`;
  }
  if (newRecordName === domainName) {
    newRecordName = '@';
  }
  if (newRecordName.includes(`.${domainName}`)) {
    newRecordName = newRecordName.replace(`.${domainName}`, '');
  }
  return records.find(record => {
    return (
      record.name.toLowerCase() === newRecordName.toLowerCase() &&
      record.type.toLowerCase().replace('in ', '') ===
        newRecord.type.toLowerCase().replace('in ', '') &&
      record.value.toString().trim() === newRecord.value.toString().trim()
    );
  });
};

export const CheckExistingRecordRoute53 = (
  records: DnsRecord[],
  domainName: string,
  newRecord: IDomainRecord,
  isReverse: boolean = false
): DnsRecord => {
  let newRecordName = newRecord.name.toString();
  if (isReverse) {
    newRecordName = newRecordName.endsWith('.')
      ? newRecordName
      : `${newRecordName}.`;
  }
  if (newRecordName === domainName) {
    newRecordName = '@';
  }
  if (newRecordName.includes(`.${domainName}`)) {
    newRecordName = newRecordName.replace(`.${domainName}`, '');
  }
  return records.find(
    (record: DnsRecord) =>
      record.name.toLowerCase() === newRecordName.toLowerCase() &&
      record.type.toLowerCase().replace('in ', '') ===
        newRecord.type.toLowerCase().replace('in ', '')
  );
};
