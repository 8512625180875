import { Component, OnInit } from '@angular/core';
import { ContactPersonType } from 'src/app/helpers/EnumHelper';
import { IUserProfile } from '../../company/users/shared/user-models';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CompanyAPIService } from 'src/app/services/api/company/company.service';
import { firstValueFrom } from 'rxjs';
import { MenuItem } from 'primeng/api/menuitem';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-support-contacts',
  templateUrl: './support-contacts.component.html',
  styleUrls: ['./support-contacts.component.scss'],
})
export class SupportContactsComponent implements OnInit {
  user: IUserProfile;
  noContacts: boolean = false;
  supportContacts: any;
  accountManager: any = undefined;
  clientManager: any = undefined;
  creditController: any = undefined;

  constructor(
    private authService: AuthService,
    private companyAPIService: CompanyAPIService,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    const breadcrumbs: MenuItem[] = [
      { label: ' Support', routerLink: '/support' },
      { label: 'Contacts', routerLink: '/' },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.user = await this.authService.getUser();
    this.loadTimicoContactDetails();
  }

  async loadTimicoContactDetails() {
    this.supportContacts = await firstValueFrom(
      this.companyAPIService.getCompanyTimicoContacts('')
    );

    if (
      this.stringIsNullOrEmpty(this.supportContacts.accountManager.sysId) &&
      this.stringIsNullOrEmpty(this.supportContacts.clientManager.sysId) &&
      this.stringIsNullOrEmpty(this.supportContacts.creditController.sysId)
    ) {
      this.noContacts = true;
    }

    if (!this.noContacts) {
      if (
        !this.stringIsNullOrEmpty(this.supportContacts.accountManager.sysId)
      ) {
        this.populateContact(ContactPersonType.AccountManager);
      }

      if (!this.stringIsNullOrEmpty(this.supportContacts.clientManager.sysId)) {
        this.populateContact(ContactPersonType.ClientManager);
      }

      if (
        !this.stringIsNullOrEmpty(this.supportContacts.creditController.sysId)
      ) {
        this.populateContact(ContactPersonType.CreditController);
      }
    }
  }

  stringIsNullOrEmpty(text: string) {
    return text === '' || text == undefined;
  }

  populateContact(personType: ContactPersonType) {
    let person;
    let name = '';
    let email = '';

    switch (personType) {
      case ContactPersonType.AccountManager:
        person = this.supportContacts.accountManager;
        break;
      case ContactPersonType.ClientManager:
        person = this.supportContacts.clientManager;
        break;
      case ContactPersonType.CreditController:
        person = this.supportContacts.creditController;
        break;
    }

    if (person.firstName == undefined || person.lastName == undefined) {
      return;
    }

    name = person.firstName + ' ' + person.lastName;

    if (!this.stringIsNullOrEmpty(person.email)) {
      email = person.email;
    }

    switch (personType) {
      case ContactPersonType.AccountManager:
        this.accountManager = {
          Name: name,
          BusinessNumber: person.phone,
          MobileNumber: person.mobilePhone,
          Email: email,
        };
        break;
      case ContactPersonType.ClientManager:
        this.clientManager = {
          Name: name,
          BusinessNumber: person.phone,
          MobileNumber: person.mobilePhone,
          Email: email,
        };
        break;
      case ContactPersonType.CreditController:
        this.creditController = {
          Name: name,
          BusinessNumber: person.phone,
          MobileNumber: person.mobilePhone,
          Email: email,
        };
        break;
    }
  }
}
