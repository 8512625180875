import { DatePipe } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuItem, Message } from 'primeng/api';
import { BehaviorSubject, Subject } from 'rxjs';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import {
  BroadbandMonthlyColumns,
  broadbandReportColumns,
} from '../shared/broadband-models';
import { BroadbandUsageService } from '../shared/broadband-usage.service';
import { TableColumn } from 'src/app/models/table';
import { BroadBandUsage } from 'src/app/models/broadband/broadband.models';

@Component({
  selector: 'app-broadband-usage',
  templateUrl: './broadband-usage.component.html',
  styleUrls: ['./broadband-usage.component.scss'],
})
export class BroadbandUsageComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  usageMonthlySubject = new BehaviorSubject(null);
  usageDailySubject = new BehaviorSubject(null);

  usageMonthly$ = this.usageMonthlySubject.asObservable();
  usageDaily$ = this.usageDailySubject.asObservable();

  graphDataSubject = new BehaviorSubject(null);
  graphData$ = this.graphDataSubject.asObservable();

  graphLoaded: boolean;
  graphToggle = false;

  loading: boolean;
  reportDate = '';

  monthTableColumn: TableColumn[] = BroadbandMonthlyColumns;
  reportTableColumns: TableColumn[] = broadbandReportColumns;

  selectedMonth!: BroadBandUsage;

  errorMessage: Message[] | undefined;

  tableLoading = false;

  private isAlive = true;

  stateOptions: { label: string; value: boolean }[] = [
    { label: 'Table', value: false },
    { label: 'Graph', value: true },
  ];

  selectedUsername: string;

  constructor(
    public broadbandUsageService: BroadbandUsageService,
    private route: ActivatedRoute,
    public datepipe: DatePipe,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    try {
      this.loading = true;
      //Load user into  service
      if (!this.isAlive) {
        return;
      }
      this.selectedUsername = this.route.snapshot.params.id;
      //Default report date to this month
      this.reportDate = this.datepipe.transform(Date(), 'MMMM y');

      //Load data
      await this.getUsageDataMonthly(this.selectedUsername);
      if (!this.isAlive) {
        return;
      }

      const breadcrumbs: MenuItem[] = [
        {
          label: 'Broadband',
          routerLink: '/secure/products-and-services/broadband',
        },
        { label: this.selectedUsername },
      ];
      this.breadcrumbService.setBreadcrumbs(breadcrumbs);

      this.loading = false;
    } catch (error) {
      console.error(error);
    }
  }

  // graph = {};

  async getUsageDataMonthly(selectedUsername: string) {
    try {
      this.usageMonthlySubject.next([]);
      const usageDataMonthly =
        await this.broadbandUsageService.loadUsageMonthly(selectedUsername);
      this.usageMonthlySubject.next(usageDataMonthly);
      this.selectedMonth = usageDataMonthly?.[0] ?? null;
      await this.getUsageDataDaily(
        usageDataMonthly[0].date,
        this.selectedUsername
      );
      if (!this.isAlive) {
        return;
      }
    } catch {
      this.errorMessage = [
        {
          severity: 'error',
          summary: 'Error',
          detail: 'No ADSL information is available for this user.',
        },
      ];
    }
  }

  async getUsageDataDaily(date: string, selectedUsername: string) {
    this.tableLoading = true;
    this.usageDailySubject.next([]);
    const { graph, data } = await this.broadbandUsageService.loadUsageDaily(
      date,
      selectedUsername
    );
    // Emit data
    this.usageDailySubject.next(data);

    // const { graph, data } = await this.broadbandUsageService.loadUsageMonthly(selectedUsername);
    this.graphDataSubject.next(graph);
    this.tableLoading = false;
  }

  onRowSelect(event): void {
    this.graphToggle = false;
    this.getUsageDataDaily(event.data.date, this.selectedUsername);
    this.reportDate = this.datepipe.transform(event.data.date, 'dd/MM/yyyy');
    this.reportDate = this.datepipe.transform(this.reportDate, 'MMMM y');
  }

  ngOnDestroy() {
    this.isAlive = false;
    this.destroy$.next();
    this.destroy$.complete();
  }
}
