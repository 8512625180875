import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';
import { ImpersonateUserComponent } from 'src/app/components/misc/pop-up/impersonate-user/impersonate-user.component';
import { CompanyAPIService } from 'src/app/services/api/company/company.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ProfileMenuItems } from 'src/app/services/general/menu/profile-menu';
import { ToastService, ToastType } from 'src/app/services/global/toast.service';
import { PermissionService } from 'src/app/services/permissions.service';
import { ServiceHubMenuItem } from '../../../services/general/menu/menu';
import { IUserProfile } from '../../service-hub/company/users/shared/user-models';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { MenuNotificationsComponent } from '../menu-notifications/menu-notifications.component';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { AvatarModule } from 'primeng/avatar';

@Component({
  selector: 'top-bar',
  templateUrl: 'top-bar.component.html',
  styleUrls: ['top-bar.component.scss'],
  standalone: true,
  providers: [DialogService],
  imports: [
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    ButtonModule,
    FormsModule,
    RouterModule,
    TieredMenuModule,
    AvatarModule,
    MenuNotificationsComponent,
  ],
})
export class TopBarComponent implements OnInit {
  @ViewChild('avatarMenu') avatarMenu: ElementRef;

  @Output() openImpersonateDialog = new EventEmitter<unknown>();
  @Output() closeMenuEvent = new EventEmitter<boolean>();

  user: IUserProfile;
  profileMenuItems: ServiceHubMenuItem[] = [];
  searchValue = '';
  isEnterpriseCompany: boolean;
  canImpersonateUser = false;
  isImpersonating = false;
  impersonatedUser: IUserProfile;
  ref: DynamicDialogRef;

  constructor(
    private router: Router,
    private permissionService: PermissionService,
    public authService: AuthService,
    private companyService: CompanyAPIService,
    private dialogService: DialogService,
    private toastService: ToastService
  ) {}

  async ngOnInit() {
    this.user = await this.authService.getUser();
    this.isImpersonating = this.authService.isImpersonating();

    this.profileMenuItems = this.buildProfileMenuItems(
      this.isImpersonating,
      this.isEnterpriseCompany
    );

    this.profileMenuItems.forEach(item => {
      if (item.label === 'Impersonate') {
        item.command = () => this.openImpersonate();
      }
      if (item.label === 'End Impersonation') {
        item.command = () => this.endImpersonate();
      }
    });
  }

  buildProfileMenuItems(
    isImpersonating,
    isEnterpriseCompany
  ): ServiceHubMenuItem[] {
    return ProfileMenuItems.filter(menuItem => {
      if (
        menuItem.label === 'Impersonate' ||
        menuItem.label === 'End Impersonation'
      ) {
        if (
          this.permissionService.checkPermission('IMPERSONATE') &&
          this.permissionService.digitalSpaceID()
        ) {
          if (menuItem.label === 'Impersonate') {
            return !isImpersonating;
          }
        }
        if (menuItem.label === 'End Impersonation') {
          return isImpersonating;
        }

        return false;
      } else if (menuItem.label === 'Developer') {
        return (
          this.permissionService.digitalSpaceID() &&
          this.permissionService.checkPermission('TIMICO_DEVELOPER')
        );
      } else if (menuItem.label === 'Service Policies') {
        return isEnterpriseCompany;
      }
      return true;
    });
  }

  async getCompanyType() {
    this.isEnterpriseCompany = await firstValueFrom(
      this.companyService.isEnterprise(this.user.companyId)
    );
  }

  endImpersonation(): void {
    this.authService.clearImpersonation();
  }

  search() {
    if (!this.searchValue.length) {
      this.toastService.add({
        severity: ToastType.warning,
        summary: 'Sorry',
        detail: 'Please enter a search term to search',
      });
      return;
    }
    this.router.navigate(['/secure/search'], {
      queryParams: { query: this.searchValue },
    });
  }

  openImpersonate() {
    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    this.ref = this.dialogService.open(ImpersonateUserComponent, {
      width: '40%',
      contentStyle: { overflow: 'auto' },
      showHeader: false,
      styleClass: 'p-dialog-custom',
    });
  }

  endImpersonate() {
    this.authService.clearImpersonation();
  }

  toggleMenu() {
    this.closeMenuEvent.emit(true);
  }
}
