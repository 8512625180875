import { Injectable } from '@angular/core';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';
import { ServiceNowFilterQuery } from 'src/app/models/servicenow.model';

@Injectable({
  providedIn: 'root',
})
export class LogsService {
  constructor(private apiRequest: ApiRequest) {}

  getUserLogs(filter: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: 'Logs/GetUserLogs',
      body: filter,
    };

    return this.apiRequest.request(requestProps);
  }
}
