import { Component, OnInit, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { costCentreUserFilter } from 'src/app/components/misc/filters/filters';
import { UnityService } from 'src/app/services/api/unity/unity.service';
import {
  costCentreColumns,
  ICostCentre,
} from '../edit-cost-centre/models/cost-centre.models';
import { LazyLoadEvent } from 'primeng/api';
import {
  IsPaginatorState,
  SetSelectedColumns,
  TableReset,
} from 'src/app/helpers/table.helper';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { TableColumn } from 'src/app/models/table';
import { BuildServiceNowQuery } from 'src/app/helpers/servicenow/filter/servicenow-filter.helper';
import { Paginator, PaginatorState } from 'primeng/paginator';

@Component({
  selector: 'app-edit-cost-user',
  templateUrl: './edit-cost-user.component.html',
  styleUrls: ['./edit-cost-user.component.scss'],
})
export class EditCostUserComponent implements OnInit {
  selectedCostCentreUser: ICostCentre;
  @ViewChild('tablePaginator') paginator: Paginator;
  filter = cloneDeep(costCentreUserFilter);
  usersSubject = new BehaviorSubject(null);
  usersSubject$ = this.usersSubject.asObservable();
  siteId: string;
  totalRecords: number = 0;
  tableLoading: boolean = false;
  firstLoad: boolean = true;
  cols: TableColumn[] = cloneDeep(costCentreColumns);
  _selectedColumns: TableColumn[] = [];

  get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter(col =>
      val.map(valCol => valCol.header).includes(col.header)
    );
  }

  ngOnInit(): void {
    this.siteId = this.dialogConfig.data?.id;
  }

  constructor(
    private unityService: UnityService,
    public ref: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig
  ) {}

  async getUsers(
    table: Table,
    event?: LazyLoadEvent | PaginatorState
  ): Promise<void> {
    if (IsPaginatorState(event)) {
      table.rows = event?.rows;
      table.first = event?.first;
    }
    const isFirstLoad = this.firstLoad ? true : false;
    table.loading = true;
    this.usersSubject.next([]);
    if (isFirstLoad) {
      this.reset(table, true, cloneDeep(costCentreColumns));
      this.firstLoad = false;
    }

    const currentPage =
      event?.first != null && event?.rows != null
        ? event.first / event.rows
        : table.first / table.rows;
    const currentPerPage = event?.rows ? event.rows : table.rows;

    let serviceNowFilter = BuildServiceNowQuery(
      table,
      this.cols,
      currentPerPage,
      currentPage,
      event,
      { SiteID: this.siteId }
    );
    const response = await firstValueFrom(
      this.unityService.getUsersBySiteId(serviceNowFilter)
    );

    this.usersSubject.next(response.result);
    this.totalRecords = response.overallQueryCount;
    if (!IsPaginatorState(event)) {
      if (this.paginator) {
        this.paginator.first = 0;
      }
    }
    table.loading = false;
  }

  closeDialog(): void {
    this.ref.close();
  }

  confirmButton(): void {
    this.ref.close(this.selectedCostCentreUser);
  }

  reset(
    table: Table,
    firstLoad: boolean = false,
    columns: TableColumn[] = costCentreColumns
  ) {
    this._selectedColumns = SetSelectedColumns(
      this.cols,
      columns.filter(column => column?.default).map(column => column?.field)
    );
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
    });
  }
}
