import { TableColumn, TableSort } from 'src/app/models/table';

export enum ContactType {
  contact = 'contact',
  authorisation = 'authorisation',
}

export interface ICompanyContactCategory {
  sysId: string;
  name: string;
  contacts: Array<ICompanyContact>;
  type?: string;
}

export interface ICompanyContact {
  sysID: string;
  name: string;
}

export interface ICompanyTableData {
  name: string;
}

export interface IUserTableData {
  firstName: string;
  lastName: string;
  email: string;
  uTimicoPortalRole: string;
}

export interface IUsersForCategoryRequest {
  Amount: number;
  CategoryID: string;
  CurrentPage: number;
  Filter: any;
}

export interface IAddUsersToContactTypeRequest {
  userIDs: string[];
  contactTypeID: string;
}

export interface IRemoveUsersFromContactTypeRequest {
  userId: string;
  contactTypeId: string;
}

export interface IAddContactDialogData {
  categorySysID: string;
  categoryName: string;
}
export interface IRemoveContactDialogData {
  categorySysID: string;
  categoryName: string;
  userSysID: string;
  username: string;
  type?: string;
}

export enum CompanyTableColumns {
  Category = 'category',
  AddUser = 'addUser',
}

export enum UserTableColumns {
  Name = 'name',
  Email = 'email',
  Role = 'UTimicoPortalRole',
  Remove = 'remove',
}

export interface ICategoryColumn {
  field: string;
  header: string;
  display: any;
  showSortIcon: boolean;
}

enum snowFilter {}

export const categoryColumns: ICategoryColumn[] = [
  {
    field: 'name',
    header: 'Category',
    display: { type: 'text' },
    showSortIcon: true,
  },
];

export interface ITableDataApiResponse<T> {
  overallQueryCount: number;
  result: Array<T>;
}

export interface IUserColumn {
  field: string;
  header?: string;
  default: boolean;
  display?: any;
  filter?: {
    type: string;
    values: { label: string; value: string }[];
  };
  checkBoxVisible?: boolean;
  showSortIcon: boolean;
  showInFilter?: boolean;
}

export const userColumns: TableColumn[] = [
  {
    field: 'name',
    header: 'Name',
    display: { type: 'text' },
    filter: { type: 'text', values: [] },
    showSortIcon: true,
    default: true,
    serviceNowField: UserTableColumns.Name,
    sortColumnOrder: 1,
    sortOrder: TableSort.asc,
  },
  {
    field: 'email',
    header: 'Email Address',
    display: { type: 'text' },
    filter: { type: 'text', values: [] },
    showSortIcon: true,
    default: true,
    serviceNowField: UserTableColumns.Email,
  },
  {
    field: 'uTimicoPortalRole',
    header: 'Role',
    display: { type: 'text' },
    filter: { type: 'text', values: [] },
    showSortIcon: true,
    default: true,
    serviceNowField: UserTableColumns.Role,
  },
];
