<div class="flex flex-column">
  <div
    class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200 border-bottom-1">
    <h1
      class="px-3 text-xl flex justify-content-between align-items-center m-0">
      Service Status
    </h1>
  </div>

  <div
    class="flex-grow-1 overflow-auto mx-3 overflow-hidden bg-white border-solid border-1 border-gray-200 border-top-none pb-3 px-3">
    @if (loading) {
      <loading-spinner />
    }
    @if (!loading) {
      <div class="flex flex-wrap justify-content-around bg-white">
        @for (service of services; track service) {
          <div
            class="text-center align-items-center justify-content-center col-12 md:col-6 lg:col-2">
            @if (services) {
              <service-status-widget [service]="service" />
            }
          </div>
        }
      </div>
    }
  </div>
</div>
