@if (loading) {
  <loading-spinner />
}

@if (!loading) {
  <div class="flex flex-column h-full">
    <div
      class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200">
      <h1 class="px-3 text-xl">Support Articles</h1>
    </div>
    <div
      class="mx-3 bg-white border-solid border-1 border-gray-200 border-top-none">
      <div class="flex-grow-1 overflow-auto mx-3 overflow-hidden bg-white">
        <p-tabView styleClass="tabs-evenly-spaced">
          @for (supportDetail of supportDetails; track supportDetail) {
            <p-tabPanel header="{{ supportDetail.category }}">
              <app-support-category
                [supportService]="supportDetail"></app-support-category>
            </p-tabPanel>
          }
        </p-tabView>
        <div class="flex justify-content-center flex-wrap">
          <h2 class="text-base">
            Still need help? Please
            <span>
              <a class="text-primary font-bold" routerLink="/support/contacts"
                >contact us.</a
              >
            </span>
          </h2>
        </div>
      </div>
    </div>
  </div>
}
