@if (loading) {
  <loading-spinner />
}
@if (!loading) {
  <div class="flex flex-column h-full">
    <h1 class="text-xl px-3">{{ outage?.description }}</h1>
    <hr class="w-full" />
    <div class="px-5 shadow-2">
      <div class="grid">
        <div class="col-12">
          <div class="flex flex-column">
            <span class="text-lg text-gray-600">State</span>
            <p-chip
              [label]="outage?.state"
              [styleClass]="'p-chip-' + getColor(outage?.state)"></p-chip>
            <hr class="w-full" />
          </div>
        </div>
        <div class="col-12">
          <span class="text-lg text-gray-600">Type</span>
          <p class="text-lg m-0">{{ outage?.type }}</p>
          <hr class="w-full" />
        </div>
        <div class="col-12">
          <span class="text-lg text-gray-600">Start Date</span>
          <p class="text-lg m-0">{{ outage?.start }}</p>
          <hr class="w-full" />
        </div>
        <div class="col-12">
          <span class="text-lg text-gray-600">End Date</span>
          <p class="text-lg m-0">{{ outage?.end }}</p>
          <hr class="w-full" />
        </div>
        <div class="col-12">
          <span class="text-lg text-gray-600">Description</span>
          <p class="word-break-break-word white-space-pre-line text-lg m-0">
            {{ outage?.message }}
          </p>
          <hr class="w-full" />
        </div>
      </div>
    </div>
    <div class="pb-5 pt-5 shadow-2">
      <h1 class="text-xl px-3">Updates</h1>
      <hr class="w-full" />
      <div class="flex flex-column h-full px-3">
        <p-table
          #dt1
          [value]="updates$ | async"
          [columns]="tableColumn"
          styleClass="p-datatable-striped"
          [rowHover]="true"
          sortMode="multiple"
          [rows]="25"
          rowExpandMode="single"
          dataKey="content">
          <ng-template pTemplate="header" let-columns>
            <tr>
              @for (col of columns; track col) {
                <th class="text-white bg-gray-800 p-datatable-header-first-row">
                  {{ col.header }}
                </th>
              }
              <th class="bg-gray-800" style="width: 5rem"></th>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="body"
            let-update
            let-columns="columns"
            let-expanded="expanded">
            <tr [pRowToggler]="update" class="cursor-pointer">
              @for (col of columns; track col) {
                <td>
                  @if (col.display?.type === 'text') {
                    {{
                      update[col.field].length >= 22
                        ? update[col.field].substring(0, 22) + '...'
                        : update[col.field]
                    }}
                  }
                  @if (col.display?.type === 'date') {
                    {{ update[col.field] }}
                  }
                </td>
              }
              <td>
                <button
                  type="button"
                  pButton
                  pRipple
                  class="p-button-text p-button-rounded p-button-plain"
                  [icon]="
                    expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
                  "></button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-comment>
            <tr>
              <td colspan="5" class="white-space.pre-wrap word-break-break-all">
                {{ comment?.content }}
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td [attr.colspan]="totalColumns">No results were found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
}
