<p-card
  styleClass="shadow-3 border-pink-500 border-solid border-none border-top-2">
  <div [routerLink]="routerLink" class="flex flex-column cursor-pointer">
    <h2 class="text-center m-0 my-2 text-lg font-bold">{{ title }}</h2>
    <hr class="w-full border-gray-50" />
    <div class="flex p-3">
      @if (statistic?.loading) {
        <loading-spinner
          class="col"
          [padding]="'0 0 0 0'"
        [displayMessage]="false"></loading-spinner>
      }
      @if (!statistic?.loading) {
        <div class="col col-6 justify-content-center align-items-center flex">
          <img [src]="imageSrc" alt="orders icon" />
        </div>
        <div class="col col-6 flex justify-content-center align-items-center">
          <span class="text-6xl font-bold">{{ statistic?.value }}</span>
        </div>
      }
    </div>
  </div>
</p-card>
