@if (loading) {
  <loading-spinner />
}
@if (!loading && !messages?.length) {
  <div class="flex flex-column">
    <div
      class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200 border-bottom-1">
      <h1 class="px-3 text-xl flex justify-content-between align-items-center">
        Order Line Item - {{ orderLineItem?.number }}
        <span class="pl-1">
          <p-chip
            [label]="orderLineItem?.state"
            [styleClass]="'p-chip-' + getColor(orderLineItem?.state)">
          </p-chip>
        </span>
      </h1>
      <div class="pr-2">
        <span
          pTooltip="Adding attachments is not allowed while impersonating"
          tooltipPosition="bottom"
          [tooltipDisabled]="!isImpersonating">
          <p-button
            icon="pi pi-paperclip"
            label="Attachment"
            [disabled]="
              orderLineItem?.state === 'Closed' ||
              orderLineItem?.state === 'Resolved' ||
              isImpersonating ||
              !canUpdateOrderLine
            "
            (onClick)="openFileManager()"
            class="mr-1"></p-button>
        </span>
      </div>
    </div>
    <div
      class="mx-3 overflow-hidden bg-white border-solid border-1 border-gray-200 border-top-none">
      <div class="flex bg-white flex-wrap align-content-start">
        <div class="col-12 md:col-6 flex flex-wrap align-content-start">
          <div
            class="col-12 border-none border-bottom-1 border-solid border-gray-200">
            <h2 class="text-gray-600 text-base m-0 mb-1">Subject</h2>
            <p class="text-lg m-0">
              {{ orderLineItem?.shortDescription }}
            </p>
          </div>
          <div class="col-12 md:col-6 flex flex-wrap pl-0">
            <app-ticket-item-display
              [ngClass]="
                getTicketItemDisplayContainerClasses({
                  border: false,
                })
              "
              [label]="'Request For'"
              [value]="
                orderLineItem?.requestedFor || '&zwnj;'
              "></app-ticket-item-display>
            <app-ticket-item-display
              [ngClass]="
                getTicketItemDisplayContainerClasses({
                  border: true,
                  borderBottom: true,
                })
              "
              [label]="'Required By Date'"
              [value]="
                orderLineItem?.requiredBy || '&zwnj;'
              "></app-ticket-item-display>
          </div>
          <div class="col-12 md:col-6 flex flex-wrap align-content-start">
            <app-ticket-item-display
              [ngClass]="
                getTicketItemDisplayContainerClasses({
                  border: false,
                })
              "
              [label]="'Product Specification'"
              [value]="
                orderLineItem?.productSpecification
              "></app-ticket-item-display>
            <app-ticket-item-display
              [ngClass]="
                getTicketItemDisplayContainerClasses({
                  border: true,
                  borderBottom: true,
                })
              "
              [label]="'Committed Date'"
              [value]="
                orderLineItem?.committedDate || '&zwnj;'
              "></app-ticket-item-display>
          </div>
          <div
            class="col-12 border-none border-bottom-1 border-solid border-gray-200">
            <h2 class="text-gray-600 text-base m-0">Completed Date</h2>
            <p class="text-lg m-0 pt-1 pb-1">
              {{ orderLineItem?.completedDate }}
            </p>
          </div>
        </div>
        <div class="col-12 md:col-6 flex flex-wrap align-content-start">
          <app-ticket-item-display
            [ngClass]="
              getTicketItemDisplayContainerClasses({
                border: false,
              })
            "
            [label]="'Address'"
            [value]="address"></app-ticket-item-display>
          <app-ticket-item-display
            [ngClass]="
              getTicketItemDisplayContainerClasses({
                border: true,
              })
            "
            [label]="'Attachments'"></app-ticket-item-display>
          @for (file of attachmentFiles | slice: 0 : 3; track file) {
            <ng-container class="pt-1">
              <div class="inline-flex">
                <a
                  class="hover:text-primary m-1 pl-1 text-lg text-gray-900 font-bold cursor-pointer"
                  (click)="fileManagerService.downloadFile($event, file)">
                  <i
                    class="text-lg mr-1 pi {{
                      fileManagerService.setFileIconPrimeIcon(file)
                    }}">
                    <span class="ml-1">{{ file.fileName }}</span>
                  </i>
                </a>
              </div>
            </ng-container>
          }
          @if (attachmentFiles?.length > 3) {
            <p-badge
              class="cursor-pointer"
              severity="success"
              (click)="openAttachments()"
              [value]="attachmentFiles?.length - 3"></p-badge>
          }
        </div>
      </div>
    </div>
  </div>
}

@if (!loading && !messages?.length) {
  <div class="mx-3 py-2">
    @if (canViewOrderInfo) {
      <order-internal-information
        [orderNumber]="orderNumber"
        [addressReference]="addressReference"></order-internal-information>
    }
  </div>
}

@if (!loading && !messages?.length) {
  <div class="mx-3 py-1">
    @if (orderSysId) {
      <app-order-characteristics
        [sysId]="orderSysId"></app-order-characteristics>
    }
  </div>
}

@if (!loading && !messages?.length) {
  <div class="pb-5 pt-2">
    @if (comments$) {
      <app-comments
        [comments$]="comments$"
        (addCommentEvent)="addComment($event)"></app-comments>
    }
  </div>
}

@if (messages?.length) {
  <div class="text-center">
    <p-messages [(value)]="messages" />
    <p-button
      label="Return to Orders"
      routerLink="/secure/tickets/orders"></p-button>
  </div>
}
