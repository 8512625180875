import { TableColumn, TableSort } from 'src/app/models/table';

export type LoginHistory = {
  sysUpdatedOn: Date;
  uUser: string;
};

export const LoginHistoryColumns: TableColumn[] = [
  {
    field: 'sysUpdatedOn',
    serviceNowField: 'SysCreatedOn',
    header: 'Login Date',
    display: { type: 'date' },
    filter: { type: 'dateRange', values: [] },
    checkBoxVisible: true,
    showSortIcon: true,
    default: true,
    sortOrder: TableSort.desc,
    sortColumnOrder: 1,
  },
];
