import { FilterMetadata } from 'primeng/api';

export type FilterType =
  | 'text'
  | 'dropdown'
  | 'multiSelect'
  | 'multiSelectMetric'
  | 'date'
  | 'dateRange';

export type TableColumnColor = Record<string, string>;

export interface TableColumnDisplay {
  type: string;
  colors?: TableColumnColor | null;
  showAttributes?: boolean;
}

export enum TableSort {
  asc = 1,
  desc = -1,
}

export interface TableColumn {
  field: string;
  serviceNowField?: string;
  exportField?: string;
  header?: string;
  default: boolean;
  filter?: TableColumnFilter;
  order?: number;
  disabled?: boolean;
  showSortIcon?: boolean;
  display?: TableColumnDisplay;
  sortOrder?: TableSort;
  sortColumnOrder?: number;
  checkBoxVisible?: boolean;
  cell?: (data) => unknown;
  expand?: (data) => unknown;
}

export interface TableColumnFilter {
  type: FilterType;
  values?: { label: string; value: string | boolean }[];
  preset?: FilterMetadata;
  message?: string;
}

export type TableFiltersWithColumns = Record<
  string,
  FilterMetadataWithColumn | FilterMetadataWithColumn[]
>;

export type FilterMetadataWithColumn = FilterMetadata & {
  column?: TableColumn;
};

export type Filters = Record<string, FilterMetadata | FilterMetadata[]>;

export interface ITableClickEvent<T> {
  data: T;
  index: number;
  originalEvent: PointerEvent;
  type: string;
}
