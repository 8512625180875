import { DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-credential-confirm',
  templateUrl: './credential-confirm.component.html',
  styleUrls: ['./credential-confirm.component.scss'],
})
export class CredentialConfirmComponent {
  dialogData: any;
  addBreak: boolean = false;
  constructor(
    private dialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {
    this.dialogData = this.dialogConfig.data;
    this.addHTMLBreak();
  }

  addHTMLBreak(): void {
    if (this.dialogData.content.length < 57) {
      this.addBreak = true;
    }
  }
  submitButton() {
    this.ref.close(true);
  }

  cancelButton(): void {
    this.ref.close(false);
  }
}
