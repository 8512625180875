<div class="w-full p-2">
  @if (pageTitle === AddEditUserPageTitle.ADD) {
    <div class="formgrid grid">
      <div class="field col flex gap-3 justify-content-center">
        <div class="flex align-items-center">
          <p-radioButton
            name="AddEditUserType"
            value="single"
            inputId="AddEditUserSingle"
            [(ngModel)]="selectedAddEditUserType"></p-radioButton>
          <label for="AddEditUserSingle" class="ml-2">Single User</label>
        </div>
        <div class="flex align-items-center">
          <p-radioButton
            (onClick)="onMultipleUserSelect()"
            name="AddEditUserType"
            value="multiple"
            inputId="AddEditUserMultiple"
            [(ngModel)]="selectedAddEditUserType"></p-radioButton>
          <label for="AddEditUserMultiple" class="ml-2">Multiple Users</label>
        </div>
      </div>
    </div>
  }
  <form [formGroup]="addEditUserDetailsForm">
    <div class="formgrid grid">
      <div class="field col">
        <label for="firstName">First Name</label>
        <input
          id="firstName"
          type="text"
          pInputText
          formControlName="firstName"
          aria-label="First Name"
          placeholder="First Name"
          class="w-full" />
        @if (
          addEditUserDetailsForm.get('firstName').invalid &&
          addEditUserDetailsForm.get('firstName').touched
        ) {
          <small class="p-error"> First Name is required. </small>
        }
      </div>
      <div class="field col">
        <label for="lastName">Last Name</label>
        <input
          id="lastName"
          type="text"
          pInputText
          formControlName="lastName"
          aria-label="Last Name"
          placeholder="Last Name"
          class="w-full" />
        @if (
          addEditUserDetailsForm.get('lastName').invalid &&
          addEditUserDetailsForm.get('lastName').touched
        ) {
          <small class="p-error"> Last Name is required. </small>
        }
      </div>
    </div>

    <div class="formgrid grid">
      <div class="field col">
        <label for="email">Email Address</label>
        <input
          id="email"
          type="email"
          pInputText
          formControlName="email"
          aria-label="Email"
          placeholder="Email Address"
          class="w-full" />
        @if (
          addEditUserDetailsForm.get('email').invalid &&
          addEditUserDetailsForm.get('email').touched &&
          addEditUserDetailsForm.get('email').errors
        ) {
          <small class="p-error">
            @if (addEditUserDetailsForm.get('email').errors.required) {
              Email address is required.
            }
            @if (addEditUserDetailsForm.get('email').errors.forbiddenEmail) {
              This email address is already in use.
            }
            @if (addEditUserDetailsForm.get('email').errors.email) {
              Invalid email address.
            }
          </small>
        }
        @if (checkEmailExistsStatus === 'PENDING') {
          <div class="py-2">
            <small class="p-info p-2">
              <i class="pi pi-spin pi-spinner"></i>
              Checking email unique...
            </small>
          </div>
        }
      </div>
    </div>

    <div class="formgrid grid">
      <div class="field col">
        <label for="mobilePhone">Mobile Number</label>
        <input
          id="mobilePhone"
          type="tel"
          pInputText
          formControlName="mobilePhone"
          aria-label="Mobile Number"
          placeholder="Mobile Number"
          class="w-full" />
        @if (
          addEditUserDetailsForm.get('mobilePhone').invalid &&
          addEditUserDetailsForm.get('mobilePhone').touched &&
          addEditUserDetailsForm.get('mobilePhone').errors &&
          addEditUserDetailsForm.get('mobilePhone').errors
            ?.mobileNumberForbidden
        ) {
          <small class="p-error">
            Invalid number. Ensure the number is a valid international number.
          </small>
        }
      </div>
    </div>
    @if (pageTitle === AddEditUserPageTitle.EDIT) {
      <div class="formgrid grid">
        <div class="field col">
          <label for="active">Active</label>
          <p-inputSwitch
            class="ml-2"
            formControlName="active"
            [inputId]="'active'"></p-inputSwitch>
        </div>
      </div>
    }
  </form>
</div>
