<div class="flex flex-column h-full">
  <h1 class="text-xl px-3">Generating ServiceHub Developer Credentials</h1>
  <hr class="w-full" />
  <div class="px-3">
    <h1 class="text-lg font-normal">
      Please provide a friendly name to reference this ServiceHub Developer key.
    </h1>
  </div>
  <div>
    <form [formGroup]="credentialsFormControl">
      <div class="field px-3">
        <label for="name">Name</label>
        <input
          placeholder="Name"
          id="name"
          formControlName="name"
          type="text"
          id="firstName"
          type="text"
          pInputText
          aria-label="name-input"
          class="w-full" />
        @if (
          credentialsFormControl.get('name').invalid &&
          credentialsFormControl.get('name').touched
          ) {
          <small
            class="p-error text-base">
            Friendly Name is required
          </small>
        }
      </div>
    </form>
  </div>
  <div class="flex align-content-center justify-content-center pt-4 pb-4">
    <p-button
      styleClass="p-button-secondary"
      label="Cancel"
    (onClick)="cancelButton()"></p-button>
    <p-button
      styleClass="p-button"
      [disabled]="credentialsFormControl.invalid"
      label="Submit"
    (onClick)="submitButton()"></p-button>
  </div>
</div>
