import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { IUserDetails } from 'src/app/components/service-hub/account/shared/models/account-models';
import { AccountService } from 'src/app/components/service-hub/account/shared/services/account.service';
import { mobileNumberValidator } from 'src/app/helpers/mobile-validator.directive';
import { IDialogResult } from 'src/app/models/dialog-models';
import { ServiceNowValidatorService } from 'src/app/services/existing-service-now-validator.service';

@Component({
  selector: 'app-user-details-dialog',
  templateUrl: './user-details-dialog.component.html',
  styleUrls: ['./user-details-dialog.component.scss'],
})
export class UserDetailsDialogComponent implements OnInit {
  constructor(
    private accountService: AccountService,
    private fb: UntypedFormBuilder,
    private serviceNowValidatorService: ServiceNowValidatorService,
    private dialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {
    this.dialogData = this.dialogConfig.data;
  }

  profile: IUserDetails;
  profileForm: UntypedFormGroup;
  submittingData: boolean = false;
  dialogData: any;
  checkRoleExistsStatus: string = 'VALID';

  ngOnInit(): void {
    this.profile = this.dialogData.profile;
    this.profileForm = this.fb.group({
      firstName: [this.profile.firstName, Validators.required],
      lastName: [this.profile.lastName, Validators.required],
      email: [
        this.profile.email,
        {
          updateOn: 'blur',
          validators: [Validators.required, Validators.email],
          asyncValidators: [
            this.serviceNowValidatorService.emailExists(this.profile.email),
          ],
        },
      ],
      mobilePhone: [
        this.profile.mobilePhone.replace(/\s/g, ''),
        [mobileNumberValidator()],
      ],
      phone: this.profile.phone,
    });
    this.profileForm.get('email').statusChanges.subscribe(status => {
      this.checkRoleExistsStatus = status;
    });
  }

  emailErrorMessage(): string {
    if (
      this.profileForm.get('email').errors &&
      this.profileForm.get('email').errors.required
    ) {
      return 'Email is required.';
    }
    if (
      this.profileForm.get('email').errors &&
      this.profileForm.get('email').errors.email
    ) {
      return 'Email not in correct format.';
    }
    if (
      this.profileForm.get('email').errors &&
      this.profileForm.get('email').errors.forbiddenEmail
    ) {
      return 'This email address is already in use.';
    }
  }

  async updateUserProfile(profile: IUserDetails) {
    await this.accountService.updateUserProfile(profile);
  }

  formValid(form): boolean {
    if (form.status === 'VALID') {
      return true;
    } else {
      return false;
    }
  }

  async onSubmit(profile: IUserDetails): Promise<void> {
    this.submittingData = true;
    await this.updateUserProfile(profile);
    let dialogResult: IDialogResult<IUserDetails> = {
      updateSuccessful: true,
      updatedData: profile,
    };
    this.ref.close(dialogResult);
    this.submittingData = false;
  }

  close(): void {
    this.ref.close();
  }
}
