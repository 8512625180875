import { MenuItem } from 'primeng/api';

export interface ServiceHubMenuItem extends MenuItem {
  permissions?: string[];
  items?: ServiceHubMenuItem[];
  command?: () => void;
}

export const menu: ServiceHubMenuItem[] = [
  {
    label: 'Dashboard',
    icon: 'pi pi-home',
    routerLink: '/secure/dashboard',
    permissions: [],
  },
  {
    label: 'Ticketing',
    permissions: [
      'TICKETS_INCIDENTS_READ',
      'TICKETS_REQUESTS_READ',
      'TICKETS_ORDERS_READ',
      'TICKETS_COMPANY_READ',
    ],
    items: [
      {
        label: 'Raise a Ticket',
        routerLink: '/secure/tickets/raise',
        permissions: ['TICKETS_INCIDENTS_CREATE', 'TICKETS_REQUESTS_CREATE'],
      },
      {
        label: 'Incidents',
        routerLink: '/secure/tickets/incidents',
        permissions: ['TICKETS_INCIDENTS_READ'],
      },
      {
        label: 'Service Requests',
        routerLink: '/secure/tickets/service-requests',
        permissions: ['TICKETS_REQUESTS_READ'],
      },
      {
        label: 'Orders',
        routerLink: '/secure/tickets/orders',
        permissions: ['TICKETS_ORDERS_READ'],
      },
      {
        label: 'Change Requests',
        routerLink: '/secure/tickets/changes',
        permissions: ['TICKETS_CHANGE_READ'],
      },
      {
        label: 'Ticketing Insight',
        routerLink: '/secure/tickets/tickets-reporting',
        permissions: ['VIEW_REPORT'],
      },
    ],
  },
  {
    label: 'Usage & Monitoring',
    permissions: [
      'PRODUCTS_SERVICES_BROADBAND_READ',
      'PRODUCTS_SERVICES_BROADBAND_READ_COMPANY',
      'PRODUCTS_SERVICES_SCIENCE_LOGIC_READ',
      'PRODUCTS_SERVICES_MOBILE_READ',
      'PRODUCTS_SERVICES_DOMAINS_READ',
      'PRODUCTS_SERVICES_SERVICE_STATUS_READ',
      'PRODUCTS_SERVICES_BROADBAND_CHECKER_READ',
    ],
    items: [
      {
        label: 'Service Status',
        routerLink: '/secure/products-and-services/services/status',
        permissions: ['PRODUCTS_SERVICES_SERVICE_STATUS_READ'],
      },
      {
        label: 'Device Utilisation',
        routerLink: '/secure/products-and-services/monitoring/devices/server',
        permissions: ['PRODUCTS_SERVICES_SCIENCE_LOGIC_READ'],
      },
      {
        label: 'Device Insight',
        routerLink:
          '/secure/products-and-services/monitoring/devices/device-insight/last-24-hours',
        permissions: ['DEVICES_GRAPHICAL_READ'],
      },
      {
        label: 'Broadband',
        routerLink: '/secure/products-and-services/broadband',
        permissions: [
          'PRODUCTS_SERVICES_BROADBAND_READ',
          'PRODUCTS_SERVICES_BROADBAND_READ_COMPANY',
        ],
      },
      {
        label: 'Mobile',
        routerLink: '/secure/products-and-services/telephony',
        permissions: ['PRODUCTS_SERVICES_MOBILE_READ'],
      },
      {
        label: 'Domains',
        routerLink: '/secure/products-and-services/domains',
        permissions: ['PRODUCTS_SERVICES_DOMAINS_READ'],
      },
      //{ label: "Broadband Checker", description: "Check broadband status", routerLink: "/secure/products-and-services/broadband-check", permissions: ["PRODUCTS_SERVICES_BROADBAND_CHECKER_READ"], visibility: false },
    ],
  },
  {
    label: 'Billing & Budget',
    // description: "Company invoices, credit notes and usage",
    permissions: [
      'BILLING_INVOICES_READ',
      'BILLING_INVOICE_READ_COMPANY',
      'BILLING_CREDIT_NOTES_READ',
      'BILLING_CREDIT_NOTES_COMPANY_READ',
      'BILLING_USAGE_READ',
    ],
    items: [
      {
        label: 'Billing Overview',
        // description: "Billing summary",
        routerLink: '/secure/billing/overview',
        permissions: ['BILLING_OVERVIEW_READ'],
      },
      {
        label: 'Invoices',
        // description: "View invoices",
        routerLink: '/secure/billing/invoices',
        permissions: ['BILLING_INVOICES_READ', 'BILLING_INVOICE_READ_COMPANY'],
      },

      {
        label: 'Credit Notes',
        // description: "View credit notes",
        routerLink: '/secure/billing/credit-notes',
        permissions: [
          'BILLING_CREDIT_NOTES_READ',
          'BILLING_CREDIT_NOTES_READ_COMPANY',
        ],
      },
      {
        label: 'Connectivity Usage',
        // description: "View usage stats",
        routerLink: '/secure/billing/usage',
        permissions: ['BILLING_USAGE_READ'],
      },
      // { label: "Payment", routerLink: "/secure/billing/payment", permissions: ["BILLING_PAYMENT_READ"] },
    ],
  },
  {
    label: 'Company',
    // description: "User, contact and survey management",
    permissions: ['COMPANY_USERS_READ', 'COMPANY_RAG_READ'],
    items: [
      {
        label: 'Users',
        // description: "View users",
        routerLink: '/secure/company/users',
        permissions: ['COMPANY_USERS_READ'],
      },
      {
        label: 'Roles',
        // description: "View roles",
        routerLink: '/secure/company/roles',
        permissions: ['COMPANY_ROLES_CREATE_UPDATE'],
      },
      {
        label: 'Contact & Authorisation Groups',
        // description: "View contacts",
        routerLink: '/secure/company/contacts',
        permissions: ['AUTHORISATION_TYPES_READ'],
      },
      //{ label: "User Activity", description: "View user activity", routerLink: "/secure/company/company-activity" }
    ],
  },
  {
    label: 'Developer',
    // description: "Developer tools",
    permissions: ['DEVELOPER_CREDENTIALS_READ', 'DEVELOPER_WEB_HOOKS_READ'],
    items: [
      {
        label: 'Credentials',
        // description: "Update credentials",
        routerLink: '/secure/developer/credentials',
        permissions: ['DEVELOPER_CREDENTIALS_READ'],
      },
      {
        label: 'Webhooks',
        // description: "Update web hooks",
        routerLink: '/secure/developer/web-hooks',
        permissions: ['DEVELOPER_WEB_HOOKS_READ'],
      },
    ],
  },
];
