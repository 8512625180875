import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { InvoiceService } from 'src/app/services/api/invoice/invoice.service';
import { ExportService } from 'src/app/services/export.service';

import { ActivatedRoute } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import { DateTime } from 'luxon';
import { LazyLoadEvent } from 'primeng/api';
import { MenuItem } from 'primeng/api/menuitem';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { Paginator, PaginatorState } from 'primeng/paginator';
import { ExportPdfComponent } from 'src/app/components/misc/pop-up/export-pdf/export-pdf.component';
import { BuildServiceNowQuery } from 'src/app/helpers/servicenow/filter/servicenow-filter.helper';
import {
  IsPaginatorState,
  SetSelectedColumns,
  TableReset,
} from 'src/app/helpers/table.helper';
import { Invoice } from 'src/app/models/billing/invoice-models';
import {
  BillingItemTypes,
  ExportPdfRequest,
} from 'src/app/models/export/export-pdf.model';
import { ITableClickEvent, TableColumn } from 'src/app/models/table';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import { ToastService } from 'src/app/services/global/toast.service';
import { InvoiceMultiSelectStateService } from 'src/app/services/invoice-multi-select-state.service';
import { PrimeNGFilterService } from 'src/app/services/prime-ng-filter.service';
import { DownloadInvoiceCreditNoteService } from '../../../../services/download-invoice.service';
import { invoiceColumns } from '../models/invoice-models';

@Component({
  selector: 'invoices',
  templateUrl: 'invoices.component.html',
  styleUrls: ['invoices.component.scss'],
  providers: [DialogService],
})
export class InvoicesComponent implements OnInit {
  @ViewChild('dataTable') dataTable: Table;
  @ViewChild('tablePaginator') paginator: Paginator;
  invoices: Array<any>;
  invoicesSubject = new BehaviorSubject<Array<Invoice>>([]);
  invoices$ = this.invoicesSubject.asObservable();
  selectedInvoice: number = 0;
  filterByDate: Date | undefined;
  totalRecords: number = 0;
  _selectedColumns: TableColumn[] = [];
  defaultSelectedColumns: string[] = [];
  selectedInvoices!: Invoice;
  initialized: boolean = false;
  invoiceNumber: string;
  cols: TableColumn[] = cloneDeep(invoiceColumns);
  staticTable: Table;
  firstLoad: boolean = true;

  get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter(col =>
      val.map(valCol => valCol.header).includes(col.header)
    );
  }

  constructor(
    private exp: ExportService,
    private invoiceService: InvoiceService,
    private invoiceMultiSelectState: InvoiceMultiSelectStateService,
    public primeNGFilterService: PrimeNGFilterService,
    private route: ActivatedRoute,
    private exportInvoice: DownloadInvoiceCreditNoteService,
    private breadcrumbService: BreadcrumbService,
    private toastService: ToastService,
    private dialogService: DialogService
  ) {}
  ngOnInit() {
    const breadcrumbs: MenuItem[] = [
      {
        label: 'Invoices',
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);

    if (this.route.snapshot.queryParams) {
      this.invoiceNumber = this.route.snapshot.queryParams['invoiceNumber'];
    }
  }

  ngAfterViewChecked() {
    if (this.dataTable && this.invoiceNumber) {
      this.runSearchFilter();
    }
  }

  async loadInvoices(
    table: Table,
    event?: LazyLoadEvent | PaginatorState
  ): Promise<void> {
    if (IsPaginatorState(event)) {
      table.rows = event?.rows;
      table.first = event?.first;
    }

    table.loading = true;
    const isFirstLoad = this.firstLoad ? true : false;

    this.invoicesSubject.next([]);

    if (isFirstLoad) {
      this.reset(table, true, cloneDeep(invoiceColumns));
      this.firstLoad = false;
    }

    const currentPage =
      event?.first != null && event?.rows != null
        ? event.first / event.rows
        : table.first / table.rows;
    const currentPerPage = event?.rows ? event.rows : table.rows;

    let serviceNowFilter = BuildServiceNowQuery(
      table,
      this.cols,
      currentPerPage,
      currentPage,
      event
    );

    const response = await firstValueFrom(
      this.invoiceService.getInvoices(serviceNowFilter)
    );
    this.invoices = response.result.map(item => {
      const date = DateTime.fromFormat(item.date, 'dd/MM/yyyy')
        .setZone(DateTime.local().zoneName)
        .toJSDate();
      if (date instanceof Date && !isNaN(date.getTime())) {
        return {
          ...item,
          date: date,
        };
      } else {
        return {
          ...item,
          date: null,
        };
      }
    });

    this.invoicesSubject.next(this.invoices);
    this.totalRecords = response.overallQueryCount;
    this.staticTable = table;
    if (!IsPaginatorState(event)) {
      if (this.paginator) {
        this.paginator.first = 0;
      }
    }
    table.loading = false;
  }

  handleRowClicked(event: ITableClickEvent<Invoice>): void {
    let invoice = event.data;
    const index = this.invoiceMultiSelectState.invoices.findIndex(
      selectedInvoice => selectedInvoice.invoiceNo === invoice.invoiceNo
    );
    if (index === -1) {
      this.invoiceMultiSelectState.invoices.push(invoice);
    } else {
      this.invoiceMultiSelectState.invoices.splice(index, 1);
    }
    this.selectedInvoice = this.invoiceMultiSelectState.invoices.length;
  }

  handleRowUnselect(event: ITableClickEvent<Invoice>): void {
    let invoice = event.data;
    const index = this.invoiceMultiSelectState.invoices.findIndex(
      selectedInvoice => selectedInvoice.invoiceNo === invoice.invoiceNo
    );
    if (index !== -1) {
      this.invoiceMultiSelectState.invoices.splice(index, 1);
    }
    this.selectedInvoice = this.invoiceMultiSelectState.invoices.length;
  }

  selectAllToggle(): void {
    //Clear the old items before adding new stops duplicates
    this.invoiceMultiSelectState.invoices.length = 0;
    this.invoiceMultiSelectState.invoices.push(...this.dataTable.selection);
    this.selectedInvoice = this.invoiceMultiSelectState.invoices.length;
  }

  openExportDialog() {
    const invoicesToExport = cloneDeep(this.invoiceMultiSelectState.invoices);

    const exportInvoicesRequest: ExportPdfRequest = {
      billingItems: invoicesToExport,
      itemType: BillingItemTypes.Invoice,
    };

    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    const ref: DynamicDialogRef = this.dialogService.open(ExportPdfComponent, {
      showHeader: false,
      width: '70%',
      data: exportInvoicesRequest,
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      closeOnEscape: false,
      styleClass: 'p-dialog-custom',
    } as DynamicDialogConfig<ExportPdfRequest>);
  }

  getDatesFromCalendar(selectedDate: object): void {
    if (Array.isArray(selectedDate)) {
      this.primeNGFilterService.filterByDates(this.dataTable, selectedDate);
    } else {
      this.primeNGFilterService.filterByDate(this.dataTable, selectedDate);
    }
  }

  handleColumnChange(val: any[]) {
    this.cols = this.cols.filter(col =>
      val.map(valCol => valCol.header).includes(col.header)
    );
  }

  reset(
    table: Table,
    firstLoad: boolean = false,
    columns: TableColumn[] = invoiceColumns
  ) {
    this._selectedColumns = SetSelectedColumns(
      this.cols,
      columns.filter(column => column?.default).map(column => column?.field)
    );
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
    });
  }

  runSearchFilter() {
    this.dataTable.filter(this.invoiceNumber, 'invoiceNo', 'contains');
    this.invoiceNumber = '';
  }
}
