import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { Paginator, PaginatorState } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { BuildServiceNowQuery } from 'src/app/helpers/servicenow/filter/servicenow-filter.helper';
import {
  IsPaginatorState,
  SetSelectedColumns,
  TableReset,
} from 'src/app/helpers/table.helper';
import { IDownloadFile, ITableToFile } from 'src/app/models/export-models';
import { TableColumn } from 'src/app/models/table';
import {
  ExportFileName,
  ExportService,
  ExportTableEndpoint,
  ExportType,
} from 'src/app/services/export.service';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import { ToastService, ToastType } from 'src/app/services/global/toast.service';
import { ExportTable } from '../science-logic/cmdb-devices/shared/export-devices';
import { broadbandColumns } from './shared/broadband-models';
import { BroadbandService } from 'src/app/services/api/broadband/broadband.service';
import { GetMultipleBroadbandResponse } from 'src/app/models/broadband/broadband.models';
import { ExportName, ExportView } from 'src/app/models/export/export-pdf.model';

@Component({
  selector: 'broadband',
  templateUrl: 'broadband.component.html',
  styleUrls: ['broadband.component.scss'],
})
export class BroadbandComponent implements OnInit {
  @ViewChild('dataTable') dataTable: Table;
  @ViewChild('tablePaginator') paginator: Paginator;
  broadbandSubject = new BehaviorSubject<GetMultipleBroadbandResponse[]>([]);
  broadband$ = this.broadbandSubject.asObservable();
  totalRecords = 0;
  cols: TableColumn[] = cloneDeep(broadbandColumns);
  _selectedColumns: TableColumn[] = [];
  firstLoad = true;
  columns: TableColumn[] = broadbandColumns;

  get selectedColumns(): TableColumn[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: TableColumn[]) {
    this._selectedColumns = this.cols.filter(col =>
      val.map(valCol => valCol.header).includes(col.header)
    );
  }

  exportOptions = [
    {
      label: 'All',
      value: 'all',
      command: () => this.exportAll(),
    },
    {
      label: 'In View',
      value: 'in_view',
      command: () =>
        ExportTable(
          this.dataTable,
          this.broadbandSubject.value,
          this._selectedColumns,
          'all',
          `${ExportName.broadband} - ${ExportView.inView}`
        ),
    },
  ];

  constructor(
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private toastService: ToastService,
    private exportService: ExportService,
    private broadbandService: BroadbandService
  ) {}

  ngOnInit() {
    this.breadcrumbService.clearBreadcrumbs();
    const breadcrumbs: MenuItem[] = [
      {
        label: 'Broadband',
        routerLink: '/secure/products-and-services/broadband',
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
  }

  async loadADSLines(
    table: Table,
    event?: LazyLoadEvent | PaginatorState
  ): Promise<void> {
    if (IsPaginatorState(event)) {
      table.rows = event?.rows;
      table.first = event?.first;
    }
    const isFirstLoad = this.firstLoad ? true : false;
    table.loading = true;
    this.broadbandSubject.next([]);

    if (isFirstLoad) {
      this.reset(table, true, cloneDeep(broadbandColumns));
      this.firstLoad = false;
    }

    const currentPage =
      event?.first != null && event?.rows != null
        ? event.first / event.rows
        : table.first / table.rows;
    const currentPerPage = event?.rows ? event.rows : table.rows;

    const serviceNowFilter = BuildServiceNowQuery(
      table,
      this.cols,
      currentPerPage,
      currentPage,
      event
    );

    const broadbandCount =
      (await firstValueFrom(
        this.broadbandService.getCount(serviceNowFilter)
      )) ?? 0;

    const response = await firstValueFrom(
      this.broadbandService.getMultipleBroadband(serviceNowFilter)
    );

    this.totalRecords = broadbandCount;
    this.broadbandSubject.next(response);
    table.loading = false;
  }

  handleRowClicked(selectedRow): void {
    if (!selectedRow.dslUsername) {
      return;
    }
    this.router.navigateByUrl(
      `/secure/products-and-services/broadband/${selectedRow?.dslUsername}`
    );
  }

  handleColumnChange(val: TableColumn[]) {
    this.cols = this.cols.filter(col =>
      val.map(valCol => valCol.header).includes(col.header)
    );
  }

  defaultExport() {
    ExportTable(
      this.dataTable,
      this.broadbandSubject.value,
      this.cols,
      'all',
      `${ExportName.broadband} - ${ExportView.inView}`
    );
  }

  async exportAll() {
    this.toastService.add({
      severity: ToastType.info,
      summary: 'Downloading',
      detail:
        "We are just sorting your data out for you. This won't take long.",
    });
    const serviceNowFilter = BuildServiceNowQuery(this.dataTable, this.cols);
    const fields = this._selectedColumns.map(item => item.exportField);

    const endpoint: string = ExportTableEndpoint.Broadband;
    const body: ITableToFile = {
      Filter: serviceNowFilter.Filter,
      ExportType: ExportType.Csv,
      CurrentPage: 0,
      Amount: 25,
      Pagination: false,
      ExportColumns: fields,
    };
    const downloadedFile: IDownloadFile =
      await this.exportService.exportTableToFile(endpoint, body);
    this.exportService.downloadFile(
      downloadedFile,
      ExportType.Csv,
      ExportFileName.broadband
    );
  }
  reset(
    table: Table,
    firstLoad = false,
    columns: TableColumn[] = broadbandColumns
  ) {
    this._selectedColumns = SetSelectedColumns(
      this.cols,
      columns.filter(column => column?.default).map(column => column?.field)
    );
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
    });
  }
}
