<div class="flex flex-column h-full">
  <h1 class="text-xl px-3">Service Policies</h1>
  <hr class="w-full" />
  <div class="flex-grow-1 overflow-auto">
    @if (loading) {
      <div>
        <loading-spinner />
      </div>
    }
    <div class="px-3">
      @if (!loading) {
        <p-table
          #dt1
          [value]="policies$ | async"
          [columns]="tableColumn"
          [paginator]="false"
          [rows]="25"
          styleClass="p-datatable-striped"
          [scrollable]="true"
          [tableStyle]="{ 'min-width': '50rem' }"
          [rowHover]="true"
          [rowsPerPageOptions]="[25, 50, 100]"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} service policies">
          <ng-template pTemplate="header" let-columns>
            <tr>
              @for (col of columns; track col) {
                <th
                  class="text-white bg-gray-800 p-datatable-header-first-row"
                  [pSortableColumn]="col.field">
                  {{ col.header }}
                </th>
              }
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-policie>
            <tr class="cursor-pointer" (click)="downloadPolicy(policie)">
              <td>{{ policie }}</td>
            </tr>
          </ng-template>
        </p-table>
      }
    </div>
  </div>
</div>
