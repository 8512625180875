export const IncidentRequestSizeLimit = 1000;

export enum IncidentTabs {
  open = 'Open',
  closed = 'Closed',
}

export const IncidentOpenStateValues = [
  'New',
  'In Progress',
  'Awaiting Info',
  'Resolved',
];

export const IncidentClosedStateValues = ['Closed'];

export interface GetSingleIncidentResponse {
  sysId: string;
  number: string;
  shortDescription: string;
  description: string;
  priority: string;
  state: string;
  callerId: string;
  callerName: string;
  sysCreatedOn?: Date;
  closeNotes: string;
  resolvedBy: string;
  resolvedAt?: Date;
  location: string;
  businessService: string;
  serviceComponent: string;
  symptom: string;
  configurationItem: string;
  businessTimeLeft: string;
  schedule: string;
  cmdbCi: string;
  attributes: Attributes;
}

type Attributes = Record<string, string | number | boolean | Date | null>;

export enum IncidentTableColumnNames {
  number = 'Number',
  description = 'Description',
  shortDescription = 'ShortDescription',
  raisedBy = 'CallerId.name',
  createdOn = 'SysCreatedOn',
  priority = 'Priority',
  openedAt = 'opened',
  state = 'State',
  service = 'ServiceComponent',
  survey = 'SurveyStatus',
  symptom = 'Symptom',
  location = 'Location',
  onHoldReason = 'HoldReason',
  cmdbCi = 'CmdbCi',
  callerID = 'UCallerSysId',
}

export interface GetMultipleIncidentsResponse {
  sysId: string;
  number: string;
  shortDescription: string;
  callerId: string;
  sysCreatedOn?: Date;
  priority: string;
  state: string;
  serviceComponent: string;
  symptom: string;
  surveyId: string;
  location: string;
  holdReason: string;
  attributes: Attributes;
}
