import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';
import { GraphResponse } from 'src/app/models/graphs/pie.chart.models';

@Injectable({
  providedIn: 'root',
})
export class GraphService {
  constructor(private apiRequest: ApiRequest) {}

  GetIncidentsByStateGraph(): Observable<GraphResponse[]> {
    const requestProps: IRequestOptions = {
      endpoint: 'Graph/GetIncidentsByStateGraph',
    };
    return this.apiRequest.request(requestProps);
  }

  GetRequestsByStateGraphTask(): Observable<GraphResponse[]> {
    const requestProps: IRequestOptions = {
      endpoint: 'Graph/GetRequestsByStateGraph',
    };
    return this.apiRequest.request(requestProps);
  }

  GetOrdersByStateGraphTask(): Observable<GraphResponse[]> {
    const requestProps: IRequestOptions = {
      endpoint: 'Graph/GetOrdersByStateGraph',
    };
    return this.apiRequest.request(requestProps);
  }
}
