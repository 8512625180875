@if (showInput) {
  <div class="pb-1 text-left">
    <span class="text-primary text-lg">*</span>
    <label for="Reason" class="text-lg font-bold">Comment</label>
    <form [formGroup]="infoForm">
      <textarea
        pInputText
        id="Comment"
        placeholder="Comment"
        formControlName="infoComment"
        aria-label="comment"
        type="text"
        rows="4"
        class="w-full"></textarea>
      @if (
        infoForm.get('infoComment').invalid &&
        infoForm.get('infoComment').touched
      ) {
        <small class="p-error text-base">Comment is required</small>
      }
    </form>
  </div>
}

<div class="flex align-content-center justify-content-center">
  @switch (ticketState) {
    @case ('Closed') {
      <span 
        class="block"
        pTooltip="It looks like you do not have permissions to raise incidents. Please contact a ServiceHub administrator in your company."
        tooltipPosition="top"
        [tooltipDisabled]="canRaiseIncident"
        appendTo="body">
        <p-button
          styleClass="p-button-secondary"
          label="Raise an incident"
          (onClick)="redirect()"
          [disabled]="!canRaiseIncident">
        </p-button>  
      </span>
      <p-button label="Close" (onClick)="close()"></p-button>
    }
    @case ('Resolved') {
      <p-button label="Close" (onClick)="close()"></p-button>
    }
    @default {
      <p-button
        styleClass="p-button-secondary"
        label="Cancel"
        (onClick)="cancel()"></p-button>
      <p-button
        [disabled]="infoForm.invalid"
        label="Submit"
        (onClick)="submitInformation()"></p-button>
    }
  }
</div>
