<div class="flex flex-column h-full">
  <h1 class="text-xl px-3">Attachment</h1>
  <hr class="w-full" />
</div>
<div class="px-3">
  @if (fileManagerService.currentFiles) {
    @for (file of fileManagerService.currentFiles; track file) {
      <div class="h-2rem block">
        <i
          class="text-lg font-bold pi {{
            fileManagerService.setFileIconPrimeIcon(file)
          }}">
          <span class="pl-2">{{ file.fileName }}</span>
          -
          <a
            href=""
            class="text-primary"
            (click)="fileManagerService.downloadFile($event, file)"
            >Download</a
            >
          </i>
        </div>
      }
    }
  </div>
  <div class="flex align-items-center justify-content-center pb-2">
    <p-button (onClick)="closeDialog()" label="Close" icon="pi pi-times">
    </p-button>
  </div>
