import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  SecurityContext,
} from '@angular/core';
import { EChartsOption } from 'echarts';
import { Router } from '@angular/router';
import { IHoldReason } from 'src/app/components/misc/graph/shared/pie-chart.models';
import { IncidentService } from 'src/app/services/api/incident/incident.service';
import { firstValueFrom } from 'rxjs';
import { GraphResponse } from 'src/app/models/graphs/pie.chart.models';
import { GraphService } from 'src/app/services/api/graph/graph.service.ts.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { IUserProfile } from 'src/app/components/service-hub/company/users/shared/user-models';
import { DomSanitizer } from '@angular/platform-browser';

export enum ChartArea {
  Incidents = 'incidents',
  Orders = 'orders',
  Requests = 'service-requests',
}
@Component({
  selector: 'e-chart-pie',
  templateUrl: './e-chart-pie.component.html',
  styleUrls: ['./e-chart-pie.component.scss'],
})
export class EChartPieComponent implements OnChanges {
  @HostBinding('class') hostClass = 'col-12 md:col-4';
  @Input() chart: string;
  data: GraphResponse[];
  holdInfo: IHoldReason[];
  noData = false;
  newChart: EChartsOption;
  noPermission = false;
  isLoading = true;
  totalCount: number;

  user: IUserProfile;

  filter = [
    {
      Column: 'State',
      Value: ['Awaiting Info'],
      Type: 'dropdown',
      Options: [],
      Visibility: true,
    },
  ];

  graphColorMap = {
    New: '#E31C79',
    'In Progress': '#0E94BB',
    'Awaiting Info': '#532973',
    Open: '#F8C62D',
    Resolved: '#00B0A3',
  };

  constructor(
    private router: Router,
    private incidentService: IncidentService,
    private graphService: GraphService,
    private authService: AuthService,
    private readonly sanitizer: DomSanitizer
  ) {}

  async ngOnChanges() {
    this.user = await this.authService.getUser();
    this.isLoading = true;
    await this.loadChartData();
    this.isLoading = false;
  }

  async loadChartData() {
    if (
      (this.chart === ChartArea.Incidents &&
        !this.authService.hasPermission(this.user, 'TICKETS_INCIDENTS_READ')) ||
      (this.chart === ChartArea.Requests &&
        !this.authService.hasPermission(this.user, 'TICKETS_REQUESTS_READ')) ||
      (this.chart === ChartArea.Orders &&
        !this.authService.hasPermission(this.user, 'TICKETS_ORDERS_READ'))
    ) {
      this.noPermission = true;
      return;
    }
    switch (this.chart) {
      case ChartArea.Incidents:
        await this.loadIncidentsChart();
        break;
      case ChartArea.Requests:
        await this.loadRequestsChart();
        break;
      case ChartArea.Orders:
        await this.loadOrdersChart();
        break;
      default:
        console.warn('Unknown chart type:', this.chart);
    }
  }

  getIncidentUrl(state: string): string {
    return this.sanitizer.sanitize(
      SecurityContext.URL,
      `/secure/tickets/incidents?state=${encodeURIComponent(state)}`
    );
  }

  getRequestUrl(state: string): string {
    return this.sanitizer.sanitize(
      SecurityContext.URL,
      `/secure/tickets/service-requests?state=${encodeURIComponent(state)}`
    );
  }

  getOrderUrl(state: string): string {
    return this.sanitizer.sanitize(
      SecurityContext.URL,
      `/secure/tickets/orders?state=${encodeURIComponent(state)}`
    );
  }

  async incidentsPieChart(): Promise<EChartsOption> {
    const holdInfo = this.holdInfo;

    return (this.newChart = {
      title: {
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
        className: 'pie-chart-tooltip',
        formatter: function (value) {
          const label = value.name;
          const color = value.color;
          const count = value.value;

          if (label.includes('Awaiting Info')) {
            const dataPoints = holdInfo.map(item => {
              return `
                <span style="padding-left: 3px;">
                  ${item.holdReason}: <strong>${item.count}</strong>
                </span>`;
            });
            return `<div style="text-align: left;">
              <span class="awaiting-info" style="margin-right:0px;margin-left:0!important;border-radius:10px;width:9px;height:9px;background-color:${color};"></span>
              ${dataPoints.join('<br/>')}
            </div>`;
          } else {
            return `<span style="border-radius:10px;width:9px;height:9px;background-color:${color};"></span> ${label}: <strong>${count}</strong>`;
          }
        },
      },
      series: [
        {
          data: this.data?.map(item => ({
            value: item.count,
            name: item.state,
            url: this.getIncidentUrl(item.state),
            itemStyle: {
              color: this.graphColorMap[item.state],
            },
          })),
          radius: '60%',
          type: 'pie',
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          label: {
            show: true,
            formatter: '{b} ({c})',
          },
        },
      ],
    });
  }

  requestPieChart(): EChartsOption {
    this.totalCount = this.data?.reduce((sum, item) => sum + item.count, 0);

    return (this.newChart = {
      title: {
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
        className: 'pie-chart-tooltip',
        formatter: function (value) {
          const label = value.name;
          const color = value.color;
          const count = value.value;
          return `<span style="border-radius:10px;width:9px;height:9px;background-color:${color};"></span> ${label}: <strong>${count}</strong>`;
        },
      },
      series: [
        {
          data: this.data?.map(item => ({
            value: item.count,
            name: item.state,
            url: this.getRequestUrl(item.state),
            itemStyle: {
              color: this.graphColorMap[item.state],
            },
          })),
          radius: '60%',
          type: 'pie',
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          label: {
            show: true,
            formatter: '{b} ({c})',
          },
        },
      ],
    });
  }

  ordersPieChart(): EChartsOption {
    this.totalCount = this.data?.reduce((sum, item) => sum + item.count, 0);

    return (this.newChart = {
      title: {
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
        className: 'pie-chart-tooltip',
        formatter: function (value) {
          const label = value.name;
          const color = value.color;
          const count = value.value;
          return `<span style="border-radius:10px;width:9px;height:9px;background-color:${color};"></span> ${label}: <strong>${count}</strong>`;
        },
      },
      series: [
        {
          data: this.data?.map(item => ({
            value: item.count,
            name: item.state,
            url: this.getOrderUrl(item.state),
            itemStyle: {
              color: this.graphColorMap[item.state],
            },
          })),
          radius: '60%',
          type: 'pie',
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          label: {
            show: true,
            formatter: '{b} ({c})',
          },
        },
      ],
    });
  }

  async loadOrdersChart() {
    try {
      const response = await firstValueFrom(
        this.graphService.GetOrdersByStateGraphTask()
      );
      if (!response) {
        this.handleNoData();
        return;
      }
      this.data = response;
      this.totalCount = this.data?.reduce((sum, item) => sum + item.count, 0);
      if (this.totalCount === 0) {
        this.handleNoData();
        return;
      }
      this.ordersPieChart();
    } catch (error) {
      if (error?.status === 403) {
        this.noPermission = true;
      } else {
        this.handleNoData();
      }
    }
  }

  async loadRequestsChart() {
    try {
      const response = await firstValueFrom(
        this.graphService.GetRequestsByStateGraphTask()
      );
      if (!response) {
        this.handleNoData();
        return;
      }
      this.data = response;
      this.totalCount = this.data?.reduce((sum, item) => sum + item.count, 0);
      if (this.totalCount === 0) {
        this.handleNoData();
        return;
      }
      this.requestPieChart();
    } catch (error) {
      if (error?.status === 403) {
        this.noPermission = true;
      } else {
        this.handleNoData();
      }
    }
  }

  async loadIncidentsChart() {
    try {
      const response = await firstValueFrom(
        this.graphService.GetIncidentsByStateGraph()
      );
      if (!response) {
        this.handleNoData();
        return;
      }
      this.data = response;
      this.totalCount = this.data?.reduce((sum, item) => sum + item.count, 0);
      if (this.totalCount === 0) {
        this.handleNoData();
        return;
      }
      const holdInfo = await firstValueFrom(
        this.incidentService.getIncidentHoldReason(this.filter)
      );
      this.holdInfo = holdInfo;
      this.incidentsPieChart();
    } catch (error) {
      if (error?.status === 403) {
        this.noPermission = true;
      } else {
        this.handleNoData();
      }
    }
  }

  handleNoData(): void {
    this.noData = true;
    this.isLoading = false;
  }

  onChartClick(event) {
    const url = event.data.url;
    if (url != null) this.router.navigateByUrl(event.data.url);
  }

  countClickEvent(event: string): void {
    switch (event) {
      case ChartArea.Incidents:
        this.router.navigate(['/secure/tickets/incidents']);
        break;
      case ChartArea.Requests:
        this.router.navigate(['/secure/tickets/service-requests']);
        break;
      case ChartArea.Orders:
        this.router.navigate(['/secure/tickets/orders']);
        break;
      default:
        this.router.navigate(['/secure/dashboard']);
    }
  }
}
