import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { OutageService } from 'src/app/services/api/outage/outage.service';
import { GetOutageResponse } from 'src/app/models/outage/outage.models';

@Component({
  selector: 'dashboard-service-status',
  templateUrl: './dashboard-service-status.component.html',
  styleUrls: ['./dashboard-service-status.component.scss'],
})
export class DashboardServiceStatusComponent implements OnInit {
  services: GetOutageResponse;
  loading = false;
  constructor(private outAgeService: OutageService) {}

  ngOnInit() {
    this.loadServiceStatus();
  }

  async loadServiceStatus() {
    this.loading = true;
    const response = await firstValueFrom(
      this.outAgeService.getOutagesInformation()
    );
    this.services = response;
    this.loading = false;
  }
}
