import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-authorisation-dialog',
  templateUrl: './authorisation-dialog.component.html',
  styleUrls: ['./authorisation-dialog.component.scss'],
})
export class AuthorisationDialogComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  closeDialog(): void {
    //this.dialogRef.close();
  }
}
