<div class="flex flex-column h-full">
  <div
    class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200">
    <h1 class="text-xl px-3">ServiceHub Developer Credentials</h1>
  </div>
  <div
    class="flex-grow-1 overflow-y-auto mx-3 overflow-hidden bg-white border-solid border-1 border-gray-200 border-top-none border-bottom-none px-3">
    @if (loading) {
      <loading-spinner />
    }
    @if (!loading) {
      <p-table
        [value]="credentials$ | async"
        [columns]="tableColums"
        styleClass="p-datatable-striped"
        [rowHover]="true"
        sortMode="multiple"
        [rows]="25"
        scrollHeight="flex"
        [scrollable]="true"
        [rowsPerPageOptions]="[25, 50, 100]"
        [paginator]="true">
        <ng-template pTemplate="caption">
          <p class="m-0 text-xl">
            Generating ServiceHub Credentials will allow for you to access our
            ServiceHub REST API, with the ability to call our specific
            endpoints.
          </p>
          <div class="flex justify-content-end align-content-center">
            <p-button
              label="Add"
              icon="pi pi-plus"
              iconPos="left"
              (onClick)="openNewCredentialsPopUp()"
              styleClass="p-button"
              appendTo="body"></p-button>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            @for (col of columns; track col) {
              <th
                class="text-white bg-gray-800 p-datatable-header-first-row"
                [pSortableColumn]="col.field">
                {{ col.header }}
                @if (col.showSortIcon) {
                  <p-sortIcon
                    class="text-white"
                    [field]="col.field"></p-sortIcon>
                }
              </th>
            }
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-credential>
          <tr>
            <td>{{ credential.friendlyName }}</td>
            <td>{{ credential.lastUsed | date: 'dd/MM/yyyy HH:mm' }}</td>
            <td>{{ credential.created | date: 'dd/MM/yyyy HH:mm' }}</td>
            <td>
              <p-button
                label="Regenerate"
                (click)="regenerateCredential(credential)">
              </p-button>
            </td>
            <td>
              <i
                (keyup.enter)="revokeCredential(credential)"
                role="button"
                tabindex="0"
                class="pi pi-trash hover:text-primary cursor-pointer"
                (click)="revokeCredential(credential)"></i>
            </td>
          </tr>
        </ng-template>
      </p-table>
    }
  </div>
</div>
