import { TableColumn, TableSort } from 'src/app/models/table';

export enum UserTableColumns {
  Name = 'name',
  Email = 'email',
  Role = 'role',
  Remove = 'remove',
}

export interface IUserColumn {
  field: string;
  header?: string;
  default: boolean;
  display?: any;
  filter?: {
    type: string;
    values: { label: string; value: string }[];
  };
  checkBoxVisible?: boolean;
  showSortIcon: boolean;
  showInFilter?: boolean;
}

export const userColumns: TableColumn[] = [
  {
    field: 'name',
    header: 'Name',
    display: { type: 'text' },
    filter: { type: 'text', values: [] },
    showSortIcon: true,
    default: true,
    serviceNowField: UserTableColumns.Name,
    sortColumnOrder: 1,
    sortOrder: TableSort.asc,
  },
  {
    field: 'email',
    header: 'Email Address',
    display: { type: 'text' },
    filter: { type: 'text', values: [] },
    showSortIcon: true,
    default: true,
    serviceNowField: UserTableColumns.Email,
  },
  {
    field: 'role',
    header: 'Role',
    display: { type: 'text' },
    filter: { type: 'text', values: [] },
    showSortIcon: true,
    default: true,
    serviceNowField: UserTableColumns.Role,
  },
];
