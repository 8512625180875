<div class="flex flex-column h-full">
  <div
    class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200">
    <h1 class="px-3 text-xl">Roles</h1>
  </div>
  <div
    class="flex-grow-1 overflow-auto mx-3 overflow-hidden bg-white border-solid border-1 border-gray-200 border-top-none border-bottom-none px-3">
    @if (loading) {
      <loading-spinner />
    }
    @if (!loading) {
      <p-table
        [value]="roles$ | async"
        [columns]="tableColums"
        styleClass="p-datatable-striped"
        [rowHover]="true"
        sortMode="multiple"
        [rows]="25"
        [rowsPerPageOptions]="[25, 50, 100]"
        [paginator]="true"
        scrollHeight="flex"
        [scrollable]="true">
        <ng-template pTemplate="caption">
          <div class="flex justify-content-end align-content-center">
            <p-button
              label="Add"
              icon="pi pi-plus"
              iconPos="left"
              (onClick)="openAddRole()"
              styleClass="p-button"
              appendTo="body"></p-button>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            @for (col of columns; track col) {
              <th
                class="text-white bg-gray-800 p-datatable-header-first-row"
                [pSortableColumn]="col.field">
                {{ col.header }}
                @if (col.showSortIcon) {
                  <p-sortIcon
                    class="text-white"
                    [field]="col.field"></p-sortIcon>
                }
              </th>
            }
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-role>
          <tr class="cursor-pointer" (click)="viewRole(role)">
            <td>{{ role.name }}</td>
            <td>{{ role.description }}</td>
            @if (showEditColumn && !role.default) {
              <td>
                <p-button label="Edit" (click)="editRoleClicked(role)">
                </p-button>
              </td>
            } @else {
              <td class="no-button-row"></td>
              <!--No button row for default permission class is added to fix row hight when no button is showing-->
            }
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [attr.colspan]="tableColums">No results were found.</td>
          </tr>
        </ng-template>
      </p-table>
    }
  </div>
  <br />
</div>
