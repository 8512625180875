<div class="flex flex-column h-full">
  <div
    class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200">
    <h1 class="px-3 text-xl">Incidents</h1>
    @if (hasCompanyToggle) {
      <div class="pr-3">
        <p-selectButton
          [options]="stateOptions"
          [(ngModel)]="companyToggle"
          (ngModelChange)="onToggleChange(dataTable)"
          [unselectable]="true"
          optionValue="value"
          [styleClass]="selectbutton"
          class="selectbutton" />
      </div>
    }
  </div>
  <div
    class="mx-3 bg-white border-solid border-1 border-gray-200 border-top-none border-bottom-none">
    <digital-space-tab-menu
      [tabs]="tabs"
      [activeTab]="activeTab"
      [disabled]="dataTable?.loading ? true : false"
      (tabChange)="tabChange($event)"></digital-space-tab-menu>
  </div>
  <div
    class="flex-grow-1 overflow-auto mx-3 overflow-hidden bg-white border-solid border-1 border-gray-200 border-top-none border-bottom-none px-3">
    <p-table
      #dataTable
      class="p-datatable-equal-width"
      [value]="incidents$ | async"
      [columns]="selectedColumns"
      styleClass="p-datatable-striped"
      [rowHover]="true"
      sortMode="single"
      [rows]="25"
      [paginator]="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{first}-{last} of {totalRecords}"
      [rowsPerPageOptions]="[25, 50, 100]"
      scrollHeight="flex"
      [scrollable]="true">
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between">
          <div class="flex">
            @if (selectedColumns && dataTable && dataTable.loading === false) {
              <prime-filter-dropdown
                [selectedColumns]="cols"
                [table]="dataTable"
                (filterChange)="onFiltersChanged($event)">
              </prime-filter-dropdown>
            }
            <p-multiSelect
              styleClass="p-multiselect-label-hide p-multiselect-border"
              [options]="cols"
              [(ngModel)]="selectedColumns"
              optionLabel="header"
              dropdownIcon="pi pi-sliders-h"
              placeholder="Choose Columns"
              selectedItemsLabel="{0} columns selected"
              (ngModelChange)="handleColumnChange(cols)"
              optionDisabled="disabled"></p-multiSelect>
            <p-button
              label="Reset"
              styleClass="p-button p-button-text p-button-secondary"
              icon="pi pi-eraser"
              (click)="resetTable(dataTable)"></p-button>
          </div>
          <p-splitButton
            label="Export"
            icon="pi pi-download"
            iconPos="left"
            [model]="exportOptions"
            styleClass="p-button"
            appendTo="body"
            (onClick)="defaultExport()"></p-splitButton>
        </div>
      </ng-template>

      <ng-template pTemplate="header" let-columns>
        <tr>
          @for (col of columns; track col) {
            <th
              class="text-white bg-gray-800 p-datatable-header-first-row"
              [pSortableColumn]="col.field">
              {{ col.header }}
              @if (col.showSortIcon) {
                <p-sortIcon class="text-white" [field]="col.field"></p-sortIcon>
              }
            </th>
          }
        </tr>
      </ng-template>

      <ng-template pTemplate="loadingbody">
        <tr>
          <td [attr.colspan]="selectedColumns?.length + 1">
            <loading-spinner></loading-spinner>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingicon"></ng-template>

      <ng-template pTemplate="body" let-incident let-columns="columns">
        <tr class="cursor-pointer" (click)="handleRowClicked(incident)">
          @for (col of columns; track col) {
            <td>
              @if (col.display?.type === 'text') {
                {{ incident[col.field] }}
                @if (col.display?.showAttributes) {
                  @for (
                    attributeChip of incident.attributeChips;
                    track attributeChip
                  ) {
                    <p-chip
                      [label]="attributeChip.label"
                      [styleClass]="attributeChip.class"></p-chip>
                  }
                }
              }
              @if (col.display?.type === 'date') {
                {{ incident[col.field] | date: 'dd/MM/yyyy HH:ss' }}
              }
              @if (col.display.type === 'chip') {
                <div class="text-center">
                  <p-chip
                    [label]="incident[col.field]"
                    [styleClass]="
                      getColor(col.display.colors, incident[col.field])
                        ? 'p-chip-' +
                          getColor(col.display.colors, incident[col.field]) +
                          ' block'
                        : 'block'
                    "></p-chip>
                </div>
              }
            </td>
          }
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="selectedColumns?.length + 1">
            No results were found.
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
