<div class="flex flex-column">
  <div class="flex-grow-1">
    <app-add-edit-user-permissions
      [selectedRole]="role"
      [addEditUserPermissionsForm]="
        permissionsForm
      "></app-add-edit-user-permissions>
  </div>
  <div class="flex align-items-center justify-content-center pt-3">
    <p-button
      (onClick)="close()"
      styleClass="p-button-secondary"
      label="Cancel"></p-button>
    <p-button
      styleClass="p-button"
      label="Save"
      (onClick)="submit()"></p-button>
  </div>
</div>
