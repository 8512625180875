import { TableColumn, TableSort } from 'src/app/models/table';

export interface ICrudHistory {
  sysUpdatedOn: Date;
  uUser: string;
}

export const CrudColumns: TableColumn[] = [
  {
    field: 'sysCreatedOn',
    serviceNowField: 'SysCreatedOn',
    header: 'Log Date',
    display: { type: 'date' },
    filter: { type: 'dateRange', values: [] },
    checkBoxVisible: true,
    showSortIcon: true,
    default: true,
    sortOrder: TableSort.desc,
    sortColumnOrder: 1,
  },
  {
    field: 'uOperation',
    serviceNowField: 'UOperation',
    header: 'Operation',
    display: { type: 'text' },
    filter: {
      type: 'multiSelect',
      values: [
        { label: 'Create', value: 'Create' },
        { label: 'Read', value: 'Read' },
        { label: 'Delete', value: 'Delete' },
      ],
    },
    showSortIcon: false,
    default: true,
  },
  {
    field: 'uMessage',
    serviceNowField: 'UMessage',
    header: 'Message',
    display: { type: 'text' },
    checkBoxVisible: true,
    showSortIcon: false,
    default: true,
  },
  {
    field: 'uTask.Number',
    serviceNowField: 'UTask.Number',
    header: 'Record',
    display: { type: 'text' },
    checkBoxVisible: true,
    showSortIcon: false,
    default: true,
  },
  {
    field: 'uImpersonating',
    serviceNowField: 'uImpersonating',
    header: 'Impersonating',
    display: { type: 'text' },
    filter: null,
    checkBoxVisible: true,
    showSortIcon: false,
    default: true,
  },
  {
    field: 'uImpersonator',
    serviceNowField: 'uImpersonator',
    header: 'Impersonated By',
    display: { type: 'text' },
    filter: null,
    checkBoxVisible: true,
    showSortIcon: false,
    default: true,
  },
];
