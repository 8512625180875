import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Table } from 'primeng/table';
import { ExportData } from '../../../../../../models/export/export-pdf.model';
import { IIncidentPriority } from 'src/app/components/service-hub/tickets/tickets-reporting/shared/tickets-reporting.model';

const extractSelectedColumns = (visibleData: any[], selectedColumns): any[] => {
  let rows = [];
  for (const row of visibleData) {
    const extractedRows = [];
    const extractedRow = {};
    for (const selectedColumn of selectedColumns) {
      extractedRow[selectedColumn.header] = row[selectedColumn.field];
    }
    for (const key in extractedRow) {
      if (Array.isArray(extractedRow[key])) {
        for (const multiValueRow of extractedRow[key]) {
          extractedRows.push({ ...extractedRow, [key]: multiValueRow });
        }
      }
    }
    if (extractedRows.length === 0) {
      extractedRows.push(extractedRow);
    }
    rows.push(...extractedRows);
  }
  return rows;
};

export const ExportTable = (
  table: Table,
  items,
  selectedColumns,
  type: string = 'all',
  label?: string
) => {
  let data = type === 'all' ? items : table.filteredValue || items;
  if (type !== 'all') {
    const first = table.first;
    const rows = table.rows;
    data = data.slice(first, first + rows);
  }
  const headers = selectedColumns.map(col => col.header);

  const extractedData = extractSelectedColumns(data, selectedColumns);
  const worksheet = XLSX.utils.json_to_sheet(extractedData, {
    header: headers,
  });
  const workbook: XLSX.WorkBook = {
    Sheets: { data: worksheet },
    SheetNames: ['data'],
  };
  const csvData = XLSX.write(workbook, { bookType: 'csv', type: 'string' });
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  //Can be removed after all page export file names are updated
  saveAs(blob, `${label ? label : 'exported_data'}.csv`);
};

export const GetExportItemsFromFullArray = (array, first, rows) => {
  // Calculate end index
  const endIndex = first + rows;
  // Slice the array to get the required items
  return array.slice(first, endIndex);
};

export const ExportGraphData = (
  data: ExportData | IIncidentPriority[],
  columns: string[]
) => {
  const items = Array.isArray(data) ? data : data.data;
  const label = !Array.isArray(data)
    ? data.label + ' by month'
    : 'incidents by priority';

  const formattedColumns = columns.map(
    col => col.charAt(0).toUpperCase() + col.slice(1).toLowerCase()
  );

  const extractedData = items.map(item =>
    Object.fromEntries(
      columns.map((field, index) => [formattedColumns[index], item[field]])
    )
  );

  const workbook = {
    Sheets: {
      data: XLSX.utils.json_to_sheet(extractedData),
    },
    SheetNames: ['data'],
  };

  saveAs(
    new Blob([XLSX.write(workbook, { bookType: 'csv', type: 'string' })], {
      type: 'text/csv;charset=utf-8;',
    }),
    `${label}_exported_data.csv`
  );
};
