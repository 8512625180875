import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ManageRoute53HostedzoneComponent } from 'src/app/components/misc/pop-up/manage-route53-hostedzone/manage-route53-hostedzone.component';
import { PermissionService } from 'src/app/services/permissions.service';
import { DomainAdminEvent, RecordsSource } from '../domain/domain.component';
import { ToastService } from 'src/app/services/global/toast.service';
import { DomainService } from 'src/app/services/api/domain/domain.service';

@Component({
  selector: 'domain-admin-view',
  templateUrl: './domain-admin.component.html',
  styleUrls: ['./domain-admin.component.scss'],
  providers: [DialogService],
})
export class DomainAdminComponent implements OnInit {
  @Input() domainName: string;
  @Input() company: string;
  @Input() domainStatus: {
    name: string;
    suspendedStatus: string;
    zoneId: string;
  } | null;
  @Input() domainSysId: string;
  @Input() recordsSource: RecordsSource;
  @Output() domainAdminEvent: EventEmitter<DomainAdminEvent> =
    new EventEmitter<DomainAdminEvent>();
  ref: DynamicDialogRef | undefined;
  isDsAdmin: boolean = false;

  constructor(
    public dialogService: DialogService,
    private permissionService: PermissionService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isDsAdmin = !this.permissionService.checkPermission(
      'INTERNAL_DNS_ADMIN'
    )
      ? false
      : true;
  }

  async createHostedZone() {
    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    this.ref = this.dialogService.open(ManageRoute53HostedzoneComponent, {
      showHeader: false,
      width: '40%',
      data: {
        domainName: this.domainName,
        domainSysId: this.domainSysId,
        title: 'Add Hosted Zone',
        content: `Are you sure you want to create a hosted zone for ${this.domainName}?`,
        action: DomainAdminEvent.HostedZoneAdd,
      },
      styleClass: 'p-dialog-custom',
    });
    this.ref.onClose.subscribe(async result => {
      if (result) {
        this.domainAdminEvent.emit(DomainAdminEvent.HostedZoneAdd);
      }
    });
  }

  async removeHostedZone() {
    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    this.ref = this.dialogService.open(ManageRoute53HostedzoneComponent, {
      showHeader: false,
      width: '40%',
      data: {
        domainName: this.domainName,
        domainSysId: this.domainSysId,
        title: 'Remove Hosted Zone',
        content: 'Are you sure you want to remove this hosted zone?',
        action: DomainAdminEvent.HostedZoneDeleted,
      },
      styleClass: 'p-dialog-custom',
    });
    this.ref.onClose.subscribe(async result => {
      if (result) {
        this.domainAdminEvent.emit(DomainAdminEvent.HostedZoneDeleted);
      }
    });
  }

  async suspendHostedZone() {
    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    this.ref = this.dialogService.open(ManageRoute53HostedzoneComponent, {
      showHeader: false,
      width: '40%',
      data: {
        domainName: this.domainName,
        domainSysId: this.domainSysId,
        content: 'Are you sure you want to suspend this hosted zone?',
        action: DomainAdminEvent.HostedZoneSuspend,
      },
      styleClass: 'p-dialog-custom',
    });
    this.ref.onClose.subscribe(async result => {
      if (result) {
        this.domainAdminEvent.emit(DomainAdminEvent.HostedZoneSuspend);
      }
    });
  }

  async resumeHostedZone() {
    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    this.ref = this.dialogService.open(ManageRoute53HostedzoneComponent, {
      showHeader: false,
      width: '40%',
      data: {
        domainName: this.domainName,
        domainSysId: this.domainSysId,
        content: 'You are about to resume this hosted zone?',
        contentBody: '',
        action: DomainAdminEvent.HostedZoneResume,
      },
      styleClass: 'p-dialog-custom',
    });
    this.ref.onClose.subscribe(async result => {
      if (result) {
        this.domainAdminEvent.emit(DomainAdminEvent.HostedZoneResume);
      }
    });
  }
}
