@if (!loading) {
  <div class="flex flex-column h-full">
    <h1 class="text-xl px-3">{{ method }} DNS Record</h1>
    <hr class="w-full" />
  </div>
  <div class="pl-3">
    <h2 class="text-xl">
      Please provide the information below for the DNS Record.
    </h2>
  </div>
  <div class="pl-3 pr-3">
    <p-messages
      [(value)]="errorMessage"
      [enableService]="false"
      [closable]="true"
      [escape]="false"></p-messages>
    @if (route53RecordConflict) {
      <p-messages severity="warn">
        <ng-template pTemplate>
          <div>
            Duplicate record found,
            <a
              class="font-bold cursor-pointer"
              (click)="setEditRecord(route53RecordConflict)"
              >click here</a
            >
            to append this to the existing record and begin editing.
            <br />
            <strong>Note: TTL for the existing record will be used</strong>
          </div>
        </ng-template>
      </p-messages>
    }
    <form [formGroup]="addDNSFormControl">
      <div class="field">
        <label for="name">Name</label>
        <input
          placeholder="Name"
          id="name"
          formControlName="name"
          type="text"
          pInputText
          aria-label="Name"
          class="w-full" />
        <small class="block"
          >Enter "&#64;" to create a record for the root domain</small
        >
        @if (
          addDNSFormControl.get('name').hasError('required') &&
          addDNSFormControl.get('name').touched
        ) {
          <small class="p-error text-base"> Name is required </small>
        }
      </div>
      <div class="field">
        <label for="ttl">TTL</label>
        @if (recordsSource === 'route53') {
          <div>
            <p-inputNumber
              inputId="ttl"
              formControlName="ttl"
              [useGrouping]="false">
            </p-inputNumber>
            @for (ttl of ttlList; track ttl) {
              <button
                pButton
                class="p-button-text p-button-sm p-button-secondary m-0 text-center"
                (click)="setTtl(ttl.value)">
                {{ ttl.buttonLabel }}
              </button>
            }
          </div>
        }
        <small>TTL in seconds</small>
        @if (
          addDNSFormControl.get('ttl').hasError('required') &&
          addDNSFormControl.get('ttl').touched
        ) {
          <small class="p-error text-base"> TTL is required </small>
        }
      </div>
      <div class="field">
        <label for="Type">Type</label>
        <br />
        <p-dropdown
          formControlName="type"
          [options]="typeList"
          [style]="{ width: '100%' }"
          placeholder="Select"
          appendTo="body"></p-dropdown>
        @if (
          addDNSFormControl.get('type').hasError('required') &&
          addDNSFormControl.get('type').touched
        ) {
          <small class="p-error text-base"> Type is required </small>
        }
      </div>
      <div class="field">
        <div class="field">
          <label for="value">Value</label>
          @if (recordsSource === 'route53') {
            @if (recordsSource === 'route53') {
              <textarea
                id="value"
                rows="5"
                pInputTextarea
                formControlName="value"
                aria-label="value"
                placeholder="Value"
                class="w-full p-2"></textarea>
            }
            <small class="block">Enter multiple values on separate lines</small>
          }
          @if (
            addDNSFormControl.get('value').hasError('required') &&
            addDNSFormControl.get('value').touched
          ) {
            <small class="p-error text-base">Value is required</small>
          }
        </div>
      </div>
    </form>
    <div class="flex align-items-center justify-content-center pb-2">
      <p-button
        (onClick)="close()"
        styleClass="p-button-secondary"
        label="Cancel"></p-button>
      <p-button
        [disabled]="addDNSFormControl.invalid"
        styleClass="p-button"
        label="Submit"
        (onClick)="
          submit(method, record, domainName, existingRecords)
        "></p-button>
    </div>
  </div>
}
@if (loading) {
  <loading-spinner />
}
