<h2>Add User Review</h2>
<div class="border border-1 border-gray-500 p-3 max-h-16rem overflow-auto">
  <div class="row">
    <div class="col">
      <strong>First Name:</strong> {{ review.details.firstName }}
    </div>
    <div class="col">
      <strong>Last Name:</strong> {{ review.details.lastName }}
    </div>
  </div>
  <div class="row">
    <div class="col"><strong>Email:</strong> {{ review.details.email }}</div>
  </div>
  <div class="row">
    <div class="col">
      <strong>Mobile:</strong> {{ review.details.mobilePhone }}
    </div>
  </div>
  @if (pageTitle === AddEditUserPageTitle.EDIT) {
    <div class="row">
      <div class="col">
        <strong>Active:</strong> {{ review.details.active }}
      </div>
    </div>
  }
</div>

@if (review.selectRole?.role) {
  <div
    class="border border-1 border-gray-500 p-3 mt-3 max-h-16rem overflow-auto">
    <div class="row">
      <div class="col">
        <strong>Selected Role:</strong>
        {{ review.selectRole.role.name }}
      </div>
    </div>
    @if (review.permissions?.length) {
      <ng-container
        class="border border-1 border-gray-500 p-3 mt-3 max-h-16rem overflow-auto">
        <div class="row">
          <div class="col">
            <h4>Permissions</h4>
          </div>
        </div>
        @for (category of review.permissions; track category) {
          <div class="row">
            <div class="col">
              <h5>{{ category.name }}</h5>
              <ul class="grid">
                @for (permission of category.permissions; track permission) {
                  <li class="col-3">
                    {{ permission.label }} <br />
                    <small class="font-bold"
                      >{{
                        permission.rolePermission
                          ? review.selectRole.role.name
                          : 'Optional'
                      }}
                      Permission</small
                    >
                  </li>
                }
              </ul>
            </div>
          </div>
        }
      </ng-container>
    }
  </div>
}

@if (review.additional.contactTypes?.length) {
  <div
    class="border border-1 border-gray-500 p-3 mt-3 max-h-16rem overflow-auto">
    <div class="row">
      <div class="col">
        <h4>Contact Types</h4>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ul class="grid">
          @for (
            contactType of review.additional.contactTypes;
            track contactType
          ) {
            <li class="col-3">
              {{ contactType?.name }}
            </li>
          }
        </ul>
      </div>
    </div>
  </div>
}

@if (review.additional.authorisationTypes?.length) {
  <div
    class="border border-1 border-gray-500 p-3 mt-3 max-h-16rem overflow-auto">
    <div class="row">
      <div class="col">
        <h4>Authorisation Types</h4>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ul class="grid">
          @for (
            authorisationType of review.additional.authorisationTypes;
            track authorisationType
          ) {
            <li class="col-3">
              {{ authorisationType.name }}
            </li>
          }
        </ul>
      </div>
    </div>
  </div>
}

@if (review.additional.billingEntities?.length) {
  <div
    class="border border-1 border-gray-500 p-3 mt-3 max-h-16rem overflow-auto">
    <div class="row">
      <div class="col">
        <h4>Invoicing Accounts</h4>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ul class="grid">
          @for (
            billingEntity of review.additional.billingEntities;
            track billingEntity
          ) {
            <li class="col-3">
              {{ billingEntity.uName }}
            </li>
          }
        </ul>
      </div>
    </div>
  </div>
}
