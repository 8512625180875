@if (submittingData) {
  <div class="w-20rem">
    <loading-spinner />
  </div>
}

@if (!submittingData) {
  <div class="flex flex-column h-full">
    <h1 class="text-xl px-3">Are you sure?</h1>
    <hr class="w-full" />
    <div class="mx-3 text-lg">
      <p class="m-0 mt-1 mb-1">
        You are about to remove
        <span class="text-primary font-bold">{{ data.username }}</span>
        from the
        <span class="text-primary font-bold">{{ data.categoryName }}</span>
        {{ contactType }} group. <br />
        Do you wish to continue?
      </p>
    </div>
    <div class="flex align-items-center justify-content-center pb-3">
      <p-button
        styleClass="p-button-secondary"
        label="No"
      (onClick)="close()"></p-button>
      <p-button
        styleClass="p-button"
        label="Yes"
      (onClick)="submitClicked()"></p-button>
    </div>
  </div>
}
