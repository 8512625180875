<div class="flex flex-column h-full">
  <h1 class="text-xl px-3">Amend Cost Centre</h1>
  <hr class="w-full" />
  <div class="px-3">
    <p-table
      #dataTable
      [value]="costCentres$ | async"
      (onLazyLoad)="getCostCentres(dataTable, $event)"
      [columns]="selectedColumns"
      styleClass="p-datatable-striped"
      [rowHover]="true"
      sortMode="single"
      [rows]="25"
      [lazy]="true"
      [(selection)]="selectedCostCentre"
      selectionMode="single"
      [scrollable]="true"
      scrollHeight="600px">
      <ng-template pTemplate="caption">
        <div class="flex justify-content-end align-content-center">
          <div class="flex-grow-1">
            @if (
              selectedColumns && dataTable && dataTable.loading === false
              ) {
              <prime-filter-dropdown
                [selectedColumns]="cols"
              [table]="dataTable"></prime-filter-dropdown>
            }
          </div>
          <p-button
            label="Reset"
            styleClass="p-button p-button-text p-button-secondary"
            icon="pi pi-filter-slash"
          (click)="reset(dataTable, false)"></p-button>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          @for (col of columns; track col) {
            <th
              class="text-white bg-gray-800 p-datatable-header-first-row"
              [pSortableColumn]="col.field">
              {{ col.header }}
              @if (col.showSortIcon) {
                <p-sortIcon
                  class="text-white"
                [field]="col.field"></p-sortIcon>
              }
            </th>
          }
        </tr>
      </ng-template>

      <ng-template pTemplate="loadingbody">
        <tr>
          <td [attr.colspan]="selectedColumns?.length + 1">
            <loading-spinner></loading-spinner>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingicon"></ng-template>

      <ng-template pTemplate="body" let-centre let-columns="columns">
        <tr [pSelectableRow]="centre" class="cursor-pointer">
          @for (col of columns; track col) {
            <td>
              @if (col.display?.type === 'text') {
                {{ centre[col.field] }}
              }
            </td>
          }
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="selectedColumns?.length + 1">
            No results were found.
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="footer">
        <tr>
          <td [attr.colspan]="selectedColumns.length + 1">
            <div class="flex align-items-center justify-content-center">
              <p-paginator
                #tablePaginator
                (onPageChange)="getCostCentres(dataTable, $event)"
                [first]="dataTable.first"
                [rows]="dataTable.rows"
                [totalRecords]="totalRecords"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} - {last} of {totalRecords}"
                [rowsPerPageOptions]="[25, 50, 100]"
              dropdownAppendTo="body"></p-paginator>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="flex align-content-center justify-content-center py-3">
    <p-button
      styleClass="p-button-secondary"
      label="Cancel"
    (onClick)="closeDialog()"></p-button>
    <p-button
      styleClass="p-button"
      label="Confirm"
    (onClick)="confirmButton()"></p-button>
  </div>
</div>
