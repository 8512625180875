@if (!loading) {
  @if (!loading) {
    <div class="flex">
      <div class="col-4 p-0 mr-2">
        <h2 class="text-xl">
          Default Permissions for Role - {{ selectedRole.name }}
        </h2>
      </div>
      <div class="col-8 p-0 ml-2">
        <h2 class="text-xl">Optional Permissions</h2>
      </div>
    </div>
  }
  <div class="flex">
    <div class="col-4 p-0 max-h-30rem overflow-auto pr-2">
      <div class="border-1 border-gray-500 p-3">
        @for (
          category of permissionOptions.selectedRolePermissionCategories;
          track category
        ) {
          <h3>{{ category.name | titlecase | replaceUnderscore }}</h3>
          @for (permission of category.permissions; track permission) {
            <ul>
              <li>{{ permission.uLabel }} {{ permission.description }}</li>
            </ul>
          }
        }
      </div>
    </div>
    <div class="col-8 p-0 max-h-30rem overflow-auto pl-2">
      <div class="border-1 border-gray-500 p-3">
        @for (
          category of permissionOptions.additionalPermissionCategories;
          track category
        ) {
          <div class="flex align-content-center flex-wrap mb-3">
            <h3 class="m-0">
              {{ category.name | titlecase | replaceUnderscore }}
            </h3>
            <button
              pButton
              (click)="toggleSelectCategoryPermissions(category.permissions)"
              class="p-button-sm p-button-outlined ml-2">
              {{
                allCategoryPermissionsSelected(category.permissions)
                  ? 'Deselect All'
                  : 'Select All'
              }}
            </button>
          </div>
          <div class="grid mb-3">
            @for (permission of category.permissions; track permission) {
              <div class="col-6">
                <div class="flex">
                  <p-checkbox
                    [value]="permission"
                    [inputId]="permission.label"
                    [(ngModel)]="selectedAdditionalPermissions"
                    (click)="onPermissionsChange()"></p-checkbox>
                  <label class="ml-2" [for]="permission.label"
                    >{{ permission.label }}<br />{{
                      permission.description
                    }}</label
                  >
                </div>
              </div>
            }
          </div>
        }
        @if (permissionOptions.additionalPermissionCategories.length === 0) {
          <div class="text-center">
            <p>No additional permissions available</p>
          </div>
        }
      </div>
    </div>
  </div>
}

@if (loading) {
  <loading-spinner></loading-spinner>
}
