import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';
import { SupportDetailsResponse } from 'src/app/models/support/support-articles.models';

@Injectable({
  providedIn: 'root',
})
export class HelpArticleService {
  constructor(private apiRequest: ApiRequest) {}

  getSupportCategoryContent(filter: any): Observable<SupportDetailsResponse> {
    // TODO: check typo and fix if needed
    const requestProps: IRequestOptions = {
      endpoint: 'HelpArticle/GetSupportCategoryContent',
      body: {
        Filter: filter,
      },
    };
    return this.apiRequest.request(requestProps);
  }
}
