import { Component, Input } from '@angular/core';
import { DashBoardStatistic } from 'src/app/components/service-hub/dashboard/dashboard.model';

@Component({
  selector: 'app-dashboard-statistic-card',
  templateUrl: './dashboard-statistic-card.component.html',
  styleUrl: './dashboard-statistic-card.component.scss',
})
export class DashboardStatisticCardComponent {
  @Input() title: string;
  @Input() routerLink: string;
  @Input() statistic: DashBoardStatistic;
  @Input() imageSrc: string;
}
