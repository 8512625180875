import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { firstValueFrom, Observable, Subject, takeUntil } from 'rxjs';
import { TableColumnColor } from 'src/app/models/table';
import { ToastService, ToastType } from 'src/app/services/global/toast.service';
import { AddCommentComponent } from '../pop-up/add-comment/add-comment.component';
import { CommentColumns, ticketData } from '../shared/comments.model';

export enum CommentTypes {
  Incident = 'incident',
  RequestedItem = 'requestedItem',
  Case = 'case',
  Order = 'order',
  OrderLine = 'orderLine',
}
@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
  providers: [DialogService],
})
export class CommentsComponent implements OnInit {
  destroy$ = new Subject<any>();
  @Input() comments$: Observable<any>;
  @ViewChild('dataTable', { static: true }) dataTable: Table;
  @Output() addCommentEvent = new EventEmitter<string>();
  tableColumn = CommentColumns;
  ref: DynamicDialogRef;
  ticket: ticketData;
  comments: any;
  canAddComments: boolean;
  isAllExpanded = false;
  shouldDisableButton = true;

  constructor(
    private dialogService: DialogService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.comments$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data) {
        this.comments = data.comments.map(comment => {
          if (!comment.value) {
            return {
              ...comment,
              short: '',
              expandable: false,
              expanded: false,
            };
          }

          comment.short =
            comment.value.length > 100
              ? comment.value.substring(0, 100) + '...'
              : comment.value;

          const firstLineMatch = comment.value.match(/^[^\n]*/);
          if (firstLineMatch && firstLineMatch[0] !== comment.value) {
            comment.short = firstLineMatch[0].substring(0, 100) + '...';
          }

          comment.expandable = comment.short.length !== comment.value.length;
          comment.expanded = false;

          return comment;
        });

        if (this.shouldToggleAllRows(data.comments)) {
          this.toggleAllRows();
        }

        this.canAddComments = data.canAddComments;

        this.shouldDisableButton = data.comments.every(
          item => item.expandable === false
        );
      }
    });
  }

  private shouldToggleAllRows(comments): boolean {
    return comments.length === 1;
  }

  toggleAllRows(): void {
    this.isAllExpanded = !this.isAllExpanded;
    this.comments.map(comment => {
      comment.expanded = this.isAllExpanded;
    });
  }

  toggleRow(comment) {
    if (comment.expandable) {
      comment.expanded = !comment.expanded;
      const expandableComments = this.comments.filter(
        comment => comment.expandable
      );
      this.isAllExpanded = expandableComments.every(
        comment => comment.expanded
      );
    }
  }

  getChipColor(colors: TableColumnColor[], value: string): string {
    if (value === 'Expedite' || value === 'Escalate') {
      return value;
    }
    return colors[value] ?? null;
  }

  async openAddComment() {
    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    this.ref = this.dialogService.open(AddCommentComponent, {
      width: '32%',
      contentStyle: { overflow: 'auto' },
      showHeader: false,
      styleClass: 'p-dialog-custom',
    });

    const result = await firstValueFrom(this.ref.onClose);

    if (result) {
      this.addCommentEvent.emit(result);
      return;
    }
    this.toastService.add({
      severity: ToastType.warning,
      summary: 'Adding Comment',
      detail: 'Operation Cancelled',
    });
  }
}
