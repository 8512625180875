<div class="flex flex-column h-full">
  <h1 class="text-xl px-3">An update has been released!</h1>
  <hr class="w-full" />
</div>
<div class="flex pl-5 pr-5">
  <div [innerHTML]="releaseNotes?.releaseNote.uReleaseNote | keepHtml"></div>
</div>
<div class="flex align-content-right justify-content-end pb-2">
  <p-button label="Close" (onClick)="closeButton(false)"></p-button>
  <p-button label="View Notes" (onClick)="closeButton(true)"> </p-button>
</div>
