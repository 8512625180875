<div class="flex flex-column flex-wrap align-content-center p-4">
  @if (loading) {
    <loading-spinner></loading-spinner>
  }
  @if (!loading) {
    <div class="w-full max-w-50rem">
      <div class="row">
        <h1 class="col">Digital Space - Customer Satisfaction Survey</h1>
        <!-- <div *ngIf="previousUrl" class="col flex justify-content-end flex-wrap">
        <button
          (click)="goBack()"
          pButton
          pRipple
          label="Back"
          class="p-button-primary"
        ></button>
      </div> -->
    </div>
    <hr class="w-full" />
    <p-messages
      [(value)]="messages"
      [enableService]="false"
      [escape]="false"
    [closable]="false"></p-messages>
    @if (canTakeSurvey) {
      <h2 class="text-center">Please rate your experience</h2>
      <div class="flex justify-content-center mb-4 text-center">
        <p-selectButton
          [options]="scoreOptions"
          [(ngModel)]="score"
          styleClass="text-center">
          <ng-template let-item pTemplate>
            <span class="w-full">{{ item.value }}</span>
          </ng-template>
        </p-selectButton>
      </div>
      <label for="description">Additional Feedback</label>
      <textarea
        pInputTextarea
        [(ngModel)]="additionalComments"
        id="description"
        type="text"
        rows="5"
      class="w-full p-2"></textarea>
      <small>
        Please help us by providing any additional comments you might have in
        order to improve our service.
      </small>
      <div class="flex justify-content-end flex-wrap mt-4">
        <button
          (click)="submitSurvey()"
          pButton
          pRipple
          label="Submit"
        class="p-button-primary"></button>
      </div>
    }
  </div>
}
</div>
