import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { Case } from '../shared/case-models';
import { MenuItem, Message } from 'primeng/api';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { IUserProfile } from '../../company/users/shared/user-models';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileService } from 'src/app/services/api/file/file.service';
import { FileManagerComponent } from 'src/app/components/misc/pop-up/file-manager/file-manager.component';
import { ViewFilesComponent } from 'src/app/components/misc/pop-up/view-files/view-files.component';
import { FileManagerService } from 'src/app/components/misc/pop-up/file-manager/file-manager.service';
import { ActivatedRoute, Params } from '@angular/router';
import {
  caseCloseState,
  CaseService,
  caseState,
} from 'src/app/services/api/case/case.service';
import { CommentTypes } from 'src/app/components/misc/comments/comments.component';
import { TableNames } from 'src/app/models/servicenow.model';
import { TicketConfirmationComponent } from 'src/app/components/misc/pop-up/ticket-confirmation/ticket-confirmation.component';
import { AddCommentComponent } from 'src/app/components/misc/pop-up/add-comment/add-comment.component';
import {
  CreateCommentRequest,
  TicketComment,
} from 'src/app/models/comments/comment.models';
import { ToastService, ToastType } from 'src/app/services/global/toast.service';
import {
  GenericApiErrorMessage,
  IsDisplayableException,
} from 'src/app/helpers/error.helper';

@Component({
  selector: 'app-case',
  templateUrl: './case.component.html',
  styleUrls: ['./case.component.scss'],
})
export class CaseComponent implements OnInit {
  CommentTypes = CommentTypes;
  commentSubject = new BehaviorSubject(null);
  comments$ = this.commentSubject.asObservable();
  comments: any;
  loading: boolean = false;
  case: Case;
  user: IUserProfile;
  canUpdateCase: boolean = false;
  attachmentFiles: any;
  ref: DynamicDialogRef;
  caseId: string;
  isImpersonating: boolean = false;

  messages: Message[] | undefined = [];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private caseService: CaseService,
    private authService: AuthService,
    private fileService: FileService,
    public fileManagerService: FileManagerService,
    private dialogService: DialogService,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.user = await this.authService.getUser();
    this.isImpersonating = this.authService.isImpersonating();
    this.canUpdateCase =
      this.user.uTimicoPortalPermissions.includes('TICKETS_REQUESTS_UPDATE') ||
      this.user.uTimicoPortalPermissions.includes('TICKETS_COMPANY_UPDATE');
    this.activatedRoute.params.subscribe(async (params: Params) => {
      this.caseId = params['id'];
    });
    await this.loadCase();
    const breadcrumbs: MenuItem[] = [
      {
        label: 'Service Requests',
        routerLink: '/secure/tickets/service-requests',
      },
      { label: this.case?.number },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
  }

  async loadCase() {
    this.loading = true;
    try {
      this.case = await firstValueFrom(this.caseService.getSingle(this.caseId));
      await this.loadComments();
      await this.caseLoadFiles(this.case.sysId);
    } catch (error) {
      console.error(error);
      let message = {
        severity: ToastType.error,
        summary: 'Error',
        detail: GenericApiErrorMessage,
        closable: false,
      };
      if (IsDisplayableException(error?.status)) {
        message = {
          severity: ToastType.error,
          summary: 'Error',
          detail: error.error,
          closable: false,
        };
      }
      this.messages = [message];
    } finally {
      this.loading = false;
    }
  }

  async caseLoadFiles(sysId: string) {
    let files = await firstValueFrom(this.fileService.getFiles(sysId));
    this.attachmentFiles = files.fileList;
  }

  canResolveCase(_case: Case): boolean {
    return (
      _case?.state !== 'Fulfilled' &&
      _case?.state !== 'Closed' &&
      _case?.state !== 'Resolved' &&
      _case?.state !== 'Cancelled'
    );
  }

  openAttachments() {
    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    this.ref = this.dialogService.open(ViewFilesComponent, {
      showHeader: false,
      data: {
        files: this.attachmentFiles,
        sysId: this.case.sysId,
        table: TableNames.CASE,
      },
      styleClass: 'p-dialog-custom',
    });
  }

  async close(closureNotes) {
    const response: boolean = await firstValueFrom(
      this.caseService.resolve({
        sysId: this.case.sysId,
        state: caseState.resolved,
        closeNotes: closureNotes,
        resolutionCode: caseCloseState.resolvedByCustomer,
      })
    );
    //TODO:Add error here if reponse false
    if (response) {
      this.toastService.add({
        severity: ToastType.info,
        summary: 'Case',
        detail: 'Your Case will be closed within 4 days.',
      });
      await this.loadCase();
    }
  }

  openConfirmation(closing) {
    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    this.ref = this.dialogService.open(TicketConfirmationComponent, {
      width: '48%',
      contentStyle: { overflow: 'auto' },
      showHeader: false,
      data: {
        title: 'Are you sure?',
        message: 'You will need to provide a reason in order to',
        messageReason: closing ? 'resolve' : 're-open',
        messageEnd: ' this case ticket. Do you wish to continue?',
      },
      styleClass: 'p-dialog-custom',
    });
    this.ref.onClose.subscribe(result => {
      if (result) {
        this.openRequiredComment(closing);
      } else {
        this.toastService.add({
          severity: ToastType.warning,
          summary: 'Case',
          detail: 'Operation Cancelled.',
        });
      }
    });
  }

  async openRequiredComment(closing) {
    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    this.ref = this.dialogService.open(AddCommentComponent, {
      width: '32%',
      height: '42%',
      contentStyle: { overflow: 'auto' },
      showHeader: false,
      styleClass: 'p-dialog-custom',
    });
    const result = await firstValueFrom(this.ref.onClose);

    if (result) {
      this.loading = true;
      if (closing) {
        await this.close(result);
        this.loading = false;
        return;
      }
      await this.addComment(result);
      await this.reopen();
      this.loading = false;
      return;
    }
    this.toastService.add({
      severity: ToastType.warning,
      summary: 'Adding Comment',
      detail: 'Operation Cancelled.',
    });
  }

  async reopen() {
    const response: boolean = await firstValueFrom(
      this.caseService.reopen({
        sysId: this.case.sysId,
        state: caseState.open,
        resolutionCode: caseCloseState.reopen,
      })
    );
    if (response) {
      this.toastService.add({
        severity: ToastType.warning,
        summary: 'Case',
        detail: 'Your Case will be re-evaluated by our team.',
      });
      await this.loadCase();
    }
  }

  async addComment(comment) {
    const commentToAdd: CreateCommentRequest = {
      itemSysId: this.case.sysId,
      comment: comment,
    };
    let addComment: TicketComment;

    addComment = await await firstValueFrom(
      this.caseService.createComment(commentToAdd)
    );
    if (addComment) {
      this.toastService.add({
        severity: ToastType.success,
        summary: 'Adding Comment',
        detail: 'Comment Added.',
      });
      await this.loadCase();
    }
  }

  async loadComments() {
    const comments = await firstValueFrom(
      this.caseService.getComments(this.case.sysId)
    );
    const canAddComments =
      this.canUpdateCase &&
      this.case?.state !== 'Fulfilled' &&
      this.case?.state !== 'Closed' &&
      this.case?.state !== 'Resolved' &&
      this.case?.state !== 'Cancelled';

    this.commentSubject.next({
      comments: comments,
      canAddComments: canAddComments,
    });
  }

  async openFileManager() {
    // TODO convert to conform with prime Dynamic Dialog
    // including header and footer
    this.ref = this.dialogService.open(FileManagerComponent, {
      showHeader: false,
      width: '45%',
      data: {
        files: this.attachmentFiles,
        sysId: this.case.sysId,
        table: TableNames.CASE,
      },
      styleClass: 'p-dialog-custom',
    });

    const result = await firstValueFrom(this.ref.onClose);
    if (result) {
      this.toastService.add({
        severity: ToastType.success,
        summary: 'File Manager',
        detail: 'File(s) Uploaded.',
      });
      this.loading = true;
      await this.loadCase();
      this.loading = false;
    }
  }

  getColor(value: string): string {
    switch (value) {
      case 'New':
        return 'new';
      case 'In Progress':
        return 'inprogress';
      case 'Awaiting Info':
        return 'awaitinginfo';
      case 'Resolved':
        return 'resolved';
      case 'Closed':
        return 'closed';
      case 'Open':
        return 'open';
      default:
        return 'closed';
    }
  }

  getContainerClasses(options: { [key: string]: boolean | string }) {
    const classes = {};
    if (options?.cols !== false) {
      classes[`col-${options?.cols || 12}`] = true;
    }
    if (options?.border) {
      classes['border-none'] = true;
      classes['border-top-1'] = true;
      classes['border-solid'] = true;
      classes['border-gray-200'] = true;
    }
    if (options?.borderBottom) {
      classes['border-bottom-1'] = true;
      classes['border-gray-200'] = true;
    }
    return classes;
  }
}
