import { Injectable } from '@angular/core';
import { UploadAttachmentRequest } from 'src/app/components/misc/pop-up/file-manager/file-manager.models';
import {
  CaseReOpenRequest,
  CaseResolveRequest,
} from 'src/app/components/service-hub/tickets/shared/case-models';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';
import { CreateCommentRequest } from 'src/app/models/comments/comment.models';
import { ServiceNowFilterQuery } from 'src/app/models/servicenow.model';

const taskDirectory = 'Case';

@Injectable({
  providedIn: 'root',
})
export class CaseService {
  constructor(private apiRequest: ApiRequest) {}

  getMultiple(request: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetMultipleCases`,
      body: request,
    };

    return this.apiRequest.request(requestProps);
  }

  getCount(request: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetCasesCount`,
      body: request,
    };

    return this.apiRequest.request(requestProps);
  }

  getSingle(request: string) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetSingleCase`,
      body: request,
    };

    return this.apiRequest.request(requestProps);
  }

  createComment(request: CreateCommentRequest) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/CreateCaseComment`,
      body: request,
    };

    return this.apiRequest.request(requestProps);
  }

  getComments(orderLineItemId: string) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetCaseComments`,
      body: orderLineItemId,
    };

    return this.apiRequest.request(requestProps);
  }

  uploadAttachment(request: UploadAttachmentRequest) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/CaseUploadAttachment`,
      body: request,
    };
    return this.apiRequest.request(requestProps);
  }

  resolve(request: CaseResolveRequest) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/ResolveCase`,
      body: request,
    };
    return this.apiRequest.request(requestProps);
  }

  reopen(request: CaseReOpenRequest) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/ReOpenCase`,
      body: request,
    };
    return this.apiRequest.request(requestProps);
  }
}

/**
 * Represents the states required by the backend to align with ServiceNow.
 */
export enum caseState {
  resolved = 'resolved',
  new = 'new',
  open = 'open',
  awaitingInfo = 'awaiting info',
  closed = 'closed',
  cancelled = 'cancelled',
}

export enum caseCloseState {
  reopen = 'reopen',
  resolvedByCustomer = 'resolved by customer',
  amendmentApplied = 'amendment applied',
  informationProvided = 'information provided',
  noResponseForRequiredInformation = 'no response for required information',
  resolvedByDigitalSpace = 'resolved by digital space',
}
