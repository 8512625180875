<div class="flex flex-column">
  <div
    class="flex justify-content-between align-items-center bg-white mx-3 py-3 border-solid border-1 border-gray-200">
    <h1 class="px-3 text-xl">Records and Comments</h1>
    <div class="pr-3">
      <ng-container>
        @if (canAddComments) {
          <button
            pButton
            label="Add Comment"
            icon="pi pi-comment"
            (click)="openAddComment()"></button>
        }
      </ng-container>
    </div>
  </div>
  <div
    class="flex-grow-1 overflow-auto mx-3 overflow-hidden bg-white border-solid border-1 border-gray-200 border-top-none border-bottom-none px-3 pt-2 pb-2">
    <p-button
      [label]="isAllExpanded ? 'Collapse All' : 'Expand All'"
      [icon]="isAllExpanded ? 'pi pi-sort' : 'pi pi-sort'"
      [disabled]="!(comments$ | async)?.comments?.length"
      [disabled]="shouldDisableButton"
      text
      [outlined]="true"
      (onClick)="toggleAllRows()"></p-button>
  </div>

  <div
    class="flex-grow-1 overflow-auto mx-3 overflow-hidden bg-white border-solid border-1 border-gray-200 border-top-none pb-3 px-3">
    <p-table
      #dataTable
      [value]="(comments$ | async)?.comments"
      [columns]="tableColumn"
      styleClass="p-datatable-striped"
      [rowHover]="true"
      sortMode="multiple"
      [rows]="25"
      rowExpandMode="multiple"
      dataKey="value">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th></th>
          @for (col of columns; track col) {
            <th class="text-white bg-gray-800 p-datatable-header-first-row">
              {{ col.header }}
            </th>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-comment let-columns="columns">
        <tr
          [class.cursor-pointer]="comment.expandable"
          (click)="toggleRow(comment)">
          <td class="w-2rem vertical-align-top pt-3">
            @if (comment.expandable) {
              <i
                [class]="
                  comment.expanded
                    ? 'pi pi-chevron-down px-2'
                    : 'pi pi-chevron-right px-2'
                "></i>
            }
          </td>
          <td
            class="vertical-align-top p-3"
            [class.white-space-pre-line]="comment.expanded"
            [class.word-break-break-word]="comment.expanded">
            {{ comment.expanded ? comment.value : comment.short }}
          </td>
          <td class="w-14rem vertical-align-top pt-3">
            {{ comment.sysCreatedOn }}
          </td>
          <td class="w-14rem vertical-align-top pt-3">
            {{ comment.uCommentedBy }}
          </td>
          <td class="w-16rem vertical-align-top pt-2">
            <div class="w-full m-1">
              <p-chip
                [label]="comment.type"
                [styleClass]="
                  getChipColor(
                    columns[columns.length - 1].display.colors,
                    comment.type
                  )
                    ? 'w-10 justify-content-center p-chip-' +
                      getChipColor(
                        columns[columns.length - 1].display.colors,
                        comment.type
                      )
                    : 'w-10 justify-content-center'
                "></p-chip>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <tr>
          <td [attr.colspan]="tableColumn?.length + 1">
            <loading-spinner></loading-spinner>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingicon"></ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="tableColumn?.length + 1">
            No results were found.
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
