export enum productCategories {
  Cloud = 'Cloud & Hosting',
  Connectivity = 'Connectivity',
  Services = 'IT Managed Services',
  Security = 'Security',
  UCC = 'UC&C',
}

export enum ProductTableColumns {
  Category = 'category',
  Description = 'description',
  CLI = 'cli',
  User = 'user',
  Subsite = 'subsite',
  BillingFrequency = 'billingFrequency',
  Charge = 'charge',
  Usage = 'usage',
  BillingStartDate = 'billingStartDate',
  BillingEndDate = 'billingEndDate',
  ContractTerm = 'contractTerm',
  ContractEndDate = 'contractEndDate',
}
