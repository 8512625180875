import { Injectable } from '@angular/core';
import {
  firstValueFrom,
  interval,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import { Notification } from '../../models/notification/notification.models';

import { Router } from '@angular/router';
import { NotificationsService } from '../../services/api/notifications/notifications.service';
import { environment } from 'src/environments/environment';
import { DateTime } from 'luxon';

@Injectable()
export class NotificationHelper {
  subscribed = false;
  intervalSubscription: Subscription;
  notifications: Notification[] = [];
  notificationsUpdate = new Subject<Notification[]>();
  notificationsCountUpdate = new Subject<number>();
  markAllReadUpdate = new Subject();

  constructor(
    private route: Router,
    private notificationsService: NotificationsService
  ) {}

  getNotificationsUpdates(): Observable<Notification[]> {
    return this.notificationsUpdate.asObservable();
  }

  getNotificationCountUpdate(): Observable<number> {
    return this.notificationsCountUpdate.asObservable();
  }

  getMarkAllReadUpdate(): Observable<unknown> {
    return this.markAllReadUpdate.asObservable();
  }

  async loadAllNotifications(requestBody) {
    // TODO added await, check this is correct
    const notifications = await firstValueFrom(
      this.notificationsService.getAllNotifications(requestBody)
    );

    return notifications;
  }

  async getUnreadNotifications() {
    const unreadNotifications: Notification[] = await firstValueFrom(
      this.notificationsService.getUnreadNotifications('')
    );

    unreadNotifications.forEach(notification => {
      notification.lastUpdated = this.lastUpdatedDate(notification.lastUpdated);
    });
    this.notifications = unreadNotifications;
    this.updateNotifications();
    if (!this.subscribed) {
      this.intervalSubscription = interval(environment.countTime).subscribe(
        () => {
          this.getUnreadNotifications();
        }
      );
      this.subscribed = true;
    }
    return unreadNotifications;
  }

  openNotification(notification: Notification) {
    firstValueFrom(
      this.notificationsService.setNotificationAsRead(notification.sysId)
    );
    this.readNotification(notification.sysId);
    if (notification.category != 'Other') {
      if (notification.category == 'Survey') {
        window.open(
          environment.baseUrl +
            this.getLink(notification.category) +
            '/' +
            notification.ticketSysId
        );
      } else {
        this.route.navigateByUrl(
          '/secure/tickets/' +
            this.getLink(notification.category) +
            '/' +
            notification.ticketSysId
        );
      }
    } else {
      this.route.navigateByUrl(
        '/secure/account/notification/' + notification.sysId
      );
    }
  }

  readNotification(sysId: string) {
    this.notifications
      .filter(notification => notification.sysId == sysId)
      .forEach(notification => (notification.notificationRead = true));
    this.updateNotifications();
  }

  updateNotifications() {
    this.notificationsUpdate.next(this.notifications);
    this.notificationsCountUpdate.next(
      this.notifications.filter(n => n.notificationRead == false).length
    );
  }

  lastUpdatedDate(date): string {
    return DateTime.fromFormat(date, 'dd/MM/yyyy').toFormat('dd/MM/yyyy');
  }

  clearInterval() {
    this.intervalSubscription?.unsubscribe();
  }

  getLink(category): string {
    switch (category) {
      case 'Incident':
        return 'incident';
      case 'Requested Item':
        return 'service-request';
      case 'Order':
        return 'service-request';
      default:
        return category.toLowerCase();
    }
  }

  async markAllAsRead() {
    this.notifications.forEach(n => (n.notificationRead = true));
    this.markAllReadUpdate.next(void 0);
    this.updateNotifications();
    firstValueFrom(this.notificationsService.setAllNotificationsAsRead());
  }
}
