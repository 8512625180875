import { Injectable } from '@angular/core';

@Injectable()
export class UtilService {
  mobile: Boolean = false;

  constructor() {
    this.mobile = this.isMobile();
  }

  // Returns an array equal to a given object.
  generateArray(obj) {
    return Object.keys(obj).map(key => obj[key]);
  }

  // Returns an array with the property names of a given object
  generateArrayName(obj) {
    return Object.keys(obj).map(key => key);
  }

  // Prevents special characters from being typed onto the textboxes.
  omitSpecialChar(event) {
    var k = event.charCode;
    return (
      (k > 47 && k < 58) ||
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      k == 44 ||
      k == 45 ||
      k == 95
    );
  }

  // Prevents special characters from being used in strings.
  omitSpecialString(value: string): string {
    return value.replace(/[&\/\\#+()$~%.'":*?{}]/g, '');
  }

  isMobile(): Boolean {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  clone(objectToClone) {
    return JSON.parse(JSON.stringify(objectToClone));
  }

  isNullOrEmpty(value) {
    return value == null || value === '';
  }

  pluralize(singular: string, count): string {
    if (count == 1) {
      return singular;
    }
    var pluralRule;
    var tempSingular = singular;
    var lastLetter = tempSingular.slice(singular.length - 1);

    if (
      lastLetter == 's' ||
      lastLetter == 'h' ||
      lastLetter == 'n' ||
      lastLetter == 'e' ||
      lastLetter == 'y'
    ) {
      var lastTwoLetters = tempSingular.slice(singular.length - 2);
      if (lastLetter == 'y') {
        var isVowel = this.vowels.find(x => x.letter == lastTwoLetters[0]);
        if (isVowel == null) {
          pluralRule = this.pluralRules.find(x => x.letter == lastLetter);
        }
      } else {
        if (
          lastTwoLetters[0] == 's' ||
          lastTwoLetters[0] == 'c' ||
          lastTwoLetters[0] == 'f' ||
          lastTwoLetters[0] == 'u' ||
          lastTwoLetters[0] == 'i' ||
          lastTwoLetters[0] == 'o'
        ) {
          pluralRule = this.pluralRules.find(x => x.letter == lastTwoLetters);
        } else {
          pluralRule = this.pluralRules.find(x => x.letter == lastLetter);
        }
      }
    } else {
      pluralRule = this.pluralRules.find(x => x.letter == lastLetter);
    }

    if (pluralRule == null) {
      return singular + 's';
    } else {
      if (pluralRule.replace == false) {
        return singular + pluralRule.plural;
      } else {
        return singular.replace(pluralRule.letter, pluralRule.plural);
      }
    }
  }

  vowels = [
    { letter: 'a' },
    { letter: 'e' },
    { letter: 'i' },
    { letter: 'o' },
    { letter: 'u' },
  ];

  pluralRules = [
    { letter: 'o', replace: false, plural: 'es' },
    { letter: 's', replace: false, plural: 'es' },
    { letter: 'ss', replace: false, plural: 'es' },
    { letter: 'sh', replace: false, plural: 'es' },
    { letter: 'ch', replace: false, plural: 'es' },
    { letter: 'x', replace: false, plural: 'es' },
    { letter: 'z', replace: false, plural: 'es' },
    { letter: 'f', replace: true, plural: 'ves' },
    { letter: 'fe', replace: true, plural: 'ves' },
    { letter: 'us', replace: true, plural: 'i' },
    { letter: 'is', replace: true, plural: 'es' },
    { letter: 'on', replace: true, plural: 'a' },
    { letter: 'y', replace: true, plural: 'ies' },
  ];

  generateUniqueId() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  getMonthString(month) {
    switch (month) {
      case 1:
        return 'January';
      case 2:
        return 'February';
      case 3:
        return 'March';
      case 4:
        return 'April';
      case 5:
        return 'May';
      case 6:
        return 'June';
      case 7:
        return 'July';
      case 8:
        return 'August';
      case 9:
        return 'September';
      case 10:
        return 'October';
      case 11:
        return 'November';
      case 12:
        return 'December';
    }
  }

  getMonthNumber(month) {
    switch (month) {
      case 'January':
        return 1;
      case 'February':
        return 2;
      case 'March':
        return 3;
      case 'April':
        return 4;
      case 'May':
        return 5;
      case 'June':
        return 6;
      case 'July':
        return 7;
      case 'August':
        return 8;
      case 'September':
        return 9;
      case 'October':
        return 10;
      case 'November':
        return 11;
      case 'December':
        return 12;
    }
  }

  getUrlForTicketReference(ticket) {
    let ticketRef = ticket.number.substring(0, 3);
    switch (ticketRef) {
      case 'INC':
        return 'secure/tickets/incident/';
      case 'RIT':
        return 'secure/tickets/service-request/';
    }
  }

  getRealValueForStateFilter(state) {
    switch (state) {
      case 'Closed':
        return '3';
      case 'PIR':
        return '11';
      case 'On Hold':
        return '17';
      case 'Implementation':
        return '10';
      case 'Approval':
        return '9';
      case 'Implementation Planning':
        return '15';
      case 'Validation':
        return '2';
      default:
        return state;
    }
  }
}
