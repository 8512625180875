import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IBar,
  IBarCategoryContainer,
  IBarContainer,
  IBarDropdownOption,
  IUpdatedBarControls,
} from '../../models/telephony-models';
import { BarringService } from '../../shared/barring.service';
import { BreadcrumbService } from 'src/app/services/general/breadcrumb/breadcrumb.service';
import { MenuItem } from 'primeng/api/menuitem';

@Component({
  selector: 'edit-bars',
  templateUrl: './edit-bars.component.html',
  styleUrls: ['./edit-bars.component.scss'],
})
export class EditBarsComponent implements OnInit {
  loading: boolean = false;
  mobileNumber: string = '';
  network: string = '';
  bars: IBar[];
  updatedControls: IUpdatedBarControls;
  submitFinished: boolean = false;
  formLoaded: boolean = false;
  items: MenuItem[] | undefined;
  steps: MenuItem[] | undefined = [];

  activeStepIndex: number = 0;
  activeIndex: MenuItem = this.steps[0];

  dropdownOptions: IBarDropdownOption[] = [
    { label: 'On', value: true },
    { label: 'Off', value: false },
  ];

  formSectionStrings = {
    general: 'general',
    roaming: 'roaming',
    roamingCap: 'roamingcap',
    calling: 'calling',
  };

  barringForm = this.formBuilder.group({
    general: this.formBuilder.array([]),
    roaming: this.formBuilder.array([]),
    roamingcap: this.formBuilder.array([]),
    calling: this.formBuilder.array([]),
  });

  constructor(
    public barringService: BarringService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    await this.initialise();
    const breadcrumbs: MenuItem[] = [
      {
        label: 'Mobile',
        routerLink: '/secure/products-and-services/telephony',
      },
      {
        label: this.mobileNumber,
        routerLink: '/secure/products-and-services/telephony/data',
      },
      { label: 'Amend Options' },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);

    this.items = [
      {
        label: 'Barring',
      },
      {
        label: 'Confirm',
      },
      {
        label: 'Submit',
      },
    ];
    this.activeIndex = this.items[this.activeStepIndex];
  }

  async initialise() {
    this.loading = true;
    this.mobileNumber = this.barringService.storedMobileNumber;
    if (!this.mobileNumber) this.navigateToMobileTable();
    else {
      this.bars = await this.loadBars(this.mobileNumber);
      let barData: IBarCategoryContainer = await this.loadBarsCategorised(
        this.mobileNumber
      );
      this.network = barData.network;
      barData.barCategories.forEach(categoryItem =>
        this.buildForm(categoryItem.barList)
      );
    }
    this.loading = false;
  }

  buildForm(bars: IBar[]) {
    bars.forEach(item => {
      this.formAddBar(item);
    });
    this.formLoaded = true;
  }

  formAddBar(bar: IBar) {
    const newFormBar = this.formBuilder.group({
      name: [bar.name, '', ''],
      active: [bar.active, '', ''],
      category: [bar.category, '', ''],
    });
    this.getFormCategory(bar.category.toLowerCase()).push(newFormBar);
  }

  async loadBars(mobileNumber: string) {
    let barData: IBarContainer =
      await this.barringService.loadBars(mobileNumber);
    return barData.bars;
  }

  async loadBarsCategorised(mobileNumber: string) {
    let barData: IBarCategoryContainer =
      await this.barringService.loadBarsCategorised(mobileNumber);
    return barData;
  }

  async submitForm() {
    this.stepForward();
    let request: IBarContainer = {
      network: this.network,
      cli: this.mobileNumber,
      bars: this.updatedControls.nonCategorised,
    };
    let result = await this.barringService.toggleBars(request);
    if (result) {
      this.submitFinished = true;
    }
  }

  getFormCategory(category: string): FormArray {
    return this.barringForm.controls[category] as FormArray;
  }

  updateDirtyValues() {
    this.updatedControls = this.getDirtyValues(this.barringForm);
    this.stepForward();
  }

  getDirtyValues(parentForm: FormGroup) {
    let dirtyControls: IUpdatedBarControls = {
      categorised: [],
      nonCategorised: [],
    };
    Object.keys(parentForm.controls).forEach(control => {
      let currentFormSection = <FormArray>parentForm.get(control);
      if (currentFormSection.dirty) {
        let dirtyControlCategory = {
          name: control,
          updatedValues: [],
        };
        currentFormSection.controls.forEach(item => {
          if (this.controlChanged(item) === true) {
            dirtyControlCategory.updatedValues.push(item);
            dirtyControls.nonCategorised.push(item.value);
          }
        });
        dirtyControls.categorised.push(dirtyControlCategory);
        dirtyControls.nonCategorised.push();
      }
    });
    return dirtyControls;
  }

  controlChanged(control): boolean {
    if (
      control.value.active !== '' &&
      control.dirty &&
      this.bars.filter(x => x.name === control.value.name)[0].active !==
        control.value.active
    ) {
      return true;
    } else {
      return false;
    }
  }

  navigateToMobileTable() {
    this.router.navigateByUrl('secure/products-and-services/telephony');
  }

  navigateToMobileData() {
    this.router.navigateByUrl('secure/products-and-services/telephony/data');
  }

  convertActive(active: boolean) {
    switch (active) {
      case true:
        return 'On';
      case false:
        return 'Off';
    }
  }

  cssSetDropdownStyle(dropdownValue) {
    return this.barringService.cssSetDropdownStyle(dropdownValue);
  }

  cssDisableSelectedOption(bar: FormControl, option: boolean) {
    return this.barringService.cssDisableSelectedOption(bar, option);
  }

  onActiveIndexChange(event: number): void {
    this.activeStepIndex = event;
    this.activeIndex = this.items[this.activeStepIndex];
  }

  stepBack(): void {
    if (this.items[this.activeStepIndex - 1]) {
      this.onActiveIndexChange(this.activeStepIndex - 1);
    }
  }

  stepForward(): void {
    if (this.items[this.activeStepIndex + 1]) {
      this.onActiveIndexChange(this.activeStepIndex + 1);
    }
  }
}
