import { TableColumn } from './../../../../../models/table';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import {
  SupportArticleFilters,
  supportColumns,
} from '../shared/support-models';
import { cloneDeep } from 'lodash-es';
import { HelpArticleService } from 'src/app/services/api/help-article/help-article.service';
import { Table } from 'primeng/table';
import {
  SupportCategories,
  SupportDetailsResponse,
} from 'src/app/models/support/support-articles.models';

@Component({
  selector: 'app-support-category',
  templateUrl: './support-category.component.html',
  styleUrls: ['./support-category.component.scss'],
})
export class SupportCategoryComponent implements OnInit {
  @Input() supportService: SupportCategories;
  @ViewChild('dataTable') dataTable: Table;
  loading = false;
  supportArticlesSubject = new BehaviorSubject<SupportDetailsResponse>(null);
  supportArticles$ = this.supportArticlesSubject.asObservable();
  filter = cloneDeep(SupportArticleFilters);
  constructor(private helpArticleService: HelpArticleService) {}
  cols: TableColumn[] = cloneDeep(supportColumns);
  ngOnInit() {
    this.loadSupportDetails();
  }

  async loadSupportDetails(): Promise<void> {
    this.loading = true;
    if (this.supportService != null) {
      const index = this.filter.find(f => f.Column == 'uCategory');
      if (!index) {
        this.filter.push({
          Column: 'uCategory',
          Value: [this.supportService.category],
          Type: 'text',
          Options: [],
          Visibility: false,
        });
      }

      const supportDetails = await firstValueFrom(
        this.helpArticleService.getSupportCategoryContent(this.filter)
      );
      this.supportArticlesSubject.next(supportDetails);
      this.loading = false;
    }
  }
}
