<div class="h-full bg-white min-h-25rem">
  <div class="flex flex-column h-full">
    <h2
      class="text-center m-0 py-3 text-xl font-bold border-solid border-1 border-gray-200 border-bottom-1">
      {{ chart | humanise | titlecase }}
    </h2>
    @if (isLoading) {
      <div class="flex justify-content-center align-items-center flex-grow-1">
        <loading-spinner></loading-spinner>
      </div>
    }
    @if (!isLoading) {
      @if (!noData && !noPermission) {
        <div class="border-solid border-1 border-gray-200 border-top-none">
          <div
            class="flex justify-content-between align-items-center mt-1 px-3">
            <h1 class="text-lg m-0">Active Tickets</h1>
            <h1
              (click)="countClickEvent(chart)"
              (keyup.enter)="countClickEvent(chart)"
              role="button"
              tabindex="0"
              class="text-5xl m-0 cursor-pointer hover:text-pink-500">
              {{ totalCount }}
            </h1>
          </div>
          <div
            class="h-19rem"
            echarts
            [options]="newChart"
            (chartClick)="onChartClick($event)"></div>
        </div>
      }
      @if (noData) {
        <div
          class="flex justify-content-center align-items-center flex-grow-1 border-solid border-1 border-top-none border-gray-200 border-bottom-1">
          <p class="text-2xl text-gray-600">No data available.</p>
        </div>
      }
      @if (noPermission) {
        <div
          class="flex justify-content-center align-items-center flex-grow-1 border-solid border-1 border-top-none border-gray-200 border-bottom-1">
          <p class="text-2xl text-gray-600">No access to view the data.</p>
        </div>
      }
    }
  </div>
</div>
