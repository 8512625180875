<!-- <div class="flex flex-column h-full">
  <h1 class="text-xl px-3">Provide Information</h1>
  <hr class="w-full mt-0" />
  <div class="px-4">
    <p-messages
      *ngIf="messageDetail"
      [(value)]="messageDetail"
      [enableService]="false"
      [closable]="false"
    />
    <div
      *ngIf="latestComment"
      class="white-space-pre-line word-break-break-word text-base m-0 pb-2 overflow-auto"
    >
      <small class="right-0"
        >{{ latestComment.sysCreatedOn }}
        {{ latestComment.uCommentedBy }}</small
      >
      {{ latestComment.value }}
    </div>

    <div *ngIf="showInput" class="pb-1">
      <span
        ><span class="text-primary text-lg">*</span
        ><label for="Reason" class="text-lg font-bold">Comment</label></span
      >
      <form [formGroup]="infoForm">
        <textarea
          pInputText
          id="Comment"
          placeholder="Comment"
          formControlName="infoComment"
          aria-label="comment"
          type="text"
          rows="4"
          class="w-full"
        ></textarea>
        <small
          *ngIf="
            infoForm.get('infoComment').invalid &&
            infoForm.get('infoComment').touched
          "
          class="p-error text-base"
        >
          Comment needs to be provided to submit.
        </small>
      </form>
    </div>
  </div>
  <div class="flex align-content-center justify-content-center py-3">
    @switch (ticketState) { @case ("Closed") {
    <p-button
      styleClass="p-button-secondary"
      label="Raise an incident"
      (onClick)="redirect()"
    ></p-button>
    <p-button
      styleClass="p-button"
      label="Close"
      (onClick)="close()"
    ></p-button>
    } @case ("Resolved") {
    <p-button
      styleClass="p-button"
      label="Close"
      (onClick)="close()"
    ></p-button>
    } @default {
    <p-button
      styleClass="p-button-secondary"
      label="Cancel"
      (onClick)="cancel()"
    ></p-button>
    <p-button
      styleClass="p-button"
      [disabled]="infoForm.invalid"
      label="Submit"
      (onClick)="submitInformation()"
    ></p-button>
    } }
  </div>
</div> -->
@if (messageDetail) {
  <p-messages
    [(value)]="messageDetail"
    [enableService]="false"
    [closable]="false" />
}
@if (latestComment) {
  <div
    class="white-space-pre-line word-break-break-word text-base m-0 pb-2 overflow-auto">
    <small class="block font-bold">
      {{ latestComment.sysCreatedOn }} {{ latestComment.uCommentedBy }}
    </small>
    {{ latestComment.value }}
  </div>
}
