import { Inject, Injectable } from '@angular/core';
import {
  ApiRequest,
  IRequestOptions,
} from 'src/app/helpers/api.request.helper';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private apiRequest: ApiRequest) {}

  //ToDo Add filter type
  getUsageAlerts(filter: any) {
    const requestProps: IRequestOptions = {
      endpoint: 'Alert/GetUsageAlerts',
      body: filter,
    };
    return this.apiRequest.request(requestProps);
  }

  //ToDo Add type
  createUsageAlert(usageAlerts: any) {
    const requestProps: IRequestOptions = {
      endpoint: 'Alert/CreateUsageAlert',
      body: usageAlerts,
    };
    return this.apiRequest.request(requestProps);
  }

  //Totype
  updateUsageAlert(useageAlert: any) {
    const requestProps: IRequestOptions = {
      endpoint: 'Alert/UpdateUsageAlert',
      body: useageAlert,
    };
    return this.apiRequest.request(requestProps);
  }

  //ToDo Add Type
  deleteUsageAlert(useageAlert: any) {
    const requestProps: IRequestOptions = {
      endpoint: 'Alert/DeleteUsageAlert',
      body: useageAlert,
    };
    return this.apiRequest.request(requestProps);
  }
}
